import { Trans, useTranslation } from 'react-i18next';

import { RippleHyperLink, RippleTypography } from '@/design';
import { useTeamInformation } from '@/models/TeamInformation';
import { hasGranularControl } from '@/modules/TeamSettings/utils';

export const TwoStepVerificationErrorMessage = () => {
  const teamInfo = useTeamInformation();
  const msg = hasGranularControl(teamInfo) ? 'pref_policy_security_to_owner_without_granular' : 'pref_policy_security_to_owner';
  const search = new URLSearchParams({ msg }).toString();
  const link = `/account_info?${search}#team_tab`;
  const { t } = useTranslation(['common']);
  return (
    <RippleTypography variant="body02">
      <Trans
        t={t}
        i18nKey="common:two_step_verification_is_required_to_do_this_action_learn_more"
        components={{
          Link: <RippleHyperLink variant="hyperlink02" target="_blank" href={link} />,
        }}
      />
    </RippleTypography>
  );
};
