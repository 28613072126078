import { useTranslation } from 'next-i18next';

import { RippleBodyText01, RippleHeading05 } from '@/design';

/**
 * Currently only for welcome flow on EU stack, mostly you should use `useLaunchSRCSuccessMessage` to hint the user.
 */
export function LaunchSRCSuccess(): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <RippleHeading05 marginTop="32px">{t('common:launchSRC.success.title')}</RippleHeading05>
      <RippleBodyText01 marginTop="32px">{t('common:launchSRC.success.description')}</RippleBodyText01>
    </>
  );
}
