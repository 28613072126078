import { useTranslation } from 'react-i18next';

import {
  RippleButton,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleTypography,
} from '@/design';

import { MAX_POLICY_COUNT } from '../constants';

type ExceededMaxPolicyModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ExceededMaxPolicyModal = ({ isOpen, onClose }: ExceededMaxPolicyModalProps) => {
  const { t } = useTranslation();
  return (
    <RippleModal isOpen={isOpen} onClose={onClose} size="2xl">
      <RippleModalOverlay />
      <RippleModalContent>
        <RippleModalHeader>
          <RippleTypography variant="heading05">{t('emm-policy:exceeded_maximum_number_of_policies')}</RippleTypography>
        </RippleModalHeader>
        <RippleModalBody>
          <RippleTypography variant="body02">
            {t('emm-policy:you_have_exceeded_the_limit_of_policies', { maximum: MAX_POLICY_COUNT })}
          </RippleTypography>
        </RippleModalBody>
        <RippleModalFooter gap="8px">
          <RippleButton variant="primary" onClick={onClose}>
            {t('common:ok')}
          </RippleButton>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
};
