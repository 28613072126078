// Include menuList item for adding iframe navigation border when the URL matches.
const menuList = [
  {
    key: 'logs',
    list: ['/logs/active_sessions', '/w/logs/reports', '/w/logs/system_tools', '/w/logs/service_desk_sessions', '/w/logs/reports'],
  },
  {
    key: 'management',
    list: [
      '/group',
      '/inventory',
      '/scheduled_access',
      '/service_desk/channels',
      '/service_desk/surveys',
      '/sos/packages',
      '/team',
      '/vulnerability_score',
      '/w/antivirus',
      '/w/endpoint_security',
      '/w/inventory',
      '/w/policy',
      '/w/preference_policy',
      '/windows_updates',
    ],
  },
  {
    key: 'sos',
    list: ['/sos/management', '/sos/launch/', '/sos'],
  },
  {
    key: 'service_desk',
    list: ['/service_desk/support_sessions'],
  },
  {
    key: 'computers',
    list: ['/computers'],
  },
  {
    key: 'dashboard',
    list: ['/w/dashboard'],
  },
  {
    key: 'get_started',
    list: ['/w/get-started'],
  },
];

export function getCurrentMenuName(pathname: string): string | null {
  const target = menuList.find((menu) => {
    return menu.list.find((menuItem) => {
      return pathname.startsWith(menuItem);
    });
  });

  return target ? target.key : null;
}
