import { Flex } from '@chakra-ui/react';

import { RippleBodyText01, RippleHeading05 } from '@/design';
import { SurveyFormScenario, SurveyFormScenarioMap, SurveyFormValue } from '@/modules/ServiceDesk/SurveyForm/Editor/types';

type WelcomeMessageProps = {
  scenario: SurveyFormScenario;
  title: SurveyFormValue['welcome_message']['title'];
  editTitle?: () => void;
  subtitle: SurveyFormValue['welcome_message']['subtitle'];
  editSubtitle?: () => void;
};
export const WelcomeMessage = ({ scenario, title, editTitle, subtitle, editSubtitle }: WelcomeMessageProps) => {
  return (
    <Flex direction="column" align="center" pt="12px" gap="8px" w="100%">
      {title !== '' && (
        <RippleHeading05
          px="16px"
          py="4px"
          borderRadius="4px"
          w="100%"
          align="center"
          _hover={scenario === SurveyFormScenarioMap.editor ? { bg: 'blue.0', cursor: 'pointer' } : {}}
          whiteSpace="pre-wrap"
          onClick={editTitle}
        >
          {title}
        </RippleHeading05>
      )}
      {subtitle !== '' && (
        <RippleBodyText01
          px="16px"
          py="4px"
          borderRadius="4px"
          color="neutral.300"
          w="100%"
          align="center"
          _hover={scenario === SurveyFormScenarioMap.editor ? { bg: 'blue.0', cursor: 'pointer' } : {}}
          whiteSpace="pre-wrap"
          onClick={editSubtitle}
        >
          {subtitle}
        </RippleBodyText01>
      )}
    </Flex>
  );
};
