import { OTableResult, OTableSourceData, OTableSourceGroup } from '@/components/ObservableTable/types';
import { AccordionHashMap, OTableAccordionParams } from '@/components/ObservableTable/useObservableAccordion';
import { OTableRowTypes, OTableViewStates } from '@/components/ObservableTable/useObservableTable';

type OTableAccordionProps = {
  updateAccordion: (accordionHashMap: AccordionHashMap) => void;
};

export type OTableAccordionResult = {
  onToggleAccordionAll: () => void;
  isAllRowsExpanded: boolean;
};

export const makeAccordionPipe =
  <D extends OTableSourceData, G extends OTableSourceGroup>(tableResult: OTableResult<D, G>, { updateAccordion }: OTableAccordionProps) =>
  (accordionParams: OTableAccordionParams): OTableResult<D, G> => {
    const { accordionHashMap } = accordionParams;

    const { states, rows, groups } = tableResult;

    if (states.viewState !== OTableViewStates.GROUP) {
      return tableResult;
    }

    const onToggleAccordionAll = () => {
      const isAllExpanded = groups?.every((group) => accordionHashMap[group.id]) ?? false;

      if (isAllExpanded) {
        updateAccordion({});
      } else {
        const expandedHashMap = groups?.reduce<AccordionHashMap>((acc, group) => {
          acc[group.id] = true;
          return acc;
        }, {});
        updateAccordion(expandedHashMap ?? {});
      }
    };

    const groupKeys = Object.keys(accordionHashMap);
    const isAllRowsExpanded = groups?.length === groupKeys.length && groupKeys.every((accordion) => accordionHashMap[accordion]);

    const accordion: OTableAccordionResult = {
      onToggleAccordionAll,
      isAllRowsExpanded,
    };

    const nextRows = rows.filter((row) => {
      if (row.type === OTableRowTypes.GROUP) {
        return true;
      }

      return row.original.gid && Boolean(accordionHashMap[row.original.gid]);
    });

    return {
      ...tableResult,
      rows: nextRows,
      accordion,
    };
  };
