import { Row, createColumnHelper, flexRender, getSortedRowModel } from '@tanstack/react-table';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';

import {
  RippleCheckbox,
  RippleReactTable,
  RippleSkeleton,
  RippleTD,
  RippleTR,
  RippleTooltip,
  RippleTypography,
  RippleVirtuosoReactTable,
} from '@/design';
import { sortTeamMemberRole } from '@/showcase';

import { UserRoleCell } from './UserCells';
import { accessPermissionModalAtom, tableSelectionStateAtom, userListAtom } from './atoms';
import { useIsSubmitting } from './hooks';
import type { User } from './types';
import { getRoleTag } from './utils';

const columnHelper = createColumnHelper<User>();

const columnWidthMap = {
  checkbox: 44,
  email: 480,
  role: 0, // remaining space
};

const columns = [
  columnHelper.display({
    id: 'checkbox',
    size: columnWidthMap.checkbox,
    header: function RenderHeader({ table }) {
      const isSubmitting = useIsSubmitting();
      const { hasLimitedManagePermission } = useAtomValue(accessPermissionModalAtom);

      return (
        <RippleCheckbox
          data-testid="checkbox-all"
          isDisabled={isSubmitting || hasLimitedManagePermission}
          isChecked={table.getIsAllRowsSelected()}
          isIndeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      );
    },
    cell: function RenderCell({ row }) {
      const isSubmitting = useIsSubmitting();
      const { hasLimitedManagePermission } = useAtomValue(accessPermissionModalAtom);

      return (
        <RippleCheckbox
          data-testid={`checkbox-${row.id}`}
          isDisabled={isSubmitting || hasLimitedManagePermission}
          isChecked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      );
    },
  }),
  columnHelper.accessor('email', {
    size: columnWidthMap.email,
    header: function RenderHeader() {
      const { t } = useTranslation();
      return t('computer:splashtop_account');
    },
    cell: function RenderCell({ getValue }) {
      const value = getValue();

      return (
        <RippleTooltip label={value}>
          <RippleTypography variant="body02" isTruncated>
            {value}
          </RippleTypography>
        </RippleTooltip>
      );
    },
  }),
  columnHelper.accessor((user) => getRoleTag(user), {
    id: 'role',
    size: columnWidthMap.role,
    minSize: 0,
    sortingFn: (aRow, bRow) => {
      const aRoleTag = getRoleTag(aRow.original);
      const bRoleTag = getRoleTag(bRow.original);
      return sortTeamMemberRole(aRoleTag, bRoleTag);
    },
    header: function RenderHeader() {
      const { t } = useTranslation();
      return t('computer:role');
    },
    cell: function RenderCell({ getValue }) {
      return <UserRoleCell roleTag={getValue()} />;
    },
  }),
];

export function UserListTable(): React.JSX.Element {
  const userList = useAtomValue(userListAtom);

  const [rowSelection, onRowSelectionChange] = useAtom(tableSelectionStateAtom);

  return (
    <RippleVirtuosoReactTable
      variant="compact"
      style={{ width: '100%' }}
      columns={columns}
      data={userList}
      virtuosoOptions={{ itemContent: renderItemContent }}
      tableOptions={{
        state: { rowSelection },
        initialState: { sorting: [{ id: 'role', desc: true }] },
        onRowSelectionChange,
        getSortedRowModel: getSortedRowModel(),
        getRowId: (row) => row.id,
      }}
    />
  );
}

function renderItemContent(_index: number, row: Row<User>) {
  return (
    <RippleTR key={row.id} isSelected={row.getIsSelected()} w="100%">
      {row.getVisibleCells().map((cell) => {
        const widthProp = cell.column.columnDef.size ? { w: `${cell.column.columnDef.size}px` } : { flex: 1 };

        return (
          <RippleTD key={cell.id} {...widthProp}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </RippleTD>
        );
      })}
    </RippleTR>
  );
}

const loadingTableColumns = [
  columnHelper.display({
    id: 'checkbox',
    size: columnWidthMap.checkbox,
    header: () => <RippleCheckbox isDisabled />,
    cell: () => <RippleCheckbox isDisabled />,
  }),
  columnHelper.display({
    id: 'email',
    size: columnWidthMap.email,
    header: function RenderHeader() {
      const { t } = useTranslation();
      return t('computer:splashtop_account');
    },
    cell: () => <RippleSkeleton w="128px" />,
  }),
  columnHelper.display({
    id: 'role',
    size: columnWidthMap.role,
    minSize: 0,

    header: function RenderHeader() {
      const { t } = useTranslation();
      return t('computer:role');
    },
    cell: () => '',
  }),
];

const placeholderData: Array<User> = Array.from({ length: 5 }).map((_, index) => ({
  type: 'external_user',
  id: `externalUser_${index}`,
  userId: index,
  email: '',
}));

export function UserListLoadingTable(): React.JSX.Element {
  return <RippleReactTable variant="compact" width="100%" columns={loadingTableColumns} data={placeholderData} />;
}
