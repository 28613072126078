import { useEffect, useRef, useTransition } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import { useSetAtom } from 'jotai';

import { leftOffsetAtom } from '../atoms';

type ComputerListPageContainerProps = {
  children: React.ReactNode;
} & BoxProps;

export const ComputerListPageContainer = ({ children, ...otherProps }: ComputerListPageContainerProps) => {
  const childrenContainerRef = useRef<HTMLDivElement>(null);
  const setLeftOffset = useSetAtom(leftOffsetAtom);
  const [, startLeftOffsetTransition] = useTransition();

  useEffect(
    function registerWindowResize() {
      function updateLeftOffset() {
        const leftOffset = getOffsetLeft(childrenContainerRef.current);

        startLeftOffsetTransition(() => {
          setLeftOffset(leftOffset);
        });
      }

      updateLeftOffset();
      window.addEventListener('resize', updateLeftOffset);

      return () => {
        window.removeEventListener('resize', updateLeftOffset);
      };
    },
    [setLeftOffset],
  );

  return (
    <Box mx="auto" px="32px" w="100%" maxW="1920px" h="100%" {...otherProps}>
      <Box ref={childrenContainerRef} h="100%">
        {children}
      </Box>
    </Box>
  );
};

/**
 * Get offset from page left boundary to targetElement left boundary
 */
function getOffsetLeft(targetElement: HTMLElement | null): number {
  let offsetLeft = 0;
  let element: HTMLElement | null = targetElement;
  while (element) {
    offsetLeft += element.offsetLeft;
    element = element.offsetParent as HTMLElement | null;
  }
  return offsetLeft;
}
