import { MessageSchemaType } from '@/services/messages/schemas';

import { Container } from './Container';
import { FeatureCard } from './FeatureCard';

export type FullPageProps = {
  pages?: MessageSchemaType['pages'];
};

export const FullPage = ({ pages }: FullPageProps) => {
  if (!pages) return null;

  return (
    <>
      <Container size={pages.length}>
        {pages.map((page) => {
          return (
            <FeatureCard
              imageUrl={page.image}
              key={page.page}
              title={page.title}
              description={page.body_text}
              url={page.url}
              buttonText={page.link_text}
            />
          );
        })}
      </Container>
    </>
  );
};
