/**
 *  @see https://splashtop.atlassian.net/wiki/spaces/CS/pages/1176109287/User+Info+Query+API
 */
import axios from 'axios';

import { featureControl } from '@/feature/toggle';

import { checkResponse } from '../../utils';
import { generateSchema } from './generateSchema';
import { generateSchema_new } from './generateSchema_new';
import { QueryMap } from './types';

const method = 'POST';

const getUrl = () => '/api/web/v1/users/info_query';

const execute = <Q extends QueryMap>(query: Q) => {
  const schema = featureControl.getToggle('PCP_3490__Improve_infoQueryService') ? generateSchema_new(query) : generateSchema(query);

  return checkResponse(axios.request({ method, url: getUrl(), data: query }), schema);
};

export const infoQueryService = {
  method,
  getUrl,
  execute,
} as const;
