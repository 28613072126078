import {
  DownloadPageKind,
  DownloadPageKindValue,
  DownloadPageKindValueOption,
  QuestionnaireField,
  QuestionnaireFieldKind,
  SupportFormValueForAPI,
} from '@/services/serviceDesk/supportForm/types';

export const SupportFormScenarioMap = {
  endUser: 'end-user',
  pcp: 'pcp',
  preview: 'preview',
} as const;

export type SupportFormScenario = (typeof SupportFormScenarioMap)[keyof typeof SupportFormScenarioMap];

export type SupportFormMode = 'create' | 'edit' | 'clone'; // only in PCP scenarios

export const DownloadPageKindOptions = {
  SPLASHTOP_CLASSIC: '1',
  HIDE_SPLASHTOP_BRAND: '2',
} as const;

export const DOWNLOAD_PAGE_KIND_MAP: Record<DownloadPageKindValueOption, DownloadPageKindValue> = {
  1: DownloadPageKind.SPLASHTOP_CLASSIC,
  2: DownloadPageKind.HIDE_SPLASHTOP_BRAND,
};

export type AddFieldKind = Pick<typeof QuestionnaireFieldKind, 'shortText' | 'comboBox' | 'longText' | 'radioButton' | 'checkbox'>;

export type AddFieldKindValues = AddFieldKind[keyof AddFieldKind];

export type SupportFormValue = Omit<SupportFormValueForAPI, 'technician_id' | 'download_page_kind' | 'questionnaire'> & {
  technician_id: string;
  download_page_kind: DownloadPageKindValueOption;
  questionnaire: Array<QuestionnaireField>;
};
