import { useRouter } from 'next/router';
import { useEffect } from 'react';

import * as Sentry from '@sentry/nextjs';
import { QueryKey, useQuery } from '@tanstack/react-query';
import chunk from 'lodash/chunk';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useRippleFlashMessage } from '@/design';
import { useTeamId } from '@/models/TeamInformation';
import { listEmmPolicyServersService } from '@/services/teams/emm_policies';

import { usePoliciesQuery } from './usePoliciesQuery';

const policiesSelect = <T>(data: T): T => data;

type ServerPolicyList = z.infer<typeof listEmmPolicyServersService.responseSchema>;

type UseServerPolicyListQuery<T> = {
  select?: (data: ServerPolicyList) => T;
  enabled?: boolean;
  queryKeys?: QueryKey;
};

export const useServerPolicyListQuery = <TData = ServerPolicyList>({
  select,
  enabled = true,
  queryKeys = [],
}: UseServerPolicyListQuery<TData>) => {
  const policiesQuery = usePoliciesQuery({ select: policiesSelect, enabled });
  const teamId = useTeamId();
  const queryKey = ['listEmmPolicyServers', teamId, policiesQuery.data, ...queryKeys];

  const { flashMessage } = useRippleFlashMessage();
  const { t } = useTranslation();
  const router = useRouter();

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      if (!policiesQuery.data) {
        return [] as ServerPolicyList;
      }
      const chunks = chunk(policiesQuery.data ?? [], 5000);
      const results = await Promise.all(
        chunks.map((chunk) =>
          listEmmPolicyServersService.execute(
            teamId,
            chunk.map((policy) => policy.id),
          ),
        ),
      );
      return results.flat();
    },
    enabled: Boolean(policiesQuery.data),
    select,
  });

  useEffect(
    function genericErrorHandler() {
      if (!query.error) {
        return;
      }
      if (listEmmPolicyServersService.errorHandling.resourceNotExist(query.error)) {
        flashMessage({ variant: 'error', title: t('common:failed_to_get_data') });
        setTimeout(() => {
          router.push('/w/policy');
        });
        return;
      }
      if (
        listEmmPolicyServersService.errorHandling.memberDisabled(query.error) ||
        listEmmPolicyServersService.errorHandling.notMatchAnyProduct(query.error) ||
        listEmmPolicyServersService.errorHandling.roleNotSupported(query.error) ||
        listEmmPolicyServersService.errorHandling.userHasNoTeam(query.error)
      ) {
        flashMessage({ variant: 'error', title: t('common:failed_to_get_data') });
        setTimeout(() => {
          window.location.href = '/';
        }, 2000);
        return;
      }
      // Unexpected error
      Sentry.captureException(query.error);
      flashMessage({ id: 'policy-list-query', variant: 'error', title: t('common:failed_to_get_data') });
    },
    [flashMessage, query.error, router, t],
  );

  return {
    queryKey,
    ...query,
  };
};
