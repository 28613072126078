import React from 'react';

import { CSSWithMultiValues } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleBanner } from '@/design';

export type ComputerNoResultAlertsProps = {
  isLoading: boolean;
  haveFilterApplied: boolean;
  haveSearchApplied: boolean;
  isEmpty: boolean;
  selectedComputerCount: number;
  onShowAll: () => void;
  sx?: CSSWithMultiValues;
};

export const ComputerNoResultAlerts = (props: ComputerNoResultAlertsProps) => {
  const { t } = useTranslation();
  const { isLoading, haveFilterApplied, haveSearchApplied, isEmpty, selectedComputerCount, onShowAll, sx = {} } = props;

  if (isLoading || !isEmpty) {
    return null;
  }

  let title = '';
  const haveSelectedComputers = selectedComputerCount !== 0;
  let button: { name: string; onClick: () => void } | undefined = {
    name: t('computer:selector.showAll'),
    onClick: onShowAll,
  };

  if (haveFilterApplied && haveSearchApplied) {
    title = haveSelectedComputers
      ? t('computer:selector.noAnyResult', { count: selectedComputerCount })
      : t('computer:selector.noAnyResultAndNoSelected');
  } else if (haveFilterApplied) {
    title = haveSelectedComputers
      ? t('computer:selector.noFilterResult', { count: selectedComputerCount })
      : t('computer:selector.noFilterResultAndNoSelected');
  } else if (haveSearchApplied) {
    title = haveSelectedComputers
      ? t('computer:selector.noSearchResult', { count: selectedComputerCount })
      : t('computer:selector.noSearchResultAndNoSelected');
  } else {
    title = t('computer:selector.noComputers');
    button = undefined;
  }

  return (
    <RippleBanner
      sx={{
        mt: '16px',
        mb: '30px',
        '.button': {
          textTransform: 'capitalize',
        },
        ...sx,
      }}
      variant="alert"
      button={button}
    >
      {title}
    </RippleBanner>
  );
};
