import { useTranslation } from 'next-i18next';

import { PageFooterBase } from '@/showcase/Layout';
import { AdRollTrackingCode } from '@/showcase/Layout/PageFooter/AdRollTrackingCode';
import { useDownloadUrl } from '@/showcase/Layout/PageFooter/hooks/useDownloadUrl';

export const PageFooter = ({ marginTop }: { marginTop?: string }) => {
  const { t } = useTranslation();
  const downloadUrl = useDownloadUrl();

  const itemList = [
    { label: t('common:footer.faq'), url: 'https://support-splashtopbusiness.splashtop.com/home', title: 'FAQ' },
    { label: t('common:footer.downloads'), url: downloadUrl, title: 'Download' },
    { label: t('common:footer.terms'), url: 'https://www.splashtop.com/terms/splashtop', title: 'Terms' },
    { label: t('common:footer.privacy'), url: 'https://www.splashtop.com/privacy', title: 'Privacy' },
    { label: t('common:footer.status'), url: 'https://status.splashtop.com', title: 'Status' },
    { label: t('common:footer.referralProgram'), url: '/splashtop_referral', title: 'Referral Program' },
    {
      label: t('common:footer.survey'),
      url: 'https://marketing.splashtop.com/acton/media/3744/splashtop-customer-satisfaction-survey',
      title: 'Survey',
    },
  ];

  return (
    <>
      <PageFooterBase
        itemList={itemList}
        containerStyles={{ marginTop: marginTop ?? '64px' }}
        copyrightText={`Copyright © ${new Date().getFullYear()} Splashtop Inc. `}
      />
      <AdRollTrackingCode />
    </>
  );
};
