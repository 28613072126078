import { useMemo } from 'react';

import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { GetPluginFunc, OTablePlugin, OTableSourceGroup } from '@/components/ObservableTable/types';
import { OTableSortByList, OTableSortStateValues, OTableSortStates } from '@/components/ObservableTable/useObservableSort';
import { OTableGroupSortProps, makeGroupSortPipe } from '@/components/ObservableTable/utils/groupSort';

export type OTableOnToggleGroupSort = (accessor: string, options?: { sortByDesc?: boolean; mustSort?: boolean }) => void;

export type OTableGroupSortParams = {
  sortBy: OTableSortByList;
  sortFunc?: OTableGroupSortProps<OTableSourceGroup>['sortFunc'];
};

type UseObservableGroupSortProps = {
  initSortBy?: OTableSortByList;
  sortFunc?: OTableGroupSortProps<OTableSourceGroup>['sortFunc'];
};

function useObservableGroupSort(props: UseObservableGroupSortProps = {}) {
  return useMemo(() => {
    const { initSortBy, sortFunc } = props;
    const initParams: OTableGroupSortParams = {
      sortBy: initSortBy ?? [],
      sortFunc,
    };

    const groupSortSubject = new BehaviorSubject<OTableGroupSortParams>(initParams);

    const getPlugin: GetPluginFunc = (tableResult) => groupSortSubject.pipe(map(makeGroupSortPipe(tableResult)));

    const onToggleGroupSort: OTableOnToggleGroupSort = (accessor, options = {}) => {
      const currentValue = groupSortSubject.getValue();
      const sortIndex = currentValue.sortBy.findIndex((sort) => sort.accessor === accessor);

      let nextSortBy: OTableSortByList = [];
      if (sortIndex === -1) {
        nextSortBy = [
          {
            accessor,
            desc: Boolean(options.sortByDesc),
          },
        ];
      } else {
        const currentSort = currentValue.sortBy[sortIndex];

        if (options.sortByDesc !== undefined) {
          const nextSort = {
            ...currentSort,
            desc: options.sortByDesc,
          };
          nextSortBy = [nextSort];
        } else if (!currentSort.desc) {
          const nextSort = {
            ...currentSort,
            desc: true,
          };
          nextSortBy = [nextSort];
        } else if (options.mustSort) {
          const nextSort = {
            ...currentSort,
            desc: false,
          };
          nextSortBy = [nextSort];
        }
      }

      groupSortSubject.next({
        ...currentValue,
        sortBy: nextSortBy,
      });
    };

    const onToggleGroupManySort = (accessor: string) => {
      const currentValue = groupSortSubject.getValue();
      const sortIndex = currentValue.sortBy.findIndex((sort) => sort.accessor === accessor);

      const nextSortBy = [...currentValue.sortBy];
      if (sortIndex === -1) {
        nextSortBy.push({
          accessor,
          desc: false,
        });
      } else {
        const currentSort = currentValue.sortBy[sortIndex];

        if (!currentSort.desc) {
          const nextSort = {
            ...currentSort,
            desc: true,
          };
          nextSortBy[sortIndex] = nextSort;
        } else {
          nextSortBy.splice(sortIndex, 1);
        }
      }

      groupSortSubject.next({
        ...currentValue,
        sortBy: nextSortBy,
      });
    };

    const getGroupSort = (accessor: string): OTableSortStateValues => {
      const currentValue = groupSortSubject.getValue();
      const sort = currentValue.sortBy.find((sort) => sort.accessor === accessor);

      if (sort === undefined) {
        return OTableSortStates.UNDEFINED;
      } else if (sort?.desc) {
        return OTableSortStates.DESC;
      } else {
        return OTableSortStates.ASC;
      }
    };

    const groupSortPlugin: OTablePlugin = {
      type: 'groupSort' as const,
      getPlugin,
    };

    return {
      groupSortPlugin,
      onToggleGroupSort,
      onToggleGroupManySort,
      getGroupSort,
    };
  }, []); // eslint-disable-line
}

export default useObservableGroupSort;
