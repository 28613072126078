import { OTableGroup, OTableResult, OTableRow, OTableSourceData, OTableSourceGroup } from '@/components/ObservableTable/types';
import { FilterParams } from '@/components/ObservableTable/useObservableFilter';
import { OTableRowTypes, OTableViewStates } from '@/components/ObservableTable/useObservableTable';

function getGroupAndRowKey(gid: string, rowId: string) {
  return `${gid}-${rowId}`;
}
export const makeFilterPipe =
  <D extends OTableSourceData, G extends OTableSourceGroup>(tableResult: OTableResult<D, G>) =>
  (filterParams: FilterParams): OTableResult<D, G> => {
    const { rows, groupHashMap, states } = tableResult;
    const { filters } = filterParams;

    const rowFilters = filters.filter((filter) => filter.type === OTableRowTypes.ROW);
    const groupFilters = filters.filter((filter) => filter.type === OTableRowTypes.GROUP);

    const filterGroupHashMap = rows
      .filter((row): row is OTableGroup<G> => {
        if (row.type === OTableRowTypes.ROW) {
          return false;
        }

        return groupFilters.every((filter) => {
          const value = (row.original as Record<string, unknown>)[filter.accessor];

          return filter.matches.some((match) => match === value);
        });
      })
      .reduce<Record<string, true>>((acc, row) => {
        const gid = row.original.id;
        acc[gid] = true;

        groupHashMap?.[gid]?.rowIds?.forEach((rowId) => {
          acc[getGroupAndRowKey(gid, rowId)] = true;
        });

        return acc;
      }, {});

    const filterRowHashMap = rows
      .filter((row): row is OTableRow<D> => {
        if (row.type === OTableRowTypes.GROUP) {
          return false;
        }

        return rowFilters.every((filter) => {
          const value = (row.original as Record<string, unknown>)[filter.accessor];

          return filter.matches.some((match) => match === value);
        });
      })
      .reduce<Record<string, true>>((acc, row) => {
        acc[row.original.id] = true;
        return acc;
      }, {});

    const nextRows = rows.reduce(
      (all, row) => {
        let isMatched = false;
        if (row.type === OTableRowTypes.GROUP) {
          const gid = row.original.id;

          const rowIds = groupHashMap?.[gid]?.rowIds;

          const isFilterInGroup = groupFilters.length === 0 || filterGroupHashMap[gid];
          const isFilterInRows = rowFilters.length === 0 || (rowIds && rowIds.some((id) => filterRowHashMap[id]));

          isMatched = Boolean(isFilterInGroup && isFilterInRows);
        } else {
          const { id, gid } = row.original;

          isMatched =
            (states.viewState !== OTableViewStates.GROUP || filterGroupHashMap[getGroupAndRowKey(gid ?? '', id)]) && filterRowHashMap[id];
        }

        if (isMatched) {
          const isFiltered = groupFilters.length > 0 || rowFilters.length > 0 ? true : null;
          all.push({
            ...row,
            isFiltered: row.isFiltered || Boolean(isFiltered),
            filterMatches: {
              ...row.filterMatches,
              isFiltered,
            },
          });
        }

        return all;
      },
      [] as OTableResult<D, G>['rows'],
    );

    return {
      ...tableResult,
      rows: nextRows,
    };
  };
