import { useRouter } from 'next/router';
import { useEffect } from 'react';

import * as Sentry from '@sentry/nextjs';
import { QueryKey, useQuery } from '@tanstack/react-query';
import chunk from 'lodash/chunk';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useRippleFlashMessage } from '@/design';
import { useTeamId } from '@/models/TeamInformation';
import { listEmmPolicyGroupsService } from '@/services/teams/emm_policies';

import { usePoliciesQuery } from './usePoliciesQuery';

type GroupPolicyList = z.infer<typeof listEmmPolicyGroupsService.responseSchema>;

type UseGroupPolicyListQueryProps<T> = {
  select?: (data: GroupPolicyList) => T;
  queryKeys?: QueryKey;
  enabled?: boolean;
};

const policiesSelect = <T>(data: T): T => data;

export const useGroupPolicyListQuery = <TData = GroupPolicyList>({
  select,
  enabled = true,
  queryKeys = [],
}: UseGroupPolicyListQueryProps<TData> = {}) => {
  const teamId = useTeamId();
  const { flashMessage } = useRippleFlashMessage();
  const { t } = useTranslation();
  const router = useRouter();
  const policiesQuery = usePoliciesQuery({ select: policiesSelect });
  const queryKey = ['listEmmPolicyGroups', teamId, policiesQuery.data, ...queryKeys];
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const chunks = chunk(policiesQuery.data, 5000);
      const results = await Promise.all(
        chunks.map((chunk) =>
          listEmmPolicyGroupsService.execute(
            teamId,
            chunk.map((policy) => policy.id),
          ),
        ),
      );
      return results.flat();
    },
    enabled: Boolean(policiesQuery.data) && enabled,
    select,
  });

  useEffect(
    function genericErrorHandler() {
      if (!query.error) {
        return;
      }
      if (listEmmPolicyGroupsService.errorHandling.resourceNotExist(query.error)) {
        flashMessage({ id: 'failed_to_get_data', variant: 'error', title: t('common:failed_to_get_data') });
        setTimeout(() => {
          router.push('/w/policy');
        }, 2000);
        return;
      }
      if (
        listEmmPolicyGroupsService.errorHandling.userHasNoTeam(query.error) ||
        listEmmPolicyGroupsService.errorHandling.memberDisabled(query.error) ||
        listEmmPolicyGroupsService.errorHandling.teamSeatExpired(query.error) ||
        listEmmPolicyGroupsService.errorHandling.roleNotSupported(query.error) ||
        listEmmPolicyGroupsService.errorHandling.notMatchAnyProduct(query.error)
      ) {
        flashMessage({ id: 'failed_to_get_data', variant: 'error', title: t('common:failed_to_get_data') });
        setTimeout(() => {
          window.location.href = '/';
        }, 2000);
        return;
      }
      // Unexpected error
      Sentry.captureException(query.error);
      flashMessage({ id: 'failed_to_get_data', variant: 'error', title: t('common:failed_to_get_data') });
    },
    [flashMessage, query.error, router, t],
  );

  return {
    queryKey,
    ...query,
  };
};
