import { useMemo } from 'react';

import { BehaviorSubject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { GetPluginFunc, OTablePlugin } from '@/components/ObservableTable/types';
import {
  UpdateSelected,
  makeFilterSelectedGroupPipe,
  makeSelectionPipe,
  makeUpdateSelectedGroupPipe,
} from '@/components/ObservableTable/utils/selection';

export type SelectedRowIds = Record<string, boolean>;
export type SelectedGroupIds = Record<string, boolean>;

export type SelectionParams = {
  selectedRowIds: SelectedRowIds;
  selectedGroupIds: SelectedGroupIds;
  prevSelectedRowIds: SelectedRowIds;
  prevSelectedGroupIds: SelectedGroupIds;
  isSelectedOnly: boolean;
};

type UseObservableSelectionProps = {
  initSelectedRowIds?: SelectedRowIds;
  initSelectedGroupIds?: SelectedGroupIds;
  initIsSelectedOnly?: boolean;
};

type OnToggleSelectedOnly = (isSelectedOnly?: boolean) => void;
export type OTableSelectedIds = Pick<SelectionParams, 'selectedRowIds' | 'selectedGroupIds'>;
type UseObservableSelectionResult = {
  selectionPlugin: OTablePlugin;
  updateSelected: UpdateSelected;
  getSelectedRowCount: () => number;
  getSelectedIds: () => OTableSelectedIds;
  onToggleSelectedOnly: OnToggleSelectedOnly;
  onCancelSelectedOnly: () => void;
  getIsSelectedOnly: () => boolean;
};

function useObservableSelection(props: UseObservableSelectionProps = {}): UseObservableSelectionResult {
  return useMemo(() => {
    const { initSelectedRowIds, initSelectedGroupIds, initIsSelectedOnly } = props;
    const initRowIds = initSelectedRowIds ?? {};
    const initGroupIds = initSelectedGroupIds ?? {};
    const initParams: SelectionParams = {
      selectedRowIds: initRowIds,
      selectedGroupIds: initGroupIds,
      prevSelectedRowIds: initIsSelectedOnly ? initRowIds : {},
      prevSelectedGroupIds: initIsSelectedOnly ? initGroupIds : {},
      isSelectedOnly: initIsSelectedOnly ?? false,
    };

    const selectionSubject = new BehaviorSubject<SelectionParams>(initParams);
    const updateSelected: UpdateSelected = (selectedRowIds: SelectedRowIds, selectedGroupIds?: SelectedGroupIds) => {
      const value = selectionSubject.getValue();
      const nextValue = {
        ...value,
        selectedRowIds,
      };

      if (selectedGroupIds) {
        nextValue.selectedGroupIds = selectedGroupIds;
      }

      if (value.isSelectedOnly) {
        const nextPrevSelectedRowIds = { ...value.prevSelectedRowIds };

        Object.keys(selectedRowIds).forEach((rowId) => {
          if (selectedRowIds[rowId]) {
            nextPrevSelectedRowIds[rowId] = true;
          }
        });

        nextValue.prevSelectedRowIds = nextPrevSelectedRowIds;

        if (selectedGroupIds) {
          const nextPrevSelectedGroupIds = { ...value.prevSelectedGroupIds };

          Object.keys(selectedGroupIds).forEach((groupId) => {
            if (selectedGroupIds[groupId]) {
              nextPrevSelectedGroupIds[groupId] = true;
            }
          });

          nextValue.prevSelectedGroupIds = nextPrevSelectedGroupIds;
        }
      }

      selectionSubject.next(nextValue);
    };

    const getPlugin: GetPluginFunc = (tableResult) =>
      selectionSubject.pipe(
        tap(makeUpdateSelectedGroupPipe(tableResult, { updateSelected })),
        filter(makeFilterSelectedGroupPipe(tableResult)),
        map(makeSelectionPipe(tableResult, { updateSelected })),
      );

    const getSelectedIds: () => OTableSelectedIds = () => {
      const value = selectionSubject.getValue();

      return {
        selectedRowIds: value.selectedRowIds,
        selectedGroupIds: value.selectedGroupIds,
      };
    };

    const getSelectedRowCount = () => {
      const { selectedRowIds } = selectionSubject.getValue();

      return Object.keys(selectedRowIds).filter((rowId) => selectedRowIds[rowId]).length;
    };

    const onToggleSelectedOnly: OnToggleSelectedOnly = (isSelectedOnly) => {
      const value = selectionSubject.getValue();
      const nextIsSelectedOnly = typeof isSelectedOnly === 'boolean' ? isSelectedOnly : !value.isSelectedOnly;

      const nextValue = {
        ...value,
        isSelectedOnly: nextIsSelectedOnly,
      };

      if (nextIsSelectedOnly) {
        nextValue.prevSelectedRowIds = value.selectedRowIds;
        nextValue.prevSelectedGroupIds = value.selectedGroupIds;
      }
      selectionSubject.next(nextValue);
    };

    const onCancelSelectedOnly = () => {
      const value = selectionSubject.getValue();

      selectionSubject.next({
        ...value,
        isSelectedOnly: false,
      });
    };

    const getIsSelectedOnly = () => {
      const value = selectionSubject.getValue();

      return value.isSelectedOnly;
    };

    const selectionPlugin: OTablePlugin = {
      type: 'selection' as const,
      getPlugin,
    };

    return {
      selectionPlugin,
      updateSelected,
      getSelectedIds,
      getSelectedRowCount,
      onToggleSelectedOnly,
      onCancelSelectedOnly,
      getIsSelectedOnly,
    };
  }, []); // eslint-disable-line
}

export default useObservableSelection;
