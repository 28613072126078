import { Box } from '@chakra-ui/react';

import NavigationIframe, { NAV_TYPES, NavTypeKeys } from '@/components/Navigation/NavigationIframe';
import useTeamInformation from '@/utils/useTeamInformation';

export const useNavType = () => {
  const teamInformation = useTeamInformation('sos');

  let navType: NavTypeKeys = NAV_TYPES.NORMAL;
  if (teamInformation) {
    const { currentTeam, plan, memberStatus } = teamInformation;
    const isSOS3 = plan === 'plus';
    const isSOS4 = plan === 'enterprise';
    const isSOSUnlimited = plan === 'unlimited';
    const isNotInvited = memberStatus !== 'invited';

    if (currentTeam === 'sos' && (isSOS3 || isSOS4 || isSOSUnlimited) && isNotInvited) {
      navType = NAV_TYPES.WITH_SUBTITLE;
    }
  }

  return navType;
};

export const PageHeader = () => {
  const navType = useNavType();

  return (
    <Box as="header" height="55px">
      <NavigationIframe navType={navType} />
    </Box>
  );
};
