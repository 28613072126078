import React from 'react';

import { RippleSkeleton, RippleTrLegacy } from '@/design';

export type ComputerTableLoadingProps = {
  width: number;
};

export const ComputerTableLoading = ({ width }: ComputerTableLoadingProps) => {
  return (
    <>
      {Array(3)
        .fill(null)
        .map((_, index) => {
          return (
            <RippleTrLegacy key={index}>
              <RippleSkeleton width={`${width}px`} my="5px" />
            </RippleTrLegacy>
          );
        })}
    </>
  );
};
