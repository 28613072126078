import { useTranslation } from 'next-i18next';

import { COPY_RIGHT } from '@/constants';
import { PageFooterBase } from '@/showcase/Layout';

export const Footer = () => {
  const { t, i18n } = useTranslation();

  const isRTL = i18n.language === 'he';

  const termLabel = isRTL ? 'תנאי השירות' : t('common:footer.termsOfService');
  const privacyPolicyLabel = isRTL ? 'מדיניות פרטיות' : t('common:footer.privacyPolicy');

  const itemList = [
    { label: termLabel, url: 'https://www.splashtop.com/terms/splashtop', title: termLabel },
    { label: privacyPolicyLabel, url: 'https://www.splashtop.com/privacy', title: privacyPolicyLabel },
  ];

  return <PageFooterBase itemList={itemList} containerStyles={{ bg: 'transparent' }} copyrightText={COPY_RIGHT} />;
};
