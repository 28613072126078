import { format } from 'date-fns';

import type { Inventory, Software } from '../types';
import { fromMBtoGB, fromMHzToGHz, validateSerialNumber } from '../utils';
import type { ExportType } from './types';

const exportTypeStringMap: Record<ExportType, string> = {
  system: 'System_Hardware',
  software: 'Software',
};

export function generateFileName(exportType: ExportType) {
  const exportTypeString = exportTypeStringMap[exportType];
  const currentTimeString = format(new Date(), 'yyyyMMddHHMMSS');
  return `Computer_${exportTypeString}_Inventory-${currentTimeString}.csv`;
}

export function replaceNullValue<T extends object>(
  obj: T,
): {
  [P in keyof T]: T[P] extends null ? undefined : NonNullable<T[P]>;
} {
  // TODO: better typing
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      const newValue = value === null ? undefined : value;
      return [key, newValue];
    }),
  ) as unknown as {
    [P in keyof T]: T[P] extends null ? undefined : NonNullable<T[P]>;
  };
}

export type Column<T> = { header: string; transform: (item: T) => string };
type SoftwareItem = Partial<Software> & { report_time: string; computer_name: string; group_id?: number };

export interface ExtractInventory<T> {
  columns: Array<Column<T>>;
  transformRawData?(rawData: Array<unknown>): Array<T>;
}

const transformInventory = {
  getCpu(inventory: Partial<Inventory>): {
    cpu: string;
    cpu_speed: string;
    cpu_slot: string;
    cpu_core: string;
    cpu_processor: string;
  } {
    if (inventory.hardware?.processor) {
      const { type, speed, slot, core, processor } = inventory.hardware.processor.reduce<{
        type: Array<string>;
        speed: Array<string>;
        slot: Array<string>;
        core: Array<string>;
        processor: Array<string>;
      }>(
        (acc, cur) => {
          const { type, speed, slot, number_of_cores, number_of_logical_processors } = cur;
          acc.type.push(type ?? '');
          acc.speed.push(String(speed ? fromMHzToGHz(speed) : ''));
          acc.slot.push(String(slot ?? ''));
          acc.core.push(String(number_of_cores ?? ''));
          acc.processor.push(String(number_of_logical_processors ?? ''));
          return acc;
        },
        {
          type: [],
          speed: [],
          slot: [],
          core: [],
          processor: [],
        },
      );

      return {
        cpu: type.join(','),
        cpu_speed: speed.join(','),
        cpu_slot: slot.join(','),
        cpu_core: core.join(','),
        cpu_processor: processor.join(','),
      };
    }
    return { cpu: '', cpu_speed: '', cpu_slot: '', cpu_core: '', cpu_processor: '' };
  },
  getMemory(inventory: Partial<Inventory>): {
    total_ram_size: string;
    memory_manufacturer: string;
    memory_size: string;
    memory_speed: string;
    memory_slot: string;
  } {
    const total_ram_size = inventory.hardware?.memory?.size ? String(fromMBtoGB(inventory.hardware.memory.size)) : '';

    const { manufacturer, size, speed, slot } = inventory.hardware?.memory?.modules?.reduce<{
      manufacturer: Array<string>;
      size: Array<string>;
      speed: Array<string>;
      slot: Array<string>;
    }>(
      (acc, cur) => {
        const { manufacturer = '', size, speed, slot = '' } = cur;
        acc.manufacturer.push(manufacturer);
        acc.size.push(String(size ? fromMBtoGB(size) : ''));
        acc.speed.push(String(speed ?? ''));
        acc.slot.push(slot);

        return acc;
      },
      { manufacturer: [], size: [], speed: [], slot: [] },
    ) ?? { manufacturer: [], size: [], speed: [], slot: [] };

    return {
      total_ram_size,
      memory_manufacturer: manufacturer.join(','),
      memory_size: size.join(','),
      memory_speed: speed.join(','),
      memory_slot: slot.join(','),
    };
  },
  getStorage(inventory: Partial<Inventory>): {
    drive_name: string;
    drive_capacity: string;
    drive_type: string;
    drive_serial_number: string;
    partition: string;
    mount_point: string;
    partition_name: string;
    partition_file_system: string;
    partition_free_space: string;
    partition_capacity: string;
    total_free_space: string;
    total_storage: string;
  } {
    type PartitionMap = Record<
      string,
      {
        mount_point: string;
        partition_name: string;
        partition_file_system: string;
        partition_free_space: number;
        partition_capacity: number;
      }
    >;
    const {
      name,
      capacity,
      type,
      serial_number,
      partitionMap = {},
    } = inventory.hardware?.drive?.reduce<{
      name: Array<string>;
      capacity: Array<string>;
      type: Array<string>;
      serial_number: Array<string>;
      partitionMap: PartitionMap;
    }>(
      (acc, cur) => {
        const { name = '', capacity, type = '', serial_number = '', partition = [] } = cur;

        acc.name.push(name);
        acc.capacity.push(typeof capacity === 'number' ? String(fromMBtoGB(capacity)) : '');
        acc.type.push(type);
        acc.serial_number.push(serial_number);

        const newPartitionMap = { ...acc.partitionMap };
        partition.forEach(({ drive = '', name = '', mount_point = '', file_system = '', free_space = 0, capacity = 0 }) => {
          if (newPartitionMap[drive]) {
            newPartitionMap[drive].partition_free_space += free_space;
            newPartitionMap[drive].partition_capacity += capacity;
          } else {
            newPartitionMap[drive] = {
              mount_point,
              partition_name: name,
              partition_file_system: file_system,
              partition_free_space: free_space,
              partition_capacity: capacity,
            };
          }
        });
        acc.partitionMap = newPartitionMap;

        return acc;
      },
      {
        name: [],
        capacity: [],
        type: [],
        serial_number: [],
        partitionMap: {},
      },
    ) ?? {
      name: [],
      capacity: [],
      type: [],
      serial_number: [],
      partitionMap: {},
    };

    const {
      partition,
      mount_point,
      partition_name,
      partition_file_system,
      partition_free_space,
      partition_capacity,
      all_partition_free_space,
      all_partition_storage,
    } = Object.entries(partitionMap).reduce<{
      partition: Array<string>;
      mount_point: Array<string>;
      partition_name: Array<string>;
      partition_file_system: Array<string>;
      partition_free_space: Array<string>;
      partition_capacity: Array<string>;
      all_partition_free_space: number;
      all_partition_storage: number;
    }>(
      (acc, [partition, { mount_point, partition_name, partition_file_system, partition_free_space, partition_capacity }]) => {
        acc.partition.push(partition);
        acc.mount_point.push(mount_point);
        acc.partition_name.push(partition_name);
        acc.partition_file_system.push(partition_file_system);
        acc.partition_free_space.push(String(fromMBtoGB(partition_free_space)));
        acc.partition_capacity.push(String(fromMBtoGB(partition_capacity)));
        acc.all_partition_free_space += partition_free_space;
        acc.all_partition_storage += partition_capacity;
        return acc;
      },
      {
        partition: [],
        mount_point: [],
        partition_name: [],
        partition_file_system: [],
        partition_free_space: [],
        partition_capacity: [],
        all_partition_free_space: 0,
        all_partition_storage: 0,
      },
    );
    const total_free_space = String(fromMBtoGB(all_partition_free_space));
    const total_storage = String(fromMBtoGB(all_partition_storage));

    return {
      drive_name: name.join(','),
      drive_capacity: capacity.join(','),
      drive_type: type.join(','),
      drive_serial_number: serial_number.join(','),
      partition: partition.join(','),
      mount_point: mount_point.join(','),
      partition_name: partition_name.join(','),
      partition_file_system: partition_file_system.join(','),
      partition_free_space: partition_free_space.join(','),
      partition_capacity: partition_capacity.join(','),
      total_free_space,
      total_storage,
    };
  },
  getDisplay(inventory: Partial<Inventory>): {
    display_adapter: string;
    display_vram: string;
    display_resolution: string;
    display_slot: string;
  } {
    const { display_adapter, display_vram, display_resolution, display_slot } = inventory.hardware?.display_driver?.reduce<{
      display_adapter: Array<string>;
      display_vram: Array<string>;
      display_resolution: Array<string>;
      display_slot: Array<string>;
    }>(
      (acc, cur) => {
        const { type = '', vram, resolution = '', slot } = cur;
        acc.display_adapter.push(type);
        acc.display_vram.push(typeof vram === 'number' ? String(vram) : '');
        acc.display_resolution.push(resolution);
        acc.display_slot.push(typeof slot === 'number' ? String(slot) : '');

        return acc;
      },
      {
        display_adapter: [],
        display_vram: [],
        display_resolution: [],
        display_slot: [],
      },
    ) ?? {
      display_adapter: [],
      display_vram: [],
      display_resolution: [],
      display_slot: [],
    };

    return {
      display_adapter: display_adapter.join(','),
      display_vram: display_vram.join(','),
      display_resolution: display_resolution.join(','),
      display_slot: display_slot.join(','),
    };
  },
  getNetwork(inventory: Partial<Inventory>): {
    network_adaptor: string;
    mac_address: string;
    ip_address: string;
    subnet_mask: string;
    dhcp_server: string;
    default_gateway: string;
    speed: string;
  } {
    const { network_adaptor, mac_address, ip_address, subnet_mask, dhcp_server, default_gateway, speed } =
      inventory.hardware?.network?.interface?.reduce<{
        network_adaptor: Array<string>;
        mac_address: Array<string>;
        ip_address: Array<string>;
        subnet_mask: Array<string>;
        dhcp_server: Array<string>;
        default_gateway: Array<string>;
        speed: Array<string>;
      }>(
        (acc, cur) => {
          const { name = '', physical_address_mac, ip_address = [], subnet_mask = [], dhcp_server, default_gateway = '', speed } = cur;
          const ipAddressString = ip_address?.join(',') ?? '';
          const subnetMaskString = subnet_mask?.join(',') ?? '';
          const defaultGatewayString = default_gateway ?? '';

          acc.network_adaptor.push(name);
          acc.mac_address.push(physical_address_mac ?? '');
          acc.ip_address.push(ipAddressString);
          acc.subnet_mask.push(subnetMaskString);
          acc.dhcp_server.push(dhcp_server ?? '');
          acc.default_gateway.push(defaultGatewayString);
          acc.speed.push(String(speed ?? ''));

          // const old = {
          //   network_adaptor: joinString(acc.network_adaptor, name),
          //   mac_address: joinString(acc.mac_address, physical_address_mac ?? ''),
          //   ip_address: joinString(acc.ip_address, ipAddressString, ';'),
          //   subnet_mask: joinString(acc.subnet_mask, subnetMaskString, ';'),
          //   dhcp_server: joinString(acc.dhcp_server, dhcp_server ?? ''),
          //   default_gateway: joinString(acc.default_gateway, defaultGatewayString, ';'),
          //   speed: joinString(acc.speed, String(speed ?? '')),
          // };

          return acc;
        },
        {
          network_adaptor: [],
          mac_address: [],
          ip_address: [],
          subnet_mask: [],
          dhcp_server: [],
          default_gateway: [],
          speed: [],
        },
      ) ?? {
        network_adaptor: [],
        mac_address: [],
        ip_address: [],
        subnet_mask: [],
        dhcp_server: [],
        default_gateway: [],
        speed: [],
      };
    return {
      network_adaptor: network_adaptor.join(';'),
      mac_address: mac_address.join(';'),
      ip_address: ip_address.join(';'),
      subnet_mask: subnet_mask.join(';'),
      dhcp_server: dhcp_server.join(';'),
      default_gateway: default_gateway.join(';'),
      speed: speed.join(';'),
    };
  },
};

type TransformMap = Record<
  Inventory['id'],
  {
    cpu: ReturnType<typeof transformInventory.getCpu>;
    memory: ReturnType<typeof transformInventory.getMemory>;
    storage: ReturnType<typeof transformInventory.getStorage>;
    display: ReturnType<typeof transformInventory.getDisplay>;
    network: ReturnType<typeof transformInventory.getNetwork>;
  }
>;

export class ExtractSystemHardware implements ExtractInventory<Partial<Inventory>> {
  groupNameMap: Record<Inventory['group_id'], string>;
  transformMap: TransformMap = {};

  constructor(groupNameMap: Record<Inventory['group_id'], string>) {
    this.groupNameMap = groupNameMap;
  }

  get columns() {
    const transformMap = this.transformMap;

    const osBuildRelatedColumns: Array<Column<Partial<Inventory>>> = [
      {
        header: 'OS Build',
        transform: ({ os_build, os_build_rev }) => (os_build_rev ? `${os_build}(${os_build_rev})` : os_build ?? ''),
      },
      { header: 'Architecture', transform: ({ architecture }) => architecture ?? '' },
    ];

    const columns: Array<Column<Partial<Inventory>>> = [
      { header: 'Computer Name', transform: ({ server_name: computer_name = '' }) => computer_name },
      { header: 'Group', transform: ({ group_id }) => (typeof group_id === 'number' ? this.groupNameMap[group_id] : '') },
      { header: 'Report Time', transform: ({ local_time: report_time = '' }) => report_time },
      { header: 'OS', transform: ({ os_product, system }) => os_product ?? system?.operating_system ?? '' },
      ...osBuildRelatedColumns,
      { header: 'Device Name', transform: ({ system }) => system?.computer_name ?? '' },
      { header: 'Domain', transform: ({ system }) => system?.domain ?? '' },
      { header: 'Manufacturer', transform: ({ hardware }) => hardware?.manufacturer ?? '' },
      {
        header: 'Model / Product',
        transform: ({ hardware }) => (hardware?.product || hardware?.model) ?? '',
      },
      {
        header: 'Serial Number',
        transform: ({ hardware }) =>
          validateSerialNumber(hardware?.motherboard?.bios_serial_number) ||
          validateSerialNumber(hardware?.motherboard?.serial_number) ||
          validateSerialNumber(hardware?.serial_number) ||
          '',
      },
      { header: 'BIOS Version', transform: ({ hardware }) => hardware?.motherboard?.bios_version ?? '' },
      { header: 'CPU', transform: ({ id }) => (id ? transformMap[id].cpu.cpu : '') },
      { header: 'CPU Speed (GHz)', transform: ({ id }) => (id ? transformMap[id].cpu.cpu_speed : '') },
      { header: 'CPU Slot', transform: ({ id }) => (id ? transformMap[id].cpu.cpu_slot : '') },
      { header: 'CPU Core', transform: ({ id }) => (id ? transformMap[id].cpu.cpu_core : '') },
      { header: 'CPU Processor', transform: ({ id }) => (id ? transformMap[id].cpu.cpu_processor : '') },
      {
        header: 'Total RAM (GB)',
        transform: ({ id, os, hardware }) => {
          if (os === 'Android') {
            return String(fromMBtoGB(hardware?.ram?.total));
          }
          return id ? transformMap[id].memory.total_ram_size : '';
        },
      },
      { header: 'Memory Manufacturer', transform: ({ id }) => (id ? transformMap[id].memory.memory_manufacturer : '') },
      {
        header: 'Memory Size (GB)',
        transform: ({ id, os, hardware }) => {
          if (os === 'Android') {
            return String(fromMBtoGB(hardware?.ram?.total));
          }
          return id ? transformMap[id].memory.memory_size : '';
        },
      },
      { header: 'Memory Speed (MHz)', transform: ({ id }) => (id ? transformMap[id].memory.memory_speed : '') },
      { header: 'Memory Slot', transform: ({ id }) => (id ? transformMap[id].memory.memory_slot : '') },
      { header: 'Motherboard BIOS', transform: ({ hardware }) => hardware?.motherboard?.bios_version ?? '' },
      { header: 'Motherboard Chipset', transform: ({ hardware }) => hardware?.motherboard?.chipset ?? '' },
      { header: 'Motherboard Memory Slot', transform: ({ hardware }) => hardware?.motherboard?.memory_slots?.toString() ?? '' },
      {
        header: 'Motherboard SN',
        transform: ({ hardware }) =>
          validateSerialNumber(hardware?.motherboard?.bios_serial_number) ||
          validateSerialNumber(hardware?.motherboard?.serial_number) ||
          validateSerialNumber(hardware?.serial_number) ||
          '',
      },
      {
        header: 'Total Free Space (GB)',
        transform: ({ id, os, hardware }) => {
          if (os === 'Android') {
            return String(hardware?.rom?.free ?? '0');
          }
          return id ? transformMap[id].storage.total_free_space : '';
        },
      },
      {
        header: 'Total Storage (GB)',
        transform: ({ id, os, hardware }) => {
          if (os === 'Android') {
            return String(hardware?.rom?.total ?? '0');
          }
          return id ? transformMap[id].storage.total_storage : '';
        },
      },
      { header: 'Drive', transform: ({ id }) => (id ? transformMap[id].storage.drive_name : '') },
      {
        header: 'Drive Capacity (GB)',
        transform: ({ id, os, hardware }) => {
          if (os === 'Android') {
            return String(typeof hardware?.rom?.total === 'number' ? hardware?.rom?.total : '');
          }
          return id ? transformMap[id].storage.drive_capacity : '';
        },
      },
      { header: 'Drive Type', transform: ({ id }) => (id ? transformMap[id].storage.drive_type : '') },
      {
        header: 'Drive Serial Number',
        transform: ({ id }) => (id ? transformMap[id].storage.drive_serial_number : ''),
      },
      { header: 'Partition', transform: ({ id }) => (id ? transformMap[id].storage.partition : '') },
      { header: 'Mount Point', transform: ({ id }) => (id ? transformMap[id].storage.mount_point : '') },
      { header: 'Partition Name', transform: ({ id }) => (id ? transformMap[id].storage.partition_name : '') },
      {
        header: 'Partition File System',
        transform: ({ id }) => (id ? transformMap[id].storage.partition_file_system : ''),
      },
      {
        header: 'Partition Free Space (GB)',
        transform: ({ id }) => (id ? transformMap[id].storage.partition_free_space : ''),
      },
      {
        header: 'Partition Capacity (GB)',
        transform: ({ id }) => (id ? transformMap[id].storage.partition_capacity : ''),
      },
      { header: 'Display Adapter', transform: ({ id }) => (id ? transformMap[id].display.display_adapter : '') },
      { header: 'Display VRAM (MB)', transform: ({ id }) => (id ? transformMap[id].display.display_vram : '') },
      { header: 'Display Resolution', transform: ({ id }) => (id ? transformMap[id].display.display_resolution : '') },
      { header: 'Display Slot', transform: ({ id }) => (id ? transformMap[id].display.display_slot : '') },
      { header: 'Network Adaptor', transform: ({ id }) => (id ? transformMap[id].network.network_adaptor : '') },
      { header: 'MAC Address', transform: ({ id }) => (id ? transformMap[id].network.mac_address : '') },
      { header: 'LAN IP Address', transform: ({ id }) => (id ? transformMap[id].network.ip_address : '') },
      { header: 'Subnet Mask', transform: ({ id }) => (id ? transformMap[id].network.subnet_mask : '') },
      { header: 'DHCP Server', transform: ({ id }) => (id ? transformMap[id].network.dhcp_server : '') },
      { header: 'Default Gateway', transform: ({ id }) => (id ? transformMap[id].network.default_gateway : '') },
      { header: 'Speed (Mbps)', transform: ({ id }) => (id ? transformMap[id].network.speed : '') },
      { header: 'Time Zone', transform: ({ system }) => system?.time_zone ?? '' },
      { header: 'Last Boot', transform: ({ system }) => system?.last_boot_time ?? '' },
      { header: 'Last Logged on', transform: ({ system }) => system?.last_logon_user ?? '' },
    ];

    return columns;
  }

  transformRawData(rawData: Array<Partial<Inventory>>) {
    this.transformMap = this._getTransformMap(rawData);
    return rawData;
  }

  debug(inventoryList: Array<Partial<Inventory>>) {
    this.transformRawData(inventoryList);

    return inventoryList.map((inventory) =>
      this.columns.reduce<Record<string, unknown>>((acc, { header, transform }) => {
        acc[header] = transform(inventory);
        return acc;
      }, {}),
    );
  }

  _getTransformMap(inventoryList: Array<Partial<Inventory>>): TransformMap {
    return inventoryList.reduce<TransformMap>((transformAccumulator, inventory) => {
      if (inventory.id) {
        transformAccumulator[inventory.id] = {
          cpu: transformInventory.getCpu(inventory),
          memory: transformInventory.getMemory(inventory),
          storage: transformInventory.getStorage(inventory),
          display: transformInventory.getDisplay(inventory),
          network: transformInventory.getNetwork(inventory),
        };
      }
      return transformAccumulator;
    }, {});
  }
}

export class ExtractSoftware implements ExtractInventory<SoftwareItem> {
  groupNameMap: Record<Inventory['group_id'], string>;

  constructor(groupNameMap: Record<Inventory['group_id'], string>) {
    this.groupNameMap = groupNameMap;
  }

  get columns() {
    const columns: Array<Column<SoftwareItem>> = [
      { header: 'Computer Name', transform: ({ computer_name }) => computer_name },
      { header: 'Group', transform: ({ group_id }) => (typeof group_id === 'number' ? this.groupNameMap[group_id] : '') },
      { header: 'Report Time', transform: ({ report_time }) => report_time },
      { header: 'Software Name', transform: ({ name = '' }) => name },
      { header: 'Vendor', transform: ({ vendor = '' }) => vendor },
      { header: 'Version', transform: ({ version = '' }) => version },
      { header: 'Installed Date', transform: ({ install_date = '' }) => install_date },
    ];
    return columns;
  }

  transformRawData(rawData: Array<Partial<Inventory>>) {
    return rawData
      .map<Array<SoftwareItem>>(({ local_time: report_time = '', server_name = '', group_id, software = [] }) => {
        return (
          software?.map<SoftwareItem>((item) => {
            return { report_time, computer_name: server_name, group_id, ...item };
          }) ?? []
        );
      })
      .flat();
  }

  debug(inventoryList: Array<Partial<Inventory>>) {
    const transformedData = this.transformRawData(inventoryList);

    return transformedData.map((item) =>
      this.columns.reduce<Record<string, unknown>>((acc, { header, transform }) => {
        acc[header] = transform(item);
        return acc;
      }, {}),
    );
  }
}

export class ExportInventory<T = unknown> {
  extractInventory: ExtractInventory<T>;

  constructor(extractInventory: ExtractInventory<T>) {
    this.extractInventory = extractInventory;
  }

  output(rawData: Array<unknown>) {
    const headers = this.extractInventory.columns.map(({ header }) => header);
    const transformedData: Array<T> = this.extractInventory.transformRawData?.(rawData) ?? (rawData as Array<T>);
    const extractedData = transformedData.map((item) => this.extractInventory.columns.map(({ transform }) => transform(item)));

    return {
      headers,
      data: extractedData,
    };
  }
}
