import { useState } from 'react';

import { Box, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import {
  RippleArrowRight,
  RippleBodyText02,
  RippleHeading07,
  RippleModal,
  RippleModalBody,
  RippleModalCloseButton,
  RippleModalContent,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalTitle,
} from '@/design';
import checkIsUnsupportedSafari from '@/modules/WebClient/utils/checkIsUnsupportedSafari';

import { BrowserAppIcon, BusinessAppIcon } from './Icons';

type ConnectModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onBusinessApp: () => void;
  onWebApp: () => void;
};

export default function ConnectModal({ isOpen, onClose, onBusinessApp, onWebApp }: ConnectModalProps) {
  const { t } = useTranslation(['support-session']);
  const [showTooltip, setShowTooltip] = useState(false);
  const isUnsupportedSafari = checkIsUnsupportedSafari();

  const openBusinessApp = () => {
    onBusinessApp();
    onClose();
  };

  const openWebApp = () => {
    if (isUnsupportedSafari) return;

    onWebApp();
    onClose();
  };

  return (
    <RippleModal isOpen={isOpen} onClose={onClose} motionPreset="scale">
      <RippleModalOverlay />
      <Tooltip
        label="Web app can only run on Safari 15.6.1 or later. Please upgrade your macOS to get the newer version of Safari. Or you can choose another browser to run the web app." // TODO: Add to i18n
        aria-label="Web app can only run on Safari 15.6.1 or later. Please upgrade your macOS to get the newer version of Safari. Or you can choose another browser to run the web app." // TODO: Add to i18n
        background="blue.300"
        color="white"
        boxShadow=" 0px 4px 8px 2px rgba(0, 0, 0, 0.12)"
        borderRadius="4px"
        fontWeight="600"
        fontSize="0.875rem"
        minW="348px"
        isOpen={showTooltip}
      >
        <RippleModalContent
          maxW="560px"
          width="100%"
          borderRadius="4px"
          backgroundColor="neutral.40"
          boxShadow="0px 8px 16px 4px rgba(0, 0, 0, 0.12)"
        >
          <RippleModalHeader padding="24px 24px 0px 24px">
            <RippleModalTitle>{t('support-session:connectModal.modalTitle')}</RippleModalTitle>
          </RippleModalHeader>
          <RippleModalCloseButton data-testid="close-icon-button" right="24px" top="24px" />
          <RippleModalBody padding="24px">
            <Box
              data-testid="open-business-app"
              backgroundColor="white"
              borderRadius="4px"
              boxShadow="0px 1px 3px 1px rgba(0, 0, 0, 0.24)"
              padding="16px"
              cursor="pointer"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              _hover={{ backgroundColor: 'blue.10' }}
              marginBottom="8px"
              onClick={openBusinessApp}
            >
              <Box display="flex" maxW="392px" alignItems="center">
                <Box marginRight="16px">
                  <BusinessAppIcon />
                </Box>
                <Box>
                  <RippleHeading07>{t('support-session:connectModal.modalSubTitleFromApp')}</RippleHeading07>
                  <RippleBodyText02>{t('support-session:connectModal.modalSubTitleFromAppDesc')}</RippleBodyText02>
                  <RippleBodyText02>{t('support-session:connectModal.modalInstallRequired')}</RippleBodyText02>
                </Box>
              </Box>
              <Box width="16px">
                <RippleArrowRight />
              </Box>
            </Box>
            <Box
              data-testid="open-web-app"
              backgroundColor="white"
              borderRadius="4px"
              boxShadow="0px 1px 3px 1px rgba(0, 0, 0, 0.24)"
              padding="16px"
              cursor={isUnsupportedSafari ? 'not-allowed' : 'pointer'}
              opacity={isUnsupportedSafari ? 0.32 : 1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              _hover={{ backgroundColor: 'blue.10' }}
              onClick={openWebApp}
              onMouseEnter={() => isUnsupportedSafari && setShowTooltip(true)}
              onMouseLeave={() => isUnsupportedSafari && setShowTooltip(false)}
            >
              <Box display="flex" maxW="392px" alignItems="center">
                <Box marginRight="16px">
                  <BrowserAppIcon />
                </Box>
                <Box>
                  <RippleHeading07>{t('support-session:connectModal.modalSubTitleFromWeb')}</RippleHeading07>
                  <RippleBodyText02>{t('support-session:connectModal.modalSubTitleFromWebDesc')}</RippleBodyText02>
                  <RippleBodyText02>{t('support-session:connectModal.modalInstallNoInstallRequired')}</RippleBodyText02>
                </Box>
              </Box>
              <Box width="16px">
                <RippleArrowRight />
              </Box>
            </Box>
          </RippleModalBody>
        </RippleModalContent>
      </Tooltip>
    </RippleModal>
  );
}
