import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useTranslation } from 'next-i18next';

import {
  RippleButton,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalTitle,
} from '@/design';

type ErrorModalAtomPayload = {
  title: string;
  message: string;
  onClickPrimaryButton: () => void;
  primaryButtonLabel?: string;
  onClickSecondaryButton?: () => void;
  secondaryButtonLabel?: string;
};

export const errorModalAtom = atomWithReset<{ isOpen: boolean; payload: ErrorModalAtomPayload }>({
  isOpen: false,
  payload: { title: '', message: '', onClickPrimaryButton: () => null },
});

export const useErrorModal = () => {
  const set = useSetAtom(errorModalAtom);
  const reset = useResetAtom(errorModalAtom);

  return {
    openErrorModal(payload: ErrorModalAtomPayload) {
      set({ isOpen: true, payload });
    },
    closeErrorModal() {
      reset();
    },
  };
};

export const ErrorModal = () => {
  const { t } = useTranslation();
  const {
    isOpen,
    payload: { title, message, onClickPrimaryButton, primaryButtonLabel, onClickSecondaryButton, secondaryButtonLabel },
  } = useAtomValue(errorModalAtom);

  return (
    <RippleModal isOpen={isOpen} onClose={onClickPrimaryButton} size="xl">
      <RippleModalOverlay />
      <RippleModalContent>
        <RippleModalHeader>
          <RippleModalTitle>{title}</RippleModalTitle>
        </RippleModalHeader>
        <RippleModalBody>{message}</RippleModalBody>
        <RippleModalFooter gap="12px">
          {onClickSecondaryButton && secondaryButtonLabel && (
            <RippleButton variant="grayScaleGhost" onClick={onClickSecondaryButton}>
              {secondaryButtonLabel}
            </RippleButton>
          )}
          <RippleButton onClick={onClickPrimaryButton}>{primaryButtonLabel || t('common:ok')}</RippleButton>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
};
