import React, { HTMLAttributes } from 'react';

import styled from '@emotion/styled';
import type { BorderProps, ColorProps, SpaceProps } from 'styled-system';
import { border, color, space } from 'styled-system';

const Wrapper = styled.div<HTMLAttributes<HTMLDivElement> & ColorProps & SpaceProps & BorderProps>`
  box-sizing: border-box;
  padding: 9px 15px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  background-color: #fffacd;
  border: 1px solid #fffacd;
  border-radius: 4px;

  ${color}
  ${space}
  ${border}
`;

const Alert = ({ children, ...otherProps }: HTMLAttributes<HTMLDivElement> & ColorProps & SpaceProps & BorderProps) => {
  return <Wrapper {...otherProps}>{children}</Wrapper>;
};

export default Alert;
