/**
 * ---------------------------- NOTICE ----------------------------
 * To ensure the business logic defined in this document can be applied across the entire product,
 * please discuss with front-end team members when you need to modify this file.
 * ----------------------------------------------------------------
 */
import { useAtomValue } from 'jotai';

import {
  attendedTeamInformationAtom,
  teamExpirationAtom,
  teamIdAtom,
  teamInformationAtom,
  teamIsResellAtom,
  teamRoleAtom,
  unattendedTeamInformationAtom,
} from './atoms';

export function useTeamInformation() {
  return useAtomValue(teamInformationAtom);
}

export function useAttendedTeamInformation() {
  return useAtomValue(attendedTeamInformationAtom);
}

export function useUnattendedTeamInformation() {
  return useAtomValue(unattendedTeamInformationAtom);
}

export function useTeamId() {
  return useAtomValue(teamIdAtom);
}

export function useTeamRole() {
  return useAtomValue(teamRoleAtom);
}

/**
 *
 * `true` indicates that this team purchases Splashtop products through a distributor channel,
 * and any product upgrades or upsell may need to go through the reseller.
 */
export function useTeamIsFromReseller() {
  return useAtomValue(teamIsResellAtom);
}

export function useTeamExpiration() {
  return useAtomValue(teamExpirationAtom);
}
