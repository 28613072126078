import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { MessageSchemaType } from '@/services/users/getMessageService/schemas';

// local storage to store user viewed message IDs
const KEY = 'emm_policy__viewed_msgs';
export const viewedMessagesAtom = atomWithStorage<MessageSchemaType['viewed_messages']>(KEY, []);

// current message to display
export const messageAtom = atom<MessageSchemaType['message'] | undefined>(undefined);
