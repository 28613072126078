import { type TFunction } from 'next-i18next';

import { type ComputerData, type ComputerSharedModeNumber, computerSharedModeNumberEnum } from '@/services/computers';

type ComputerDataRequiredPropertiesOnly = Pick<ComputerData, 'share_mode'>;

const weightMap: Record<ComputerSharedModeNumber, number> = {
  [computerSharedModeNumberEnum['stop_share_server']]: 0,
  [computerSharedModeNumberEnum['share_to_all']]: 1,
  [computerSharedModeNumberEnum['share_to_someone']]: 2,
  [computerSharedModeNumberEnum['share_to_admin']]: 3,
  [computerSharedModeNumberEnum['share_to_owner']]: -1, // NOTE: This value is not implemented, reserved for future use
};

export const access = {
  getAccessor: () => 'share_mode' as const,
  getLabel: (t: TFunction) => t('computer:column_access_headerLabel'),
  sortingFn: (a: ComputerDataRequiredPropertiesOnly, b: ComputerDataRequiredPropertiesOnly) => {
    if (a.share_mode === undefined) return -1;
    if (b.share_mode === undefined) return 1;

    return weightMap[a.share_mode] - weightMap[b.share_mode];
  },
  getCellContent: (t: TFunction, value: ComputerSharedModeNumber) => {
    const labelMap: Record<typeof value, string> = {
      0: t('computer:column_access_value_self'),
      1: t('computer:column_access_value_entireTeam'),
      2: t('computer:column_access_value_specificUsers'),
      3: t('computer:column_access_value_allAdmins'),
      4: 'share_to_owner', // NOTE: This value is not implemented, reserved for future use
    };

    return labelMap[value];
  },
} as const;
