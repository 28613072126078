import { Trans, useTranslation } from 'next-i18next';

import { RippleBodyText02, RippleHyperLink } from '@/design';

export const InstallationTutorialText = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['setup']);

  const isForChina = language === 'zh-CN';
  const tutorialLink = isForChina
    ? 'https://www.splashtop.cn/cn/resources/quick-setup-guide'
    : 'https://support-splashtopbusiness.splashtop.com/hc/articles/360015781911-Splashtop-Installation-Tutorial';

  return (
    <RippleBodyText02 mt="32px" mb="64px" align="center" color="dark.100">
      <Trans
        i18nKey="setup:installationTutorialLinkIntro"
        values={{ tutorial: t('setup:installationTutorial') }}
        components={{
          Link: <RippleHyperLink variant="hyperlink02" target="_blank" href={tutorialLink} />,
        }}
      />
    </RippleBodyText02>
  );
};
