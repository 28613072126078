import { useQuery } from '@tanstack/react-query';

import { getServiceDeskCloudBuildURL } from '@/services/serviceDesk/supportSession';

export default function useGetMobileCategory(packageCode: string, category: 'win' | 'mac' | 'Android' | 'iOS' | null) {
  return useQuery({
    queryKey: ['category', packageCode],
    queryFn: () => getServiceDeskCloudBuildURL(packageCode, category),
    enabled: Boolean(category),
  });
}
