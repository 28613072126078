export type SupportFormSummary = {
  id: number;
  name: string;
  technician: string | null; // email
  snippet_url: string;
  created_at: string;
  status: 0 | 1;
};

export type SupportFormSummaryList = Array<SupportFormSummary>;

export type SupportFormDetail = {
  name: string;
  technician_id: number;
  questions: Array<Question>;
  action_button_label: string;
  theme_color: string;
  download_page_kind: number;
};

export type Question = {
  kind: number;
  order: number;
  question: string;
  description: string;
  required: string;
  options?: Array<{
    option: string;
    default: boolean;
  }>;
};

export type Questionnaire = Array<{
  order: number;
  question: string;
  answer: string;
}>;

export type SupportSessionCode = string;

// support form related
export const DownloadPageKind = {
  SPLASHTOP_CLASSIC: 1,
  HIDE_SPLASHTOP_BRAND: 2,
} as const;

export type DownloadPageKindValueOption = `${DownloadPageKindValue}`;

export type DownloadPageKindValue = (typeof DownloadPageKind)[keyof typeof DownloadPageKind];

export const QuestionnaireFieldKind = {
  customerName: 1,
  customerIssue: 2,
  shortText: 3,
  longText: 4,
  radioButton: 5,
  checkbox: 6,
  comboBox: 7,
} as const;

export type QuestionnaireFieldKindValue = (typeof QuestionnaireFieldKind)[keyof typeof QuestionnaireFieldKind];

export type QuestionnaireFieldOption = { option: string };

export type QuestionnaireField = {
  kind: QuestionnaireFieldKindValue;
  order: number;
  required: boolean;
  question: string;
  placeholder: string;
  default_option: string | Array<string>;
  options: Array<QuestionnaireFieldOption> | null;
  uuid: string; // For d&d features
};

export type QuestionnaireFieldKeys = keyof QuestionnaireField;

export type SupportFormValueForAPI = {
  name: string; // support form name
  questionnaire: Array<Omit<QuestionnaireField, 'uuid'>>;
  action_button_label: string;
  theme_color: string;
  technician_id: number;
  download_page_kind: DownloadPageKindValue;
};

export type GetSupportFormValueForAPI = SupportFormValueForAPI & { checksum: string };

export type CreateFormResponse = { snippet_url: string };

export type SupportFormAnswer = string | Array<string> | null;

export type QuestionField = {
  order: number;
  question: string;
  answer: SupportFormAnswer;
};

export type SubmitFormValueForAPI = {
  customer_name: string;
  customer_issue: string;
  questionnaire: Array<QuestionField>;
  checksum: string;
};

export type SubmitFormResponse = { support_session_code: string };

export type CreatePinCodeResponse = {
  pin_code: string;
  pin_fqdn: string;
  expires_at: string;
};
