import { VStack } from '@chakra-ui/react';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';

import { RippleRadio, RippleRadioGroup, RippleSkeleton } from '@/design';
import { type ComputerSharedMode, computerSharedModeMap } from '@/services/computers';

import { accessPermissionModalAtom, sharedModeAtom } from './atoms';
import { useIsSubmitting, useSharedModeQuery } from './hooks';

export function SharedModeSelect(): React.JSX.Element {
  const { t } = useTranslation();

  const sharedModeQuery = useSharedModeQuery();
  const isSubmitting = useIsSubmitting();

  const { computerData, hasLimitedManagePermission } = useAtomValue(accessPermissionModalAtom);

  const isDeployedComputer = Boolean(computerData?.deployed);

  const [sharedMode, setSharedMode] = useAtom(sharedModeAtom);

  const isDisabled = isSubmitting;

  if (sharedModeQuery.isFetching) {
    return (
      <VStack spacing="4px" alignItems="start">
        <RippleSkeleton height="28px" width="400px" />
        <RippleSkeleton height="28px" width="400px" />
        <RippleSkeleton height="28px" width="400px" />
        <RippleSkeleton height="28px" width="400px" />
      </VStack>
    );
  }

  return (
    <RippleRadioGroup
      isDisabled={isDisabled}
      name="share-with"
      value={sharedMode}
      onChange={(value) => setSharedMode(value as ComputerSharedMode)}
    >
      <VStack spacing="4px" alignItems="start">
        {/* NOTE: Deployed computer has no owner */}
        {!isDeployedComputer && (
          <RippleRadio data-testid="stop_share_server" value={computerSharedModeMap.stop_share_server} py="4px">
            {t('computer:only_the_owner_of_the_computer')}
          </RippleRadio>
        )}
        <RippleRadio
          data-testid="share_to_admin"
          value={computerSharedModeMap.share_to_admin}
          py="4px"
          isDisabled={isDisabled || hasLimitedManagePermission}
        >
          {t('computer:all_admins')}
        </RippleRadio>
        <RippleRadio
          data-testid="share_to_all"
          value={computerSharedModeMap.share_to_all}
          py="4px"
          isDisabled={isDisabled || hasLimitedManagePermission}
        >
          {t('computer:all_admins_and_members')}
        </RippleRadio>
        <RippleRadio
          data-testid="share_to_someone"
          value={computerSharedModeMap.share_to_someone}
          py="4px"
          isDisabled={isDisabled || hasLimitedManagePermission}
        >
          {t('computer:specific_splashtop_id')}
        </RippleRadio>
      </VStack>
    </RippleRadioGroup>
  );
}
