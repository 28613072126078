import React from 'react';

import { Box, Flex, forwardRef } from '@chakra-ui/react';
import type { BoxProps, StyleProps } from '@chakra-ui/react';

import { RippleBodyText02, RippleHeading09 } from '@/design';

export const ModalGridTable = forwardRef<BoxProps, 'div'>((props, ref) => {
  return <Box display="grid" ref={ref} {...props} />;
});

export const ModalGridThead = React.Fragment;

export const ModalGridTbody = React.Fragment;

export const ModalGridTfoot = Box;

export const ModalGridTr = ({ children }: { children: React.ReactElement | Array<React.ReactElement> }) => {
  // Inject padding left/right on the first/last Td element
  const lastIndex = React.Children.count(children) - 1;
  const additionPropsMap: Record<number, StyleProps> = {
    0: { pl: '16px' },
    [lastIndex]: { pr: '16px' },
  };
  if (React.Children.count(children) === 1) {
    additionPropsMap[0] = { px: '16px' };
  }
  const injectedChildren = React.Children.map(children, (child, index) => {
    const additionProps = additionPropsMap[index];
    return React.cloneElement(child, additionProps);
  });

  return <>{injectedChildren}</>;
};

export const ModalGridTh = forwardRef<BoxProps, 'div'>(({ children, ...otherProps }, ref) => {
  return (
    <Box
      ref={ref}
      minH="36px"
      px="8px"
      py="8px"
      textTransform="none"
      fontSize="12px"
      borderBottom="1px solid"
      borderColor="neutral.60"
      {...otherProps}
    >
      <RippleHeading09 color="neutral.300" textAlign="inherit">
        {children}
      </RippleHeading09>
    </Box>
  );
});

export type ModalGridTdProps = BoxProps;
export const ModalGridTd = forwardRef<ModalGridTdProps, 'div'>(({ children, ...otherProps }, ref) => {
  return (
    <Flex
      ref={ref}
      className="ripple-modal-grid-td"
      px="6px"
      py="8px"
      fontSize="14px"
      borderBottom="1px solid"
      borderColor="neutral.60"
      minH="48px"
      alignItems="start"
      mb="-1px" // "Hide" last row's borderBottom
      {...otherProps}
    >
      <Flex minH="32px" alignItems="center" className="ripple-modal-grid-td__sub-container">
        <RippleBodyText02 textAlign="inherit" as="div">
          {children}
        </RippleBodyText02>
      </Flex>
    </Flex>
  );
});

export const ModalGridTableCaption = Box;

export const ModalGridTableContainer = forwardRef<BoxProps, 'div'>((props, ref) => {
  return <Box ref={ref} overflow="hidden" border="1px solid" borderColor="neutral.60" borderRadius="4px" {...props} />;
});
