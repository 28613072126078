import { Box, Flex } from '@chakra-ui/react';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'next-i18next';

import { RippleBodyText02, RippleHeading05 } from '@/design';
import { useAddComputerHandler } from '@/modules/Unboxing/hooks/useAddComputerHandler';
import { useStreamerType } from '@/modules/Unboxing/hooks/useStreamerType';
import { AddComputerBtn } from '@/modules/Unboxing/shared/AddComputerBtn';
import { AttendedStreamerSteps } from '@/modules/Unboxing/shared/AttendedStreamerSteps';
import { StreamerAccordion } from '@/modules/Unboxing/shared/StreamerAccordion';

const ConnectTo = () => {
  const { t } = useTranslation(['setup']);
  const { isShowInstruction, isMember, handleClickAddComputer } = useAddComputerHandler();
  const type = useStreamerType();

  return (
    <Flex direction="column" align="center" w="320px" border="1px solid" borderColor="neutral.60" borderRadius="base" pt="24px">
      <RippleHeading05 mb="16px">{capitalize(t('setup:connectTo'))}</RippleHeading05>
      {type === 'both' && (
        <Box pb="8px" w="100%">
          <StreamerAccordion isMember={isMember} onClickAddComputer={handleClickAddComputer} />
        </Box>
      )}
      {type === 'unattended' && (
        <Flex direction="column" align="center" pb="24px" justify="space-between" h="100%">
          <RippleBodyText02 align="center" color="dark.100" px="24px" mb="16px">
            {isShowInstruction ? t('setup:adminWillConfigure') : t('setup:unattendedStreamer')}
          </RippleBodyText02>
          {!isShowInstruction && <AddComputerBtn onClick={handleClickAddComputer} />}
        </Flex>
      )}
      {type === 'attended' && (
        <Flex px="24px" pb="24px" direction="column" justify="center" align="center">
          <AttendedStreamerSteps />
        </Flex>
      )}
    </Flex>
  );
};

export default ConnectTo;
