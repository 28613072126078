import { z } from 'zod';

import { TeamPermissionQuery } from './types';

export const teamPermissionKeySchema = z.enum([
  'access_schedule',
  'alert_profile', // 非單憑 team.settings 判斷
  'allow_src_version_control',
  'allow_srs_version_control',
  'antivirus',
  'attended_access', // 非單憑 team.settings 判斷
  'bitdefender',
  'bitdefender2',
  'blank_computer_list',
  'command_prompt',
  'create_group_admin',
  'display_group_to_member',
  'emm_os',
  'emm_os_opportunities',
  'emm_patch',
  'emm_patch_opportunities',
  'event_logs',
  'extra_src_management',
  'file', // 非單憑 team.settings 判斷
  'file_transfer',
  'force_admin_2step_auth',
  'force_member_2step_auth',
  'inventory',
  'macaddr_whitelist',
  'manage_infra_gen',
  'member_fetch_computer_notes', // 非單憑 team.settings 判斷
  'member_list', // 非單憑 team.settings 判斷
  'member_reboot_computer', // 非單憑 team.settings 判斷
  'member_reboot_computer_options', // 非單憑 team.settings 判斷
  'mobile_streamer',
  'multiple_groups',
  'off_session_chat',
  'off_session_file',
  'one_to_many', // 非單憑 team.settings 判斷
  'one_to_many_basic',
  'open_api_token',
  'os_opportunities',
  'pref_policy_security',
  'preference_policy',
  'premier_pack',
  'premium_tool', // 非單憑 team.settings 判斷
  'rdp_computer',
  'relay_recording',
  'relay_recording_access',
  'remote_print',
  'remote_reboot',
  'restrict_pcp_ip',
  'restrict_src_ip',
  'smart_action',
  'sos_relay_recording',
  'sos_relay_recording_access',
  'sos_service_desk',
  'sos_service_desk_premium_tool', // 非單憑 team.settings 判斷
  'sos_service_desk_psa',
  'sos_sso',
  'sos_ticket_session',
  'sos_web_src',
  'ssh_computer',
  'sso',
  'vnc_computer',
  'vulnerability_score',
  'wake_on_lan',
  'web_src',
  'voice_call', // Depend on the toggle `PCP_3760__VoiceCall_ActiveSessions_SOS14`
  'sos_voice_call', // Depend on the toggle `PCP_3760__VoiceCall_ActiveSessions_SOS14`
]);

export function getTeamPermissionSchema<Q extends TeamPermissionQuery>(query: Q) {
  if (!query.team_permissions) {
    return z.object({ team_permissions: z.never().optional() });
  }

  const shape = query.team_permissions.reduce<Partial<Record<z.infer<typeof teamPermissionKeySchema>, z.ZodBoolean>>>((acc, key) => {
    return { ...acc, [key]: z.boolean() };
  }, {});

  return z.object({ team_permissions: z.object(shape) });
}
