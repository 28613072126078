import React from 'react';

import { CSSWithMultiValues } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleBanner } from '@/design';

export type NoSearchResultsProps = {
  isLoading: boolean;
  isEmpty: boolean;
  onShowAll: () => void;
  sx?: CSSWithMultiValues;
};

export const NoSearchResults = (props: NoSearchResultsProps) => {
  const { t } = useTranslation();
  const { isLoading, isEmpty, onShowAll, sx = {} } = props;

  if (isLoading || !isEmpty) {
    return null;
  }

  const title = t('computer:selector.noSearchResultAndNoSelected');
  const button: { name: string; onClick: () => void } | undefined = {
    name: t('computer:selector.showAll'),
    onClick: onShowAll,
  };

  return (
    <RippleBanner
      sx={{
        ...sx,
        '.button': {
          textTransform: 'capitalize',
        },
      }}
      variant="alert"
      button={button}
    >
      {title}
    </RippleBanner>
  );
};
