import { HStack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';

import { RippleButton, RippleHeading06, RippleSheetAction } from '@/design';
import { FormEditor } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor';
import { SUPPORT_FORM_CONTENT_WIDTH } from '@/modules/ServiceDesk/SupportForm/Editor/constants';
import { useResetCurrentOpenField } from '@/modules/ServiceDesk/SupportForm/Editor/hooks/useEditingForm';
import { SupportFormValue } from '@/modules/ServiceDesk/SupportForm/Editor/types';
import { QuestionnaireFieldKind, QuestionnaireFieldKindValue } from '@/services/serviceDesk/supportForm/types';
import preSanitize from '@/utils/pre-sanitize';

type CustomFieldsProps = {
  onClickPreviousStep: () => void;
  onClickNextStep: () => void;
};

export function CustomFields({ onClickPreviousStep, onClickNextStep }: CustomFieldsProps) {
  const { setValue, watch } = useFormContext<SupportFormValue>();
  const { t } = useTranslation();
  const resetCurrentOpenField = useResetCurrentOpenField();

  const questionnaire = watch('questionnaire');
  const actionButtonLabel = watch('action_button_label');

  const fieldDefaultValueMap: Record<QuestionnaireFieldKindValue, string> = {
    [QuestionnaireFieldKind.customerName]: t('support-forms:customerName'),
    [QuestionnaireFieldKind.customerIssue]: t('support-forms:customerIssue'),
    [QuestionnaireFieldKind.shortText]: t('support-forms:untitled'),
    [QuestionnaireFieldKind.longText]: t('support-forms:untitled'),
    [QuestionnaireFieldKind.comboBox]: t('support-forms:untitled'),
    [QuestionnaireFieldKind.radioButton]: t('support-forms:untitled'),
    [QuestionnaireFieldKind.checkbox]: t('support-forms:untitled'),
  };

  const validateAndAutoFillEmptyField = () => {
    questionnaire.forEach((field, index) => {
      const questionSanitized = preSanitize(field.question);
      const placeholderSanitized = preSanitize(field.placeholder);

      setValue(`questionnaire.${index}.question`, questionSanitized.trim() === '' ? fieldDefaultValueMap[field.kind] : questionSanitized);
      setValue(`questionnaire.${index}.placeholder`, placeholderSanitized.trim() === '' ? '' : placeholderSanitized);
    });

    const actionButtonLabelSanitized = preSanitize(actionButtonLabel);

    setValue('action_button_label', actionButtonLabelSanitized.trim() === '' ? t('common:submit') : actionButtonLabelSanitized);
  };

  const handleClickPreviousStep = () => {
    validateAndAutoFillEmptyField();
    resetCurrentOpenField();
    onClickPreviousStep();
  };

  const handleClickNextStep = () => {
    validateAndAutoFillEmptyField();
    resetCurrentOpenField();
    onClickNextStep();
  };

  return (
    <HStack w={SUPPORT_FORM_CONTENT_WIDTH} mt="48px" gap="24px" flexDirection="column" alignItems="flex-start">
      <RippleHeading06>{t('support-forms:customFields')}</RippleHeading06>
      <FormEditor validateAndAutoFillEmptyField={validateAndAutoFillEmptyField} />
      <RippleSheetAction>
        <RippleButton variant="grayScaleGhost" onClick={handleClickPreviousStep}>
          {t('common:back')}
        </RippleButton>
        <RippleButton onClick={handleClickNextStep}>{t('common:next')}</RippleButton>
      </RippleSheetAction>
    </HStack>
  );
}
