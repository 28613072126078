/**
 * Get message details by ID
 * @see https://splashtop.atlassian.net/wiki/spaces/CS/pages/1128497215/Message+Details+API
 */
import axios from 'axios';

import { checkResponse, createGenericErrorHandlers } from '@/services/utils';

import { messageSchema } from './schemas';

function languageMapping(language: string) {
  if (language === 'zh-TW') return 'tw';
  if (language === 'zh-CN') return 'cn';
  return language;
}

const getUrl = (messageID: string) => `/api/web/v1/messages/${messageID}`;

const METHOD = 'GET';

const responseSchema = messageSchema;

const execute = ({ messageID, language = 'en' }: { messageID: string; language: string }) =>
  checkResponse(
    axios({
      method: METHOD,
      url: getUrl(messageID),
      params: {
        language: languageMapping(language),
      },
    }),
    responseSchema,
  );
const errorHandling = createGenericErrorHandlers();

export const getMessageDetailsService = { getUrl, execute, method: METHOD, responseSchema, errorHandling };
