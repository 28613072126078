import type { RowSelectionState } from '@tanstack/react-table';
import { atom } from 'jotai';
import { atomWithImmer } from 'jotai-immer';

import type { ComputerItem, HeaderSelectState } from '../types';
import { hasActionAbility } from '../utils';
import { computerDataMapAtom, computerIdListAtom } from './computerData';
import { currentVisibleComputerIdListAtom } from './tableState';

export const rowSelectionStateAtom = atomWithImmer<RowSelectionState>({});
export const selectedComputerIdListAtom = atom<Array<string>>((get) => {
  const rowSelection = get(rowSelectionStateAtom);

  return Object.entries(rowSelection).reduce<Array<string>>((acc, [rowId, isSelected]) => {
    if (isSelected) {
      acc.push(rowId);
    }
    return acc;
  }, []);
});
export const selectedComputerListAtom = atom<Array<ComputerItem>>((get) => {
  const selectedComputerIdList = get(selectedComputerIdListAtom);
  const computerDataMap = get(computerDataMapAtom);

  return selectedComputerIdList.map((computerId) => computerDataMap[computerId]).filter((item) => Boolean(item));
});

export const rowSelectionCountAtom = atom<number>((get) => {
  const selectedComputerIdList = get(selectedComputerIdListAtom);
  return selectedComputerIdList.length;
});

const selectableComputerIdListAtom = atom<Array<string>>((get) => {
  const computerIdList = get(computerIdListAtom);
  const computerDataMap = get(computerDataMapAtom);
  return computerIdList.filter((computerId) => hasActionAbility(computerDataMap[computerId]));
});
const selectableComputerIdMapAtom = atom<Record<string, boolean>>((get) => {
  return get(selectableComputerIdListAtom).reduce<Record<string, boolean>>((acc, computerId) => {
    acc[computerId] = true;
    return acc;
  }, {});
});

const currentVisibleSelectableComputerIdListAtom = atom<Array<string>>((get) => {
  const selectableComputerIdMap = get(selectableComputerIdMapAtom);
  const currentVisibleComputerIdList = get(currentVisibleComputerIdListAtom);

  return currentVisibleComputerIdList.filter((computerId) => selectableComputerIdMap[computerId]);
});

const isAllCurrentVisibleRowsSelectedAtom = atom<boolean>((get) => {
  const rowSelection = get(rowSelectionStateAtom);
  const currentVisibleComputerIdList = get(currentVisibleComputerIdListAtom);
  return currentVisibleComputerIdList.length > 0 && currentVisibleComputerIdList.every((computerId) => rowSelection[computerId]);
});
export const headerSelectionStateAtom = atom<HeaderSelectState>((get) => {
  const rowSelectionCount = get(rowSelectionCountAtom);
  const isAllCurrentVisibleRowsSelected = get(isAllCurrentVisibleRowsSelectedAtom);

  if (rowSelectionCount === 0) return 'none';
  else if (isAllCurrentVisibleRowsSelected) return 'all';
  return 'indeterminate';
});

const selectAllAtom = atom(null, (get, set) => {
  const currentVisibleSelectableComputerIdList = get(currentVisibleSelectableComputerIdListAtom);
  set(rowSelectionStateAtom, (draft) => {
    currentVisibleSelectableComputerIdList.forEach((computerId) => {
      draft[computerId] = true;
    });
  });
});
const clearSelectAtom = atom(null, (get, set) => {
  const currentVisibleSelectableComputerIdList = get(currentVisibleSelectableComputerIdListAtom);
  set(rowSelectionStateAtom, (draft) => {
    currentVisibleSelectableComputerIdList.forEach((computerId) => {
      draft[computerId] = false;
    });
  });
});

export const removeSelectAtom = atom(null, (_get, set, targetComputerIdList: Array<ComputerItem['id']>) => {
  set(rowSelectionStateAtom, (draft) => {
    targetComputerIdList.forEach((computerId) => {
      delete draft[computerId];
    });
  });
});

export const toggleHeaderSelectionStateAtom = atom(null, (get, set) => {
  const selectionState = get(headerSelectionStateAtom);

  switch (selectionState) {
    case 'none': {
      set(selectAllAtom);
      break;
    }

    case 'indeterminate': {
      const allSelectableCount = get(selectableComputerIdListAtom).length;
      const selectedCount = get(rowSelectionCountAtom);

      if (allSelectableCount === selectedCount) {
        set(clearSelectAtom);
      } else {
        set(selectAllAtom);
      }

      break;
    }

    case 'all': {
      set(clearSelectAtom);
    }
  }
});
