import { useCallback } from 'react';

import { getFilteredRowModel, getSortedRowModel } from '@tanstack/react-table';

import { RippleGroupedVirtuosoReactTable } from '@/design';

import { PolicyAssignment, PolicyAssignmentList, usePolicyColumns } from '../hooks';
import { GroupHeader } from './GroupHeader';

type Node = PolicyAssignment['policyNode'];

type PolicyListProps = {
  data: PolicyAssignmentList;
  isLoading: boolean;
  isTeamExpired: boolean;
  searchKeyword: string;
  onModalOpen: (type: 'CREATE' | 'CLONE' | 'ASSIGN' | 'DELETE', node: Node | null) => void;
  onEdit: (node: Node) => void;
  onStatusChange: (node: Node) => void;
};

export const PolicyList = ({ data, isLoading, isTeamExpired, searchKeyword, onEdit, onModalOpen, onStatusChange }: PolicyListProps) => {
  const handleModalOpen = useCallback(
    (modal: 'CREATE' | 'CLONE' | 'ASSIGN' | 'DELETE') => (node: Node) => {
      onModalOpen(modal, node);
    },
    [onModalOpen],
  );
  const columns = usePolicyColumns({
    isLoading,
    searchKeyword,
    isTeamExpired,
    onEdit,
    onClone: handleModalOpen('CLONE'),
    onDelete: handleModalOpen('DELETE'),
    onAssign: handleModalOpen('ASSIGN'),
    onChildCreate: handleModalOpen('CREATE'),
    onStatusChange,
  });
  return (
    <RippleGroupedVirtuosoReactTable
      data={data}
      columns={columns}
      groupHeader={GroupHeader}
      groupEmptyContent={() => <div>Empty</div>}
      groupByFn={(row) => row.original.policyNode.policy.platform}
      width="100%"
      virtuosoOptions={{
        useWindowScroll: true,
      }}
      tableOptions={{
        enableSorting: true,
        state: {
          globalFilter: searchKeyword,
        },
        enableGlobalFilter: true,
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
      }}
    />
  );
};
