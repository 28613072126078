import { Flex, HStack } from '@chakra-ui/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { Trans, useTranslation } from 'next-i18next';

import { RippleButton, RippleHyperLink, RippleTypography } from '@/design';
import { useTeamInformation } from '@/models/TeamInformation';

import { useWarningModal } from './WarningModal';
import { accessPermissionModalAtom, resetModalAtom, sharedModeAtom, tableSelectionStateAtom } from './atoms';
import { useIsLoading, useSubmitMutation, useSubmitState } from './hooks';
import { generateRowId } from './utils';

export function Footer(): React.JSX.Element {
  const { t } = useTranslation();

  const { member_id } = useTeamInformation();

  const submitMutation = useSubmitMutation();

  const isLoading = useIsLoading();
  const { isSubmitting, isSubmitDisabled } = useSubmitState();

  const resetModal = useSetAtom(resetModalAtom);

  const { computerData } = useAtomValue(accessPermissionModalAtom);
  const sharedMode = useAtomValue(sharedModeAtom);
  const tableSelectionState = useAtomValue(tableSelectionStateAtom);

  const warningModal = useWarningModal();

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <RippleTypography variant="body02">
        <Trans
          t={t}
          i18nKey="computer:access_permission_footer_hint"
          components={{
            HyperLink: (
              <RippleHyperLink
                variant="hyperlink02"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.splashtop.com/products/enterprise"
              />
            ),
          }}
        />
      </RippleTypography>
      <HStack spacing="8px">
        <RippleButton data-testid="cancel-button" variant="grayScaleGhost" isDisabled={isLoading} onClick={resetModal}>
          {t('common:cancel')}
        </RippleButton>
        <RippleButton
          data-testid="submit-button"
          variant="primary"
          isDisabled={isLoading || isSubmitDisabled}
          isLoading={isSubmitting}
          onClick={handleSubmit}
        >
          {t('common:save')}
        </RippleButton>
      </HStack>
    </Flex>
  );

  function handleSubmit() {
    const hasSelectedSelf = tableSelectionState[generateRowId.teamMember(member_id)] === true;

    if (computerData?.deployed && sharedMode === 'share_to_someone' && !hasSelectedSelf) {
      warningModal.open();
    } else {
      submitMutation.mutate();
    }
  }
}
