import { useMemo } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import chunk from 'lodash/chunk';
import flatten from 'lodash/flatten';

import { type ColumnPermissions, checkPermission, useRegisterComputerDataFields } from '@/modules/Computer/ComputerList';
import { computeShowOOBE } from '@/modules/Computer/utils';
import {
  type ComputerListCustomizedField,
  getUserComputerIdList,
  getUserComputerListByIds,
  getUserComputerListByToken,
  getUserComputerTokenList,
} from '@/services/computers';
import { CHUNK_SIZE } from '@/utils/constants';

import { useColumnPermissions } from '../useColumnPermissions';
import { useGroupConfig } from '../useGroupConfig';
import { computerIdListPlaceholder, computerListPlaceholder, tokenListPlaceholder } from './constants';

const useComputerSRSQueries = useComputerSRSQueries_tokenList;
export function useComputerDataSRS() {
  const queryClient = useQueryClient();
  const columnPermissions = useColumnPermissions();

  const { computerIdListQuery, computerListQuery, computerListQueryKey, computerIdListQueryKey } = useComputerSRSQueries({
    columnPermissions,
  });

  const computerListData = computerListQuery.data ?? computerListPlaceholder;

  const showOOBE = computeShowOOBE(computerIdListQuery);
  const isFetching = computerIdListQuery.isFetching || computerListQuery.isFetching;

  function refresh() {
    queryClient.removeQueries({ queryKey: computerIdListQueryKey, exact: true });
    computerIdListQuery.refetch().then(() => {
      queryClient.removeQueries({ queryKey: computerListQueryKey, exact: true });
      computerListQuery.refetch();
    });
  }

  return { showOOBE, isFetching, computerListData, refresh };
}

export const computerIdListSRSQueryKey = ['myComputer', 'computerList'];

export function useComputerIdListSRSQuery() {
  return useQuery({
    queryKey: computerIdListSRSQueryKey,
    queryFn: () => getUserComputerIdList(),
    staleTime: Infinity,
  });
}

export const computerTokenListQueryKey = ['myComputers', 'getComputerTokenList'];

function useComputerTokenListQuery() {
  return useQuery({
    queryKey: computerTokenListQueryKey,
    queryFn: () => getUserComputerTokenList(),
    staleTime: Infinity,
  });
}

export const useComputerListSRSQueryKey = () => {
  const computerIdListQuery = useComputerIdListSRSQuery();
  const computerIdList = computerIdListQuery.data ?? computerIdListPlaceholder;
  return ['myComputer', 'computerList', computerIdList];
};

export function useComputerListSRSQuery({ columnPermissions }: { columnPermissions: ColumnPermissions | null }) {
  const computerIdListQuery = useComputerIdListSRSQuery();
  const computerIdList = computerIdListQuery.data ?? computerIdListPlaceholder;

  const fields = useSRSFields(columnPermissions);

  const queryKey = useComputerListSRSQueryKey();

  return useQuery({
    queryKey,
    queryFn: async () => {
      if (computerIdList.length === 0) return [];

      const idListChunks = chunk(computerIdList, CHUNK_SIZE);
      const requestList = idListChunks.map((idList) => {
        return getUserComputerListByIds({ idList, fields });
      });

      const result = await Promise.all(requestList);

      return flatten(result);
    },
    staleTime: Infinity,
    enabled: !computerIdListQuery.isFetching && columnPermissions !== null,
  });
}

const useComputerListSRSTokenQueryKey = () => {
  const computerTokenListQuery = useComputerTokenListQuery();
  const tokenList = computerTokenListQuery.data ?? tokenListPlaceholder;
  return ['myComputer', 'computerList', tokenList];
};

function useComputerListSRSTokenQuery({ columnPermissions }: { columnPermissions: ColumnPermissions | null }) {
  const queryKey = useComputerListSRSTokenQueryKey();
  const computerTokenListQuery = useComputerTokenListQuery();
  const tokenList = computerTokenListQuery.data ?? tokenListPlaceholder;

  const fields = useSRSFields(columnPermissions);

  return useQuery({
    queryKey,
    queryFn: async () => {
      if (tokenList.length === 0) return [];

      const requestList = tokenList.map((token) => {
        return getUserComputerListByToken({ token, fields });
      });

      const result = await Promise.all(requestList);

      return flatten(result);
    },
    staleTime: Infinity,
    enabled: !computerTokenListQuery.isFetching && columnPermissions !== null,
  });
}

function useComputerSRSQueries_tokenList({ columnPermissions }: { columnPermissions: ColumnPermissions | null }) {
  return {
    computerIdListQueryKey: computerTokenListQueryKey,
    computerIdListQuery: useComputerTokenListQuery(),
    computerListQueryKey: useComputerListSRSTokenQueryKey(),
    computerListQuery: useComputerListSRSTokenQuery({ columnPermissions }),
  };
}

function useSRSFields(columnPermissions: ColumnPermissions | null): Array<ComputerListCustomizedField> {
  const { shouldGetGroupName } = useGroupConfig();

  const fields = useMemo(() => getSRSFields({ columnPermissions, shouldGetGroupName }), [columnPermissions, shouldGetGroupName]);

  useRegisterComputerDataFields(fields);

  return fields;
}

function getSRSFields({
  columnPermissions,
  shouldGetGroupName,
}: {
  columnPermissions: ColumnPermissions | null;
  shouldGetGroupName: boolean;
}): Array<ComputerListCustomizedField> {
  return [
    'version',
    'host_name',
    'group_id',
    ...checkPermission(shouldGetGroupName, 'group_name' as const),
    'pubip',
    'local_ip',
    ...checkPermission(columnPermissions?.notes, 'note' as const),
    'online_status',
    'is_device_owner',
    'connected',
  ];
}
