import Head from 'next/head';
import React, { ReactNode } from 'react';

import { Box, SystemStyleObject } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { PageFooter } from '@/showcase/Layout';

import { PasswordRotationAlert } from '../PasswordRotationAlert/PasswordRotationAlert';
import { PageHeader } from './PageHeader';

type PageLayoutProps = {
  children: ReactNode;
  hideHeader?: boolean;
  hideFooter?: boolean;
  sx?: SystemStyleObject;
};

export const PageLayout = ({ children, hideHeader = false, hideFooter = false, sx }: PageLayoutProps) => {
  const { t } = useTranslation(['external', 'common']);

  return (
    <>
      <Head>
        <title>{t('common:title')}</title>
      </Head>

      <Box
        display="grid"
        gridTemplateRows={hideHeader ? '1fr min-content' : 'min-content 1fr min-content'}
        // Minus 1px for hiding scrollbar
        minHeight="calc(100vh - 1px)"
        sx={sx}
      >
        {!hideHeader && <PageHeader />}
        <Box as="main" className="main">
          {children}
        </Box>
        {!hideFooter && <PageFooter marginTop="0px" />}
        <PasswordRotationAlert />
      </Box>
    </>
  );
};
