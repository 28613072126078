import { Box, Flex } from '@chakra-ui/react';
import { type TFunction } from 'next-i18next';

import Highlight from '@/components/Highlight';
import { type ComputerData } from '@/services/computers';

export const ipAddressWAN = {
  getAccessor: () => 'pubip' as const,
  getLabel: (t: TFunction) => `${t('computer:ipAddress')} (WAN)`,
  /**
   * Split IP address string and display the first two IP addresses
   *
   * @param value IP address list string (e.g. "1.1.1.1,2.2.2.2,2001:0db8:85a3:0000:0000:8a2e:0370:7334")
   * @param searchKeyword Highlight search keyword (optional)
   */
  getCellContent: (value: ComputerData['pubip'], searchKeyword?: string) => {
    if (value === null || value === undefined) return null;

    const ipList = value.split(',');
    const moreThanTwo = ipList.length > 2;

    return (
      <Flex flexDirection="column">
        {ipList.slice(0, 2).map((ip, index) => {
          let ipString = ip;
          if (index === 1) {
            ipString = `${ip}${moreThanTwo ? '...' : ''}`;
          }

          return (
            <Box key={ip}>
              <Highlight keyword={searchKeyword}>{ipString}</Highlight>
            </Box>
          );
        })}
      </Flex>
    );
  },
  /**
   * Display all IP addresses in a column
   *
   * @param value IP address list string (e.g. "1.1.1.1,2.2.2.2,2001:0db8:85a3:0000:0000:8a2e:0370:7334")
   */
  getTooltipLabel: (value: ComputerData['pubip']) => {
    if (value === null || value === undefined) return null;

    const ipList = value.split(',');

    return (
      <Flex flexDirection="column">
        {ipList.map((ip) => (
          <Box key={ip}>{ip}</Box>
        ))}
      </Flex>
    );
  },
} as const;
