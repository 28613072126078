import { ColumnVisibility } from './types';

export const baseColumnIdMap = {
  checkbox: 'checkbox',
  name: 'name',
  host_name: 'host_name',
  spacer: 'spacer',
  action: 'action',
} as const;

export const baseColumnWidthMap = {
  [baseColumnIdMap.checkbox]: 44,
  [baseColumnIdMap.name]: 272,
  [baseColumnIdMap.host_name]: 160,
  /** auto compute by css */
  [baseColumnIdMap.spacer]: 0,
  [baseColumnIdMap.action]: 86,
} as const;

export const ALL_GROUP_ID = 'all';
export const DEFAULT_GROUP_ID = '0';

/** Assign from Scheduled Access, External Share */
export const FROM_OTHER_GROUP_ID = 'other';

// TODO: Use group_id to grouping ?
export const GROUPING_COLUMN = 'groupName';
export const GROUPING_TAG = 'grouped';

export const fixedColumnVisibility: ColumnVisibility = {
  [baseColumnIdMap.checkbox]: true,
  [baseColumnIdMap.name]: true,
  [baseColumnIdMap.host_name]: false,
  [baseColumnIdMap.spacer]: true,
  [baseColumnIdMap.action]: true,
};

export const DEFAULT_LEFT_OFFSET = 32;

/**
 * This filter function name is used at custom filter fns in `tanstack/react-table`.
 * To use custom filter, we need to use `module` keyword to overwrite the type `FilterFns` to extend the custom filter fn names
 * ref: https://tanstack.com/table/v8/docs/api/features/column-filtering/#filterfns
 * ```typescript
 * declare module '@tanstack/[adapter]-table' {
 *   interface FilterFns {
 *     myCustomFilter: FilterFn<unknown>
 *   }
 * }
 * ```
 *
 * But this method will pollute the project, so we create this constant string to reference and use `as keyof FilterFns` instead.
 * ```typescript
 * filterFn: sharedFilterFnName as keyof FilterFns
 * ```
 */
export const sharedFilterFnName = 'sharedFilterFn';
