import { useQuery } from '@tanstack/react-query';

import { useTeamInformation } from '@/models/TeamInformation';
import { infoQueryService } from '@/services/users';

export const useManageAssociates = () => {
  const { teamKind } = useTeamInformation();

  const infoQuery = useQuery({
    queryKey: ['group_ManageAssociates', 'info'],
    queryFn: () => infoQueryService.execute({ team_permissions: ['multiple_groups'] }),
    enabled: true,
  });

  const isTeamAccessible = Boolean(infoQuery.data?.[teamKind]?.team_permissions.multiple_groups);

  return {
    isFetched: infoQuery.isFetched,
    canAccess: isTeamAccessible,
  };
};
