import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { getTeamGranularControlService } from '@/services/teams/granular_controls';
import { GranularControlKey, GranularControlMap } from '@/services/teams/granular_controls/types';

import { queryMainKey } from '../constants';

function getQueryKeys(teamId: number, features: Array<GranularControlKey>) {
  return [queryMainKey, 'getTeamGranularControl', teamId, features] as const;
}

type QueryKey = ReturnType<typeof getQueryKeys>;

export function useNormalGranularControlQuery(
  teamId: number | null,
  features: Array<GranularControlKey> | null,
  queryOptions: Omit<UseQueryOptions<GranularControlMap, unknown, GranularControlMap, QueryKey>, 'queryKey' | 'queryFn'> = {},
) {
  return useQuery<GranularControlMap, unknown, GranularControlMap, QueryKey>({
    queryKey: getQueryKeys(teamId ?? NaN, features ?? []),
    queryFn: () => {
      if (teamId && features) {
        return getTeamGranularControlService.execute(teamId, features);
      }
      return null as never;
    },
    ...queryOptions,
  });
}
