import { LaunchSRCModal, useLaunchSRCModal } from './LaunchSRCModal';

export function useRemoteCommand() {
  const launchSRCModal = useLaunchSRCModal();

  return {
    execute: launchSRCModal.open,
  };
}

export function RemoteCommand(): React.JSX.Element {
  return (
    <>
      <LaunchSRCModal />
    </>
  );
}
