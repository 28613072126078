import { useTranslation } from 'next-i18next';

import { RippleFilter, RippleIconButton, RippleTooltip } from '@/design';

import { useFiltersDrawer } from '../FiltersDrawer';
import { useActionBarContext } from './hooks';

type FilterSettingsProps = { isDisabled?: boolean };
export function FilterSettings({ isDisabled = false }: FilterSettingsProps): React.JSX.Element {
  const { t } = useTranslation();

  const filtersDrawer = useFiltersDrawer();
  const { isLoading } = useActionBarContext();

  return (
    <RippleTooltip label={t('common:filters')} shortcut="F">
      <RippleIconButton
        data-testid="filters"
        aria-label="filter"
        icon={<RippleFilter />}
        onClick={filtersDrawer.open}
        isDisabled={isDisabled || isLoading}
      />
    </RippleTooltip>
  );
}
