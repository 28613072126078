import { createContext } from 'react';

import type { SeatInformationResponse } from '@/services/common/types';

export type Update = {
  update?: () => void;
  isDefault?: boolean;
  setSeatContext?: (context: SeatInformationResponse) => void;
};

export type SeatContextType = SeatInformationResponse & Update;

export const SeatContext = createContext<SeatContextType>({
  created_at: '',
  multiple_magnification: 0,
  seats_users_count: 0,
  occupied_seat_count: 0,
  team_members_count: 0,
  on_shelf: false,
  can_change_plan: false,
  can_trial: false,
  seat_is_recurring: false,
  seat_auto_renew: false,
  can_buy_product: false,
  buy_url: '',
  trial_url: '',
  upgrade_url: '',
  team_member_permissions: {
    sso: false,
  },
  team_permissions: {
    sso: false,
  },
  product_options: {
    can_buy_pcs: false,
  },
  team_settings: {
    schedule_time_zone_minutes: 0,
    schedule_time_zone: null,
  },
  team_code: '',
  isDefault: true,
});
