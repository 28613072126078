/**
 * List all servers which are assigned by target policies
 * @see https://github.com/SplashtopInc/be_kung_fu/blob/master/be_app/api/web-api/v1/emm/emm_policies.md#list-emm-policy-servers-api
 */
import axios from 'axios';
import { z } from 'zod';

import { commonResponseSchema } from '@/services/schemas';
import { checkResponse, createGenericErrorHandlers } from '@/services/utils';

const payloadSchema = z.array(z.number()).refine((arr) => arr.length <= 100, { message: 'Maximum 100 policy IDs allowed' });
type PayloadSchema = z.infer<typeof payloadSchema>;

const responseSchema = z.array(
  z.object({
    emm_policy_id: z.number(),
    server_id: z.number(),
  }),
);

const METHOD = 'POST';

const getUrl = (teamId: number) => `/api/web/v1/teams/${teamId}/emm_policies/servers`;

const execute = (teamId: number, policyIds: PayloadSchema) =>
  checkResponse(axios({ method: METHOD, url: getUrl(teamId), data: { emm_policy_ids: policyIds } }), responseSchema);

const errorHandling = {
  ...createGenericErrorHandlers(),
  /**
   * @code 40409
   * - EMM policy IDs exceed maximum limit (5000)
   */
  exceedNumberLimit: (response: any) => {
    const errorSchema = commonResponseSchema.merge(
      z.object({ result: z.literal(40409), data: z.object({ error: z.literal('exceed_number_limit') }) }),
    );
    return errorSchema.safeParse(response).success;
  },
  /**
   * @code 40422
   * EMM policy ID is empty
   */
  wrongParams: (response: any) => {
    const errorSchema = commonResponseSchema.merge(
      z.object({ result: z.literal(40422), data: z.object({ error: z.literal('wrong_params') }) }),
    );
    return errorSchema.safeParse(response).success;
  },
} as const;

export const listEmmPolicyServersService = { getUrl, payloadSchema, execute, method: METHOD, responseSchema, errorHandling };
