import { z } from 'zod';

import { categoryEnum, createPolicyItemSchema, policyPlatformEnum } from '../types';
import {
  Source,
  notifyBeforeRebootSecondMap,
  patchPolicyItemConfig,
  rebootIntervalSecondMap,
  sourceProviderMap,
  updateAfterReleaseDayMap,
} from './constants';

export const enablePatchPolicySchema = createPolicyItemSchema(patchPolicyItemConfig.enable_policy.code, z.boolean());

export const serviceProviderSourceSchema = z.object({
  alias: z.string().nullable().optional(),
  order: z.number(),
  password: z.string().nullable().optional(),
  proxy: z
    .object({
      auto: z.boolean(),
      password: z.string().nullable().optional(),
      port: z.number().nullable().optional(),
      url: z.string().nullable().optional(),
      user: z.string().nullable().optional(),
    })
    .nullable(),
  source_url: z.string().nullable(),
  tap: z.string().nullable().optional(),
  user: z.string().nullable().optional(),
});

export type ServiceProviderSource = z.infer<typeof serviceProviderSourceSchema>;

export const serviceProviderSchema = createPolicyItemSchema(
  patchPolicyItemConfig.service_provider.code,
  z.object({
    source: z.array(serviceProviderSourceSchema),
    sp: z.union([z.literal(sourceProviderMap.Windows), z.literal(sourceProviderMap.macOS)]),
  }),
);

export const scanScheduleSchema = createPolicyItemSchema(
  patchPolicyItemConfig.scan_schedule.code,
  z.object({ interval: z.number().max(9999999).min(0), time: z.string() }),
);

export const updateScheduleSchema = createPolicyItemSchema(
  patchPolicyItemConfig.update_schedule.code,
  z.object({ interval: z.number().max(9999999).min(0), time: z.string() }),
);

export const makeUpScanSchema = createPolicyItemSchema(patchPolicyItemConfig.make_up_scan.code, z.boolean());

export const makeUpUpdateSchema = createPolicyItemSchema(patchPolicyItemConfig.make_up_update.code, z.boolean());

export const onboardingScanUpdateSchema = createPolicyItemSchema(patchPolicyItemConfig.onboarding_scan_update.code, z.boolean());

export const updateAfterReleaseDaySchema = z.union([
  z.literal(updateAfterReleaseDayMap.none),
  z.literal(updateAfterReleaseDayMap.sevenDay),
  z.literal(updateAfterReleaseDayMap.fourteenDay),
  z.literal(updateAfterReleaseDayMap.thirtyDay),
]);
export type UpdateAfterReleaseDay = z.infer<typeof updateAfterReleaseDaySchema>;

export const updateAfterReleaseSchema = createPolicyItemSchema(
  patchPolicyItemConfig.update_after_release.code,
  updateAfterReleaseDaySchema,
);
export type UpdateAfterRelease = z.infer<typeof updateAfterReleaseSchema>;

export const rebootIntervalMinuteSchema = z.union([
  z.literal(rebootIntervalSecondMap.none),
  z.literal(rebootIntervalSecondMap.fifteenMinutes),
  z.literal(rebootIntervalSecondMap.thirtyMinutes),
  z.literal(rebootIntervalSecondMap.sixtyMinutes),
  z.literal(rebootIntervalSecondMap.ninetyMinutes),
  z.literal(rebootIntervalSecondMap.oneHundredTwentyMinutes),
]);
export type RebootIntervalMinute = z.infer<typeof rebootIntervalMinuteSchema>;

export const notifyBeforeRebootMinuteSchema = z.union([
  z.literal(notifyBeforeRebootSecondMap.none),
  z.literal(notifyBeforeRebootSecondMap.twoMinutes),
  z.literal(notifyBeforeRebootSecondMap.threeMinutes),
  z.literal(notifyBeforeRebootSecondMap.fourMinutes),
  z.literal(notifyBeforeRebootSecondMap.fiveMinutes),
]);
export type NotifyBeforeRebootMinute = z.infer<typeof notifyBeforeRebootMinuteSchema>;

export const rebootIntervalSchema = createPolicyItemSchema(
  patchPolicyItemConfig.reboot_interval.code,
  z.object({
    interval: rebootIntervalMinuteSchema,
    login_give_up: z.boolean(),
    notify_before_reboot: notifyBeforeRebootMinuteSchema,
    reboot: z.boolean(),
  }),
);
export type RebootInterval = z.infer<typeof rebootIntervalSchema>;

export const recentVersionSchema = z.object({
  software_version_id: z.union([z.string(), z.number()]),
  version: z.string(),
  desc: z.string(),
  release_date: z.string().nullable(),
});

export const softwareSchema = z.object({
  /** The ID of the software. */
  id: z.union([z.string(), z.number()]),
  /** The display name of the software. */
  name: z.string(),
  /** The source provider of the software (Chocolatey or Homebrew). */
  sp: z.union([z.literal(Source.choco), z.literal(Source.brew)]),
  /** The application name of the software, use as policy item value's `id`. */
  app: z.string(),
  /** use as policy item code. */
  policy_item_code: z.string(),
  /** Indicates if the software is enabled to be managed. */
  enabled: z.boolean(),
  /** Indicates if the software is recommended by Splashtop. If not, it's a software added by user. */
  recommended: z.boolean(),
  /** Indicates the software only support latest version, can't downgrade */
  latest_only: z.boolean(),
  icon_url: z.string(),
  descriptions: z.string(),
  recent_versions: z.array(recentVersionSchema),
});

export type Software = z.infer<typeof softwareSchema>;

export const patchPolicySoftwareItemSchema = z.object({
  id: z.number().optional(),
  code: z.string().regex(/^030[1-2](?!0000)[0-9]{4}$/),
  operator: z.union([z.literal('+'), z.literal('-')]),
  value: z
    .object({
      sp: softwareSchema.shape.sp,
      id: softwareSchema.shape.app,
      auto_approval: z.boolean(),
      required: z.boolean(),
      approved_version: z.string().nullable(),
    })
    .nullable(),
});
export type PatchPolicySoftwareItem = z.infer<typeof patchPolicySoftwareItemSchema>;

// For policy item with fixed code
export const policyFixedCodeItemSchema = z.discriminatedUnion('code', [
  enablePatchPolicySchema,
  serviceProviderSchema,
  scanScheduleSchema,
  updateScheduleSchema,
  makeUpScanSchema,
  makeUpUpdateSchema,
  onboardingScanUpdateSchema,
  updateAfterReleaseSchema,
  rebootIntervalSchema,
]);
export type PatchPolicyFixedCodeItem = z.infer<typeof policyFixedCodeItemSchema>;
export type PolicyItem = PatchPolicyFixedCodeItem | PatchPolicySoftwareItem;
export type PolicyItemWithoutId = Omit<PolicyItem, 'id'>;

export const policyNodeIdsSchema = z.object({
  emm_policy_id: z.number(),
  parent_id: z.union([z.number(), z.null()]),
});

const policyItems = z.array(
  z.union([
    enablePatchPolicySchema,
    serviceProviderSchema,
    scanScheduleSchema,
    updateScheduleSchema,
    makeUpScanSchema,
    makeUpUpdateSchema,
    onboardingScanUpdateSchema,
    updateAfterReleaseSchema,
    rebootIntervalSchema,
    patchPolicySoftwareItemSchema,
  ]),
);
export type PolicyItems = z.infer<typeof policyItems>;

export const policyNodeSchema = policyNodeIdsSchema.merge(
  z.object({
    policy_items: policyItems,
  }),
);
export type PolicyNode = z.infer<typeof policyNodeSchema>;

export const policyDetailResponseDataSchema = z.object({
  category: z.literal(categoryEnum.enum.patch),
  platform: policyPlatformEnum.exclude(['Android']), // Windows, macOS, Linux, ...etc.
  current: policyNodeSchema,
  parents: z.array(policyNodeSchema),
});
export type PatchPolicyDetail = z.infer<typeof policyDetailResponseDataSchema>;

export const newPatchPolicyResponseDataSchema = z.object({
  [patchPolicyItemConfig.service_provider.code]: z.object({
    default: z.array(serviceProviderSourceSchema),
    options: z.array(serviceProviderSourceSchema),
  }),
});
export type NewPatchPolicy = z.infer<typeof newPatchPolicyResponseDataSchema>;
