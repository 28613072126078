import { z } from 'zod';

/** Types to query */
export const messageTypeSchema = z.union([
  z.literal('popup'),
  z.literal('banner'),
  z.literal('discover_page'),
  z.literal('get_started_page'),
]);
export type MessageType = z.infer<typeof messageTypeSchema>;

/** The page to display the message */
export const page = z.union([
  z.literal('my_computer'),
  z.literal('sos'),
  z.literal('service_desk'),
  z.literal('discover_page'),
  z.literal('policy'),
  z.literal('get_started_page'),
]);
export type Page = z.infer<typeof page>;

/** The message ids that the user has viewed (only for popup/banner) */
export const viewedMessages = z.array(z.number());
export type ViewedMessages = z.infer<typeof viewedMessages>;

/** The response details */
export const messageSchema = z.object({
  id: z.number(),
  type: z.string(),
  kind: z.string(),
  style: z.string(),
  kibana_tracking: z.boolean(),
  pages: z.array(
    z.object({
      page: z.number(),
      image: z.string(),
      title: z.string(),
      body_text: z.string(),
      link_text: z.string().nullable(),
      url: z.string().nullable(),
      end_button: z.string().nullable(),
    }),
  ),
});
export type MessageSchemaType = z.infer<typeof messageSchema>;
