import { useContext } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useSetAtom } from 'jotai';

import { RippleDownloadBusinessApp } from '@/design';
import { featureControl } from '@/feature/toggle';
import { useEmmPolicyAccess } from '@/hooks';
import { ComputerData } from '@/services/computers';
import type { Group as RawGroup } from '@/services/group/types';

import {
  ActionBar,
  ActionBarItem,
  ActionBarLeftSection,
  ActionBarRightSection,
  ColumnPermissionsContext,
  ComputerList,
  ComputerListProvider,
  FiltersDrawer,
  FiltersDrawerSections,
  TableSettingsDrawer,
  TableSettingsSection,
  resetUpdateQueueAtom,
  useInitializeColumnVisibility,
  useInitializeExpandedState,
  useInitializeViewMode,
} from '../ComputerList';
import type { ConfigurableColumn } from '../ComputerList';
import { StickyLeftWrapper } from '../ComputerList/components';
import { PageFrame } from './PageFrame';
import { useColumns } from './columns';
import { useColumns_SBA } from './columns_SBA';
import { useComputerDataSBA, useComputerDataSRS } from './useComputerData';
import { useConfigurableColumnsSBA, useConfigurableColumnsSRS } from './useConfigurableColumns';
import { useGroupData } from './useGroupData';
import { usePolicyMapQuery } from './usePolicyMap';
import {
  useInitializeFilterColumnState,
  useInitializeFilterGroup,
  useInitializeFilterPlatform,
  useInitializeSearch,
  useInitializeSortingState,
} from './utils';

const FT_PHASE_2 = featureControl.getToggle('CF_355__computer_list_UI_renewal__phase_2');

export function AllComputersSRS() {
  const queryClient = useQueryClient();
  const columns = useColumns();

  const columnPermissions = useContext(ColumnPermissionsContext);

  const configurableColumns = useConfigurableColumnsSRS(columnPermissions);

  const { computerListData, isFetching: isFetchingComputerData, refresh: refreshComputerData } = useComputerDataSRS();
  const { groupList, groupListQuery, groupListQueryKey } = useGroupData();
  const resetUpdateQueue = useSetAtom(resetUpdateQueueAtom);
  const policyMapQuery = usePolicyMapQuery(); // data for EMM policy column
  const { policyAccessQuery } = useEmmPolicyAccess();

  const canAccessEmmPolicy = policyAccessQuery.isFetched && policyAccessQuery.data && policyAccessQuery.data.canAccess;

  const isLoading = groupListQuery.isFetching || isFetchingComputerData || policyMapQuery.isFetching;

  function handleRefresh() {
    if (columnPermissions.group) {
      queryClient.removeQueries({ queryKey: groupListQueryKey, exact: true });
      groupListQuery.refetch();
    }

    refreshComputerData();

    resetUpdateQueue();

    if (canAccessEmmPolicy) {
      policyMapQuery.refetch();
    }
  }

  return (
    <AllComputersCore
      computerListData={computerListData}
      groupList={groupList}
      isLoading={isLoading}
      configurableColumns={configurableColumns}
      columns={columns}
      actionBar={
        <ActionBar isLoading={isLoading}>
          <ActionBarLeftSection>
            <ActionBarItem.BulkActions showDeleteComputers />
            <ActionBarItem.AddComputer />
            <RippleDownloadBusinessApp emphasis="secondary" />
            <ActionBarItem.Refresh onClick={handleRefresh} />
            <ActionBarItem.SelectedComputerCount />
          </ActionBarLeftSection>
          <ActionBarRightSection>
            <ActionBarItem.ViewSwitch />
            <ActionBarItem.ViewSettings />
            <ActionBarItem.FilterSettings />
            <ActionBarItem.ExportActions />
            <ActionBarItem.SearchBar />
          </ActionBarRightSection>
        </ActionBar>
      }
    />
  );
}

export function AllComputersSBA(): React.JSX.Element {
  const columns = useColumns_SBA();
  const queryClient = useQueryClient();

  const columnPermissions = useContext(ColumnPermissionsContext);

  const configurableColumns: Array<ConfigurableColumn> = useConfigurableColumnsSBA(columnPermissions);

  const { computerListData, isFetching: isFetchingComputerData, refresh: refreshComputerData } = useComputerDataSBA();
  const { groupList, groupListQuery, groupListQueryKey } = useGroupData();
  const resetUpdateQueue = useSetAtom(resetUpdateQueueAtom);
  const policyMapQuery = usePolicyMapQuery(); // data for EMM policy column
  const { policyAccessQuery } = useEmmPolicyAccess();
  const canAccessEmmPolicy = policyAccessQuery.isFetched && policyAccessQuery.data && policyAccessQuery.data.canAccess;

  const isLoading = groupListQuery.isFetching || isFetchingComputerData || policyMapQuery.isFetching;

  function handleRefresh() {
    if (columnPermissions.group) {
      queryClient.removeQueries({ queryKey: groupListQueryKey, exact: true });
      groupListQuery.refetch();
    }

    if (canAccessEmmPolicy) {
      policyMapQuery.refetch();
    }
    refreshComputerData();

    resetUpdateQueue();
  }

  return (
    <AllComputersCore
      computerListData={computerListData}
      groupList={groupList}
      isLoading={isLoading}
      configurableColumns={configurableColumns}
      columns={columns}
      actionBar={
        <ActionBar isLoading={isLoading}>
          <ActionBarLeftSection>
            <ActionBarItem.BulkActions showDeleteComputers />
            <ActionBarItem.AddComputer />
            <RippleDownloadBusinessApp emphasis="secondary" />
            <ActionBarItem.Refresh onClick={handleRefresh} />
            <ActionBarItem.SelectedComputerCount />
          </ActionBarLeftSection>
          <ActionBarRightSection>
            <ActionBarItem.ViewSwitch />
            <ActionBarItem.ViewSettings />
            <ActionBarItem.FilterSettings />
            <ActionBarItem.ExportActions />
            <ActionBarItem.SearchBar />
          </ActionBarRightSection>
        </ActionBar>
      }
    />
  );
}

type AllComputersCoreProps = {
  computerListData: Array<ComputerData>;
  groupList: Array<Pick<RawGroup, 'id' | 'name'>>;
  isLoading: boolean;
  configurableColumns: Array<ConfigurableColumn>;
  columns: Array<ColumnDef<ComputerData, unknown>>;
  actionBar: React.ReactNode;
};
function AllComputersCore(props: AllComputersCoreProps): React.JSX.Element {
  const { computerListData, groupList, isLoading, configurableColumns, columns, actionBar } = props;

  const columnPermissions = useContext(ColumnPermissionsContext);

  useInitializeViewMode({ storageKey: 'splashtop__allComputers__viewMode', hasPermission: columnPermissions.group });
  const storedColumnVisibilityAtom = useInitializeColumnVisibility({ storageKey: 'splashtop__allComputers__column', configurableColumns });
  useInitializeExpandedState({ storageKey: 'splashtop__allComputers__expandedState', hasPermission: columnPermissions.group });
  useInitializeSearch({ enable: true });
  useInitializeSortingState(columnPermissions);
  useInitializeFilterGroup();
  useInitializeFilterPlatform({ enable: true });
  useInitializeFilterColumnState({ enable: true, storedColumnVisibilityAtom });

  return (
    <PageFrame>
      <ComputerListProvider scope="team" isLoading={isLoading} data={computerListData} groupList={groupList}>
        <StickyLeftWrapper position="sticky" top="54px" zIndex={10}>
          {actionBar}
        </StickyLeftWrapper>
        <ComputerList columns={columns} />
      </ComputerListProvider>

      <TableSettingsDrawer>
        <TableSettingsSection.ViewSwitch />
        {FT_PHASE_2 && <TableSettingsSection.RowHeightSetting />}
        <TableSettingsSection.ColumnVisibilitySetting configurableColumns={configurableColumns} />
      </TableSettingsDrawer>
      <FiltersDrawer>
        {columnPermissions.group && <FiltersDrawerSections.FilterGroup />}
        <FiltersDrawerSections.FilterPlatform />
        <FiltersDrawerSections.FilterSelected />
        {columnPermissions.preferencePolicy && <FiltersDrawerSections.FilterPreferencePolicy />}
        <FiltersDrawerSections.FilterLastOnline />
      </FiltersDrawer>
    </PageFrame>
  );
}
