import { useRef } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';
import { useAtomValue } from 'jotai';

import { leftOffsetAtom, scrollContainerWidthAtom } from '../atoms';

export function StickyLeftWrapper({ children, ...otherProps }: BoxProps): React.JSX.Element {
  const leftOffset = useAtomValue(leftOffsetAtom);
  const scrollContainerWidth = useAtomValue(scrollContainerWidthAtom);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapperSize = useSize(wrapperRef);
  const wrapperWidth = wrapperSize ? `${wrapperSize?.width}px` : 'fit-content';

  const childrenContainerRef = useRef<HTMLDivElement>(null);
  const childrenContainerSize = useSize(childrenContainerRef);
  const childrenHeight = childrenContainerSize ? `${childrenContainerSize.height}px` : '0px';

  return (
    <Box ref={wrapperRef} position="relative" w="100%" h={childrenHeight} {...otherProps}>
      <Box position="absolute" top="0px" left="0px" w={scrollContainerWidth}>
        <Box ref={childrenContainerRef} position="sticky" left={`${leftOffset}px`} w={wrapperWidth}>
          {children}
        </Box>
      </Box>
    </Box>
  );
}
