import FullPageLoading from '@/components/FullPageLoading';

import { ColumnPermissionsContext } from '../contexts';
import type { ColumnPermissions } from '../types';

type ColumnPermissionsProviderProps = { value: ColumnPermissions | null; children?: React.ReactNode };
export function ColumnPermissionsProvider({ value, children }: ColumnPermissionsProviderProps): React.JSX.Element {
  if (value === null) return <FullPageLoading />;

  return <ColumnPermissionsContext.Provider value={value}>{children}</ColumnPermissionsContext.Provider>;
}
