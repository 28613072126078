import { type TeamMemberRoleTag, teamMemberRoleTagMapper } from '@/showcase';

import type { User } from './types';

export const generateRowId = {
  teamMember: (memberId: number) => `teamMember_${memberId}` as const,
  externalUser: (userId: number) => `externalUser_${userId}` as const,
};

export function computeArrayDiff<T>(prev: Array<T>, next: Array<T>): { add: Array<T>; remove: Array<T> } {
  return {
    add: next.filter((item) => !prev.includes(item)),
    remove: prev.filter((item) => !next.includes(item)),
  };
}

export function getRoleTag(user: User): TeamMemberRoleTag | null {
  if (user.type === 'external_user') return null;

  if (user.role === undefined || user.group_scope === undefined) return null;

  return teamMemberRoleTagMapper({
    role: user.role,
    group_scope: user.group_scope,
    super_admin: user.super_admin,
  });
}
