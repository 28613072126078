import { useBreakpointValue } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleAdd, RippleButton, RippleButtonText } from '@/design';
import type { RippleButtonProps } from '@/design';

type AddComputerBtnProps = {
  onClick: () => void;
};

export const AddComputerBtn = ({ onClick }: AddComputerBtnProps): React.JSX.Element => {
  const { t } = useTranslation(['setup']);
  const buttonSize = useBreakpointValue<RippleButtonProps['size']>({ base: 'sm', sm: 'xs' });

  return (
    <RippleButton size={buttonSize} onClick={onClick} leftIcon={<RippleAdd color="white" />} variant="primary" pl="9px" pr="11px">
      <RippleButtonText color="white">{t('setup:addComputer')}</RippleButtonText>
    </RippleButton>
  );
};
