import { MouseEventHandler, forwardRef } from 'react';

import { Box, FormControl } from '@chakra-ui/react';

import { RippleInput, RippleInputProps } from '@/design';
import { DragButton } from '@/modules/ServiceDesk/SurveyForm/Editor/FormEditor/EditMode/DragWrapper';
import { Label } from '@/modules/ServiceDesk/SurveyForm/Editor/FormEditor/ViewMode/Label';
import { INPUT_MAX_LENGTH } from '@/modules/ServiceDesk/SurveyForm/Editor/constant';
import { SurveyFormScenario, SurveyFormScenarioMap } from '@/modules/ServiceDesk/SurveyForm/Editor/types';

type ShortTextFieldProps = RippleInputProps & {
  labelValue: string;
  onClickLabel?: MouseEventHandler<HTMLLabelElement>;
  scenario: SurveyFormScenario;
};

export const ShortTextField = forwardRef(
  ({ isRequired, labelValue, onClickLabel, onMouseDown, placeholder, scenario, ...inputProps }: ShortTextFieldProps, ref) => {
    return (
      <Box w="100%" p="16px 48px 24px" position="relative" role="group">
        {scenario === SurveyFormScenarioMap.editor && <DragButton />}
        <FormControl isRequired={isRequired} id={labelValue}>
          <Label title={labelValue} scenario={scenario} onMouseDown={onClickLabel} />
          <RippleInput
            aria-describedby={labelValue}
            mt="4px"
            maxLength={INPUT_MAX_LENGTH}
            size="sm"
            fontSize="14px"
            type="text"
            placeholder={placeholder}
            _hover={scenario === SurveyFormScenarioMap.editor ? { bg: 'blue.0', cursor: 'pointer' } : {}}
            onMouseDown={onMouseDown}
            ref={ref}
            {...inputProps}
          />
        </FormControl>
      </Box>
    );
  },
);
