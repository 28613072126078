import { Row } from '@tanstack/react-table';

import { getOSIconType } from '@/utils/computers';

import { ComputerItemBase, FilterPlatformValue } from '../types';

export function filterPlatformFn(filterValue: FilterPlatformValue, row: Row<ComputerItemBase>): boolean {
  if (filterValue.length === 0) {
    return true;
  }

  const platform = getOSIconType(row.original.version);

  return filterValue.includes(platform);
}
