import { useContext, useEffect, useRef } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { toggleExpandAllGroupsAtom, toggleViewModeAtom, viewModeAtom } from '../atoms';
import { useFiltersDrawer, useTableSettingsDrawer } from '../components';
import { ColumnPermissionsContext } from '../contexts';

type ShortcutActions = {
  onToggleFilter?: () => void;
  onToggleTableSettings?: () => void;
  onToggleExpandGroup?: () => void;
  onToggleViewMode?: () => void;
};

const ShortcutMaps: Record<
  string,
  Array<{
    holdKeys?: Array<string | Array<string>>;
    action: keyof ShortcutActions;
  }>
> = {
  KeyF: [
    {
      action: 'onToggleFilter',
    },
  ],
  KeyV: [
    {
      action: 'onToggleTableSettings',
    },
    {
      holdKeys: [['ShiftLeft', 'ShiftRight']],
      action: 'onToggleViewMode',
    },
  ],
  KeyG: [
    {
      action: 'onToggleExpandGroup',
    },
  ],
};

export const ComputerShortcuts = () => {
  const columnPermissions = useContext(ColumnPermissionsContext);

  const holdKeysHashMap = useRef<Record<string, boolean>>({});
  const { toggle: onToggleTableSettings } = useTableSettingsDrawer();
  const { toggle: onToggleFilter } = useFiltersDrawer();
  const onToggleExpandAllGroups = useSetAtom(toggleExpandAllGroupsAtom);
  const onToggleViewMode = useSetAtom(toggleViewModeAtom);
  const viewMode = useAtomValue(viewModeAtom);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (document.activeElement?.tagName === 'INPUT' || document.activeElement?.tagName === 'TEXTAREA') {
        return;
      }

      holdKeysHashMap.current[event.code] = true;

      const shortcuts = ShortcutMaps[event.code];
      if (!shortcuts) {
        return;
      }

      const executeShortcut = shortcuts.find((shortcut) => {
        const holdKeys = shortcut?.holdKeys ?? [];
        const hasSameLength = Object.keys(holdKeysHashMap.current).length === holdKeys.length + 1;
        const hasHoldKeys = holdKeys.every((keys) => {
          if (typeof keys === 'string') {
            return holdKeysHashMap.current[keys];
          }

          return keys.some((key) => holdKeysHashMap.current[key]);
        });

        return hasSameLength && hasHoldKeys;
      });

      if (executeShortcut?.action === 'onToggleFilter') {
        onToggleFilter();
      } else if (executeShortcut?.action === 'onToggleTableSettings') {
        onToggleTableSettings();
      } else if (executeShortcut?.action === 'onToggleExpandGroup' && viewMode === 'group') {
        onToggleExpandAllGroups();
      } else if (executeShortcut?.action === 'onToggleViewMode' && columnPermissions.group) {
        onToggleViewMode();
      }
    };

    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [onToggleFilter, onToggleTableSettings, onToggleExpandAllGroups, onToggleViewMode, viewMode, columnPermissions]);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      delete holdKeysHashMap.current[event.code];
    };

    window.addEventListener('keyup', handler);
    return () => {
      window.removeEventListener('keyup', handler);
    };
  }, []);

  return null;
};
