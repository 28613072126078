/**
 * check if file size is less or equal then `maxSize`
 *
 * @param {File} file
 * @param {number} maxSize - The unit is byte.
 */
export function fileSize(file: File, maxSize: number): boolean {
  return file.size <= maxSize;
}

/**
 * check if file format is valid
 *
 * @param {File} file
 * @param {string} format - MIME type or file extension
 */
export function fileFormat(file: File, format: string | Array<string>): boolean {
  const fileType = file.type;
  const fileExtension = file.name.slice(file.name.lastIndexOf('.'));

  let result = false;
  if (Array.isArray(format)) {
    result = format.includes(fileType) || format.includes(fileExtension);
  } else {
    result = fileType === format || fileExtension === format;
  }

  return result;
}
