import axios from 'axios';

import { ResponseData } from '@/services/common/types';
import { checkResponse } from '@/services/utils';

import { SurveyList, SurveyListResponse, surveyListResponseSchema } from './types';

export const getSurveyList = async (teamId: number): Promise<SurveyListResponse> =>
  await checkResponse(
    axios.get<ResponseData & { data: { surveys: SurveyList } }>(`/api/web/v1/teams/${teamId}/service_desk/surveys`),
    surveyListResponseSchema,
  );
