import { Box, CSSWithMultiValues, Flex } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleButton, RippleFilter, RippleTypography } from '@/design';

type ComputerFilterAlertProps = {
  haveFilterApplied: boolean;
  isNotFound: boolean;
  onShowAll: () => void;
  sx?: CSSWithMultiValues;
};

export function ComputerFilterAlert(props: ComputerFilterAlertProps) {
  const { haveFilterApplied, isNotFound, onShowAll, sx } = props;
  const { t } = useTranslation();

  if (!haveFilterApplied || isNotFound) {
    return null;
  }

  return (
    <Flex
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      borderWidth="1px"
      borderStyle="solid"
      boxShadow="0px 8px 16px 4px rgba(0, 0, 0, 0.12)"
      borderRadius="4px"
      padding="7px 12px"
      borderColor="blue.200"
      backgroundColor="blue.5"
      sx={sx}
    >
      <Box flexShrink={0}>
        <RippleFilter color="blue.200" isApplied />
      </Box>
      <RippleTypography variant="body02" ml="8px" color="blue.200">
        {t('computer:selector.youHaveFiltersApplied')}
      </RippleTypography>
      <RippleButton size="xs" height="26px" ml="12px" textTransform="capitalize" onClick={onShowAll}>
        {t('computer:selector.showAll')}
      </RippleButton>
    </Flex>
  );
}
