import { useMemo, useState } from 'react';

import { Stack } from '@chakra-ui/react';
import { ColumnSort, createColumnHelper, getSortedRowModel } from '@tanstack/react-table';
import { useAtomValue } from 'jotai';
import { Trans, useTranslation } from 'next-i18next';

import {
  RippleBadge,
  RippleButton,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalSubTitle,
  RippleModalTitle,
  RippleTooltip,
  RippleTypography,
  RippleVirtuosoReactTable,
} from '@/design';
import { Group } from '@/services/group/types';

import { assignPolicyResultAtom } from '../atoms';
import { ComputerDataForPolicy } from '../hooks';

const SUCCESS = 'success' as const;
const FAILED = 'failed' as const;

type GroupResult = Pick<Group, 'id' | 'name'> & {
  result: typeof SUCCESS | typeof FAILED;
};

type ComputerResult = ComputerDataForPolicy & {
  result: typeof SUCCESS | typeof FAILED;
};

type AssignPolicyResultModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const AssignPolicyResultModal = ({ isOpen, onClose }: AssignPolicyResultModalProps) => {
  const { t } = useTranslation();
  const [sortState, setSortState] = useState<Array<ColumnSort>>([]);
  const { groups, computers } = useAtomValue(assignPolicyResultAtom);
  const groupsResult: Array<GroupResult> = useMemo(
    () => [
      ...groups.fail.map((group) => ({ ...group, result: FAILED })),
      ...groups.success.map((group) => ({ ...group, result: SUCCESS })),
    ],
    [groups.fail, groups.success],
  );
  const computersResult: Array<ComputerResult> = useMemo(
    () => [
      ...computers.fail.map((computer) => ({ ...computer, result: FAILED })),
      ...computers.success.map((computer) => ({ ...computer, result: SUCCESS })),
    ],
    [computers.fail, computers.success],
  );
  const totalFailed = groups.fail.length + computers.fail.length;
  const totalSucceeded = groups.success.length + computers.success.length;
  const totalResult = totalFailed + totalSucceeded;

  const groupColumns = useGroupColumns();
  const computerColumns = useComputerColumns();
  return (
    <RippleModal size="2xl" isOpen={isOpen} onClose={onClose}>
      <RippleModalOverlay />
      <RippleModalContent>
        <RippleModalHeader>
          <RippleModalTitle>Policy Assignment Result</RippleModalTitle>
          <RippleModalSubTitle>
            <Trans
              t={t}
              i18nKey="common:count_in_total_count_success_failed"
              values={{
                total: totalResult,
                success: totalSucceeded,
                failed: totalFailed,
              }}
              components={{
                FailedText: <RippleTypography variant="body02" as="span" color={totalFailed > 0 ? 'red.100' : 'inherit'} />,
              }}
            />
          </RippleModalSubTitle>
        </RippleModalHeader>
        <RippleModalBody>
          <Stack gap="16px">
            <RippleVirtuosoReactTable
              data={groupsResult}
              columns={groupColumns}
              tableOptions={{
                enableSorting: true,
                state: {
                  sorting: sortState,
                },
                onSortingChange: setSortState,
                getSortedRowModel: getSortedRowModel(),
              }}
              height="211px"
              variant="compact"
              colorScheme="white"
            />
            <RippleVirtuosoReactTable
              data={computersResult}
              columns={computerColumns}
              tableOptions={{
                enableSorting: true,
                state: {
                  sorting: sortState,
                },
                onSortingChange: setSortState,
                getSortedRowModel: getSortedRowModel(),
              }}
              height="211px"
              variant="compact"
              colorScheme="white"
            />
          </Stack>
        </RippleModalBody>
        <RippleModalFooter>
          <RippleButton variant="primary" size="sm" onClick={onClose}>
            {t('common:ok')}
          </RippleButton>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
};

export const useGroupColumns = () => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<GroupResult>();
  return useMemo(
    () => [
      columnHelper.accessor('name', {
        size: 300,
        header: t('emm-policy:group'),
        cell: ({ getValue }) => (
          <RippleTooltip label={getValue()} aria-label={getValue()}>
            <RippleTypography noOfLines={1} variant="body02">
              {getValue()}
            </RippleTypography>
          </RippleTooltip>
        ),
      }),
      columnHelper.accessor('result', {
        size: 162,
        header: t('common:status'),
        cell: ({ getValue }) =>
          getValue() === SUCCESS ? (
            <RippleBadge variant="secondary" colorScheme="success">
              {t('common:success')}
            </RippleBadge>
          ) : (
            <RippleBadge variant="secondary" colorScheme="danger">
              {t('common:failed')}
            </RippleBadge>
          ),
      }),
    ],
    [columnHelper, t],
  );
};

export const useComputerColumns = () => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<ComputerResult>();
  return useMemo(
    () => [
      columnHelper.accessor('name', {
        size: 300,
        header: t('emm-policy:computer'),
        cell: ({ getValue }) => (
          <RippleTooltip label={getValue()} aria-label={getValue()}>
            <RippleTypography noOfLines={1} variant="body02">
              {getValue()}
            </RippleTypography>
          </RippleTooltip>
        ),
      }),
      columnHelper.accessor('result', {
        size: 162,
        header: t('common:status'),
        cell: ({ getValue }) =>
          getValue() === SUCCESS ? (
            <RippleBadge variant="secondary" colorScheme="success">
              {t('common:success')}
            </RippleBadge>
          ) : (
            <RippleBadge variant="secondary" colorScheme="danger">
              {t('common:failed')}
            </RippleBadge>
          ),
      }),
    ],
    [columnHelper, t],
  );
};
