import { z } from 'zod';

import { commonResponseSchema } from '@/services/schemas';
import { QuestionnaireKind } from '@/services/serviceDesk/surveys/type';

export type SubmitQuestion = {
  id: number;
  kind: QuestionnaireKind;
  answer: number | string | Array<string> | null;
};

export type SubmitSurveyPayload = {
  questionnaire: Array<SubmitQuestion>;
};

export const submitSurveyErrorSchema = commonResponseSchema.merge(
  z.object({
    result: z.union([z.literal(40422), z.literal(40404)]),
  }),
);
