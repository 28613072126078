import { useTranslation } from 'next-i18next';

import { RippleIconButton, RippleTableSetting, RippleTooltip } from '@/design';

import { useTableSettingsDrawer } from '../TableSettingsDrawer';
import { useActionBarContext } from './hooks';

type ViewSettingsProps = { isDisabled?: boolean };
export function ViewSettings({ isDisabled = false }: ViewSettingsProps): React.JSX.Element {
  const { t } = useTranslation();

  const { isLoading } = useActionBarContext();

  const tableSettingsDrawer = useTableSettingsDrawer();

  return (
    <RippleTooltip label={t('computer:tableSetting.title')} shortcut="V">
      <RippleIconButton
        data-testid="view-settings"
        aria-label="view setting"
        icon={<RippleTableSetting />}
        onClick={tableSettingsDrawer.open}
        isDisabled={isDisabled || isLoading}
      />
    </RippleTooltip>
  );
}
