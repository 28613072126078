function* taskIdGenerator(): Generator<number, number> {
  let id = 0;
  while (true) {
    yield id++;
  }
}

type TaskParams<T> = {
  task: () => Promise<T>;
  condition: (result: T) => boolean;
  onSuccess: (result: T) => void;
  retryDelay?: number;
  maxAttempts?: number;
};

export class RetryQueue {
  public tasks: Array<{ taskId: number; execute: () => Promise<void> }> = [];
  private isCancelled = false;
  private generateTaskId = taskIdGenerator();

  enqueue<T>(params: Omit<TaskParams<T>, 'attempts'>) {
    this.isCancelled = false;
    const taskId = this.generateTaskId.next().value;
    const execute = () => this.executeTask({ ...params, attempts: 0, taskId });
    this.tasks.push({ taskId, execute });
    execute();
  }

  cancel() {
    this.isCancelled = true;
  }

  clear() {
    this.tasks = [];
  }

  reset() {
    this.clear();
    this.cancel();
  }

  private async executeTask<T>(params: TaskParams<T> & { taskId: number; attempts: number }): Promise<void> {
    if (this.isCancelled || (params.maxAttempts !== undefined && params.attempts >= params.maxAttempts)) return;

    const result = await params.task();
    const isSuccess = params.condition(result);

    if (isSuccess) {
      params.onSuccess(result);
      // 任務成功後，從隊列中移除
      const index = this.tasks.findIndex((task) => task.taskId === params.taskId);
      if (index > -1) {
        this.tasks.splice(index, 1);
      }
    } else if (params.retryDelay) {
      await new Promise((resolve) => setTimeout(resolve, params.retryDelay));
      return this.executeTask({ ...params, attempts: params.attempts + 1 });
    }
  }
}
