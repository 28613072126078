import { Fragment, useState } from 'react';

import { Box, Center, Flex, HStack, VStack, useToken } from '@chakra-ui/react';
import type { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useTranslation } from 'next-i18next';
import { UseFormRegisterReturn, useFormContext } from 'react-hook-form';

import { RippleAdd, RippleBodyText02, RippleIconButton, RippleInput, RippleMinus, RippleRadio, RippleTextField } from '@/design';
import { ActionBar } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/EditMode/ActionBar';
import { DragButton, DragListWrapper, DragWrapper } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/EditMode/DragWrapper';
import { FieldTitle } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/EditMode/FieldTitle';
import { Highlighter } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/EditMode/Highlighter';
import {
  LABEL_MAX_LENGTH,
  MAX_OPTION_COUNT,
  NO_DEFAULT_OPTION,
  OPTION_MAX_LENGTH,
} from '@/modules/ServiceDesk/SupportForm/Editor/constants';
import { useCurrentOpenFieldAtom } from '@/modules/ServiceDesk/SupportForm/Editor/hooks/useEditingForm';
import { useScrollToLastField } from '@/modules/ServiceDesk/SupportForm/Editor/hooks/useScrollToLastField';
import { SupportFormValue } from '@/modules/ServiceDesk/SupportForm/Editor/types';
import preSanitize from '@/utils/pre-sanitize';

type RadioButtonFieldEditModeProps = {
  fieldIndex: number;
  isRequireProps: UseFormRegisterReturn;
  labelProps: UseFormRegisterReturn;
  onDelete: () => void;
  onSave: () => void;
  options: Array<{ option: string }> | null;
  updateOptions: (options: Array<{ option: string }> | null) => void;
};

export function RadioButtonFieldEditMode({
  fieldIndex,
  isRequireProps,
  labelProps,
  onDelete,
  onSave,
  options,
  updateOptions,
}: RadioButtonFieldEditModeProps) {
  const { t } = useTranslation();
  const { labelRef } = useScrollToLastField();
  const [blue100] = useToken('colors', ['blue.100']);

  const currentOpenField = useCurrentOpenFieldAtom();
  const { setValue, watch } = useFormContext<SupportFormValue>();

  const defaultOption = watch(`questionnaire.${fieldIndex}.default_option`);

  const handleRadioChange = (value: string) => {
    setValue(`questionnaire.${fieldIndex}.default_option`, defaultOption === value ? NO_DEFAULT_OPTION : value);
  };

  // Handle add option logic
  const [newOption, setNewOption] = useState('');
  const [isSanitizedNewOptionError, setIsSanitizedNewOptionError] = useState(false);

  const newOptionSanitized = preSanitize(newOption).trim();

  const isNewOptionDuplicate = options?.some(({ option }) => option === newOption) ?? false;
  const isNewOptionSanitizedDuplicate = options?.some(({ option }) => option === newOptionSanitized) ?? false;

  const isDuplicateError = isNewOptionDuplicate || isSanitizedNewOptionError;
  const isReachOptionAmountLimit = options?.length === MAX_OPTION_COUNT;
  const isDisabledAddOptionButton = newOption === '' || isDuplicateError || isReachOptionAmountLimit;

  const addNewOption = () => {
    if (newOptionSanitized === '') {
      setNewOption('');
    } else if (isNewOptionSanitizedDuplicate) {
      setNewOption(newOptionSanitized);
      setIsSanitizedNewOptionError(true);
    } else {
      updateOptions(options ? [...options, { option: newOptionSanitized }] : [{ option: newOptionSanitized }]);
      setNewOption('');
    }
  };

  // Drag & Drop methods
  const handleDragEnd = (event: DragEndEvent) => {
    if (!options || !(event.active && event.over)) return;

    const { active, over } = event;

    if (active.id !== over.id) {
      const originalIndex: number = active.data.current?.sortable.index;
      const droppedIndex: number = over.data.current?.sortable.index;
      updateOptions(arrayMove(options, originalIndex, droppedIndex));
    }
  };

  return (
    <Box w="100%" p="0px 36px 12px" position="relative">
      <Highlighter />
      <FieldTitle title={t('support-forms:radio_button')} />
      <RippleInput
        autoFocus={currentOpenField?.autoFocusType === 'label'}
        id="edit-radio"
        aria-describedby="edit-radio"
        variant="flushed"
        mt="4px"
        mb="12px"
        fontSize="12px"
        size="sm"
        type="text"
        maxLength={LABEL_MAX_LENGTH}
        placeholder={t('support-forms:addName')}
        sx={{
          caretColor: blue100,
        }}
        _focus={{ borderColor: isDuplicateError ? 'red.100' : 'blue.100' }}
        _placeholder={{
          fontStyle: 'italic',
          fontWeight: 'normal',
          fontSize: '14px',
          color: 'dark.40',
        }}
        {...labelProps}
        ref={(e) => {
          labelProps.ref(e);
          labelRef.current = e;
        }}
      />
      <>
        <Flex alignItems="flex-start" mb="12px" gap="8px" justify="space-between">
          <RippleTextField
            autoFocus={currentOpenField?.autoFocusType === 'input'}
            id="edit-radio-options"
            aria-describedby="edit-radio-options"
            value={newOption}
            onChange={(e) => {
              setIsSanitizedNewOptionError(false);
              setNewOption(e.target.value);
            }}
            maxLength={OPTION_MAX_LENGTH}
            placeholder={t('support-forms:addAnOption')}
            size="sm"
            type="text"
            sx={{ width: '100%' }}
            _placeholder={{
              color: 'dark.40',
              fontStyle: 'italic',
              fontWeight: 400,
            }}
            isInvalid={isNewOptionDuplicate}
            helperText={isDuplicateError ? t('support-forms:optionDuplicated') : ''}
          />
          <HStack h="40px">
            <RippleIconButton
              isDisabled={isDisabledAddOptionButton}
              variant="ghost"
              aria-label="add option"
              icon={<RippleAdd color={isDisabledAddOptionButton ? 'dark.40' : 'blue.100'} />}
              onClick={addNewOption}
            />
            <RippleBodyText02 w="48px" textAlign="center">
              {t('common:default')}
            </RippleBodyText02>
          </HStack>
        </Flex>

        <VStack spacing="8px" alignItems="stretch" mb="8px">
          {options && (
            <DragListWrapper
              items={options.map((optionInfo) => {
                // @dnd-kit needs `id` for identification
                return { ...optionInfo, id: optionInfo.option };
              })}
              onDragEnd={handleDragEnd}
            >
              <Fragment>
                {options.map(({ option }) => (
                  <DragWrapper key={option} id={option} style={{ _hover: { bg: 'neutral.10' } }}>
                    <Flex gap="8px" alignItems="center" position="relative">
                      <DragButton style={{ left: '0px', opacity: 1, _hover: { bg: 'transparent' } }} />
                      <RippleBodyText02 flex="1" pl="40px" wordBreak="break-all">
                        {option}
                      </RippleBodyText02>
                      <RippleIconButton
                        variant="ghost"
                        aria-label="remove-option"
                        icon={<RippleMinus color="neutral.300" />}
                        onClick={() => {
                          if (defaultOption === option) {
                            setValue(`questionnaire.${fieldIndex}.default_option`, NO_DEFAULT_OPTION);
                          }
                          updateOptions(options ? options.filter((item) => item.option !== option) : null);
                        }}
                      />
                      <Center w="48px">
                        <RippleRadio value={option} isChecked={option === defaultOption} onClick={() => handleRadioChange(option)} />
                      </Center>
                    </Flex>
                  </DragWrapper>
                ))}
              </Fragment>
            </DragListWrapper>
          )}
        </VStack>
      </>
      <ActionBar isShowRequired switchProps={isRequireProps} onDelete={onDelete} onSave={onSave} />
    </Box>
  );
}
