import { createContext, useContext, useEffect, useId, useRef, useState } from 'react';
import type { ComponentProps, ReactNode } from 'react';

import { Box, BoxProps, Flex, SystemStyleObject, usePopoverContext } from '@chakra-ui/react';
import type { FlexProps, PopoverProps } from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';
import { createPortal } from 'react-dom';

import {
  RippleArrowLeft,
  RippleArrowRight,
  RippleBodyText02,
  RippleButton,
  RippleIconButton,
  RippleInfo16,
  RipplePopover,
  RipplePopoverContent,
  RipplePopoverTrigger,
  RippleStrong,
  RippleTooltip,
  RippleTypography,
} from '@/design';
import type { RippleButtonProps, RippleTypographyProps } from '@/design';

const NestedMenuContext = createContext<{
  activeSubMenu: string | null;
  setActiveSubMenu: (menu: string | null) => void;
  resetSubMenu: () => void;
  menuHeight: number | undefined;
  setMenuHeight: (height: number | undefined) => void;
}>({} as never);

const NestedMenuListContext = createContext<{ ref: React.RefObject<HTMLDivElement> }>({} as never);

export type NestedMenuProps = PopoverProps;
export function NestedMenu({ onClose, ...otherProps }: NestedMenuProps): React.JSX.Element {
  const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null);
  const [menuHeight, setMenuHeight] = useState<number | undefined>(undefined);

  function resetSubMenu() {
    setActiveSubMenu(null);
  }

  return (
    <NestedMenuContext.Provider value={{ activeSubMenu, setActiveSubMenu, resetSubMenu, menuHeight, setMenuHeight }}>
      <RipplePopover
        onClose={() => {
          onClose?.();
          resetSubMenu();
        }}
        placement="bottom-end"
        {...otherProps}
      />
    </NestedMenuContext.Provider>
  );
}

export const NestedMenuTrigger = RipplePopoverTrigger;

type NestedMenuContentProps = ComponentProps<typeof RipplePopoverContent>;
export function NestedMenuContent(props: NestedMenuContentProps): React.JSX.Element {
  return <RipplePopoverContent className="nestedMenuContent" boxShadow="8px" w="336px" borderRadius="4px" zIndex={20} {...props} />;
}

type NestedMenuListProps = FlexProps;
export function NestedMenuList({ children, ...otherProps }: NestedMenuListProps): React.JSX.Element {
  const { menuHeight } = useContext(NestedMenuContext);
  const menuContainerRef = useRef<HTMLDivElement>(null);
  const submenuContainerRef = useRef<HTMLDivElement>(null);
  const menuContainerSize = useSize(menuContainerRef);
  const originHeight = menuContainerSize ? `${menuContainerSize.height}px` : undefined;

  const height = menuHeight ? `${menuHeight}px` : originHeight;

  return (
    <NestedMenuListContext.Provider value={{ ref: submenuContainerRef }}>
      <Flex
        className="nestedMenuList"
        h={height}
        w="100%"
        transition="all 0.3s ease-in-out"
        position="relative"
        overflow="hidden"
        {...otherProps}
      >
        <Flex ref={menuContainerRef} py="6px" h="fit-content" w="100%" flexDirection="column">
          {children}
        </Flex>
        <Box ref={submenuContainerRef} />
      </Flex>
    </NestedMenuListContext.Provider>
  );
}

export type NestedMenuItemProps = {
  label: ReactNode;
  isDisabled?: boolean;
  tooltip?: string | React.ReactNode;
  labelProps?: Omit<RippleTypographyProps, 'variant'>;
  buttonProps?: RippleButtonProps;
} & (
  | {
      subMenu?: false;
      onClick?: () => void;
    }
  | {
      subMenu: true;
      subMenuLabel?: string;
      children: React.ReactNode;
    }
);
export function NestedMenuItem({
  label,
  isDisabled,
  tooltip,
  labelProps,
  buttonProps,
  ...otherProps
}: NestedMenuItemProps): React.JSX.Element {
  const itemId = useId();
  const { onClose: onCloseMenu } = usePopoverContext();
  const { activeSubMenu, setActiveSubMenu, resetSubMenu } = useContext(NestedMenuContext);
  const { ref: menuContainerRef } = useContext(NestedMenuListContext);

  const sharedButtonProps: RippleButtonProps = {
    variant: 'ghost',
    display: 'flex',
    justifyContent: 'space-between',
    minW: '100%',
    minH: '32px',
    h: 'fit-content',
    pl: '12px',
    pr: '4px',
    py: '6px',
    border: 'none',
    borderRadius: '0px',
    color: 'dark.100',
    _focus: { outline: 'none', color: 'dark.100' },
    _hover: { color: 'dark.100', bgColor: 'blue.10' },
    _disabled: { color: 'dark.40', cursor: 'not-allowed' },
    isDisabled,
  };

  const labelElement = (
    <RippleBodyText02 color="inherit" whiteSpace="normal" wordBreak="break-all" {...labelProps}>
      {label}
      {tooltip && typeof tooltip === 'string' ? (
        <RippleTooltip label={tooltip}>
          <Box as="span" ml="4px">
            <RippleInfo16 />
          </Box>
        </RippleTooltip>
      ) : (
        tooltip
      )}
    </RippleBodyText02>
  );

  if (otherProps.subMenu) {
    const isOpen = activeSubMenu === itemId;
    return (
      <>
        <RippleButton
          {...sharedButtonProps}
          onClick={() => {
            setActiveSubMenu(itemId);
          }}
          {...buttonProps}
        >
          {labelElement}
          <RippleArrowRight />
        </RippleButton>
        {menuContainerRef.current &&
          createPortal(
            <SubMenu isOpen={isOpen}>
              <SubMenuHeader
                label={otherProps.subMenuLabel ?? label}
                onBack={() => {
                  resetSubMenu();
                }}
              />
              {otherProps.children}
            </SubMenu>,
            menuContainerRef.current,
            itemId,
          )}
      </>
    );
  }

  return (
    <RippleButton
      {...sharedButtonProps}
      onClick={() => {
        otherProps.onClick?.();
        onCloseMenu();
      }}
      {...buttonProps}
    >
      {labelElement}
    </RippleButton>
  );
}

type SubMenuProps = {
  isOpen: boolean;
  children?: React.ReactNode;
};
function SubMenu({ isOpen, children }: SubMenuProps): React.JSX.Element {
  const { setMenuHeight } = useContext(NestedMenuContext);

  const subMenuContainerRef = useRef<HTMLDivElement>(null);
  const subMenuContainerSize = useSize(subMenuContainerRef);

  useEffect(
    function setMenuHeightOnOpen() {
      if (isOpen) {
        setMenuHeight(subMenuContainerSize?.height);
      } else {
        setMenuHeight(undefined);
      }
    },
    [isOpen, subMenuContainerSize, setMenuHeight],
  );

  return (
    <Flex
      ref={subMenuContainerRef}
      flexDirection="column"
      justifyContent="start"
      alignItems="stretch"
      w={isOpen ? '336px' : '0px'}
      h="fit-content"
      maxH="400px"
      pb="6px"
      bgColor="white"
      borderRadius="4px"
      position="absolute"
      right="0"
      top="0"
      transition={`transform 0.3s ease-in-out, width ${isOpen ? '1ms' : '0.3s'} ease-in-out`}
      transform="auto"
      translateX={isOpen ? '0' : '100%'} // right to left
      scaleX={isOpen ? '1' : '0'}
      borderWidth="1px"
      borderStyle="solid"
      borderColor="neutral.60"
      overflowY="hidden"
      zIndex={2}
    >
      {children}
    </Flex>
  );
}

type SubMenuHeaderProps = {
  label: ReactNode;
  onBack: () => void;
};
function SubMenuHeader({ label, onBack }: SubMenuHeaderProps): React.JSX.Element {
  return (
    <Box position="sticky" top="0" bgColor="white" zIndex={10}>
      <Flex justifyContent="space-between" alignItems="center" w="100%" px="12px" pt="8px">
        <RippleIconButton aria-label="back" icon={<RippleArrowLeft />} onClick={onBack} />
        <RippleStrong as="p" variant="strong02">
          {label}
        </RippleStrong>
        <Box boxSize="32px" />
      </Flex>
      <Box my="6px" h="1px" w="100%" bgColor="neutral.60" />
    </Box>
  );
}

type NestedMenuDividerProps = BoxProps;
export function NestedMenuDivider(props: NestedMenuDividerProps): React.JSX.Element {
  return <Box my="6px" h="1px" w="100%" bgColor="neutral.60" {...props} />;
}

type NestedMenuGroupProps = { title?: string; emphasized?: boolean; sx?: SystemStyleObject; children?: React.ReactNode };
export function NestedMenuGroup({ title, emphasized, sx, children }: NestedMenuGroupProps): React.JSX.Element {
  return (
    <Flex
      className="nestedMenuGroup"
      flexDirection="column"
      sx={sx}
      _notFirst={{ mt: '6px' }}
      _notLast={{ pb: '6px', borderBottom: '1px solid', borderBottomColor: 'neutral.60' }}
    >
      {title && (
        <RippleTypography
          className="nestedMenuGroup--title"
          variant="heading09"
          color={emphasized ? 'blue.100' : 'neutral.100'}
          px="12px"
          py="5px"
        >
          {title}
        </RippleTypography>
      )}
      {children}
    </Flex>
  );
}
