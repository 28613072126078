import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useFormContext } from 'react-hook-form';

import { useSetCurrentOpenField } from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';
import { useValidateAndAutoFillEmptyField } from '@/modules/ServiceDesk/SurveyForm/Editor/hooks/useValidateAndAutoFillEmptyField';
import { SurveyFormValue } from '@/modules/ServiceDesk/SurveyForm/Editor/types';
import { BrandImageMap } from '@/services/serviceDesk/surveys/type';

import { BRAND_ICON_UUID } from '../constant';

export type CustomImageFileAtom = {
  url: string; // Base64
  name: string;
};
const customImageFileAtom = atomWithReset<CustomImageFileAtom>({ url: '', name: '' });
export const useCustomImageFile = () => useAtom(customImageFileAtom);
export const useCustomImageFileValue = () => useAtomValue(customImageFileAtom);
export const useSetCustomImageFile = () => useSetAtom(customImageFileAtom);
export const useResetCustomImageFile = () => useResetAtom(customImageFileAtom);

export const useBrandImageHandler = () => {
  const { validateAndAutoFillEmptyField } = useValidateAndAutoFillEmptyField();

  const { setValue, watch } = useFormContext<SurveyFormValue>();
  const setCurrentOpenField = useSetCurrentOpenField();

  const brandImage = watch('brand_image');

  const isShowBrandImage = brandImage !== null;
  const isShowSplashtopLogo = brandImage === BrandImageMap.splashtop;

  const addBrandImageField = () => {
    setValue('brand_image', BrandImageMap.splashtop);
    validateAndAutoFillEmptyField();
    setCurrentOpenField({ uuid: BRAND_ICON_UUID, autoFocusType: 'input' });
  };

  const deleteBrandImageField = () => {
    setValue('brand_image', null);
    validateAndAutoFillEmptyField({ isResetCurrentOpenField: true });
  };

  const handleBrandImageField = () => {
    if (!isShowBrandImage) {
      addBrandImageField();
    } else {
      deleteBrandImageField();
    }
  };

  const editBrandImageField = () => {
    validateAndAutoFillEmptyField();
    setCurrentOpenField({ uuid: BRAND_ICON_UUID, autoFocusType: 'input' });
  };

  return {
    brandImage,
    isShowBrandImage,
    isShowSplashtopLogo,
    deleteBrandImageField,
    handleBrandImageField,
    editBrandImageField,
  };
};
