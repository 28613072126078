/**
 * Get a qualified message to display
 * @see https://splashtop.atlassian.net/wiki/spaces/CS/pages/1127514774/User+Message+API
 */
import axios from 'axios';

import { checkResponse, createGenericErrorHandlers } from '@/services/utils';

import { MessageType, Page, ViewedMessages, messageSchema } from './schemas';

const getUrl = () => `/api/web/v1/users/get_message`;

const METHOD = 'GET';

const responseSchema = messageSchema;

const execute = ({ type, page, viewedMessages }: { type: MessageType; page: Page; viewedMessages?: ViewedMessages }) =>
  checkResponse(
    axios({
      method: METHOD,
      url: getUrl(),
      params: {
        type,
        page,
        ...(viewedMessages ? { viewed_messages: viewedMessages.join(',') } : {}),
      },
    }),
    responseSchema,
  );
const errorHandling = createGenericErrorHandlers();

export const getMessageService = { getUrl, execute, method: METHOD, responseSchema, errorHandling };
