import { MouseEventHandler } from 'react';

import { Box, FormControl } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { Controller, useFormContext } from 'react-hook-form';

import { RippleSingleSelect, RippleSingleSelectOption, RippleSingleSelectProps } from '@/design';
import { DragButton } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/EditMode/DragWrapper';
import { Label } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/ViewMode/Label';
import { SupportFormScenario, SupportFormScenarioMap } from '@/modules/ServiceDesk/SupportForm/Editor/types';
import { QuestionnaireFieldOption } from '@/services/serviceDesk/supportForm/types';

type SingleSelectFieldProps = {
  registerFieldName: string;
  defaultValue: RippleSingleSelectProps['defaultValue'];
  isRequired: RippleSingleSelectProps['isRequired'];
  isInvalid?: RippleSingleSelectProps['isInvalid'];
  labelValue: string;
  onClickLabel?: MouseEventHandler<HTMLLabelElement>;
  onMouseDown?: RippleSingleSelectProps['onMouseDown'];
  options: Array<QuestionnaireFieldOption> | null;
  scenario: SupportFormScenario;
};

export const SingleSelectField = ({
  registerFieldName,
  defaultValue,
  isRequired,
  isInvalid,
  labelValue,
  onClickLabel,
  onMouseDown,
  options,
  scenario,
}: SingleSelectFieldProps) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  const optionsSection =
    options &&
    options.map(({ option }) => (
      <RippleSingleSelectOption key={option} value={option}>
        {option}
      </RippleSingleSelectOption>
    ));
  return (
    <Box w="100%" p="8px 36px 12px" position="relative" role="group">
      {scenario === SupportFormScenarioMap.pcp && <DragButton />}
      <FormControl isRequired={isRequired} isInvalid={isInvalid}>
        <Label title={labelValue} scenario={scenario} onMouseDown={onClickLabel} />
        {scenario === SupportFormScenarioMap.pcp ? (
          <RippleSingleSelect
            id="combo-box-single-select"
            aria-describedby="combo-box-single-select"
            placeholder={t('support-forms:select')}
            _hover={{ bg: 'blue.0', cursor: 'pointer' }}
            borderColor="neutral.300"
            menuProps={{ isOpen: false }}
            onMouseDown={onMouseDown}
            value={defaultValue ? String(defaultValue) : t('support-forms:select')}
            onChange={() => null}
          >
            {optionsSection}
          </RippleSingleSelect>
        ) : (
          <Controller
            name={registerFieldName}
            control={control}
            // To align with the API specification, an empty string as the default value indicates there is no default value
            defaultValue={defaultValue === '' ? undefined : defaultValue?.toString()}
            rules={{ required: isRequired }}
            render={({ field }) => {
              return (
                <RippleSingleSelect
                  id="combo-box-single-select"
                  aria-describedby="combo-box-single-select"
                  placeholder={t('support-forms:select')}
                  borderColor={isInvalid ? 'red.100' : 'neutral.300'}
                  onMouseDown={onMouseDown}
                  {...field}
                >
                  {optionsSection}
                </RippleSingleSelect>
              );
            }}
          />
        )}
      </FormControl>
    </Box>
  );
};
