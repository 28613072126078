import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';

import { useRippleFlashMessage } from '@/design';
import { useErrorModal } from '@/modules/ServiceDesk/SurveyForm/Editor/ErrorModal';
import { useUploadImageToS3 } from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';
import type { ErrorResponse } from '@/services/common/types';
import { updateSurvey, updateSurveyErrorSchema } from '@/services/serviceDesk/surveys';
import { SurveyFormAPIValue } from '@/services/serviceDesk/surveys/type';

const SURVEY_LIST_PATH = '/service_desk/surveys';

export const useUpdateSurvey = ({ teamId, customImageBase64 }: { teamId?: number; customImageBase64: string }) => {
  const { t } = useTranslation();
  const routers = useRouter();

  const handleError = useErrorHandler();
  const { openErrorModal, closeErrorModal } = useErrorModal();
  const { uploadImageToS3, isIdle, isUploadingImageToS3, uploadImageToS3Result, isUploadImageToS3Error } = useUploadImageToS3();

  const toFormListPage = useCallback(() => {
    routers.push(SURVEY_LIST_PATH);
  }, [routers]);

  const {
    mutateAsync: updateSurveyForm,
    data: updateSurveyResult,
    isPending: isUpdatingForm,
  } = useMutation({
    mutationFn: async ({ formValue, surveyId }: { formValue: SurveyFormAPIValue; surveyId: string }) => {
      if (typeof teamId === 'undefined') {
        return;
      }
      return await updateSurvey({ teamId, surveyId, formValue });
    },
    onError: handleError,
  });

  const imageS3Url = updateSurveyResult?.brand_image_upload_url;
  const isSubmitting = isUpdatingForm || isUploadingImageToS3;

  useEffect(
    function handleUpdateSurveyResult() {
      if (!updateSurveyResult) {
        return;
      }
      if (!imageS3Url) {
        toFormListPage();
      }
      if (imageS3Url && isIdle) {
        uploadImageToS3({ s3Url: imageS3Url, customImageBase64 });
      }
    },
    [updateSurveyResult, imageS3Url, uploadImageToS3, isIdle, customImageBase64, toFormListPage],
  );

  useEffect(
    function handleUploadImageToS3Result() {
      if (uploadImageToS3Result) {
        toFormListPage();
      }
    },
    [routers, toFormListPage, uploadImageToS3Result],
  );

  useEffect(
    function handleUploadImageToS3Error() {
      if (isUploadImageToS3Error) {
        openErrorModal({
          title: t('survey:failed_to_upload_image'),
          message: t('survey:create_survey_but_image_upload_failed'),
          onClickPrimaryButton: () => {
            closeErrorModal();
          },
          primaryButtonLabel: t('common:stay'),
          onClickSecondaryButton: () => {
            closeErrorModal();
            routers.push(SURVEY_LIST_PATH);
          },
          secondaryButtonLabel: t('common:leave'),
        });
      }
    },
    [closeErrorModal, isUploadImageToS3Error, openErrorModal, routers, t],
  );

  return { updateSurveyForm, isSubmitting };
};

const useErrorHandler = () => {
  const { t } = useTranslation();
  const routers = useRouter();
  const { openErrorModal, closeErrorModal } = useErrorModal();
  const { flashMessage } = useRippleFlashMessage();

  return (updateSurveyError: ErrorResponse) => {
    const errorValidation = updateSurveyErrorSchema.safeParse(updateSurveyError);

    if (!errorValidation.success) {
      console.error('Error status code is not valid', errorValidation.error);
    } else {
      const errorStatusCode = errorValidation.data.result;
      switch (errorStatusCode) {
        case 40422: // Wrong params
          openErrorModal({
            title: t('survey:failed_to_update_survey'),
            message: t('survey:please_contact_splashtop_support_team'),
            onClickPrimaryButton: () => {
              closeErrorModal();
              routers.push(SURVEY_LIST_PATH);
            },
          });
          break;
        case 40403:
          openErrorModal({
            title: t('survey:survey_does_not_exist'),
            message: t('survey:the_survey_you_were_working_on_does_not_exist'),
            onClickPrimaryButton: () => {
              closeErrorModal();
              routers.push(SURVEY_LIST_PATH);
            },
          });
          break;
        case 41403:
          openErrorModal({
            title: t('survey:an_error_occurred'),
            message: t('survey:please_contact_your_it_admin'),
            onClickPrimaryButton: () => {
              closeErrorModal();
              routers.push(SURVEY_LIST_PATH);
            },
          });
          break;
        case 40404:
          flashMessage({
            title: t('survey:survey_unavailable_refresh'),
            variant: 'error',
          });
          break;
        case 40409:
          flashMessage({
            title: t('survey:update_survey_name_duplicated'),
            variant: 'error',
          });
          break;
        default:
          flashMessage({
            title: t('survey:failed_to_connect_to_splashtop_servers_please_check_your_network_connection'),
            variant: 'error',
          });
      }
    }
  };
};
