import { useQuery } from '@tanstack/react-query';
import { keyBy, matches } from 'lodash';

import { useTeamId } from '@/models/TeamInformation';
import { patchPolicyItemConfig } from '@/services/teams/emm_policies/patch_policy/constants';
import { getPatchPolicyDetailService } from '@/services/teams/emm_policies/patch_policy/getPatchPolicyDetail';

/** Check if the software patch enabled */
export const useSoftwarePatchEnabledCheckQuery = ({ policyId, enabled }: { policyId?: number; enabled?: boolean }) => {
  const teamId = useTeamId();
  return useQuery({
    queryKey: ['softwarePatchDetails', teamId, policyId],
    enabled,
    queryFn: async () => {
      if (!policyId) {
        return false;
      }
      return await getPatchPolicyDetailService.execute(teamId, policyId);
    },
    select: (data) => {
      if (!data) {
        return false;
      }
      const currentConfig = data.current.policy_items.find(matches({ code: patchPolicyItemConfig.enable_policy.code }));
      if (currentConfig) {
        return Boolean(currentConfig.value);
      }
      if (!data.parents) {
        return false;
      }
      const policyMap = keyBy(data.parents, 'emm_policy_id');
      const checkParentEnabled = (parentPolicyId: number | null): boolean => {
        if (parentPolicyId === null) {
          return false;
        }
        const config = policyMap[parentPolicyId];
        if (!config) {
          return false;
        }
        const inheritedEnable = config.policy_items.find(matches({ code: patchPolicyItemConfig.enable_policy.code }));
        return inheritedEnable ? Boolean(inheritedEnable.value) : checkParentEnabled(config.parent_id);
      };
      return checkParentEnabled(data.current.parent_id);
    },
  });
};
