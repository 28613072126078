import { baseColumnIdMap, baseColumnWidthMap } from '@/modules/Computer/ComputerList/constants';

import type { ColumnVisibility } from './types';

export const GROUPING_COLUMN = 'groupName';

export const fixedColumnVisibility: ColumnVisibility = {
  [baseColumnIdMap.checkbox]: true,
  [baseColumnIdMap.name]: true,
  [baseColumnIdMap.host_name]: false,
  [baseColumnIdMap.spacer]: true,
  [baseColumnIdMap.action]: true,
};

export const columnIdMap = {
  ...baseColumnIdMap,
  antivirus_scan_status: 'antivirus_scan_status',
  group_id: 'group_id',
  groupName: 'groupName',
  antivirus_software: 'antivirus_software',
  antivirus_software_status: 'antivirus_software_status',
  antivirus_protection: 'antivirus_protection',
  antivirus_software_enabled: 'antivirus_software_enabled',
  antivirus_policy: 'antivirus_policy',
  antivirus_last_scan_time: 'antivirus_last_scan_time',
  unsolved_threats_count: 'unsolved_threats_count',
  details: 'details',
} as const;

type WidthSettings = Record<string, number>;

export const columnWidthMap = {
  ...baseColumnWidthMap,
  [columnIdMap.antivirus_scan_status]: 96,
  [columnIdMap.group_id]: 0,
  [columnIdMap.groupName]: (lang: string) => {
    const widthSettings: WidthSettings = {
      en: 160,
      es: 168,
    };
    return widthSettings[lang] || widthSettings.en;
  },
  [columnIdMap.antivirus_software]: 320,
  [columnIdMap.antivirus_protection]: 160,
  [columnIdMap.antivirus_software_enabled]: 160,
  [columnIdMap.antivirus_policy]: 160,
  [columnIdMap.antivirus_last_scan_time]: 192,
  [columnIdMap.unsolved_threats_count]: 96,
  [columnIdMap.details]: 84,
} as const;

export const BITDEFENDER_1 = 'bitdefender';
export const BITDEFENDER_2 = 'bitdefender2';

export const BITDEFENDER_1_PAYMENT_URL = '/payment/changefeature/bitdefender';
export const BITDEFENDER_2_PAYMENT_URL = '/payment/changefeature/bitdefender2';

export const BITDEFENDER_1_MANAGE_URL = '/antivirus/package/bitdefender/list';
export const BITDEFENDER_2_MANAGE_URL = '/w/endpoint_security/antivirus_policies';

export const ACTION_RESULT = {
  SUCCESS: 'success',
  FAIL: 'fail',
  OFFLINE: 'offline',
} as const;

export const NEVER_SUBSCRIBED = '';

export const SOFTWARE_OPTION_NONE = 'n/a';

// The Windows Defender has an alias display name
export const WINDOWS_DEFENDER_NAME_ALISA = 'Windows Defender/Microsoft Security Essentials';

export const ANTIVIRUS_SCAN_STATUS = {
  GOOD: 'good',
  HAS_THREATS: 'has_threats',
  NO_PROTECTION: 'no_protection',
  EXPIRED: 'expired',
  NO_SOFTWARE: 'no_software',
  NOT_SUPPORT_ANTIVIRUS_VERSION: 'not_support_antivirus_version',
} as const;
