import { BrandImageMap, SurveyTypeMap } from '@/services/serviceDesk/surveys/type';

export const SURVEY_FORM_CONTENT_WIDTH = '1140px';

export const FORM_NAME_MAX_LENGTH = 64;
export const LABEL_MAX_LENGTH = 256;
export const SHORT_TEXT_PLACEHOLDER_MAX_LENGTH = 40;
export const PLACEHOLDER_MAX_LENGTH = 64;
export const INPUT_MAX_LENGTH = 128;
export const DESCRIPTION_MAX_LENGTH = 256;
export const TEXTAREA_MAX_LENGTH = 512;

export const OPTION_MAX_LENGTH = 50;
export const SUBMIT_BUTTON_MAX_LENGTH = 20;

export const MAX_QUESTIONNAIRE_COUNT = 16;
export const MAX_OPTION_COUNT = 11;

export const NO_DEFAULT_OPTION = '';

export const BRAND_ICON_UUID = 'brand-icon';
export const WELCOME_UUID = 'welcome-message';
export const SUBMIT_BUTTON_UUID = 'submit-button';

export const INITIAL_FORM_VALUE = {
  name: '',
  description: '',
  locale: null,
  brand_image: BrandImageMap.splashtop,
  type: SurveyTypeMap.customer,
  welcome_message: {
    title: '',
    subtitle: '',
  },
  thank_you_message: '',
  questionnaire: [],
  action_button_label: '',
};
