import { useEffect, useState } from 'react';

import { HStack } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useTranslation } from 'next-i18next';

import {
  RippleButton,
  RippleHeading08,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalTitle,
  RippleTextarea,
  useRippleFlashMessage,
} from '@/design';
import { updateComputerNote } from '@/services/computers';
import preSanitize from '@/utils/pre-sanitize';

import { computerDataMapAtom } from '../atoms';

export const addNotesModalAtom = atomWithReset<{ isOpen: boolean; computerId: number | null; note: string | null }>({
  isOpen: false,
  computerId: null,
  note: null,
});

export function useAddNotesModal() {
  const set = useSetAtom(addNotesModalAtom);

  return {
    open: ({ computerId, note }: { computerId: number; note: string | null }) => {
      set({ isOpen: true, computerId, note });
    },
  };
}

export function AddNotesModal(): React.JSX.Element {
  const { t } = useTranslation();
  const { flashMessage } = useRippleFlashMessage();

  const { isOpen, computerId, note } = useAtomValue(addNotesModalAtom);
  const resetModal = useResetAtom(addNotesModalAtom);

  const [value, setValue] = useState<string>('');
  useEffect(
    function initialInputValue() {
      if (isOpen) {
        setValue(note ?? '');
      }
    },
    [isOpen, note],
  );

  const setComputerDataMap = useSetAtom(computerDataMapAtom);

  const saveMutation = useMutation({
    mutationFn: async ({ computerId, note }: { computerId: number; note: string }) => {
      return updateComputerNote(computerId, note);
    },
    onSuccess: (_response, { computerId, note }) => {
      setComputerDataMap((draft) => {
        draft[computerId].note = note;
      });
    },
    onError: () => {
      flashMessage({ variant: 'error', title: t('common:unexpectedError') });
    },
    onSettled: () => {
      resetModal();
    },
  });

  const isDisabled = saveMutation.isPending;

  return (
    <RippleModal size="2xl" isOpen={isOpen} onClose={resetModal}>
      <RippleModalOverlay />
      <RippleModalContent w="448px">
        <RippleModalHeader>
          <RippleModalTitle>{t('computer:addNotes.modal.title')}</RippleModalTitle>
        </RippleModalHeader>
        <RippleModalBody p="0 24px 32px">
          <RippleHeading08 mb="8px">{t('computer:note')}</RippleHeading08>
          <RippleTextarea
            data-testid="note-textarea"
            h="256px"
            placeholder={t('computer:addNotes.input.placeholder')}
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
            }}
            maxLength={512}
          />
        </RippleModalBody>
        <RippleModalFooter>
          <HStack spacing="8px">
            <RippleButton data-testid="cancel-button" variant="grayScaleGhost" onClick={resetModal}>
              {t('common:cancel')}
            </RippleButton>
            <RippleButton
              data-testid="save-button"
              variant="primary"
              isLoading={saveMutation.isPending}
              isDisabled={isDisabled}
              onClick={() => {
                if (computerId) {
                  saveMutation.mutate({ computerId, note: processString(value) });
                }
              }}
            >
              {t('common:save')}
            </RippleButton>
          </HStack>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
}

/**
 * The fellowing chart will be remove null, horizontal tab, line feed, vertical tab, form feed, carriage return, space
 * The fellowing charts will be change to full-width
 *  < => ＜
 *  > => ＞
 *  ; => ；
 */
function processString(input: string): string {
  const sanitizedString = preSanitize(input.trim());

  // remove null, horizontal tab, line feed, vertical tab, form feed, carriage return
  const controlChars = ['\u0000', '\u0009', '\u000A', '\u000B', '\u000C', '\u000D'];
  return controlChars.reduce((acc, controlChar) => {
    return acc.split(controlChar).join(' ');
  }, sanitizedString);
}
