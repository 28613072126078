import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { atom, useSetAtom } from 'jotai';

import { logout } from '@/authentication';
import FullPageLoading from '@/components/FullPageLoading';
import { getCurrentMenuName } from '@/components/Navigation/menuList';
import { LOCAL_ONLY, TEST_ONLY } from '@/feature/toggle';

const NAV_NAME = 'NAV_BAR';
const SANDBOX_ATTRIBUTE = 'allow-same-origin allow-scripts allow-forms';
export const NAV_TYPES = {
  NORMAL: 'NORMAL',
  WITH_SUBTITLE: 'WITH_SUBTITLE',
} as const;
export type NavTypeKeys = keyof typeof NAV_TYPES;

export const NAV_HEIGHT = 55;
const NAV_HEIGHT_MAX = '100vh';

const NAV_STYLES = {
  [NAV_TYPES.NORMAL]: {
    height: `${NAV_HEIGHT + 12}px`,
    animation: keyframes`
      0% {
        height: ${NAV_HEIGHT_MAX};
      }
      100% {
        height: ${NAV_HEIGHT + 12}px;
      }
    `,
  },
  [NAV_TYPES.WITH_SUBTITLE]: {
    height: `${NAV_HEIGHT + 14}px`,
    animation: keyframes`
      0% {
        height: ${NAV_HEIGHT_MAX};
      }
      100% {
        height: ${NAV_HEIGHT + 14}px;
      }
    `,
  },
};

type IFrameEvents =
  | {
      type: 'navbar-open';
      isOpen: boolean;
    }
  | {
      type: 'navbar-push';
      url: string;
    }
  | {
      type: 'login-status';
      isLogin: boolean;
    };

type IFrameProps = {
  isFullHeight: boolean;
  navType: NavTypeKeys;
};

const IFrame = styled.iframe<IFrameProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1030;
  width: 100vw;
  height: ${({ navType, isFullHeight }) => (isFullHeight ? NAV_HEIGHT_MAX : NAV_STYLES[navType].height)};
  overflow: hidden;
  background-color: transparent;
  border: 0;
  animation-name: ${({ navType, isFullHeight }) => (isFullHeight ? 'none' : NAV_STYLES[navType].animation)};
  animation-duration: ${({ isFullHeight }) => (isFullHeight ? '0s' : '0.3s')};
  animation-timing-function: ease-in;
`;

export const navIframeRefAtom = atom<React.RefObject<HTMLIFrameElement> | null>(null);

type NavigationIframeProps = {
  navType: NavTypeKeys;
};

function NavigationIframe({ navType }: NavigationIframeProps) {
  const route = useRouter();
  const iframeDOM = useRef<HTMLIFrameElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(LOCAL_ONLY || TEST_ONLY);
  const [isOpenNav, setIsOpenNav] = useState<boolean>(false);
  const setNavIframeRef = useSetAtom(navIframeRefAtom);

  useEffect(() => {
    const scrollListener = () => {
      const position = window.scrollY;
      const hasShadow = position !== 0;

      // @ts-ignore
      window.frames[NAV_NAME]?.postMessage(
        {
          type: 'navbar-shadow',
          hasShadow,
        },
        window.location.origin,
      );
    };

    window.addEventListener('scroll', scrollListener);

    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('message', (message: MessageEvent<IFrameEvents>) => {
      const isSameDomain = message.origin === window.location.origin;
      if (!isSameDomain) {
        return;
      }

      switch (message.data.type) {
        case 'navbar-open': {
          const isOpen = message.data.isOpen;
          setIsOpenNav(isOpen);

          break;
        }
        case 'navbar-push': {
          const url = message.data.url;
          if (url === `${window.location.origin}/logout`) {
            logout();
          } else {
            window.location.href = url ? url : '/';
          }

          break;
        }
        case 'login-status': {
          const isLogin = message.data.isLogin;

          if (isLogin) {
            setIsLoaded(isLogin);
          } else {
            logout();
          }

          break;
        }
      }
    });
  }, []);

  useEffect(
    function setIframeRefAtom() {
      if (iframeDOM) {
        setNavIframeRef(iframeDOM);
      }
    },
    [setNavIframeRef],
  );

  const iframeSrc = useMemo(() => {
    const menuKey = getCurrentMenuName(route.pathname);

    return menuKey ? `/navbar?menu=${menuKey}` : '/navbar';
  }, [route.pathname]);

  return (
    <>
      <IFrame
        name={NAV_NAME}
        ref={iframeDOM}
        src={iframeSrc}
        sandbox={LOCAL_ONLY ? undefined : SANDBOX_ATTRIBUTE}
        scrolling="no"
        isFullHeight={isOpenNav}
        navType={navType}
      />
      {!isLoaded && (
        <>
          <style global jsx>
            {`
              body {
                overflow: hidden;
              }
            `}
          </style>
          <Box backgroundColor="white" width="100vw" height="100vh" position="fixed" top={0} left={0} zIndex={9999999}>
            <FullPageLoading />
          </Box>
        </>
      )}
    </>
  );
}

export default NavigationIframe;
