import React from 'react';

import styled from '@emotion/styled';
import { animated, useTransition } from 'react-spring';

import Portal from './Portal';

const Backdrop = styled(animated.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  cursor: initial;
  background-color: rgba(0, 0, 0, 0.5);
`;

type SizeProps = 'sm' | 'lg' | 'xl' | number | string;

const Content = styled(animated.div)<{ size: SizeProps }>`
  position: relative;
  max-width: ${({ size }) => {
    if (typeof size === 'number') return `${size}px`;
    if (size === 'sm') return '600px';
    else if (size === 'lg') return '800px';
    else if (size === 'xl') return '1120px';
    else return size;
  }};
  max-height: 100%;
  margin: 30px auto;
  overflow: auto;
  cursor: initial;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
`;

type ModalProps = { isOpen: boolean; size?: SizeProps; children: React.ReactNode };

const Modal = ({ children, isOpen, size = 'sm' }: ModalProps) => {
  const transition = useTransition(isOpen, null, {
    from: { opacity: 0, transform: 'translateY(-25%)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(-25%)' },
  });
  return (
    <>
      {transition.map(
        ({ item, key, props: animation }) =>
          item && (
            <Portal selector="#modal" key={key}>
              <Backdrop style={{ opacity: animation.opacity }}>
                <Content style={animation} size={size}>
                  {children}
                </Content>
              </Backdrop>
            </Portal>
          ),
      )}
    </>
  );
};

export default Modal;
