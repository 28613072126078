import { Box, forwardRef } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { RippleArrowLeft, RippleCheckbox, RippleMenuDivider, RippleMenuItem, RippleTooltip } from '@/design';
import { Policy } from '@/services/teams/emm_policies';

import { HorizontalCollapse } from './HorizontalCollapse';

export type PolicyListProps = {
  isOpen: boolean;
  minHeight?: number;
  policies: Array<Policy>;
  showFollowGroup: boolean;
  selectedPolicyIdSet: Set<number>;
  onClose: () => void;
  onPolicyChange: (policyId: number) => void;
  onAnimateComplete: () => void;
  onFollowGroupChange: (followGroup: boolean) => void;
};
export const PolicyList = forwardRef(
  (
    {
      policies,
      isOpen,
      minHeight,
      selectedPolicyIdSet,
      showFollowGroup,
      onClose,
      onPolicyChange,
      onAnimateComplete,
      onFollowGroupChange,
    }: PolicyListProps,
    ref,
  ) => {
    const { t } = useTranslation();
    return (
      <HorizontalCollapse isOpen={isOpen} onAnimateComplete={onAnimateComplete}>
        <Box background="white" ref={ref} borderRadius="4px" overflow="hidden" py="6px" minHeight={minHeight}>
          <RippleMenuItem icon={<RippleArrowLeft />} onClick={onClose}>
            {t('common:filter_by_policy')}
          </RippleMenuItem>
          <RippleMenuDivider />
          <Box maxHeight="300px" overflowY="scroll" pt="6px">
            <RippleMenuItem
              onClick={(e) => {
                e.preventDefault();
                onFollowGroupChange(!showFollowGroup);
              }}
            >
              <RippleCheckbox isReadOnly isChecked={showFollowGroup}>
                {t('emm-policy:follow_group')}
              </RippleCheckbox>
            </RippleMenuItem>
            {policies.map((policy) => {
              return (
                <RippleMenuItem
                  key={policy.id}
                  onClick={(e) => {
                    e.preventDefault();
                    onPolicyChange(policy.id);
                  }}
                >
                  <RippleCheckbox isReadOnly isChecked={selectedPolicyIdSet?.has(policy.id)}>
                    <RippleTooltip label={policy.name} aria-label={policy.name}>
                      {policy.name}
                    </RippleTooltip>
                  </RippleCheckbox>
                </RippleMenuItem>
              );
            })}
          </Box>
        </Box>
      </HorizontalCollapse>
    );
  },
);
