/**
 * List EMM Policy all categories
 * @see https://github.com/SplashtopInc/be_kung_fu/blob/master/be_app/api/web-api/v1/emm/emm_policies.md#list-emm-policies-api
 */
import axios from 'axios';
import { z } from 'zod';

import { checkResponse, createGenericErrorHandlers } from '@/services/utils';

import { policySchema } from './types';

const getUrl = (teamId: number) => `/api/web/v1/teams/${teamId}/emm_policies`;

const METHOD = 'GET';

const responseSchema = z.array(policySchema);

const execute = (teamId: number) => checkResponse(axios({ method: METHOD, url: getUrl(teamId) }), responseSchema);
const errorHandling = createGenericErrorHandlers();

export const listEmmPoliciesService = { getUrl, execute, method: METHOD, responseSchema, errorHandling };
