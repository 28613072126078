import { useRef } from 'react';

import { Box, Flex, forwardRef } from '@chakra-ui/react';
import type { BoxProps } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'next-i18next';
import { TableVirtuoso } from 'react-virtuoso';

import { ALL_GROUP_ID } from '@/components/ComputerList/utils';
import Highlight from '@/components/Highlight';
import { OTableSortStates } from '@/components/ObservableTable/useObservableSort';
import { RippleArrowDown, RippleArrowRight, RippleBodyText02, RippleHeading07, RippleHeading09, RippleTooltip } from '@/design';
import {
  RippleComputerCell,
  RippleComputerTableHead,
  RippleTableLegacy,
  RippleTbodyLegacy,
  RippleTheadLegacy,
  RippleTrLegacy,
} from '@/design';
import { RippleSkeleton } from '@/design';

import { InventorySummary, InventoryTableColumn } from '../types';
import { getInventoryColumnHeaders, getInventoryColumnWidthsSum, inventoryColumnOrder, inventoryColumnWidths } from './constant';
import { useContainerSize, useSelectRow } from './utils';

const sortConditionMap = {
  asc: OTableSortStates.ASC,
  desc: OTableSortStates.DESC,
};

type SortCondition = {
  column: InventoryTableColumn;
  order: 'asc' | 'desc';
};

type GroupRowProps = {
  id: string;
  name: string;
  length: number;
};

export type GroupViewProps = {
  rows: Array<InventorySummary>;
  groupedRows: Record<string, Array<InventorySummary>>;
  sortedGroupList: Array<{ id: string; name: string }>;
  searchKeyword?: string;
  visibilities: Record<string, boolean>;
  expandAbilities: Record<string, boolean>;
  selectedGroupId?: string;
  isLoading: boolean;
  onSelect: (id: string) => void;
  onExpand: (id: string) => void;
  sortCondition: SortCondition;
  onChangeSortCondition: (sortCondition: SortCondition) => void;
};

const GroupView = ({
  rows,
  groupedRows,
  sortedGroupList,
  searchKeyword,
  visibilities,
  expandAbilities,
  selectedGroupId,
  isLoading,
  onSelect,
  onExpand,
  sortCondition,
  onChangeSortCondition,
}: GroupViewProps) => {
  const { t } = useTranslation();
  const columnHeader = getInventoryColumnHeaders(t);

  const handleClickSortIcon = (columnName: InventoryTableColumn) => () => {
    const newCondition: SortCondition = {
      column: columnName,
      order: sortCondition.column === columnName && sortCondition.order === 'asc' ? 'desc' : 'asc',
    };
    onChangeSortCondition(newCondition);
  };

  const [containerRef, containerHeight, containerWidth] = useContainerSize({
    isLoading,
    isEmpty: isEmpty(rows),
  });

  const rowWidth = `max(100%, ${containerWidth}px)`;

  const groupList =
    !selectedGroupId || selectedGroupId === ALL_GROUP_ID ? sortedGroupList : sortedGroupList.filter(({ id }) => id === selectedGroupId);

  type GroupRowData = { type: 'group'; id: string; name: string; length: number };
  type NormalRowData = { type: 'row'; isLastRow: boolean } & InventorySummary;

  const flattenRows: Array<GroupRowData | NormalRowData> = groupList
    .map((group) => {
      const rows = groupedRows[group.id] ?? [];
      const length = rows.length;
      const isExpand = expandAbilities[group.id];
      return isExpand
        ? [
            { ...group, length, type: 'group' as const },
            ...rows.map((row, index) => {
              return { type: 'row' as const, isLastRow: rows.length === index + 1, ...row };
            }),
          ]
        : [{ ...group, length, type: 'group' as const }];
    })
    .flat();

  const inventoryColumnOrderWithoutGroupName = inventoryColumnOrder.filter((columnName) => columnName !== 'groupName');

  // Prevent tooltip from exceeding the display range of the table
  const scrollerRef = useRef<HTMLElement | null>(null);

  function GroupRow({ id, name, length }: GroupRowProps): React.JSX.Element {
    return (
      <Flex
        display="inline-flex"
        className="group_td"
        w={rowWidth}
        flexShrink={0}
        onClick={() => onExpand(id)}
        alignItems="center"
        padding="10px 0"
        backgroundColor="neutral.40"
        _hover={{ backgroundColor: 'blue.0' }}
        cursor="pointer"
        borderColor="neutral.60"
        borderStyle="solid"
        borderWidth="1px"
        borderTopLeftRadius="4px"
        borderTopRightRadius="4px"
        borderBottomLeftRadius={rows.length > 0 && expandAbilities[id] ? '0px' : '4px'}
        borderBottomRightRadius={rows.length > 0 && expandAbilities[id] ? '0px' : '4px'}
        marginTop="12px"
      >
        <Flex position="sticky" left="0">
          <Flex alignItems="center">
            <Flex alignItems="center" justifyContent="center" width="50px" flexShrink={0}>
              {expandAbilities[id] ? <RippleArrowDown color="neutral.300" /> : <RippleArrowRight />}
            </Flex>
            <RippleHeading07 wordBreak="break-all" paddingRight="8px">
              <Highlight keyword={searchKeyword}>{name}</Highlight>
            </RippleHeading07>
          </Flex>
          <Flex alignItems="center" bg="neutral.60" margin="0 8px" padding="0 6px" minWidth="20px" minHeight="20px" flexShrink={0}>
            <RippleHeading09>{length}</RippleHeading09>
          </Flex>
        </Flex>
      </Flex>
    );
  }

  return (
    <Box ref={containerRef} w="100%">
      <TableVirtuoso
        scrollerRef={(ref) => {
          scrollerRef.current = ref as HTMLElement;
        }}
        style={{ height: containerHeight }}
        data={!isLoading ? flattenRows : []}
        components={{
          Table: RippleTableLegacy,
          TableBody: forwardRef((props, ref) => (
            <RippleTbodyLegacy
              display="table-row-group"
              background="transparent"
              border="1px solid #DFE4E9"
              borderRadius="4px"
              ref={ref}
              {...props}
            />
          )),
          TableHead: forwardRef((props, ref) => <RippleTheadLegacy ref={ref} bg="neutral.10" {...props} />),
          TableRow: forwardRef((props, ref) => {
            return <Box ref={ref} backgroundColor="neutral.10" {...props} />;
          }),
        }}
        fixedHeaderContent={() => (
          <RippleTrLegacy padding="0px 8px" bg="neutral.10">
            {inventoryColumnOrderWithoutGroupName.map((columnName, index) => {
              return (
                <RippleComputerTableHead
                  display={visibilities[columnName] ? 'flex' : 'none'}
                  width={`${inventoryColumnWidths[columnName]}px`}
                  key={index}
                  isSortable
                  sortState={sortCondition.column === columnName ? sortConditionMap[sortCondition.order] : undefined}
                  onClick={handleClickSortIcon(columnName)}
                >
                  {columnHeader[columnName]}
                </RippleComputerTableHead>
              );
            })}
          </RippleTrLegacy>
        )}
        itemContent={(_index, row) => {
          if (row.type === 'group') {
            return <GroupRow {...row} />;
          } else if (row.type === 'row') {
            return (
              <Row
                rowId={row.id}
                w={rowWidth}
                isLastRow={row.isLastRow}
                onClick={() => {
                  onSelect(row.id);
                }}
              >
                {inventoryColumnOrderWithoutGroupName.map((columnName, index) => {
                  return (
                    <RippleComputerCell
                      display={visibilities[columnName] ? 'flex' : 'none'}
                      width={`${inventoryColumnWidths[columnName]}px`}
                      height="72px"
                      key={index}
                    >
                      <RippleTooltip aria-label={columnName} label={row[columnName]} portalProps={{ containerRef: scrollerRef }}>
                        <Box width={`${inventoryColumnWidths[columnName] - 16}px`}>
                          <RippleBodyText02 noOfLines={2} width="100%">
                            <Highlight keyword={searchKeyword}>{String(row[columnName])}</Highlight>
                          </RippleBodyText02>
                        </Box>
                      </RippleTooltip>
                    </RippleComputerCell>
                  );
                })}
              </Row>
            );
          }
        }}
      />

      {isLoading ? (
        <Box>
          {Array(3)
            .fill(null)
            .map((_, index) => {
              return (
                <RippleTrLegacy key={index}>
                  <RippleSkeleton width={`${getInventoryColumnWidthsSum(visibilities)}px`} my="5px" />
                </RippleTrLegacy>
              );
            })}
        </Box>
      ) : null}
    </Box>
  );
};

export default GroupView;

type RowProps = BoxProps & { rowId: string; isLastRow: boolean };
function Row({ rowId, isLastRow, onClick, ...otherProps }: RowProps): React.JSX.Element {
  const { isSelected, selectRow } = useSelectRow(rowId);

  return (
    <RippleTrLegacy
      overflow="hidden"
      backgroundColor={isSelected ? 'green.10' : 'white'}
      _hover={{ backgroundColor: 'blue.0' }}
      borderLeft="1px solid #DFE4E9"
      borderRight="1px solid #DFE4E9"
      borderBottom="1px solid #DFE4E9"
      borderBottomLeftRadius={isLastRow ? '4px' : undefined}
      borderBottomRightRadius={isLastRow ? '4px' : undefined}
      padding="0px 8px"
      cursor="pointer"
      onClick={(event) => {
        selectRow();
        if (onClick) {
          onClick(event);
        }
      }}
      {...otherProps}
    />
  );
}
