import Head from 'next/head';

import { Box, CSSWithMultiValues } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { Footer } from './Footer';

type PublicLayoutProps = {
  hideFooter?: boolean;
  sx?: {
    container?: CSSWithMultiValues;
    wrapper?: CSSWithMultiValues;
  };
  children: React.ReactNode;
};

const PublicLayout = ({ children, hideFooter = false, sx }: PublicLayoutProps) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" paddingTop="64px" sx={sx?.wrapper}>
      <Head>
        <title>{t('common:title')}</title>
      </Head>
      <Box
        position="relative"
        boxSizing="border-box"
        display="flex"
        flexDirection="column"
        alignItems="center"
        minWidth="290px"
        padding="0 15px"
        margin="auto"
        width={['100%', 750, 970, 1170]}
        minHeight={`calc(100vh - 160px)`}
        sx={sx?.container}
      >
        {children}
      </Box>
      {!hideFooter && <Footer />}
    </Box>
  );
};

export { PublicLayout };
