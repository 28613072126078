import { Trans, useTranslation } from 'next-i18next';

import { RippleTypography } from '@/design';

type SelectionCountProps = {
  selectedCount: number;
};

const SelectionCount = ({ selectedCount }: SelectionCountProps) => {
  const { t } = useTranslation();

  return (
    <RippleTypography variant="body02">
      <Trans
        t={t}
        i18nKey="computer:computerSelected"
        values={{
          count: selectedCount,
        }}
        components={{
          Strong: <span data-testid="selection-count" />,
        }}
      />
    </RippleTypography>
  );
};

export default SelectionCount;
