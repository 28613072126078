import axios from 'axios';

import { ResponseData } from '@/services/common/types';
import { isSuccess, pureInstance } from '@/services/utils';

import type {
  CreateFormResponse,
  CreatePinCodeResponse,
  GetSupportFormValueForAPI,
  SubmitFormResponse,
  SubmitFormValueForAPI,
  SupportFormSummaryList,
  SupportFormValueForAPI,
} from './types';

export function getSupportFormList(teamId: number, channelId: number) {
  return new Promise<SupportFormSummaryList>((resolve, reject) => {
    axios
      .get<ResponseData & { data: { webforms: SupportFormSummaryList } }>(
        `/api/web/v1/teams/${teamId}/service_desk/channels/${channelId}/webforms`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data.webforms);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function createSupportForm(teamId: number, channelId: number, formData: SupportFormValueForAPI) {
  return new Promise<CreateFormResponse>((resolve, reject) => {
    axios
      .post<ResponseData & { data: CreateFormResponse }>(`/api/web/v1/teams/${teamId}/service_desk/channels/${channelId}/webforms`, {
        ...formData,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

type DeleteSupportFormResponse = Record<string, unknown>;

export function deleteSupportForm(teamId: number, channelId: number, formId: number) {
  return new Promise<DeleteSupportFormResponse>((resolve, reject) => {
    axios
      .delete<ResponseData & { data: DeleteSupportFormResponse }>(
        `/api/web/v1/teams/${teamId}/service_desk/channels/${channelId}/webforms/${formId}`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function updateSupportForm(teamId: number, channelId: number, formId: number, formData: SupportFormValueForAPI) {
  return new Promise<Record<string, never>>((resolve, reject) => {
    axios
      .put<ResponseData & { data: Record<string, never> }>(
        `/api/web/v1/teams/${teamId}/service_desk/channels/${channelId}/webforms/${formId}`,
        {
          ...formData,
        },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

type UpdateSupportFormStatusResponse = Record<string, unknown>;

export function updateSupportFormStatus(teamId: number, channelId: number, formId: number, status: 0 | 1) {
  return new Promise<UpdateSupportFormStatusResponse>((resolve, reject) => {
    axios
      .put<ResponseData & { data: UpdateSupportFormStatusResponse }>(
        `/api/web/v1/teams/${teamId}/service_desk/channels/${channelId}/webforms/${formId}/status`,
        { status },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getSupportForm(teamId: number, channelId: number, supportFormId: number) {
  return new Promise<GetSupportFormValueForAPI>((resolve, reject) => {
    axios
      .get<ResponseData & { data: GetSupportFormValueForAPI }>(
        `/api/web/v1/teams/${teamId}/service_desk/channels/${channelId}/webforms/${supportFormId}`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getSupportFormInIframe(supportFormId: string) {
  return new Promise<GetSupportFormValueForAPI>((resolve, reject) => {
    pureInstance
      .get<ResponseData & { data: GetSupportFormValueForAPI }>(`/api/web/v1/teams/service_desk/webforms/${supportFormId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function submitSupportForm(supportFormId: string, formData: SubmitFormValueForAPI) {
  return new Promise<SubmitFormResponse>((resolve, reject) => {
    pureInstance
      .post<ResponseData & { data: SubmitFormResponse }>(`/api/web/v1/teams/service_desk/webforms/${supportFormId}`, {
        ...formData,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function createPinCode(supportSessionCode: string) {
  return new Promise<CreatePinCodeResponse>((resolve, reject) => {
    pureInstance
      .post<ResponseData & { data: CreatePinCodeResponse }>(`/api/web/v1/pin/webform`, { code: supportSessionCode })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}
