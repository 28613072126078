import axios from 'axios';
import { subYears } from 'date-fns';

import {
  DeviceManager,
  RegistryEditor,
  RequestRemote,
  ServiceManager,
  TaskManager,
} from '@/modules/ServiceDesk/shared/requestPermissionBitmap';
import type { ResponseData, ResponseErrorReason } from '@/services/common/types';
import { isSuccess, pureInstance } from '@/services/utils';

import type {
  CheckSupportSessionPermissionResponse,
  CreateSupportSessionRequestBody,
  MutateSupportSessionResponse,
  RequestSupportSessionPermissionResponse,
  ServiceDeskCloudBuildURLResponse,
  SessionLogsInSupportSessionResponse,
  SessionVoiceCallDetailItem,
  ShowSupportSessionResponse,
  ShowSupportSessionServerResponse,
  SupportSessionChannelDetailResponse,
  SupportSessionChannelListResponse,
  SupportSessionChannelOptions,
  SupportSessionChatFileResponse,
  SupportSessionFileLogResponse,
  SupportSessionHistoryRequestBody,
  SupportSessionHistoryResponse,
  SupportSessionListResponse,
  SupportSessionLogListResponse,
  SupportSessionLogQueryOptions,
  SupportSessionSessionNoteRequestBody,
  SupportSessionSessionNoteResponse,
  UpdateSupportSessionStatusKind,
} from './types';

export * from './insertSupportSessionLogs';
export * from './retrieveSupportSessionLogs';
export * from './getSupportSessionLogToken';
export * from './types';

export function getChannelOptions(teamId: number, mode: 'create_ss' | 'transfer') {
  return new Promise<SupportSessionChannelOptions>((resolve, reject) => {
    axios
      .get<ResponseData & { data: SupportSessionChannelOptions }>(`/api/web/v1/teams/${teamId}/service_desk/channel_list`, {
        params: { mode },
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getSupportSessionChannelList(teamId: number) {
  return new Promise<SupportSessionChannelListResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: SupportSessionChannelListResponse }>(`/api/web/v1/teams/${teamId}/service_desk/support_channels`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getSupportSessionChannelDetail(teamId: number, channelId: number) {
  return new Promise<SupportSessionChannelDetailResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: SupportSessionChannelDetailResponse }>(
        `/api/web/v1/teams/${teamId}/service_desk/support_channels/${channelId}`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getSupportSessionList(teamId: number, channel: 'home' | 'private' | number) {
  return new Promise<SupportSessionListResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: SupportSessionListResponse }>(`/api/web/v1/teams/${teamId}/service_desk/support_sessions/`, {
        params: { channel },
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getSupportSessionListWithCommentAndCustomerInfo(teamId: number, channel: 'all' | 'home' | 'private' | number) {
  return new Promise<SupportSessionLogListResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: SupportSessionLogListResponse }>(`/api/web/v1/teams/${teamId}/service_desk/support_sessions/`, {
        params: { channel, show_comment: true, show_customer_info: true },
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function createSupportSession(teamId: number, createInfo: CreateSupportSessionRequestBody) {
  return new Promise<MutateSupportSessionResponse>((resolve, reject) => {
    const reqBody: Record<string, number | string> = {
      channel_id: createInfo.channelId,
    };

    if (createInfo.kind !== undefined) reqBody['kind'] = createInfo.kind;
    if (createInfo.name !== undefined) reqBody['name'] = createInfo.name;
    if (createInfo.assigneeId !== undefined) reqBody['assignee_id'] = createInfo.assigneeId;
    if (createInfo.priority !== undefined) reqBody['priority'] = createInfo.priority;
    if (createInfo.expiresAt !== undefined) reqBody['expires_at'] = createInfo.expiresAt;

    axios
      .post<ResponseData & { data: MutateSupportSessionResponse }>(`/api/web/v1/teams/${teamId}/service_desk/support_sessions`, reqBody)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function updateSupportSession(
  teamId: number,
  supportSessionId: number,
  updateInfo: Omit<CreateSupportSessionRequestBody, 'channelId' | 'kind'>,
) {
  return new Promise<MutateSupportSessionResponse>((resolve, reject) => {
    axios
      .put<ResponseData & { data: MutateSupportSessionResponse }>(
        `/api/web/v1/teams/${teamId}/service_desk/support_sessions/${supportSessionId}`,
        {
          name: updateInfo.name,
          assignee_id: updateInfo.assigneeId,
          priority: updateInfo.priority,
          expires_at: updateInfo.expiresAt,
        },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function showSupportSession(teamId: number, supportSessionId: number) {
  return new Promise<ShowSupportSessionResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: ShowSupportSessionResponse }>(
        `/api/web/v1/teams/${teamId}/service_desk/support_sessions/${supportSessionId}`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function showSupportSessionServer(teamId: number, supportSessionId: number) {
  return new Promise<ShowSupportSessionServerResponse | Record<string, never>>((resolve, reject) => {
    axios
      .get<ResponseData & { data: ShowSupportSessionServerResponse | Record<string, never> }>(
        `/api/web/v1/teams/${teamId}/service_desk/support_sessions/${supportSessionId}/server`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function updateSupportSessionStatus(teamId: number, supportSessionId: number, kind: UpdateSupportSessionStatusKind) {
  return new Promise<MutateSupportSessionResponse>((resolve, reject) => {
    axios
      .put<ResponseData & { data: MutateSupportSessionResponse }>(
        `/api/web/v1/teams/${teamId}/service_desk/support_sessions/${supportSessionId}/action`,
        {
          kind,
        },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function transferSupportSession(teamId: number, supportSessionId: number, channelId: number, assigneeId: number) {
  return new Promise<MutateSupportSessionResponse>((resolve, reject) => {
    axios
      .put<ResponseData & { data: MutateSupportSessionResponse }>(
        `/api/web/v1/teams/${teamId}/service_desk/support_sessions/${supportSessionId}/action`,
        {
          kind: 'transfer',
          channel_id: channelId,
          assignee_id: assigneeId,
        },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function manageAssistantForSupportSession(teamId: number, supportSessionId: number, assistant1Id: number, assistant2Id?: number) {
  return new Promise<MutateSupportSessionResponse>((resolve, reject) => {
    axios
      .put<ResponseData & { data: MutateSupportSessionResponse }>(
        `/api/web/v1/teams/${teamId}/service_desk/support_sessions/${supportSessionId}/action`,
        {
          kind: 'assist',
          assistant_1_id: assistant1Id,
          assistant_1_mode: 1,
          assistant_2_id: assistant2Id ?? 0,
          assistant_2_mode: assistant2Id ? 1 : 0,
        },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function updateCommentForSupportSession(teamId: number, supportSessionId: number, comment: string) {
  return new Promise<MutateSupportSessionResponse>((resolve, reject) => {
    axios
      .put<ResponseData & { data: MutateSupportSessionResponse }>(
        `/api/web/v1/teams/${teamId}/service_desk/support_sessions/${supportSessionId}/action`,
        {
          kind: 'comment',
          comment,
        },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getSupportSessionHistory(teamId: number, supportSessionId: number, historyInfo: SupportSessionHistoryRequestBody) {
  return new Promise<SupportSessionHistoryResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: SupportSessionHistoryResponse }>(
        `/api/web/v1/teams/${teamId}/service_desk/support_sessions/${supportSessionId}/histories`,
        {
          params: {
            start_time: historyInfo.startTime ?? '',
            end_time: historyInfo.endTime ?? '',
            per_page_size: historyInfo.perPageSize ?? 100,
            page: historyInfo.page ?? 1,
            order: historyInfo.order ?? 'desc',
          },
        },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getSupportSessionSessionNote(teamId: number, supportSessionId: number, sessionId: number) {
  return new Promise<SupportSessionSessionNoteResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: SupportSessionSessionNoteResponse }>(
        `/api/web/v1/teams/${teamId}/service_desk/support_sessions/${supportSessionId}/sessions/${sessionId}/notes`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function updateSupportSessionSessionNote(
  teamId: number,
  supportSessionId: number,
  sessionId: number,
  note: SupportSessionSessionNoteRequestBody,
) {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .put<ResponseData & ResponseErrorReason>(
        `/api/web/v1/teams/${teamId}/service_desk/support_sessions/${supportSessionId}/sessions/${sessionId}/notes`,
        note,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getSupportSessionFileLog(
  teamId: number,
  supportSessionId: number,
  sessionId: number,
  options?: SupportSessionLogQueryOptions,
) {
  return new Promise<SupportSessionFileLogResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: SupportSessionFileLogResponse }>(
        `/api/web/v1/teams/${teamId}/service_desk/support_sessions/${supportSessionId}/sessions/${sessionId}/file_logs`,
        {
          params: {
            start_time: options?.startTime ?? '',
            end_time: options?.endTime ?? '',
            per_page_size: options?.perPageSize ?? '',
            page: options?.page ?? '',
            order: options?.order ?? '',
          },
        },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getSupportSessionChatFile(teamId: number, supportSessionId: number, sessionId: number) {
  return new Promise<SupportSessionChatFileResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: SupportSessionChatFileResponse }>(
        `/api/web/v1/teams/${teamId}/service_desk/support_sessions/${supportSessionId}/sessions/${sessionId}/chat`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getSupportSessionSessionLogs(teamId: number, supportSessionId: number, options?: SupportSessionLogQueryOptions) {
  return new Promise<SessionLogsInSupportSessionResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: SessionLogsInSupportSessionResponse }>(
        `/api/web/v1/teams/${teamId}/service_desk/support_sessions/${supportSessionId}/session_logs`,
        {
          params: options
            ? {
                start_time: options?.startTime ?? '',
                end_time: options?.endTime ?? '',
                per_page_size: options?.perPageSize ?? '',
                page: options?.page ?? '',
                order: options?.order ?? '',
              }
            : {
                per_page_size: 100,
                service_kind: 'attended_remote',
                order: 'asc',
                start_time: subYears(new Date(), 1).toISOString(),
              },
        },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getSupportSessionSystemToolSessionLogs(teamId: number, supportSessionId: number, options?: SupportSessionLogQueryOptions) {
  return new Promise<SessionLogsInSupportSessionResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: SessionLogsInSupportSessionResponse }>(
        `/api/web/v1/teams/${teamId}/service_desk/support_sessions/${supportSessionId}/session_logs`,
        {
          params: options
            ? {
                start_time: options?.startTime ?? '',
                end_time: options?.endTime ?? '',
                per_page_size: options?.perPageSize ?? '',
                page: options?.page ?? '',
                order: options?.order ?? '',
              }
            : {
                per_page_size: 100,
                session_kind: 'service_desk_premium_tool',
                order: 'asc',
                start_time: subYears(new Date(), 1).toISOString(),
              },
        },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getSupportSessionVoiceCalls(teamId: number, supportSessionId: number, sessionId: number) {
  return new Promise<Array<SessionVoiceCallDetailItem>>((resolve, reject) => {
    axios
      .get<ResponseData & { data: { voice_calls: Array<SessionVoiceCallDetailItem> } }>(
        `/api/web/v1/teams/${teamId}/service_desk/support_sessions/${supportSessionId}/sessions/${sessionId}/voice_calls`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data.voice_calls);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getServiceDeskCloudBuildURL(packageCode: string, category: 'win' | 'mac' | 'Android' | 'iOS' | null) {
  return new Promise<ServiceDeskCloudBuildURLResponse>((resolve, reject) => {
    pureInstance
      .post<ResponseData & { data: ServiceDeskCloudBuildURLResponse }>(`/api/web/v1/teams/service_desk/${packageCode}/cloud_build_file`, {
        category,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export type RequestSupportSessionPermissions = {
  remoteAccess?: boolean;
  systemTools?: boolean;
};

export function requestSupportSessionPermission(teamId: number, supportSessionId: number, permissions: RequestSupportSessionPermissions) {
  let request_permission = 0;
  if (permissions.remoteAccess) {
    request_permission += RequestRemote;
  }
  if (permissions.systemTools) {
    request_permission += TaskManager + ServiceManager + RegistryEditor + DeviceManager;
  }

  return new Promise<RequestSupportSessionPermissionResponse>((resolve, reject) => {
    axios
      .post<ResponseData & { data: RequestSupportSessionPermissionResponse }>(
        `/api/web/v1/teams/${teamId}/service_desk/support_sessions/${supportSessionId}/permission`,
        {
          request_permission,
        },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function checkPermissionRequestResult(teamId: number, supportSessionId: number, permissionId: number) {
  return new Promise<CheckSupportSessionPermissionResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: CheckSupportSessionPermissionResponse }>(
        `/api/web/v1/teams/${teamId}/service_desk/support_sessions/${supportSessionId}/permission/${permissionId}`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export type PremiumToolEvents = Array<{
  created_at: string; // <dateTime>, # YYYY-mm-dd H:M:S>（UTC)
  event_type: number; // <integer>, # 11001
  action: string; // "task_name;PID"
}>;

export function getSystemToolsDetails(teamId: number, supportSessionId: number, sessionId: number) {
  return new Promise<PremiumToolEvents>((resolve, reject) => {
    axios
      .get<ResponseData & { data: { premium_tool_events: PremiumToolEvents } }>(
        `/api/web/v1/teams/${teamId}/service_desk/support_sessions/${supportSessionId}/sessions/${sessionId}/premium_tool_events`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data.premium_tool_events);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}
