import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';

export type CurrentOpenFieldAutoFocusType = 'label' | 'input' | 'theme';

type CurrentOpenFieldAtom = {
  uuid: string;
  autoFocusType: CurrentOpenFieldAutoFocusType;
} | null;

export const currentOpenFieldAtom = atomWithReset<CurrentOpenFieldAtom>(null);

export const useCurrentOpenField = () => useAtom(currentOpenFieldAtom);
export const useCurrentOpenFieldValue = () => useAtomValue(currentOpenFieldAtom);
export const useSetCurrentOpenField = () => useSetAtom(currentOpenFieldAtom);
export const useResetCurrentOpenField = () => useResetAtom(currentOpenFieldAtom);
