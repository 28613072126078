import { arrayToExistence, arrayToHashTable } from '@/utils/hashTable';

export const concatCombineAndUniqueBy = <T extends Record<string, any>, T2 extends Record<string, any>>(
  firstArray: Array<T>,
  secondArray: Array<T2>,
  concatKey: Extract<keyof T, keyof T2>,
) => {
  if (firstArray.length === 0) {
    return secondArray;
  } else if (secondArray.length === 0) {
    return firstArray;
  }

  const secondHashMap = arrayToHashTable(secondArray, { mainKey: concatKey });

  const firstCombined = firstArray.map((firstItem) => {
    const firstKeyValue = firstItem[concatKey];
    const secondItem = secondHashMap[firstKeyValue as any];

    if (secondItem) {
      return {
        ...firstItem,
        ...secondItem,
      };
    }
    return firstItem;
  });

  const firstExistHashMap = arrayToExistence(firstArray, { mainKey: concatKey });
  const secondCombined = secondArray.filter((secondItem) => !firstExistHashMap[secondItem[concatKey] as any]);

  return [...firstCombined, ...secondCombined];
};
