import axios from 'axios';
import { SafeParseError, z } from 'zod';

import { checkResponse } from '../utils';
import type {
  TeamMember,
  TeamMemberCustomField,
  TeamMemberListDefault,
  TeamMemberListGroup,
  TeamMemberListRequestMode,
  TeamMemberListSimple,
  TeamMemberRole,
  TeamMemberStatus,
} from './types';
import { teamMemberListDefaultSchema, teamMemberListGroupSchema, teamMemberListSimpleSchema, teamMemberSchema } from './types';

type TeamMemberListOption<Mode extends TeamMemberListRequestMode, CustomField extends TeamMemberCustomField = never> = {
  status?: Array<TeamMemberStatus>;
  /**
   * group_admin 必須是有啟動 create_group_admin 的 team 才能呼叫，否則會回傳 40403 錯誤
   */
  roles?: Array<TeamMemberRole>;
  ids?: Array<number>;
} & (Mode extends 'customize'
  ? { mode: 'customize'; customized_fields: Array<CustomField> }
  : Mode extends 'default'
  ? { mode?: 'default' }
  : { mode: Mode });

export function getTeamMemberList(teamId: number, options: TeamMemberListOption<'default'>): Promise<TeamMemberListDefault>;
export function getTeamMemberList(teamId: number, options: TeamMemberListOption<'simple'>): Promise<TeamMemberListSimple>;
export function getTeamMemberList(teamId: number, options: TeamMemberListOption<'group'>): Promise<TeamMemberListGroup>;
export function getTeamMemberList<Mode extends 'customize', CustomField extends TeamMemberCustomField>(
  teamId: number,
  options: TeamMemberListOption<Mode, CustomField>,
): Promise<TeamMemberListCustomize<CustomField>>;
export function getTeamMemberList<Mode extends TeamMemberListRequestMode, CustomField extends TeamMemberCustomField>(
  teamId: number,
  options: TeamMemberListOption<Mode, CustomField>,
): Promise<TeamMemberListDefault | TeamMemberListSimple | TeamMemberListGroup | TeamMemberListCustomize<CustomField>> {
  const url = `/api/web/v1/teams/${teamId}/team_members`;

  const params = new URLSearchParams();
  if (options.status) {
    params.set('status', options.status.join(','));
  }
  if (options.roles) {
    params.set('role', options.roles.join(','));
  }
  if (options.ids) {
    params.set('ids', options.ids.join(','));
  }

  switch (options.mode) {
    case 'simple': {
      params.set('mode', 'simple');
      return checkResponse(axios.get(url, { params }), teamMemberListSimpleSchema);
    }
    case 'group': {
      params.set('mode', 'group');

      return checkResponse(axios.get(url, { params }), teamMemberListGroupSchema);
    }
    case 'customize': {
      params.set('mode', 'customize');
      params.set('customized_fields', options.customized_fields.join(','));

      const schema = generateCustomFieldsSchema(options.customized_fields);
      return checkResponse(axios.get(url, { params }), schema);
    }
    case 'default':
    default: {
      return checkResponse(axios.get(url, { params }), teamMemberListDefaultSchema);
    }
  }
}

type MustHaveFields = 'id' | 'email' | 'member_name';
type TeamMemberCustomize<CustomField extends TeamMemberCustomField> = Pick<TeamMember, MustHaveFields> &
  Pick<TeamMember, CustomField> &
  (CustomField extends 'technician_manager' ? Pick<TeamMember, 'technician_manager_capability'> : Record<string, never>) &
  (CustomField extends 'super_admin' ? Pick<TeamMember, 'super_admin_capability'> : Record<string, never>) &
  (CustomField extends 'group_scope' ? Pick<TeamMember, 'in_charge_group_ids'> : Record<string, never>);
export type TeamMemberListCustomize<CustomField extends TeamMemberCustomField> = Array<TeamMemberCustomize<CustomField>>;

function generateCustomFieldsSchema<CustomField extends TeamMemberCustomField = TeamMemberCustomField>(customFields: Array<CustomField>) {
  type MaskFields = keyof TeamMemberCustomize<CustomField>;
  const mustHaveFields = ['id', 'email', 'member_name'] as const;
  const fields = [...customFields, ...mustHaveFields];

  const mask = fields.reduce<Record<MaskFields, true>>(
    (acc, field) => {
      acc[field] = true;

      switch (field) {
        case 'technician_manager': {
          acc['technician_manager_capability'] = true;
          break;
        }
        case 'super_admin': {
          acc['super_admin_capability'] = true;
          break;
        }
        case 'group_scope': {
          acc['in_charge_group_ids'] = true;
          break;
        }
        default:
          break;
      }

      return acc;
    },
    {} as Record<MaskFields, true>,
  );

  const schema = z.array(teamMemberSchema.pick(mask));

  // NOTE: z.custom here is a workaround for zod's type inference
  // NOTE: z.custom can not handle zod default schema
  return z.custom<TeamMemberListCustomize<CustomField>>(
    (data) => {
      const result = schema.safeParse(data);
      return result.success;
    },
    (data) => (schema.safeParse(data) as SafeParseError<typeof data>).error,
  );
}
