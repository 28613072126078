import { SupportSessionCategoryTypeValues } from '../services/serviceDesk/supportSession/types';
import { ANDROID_SOS_APP_URL, IOS_SOS_APP_URL_SCHEME } from './constants';
import { triggerClickUrl } from './dom';
import { getRegion } from './region';
import type { Region } from './region';

export const getMobileUrlScheme = (code: string, category: SupportSessionCategoryTypeValues, env: Region) =>
  `st-sos://com.splashtop.sos/sdlink?code=${code}&category=${category}&region=${env}`;

export const getMobileUrlSchemeForSOSCall = (code: string, category: SupportSessionCategoryTypeValues, env: Region, teamCode: string) =>
  `st-sos://com.splashtop.sos/sdlink?code=${code}&category=${category}&region=${env}&team_code=${teamCode}`;

export const getMobilePlayStoreUrlScheme = (code: string, category: SupportSessionCategoryTypeValues, env: Region) =>
  `${ANDROID_SOS_APP_URL}&url=st-sos://com.splashtop.sos/spt/sos/sdlink?category=${category}&code=${code}&region=${env}`;

export const launchSosIosApp = (code: string, category: SupportSessionCategoryTypeValues) => {
  const env = getRegion();
  const urlScheme = getMobileUrlScheme(code, category, env);

  triggerClickUrl(urlScheme);
};

export const launchSosAppStore = () => {
  triggerClickUrl(IOS_SOS_APP_URL_SCHEME);
};

export const launchSosAndroidApp = (code: string, category: SupportSessionCategoryTypeValues) => {
  const env = getRegion();
  const urlScheme = getMobileUrlScheme(code, category, env);

  triggerClickUrl(urlScheme);
};

export const launchSosCallAndroidApp = (code: string, category: SupportSessionCategoryTypeValues, teamCode: string) => {
  const env = getRegion();
  const urlScheme = getMobileUrlSchemeForSOSCall(code, category, env, teamCode);
  triggerClickUrl(urlScheme);
};

export const launchSosPlayStore = (code: string, category: SupportSessionCategoryTypeValues) => {
  const env = getRegion();
  const url = getMobilePlayStoreUrlScheme(code, category, env);
  triggerClickUrl(url);
};
