import chunk from 'lodash/chunk';
import random from 'lodash/random';

/**
 * Create a function that calls a list of promises in chunks with a delay between each chunk.
 */
export function createBulkActionCall(chunkAmount: number, delay?: number | (() => number)) {
  return async function bulkActionCall<T>(promiseClosureList: Array<() => Promise<T>>) {
    const result: Array<T> = [];
    const chunkList = chunk(promiseClosureList, chunkAmount);

    for (const promiseClosureChunk of chunkList) {
      const chunkResult = await Promise.all(promiseClosureChunk.map((promiseClosure) => promiseClosure()));
      result.push(...chunkResult);

      if (typeof delay === 'function') {
        await new Promise((resolve) => setTimeout(resolve, delay()));
      } else if (typeof delay === 'number') {
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }

    return result;
  };
}

/**
 * Critical delay function that returns a random number between 0.5 and 1.5 seconds.
 */
function criticalDelay() {
  return random(0.5, 1.5) * 1000;
}

const criticalChunkAmount = 10;

/**
 * Critical bulk action call function that calls 10 promises in each chunk with a random delay between 0.5 and 1.5 seconds.
 * This is used for some API calls that may cause backend performance issues if too many requests are sent at the same time.
 * Like delete computers in All Computers page.
 */
export const criticalBulkActionCall = createBulkActionCall(criticalChunkAmount, criticalDelay);
