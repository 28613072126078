import { Flex } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useTranslation } from 'next-i18next';

import {
  RippleButton,
  RippleHeading08,
  RippleMultipleSelect,
  RippleMultipleSelectOption,
  RippleMultipleSelectOptionDivider,
} from '@/design';
import { OSTypeEnum } from '@/utils/computers';

import { filterPlatformAtom } from '../../atoms';

export function FilterPlatform(): React.JSX.Element {
  const { t } = useTranslation();
  const [filterPlatform, setFilterPlatform] = useAtom(filterPlatformAtom);

  function handleReset() {
    setFilterPlatform([]);
  }

  return (
    <Flex flexDirection="column">
      <RippleHeading08 mb="8px">{t('computer:filter_by_platform')}</RippleHeading08>
      <RippleMultipleSelect
        data-testid="filter-platform-select-button"
        value={filterPlatform}
        placeholder={t('common:all')}
        onChange={(value) => {
          setFilterPlatform(value ?? []);
        }}
      >
        <RippleMultipleSelectOption value={OSTypeEnum.android}>Android</RippleMultipleSelectOption>
        <RippleMultipleSelectOption value={OSTypeEnum.linux}>Linux</RippleMultipleSelectOption>
        <RippleMultipleSelectOption value={OSTypeEnum.mac}>Mac</RippleMultipleSelectOption>
        <RippleMultipleSelectOption value={OSTypeEnum.rdp}>{t('computer:rdp_computer')}</RippleMultipleSelectOption>
        <RippleMultipleSelectOption value={OSTypeEnum.ssh}>{t('computer:ssh_computer')}</RippleMultipleSelectOption>
        <RippleMultipleSelectOption value={OSTypeEnum.vnc}>{t('computer:vnc_computer')}</RippleMultipleSelectOption>
        <RippleMultipleSelectOption value={OSTypeEnum.win}>Windows</RippleMultipleSelectOption>
        <RippleMultipleSelectOption value={OSTypeEnum.pc}>{t('common:others')}</RippleMultipleSelectOption>
        <RippleMultipleSelectOptionDivider />
        <RippleButton variant="grayScaleTertiary" size="xxs" ml="8px" onClick={handleReset}>
          {t('common:reset')}
        </RippleButton>
      </RippleMultipleSelect>
    </Flex>
  );
}
