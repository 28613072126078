import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import type { ComputerScope } from '../../types';

export function getMoreActionsQueryKey({ computerScope, computerId }: { computerScope: ComputerScope; computerId: number }) {
  return ['computerList', 'computerActions', computerScope, computerId] as const;
}

export function useRemoveMoreActionsCache() {
  const queryClient = useQueryClient();

  return useCallback(
    ({ computerScope, computerId }: { computerScope: ComputerScope; computerId: number }) => {
      queryClient.removeQueries({ queryKey: getMoreActionsQueryKey({ computerScope, computerId }), exact: true });
    },
    [queryClient],
  );
}
