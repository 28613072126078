import { useContext } from 'react';

import { useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';

import { RippleArrowDown16, RippleArrowUp16, RippleBulk, RippleButton, type RippleComputerGroupDropdownData } from '@/design';
import { useTeamExpiration, useTeamRole } from '@/models/TeamInformation';
import { NestedMenu, NestedMenuContent, NestedMenuGroup, NestedMenuItem, NestedMenuList, NestedMenuTrigger } from '@/showcase';
import type { NestedMenuProps } from '@/showcase';

import { useAssignGroup } from '../../AssignGroup';
import { ComputerGroupMenu } from '../../ComputerGroupMenu';
import { useDeleteComputers } from '../../DeleteComputers';
import { useRebootComputers, useUpdateStreamers } from '../../actions';
import { manageableGroupListAtom, rowSelectionCountAtom, selectedComputerListAtom } from '../../atoms';
import { ColumnPermissionsContext } from '../../contexts';
import { useShowDefaultGroup } from '../../utils';
import { useActionBarContext } from './hooks';

export function BulkActions({ isDisabled = false, showDeleteComputers = false }): React.JSX.Element {
  const { t } = useTranslation();
  const { isTeamExpired } = useTeamExpiration();
  const { isManager, isMember } = useTeamRole();

  const { isLoading } = useActionBarContext();

  const columnPermissions = useContext(ColumnPermissionsContext);
  const canAssignGroup = !isTeamExpired && isManager && columnPermissions.group;
  const canUpdateStreamers = !isTeamExpired;
  const canRebootComputers = !isTeamExpired && columnPermissions.remoteReboot && (isMember ? columnPermissions.memberRebootComputer : true);

  const selectedCount = useAtomValue(rowSelectionCountAtom);
  const hasSelectedComputer = selectedCount > 0;

  const boundary: NestedMenuProps['boundary'] = document.querySelector('main') ?? 'scrollParent';

  const isOpenStyle = {
    bg: 'blue.300',
    borderColor: 'blue.300',
  };

  return (
    <NestedMenu boundary={boundary} placement="auto-end">
      {({ isOpen }) => (
        <>
          <NestedMenuTrigger>
            <RippleButton
              data-testid="bulk-actions-button"
              size="xs"
              px="8px"
              variant="primary"
              leftIcon={<RippleBulk color="inherit" />}
              rightIcon={isOpen ? <RippleArrowUp16 color="inherit" /> : <RippleArrowDown16 color="inherit" />}
              isDisabled={isDisabled || isLoading || !hasSelectedComputer}
              sx={isOpen ? isOpenStyle : {}}
            >
              {t('computer:bulkActions.title')}
            </RippleButton>
          </NestedMenuTrigger>
          <NestedMenuContent>
            <NestedMenuList>
              {(canAssignGroup || canUpdateStreamers || canRebootComputers) && (
                <NestedMenuGroup>
                  {canUpdateStreamers && <UpdateStreamersAction />}
                  {canRebootComputers && <RebootComputersAction />}
                  {canAssignGroup && <AssignGroupAction />}
                </NestedMenuGroup>
              )}
              {showDeleteComputers && (
                <NestedMenuGroup>
                  <DeleteComputersAction />
                </NestedMenuGroup>
              )}
            </NestedMenuList>
          </NestedMenuContent>
        </>
      )}
    </NestedMenu>
  );
}

function DeleteComputersAction(): React.JSX.Element {
  const { t } = useTranslation();
  const selectedComputerList = useAtomValue(selectedComputerListAtom);
  const deleteComputers = useDeleteComputers();

  function handleDelete() {
    const targetComputerList = selectedComputerList.map(({ id, name }) => ({ id: String(id), name }));
    deleteComputers.execute({ targetComputerList });
  }

  return <NestedMenuItem data-testid="delete-computers" label={t('computer:bulkActions.item.deleteComputers')} onClick={handleDelete} />;
}

function AssignGroupAction(): React.JSX.Element {
  const { t } = useTranslation();
  const showDefaultGroup = useShowDefaultGroup();

  const groupList = useAtomValue(manageableGroupListAtom);
  const selectedComputerList = useAtomValue(selectedComputerListAtom);

  const assignGroup = useAssignGroup();

  return (
    <NestedMenuItem data-testid="assign-group" label={t('computer:assignComputerGroup')} subMenu>
      <ComputerGroupMenu
        showAllGroup={false}
        showDefaultGroup={showDefaultGroup}
        showFromOtherGroup={false}
        groups={groupList}
        onSelect={handleAssignGroup}
      />
    </NestedMenuItem>
  );

  function handleAssignGroup(selectedGroup: RippleComputerGroupDropdownData) {
    const targetGroup = { id: Number(selectedGroup.id), name: selectedGroup.name };
    const targetComputerList = selectedComputerList.map(({ id, name }) => ({ id: String(id), name }));
    assignGroup.execute({ targetGroup, targetComputerList });
  }
}

function UpdateStreamersAction(): React.JSX.Element {
  const { t } = useTranslation();
  const selectedComputerList = useAtomValue(selectedComputerListAtom);

  const { execute } = useUpdateStreamers();

  function handleClick() {
    const targetComputerList = selectedComputerList.map(({ id, name }) => ({ id: String(id), name }));
    execute({ targetComputerList });
  }

  return <NestedMenuItem data-testid="update-streamers" label={t('computer:bulkActions.item.updateStreamers')} onClick={handleClick} />;
}

function RebootComputersAction(): React.JSX.Element {
  const { t } = useTranslation();
  const selectedComputerList = useAtomValue(selectedComputerListAtom);

  const { execute } = useRebootComputers();

  function handleClick() {
    const targetComputerList = selectedComputerList.map(({ id, name }) => ({ id: String(id), name }));
    execute({ targetComputerList });
  }

  return <NestedMenuItem data-testid="reboot-computers" label={t('computer:bulkActions.item.rebootComputers')} onClick={handleClick} />;
}
