import { useQuery } from '@tanstack/react-query';

import { TeamKind } from '@/services/common/types';
import { infoQueryService } from '@/services/users';

type SosCustomizationAccess =
  | { teamType: TeamKind; isExpired: boolean; canAccess: boolean }
  | { teamType: ''; isExpired: false; canAccess: false };

export const useSosCustomizationAccessQuery = () => {
  return useQuery({
    queryKey: ['info-query', 'sos-customization-access'],
    queryFn: () =>
      infoQueryService.execute({
        team_member_permissions: ['technician_manager'],
        team_info: ['is_expired'],
      }),
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 5 * 60 * 1000, // 5 minutes
    select: (data): SosCustomizationAccess => {
      const isSosTeamAvailable = !data.sos?.team_info.is_expired && data.sos?.team_member_permissions.technician_manager;

      const teamType = isSosTeamAvailable ? 'sos' : (Object.keys(data).find((teamKind) => teamKind !== 'sos') as TeamKind | undefined);

      const teamResult = teamType ? data[teamType] ?? null : null;

      if (!teamType || !teamResult) return { teamType: '', isExpired: false, canAccess: false };

      return {
        teamType: teamType,
        isExpired: teamResult.team_info.is_expired,
        canAccess: teamResult.team_member_permissions.technician_manager,
      };
    },
  });
};
