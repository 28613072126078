import { Fragment, useMemo } from 'react';

import { Flex, forwardRef } from '@chakra-ui/react';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { GroupingState, Row } from '@tanstack/react-table';
import { useAtomValue } from 'jotai';
import { TableVirtuoso } from 'react-virtuoso';

import { RippleCheckbox } from '@/design';

import { GroupHeader } from '../GroupTable/components';
import { makeTableVirtuosoComponentsForGroup } from '../TableVirtuosoComponents';
import { groupNameMapAtom } from '../atoms';
import { FROM_OTHER_GROUP_ID, GROUPING_COLUMN } from '../constants';
import { getRowId } from '../utils';
import { useWakeComputerModalActions, useWakeComputerModalData } from './WakeComputerModalAtom';
import { WakeComputer, WakeTotalWidth, useWakeComputerModalColumns } from './WakeComputerModalColumns';

const groupingState: GroupingState = [GROUPING_COLUMN];
const makeTableComponents = <D,>() => ({
  ...makeTableVirtuosoComponentsForGroup<D>(),
  Scroller: forwardRef((props, ref) => {
    return <Flex ref={ref} overflowX="hidden" {...props} />;
  }),
});

type WakeComputerModalTableProps = {
  data: Array<WakeComputer>;
  height: number;
};

export function WakeComputerModalGroupTable({ data, height }: WakeComputerModalTableProps): React.JSX.Element {
  const groupNameMap = useAtomValue(groupNameMapAtom);

  const columns = useWakeComputerModalColumns();
  const { state, selectedComputerIdsHashMap } = useWakeComputerModalData();
  const { handleSelectId } = useWakeComputerModalActions();

  const table = useReactTable({
    data,
    columns,
    getRowId,
    state: {
      rowSelection: selectedComputerIdsHashMap,
      grouping: groupingState,
    },
    enableRowSelection: true,
    onRowSelectionChange: (expandedStateUpdater) => {
      if (typeof expandedStateUpdater === 'function' && state === 'Selector') {
        const nextSelectedIds = expandedStateUpdater(selectedComputerIdsHashMap);
        handleSelectId(nextSelectedIds);
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const { rows } = table.getRowModel();

  return (
    <TableVirtuoso
      style={{ height: `${height}px`, width: `${WakeTotalWidth}px` }}
      data={rows}
      // NOTE: `components` should be memoized to avoid some performance issues
      components={useMemo(() => makeTableComponents<Row<WakeComputer>>(), [])}
      context={{ table }}
      itemContent={(index, row) => {
        const isGroupRow = row.getCanExpand();

        if (isGroupRow) {
          const isFirstRow = index === 0;
          const isAllSelected = row.getIsAllSubRowsSelected();
          const isSomeSelected = row.getIsSomeSelected();

          const groupName = groupNameMap[row.original.group_id ?? FROM_OTHER_GROUP_ID];

          return (
            <Flex
              key={row.id}
              marginTop={isFirstRow ? '0' : '12px'}
              sx={{
                '.computerList__row__groupHeader': {
                  bgColor: isAllSelected || isSomeSelected ? 'green.20' : 'neutral.40',
                },
                '&:hover .computerList__row__groupHeader': {
                  bgColor: isAllSelected || isSomeSelected ? 'green.40' : 'blue.0',
                },
              }}
              w="100%"
            >
              <GroupHeader
                checkbox={
                  <RippleCheckbox
                    data-testid={`group-checkbox-${row.original.group_id}`}
                    isChecked={isAllSelected}
                    isIndeterminate={isSomeSelected}
                    onChange={row.getToggleSelectedHandler()}
                  />
                }
                sx={{
                  name: {
                    maxWidth: '310px',
                  },
                }}
                isExpanded={row.getIsExpanded()}
                name={groupName}
                count={row.subRows.length}
                onClick={row.getToggleExpandedHandler()}
              />
            </Flex>
          );
        }

        // computer row
        const isSelected = row.getIsSelected();
        const isLastRow = index === rows.length;
        const nextRow = rows[index + 1];
        const isLastRowInGroup = nextRow?.getCanExpand() || isLastRow;

        return (
          <Flex
            key={row.id}
            sx={{
              '[data-cell-type="normal"]': {
                bgColor: isSelected ? 'green.10' : 'white',
              },
              '&:hover [data-cell-type="normal"]': {
                bgColor: isSelected ? 'green.40' : 'blue.0',
              },
              '[data-cell-type="normal"]:first-of-type': {
                borderBottomLeftRadius: isLastRowInGroup ? '4px' : '0',
              },
              '[data-cell-type="normal"]:last-of-type': {
                borderBottomRightRadius: isLastRowInGroup ? '4px' : '0',
              },
            }}
            w="100%"
          >
            {row.getVisibleCells().map((cell) => {
              return <Fragment key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Fragment>;
            })}
          </Flex>
        );
      }}
    />
  );
}
