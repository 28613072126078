import { useAtomValue } from 'jotai';

import Highlight from '@/components/Highlight';

import { searchKeywordAtom } from '../atoms';

type HighlightSearchKeywordProps = { children: string | null | undefined };
export function HighlightSearchKeyword({ children }: HighlightSearchKeywordProps): React.JSX.Element {
  const keyword = useAtomValue(searchKeywordAtom);

  return <Highlight keyword={keyword}>{children}</Highlight>;
}
