import Image from 'next/image';

import { Box, Flex, Stack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleHeading04, RippleHeading05, RippleTooltip } from '@/design';
import ConnectFrom from '@/modules/Unboxing/Desktop/ConnectFrom';
import ConnectTo from '@/modules/Unboxing/Desktop/ConnectTo';
import DesktopSRC from '@/modules/Unboxing/images/desktop_SRC.svg';
import DesktopSRS from '@/modules/Unboxing/images/desktop_SRS.svg';
import { InstallationTutorialText } from '@/modules/Unboxing/shared/InstallationTutorialText';
import { UnboxingProps } from '@/modules/Unboxing/types';

export const UnboxingDesktop = ({ pageTitle, pageSubtitle }: UnboxingProps) => {
  const { t } = useTranslation(['welcome', 'common']);

  return (
    <Flex direction="column" align="center" justify="center" py="8vh">
      {pageTitle && <RippleHeading04 align="center">{pageTitle}</RippleHeading04>}
      {pageSubtitle && (
        <RippleHeading05 color="neutral.200" pt="16px" align="center">
          {pageSubtitle}
        </RippleHeading05>
      )}
      <Stack direction="row" my="32px" spacing="0">
        <RippleTooltip aria-label={t('welcome:srcTooltip')} label={t('welcome:srcTooltip')} placement="bottom-start">
          <Box>
            <Image src={DesktopSRC} alt="client" priority />
          </Box>
        </RippleTooltip>
        <RippleTooltip aria-label={t('welcome:srsTooltip')} label={t('welcome:srsTooltip')} placement="bottom-end">
          <Box>
            <Image src={DesktopSRS} alt="streamer" priority />
          </Box>
        </RippleTooltip>
      </Stack>
      <Stack spacing="64px" direction="row">
        <ConnectFrom />
        <ConnectTo />
      </Stack>
      <InstallationTutorialText />
    </Flex>
  );
};
