import { useRef, useState } from 'react';

import { HStack, Portal } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  RippleArrowRight,
  RippleFilter,
  RippleMenu,
  RippleMenuDivider,
  RippleMenuGroup,
  RippleMenuIconButton,
  RippleMenuItem,
  RippleMenuList,
  RippleSwitch,
  RippleTooltip,
  RippleTypography,
} from '@/design';
import { DEFAULT_GROUP_ID } from '@/modules/Computer';
import { Group } from '@/services/group';
import { Policy } from '@/services/teams/emm_policies';

import { GroupList } from './GroupList';
import { PolicyList } from './PolicyList';
import { useTableFilter } from './useTableFilter';

type TableFilterProps = {
  /**
   * Group map
   * @key group ID
   * @value group name
   */
  groupMap: Record<number, Group>;
  /**
   * Policy map
   * @key policy ID
   * @value policy name
   */
  policyMap: Record<number, Policy>;
};

export const TableFilter = ({ groupMap, policyMap }: TableFilterProps) => {
  const {
    groupFilter,
    policyFilterSet,
    setGroupFilter,
    setPolicyFilter,
    followGroupFilter,
    isSelectedOnlyFilter,
    setFollowGroupFilter,
    toggleIsSelectedOnly,
  } = useTableFilter();
  const groupListRef = useRef<HTMLDivElement>(null);
  const policyListRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const [view, setView] = useState<'' | 'GROUP' | 'POLICY'>('');
  const [menuHeight, setMenuHeight] = useState<number | undefined>(undefined);
  const filteredPolicies = [...policyFilterSet].map((id) => policyMap[Number(id)].name);
  const { filterCount } = useTableFilter();
  const { t } = useTranslation();

  const handleGroupsShown = () => {
    setView('GROUP');
    setMenuHeight(groupListRef.current?.clientHeight);
  };
  const handlePoliciesShown = () => {
    setView('POLICY');
    setMenuHeight(policyListRef.current?.clientHeight);
  };
  const handleMenuHeightChange = () => {
    if (view === 'GROUP') {
      setMenuHeight(groupListRef.current?.clientHeight);
      return;
    }
    if (view === 'POLICY') {
      setMenuHeight(policyListRef.current?.clientHeight);
      return;
    }
    setMenuHeight(undefined);
  };

  const handleMenuClose = () => {
    setMenuHeight(undefined);
    setView('');
  };

  const policyNames = (followGroupFilter ? [t('emm-policy:follow_group')] : []).concat(filteredPolicies).join(', ');
  const hasFilter = filterCount > 0;

  const groupNames =
    typeof groupFilter === 'undefined'
      ? t('common:all_groups')
      : String(groupFilter) === DEFAULT_GROUP_ID
      ? t('common:default_group')
      : groupMap[groupFilter].name;

  return (
    <RippleMenu closeOnSelect={false} placement="bottom-end" isLazy>
      <RippleTooltip label={t('common:filter')}>
        <RippleMenuIconButton
          data-testid="filter"
          aria-label="filter"
          backgroundColor={hasFilter ? 'blue.20' : 'transparent'}
          icon={<RippleFilter color={hasFilter ? 'blue.200' : undefined} isApplied={hasFilter} />}
        />
      </RippleTooltip>
      <Portal>
        <RippleMenuList zIndex="modal" w="336px" minHeight={menuHeight} transition="min-height 80ms" ref={rootRef}>
          <RippleMenuItem>
            <HStack justifyContent="space-between">
              <RippleTypography variant="body02">{t('common:show_selected_only')}</RippleTypography>
              <RippleSwitch size="md" isChecked={isSelectedOnlyFilter} onChange={toggleIsSelectedOnly} />
            </HStack>
          </RippleMenuItem>
          <RippleMenuDivider />
          <RippleMenuGroup title={t('common:filter_by_group')}>
            <RippleMenuItem pr="8px" onClick={handleGroupsShown}>
              <HStack justifyContent="space-between">
                <RippleTooltip label={groupNames} aria-label={groupNames}>
                  <RippleTypography variant="body02">{groupNames}</RippleTypography>
                </RippleTooltip>
                <RippleArrowRight />
              </HStack>
            </RippleMenuItem>
          </RippleMenuGroup>
          <RippleMenuDivider />
          <RippleMenuGroup title={t('common:filter_by_policy')}>
            <RippleMenuItem pr="8px" onClick={handlePoliciesShown}>
              <HStack justifyContent="space-between">
                <RippleTooltip label={policyNames} aria-label={policyNames}>
                  <RippleTypography variant="body02">{policyNames || t('emm-policy:all_policies')}</RippleTypography>
                </RippleTooltip>

                <RippleArrowRight />
              </HStack>
            </RippleMenuItem>
          </RippleMenuGroup>
          <GroupList
            minHeight={rootRef.current?.clientHeight}
            isOpen={view === 'GROUP'}
            ref={groupListRef}
            groups={Object.values(groupMap)}
            selectedGroupId={groupFilter}
            onClose={handleMenuClose}
            onGroupSelect={setGroupFilter}
            onAnimateComplete={handleMenuHeightChange}
          />
          <PolicyList
            ref={policyListRef}
            isOpen={view === 'POLICY'}
            minHeight={rootRef.current?.clientHeight}
            policies={Object.values(policyMap).toSorted((a, b) => a.name.localeCompare(b.name))}
            showFollowGroup={followGroupFilter}
            selectedPolicyIdSet={policyFilterSet}
            onClose={handleMenuClose}
            onGroupSelect={setGroupFilter}
            onPolicyChange={setPolicyFilter}
            onAnimateComplete={handleMenuHeightChange}
            onFollowGroupChange={setFollowGroupFilter}
          />
        </RippleMenuList>
      </Portal>
    </RippleMenu>
  );
};
