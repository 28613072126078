import { ButtonGroup } from '@chakra-ui/react';

import { RippleButton } from '@/design';

type RadioButtonGroupProps = {
  value: string;
  onChange: (value: string) => void;
  options: Array<{ label: string; value: string }>;
};
export function RadioButtonGroup({ value, onChange, options }: RadioButtonGroupProps): React.JSX.Element {
  return (
    <ButtonGroup isAttached w="100%">
      {options.map(({ label, value: optionValue }) => (
        <RippleButton
          data-testid={`radio-button-${optionValue}`}
          key={optionValue}
          variant="secondary"
          size="sm"
          flex="1"
          onClick={() => {
            onChange(optionValue);
          }}
          isActive={optionValue === value}
          _notFirst={{
            borderLeft: 'none',
          }}
        >
          {label}
        </RippleButton>
      ))}
    </ButtonGroup>
  );
}
