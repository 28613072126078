import { baseColumnIdMap, baseColumnWidthMap } from '../ComputerList';

export const columnIdMap = {
  ...baseColumnIdMap,
  computerOwner: 'computerOwner',
  access: 'access',
  group_id: 'group_id',
  groupName: 'groupName',
  version: 'version',
  osVersion: 'osVersion',
  os: 'os',
  osBuild: 'osBuild',
  architecture: 'architecture',
  emm_policy: 'emm_policy',
  preference_policy: 'preference_policy',
  pubip: 'pubip',
  local_ip: 'local_ip',
  note: 'note',
  antivirus_scan_status: 'antivirus_scan_status',
  windows_updates: 'windows_updates',
  alerts_count: 'alerts_count',
  infra_gen_status: 'infra_gen_status',
  last_online: 'last_online',
  last_session: 'last_session',
} as const;

export const columnWidthMap = {
  ...baseColumnWidthMap,
  [columnIdMap.name]: 272,
  [columnIdMap.computerOwner]: 192,
  [columnIdMap.access]: 128,
  [columnIdMap.group_id]: 0,
  [columnIdMap.groupName]: 160,
  [columnIdMap.version]: 128,
  [columnIdMap.osVersion]: 160,
  [columnIdMap.os]: 272,
  [columnIdMap.osBuild]: 272,
  [columnIdMap.architecture]: 160,
  [columnIdMap.emm_policy]: 272,
  [columnIdMap.preference_policy]: 160,
  [columnIdMap.pubip]: 352,
  [columnIdMap.local_ip]: 352,
  [columnIdMap.note]: 160,
  [columnIdMap.antivirus_scan_status]: 96,
  [columnIdMap.windows_updates]: 96,
  [columnIdMap.alerts_count]: 96,
  [columnIdMap.infra_gen_status]: 160,
  [columnIdMap.last_online]: 192,
  [columnIdMap.last_session]: 192,
} as const;
