import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import {
  RippleButton,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalTitle,
} from '@/design';

export type LeaveConfirmModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const LeaveConfirmModal = ({ isOpen, onClose, onConfirm }: LeaveConfirmModalProps) => {
  const { t } = useTranslation();
  return (
    <RippleModal isOpen={isOpen} onClose={onClose} size="xl">
      <RippleModalOverlay />
      <RippleModalContent>
        <RippleModalHeader>
          <RippleModalTitle>{t('support-forms:exitModalTitle')}</RippleModalTitle>
        </RippleModalHeader>
        <RippleModalBody>{t('support-forms:exitModalDescription')}</RippleModalBody>
        <RippleModalFooter>
          <Stack direction="row" spacing={3}>
            <RippleButton variant="grayScaleGhost" onClick={onClose}>
              {t('common:cancel')}
            </RippleButton>
            <RippleButton onClick={onConfirm}>{t('common:leave')}</RippleButton>
          </Stack>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
};
