import { ConfirmModal, useConfirmModal } from './ConfirmModal';

export function useUpdateStreamers() {
  const confirmModal = useConfirmModal();

  return {
    execute: confirmModal.open,
  };
}

export function UpdateStreamers(): React.JSX.Element {
  return (
    <>
      <ConfirmModal />
    </>
  );
}
