import { useCallback, useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { type Row } from '@tanstack/react-table';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { selectAtom } from 'jotai/utils';

import { type RippleCheckboxProps } from '@/design';
import { useTeamExpiration, useTeamInformation } from '@/models/TeamInformation';
import { ComputerData, ComputerListCustomizedField, getComputerRemoteControlPermissions } from '@/services/computers';
import { getOSIconType } from '@/utils/computers';
import getBrowserOS, { type OS as BrowserOS } from '@/utils/getOS';

import {
  computerDataFieldsAtom,
  computerDataMapAtom,
  headerSelectionStateAtom,
  rowSelectionStateAtom,
  toggleHeaderSelectionStateAtom,
} from './atoms';
import type { GroupedComputerData } from './types';
import { hasActionAbility } from './utils';

export function useHeaderCheckboxProps(): Pick<RippleCheckboxProps, 'isChecked' | 'isIndeterminate' | 'onChange'> {
  const selectionState = useAtomValue(headerSelectionStateAtom);
  const toggleHeaderSelectionState = useSetAtom(toggleHeaderSelectionStateAtom);

  return {
    isChecked: selectionState === 'all',
    isIndeterminate: selectionState === 'indeterminate',
    onChange: toggleHeaderSelectionState,
  };
}

export function useComputerCheckboxProps(computerId: string): Pick<RippleCheckboxProps, 'isChecked' | 'isDisabled' | 'onChange'> {
  const [rowSelection, setRowSelection] = useAtom(rowSelectionStateAtom);

  const computerDataAtom = useMemo(() => selectAtom(computerDataMapAtom, (computerDataMap) => computerDataMap[computerId]), [computerId]);
  const computerData = useAtomValue(computerDataAtom);

  const toggleSelection = useCallback(() => {
    setRowSelection((draft) => {
      draft[computerId] = !draft[computerId];
    });
  }, [computerId, setRowSelection]);

  return {
    isChecked: Boolean(rowSelection[computerId]),
    isDisabled: !hasActionAbility(computerData),
    onChange: toggleSelection,
  };
}

export function useGetGroupCheckboxProps<T extends GroupedComputerData>(): (
  groupRow: Row<T & { subRows?: Array<T> | undefined }>,
) => Pick<RippleCheckboxProps, 'isChecked' | 'isIndeterminate' | 'isDisabled' | 'onChange'> {
  const [rowSelection, setRowSelection] = useAtom(rowSelectionStateAtom);

  return useCallback(
    (groupRow) => {
      const selectableSubRows = groupRow.subRows?.filter((subRow) => hasActionAbility(subRow.original)) ?? [];
      const selectableComputerIdList = selectableSubRows.map((subRow) => subRow.original.id);
      const selectableCount = selectableSubRows.length;

      const selectedCount = selectableSubRows.filter((subRow) => rowSelection[subRow.original.id]).length;

      const isDisabled = selectableCount === 0;
      const isAllSelected = !isDisabled && selectedCount === selectableCount;
      const isSomeSelected = !isDisabled && selectedCount > 0 && selectedCount !== selectableCount;

      function toggleSelection() {
        setRowSelection((draft) => {
          selectableComputerIdList.forEach((computerId) => {
            draft[computerId] = !isAllSelected;
          });
        });
      }

      return {
        isChecked: isAllSelected,
        isIndeterminate: isSomeSelected,
        isDisabled,
        onChange: toggleSelection,
      };
    },
    [rowSelection, setRowSelection],
  );
}

export function useCanRemoteControl(computerData: Pick<ComputerData, 'version' | 'online_status'>): boolean {
  const { teamKind, member_status } = useTeamInformation();
  const { isTeamExpired } = useTeamExpiration();

  const { isFetching, data } = useQuery({
    queryKey: ['computerList', 'canRemoteControl'],
    queryFn: () => getComputerRemoteControlPermissions(),
    staleTime: Infinity,
  });

  const isPlatformSupport = computeIsPlatformSupport();
  function computeIsPlatformSupport() {
    const permissions = data?.[teamKind];
    const platform = getOSIconType(computerData.version);

    switch (platform) {
      case 'vnc': {
        return permissions?.team_permissions.vnc_computer ?? false;
      }
      case 'rdp': {
        return permissions?.team_permissions.rdp_computer ?? false;
      }
      case 'ssh': {
        const browserOS = getBrowserOS();
        const availableBusinessAppOSs: Array<BrowserOS> = ['Windows', 'Mac'];
        return availableBusinessAppOSs.includes(browserOS);
      }
      default: {
        return true;
      }
    }
  }

  if (isFetching) return false;

  return !isTeamExpired && member_status === 'enabled' && Boolean(computerData.online_status) && isPlatformSupport;
}

export function useRegisterComputerDataFields(fields: Array<ComputerListCustomizedField>): void {
  const setComputerDataFields = useSetAtom(computerDataFieldsAtom);

  useEffect(() => {
    setComputerDataFields(fields);
  }, [setComputerDataFields, fields]);
}
