import { useEffect } from 'react';

import * as Sentry from '@sentry/nextjs';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useRippleFlashMessage } from '@/design';
import { useTeamId } from '@/models/TeamInformation';
import { listEmmPoliciesService } from '@/services/teams/emm_policies';

type Policies = z.infer<typeof listEmmPoliciesService.responseSchema>;

type UsePoliciesQueryProps<T> = {
  select?: (data: Policies) => T;
  enabled?: boolean;
};

export const usePoliciesQueryKey = () => {
  const teamId = useTeamId();
  return ['listEmmPolicies', teamId] as const;
};

export const usePoliciesQuery = <TData = Policies>({ select, enabled = true }: UsePoliciesQueryProps<TData> = {}) => {
  const teamId = useTeamId();
  const { flashMessage } = useRippleFlashMessage();
  const { t } = useTranslation();
  const queryKey = usePoliciesQueryKey();
  const query = useQuery({
    queryFn: () => listEmmPoliciesService.execute(teamId),
    queryKey,
    select,
    enabled,
  });

  useEffect(
    function genericErrorHandler() {
      if (!query.error) {
        return;
      }
      if (
        listEmmPoliciesService.errorHandling.memberDisabled(query.error) ||
        listEmmPoliciesService.errorHandling.notMatchAnyProduct(query.error) ||
        listEmmPoliciesService.errorHandling.resourceNotExist(query.error) ||
        listEmmPoliciesService.errorHandling.roleNotSupported(query.error) ||
        listEmmPoliciesService.errorHandling.teamSeatExpired(query.error) ||
        listEmmPoliciesService.errorHandling.userHasNoTeam(query.error)
      ) {
        flashMessage({ variant: 'error', title: t('common:failed_to_get_data') });

        setTimeout(() => {
          window.location.href = '/';
        }, 2000);
        return;
      }
      // Unexpected Error
      Sentry.captureException(query.error);
      flashMessage({ id: 'policy-query', variant: 'error', title: t('common:failed_to_get_data') });
    },
    [flashMessage, query.error, t],
  );

  return query;
};
