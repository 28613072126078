import matches from 'lodash/matches';

import { TeamInfoFull } from '@/services/users/infoQueryService/team_info/types';

/**
 * Check `feature_id` and `feature_capacity` are matched for a specific team plan.
 *
 * @see fulong API (V3) spec 2.22 FEATURE CAPACITIES (feature kind is subscription)
 */
export const TeamMatches = {
  /**
   * Splashtop Access-Solo
   */
  Splashtop_Access_Solo: matchesFeatureIdSet(4, 15),
  /**
   * Splashtop Access-Pro
   */
  Splashtop_Access_Pro: matchesFeatureIdSet(4, 16),
  /**
   * Splashtop Access-Performance
   */
  Splashtop_Access_Performance: matchesFeatureIdSet(4, 17),
  /**
   * Splashtop Access-Pro + Support-10
   */
  Splashtop_Access_Pro_Support_10: matchesFeatureIdSet(4, 18),
  /**
   * Splashtop Access-Pro + Support-300
   */
  Splashtop_Access_Pro_Support_300: matchesFeatureIdSet(4, 19),
  /**
   * Splashtop Access-Performance + Support-10
   */
  Splashtop_Access_Performance_Support_10: matchesFeatureIdSet(4, 20),
  /**
   * Splashtop Access-Performance + Support-300
   */
  Splashtop_Access_Performance_Support_300: matchesFeatureIdSet(4, 21),
  /**
   * Splashtop Support-10
   */
  Splashtop_Support_10: matchesFeatureIdSet(4, 22),
  /**
   * Splashtop Support-300
   */
  Splashtop_Support_300: matchesFeatureIdSet(4, 23),
  /**
   * STE v1 (legacy prices/features)
   */
  STE_custom_v1: matchesFeatureIdSet(4, 24),
  /**
   * STE v2
   */
  STE_custom_v2: matchesFeatureIdSet(4, 25),
  /**
   * SBA subscription w/o computer limit.
   */
  SBA_without_computer_limit: matchesFeatureIdSet(4, 0),
  /**
   * SBA subscription w/ computer limit.
   */
  SBA_with_computer_limit: matchesFeatureIdSet(4, 1),
  /**
   * SBA Solo
   */
  SBA_Solo: matchesFeatureIdSet(4, 6),
  /**
   * SBA Pro
   */
  SBA_Pro: matchesFeatureIdSet(4, 7),
  /**
   * SBA Performance
   */
  SBA_Performance: matchesFeatureIdSet(4, 12),
  /**
   * MSP
   */
  MSP: matchesFeatureIdSet(4, 2),
  /**
   * SRS-Basic subscription
   */
  SRS_Basic: matchesFeatureIdSet(4, 3),
  /**
   * SRS-Plus subscription
   */
  SRS_Plus: matchesFeatureIdSet(4, 4),
  /**
   * SOS-granted SRS subscription (w/ unattended computers)
   */
  SOS_granted_SRS: matchesFeatureIdSet(4, 5),
  /**
   * SRS-Premium subscription
   */
  SRS_Premium: matchesFeatureIdSet(4, 8),
  /**
   * SOS-Enterprise-granted SRS subscription
   */
  SOS_Enterprise_granted_SRS: matchesFeatureIdSet(4, 9),
  /**
   * STE v0 (legacy)
   */
  SRS_Enterprise: matchesFeatureIdSet(4, 10),
  /**
   * SOS teams granted SRS
   */
  SOS_Teams_granted_SRS: matchesFeatureIdSet(4, 11),
  /**
   * SOS Basic (STE-lite based)
   */
  New_SOS_Basic: matchesFeatureIdSet(4, 13),
  /**
   * SOS Plus (STE-lite based)
   */
  New_SOS_Plus: matchesFeatureIdSet(4, 14),
  /**
   * SOS subscription ($99)
   */
  SOS: matchesFeatureIdSet(11, 1),
  /**
   * SOS w/ Mobile subscription ($199)
   */
  SOS_with_Mobile: matchesFeatureIdSet(11, 2),
  /**
   * SOS w/ unattended computers (SOS+10 $219, SOS+500 $399)
   */
  SOS_with_unattended_computers: matchesFeatureIdSet(11, 3),
  /**
   * SOS Enterprise
   */
  SOS_Enterprise: matchesFeatureIdSet(11, 4),
  /**
   * SOS Basic (EU only)
   */
  SOS_Basic: matchesFeatureIdSet(11, 5),
  /**
   * SOS Teams (EU only)
   */
  SOS_Teams: matchesFeatureIdSet(11, 6),
  /**
   * Classroom 1:3 subscription
   */
  STA_Classroom_1_3: matchesFeatureIdSet(5, 3),
  /**
   * Classroom 1:40 subscription
   */
  STA_Classroom_1_40: matchesFeatureIdSet(5, 255),
} as const;

export type FeatureIdSet = Pick<TeamInfoFull, 'feature_id' | 'feature_capacity'>;
export type CheckFeatureIdSetFn = (value: FeatureIdSet) => boolean;
function matchesFeatureIdSet(featureId: number, featureCapacity: number): CheckFeatureIdSetFn {
  return matches({
    feature_id: featureId,
    feature_capacity: featureCapacity,
  });
}
