import { Box, HStack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';

import { RippleBodyText02, RippleInput } from '@/design';
import { ActionBar } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/EditMode/ActionBar';
import { FieldTitle } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/EditMode/FieldTitle';
import { Highlighter } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/EditMode/Highlighter';
import { SUBMIT_BUTTON_MAX_LENGTH } from '@/modules/ServiceDesk/SupportForm/Editor/constants';
import { useCurrentOpenFieldAtom } from '@/modules/ServiceDesk/SupportForm/Editor/hooks/useEditingForm';
import { SupportFormValue } from '@/modules/ServiceDesk/SupportForm/Editor/types';

type ActionButtonsEditModeProps = {
  onSave: () => void;
};

export function ActionButtonsEditMode({ onSave }: ActionButtonsEditModeProps) {
  const { register } = useFormContext<SupportFormValue>();
  const { t } = useTranslation();
  const currentOpenField = useCurrentOpenFieldAtom();

  return (
    <Box w="100%" p="0px 36px 12px" position="relative">
      <Highlighter />
      <FieldTitle title={t('support-forms:actionButton')} />
      <HStack alignItems="center" gap="12px" mt="4px" mb="12px">
        <RippleBodyText02>{t('support-forms:label')}:</RippleBodyText02>
        <RippleInput
          autoFocus={currentOpenField?.autoFocusType === 'label'}
          id="editing_action_button_label"
          aria-describedby="editing_action_button_label"
          placeholder={t('support-forms:actionButtonPlaceholder')}
          maxLength={SUBMIT_BUTTON_MAX_LENGTH}
          size="lg"
          type="text"
          {...register('action_button_label')}
        />
      </HStack>
      <ActionBar isShowRequired={false} isShowDeleteButton={false} onSave={onSave} />
    </Box>
  );
}
