import { AccessPermissionModal, useAccessPermissionModal } from './AccessPermissionModal';
import { WarningModal } from './WarningModal';

export function useAccessPermission() {
  const accessPermissionModal = useAccessPermissionModal();

  return {
    execute: accessPermissionModal.open,
  };
}

export function AccessPermission(): React.JSX.Element {
  return (
    <>
      <AccessPermissionModal />
      <WarningModal />
    </>
  );
}
