import axios from 'axios';

import { checkResponse } from '@/services/utils';

import { GranularControlKey } from '../granular_controls/types';
import { grantGranularControlMapSchema } from './types';

const method = 'GET';

const getUrl = (teamId: number, features: Array<GranularControlKey>) => {
  const params = new URLSearchParams({ controls: features.join(',') });
  return `/api/web/v1/teams/${teamId}/grant_granular_controls?${params.toString()}`;
};

const schema = grantGranularControlMapSchema;

const execute = (teamId: number, features: Array<GranularControlKey>) =>
  checkResponse(axios.request({ method, url: getUrl(teamId, features) }), schema);

/**
 * Get grant granular control settings for a team
 *
 * @see https://splashtop.atlassian.net/wiki/spaces/CS/pages/1186234639/Team+Grant+Granular+Controls+API
 */
export const getTeamGrantGranularControlService = {
  getUrl,
  method,
  execute,
} as const;
