import Link from 'next/link';

import { Box, BoxProps, Flex, List, ListItem } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import Card from '@/components/BasicCard';
import * as Logo from '@/components/Logo';
import { RippleAndroidNoBorder, RippleDivider, RippleMacNoBorder, RippleOldWindowsNoBorder, RippleTypography } from '@/design';
import { NumberTag } from '@/modules/Welcome/NumberTag';
import { ANDROID_SOS_APP_URL, IOS_SOS_APP_URL } from '@/utils/constants';

const DownloadItemBox = (props: BoxProps) => <Flex alignItems="center" flexWrap="nowrap" {...props} />;

export type DownloadCardProps = {
  showLogo?: boolean;
  onWindowsDownload: () => void;
  onMacDownload: () => void;
};

const DownloadCard = ({ showLogo = true, onWindowsDownload, onMacDownload }: DownloadCardProps) => {
  const { t } = useTranslation();

  return (
    <Card maxWidth="640px" mb="64px">
      {showLogo && (
        <>
          <Logo.Splashtop />
          <Box mt="1.5em" mb="1em">
            <Logo.SupportApp />
          </Box>
        </>
      )}
      <RippleTypography variant="heading04" color="dark.90" py="16px">
        {t('sos-package:download.titleForServiceDesk')}
      </RippleTypography>
      <Box>
        <List as="ol" styleType="none" pt="48px" spacing="24px">
          <ListItem fontSize="14px" display="flex">
            <NumberTag>1</NumberTag>
            <Flex flexDirection="column" pl="16px" pt="6px">
              <RippleTypography variant="body02" color="dark.90">
                {t('sos-package:download.step1.line2')}
              </RippleTypography>
              <Box display="grid" gridTemplateColumns={['auto', 'auto 1fr']} gap={['8px', '12px 32px']} pt="14px">
                <DownloadItemBox>
                  <Box pr="8px">
                    <RippleOldWindowsNoBorder color="blue.100" />
                  </Box>
                  <RippleTypography
                    data-testid="service-desk__download__windows"
                    variant="body02"
                    color="blue.100"
                    cursor="pointer"
                    onClick={onWindowsDownload}
                  >
                    {t('sos-package:download.step1.windowsDownload')}
                  </RippleTypography>
                </DownloadItemBox>
                <DownloadItemBox>
                  <Box pr="8px">
                    <RippleMacNoBorder color="blue.100" />
                  </Box>
                  <RippleTypography
                    data-testid="service-desk__download__mac"
                    variant="body02"
                    color="blue.100"
                    cursor="pointer"
                    onClick={onMacDownload}
                  >
                    {t('sos-package:download.step1.macDownload')}
                  </RippleTypography>
                </DownloadItemBox>
                <DownloadItemBox>
                  <Box pr="8px">
                    <RippleAndroidNoBorder color="blue.100" />
                  </Box>
                  <Link href={ANDROID_SOS_APP_URL} target="_blank">
                    <RippleTypography data-testid="service-desk__download__android" variant="body02" color="blue.100" cursor="pointer">
                      {t('sos-package:download.step1.androidDownload')}
                    </RippleTypography>
                  </Link>
                </DownloadItemBox>
                <DownloadItemBox>
                  <Box pr="8px">
                    <RippleMacNoBorder color="blue.100" />
                  </Box>
                  <Link href={IOS_SOS_APP_URL} target="_blank">
                    <RippleTypography data-testid="service-desk__download__ios" variant="body02" color="blue.100" cursor="pointer">
                      {t('sos-package:download.step1.iosDownload')}
                    </RippleTypography>
                  </Link>
                </DownloadItemBox>
              </Box>
            </Flex>
          </ListItem>
          <ListItem fontSize="14px" display="flex">
            <NumberTag>2</NumberTag>
            <Flex flexDirection="column" pl="16px" pt="6px">
              <RippleTypography variant="body02" color="dark.90">
                {t('sos-package:download.step2.line1')}
              </RippleTypography>
              <RippleTypography variant="body02" color="neutral.100">
                {t('sos-package:download.step2.line2')}
              </RippleTypography>
            </Flex>
          </ListItem>
          <ListItem fontSize="14px" display="flex">
            <NumberTag>3</NumberTag>
            <Flex flexDirection="column" pl="16px" pt="6px">
              <RippleTypography variant="body02" color="dark.90">
                {t('sos-package:download.step3.line1s')}
              </RippleTypography>
              <RippleTypography variant="body02" color="neutral.100">
                {t('sos-package:download.step3.line2s')}
              </RippleTypography>
            </Flex>
          </ListItem>
        </List>
      </Box>

      <Box>
        <RippleDivider my="2em" />
        <RippleTypography variant="body02" color="dark.90">
          {t('sos-package:download.note.line1')}
        </RippleTypography>
        <br />
        <RippleTypography variant="body02" color="dark.90">
          {t('sos-package:download.note.line2')}
        </RippleTypography>
      </Box>
    </Card>
  );
};

export default DownloadCard;
