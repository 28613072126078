import axios from 'axios';

import { ResponseData } from '@/services/common/types';
import { checkResponse } from '@/services/utils';

import { deleteSurveyResponseSchema } from './types';

type DeleteSurveyStatusParams = {
  teamId: number;
  surveyId: number;
};

export const deleteSurvey = async ({ teamId, surveyId }: DeleteSurveyStatusParams) => {
  return await checkResponse(
    axios.delete<ResponseData>(`/api/web/v1/teams/${teamId}/service_desk/surveys/${surveyId}`),
    deleteSurveyResponseSchema,
  );
};
