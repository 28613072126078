import { TFunction } from 'next-i18next';

export const inventoryColumnOrder = [
  'computerName',
  'groupName',
  'reportTime',
  'os',
  'osBuild',
  'architecture',
  'deviceName',
  'domain',
  'manufacturer',
  'modelProduct',
  'serialNumber',
  'biosVersion',
  'cpu',
  'cpuSpeed',
  'chipset',
  'ram',
  'totalFreeSpace',
  'totalStorage',
  'displayAdapter',
  'ipAddress',
  'timeZone',
  'lastBoot',
  'lastLoggedOn',
] as const;

export const inventoryPickColumnOrder = [
  'computerName',
  'groupName',
  'reportTime',
  'os',
  'osBuild',
  'architecture',
  'deviceName',
  'manufacturer',
  'modelProduct',
  'cpu',
  'ram',
  'totalFreeSpace',
  'totalStorage',
  'ipAddress',
  'timeZone',
  'lastBoot',
  'lastLoggedOn',
] as const;

export const inventoryColumnWidths = {
  computerName: 200,
  groupName: 120,
  reportTime: 216,
  os: 200,
  osBuild: 200,
  architecture: 200,
  deviceName: 160,
  domain: 100,
  manufacturer: 200,
  modelProduct: 200,
  serialNumber: 200,
  biosVersion: 200,
  cpu: 200,
  cpuSpeed: 200,
  chipset: 120,
  ram: 120,
  totalFreeSpace: 200,
  totalStorage: 200,
  displayAdapter: 200,
  ipAddress: 200,
  timeZone: 200,
  lastBoot: 216,
  lastLoggedOn: 200,
} as const;

export const getInventoryColumnHeaders = (t: TFunction) => {
  return {
    computerName: t('computer:computerName'),
    groupName: t('computer:group'),
    reportTime: t('inventory:reportTime'),
    os: t('computer:os_version'),
    osBuild: t('OS Build'),
    architecture: t('Architecture'),
    deviceName: t('inventory:deviceName'),
    domain: t('inventory:domain'),
    manufacturer: t('inventory:manufacturer'),
    modelProduct: t('inventory:modelProduct'),
    serialNumber: t('inventory:serialNumber'),
    biosVersion: t('inventory:biosVersion'),
    cpu: t('inventory:cpu'),
    cpuSpeed: `${t('inventory:cpuSpeed')} (GHz)`,
    chipset: t('inventory:chipset'),
    ram: `${t('inventory:ram')} (GB)`,
    totalFreeSpace: `${t('inventory:totalFreeSpace')} (GB)`,
    totalStorage: `${t('inventory:totalStorage')} (GB)`,
    displayAdapter: t('inventory:displayAdapter'),
    ipAddress: t('inventory:ipAddress'),
    timeZone: t('inventory:timeZone'),
    lastBoot: t('inventory:lastBoot'),
    lastLoggedOn: t('inventory:lastLoggedOn'),
  };
};

export const getInventoryColumnWidthsSum = (visibilities?: Record<string, boolean>) => {
  return visibilities
    ? inventoryColumnOrder
        .map((columnName) => (visibilities[columnName] ? inventoryColumnWidths[columnName] : 0))
        .reduce((sum, width) => sum + width, 16)
    : Object.values(inventoryColumnWidths).reduce((sum, width) => sum + width, 16);
};
