export const opportunityStatusList = ['pending', 'approved', 'failed', 'installed', 'rejected'] as const;

export const opportunityStatusMap = {
  PENDING: 0,
  APPROVED: 1,
  REJECTED: 2,
  FAILED: 3,
  INSTALLED: 4,
} as const;

export const opportunityStatusNumberMap = {
  0: 'pending',
  1: 'approved',
  2: 'rejected',
  3: 'failed',
  4: 'installed',
} as const;

export const ALL_GROUP_ID = 'all';
export const DEFAULT_GROUP_ID = '0';
export const defaultSelectedLastDays = 90;
