import parseISO from 'date-fns/parseISO';

export const isExpired = (expirationDate: string | null | undefined): boolean => {
  if (!expirationDate) return false;

  const expirationTimestamp = parseISO(expirationDate + 'Z').getTime();
  return expirationTimestamp <= Date.now();
};

export const daysUntilExpiration = (expirationDate: string | null): number => {
  if (!expirationDate) return 0;

  const expirationTimestamp = parseISO(expirationDate + 'Z').getTime();
  const millisecondsPerDay = 86400000;

  return Math.ceil((expirationTimestamp - Date.now()) / millisecondsPerDay);
};
