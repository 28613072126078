import { useState } from 'react';

import { Box, Container, Flex } from '@chakra-ui/react';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'next-i18next';

import { RippleBodyText02, RippleHeading05 } from '@/design';
import { DownloadBusinessAppBtn } from '@/modules/Unboxing/shared/DownloadBusinessAppBtn';
import { DownloadModal } from '@/modules/Unboxing/shared/DownloadModal';

const ConnectFrom = () => {
  const { t } = useTranslation(['setup']);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  return (
    <>
      <Flex
        direction="column"
        align="center"
        justify="space-between"
        w="320px"
        border="1px solid"
        borderColor="neutral.60"
        borderRadius="base"
        p="24px"
      >
        <Container centerContent>
          <RippleHeading05 mb="16px">{capitalize(t('setup:connectFrom'))}</RippleHeading05>
          <RippleBodyText02 align="center" color="dark.100">
            {t('setup:installBusinessApp', { businessApp: t('common:business_app') })}
          </RippleBodyText02>
        </Container>
        <Box pt="16px">
          <DownloadBusinessAppBtn
            onClick={() => {
              setOpenDownloadModal(true);
            }}
          />
        </Box>
      </Flex>
      <DownloadModal
        isOpen={openDownloadModal}
        closeModal={() => {
          setOpenDownloadModal(false);
        }}
      />
    </>
  );
};

export default ConnectFrom;
