import { useQuery } from '@tanstack/react-query';

import { TeamKind } from '@/services/common/types';
import { infoQueryService } from '@/services/users';

/** Check the accessibility for the endpoint security and antivirus pages, those pages are the same module */
export type EndpointSecurityOrAntivirusAccess =
  | {
      teamKind: TeamKind;
      endpointSecurity: boolean;
      antivirus: boolean;
      bitdefender: boolean;
      bitdefender2: boolean;
      canAccess: true;
    }
  | {
      teamKind: '';
      endpointSecurity: false;
      antivirus: false;
      bitdefender: false;
      bitdefender2: false;
      canAccess: false;
    };
/** Check the accessibility for endpoint security and antivirus */
export const useEndpointSecurityOrAntivirusAccessQuery = () => {
  return useQuery({
    queryKey: ['info-query', 'endpoint-seucrity-or-antivirus'],
    queryFn: () =>
      infoQueryService.execute({
        team_member_permissions: ['antivirus', 'endpoint_security', 'bitdefender', 'bitdefender2'],
      }),
    select: (data): EndpointSecurityOrAntivirusAccess => {
      return Object.entries(data).reduce(
        (result, [teamKind, { team_member_permissions }]) => {
          if (!team_member_permissions.endpoint_security && !team_member_permissions.antivirus) {
            return result;
          }
          return {
            teamKind: teamKind as TeamKind,
            endpointSecurity: team_member_permissions.endpoint_security,
            antivirus: team_member_permissions.antivirus,
            bitdefender: team_member_permissions.bitdefender,
            bitdefender2: team_member_permissions.bitdefender2,
            canAccess: true,
          };
        },
        {
          teamKind: '',
          endpointSecurity: false,
          antivirus: false,
          bitdefender: false,
          bitdefender2: false,
          canAccess: false,
        } as EndpointSecurityOrAntivirusAccess,
      );
    },
  });
};
