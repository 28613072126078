import { useState } from 'react';

import { useTranslation } from 'next-i18next';

import {
  RippleButton,
  RippleDivider,
  RippleModal,
  RippleModalBody,
  RippleModalCloseButton,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalProps,
  RippleModalTitle,
} from '@/design';

import { Snippet } from './Snippet';

type SnippetModalProps = {
  isOpen: boolean;
  title: string;
  downloadUrl: string;
  onClose: () => void;
  onPreview: () => void;
  blockScrollOnMount: RippleModalProps['blockScrollOnMount'];
};

export const SnippetModal = ({
  isOpen = true,
  blockScrollOnMount,
  title = 'title',
  downloadUrl,
  onClose,
  onPreview,
}: SnippetModalProps) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(true);
  const handleCheckValid = (isValid: boolean) => {
    setIsValid(isValid);
  };
  const handlePreview = () => {
    if (isValid) {
      onPreview();
    }
  };

  return (
    <RippleModal isOpen={isOpen} onClose={onClose} blockScrollOnMount={blockScrollOnMount}>
      <RippleModalOverlay />
      <RippleModalContent
        maxW="448px"
        width="100%"
        borderRadius="4px"
        backgroundColor="white"
        boxShadow="0px 8px 16px 4px rgba(0, 0, 0, 0.12)"
      >
        <RippleModalHeader>
          <RippleModalTitle>{title}</RippleModalTitle>
        </RippleModalHeader>
        <RippleModalCloseButton top="22px" right="22px" />
        <RippleModalBody p="0 24px 24px 24px">
          <Snippet downloadUrl={downloadUrl} onCheckValid={handleCheckValid} />
        </RippleModalBody>
        <RippleDivider />
        <RippleModalFooter>
          <RippleButton variant="secondary" border="solid 1px" onClick={handlePreview} isDisabled={!isValid}>
            {t('common:preview')}
          </RippleButton>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
};
