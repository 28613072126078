import { parse, pick } from 'accept-language-parser';
import { z } from 'zod';

export const SupportedLanguageSchema = z.enum(['en', 'de', 'es', 'fr', 'it', 'ja', 'ko', 'zh-CN', 'zh-TW', 'pt', 'he']);

export type SupportedLanguage = z.infer<typeof SupportedLanguageSchema>;

export const SUPPORT_LANGS: Array<SupportedLanguage> = ['en', 'de', 'es', 'fr', 'it', 'ja', 'ko', 'zh-CN', 'zh-TW', 'pt', 'he'];

const DEFAULT_LANG = 'en';

const getLocale = (acceptLanguageHeader?: string) => {
  if (acceptLanguageHeader) {
    if (acceptLanguageHeader.toLocaleLowerCase().startsWith('zh-hant-')) {
      return 'zh-TW';
    } else if (acceptLanguageHeader.toLocaleUpperCase().startsWith('zh-hans-')) {
      return 'zh-CN';
    }

    const languages = parse(acceptLanguageHeader);

    if (languages.length) {
      for (let i = 0; i < languages.length; i += 1) {
        const lang = languages[i].code;
        if (lang === 'zh') {
          return pick(['zh-TW', 'zh-CN'], acceptLanguageHeader) ?? DEFAULT_LANG;
        }
        if (SUPPORT_LANGS.includes(lang as SupportedLanguage)) return lang;
      }
    }
  }
  return DEFAULT_LANG;
};

export default getLocale;
