import { z } from 'zod';

import { granularControlKeySchema, granularControlRoleEnum, granularControlSettingValueSchema } from '../granular_controls/types';

const grantGranularControlSettingSchema = createGrantGranularControlSettingSchema(granularControlSettingValueSchema);
export type GrantGranularControlSetting = z.infer<typeof grantGranularControlSettingSchema>;

export const grantGranularControlMapSchema = z
  .object({
    [granularControlKeySchema.enum.attended_access]: grantGranularControlSettingSchema,
    [granularControlKeySchema.enum.command_prompt]: grantGranularControlSettingSchema,
    [granularControlKeySchema.enum.copy_paste]: grantGranularControlSettingSchema,
    [granularControlKeySchema.enum.file]: grantGranularControlSettingSchema,
    [granularControlKeySchema.enum.one_to_many]: grantGranularControlSettingSchema,
    [granularControlKeySchema.enum.remote_control]: grantGranularControlSettingSchema,
    [granularControlKeySchema.enum.remote_print]: grantGranularControlSettingSchema,
    [granularControlKeySchema.enum.required_2sv]: grantGranularControlSettingSchema,
    [granularControlKeySchema.enum.watermark]: grantGranularControlSettingSchema,
    [granularControlKeySchema.enum.conn_perm]: grantGranularControlSettingSchema,
    [granularControlKeySchema.enum.relay_recording]: grantGranularControlSettingSchema,
    [granularControlKeySchema.enum.sos_relay_recording]: grantGranularControlSettingSchema,
  })
  .partial();
export type GrantGranularControlMap = z.infer<typeof grantGranularControlMapSchema>;

function createGrantGranularControlSettingSchema<Schema extends z.ZodTypeAny>(settingValueSchema: Schema) {
  // Currently only `manager` (admin) and `group_admin` (group admin) role is supported

  return z.object({
    [granularControlRoleEnum.manager]: settingValueSchema,
    [granularControlRoleEnum.group_admin]: settingValueSchema.optional(),
  });
}
