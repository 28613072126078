import axios from 'axios';
import { z } from 'zod';

import { checkResponse } from '@/services/utils';

const getUrl = (teamId: number) => `/api/web/v1/teams/${teamId}/timezone_list`;

const schema = z.object({
  timezone_list: z.array(
    z.object({
      /** Timezone location name e.g. `Fiji` */
      value: z.string(),
      /** Timezone offset in minutes e.g. `720` */
      time_zone_minutes: z.number(),
    }),
  ),
});

const service = (teamId: number) => checkResponse(axios.get(getUrl(teamId)), schema);

export const getTimezoneList = {
  getUrl,
  service,
};
