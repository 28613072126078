import { useRouter } from 'next/router';
import { ChangeEventHandler } from 'react';

import { HStack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { Controller, useFormContext } from 'react-hook-form';

import {
  RippleBodyText02,
  RippleButton,
  RippleHeading06,
  RippleSection,
  RippleSheetAction,
  RippleSingleSelect,
  RippleSingleSelectOption,
  RippleSingleSelectOptionDivider,
  RippleSingleSelectOptionGroup,
  RippleTextField,
} from '@/design';
import { FORM_NAME_MAX_LENGTH } from '@/modules/ServiceDesk/SupportForm/Editor/constants';
import { SUPPORT_FORM_CONTENT_WIDTH } from '@/modules/ServiceDesk/SupportForm/Editor/constants';
import { useIsDuplicateSupportFormName } from '@/modules/ServiceDesk/SupportForm/Editor/hooks/useIsDuplicateSupportFormName';
import { useSupportSessionChannelTechnicians } from '@/modules/ServiceDesk/SupportForm/Editor/hooks/useSupportSessionChannelTechnicians';
import { SupportFormMode, SupportFormValue } from '@/modules/ServiceDesk/SupportForm/Editor/types';
import preSanitize from '@/utils/pre-sanitize';

type GeneralProps = {
  mode: SupportFormMode;
  onClickNextStep: () => void;
  onClickPreviousStep: () => void;
  supportFormId: number;
};

export function General({ mode, onClickNextStep, onClickPreviousStep, supportFormId }: GeneralProps) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    watch,
  } = useFormContext<SupportFormValue>();

  const supportFormName = watch('name');

  const isDuplicateSupportFormName = useIsDuplicateSupportFormName({
    mode,
    supportFormName: preSanitize(supportFormName),
    currentSupportFormId: supportFormId,
  });

  const handleSupportFormNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue('name', e.target.value);
    // Clear errors set when submitting the form
    if (errors.name) {
      clearErrors('name');
    }
  };

  const handleNextStep = () => {
    const formNameSanitized = preSanitize(supportFormName);
    setValue('name', formNameSanitized);

    if (formNameSanitized.trim() === '' || isDuplicateSupportFormName) {
      setError('name', {
        type: 'manual',
        message: t('support-forms:formNameError'),
      });
    } else {
      onClickNextStep();
    }
  };

  return (
    <>
      <HStack w={SUPPORT_FORM_CONTENT_WIDTH} mt="48px" gap="24px" flexDirection="column" alignItems="flex-start">
        <RippleHeading06>{t('support-forms:general')}</RippleHeading06>
        <RippleSection name={t('support-forms:formName')} w="100%">
          <RippleTextField
            size="sm"
            placeholder={t('support-forms:formName')}
            maxLength={FORM_NAME_MAX_LENGTH}
            {...register('name', { required: true })}
            isInvalid={Boolean(errors.name)}
            helperText={errors.name ? errors.name.message : undefined}
            onChange={handleSupportFormNameChange}
          />
        </RippleSection>
        <TechnicianSelect />
      </HStack>
      <RippleSheetAction>
        <RippleButton variant="grayScaleGhost" onClick={onClickPreviousStep}>
          {t('common:cancel')}
        </RippleButton>
        <RippleButton onClick={handleNextStep}>{t('common:next')}</RippleButton>
      </RippleSheetAction>
    </>
  );
}

function TechnicianSelect() {
  const router = useRouter();
  const { t } = useTranslation();
  const { control, getValues } = useFormContext<SupportFormValue>();

  const technicianId = getValues('technician_id');

  const {
    isShowUnassignedOption = true,
    currentTechnicianOption,
    technicianOptions,
  } = useSupportSessionChannelTechnicians({
    channelId: (typeof router.query.id === 'string' && parseInt(router.query.id, 10)) || -1,
    assignedTechnicianId: parseInt(technicianId, 10),
  });

  return (
    <RippleSection name={t('support-session:technician')} w="100%">
      <Controller
        name="technician_id"
        control={control}
        rules={{ required: true }}
        render={({ field }) => {
          return (
            <RippleSingleSelect placeholder="" {...field}>
              {isShowUnassignedOption && <RippleSingleSelectOption value="0">{t('support-session:unassigned')}</RippleSingleSelectOption>}
              {currentTechnicianOption && (
                <RippleSingleSelectOption value={currentTechnicianOption.value}>
                  <RippleBodyText02 as="span" color="neutral.100" pr="4px">
                    ({t('support-session:you')})
                  </RippleBodyText02>
                  {currentTechnicianOption.label}
                </RippleSingleSelectOption>
              )}
              {technicianOptions.length > 0 && (
                <>
                  <RippleSingleSelectOptionDivider />
                  <RippleSingleSelectOptionGroup title={t('support-session:technician')}>
                    {technicianOptions.map((technicianOption) => (
                      <RippleSingleSelectOption key={technicianOption.value} value={technicianOption.value}>
                        {technicianOption.label}
                      </RippleSingleSelectOption>
                    ))}
                  </RippleSingleSelectOptionGroup>
                </>
              )}
            </RippleSingleSelect>
          );
        }}
      />
    </RippleSection>
  );
}
