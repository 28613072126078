import { Box, VStack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleAlert, RippleBodyText02, RippleHeading06 } from '@/design';

export const GetSurveyErrorState = () => {
  const { t } = useTranslation();
  return (
    <Box w="688px" px="56px" pt="44px" pb="56px" borderRadius="12px" border="1px solid" borderColor="neutral.300">
      <VStack spacing="8px">
        <RippleAlert color="red.100" />
        <RippleHeading06 color="dark.80">{t('survey:survey_unavailable')}</RippleHeading06>
        <RippleBodyText02 color="neutral.300" textAlign="center">
          {t('survey:survey_unavailable_contact_supporter')}
        </RippleBodyText02>
      </VStack>
    </Box>
  );
};

export const SubmitSurveyErrorState = () => {
  const { t } = useTranslation();
  return (
    <Box w="688px" px="56px" pt="48px" pb="56px" borderRadius="12px" border="1px solid" borderColor="neutral.300">
      <VStack spacing="8px">
        <RippleAlert color="red.100" />
        <RippleHeading06 color="dark.80">{t('survey:submission_unsuccessful')}</RippleHeading06>
        <RippleBodyText02 color="neutral.300" textAlign="center">
          {t('survey:submission_unsuccessful_message')}
        </RippleBodyText02>
      </VStack>
    </Box>
  );
};
