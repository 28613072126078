import { useEffect, useMemo } from 'react';

import { Flex, HStack } from '@chakra-ui/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithImmer } from 'jotai-immer';
import { atomWithReset, selectAtom, useResetAtom } from 'jotai/utils';
import { Trans, useTranslation } from 'next-i18next';
import { Virtuoso } from 'react-virtuoso';

import {
  RippleBadge,
  RippleBodyText02,
  RippleButton,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalTitle,
} from '@/design';
import { assignComputerToDefaultGroup, changeGroupComputers } from '@/services/group';
import { ModalGridTable, ModalGridTableContainer, ModalGridTbody, ModalGridTd, ModalGridTh, ModalGridThead, ModalGridTr } from '@/showcase';
import useTeamInformation from '@/utils/useTeamInformation';

import { computerDataMapAtom, rowSelectionStateAtom } from '../atoms';
import { DEFAULT_GROUP_ID } from '../constants';

type ComputerItem = { id: string; name: string };
export const resultModalAtom = atomWithReset<{
  isOpen: boolean;
  targetGroup: { id: number; name: string } | null;
  targetComputerList: Array<ComputerItem>;
}>({
  isOpen: false,
  targetGroup: null,
  targetComputerList: [],
});

export function useResultModal() {
  const set = useSetAtom(resultModalAtom);

  return {
    open({
      targetGroup,
      targetComputerList,
    }: {
      targetGroup: { id: number; name: string };
      targetComputerList: Array<{ id: string; name: string }>;
    }) {
      set({ isOpen: true, targetGroup, targetComputerList });
    },
  };
}

type Status = 'pending' | 'success' | 'failed';
type StatusMap = Record<string, Status>;
const statusMapAtom = atomWithImmer<StatusMap>({});

export function ResultModal(): React.JSX.Element {
  const { t } = useTranslation();

  const teamInformation = useTeamInformation();
  const teamId = teamInformation?.teamId ?? 0;

  const { isOpen, targetGroup, targetComputerList } = useAtomValue(resultModalAtom);
  const resetModal = useResetAtom(resultModalAtom);
  const setStatusMapAtom = useSetAtom(statusMapAtom);
  const setComputerDataMap = useSetAtom(computerDataMapAtom);
  const setRowSelectionState = useSetAtom(rowSelectionStateAtom);

  useEffect(
    function resetStatusMap() {
      setStatusMapAtom(
        targetComputerList.reduce<StatusMap>((acc, cur) => {
          acc[cur.id] = 'pending';
          return acc;
        }, {}),
      );
    },
    [setStatusMapAtom, targetComputerList],
  );

  useEffect(
    function executeAssignComputerGroup() {
      if (targetGroup !== null) {
        const targetComputerIdList = targetComputerList.map(({ id }) => Number(id));
        const isDefaultGroup = targetGroup.id === Number(DEFAULT_GROUP_ID);
        const request = isDefaultGroup
          ? assignComputerToDefaultGroup(teamId, targetComputerIdList)
          : changeGroupComputers(teamId, targetGroup.id, targetComputerIdList, []);

        request
          .then(({ success, fail }) => {
            setStatusMapAtom((draft) => {
              success.forEach((computerId) => {
                draft[computerId] = 'success';
              });

              fail.forEach((computerId) => {
                draft[computerId] = 'failed';
              });
            });

            setComputerDataMap((draft) => {
              success.forEach((computerId) => {
                console.log(draft[computerId].group_id);
                draft[computerId].group_id = targetGroup.id;
              });
            });
          })
          .then(() => {
            // TODO: Handle single computer actions
            // TODO: Failed handling ?
            setRowSelectionState((draft) => {
              targetComputerList.forEach(({ id: computerId }) => {
                delete draft[computerId];
              });
            });
          });
      }
    },
    [setComputerDataMap, setRowSelectionState, setStatusMapAtom, targetComputerList, targetGroup, teamId],
  );

  return (
    <RippleModal isOpen={isOpen} onClose={resetModal} size="2xl" closeOnEsc={false} closeOnOverlayClick={false}>
      <RippleModalOverlay />
      <RippleModalContent>
        <RippleModalHeader>
          <RippleModalTitle>{t('computer:assignGroup.resultModal.title')}</RippleModalTitle>
        </RippleModalHeader>
        <RippleModalBody display="flex" flexDirection="column">
          <StatusSummary />
          <RippleBodyText02 mt="8px">{t('computer:assignGroup.resultModal.hint')}</RippleBodyText02>

          <ModalGridTableContainer flex="1" mt="8px">
            <ModalGridTable display="block">
              <ModalGridThead>
                <Flex bg="white">
                  <ModalGridTr>
                    <ModalGridTh w="320px">{t('common:name')}</ModalGridTh>
                    <ModalGridTh w="224px">{t('computer:status')}</ModalGridTh>
                  </ModalGridTr>
                </Flex>
              </ModalGridThead>
              <ModalGridTbody>
                <Virtuoso
                  style={{ height: 300 }}
                  data={targetComputerList}
                  itemContent={(_index, computer) => <StatusItem computer={computer} />}
                />
              </ModalGridTbody>
            </ModalGridTable>
          </ModalGridTableContainer>
        </RippleModalBody>

        <RippleModalFooter>
          <CloseButton />
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
}

type StatusItemProps = { computer: ComputerItem };
function StatusItem({ computer }: StatusItemProps): React.JSX.Element {
  const { t } = useTranslation();
  const statusAtom = useMemo(() => selectAtom(statusMapAtom, (statusMap) => statusMap[computer.id]), [computer.id]);
  const status = useAtomValue(statusAtom);
  const statusBadge = computeStatusBadge();

  return (
    <Flex>
      <ModalGridTr>
        <ModalGridTd w="320px" wordBreak="break-all">
          {computer.name}
        </ModalGridTd>
        <ModalGridTd w="224px">{statusBadge}</ModalGridTd>
      </ModalGridTr>
    </Flex>
  );

  function computeStatusBadge() {
    switch (status) {
      case 'success': {
        return (
          <RippleBadge variant="secondary" colorScheme="success">
            {t('common:success')}
          </RippleBadge>
        );
      }
      case 'failed': {
        return (
          <RippleBadge variant="secondary" colorScheme="danger">
            {t('common:failed')}
          </RippleBadge>
        );
      }

      case 'pending':
      default: {
        return (
          <RippleBadge variant="secondary" colorScheme="grey">
            {t('common:pending')}
          </RippleBadge>
        );
      }
    }
  }
}

function StatusSummary(): React.JSX.Element {
  const { t } = useTranslation();
  const statusMap = useAtomValue(statusMapAtom);

  const { targetComputerList } = useAtomValue(resultModalAtom);
  const { success, failed } = computeSummary(statusMap);
  const totalTargetCount = targetComputerList.length;

  return (
    <HStack spacing="4px">
      <RippleBodyText02 color="neutral.200">
        <Trans
          t={t}
          i18nKey="computer:assignGroup.resultModal.summary"
          components={{ FailedText: <RippleBodyText02 as="span" color={failed > 0 ? 'red.100' : 'neutral.200'} /> }}
          values={{ total: totalTargetCount, success, failed }}
        />
      </RippleBodyText02>
    </HStack>
  );
}

type Summary = { success: number; failed: number; total: number };
function computeSummary(statusMap: StatusMap): Summary {
  return Object.values(statusMap).reduce<Summary>(
    (acc: Summary, status) => {
      switch (status) {
        case 'success': {
          acc.success += 1;
          acc.total += 1;
          break;
        }
        case 'failed': {
          acc.failed += 1;
          acc.total += 1;
          break;
        }
        case 'pending':
        default:
      }
      return acc;
    },
    {
      success: 0,
      failed: 0,
      total: 0,
    },
  );
}

function CloseButton(): React.JSX.Element {
  const { t } = useTranslation();
  const resetModal = useResetAtom(resultModalAtom);

  const statusMap = useAtomValue(statusMapAtom);
  const { targetComputerList } = useAtomValue(resultModalAtom);

  const { total } = computeSummary(statusMap);
  const isProcessing = total !== targetComputerList.length;

  return (
    <RippleButton data-testid="ok-button" variant="primary" onClick={resetModal} isLoading={isProcessing} isDisabled={isProcessing}>
      {t('common:ok')}
    </RippleButton>
  );
}
