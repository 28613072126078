import parseISO from 'date-fns/parseISO';
import { useAtomValue, useSetAtom } from 'jotai';

import { basicProfileAtom } from '@/models/BasicProfile';
import { teamInformationMapAtom } from '@/models/TeamInformation';
import { useUserProfile } from '@/models/UserProfile';
import type { Profile, SystemInfo, Team, Teams } from '@/services/common/types';

/** @deprecated Use type from `@/models/TeamInformation` instead */
export type TeamInformation = {
  email: string;
  emailVerified: boolean;
  name: string;
  notificationMessage: string;
  timeZone: string | null;
  timeZoneMinutes: number;
  xauthMethodId: number;
  expiresAt: string | null;
  groupScope: Team['group_scope'];
  isResell: Team['is_resell'];
  contactSales: Team['contact_sales'];
  memberId: Team['member_id'];
  memberStatus: Team['member_status'];
  plan: Team['plan'];
  role: Team['role'];
  seatKind: Team['seat_kind'];
  seatPermissions: {
    commandPrompt: Team['seat_permissions']['command_prompt'];
    fileTransfer: Team['seat_permissions']['file_transfer'];
    offSessionFile: Team['seat_permissions']['off_session_file'];
    offSessionChat: Team['seat_permissions']['off_session_chat'];
    chatTranscript: Team['seat_permissions']['chat_transcript'];
    oneToMany: Team['seat_permissions']['one_to_many'];
    premierPack: Team['seat_permissions']['premier_pack'];
    vulnerabilityScore: Team['seat_permissions']['vulnerability_score'];
    sosServiceDesk: Team['seat_permissions']['sos_service_desk'];
  };
  seatSettings: {
    perSeatCount: Team['seat_settings']['per_seat_count'];
  };
  teamId: Team['team_id'];
  teamPermissions: {
    file: Team['team_permissions']['file'];
    memberList: Team['team_permissions']['member_list'];
    premierPack: Team['team_permissions']['premier_pack'];
    sosServiceDesk: Team['team_permissions']['sos_service_desk'];
  };
  teamMemberPermissions: {
    commandPrompt: Team['team_member_permissions']['command_prompt'];
    fileTransfer: Team['team_member_permissions']['file_transfer'];
    memberList: Team['team_member_permissions']['member_list'];
    offSessionFile: Team['team_member_permissions']['off_session_file'];
    offSessionChat: Team['team_member_permissions']['off_session_chat'];
    technicianManager: Team['team_member_permissions']['technician_manager'];
    superAdmin: Team['team_member_permissions']['super_admin'];
    userManagement: Team['team_member_permissions']['user_management'];
    oneToMany: Team['team_member_permissions']['one_to_many'];
    premierPack: Team['team_member_permissions']['premier_pack'];
    accessSchedule?: Team['team_member_permissions']['access_schedule'];
    attendedAccess?: Team['team_member_permissions']['attended_access'];
  };
  teamName: Team['team_name'];

  currentTeam: keyof Teams;
  isExpired: boolean;
  isInSOSTeam: boolean;
  isNotificationEnabled: boolean;
  myEnv: string;
  browserExtensionEnv: string;
  setUserContext: (profile: Profile, teams: Teams, systemInfo: SystemInfo) => void;
};

export const compareToCurrentDate = (expireAt: string | null | undefined) => {
  if (!expireAt) return false;
  const current = new Date(new Date().toISOString()).getTime();
  if (parseISO(expireAt + 'Z').getTime() > current) return false;
  return true;
};

/**
 * @deprecated Use hooks from `@/models/TeamInformation` instead
 */
function useTeamInformation(specificTeam?: keyof Teams): TeamInformation | null {
  const profile = useUserProfile();
  const teams = useAtomValue(teamInformationMapAtom);

  const setBasicProfile = useSetAtom(basicProfileAtom);
  const setUserContext = (profile: Profile, teams: Teams, systemInfo: SystemInfo) => {
    setBasicProfile({ profile, teams, system_info: systemInfo });
  };

  if (profile && teams) {
    const userTeams = teams as NonNullable<Required<Teams>>;
    const isInSOSTeam = Object.keys(userTeams).includes('sos');
    let currentTeam = specificTeam ? specificTeam : (Object.keys(userTeams).filter((t) => t !== 'sos')[0] as keyof Teams);
    if (!currentTeam && Object.keys(userTeams).length === 1 && userTeams['sos']) {
      currentTeam = 'sos';
    }

    if (!userTeams[currentTeam]) return null;

    const isExpired = compareToCurrentDate(userTeams[currentTeam].expires_at);
    const expiresAt = userTeams[currentTeam].expires_at;
    const groupScope = userTeams[currentTeam].group_scope;
    const isResell = userTeams[currentTeam].is_resell;
    const contactSales = userTeams[currentTeam].contact_sales;
    const memberId = userTeams[currentTeam].member_id;
    const memberStatus = userTeams[currentTeam].member_status;
    const plan = userTeams[currentTeam].plan;
    const role = userTeams[currentTeam].role;
    const seatKind = userTeams[currentTeam].seat_kind;
    const seatPermissions = {
      commandPrompt: userTeams[currentTeam].seat_permissions.command_prompt,
      fileTransfer: userTeams[currentTeam].seat_permissions.file_transfer,
      offSessionFile: userTeams[currentTeam].seat_permissions.off_session_file,
      offSessionChat: userTeams[currentTeam].seat_permissions.off_session_chat,
      chatTranscript: userTeams[currentTeam].seat_permissions.chat_transcript,
      oneToMany: userTeams[currentTeam].seat_permissions.one_to_many,
      premierPack: userTeams[currentTeam].seat_permissions.premier_pack,
      vulnerabilityScore: userTeams[currentTeam].seat_permissions.vulnerability_score,
      sosServiceDesk: userTeams[currentTeam].seat_permissions.sos_service_desk,
    };
    const seatSettings = {
      perSeatCount: userTeams[currentTeam].seat_settings.per_seat_count,
    };
    const teamId = userTeams[currentTeam].team_id;
    const teamMemberPermissions = {
      commandPrompt: userTeams[currentTeam].team_member_permissions.command_prompt,
      fileTransfer: userTeams[currentTeam].team_member_permissions.file_transfer,
      memberList: userTeams[currentTeam].team_member_permissions.member_list,
      offSessionFile: userTeams[currentTeam].team_member_permissions.off_session_file,
      offSessionChat: userTeams[currentTeam].team_member_permissions.off_session_chat,
      oneToMany: userTeams[currentTeam].team_member_permissions.one_to_many,
      premierPack: userTeams[currentTeam].team_member_permissions.premier_pack,
      accessSchedule: userTeams[currentTeam].team_member_permissions?.access_schedule ?? false,
      attendedAccess: userTeams[currentTeam].team_member_permissions?.attended_access ?? false,
      technicianManager: userTeams[currentTeam].team_member_permissions.technician_manager,
      superAdmin: userTeams[currentTeam].team_member_permissions.super_admin,
      userManagement: userTeams[currentTeam].team_member_permissions.user_management,
    };
    const teamPermissions = {
      file: userTeams[currentTeam].team_permissions?.file,
      memberList: userTeams[currentTeam].team_permissions?.member_list,
      premierPack: userTeams[currentTeam].team_permissions?.premier_pack,
      sosServiceDesk: userTeams[currentTeam].team_permissions?.sos_service_desk,
    };
    const teamName = userTeams[currentTeam].team_name;

    const isNotificationEnabled =
      (userTeams[currentTeam].role === 'owner' || userTeams[currentTeam].role === 'manager') &&
      userTeams[currentTeam].member_status === 'enabled' &&
      seatPermissions.premierPack;

    return {
      email: profile.email,
      emailVerified: profile.email_verified,
      name: profile.name,
      notificationMessage: profile.notification_message,
      timeZone: profile.time_zone,
      timeZoneMinutes: profile.time_zone_minutes,
      xauthMethodId: profile.xauth_method_id,
      expiresAt,
      groupScope,
      isResell,
      contactSales,
      memberId,
      memberStatus,
      plan,
      role,
      seatKind,
      seatPermissions,
      seatSettings,
      teamId,
      teamPermissions,
      teamMemberPermissions,
      teamName,

      currentTeam,
      isExpired,
      isInSOSTeam,
      isNotificationEnabled,
      myEnv: profile.my_env,
      browserExtensionEnv: profile.browser_extension_env,
      setUserContext,
    };
  }

  return null;
}

export default useTeamInformation;

/**
 * @deprecated Use hooks from `@/models/TeamInformation` instead
 */
export function useGroupAdmin() {
  const teamInformation = useTeamInformation();
  const isGroupAdmin = teamInformation?.role !== 'member' && teamInformation?.groupScope === 'part';

  return {
    isGroupAdmin,
  };
}
