import { useRouter } from 'next/router';
import Script from 'next/script';
import { useCallback, useEffect, useState } from 'react';

declare global {
  interface Window {
    adroll_adv_id: string;
    adroll_pix_id: string;
    __adroll_loaded: boolean;
  }
}

interface RenderState {
  rendered: boolean;
  src: string;
}

export const AdRollTrackingCode = () => {
  const router = useRouter();
  const [{ rendered, src }, setRenderState] = useState<RenderState>({ rendered: false, src: '' });

  const disable =
    process.env.NEXT_PUBLIC_SERVER_ENV !== 'production' ||
    !(router.pathname.includes('sos/management') && router.query.first_login === 'true');

  const onLoad = useCallback(() => {
    window.adroll_adv_id = '5W7RN2YICJFNZNPOEDV6JV';
    window.adroll_pix_id = '5CGOTTDZQJH3NE2MDWWABI';

    if (document.readyState && !/loaded|complete/.test(document.readyState)) {
      setTimeout(onLoad, 10);
      return;
    }
    if (!window.__adroll_loaded) {
      window.__adroll_loaded = true;
      setTimeout(onLoad, 50);
      return;
    }

    const host = 'https:' === document.location.protocol ? 'https://s.adroll.com' : 'http://a.adroll.com';

    setRenderState({
      rendered: true,
      src: `${host}/j/roundtrip.js`,
    });
  }, []);

  useEffect(() => {
    if (disable) return;
    window.addEventListener?.('load', onLoad, false);
  });

  return rendered ? <Script async src={src} /> : null;
};
