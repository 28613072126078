import styled from '@emotion/styled';
import { flexbox, layout, position, space, typography } from 'styled-system';
import type { FlexboxProps, LayoutProps, PositionProps, SpaceProps, TypographyProps } from 'styled-system';

const Box = styled.div<LayoutProps & FlexboxProps & SpaceProps & PositionProps & TypographyProps>`
  ${position}
  ${layout}
  ${flexbox}
  ${space}
  ${typography}
`;

export default Box;
