import { VStack } from '@chakra-ui/react';
import { atom, useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';

import {
  RippleClose,
  RippleDrawer,
  RippleDrawerBody,
  RippleDrawerCloseButton,
  RippleDrawerContent,
  RippleDrawerFooter,
  RippleDrawerHeader,
  RippleDrawerOverlay,
  RippleIconButton,
} from '@/design';
import { useZendeskDisplay } from '@/utils/useZendesk';

export const tableSettingsDrawerAtom = atom({ isOpen: false });

export function useTableSettingsDrawer() {
  const set = useSetAtom(tableSettingsDrawerAtom);

  function open(): void {
    set({ isOpen: true });
  }

  function toggle(): void {
    set((prev) => ({ isOpen: !prev.isOpen }));
  }

  return { open, toggle };
}

type TableSettingsDrawerProps = { children?: React.ReactNode };
export function TableSettingsDrawer({ children }: TableSettingsDrawerProps): React.JSX.Element {
  const { t } = useTranslation();
  const [{ isOpen }, setAtom] = useAtom(tableSettingsDrawerAtom);
  useZendeskDisplay(!isOpen);

  return (
    <RippleDrawer isOpen={isOpen} onClose={handleClose} placement="right">
      <RippleDrawerOverlay background="none" />
      <RippleDrawerContent minWidth="600px">
        <RippleDrawerHeader>{t('computer:tableSetting.title')}</RippleDrawerHeader>
        <RippleIconButton
          data-testid="close-icon-button"
          aria-label="close"
          icon={<RippleClose />}
          position="absolute"
          top="24px"
          right="24px"
          onClick={handleClose}
        />
        <RippleDrawerBody overflow="auto" p="0">
          <VStack spacing="8px" alignItems="stretch" h="100%">
            {children}
          </VStack>
        </RippleDrawerBody>
        <RippleDrawerFooter>
          <RippleDrawerCloseButton onClick={handleClose}>{t('common:ok')}</RippleDrawerCloseButton>
        </RippleDrawerFooter>
      </RippleDrawerContent>
    </RippleDrawer>
  );

  function handleClose() {
    setAtom({ isOpen: false });
  }
}
