import * as Sentry from '@sentry/nextjs';
import { QueryObserver, useQueryClient } from '@tanstack/react-query';
import type { QueryClient } from '@tanstack/react-query';
import { useMachine } from '@xstate/react';
import { useTranslation } from 'next-i18next';
import { assign, send } from 'xstate';
import type { DoneInvokeEvent, ErrorPlatformEvent } from 'xstate';
import { createModel } from 'xstate/lib/model';

import { useRippleFlashMessage } from '@/design';
import { ErrorResponse } from '@/services/common/types';
import { getSosCallCloudBuildURL } from '@/services/serviceDesk/sosCall';
import { getServiceDeskCloudBuildURL } from '@/services/serviceDesk/supportSession';
import { pureInstance } from '@/services/utils';

import type { Category } from './types';

type downloadModelContext = {
  /** NOTE: should be replaced when interpreting */
  queryClient: QueryClient | null;
  /** NOTE: should be replaced when interpreting */
  packageCode: string;
  downloadLink: string | null;
  downloadName: string | null;
  cloudBuildURL: {
    cacheOkURL: string | null;
    cacheURL: string | null;
    backupURL: string | null;
  };
};

const downloadModelContextDefaultValue: downloadModelContext = {
  queryClient: null,
  packageCode: '',
  downloadLink: null,
  downloadName: null,
  cloudBuildURL: {
    cacheOkURL: null,
    cacheURL: null,
    backupURL: null,
  },
};

export const downloadModel = createModel(downloadModelContextDefaultValue, {
  events: {
    DOWNLOAD: (category: Category) => ({ payload: { category } }),
    BLOCK: () => ({}),
    CACHE_LINK: () => ({}),
    BACKUP_LINK: () => ({}),
    UNEXPECTED_ERROR: () => ({}),
  },
});

type GettingPackageInfoDoneEvent = DoneInvokeEvent<{
  can_build: boolean;
  cache_url: string;
  cache_ok_url: string;
  backup_url: string;
  application_name: string | null;
}>;

const downloadMachine = downloadModel.createMachine(
  {
    id: 'download-sos',
    initial: 'idle',
    states: {
      idle: {
        id: 'idle',
        on: {
          DOWNLOAD: {
            target: 'fetching',
          },
        },
      },
      fetching: {
        initial: 'gettingPackageInfo',
        entry: [assign({ downloadLink: null, downloadName: null })],
        states: {
          gettingPackageInfo: {
            invoke: {
              src: 'gettingPackageInfo',
              onDone: [
                {
                  cond: (_context, _event: any) => {
                    const event: GettingPackageInfoDoneEvent = _event;
                    return Boolean(event.data.can_build && event.data.cache_url && event.data.cache_ok_url && event.data.backup_url);
                  },
                  actions: [
                    assign({
                      cloudBuildURL: (_context, _event: any) => {
                        const event: GettingPackageInfoDoneEvent = _event;
                        return {
                          cacheOkURL: event.data.cache_ok_url,
                          cacheURL: event.data.cache_url,
                          backupURL: event.data.backup_url,
                        };
                      },
                      downloadName: (_, _event) => {
                        const event: GettingPackageInfoDoneEvent = _event;
                        return event.data.application_name;
                      },
                    }),
                  ],
                  target: 'gettingDownloadLink',
                },
                {
                  cond: (_context, _event: any) => {
                    const event: GettingPackageInfoDoneEvent = _event;
                    return Boolean(!event.data.can_build && event.data.backup_url);
                  },
                  target: 'done',
                  actions: [
                    assign({
                      downloadLink: (_context, _event: any) => {
                        const event: GettingPackageInfoDoneEvent = _event;
                        return event.data.backup_url;
                      },
                    }),
                  ],
                },
                {
                  cond: (_context, _event: any) => {
                    const event: Omit<ErrorPlatformEvent, 'data'> & { data: ErrorResponse } = _event;
                    return event.data.statusCode === 40403 || event.data.statusCode === 40404;
                  },
                  actions: [send('BLOCK')],
                },
                { target: '#idle', actions: ['handleUnexpectedError'] },
              ],
              onError: {
                target: '#idle',
                actions: ['handleUnexpectedError'],
              },
            },
          },
          gettingDownloadLink: {
            invoke: {
              src: 'gettingDownloadLink',
            },
            on: {
              CACHE_LINK: {
                target: 'done',
                actions: [assign({ downloadLink: (context) => context.cloudBuildURL.cacheURL })],
              },
              BACKUP_LINK: {
                target: 'done',
                actions: [assign({ downloadLink: (context) => context.cloudBuildURL.backupURL })],
              },
              UNEXPECTED_ERROR: {
                target: '#idle',
                actions: ['handleUnexpectedError'],
              },
            },
          },
          done: {
            type: 'final',
          },
        },
        on: {
          BLOCK: 'blocking',
        },
        onDone: 'idle',
      },
      blocking: {},
    },
  },
  {
    services: {
      gettingPackageInfo: async (context, event) => {
        const { packageCode } = context;
        if (event.type === 'DOWNLOAD') {
          const { category } = event.payload;
          try {
            const { can_build, cache_url, cache_ok_url, backup_url } = await getServiceDeskCloudBuildURL(packageCode, category);
            return { can_build, cache_url, cache_ok_url, backup_url };
          } catch (error) {
            Sentry.captureException(error);
            return error;
          }
        }
        return Promise.reject("Shouldn't get in here");
      },
      gettingDownloadLink: (context) => (callback) => {
        const {
          queryClient,
          cloudBuildURL: { cacheOkURL },
        } = context;
        if (queryClient && cacheOkURL) {
          const observer = new QueryObserver(queryClient, {
            queryKey: ['checkCacheUrl'],
            queryFn: async () => pureInstance.head(cacheOkURL),
            retry: 20,
            gcTime: 0,
            retryDelay: 1000, // ms
          });
          const unsubscribe = observer.subscribe((result) => {
            if (result.status === 'success') {
              callback('CACHE_LINK');
            } else if (result.status === 'error') {
              callback('BACKUP_LINK');
            }
          });
          return () => unsubscribe();
        } else {
          callback('UNEXPECTED_ERROR');
          return () => undefined;
        }
      },
    },
    actions: {
      handleUnexpectedError: () => {
        // NOTE: should be replaced when interpreting
        console.log('handleUnexpectedError');
      },
    },
  },
);

export function useDownloadMachine(packageCode: string) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { flashMessage } = useRippleFlashMessage();

  const showErrorToast = () => {
    flashMessage({ title: t('common:unexpectedError'), variant: 'error' });
  };

  return useMachine(downloadMachine, {
    context: {
      packageCode,
      queryClient,
    },
    actions: {
      handleUnexpectedError: () => {
        showErrorToast();
      },
    },
  });
}

export function useSosCallDownloadMachine(packageCode: string) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { flashMessage } = useRippleFlashMessage();

  const showErrorToast = () => {
    flashMessage({ title: t('common:unexpectedError'), variant: 'error' });
  };

  return useMachine(downloadMachine, {
    context: {
      packageCode,
      queryClient,
    },
    actions: {
      handleUnexpectedError: () => {
        showErrorToast();
      },
    },
    services: {
      gettingPackageInfo: async (context, event) => {
        const { packageCode } = context;
        if (event.type === 'DOWNLOAD') {
          const { category } = event.payload;
          try {
            const { can_build, application_name, cache_url, cache_ok_url, backup_url } = await getSosCallCloudBuildURL(
              packageCode,
              category,
            );
            return { can_build, application_name, cache_url, cache_ok_url, backup_url };
          } catch (error) {
            Sentry.captureException(error);
            return error;
          }
        }
        return Promise.reject("Shouldn't get in here");
      },
    },
  });
}

export default downloadMachine;
