import { ColumnVisibilitySetting } from './ColumnVisibilitySetting';
import { RowHeightSetting } from './RowHeightSetting';
import { ViewSwitch } from './ViewSwitch';

export * from './TableSettingsDrawer';
export const TableSettingsSection = {
  ViewSwitch,
  RowHeightSetting,
  ColumnVisibilitySetting,
};
export * from './utils';
