import { Flex } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useTranslation } from 'next-i18next';

import { RippleBodyText02, RippleHeading08 } from '@/design';

import { rowHeightAtom } from '../../atoms';
import { RowHeight } from '../../types';
import { RadioButtonGroup } from '../RadioButtonGroup';

export function RowHeightSetting(): React.JSX.Element {
  const { t } = useTranslation();
  const [rowHeight, setRowHeight] = useAtom(rowHeightAtom);

  return (
    <Flex flexDirection="column" p="0 24px 24px 24px">
      <RippleHeading08 mb="4px">{t('computer:tableSetting.row.label')}</RippleHeading08>
      <RippleBodyText02 mb="16px">{t('computer:tableSetting.row.description')}</RippleBodyText02>
      <RadioButtonGroup
        value={rowHeight}
        onChange={(value) => {
          setRowHeight(value as RowHeight);
        }}
        options={[
          { label: t('computer:tableSetting.row.option.large'), value: 'large' },
          { label: t('computer:tableSetting.row.option.medium'), value: 'medium' },
          { label: t('computer:tableSetting.row.option.small'), value: 'small' },
        ]}
      />
    </Flex>
  );
}
