import { z } from 'zod';

import { commonResponseSchema } from '@/services/schemas';

export const createSurveyResultSchema = z.object({
  id: z.number(),
  brand_image_upload_url: z.string().nullable(),
});

export type CreateSurveyResult = z.infer<typeof createSurveyResultSchema>;

export const createSurveyErrorSchema = commonResponseSchema.merge(
  z.object({
    result: z.union([z.literal(40422), z.literal(40403), z.literal(41403), z.literal(40409)]),
    data: z.object({ error: z.string() }).optional(),
  }),
);
