import React from 'react';

import { addMinutes, addSeconds, format, parseISO, subDays } from 'date-fns';

import useTeamInformation from '@/utils/useTeamInformation';

const TIME_FORMAT = 'HH:mm:ss';

const getDateFormat = (locale = 'en') => {
  // Currently only enable on development environment
  switch (locale) {
    case 'de':
      return 'dd.MM.yyyy';
    case 'es':
    case 'pt':
    case 'fr':
    case 'it':
      return 'dd/MM/yyyy';
    case 'ko':
      return 'yyyy. MM. dd.';
    case 'ja':
    case 'zh-CN':
    case 'zh-TW':
      return 'yyyy/MM/dd';
    case 'en':
    default:
      return 'yyyy-MM-dd';
  }
};

const getDateTimeFormat = (locale = 'en') => {
  // Currently only enable on development environment
  switch (locale) {
    case 'de':
      return 'dd.MM.yyyy, HH:mm:ss';
    case 'es':
    case 'pt':
      return 'dd/MM/yyyy HH:mm:ss';
    case 'fr':
    case 'it':
      return 'dd/MM/yyyy, HH:mm:ss';
    case 'ko':
      return 'yyyy. MM. dd. HH:mm:ss';
    case 'ja':
    case 'zh-CN':
    case 'zh-TW':
      return 'yyyy/MM/dd HH:mm:ss';
    case 'en':
    default:
      return 'yyyy-MM-dd HH:mm:ss';
  }
};

export const formatDate = (date: Date, locale?: string) => format(date, getDateFormat(locale));
export const formatTime = (date: Date) => format(date, TIME_FORMAT);
export const formatDateTime = (date: Date, locale?: string) => format(date, getDateTimeFormat(locale));

// dateTimeString is ISO format
export function addSecondsToDateTime(dateTimeString: string, diffSeconds = 0, formatLocale?: string): string {
  const date = parseISO(dateTimeString);
  const result = addSeconds(date, diffSeconds);

  return formatDateTime(result, formatLocale);
}

// dateTimeString is ISO format
export function addMinutesToDate(dateTimeString: string, diffMinutes = 0, formatLocale?: string): string {
  const date = parseISO(dateTimeString);
  const result = addMinutes(date, diffMinutes);

  return formatDate(result, formatLocale);
}

export function addMinutesToTime(dateTimeString: string, diffMinutes = 0): string {
  const date = parseISO(dateTimeString);
  const result = addMinutes(date, diffMinutes);

  return formatTime(result);
}

export function addMinutesToDateTime(dateTimeString: string, diffMinutes = 0, formatLocale?: string): string {
  const date = parseISO(dateTimeString);
  const result = addMinutes(date, diffMinutes);

  return formatDateTime(result, formatLocale);
}

export function useTime(locale?: string) {
  const teamInfo = useTeamInformation();
  const diffMinutes = teamInfo?.timeZoneMinutes || 0;

  return React.useMemo(() => {
    function formatDateTime(time: string) {
      return addMinutesToDateTime(time, diffMinutes, locale);
    }
    function formatDate(time: string) {
      return addMinutesToDate(time, diffMinutes, locale);
    }
    function formatTime(time: string) {
      return addMinutesToTime(time, diffMinutes);
    }

    return { formatDateTime, formatDate, formatTime };
  }, [diffMinutes, locale]);
}

export const toUTCToZonedTime = (date: Date, minutes: number, beforeDays = 0) => {
  const currentUTCDate = addMinutes(date, date.getTimezoneOffset()); // get UTC date
  const currentUTCDateBeforeDays = subDays(currentUTCDate, beforeDays); // minus before days
  return addMinutes(currentUTCDateBeforeDays, minutes); // add or minus time depend time zone
};

/** ex. May 26, 2023 */
export const formatToAbbreviateMonth = (date: string) => {
  const parsedDate = parseISO(date);
  return format(parsedDate, 'MMM d, yyyy');
};

/** ex. 20230523 */
export const formattedCompactDate = (date: Date) => format(date, 'yyyyMMdd');

export default formatDateTime;
