import { useRouter } from 'next/router';
import { useEffect } from 'react';

import * as Sentry from '@sentry/nextjs';
import { useMutation } from '@tanstack/react-query';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import partition from 'lodash/partition';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useRippleFlashMessage } from '@/design';
import { useTeamExpiration, useTeamId } from '@/models/TeamInformation';
import { categoryKeySchema } from '@/modules/PolicyCategories/type';
import { Platform, deleteEmmPolicyService, updateEmmPolicyService } from '@/services/teams/emm_policies';

import { messageAtom } from '../atoms';
import { cloneDetailsAtom } from '../atoms/cloneDetails';
import { currentPolicyAtom } from '../atoms/currentPolicy';
import { policyNamesAtom } from '../atoms/policyNames';
import { TwoStepVerificationErrorMessage } from '../components/TwoStepVerificationErrorMessage';
import { MAX_POLICY_COUNT } from '../constants';
import { PolicyAssignmentList, PolicyRelationModel, useCreateDefaultPolicy, useCreatePolicyMutation, usePoliciesQuery } from '../hooks';
import { PolicyToCreate } from '../types';
import { isPreparedDefaultPolicy } from '../utils';
import { AssignPolicyModal } from './AssignPolicyModal';
import { AssignPolicyResultModal } from './AssignPolicyResultModal';
import { ClonePolicyModal } from './ClonePolicyModal';
import { CreatePolicyModal } from './CreatePolicyModal';
import { DeletePolicyModal } from './DeletePolicyModal';
import { DisablePolicyModal } from './DisablePolicyModal';
import { EnablePolicyModal } from './EnablePolicyModal';
import { ExceededMaxPolicyModal } from './ExceededMaxPolicyModal';
import { TipsModal } from './TipsModal';

export const modalTypeEnum = z.enum([
  'EXCEED_MAX',
  'CREATE',
  'CLONE',
  'ASSIGN',
  'ASSIGN_RESULT',
  'DELETE',
  'DISABLE',
  'ENABLE',
  'TIPS',
  'TEAM_DEFAULT_UPDATE',
  '',
]);
export type ModalType = z.infer<typeof modalTypeEnum>;

export const modalAtom = atom<ModalType>('');

type ModalsProps = {
  policyCount: number | undefined;
  policyRelationModel: PolicyRelationModel;
  policiesByPlatformMap: Record<Platform, PolicyAssignmentList>;
  onClose: () => void;
};
export const Modals = ({ policyCount, policyRelationModel, policiesByPlatformMap, onClose }: ModalsProps) => {
  const router = useRouter();
  const teamId = useTeamId();
  const { isTeamExpired } = useTeamExpiration();
  const [modal, setModal] = useAtom(modalAtom);
  const policiesQuery = usePoliciesQuery();
  const { flashMessage } = useRippleFlashMessage();
  const { t } = useTranslation();
  const message = useAtomValue(messageAtom);
  const currentPolicy = useAtomValue(currentPolicyAtom);
  const policyNames = useAtomValue(policyNamesAtom);
  const setCloneDetails = useSetAtom(cloneDetailsAtom);

  const [policyChildren, policyOverridden] = partition(currentPolicy?.children ?? [], (node) => node.policy.policy_kind !== 'server');

  const handleModalClose = () => {
    setModal('');
    onClose();
  };

  const createPolicyMutation = useCreatePolicyMutation();
  const createSuperRootPolicy = useCreateDefaultPolicy();

  const updatePolicyMutation = useMutation({
    mutationFn: ({ policyId, payload }: { policyId: number; payload: z.infer<typeof updateEmmPolicyService.payloadSchema> }) =>
      updateEmmPolicyService.execute(teamId, policyId, payload),
    onSuccess: () => {
      flashMessage({ title: t('common:updated_successfully'), variant: 'success' });
      policiesQuery.refetch();
    },
    onError: (error) => {
      if (updateEmmPolicyService.errorHandling.require2SV(error)) {
        flashMessage({
          variant: 'error',
          title: <TwoStepVerificationErrorMessage />,
        });
        return;
      }
      Sentry.captureException(error);
      flashMessage({ variant: 'error', title: t('common:failed_to_update') });
    },
  });
  const deletePolicyMutation = useMutation({
    mutationFn: async ({ policyId, overriddenIds }: { policyId: number; overriddenIds?: Array<number> }) => {
      if (overriddenIds && overriddenIds.length > 0) {
        await Promise.allSettled(overriddenIds.map((id) => deleteEmmPolicyService.execute(teamId, id)));
      }
      await deleteEmmPolicyService.execute(teamId, policyId);
    },
    onSuccess: () => {
      flashMessage({ title: t('common:deleted_successfully'), variant: 'success' });
      policyRelationModel.refetchPolicies();
    },
    onError: (error) => {
      if (deleteEmmPolicyService.errorHandling.require2SV(error)) {
        flashMessage({
          variant: 'error',
          title: <TwoStepVerificationErrorMessage />,
        });
        return;
      }
      Sentry.captureException(error);
      flashMessage({ variant: 'error', title: t('common:failed_to_delete') });
    },
  });

  const handleCreate = async (policy: PolicyToCreate) => {
    if (isTeamExpired) {
      return;
    }
    if (policyCount && policyCount >= MAX_POLICY_COUNT) {
      return;
    }
    const isCreateChildPolicy = Boolean(policy.parentId);
    const isPreparePolicyExists = policiesByPlatformMap[policy.platform].some(({ policyNode }) =>
      isPreparedDefaultPolicy(policyNode.policy.id),
    );
    if (isPreparePolicyExists) {
      const rootPolicy = await createSuperRootPolicy(policy.platform);
      const newPolicy = await createPolicyMutation.mutateAsync({
        parent_id: isCreateChildPolicy ? rootPolicy.id : null,
        super_root: false,
        policy_kind: 'policy',
        name: policy.name,
        active: policy.active,
        platform: policy.platform,
        description: policy.description,
      });
      handleModalClose();
      router.push(`/w/policy/${newPolicy.id}/${categoryKeySchema.Enum.streamer_preference}`);
      return;
    }
    const newPolicy = await createPolicyMutation.mutateAsync({
      super_root: false,
      policy_kind: 'policy',
      name: policy.name,
      active: policy.active,
      platform: policy.platform,
      parent_id: policy.parentId,
      description: policy.description,
    });
    handleModalClose();
    router.push(`/w/policy/${newPolicy.id}/${categoryKeySchema.Enum.streamer_preference}`);
  };

  const handleClone = async (policy: PolicyToCreate) => {
    if (isTeamExpired) {
      return;
    }
    if (policyCount && policyCount >= MAX_POLICY_COUNT) {
      return;
    }
    if (!currentPolicy?.policy.id) {
      return;
    }

    const policyId = isPreparedDefaultPolicy(currentPolicy.policy.id)
      ? (await createSuperRootPolicy(policy.platform)).id
      : currentPolicy.policy.id;

    setCloneDetails({
      name: policy.name,
      description: policy.description,
      active: policy.active,
      platform: policy.platform,
      parentId: policy.parentId,
    });
    router.push(`/w/policy/${policyId}/${categoryKeySchema.enum.streamer_preference}/clone`);
  };

  const handlePolicyDeleteConfirm = () => {
    if (!currentPolicy || isPreparedDefaultPolicy(currentPolicy?.policy.id)) {
      return;
    }
    const target = policiesByPlatformMap[currentPolicy?.policy.platform].find(
      ({ policyNode }) => currentPolicy && policyNode.policy.id === currentPolicy.policy.id,
    );
    if (!target) {
      return;
    }
    if (policyChildren.length > 0) {
      // We only allow deleting the leaf node of the policy tree.
      return;
    }
    if (policyOverridden.length > 0) {
      // If the policy is overridden, we need to delete the overridden policy first.
      deletePolicyMutation.mutate({
        policyId: currentPolicy?.policy.id,
        overriddenIds: policyOverridden.map((node) => node.policy.id),
      });
      return;
    }

    deletePolicyMutation.mutate({ policyId: currentPolicy?.policy.id });
  };

  const handlePolicyDisableConfirm = () => {
    if (isTeamExpired) {
      return;
    }
    if (!currentPolicy) {
      return;
    }
    if (isPreparedDefaultPolicy(currentPolicy?.policy.id)) {
      createSuperRootPolicy(currentPolicy?.policy.platform, false);
      policiesQuery.refetch();
      return;
    }
    updatePolicyMutation.mutate({
      policyId: currentPolicy?.policy.id,
      payload: { active: false },
    });
  };

  const handlePolicyEnableConfirm = () => {
    if (isTeamExpired) {
      return;
    }
    if (!currentPolicy) {
      return;
    }
    if (isPreparedDefaultPolicy(currentPolicy?.policy.id)) {
      createSuperRootPolicy(currentPolicy?.policy.platform, true);
      return;
    }
    updatePolicyMutation.mutate({
      policyId: currentPolicy.policy.id,
      payload: { active: true },
    });
  };

  const handlePolicyAssignFailed = () => {
    setModal('ASSIGN_RESULT');
  };

  useEffect(() => {
    return () => {
      setModal('');
    };
  }, [setModal]);

  return (
    <>
      <CreatePolicyModal
        isOpen={modal === 'CREATE'}
        isLoading={modal === 'CREATE' && createPolicyMutation.isPending}
        policyNames={policyNames}
        policesByPlatform={policiesByPlatformMap}
        parentPolicy={currentPolicy?.policy ?? null}
        onClose={handleModalClose}
        onCreate={(policy) => {
          handleCreate(policy);
        }}
      />
      <ClonePolicyModal
        isOpen={Boolean(currentPolicy) && modal === 'CLONE'}
        isLoading={modal === 'CLONE' && createPolicyMutation.isPending}
        policyNames={policyNames}
        policesByPlatform={policiesByPlatformMap}
        srcPolicy={currentPolicy?.policy ?? null}
        onClose={handleModalClose}
        onCreate={(policy) => {
          handleClone(policy);
        }}
      />
      <AssignPolicyModal
        isOpen={modal === 'ASSIGN'}
        isLoading={policyRelationModel.isLoading}
        isRefetching={policyRelationModel.isRefetching}
        groupIds={policyRelationModel.sortedGroupIds}
        currentPolicy={currentPolicy?.policy ?? null}
        teamPolicy={policyRelationModel.teamPolicy && currentPolicy ? policyRelationModel.teamPolicy[currentPolicy.policy.platform] : null}
        onClose={handleModalClose}
        onAssignFailed={handlePolicyAssignFailed}
        onRefetch={policyRelationModel.refetch}
      />
      <AssignPolicyResultModal isOpen={modal === 'ASSIGN_RESULT'} onClose={handleModalClose} />
      <DeletePolicyModal
        isOpen={modal === 'DELETE'}
        hasChildren={policyChildren.length > 0}
        hasOverridden={policyOverridden.length > 0}
        isLoading={deletePolicyMutation.isPending}
        onClose={handleModalClose}
        onDelete={handlePolicyDeleteConfirm}
      />
      <EnablePolicyModal
        isOpen={modal === 'ENABLE'}
        isTeamDefault={Boolean(currentPolicy?.policy.super_root)}
        isLoading={updatePolicyMutation.isPending && modal === 'ENABLE'}
        onClose={handleModalClose}
        onEnable={handlePolicyEnableConfirm}
      />
      <DisablePolicyModal
        isOpen={modal === 'DISABLE'}
        isLoading={updatePolicyMutation.isPending && modal === 'DISABLE'}
        isTeamDefault={Boolean(currentPolicy?.policy.super_root)}
        onClose={handleModalClose}
        onDisable={handlePolicyDisableConfirm}
      />
      <ExceededMaxPolicyModal isOpen={modal === 'EXCEED_MAX'} onClose={handleModalClose} />
      <TipsModal message={message} isOpen={modal === 'TIPS'} onClose={handleModalClose} />
    </>
  );
};
