import { useContext } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { useAtomValue } from 'jotai';

import { ComputerTable } from './ComputerTable';
import { GroupTable } from './GroupTable';
import { computerListAtom, viewModeAtom } from './atoms';
import { ColumnPermissionsContext } from './contexts';
import { ComputerItemBase } from './types';

type ComputerListProps<T extends ComputerItemBase = ComputerItemBase> = {
  columns: Array<ColumnDef<T, unknown>>;
  hasSelection?: boolean;
};
export function ComputerList<T extends ComputerItemBase = ComputerItemBase>({ columns, hasSelection }: ComputerListProps<T>) {
  const columnPermissions = useContext(ColumnPermissionsContext);
  const data = useAtomValue(computerListAtom) as Array<T>;

  const viewMode = useAtomValue(viewModeAtom);

  return (
    <Flex flex="1" w="100%">
      <Box w="100%" display={viewMode === 'computer' ? 'block' : 'none'}>
        <ComputerTable columns={columns} data={data} />
      </Box>
      {columnPermissions.group && (
        <Box w="100%" display={viewMode === 'group' ? 'block' : 'none'}>
          <GroupTable columns={columns} data={data} hasSelection={hasSelection} />
        </Box>
      )}
    </Flex>
  );
}
