import { useEffect, useRef, useTransition } from 'react';

import { Flex } from '@chakra-ui/react';
import { useSetAtom } from 'jotai';

import { scrollWidthAtom } from './atoms';
import { ViewMode } from './types';

type TableHeaderContainerProps = { children?: React.ReactNode; viewMode: ViewMode };
export function TableHeaderContainer({ children, viewMode }: TableHeaderContainerProps): React.JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const [, startResizeTransition] = useTransition();

  const setScrollWidth = useSetAtom(scrollWidthAtom);

  useEffect(
    function registerResizeObserver() {
      const observer = new ResizeObserver(([entry]) => {
        startResizeTransition(() => {
          setScrollWidth({ viewMode, value: entry.contentRect.width });
        });
      });

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        observer.disconnect();
      };
    },
    [viewMode, setScrollWidth],
  );

  return (
    <Flex ref={ref} w="100%">
      {children}
    </Flex>
  );
}
