import { z } from 'zod';

import { CurrentOpenFieldAutoFocusType } from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';
import {
  QuestionnaireFieldKindMap,
  QuestionnaireKind,
  baseFieldAPISchema,
  checkboxOnlyAPISchema,
  comboBoxOnlyAPISchema,
  commonSurveyFormSchema,
  csatOnlyAPISchema,
  longTextOnlyAPISchema,
  npsOnlyAPISchema,
  radioButtonOnlyAPISchema,
  shortTextOnlyAPISchema,
} from '@/services/serviceDesk/surveys/type';

import { NO_DEFAULT_OPTION } from './constant';

export type SurveyFormMode = 'create' | 'edit' | 'clone';

export const SurveyFormScenarioMap = {
  endUser: 'end-user',
  editor: 'editor',
  preview: 'preview',
  editorPreview: 'editor-in-preview-mode',
} as const;

const SurveyFormScenarioSchema = z.union([
  z.literal(SurveyFormScenarioMap.endUser),
  z.literal(SurveyFormScenarioMap.editor),
  z.literal(SurveyFormScenarioMap.preview),
  z.literal(SurveyFormScenarioMap.editorPreview),
]);

export type SurveyFormScenario = z.infer<typeof SurveyFormScenarioSchema>;

// Survey form schema during create/edit
const baseFieldSchema = baseFieldAPISchema.extend({
  uuid: z.string(), // Only for drag & drop feature
});

export const shortTextFieldSchema = baseFieldSchema.merge(shortTextOnlyAPISchema);
export const longTextFieldSchema = baseFieldSchema.merge(longTextOnlyAPISchema);
export const comboBoxFieldSchema = baseFieldSchema.merge(comboBoxOnlyAPISchema);
export const radioButtonFieldSchema = baseFieldSchema.merge(radioButtonOnlyAPISchema);
export const checkboxFieldSchema = baseFieldSchema.merge(checkboxOnlyAPISchema);
export const npsFieldSchema = baseFieldSchema.merge(npsOnlyAPISchema);
export const csatFieldSchema = baseFieldSchema.merge(csatOnlyAPISchema);

const questionnaireFieldSchema = z.discriminatedUnion('kind', [
  shortTextFieldSchema,
  longTextFieldSchema,
  comboBoxFieldSchema,
  radioButtonFieldSchema,
  checkboxFieldSchema,
  npsFieldSchema,
  csatFieldSchema,
]);

export type QuestionnaireField = z.infer<typeof questionnaireFieldSchema>;

export const surveyFormSchema = commonSurveyFormSchema.merge(
  z.object({
    name: z.string(),
    questionnaire: z.array(questionnaireFieldSchema),
  }),
);

export type SurveyFormValue = z.infer<typeof surveyFormSchema>;

export type SurveyFormValueKey = keyof z.infer<typeof surveyFormSchema>;

export const ADD_FIELD_MAP: Record<QuestionnaireKind, QuestionnaireField> = {
  [QuestionnaireFieldKindMap.shortText]: {
    id: null,
    kind: QuestionnaireFieldKindMap.shortText,
    required: false,
    question: '',
    answer_placeholder: '',
    default_option: null,
    options: null,
    order: 0,
    uuid: '',
  },
  [QuestionnaireFieldKindMap.longText]: {
    id: null,
    kind: QuestionnaireFieldKindMap.longText,
    required: false,
    question: '',
    answer_placeholder: '',
    default_option: null,
    options: null,
    order: 0,
    uuid: '',
  },
  [QuestionnaireFieldKindMap.comboBox]: {
    id: null,
    kind: QuestionnaireFieldKindMap.comboBox,
    required: false,
    question: '',
    answer_placeholder: '',
    default_option: NO_DEFAULT_OPTION,
    options: null,
    order: 0,
    uuid: '',
  },
  [QuestionnaireFieldKindMap.radioButton]: {
    id: null,
    kind: QuestionnaireFieldKindMap.radioButton,
    required: false,
    question: '',
    answer_placeholder: '',
    default_option: NO_DEFAULT_OPTION,
    options: null,
    order: 0,
    uuid: '',
  },
  [QuestionnaireFieldKindMap.checkbox]: {
    id: null,
    kind: QuestionnaireFieldKindMap.checkbox,
    required: false,
    question: '',
    answer_placeholder: '',
    default_option: [],
    options: [],
    order: 0,
    uuid: '',
  },
  [QuestionnaireFieldKindMap.nps]: {
    id: null,
    kind: QuestionnaireFieldKindMap.nps,
    required: false,
    question: '',
    answer_placeholder: '',
    default_option: null,
    options: null,
    order: 0,
    uuid: '',
  },
  [QuestionnaireFieldKindMap.csat]: {
    id: null,
    kind: QuestionnaireFieldKindMap.csat,
    required: false,
    question: '',
    answer_placeholder: '',
    default_option: null,
    options: null,
    order: 0,
    uuid: '',
  },
};

export type ClickItemHandler = { fieldUuid: string; autoFocusType: CurrentOpenFieldAutoFocusType };
