import styled from '@emotion/styled';

export const Anchor = styled.a`
  color: #428bca;
  text-decoration: none;
  &:hover {
    color: #2a6496;
    text-decoration: underline;
  }
`;

export const AnchorWithUnderLine = styled.a`
  color: #428bca;
  text-decoration: underline;
  &:hover {
    color: #2a6496;
    text-decoration: underline;
  }
`;

export default Anchor;
