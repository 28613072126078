import { Collapse } from '@chakra-ui/react';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';

import { RippleArrowDown16, RippleArrowRight16, RippleHeading09 } from '@/design';

export type CollapseSectionProps = {
  title: string;
  content: React.JSX.Element;
};

const CollapseSection = ({ title, content }: CollapseSectionProps) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Box borderRadius="4px" borderWidth="1px" paddingX="12px" borderStyle="solid" borderColor="neutral.60">
      <Collapse startingHeight="38px" in={isOpen} animateOpacity>
        <Flex onClick={onToggle} height="38px" alignItems="center">
          {isOpen ? <RippleArrowDown16 /> : <RippleArrowRight16 />}
          <RippleHeading09 paddingX="8px">{title}</RippleHeading09>
        </Flex>
        <Box paddingBottom="12px">{content}</Box>
      </Collapse>
    </Box>
  );
};

export default CollapseSection;
