import { Trans, useTranslation } from 'react-i18next';

import {
  RippleButton,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleStrong,
  RippleTypography,
} from '@/design';

type EnablePolicyModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  isTeamDefault: boolean;
  onClose: () => void;
  onEnable: () => void;
};

export const EnablePolicyModal = ({ isOpen, isLoading, isTeamDefault, onClose, onEnable }: EnablePolicyModalProps) => {
  const { t } = useTranslation();
  const handleEnable = () => {
    onEnable();
    onClose();
  };
  return (
    <RippleModal isOpen={isOpen} onClose={onClose}>
      <RippleModalOverlay />
      <RippleModalContent w="448px">
        <RippleModalHeader>
          <RippleTypography variant="heading05">{t('emm-policy:enable_policy')}</RippleTypography>
        </RippleModalHeader>
        <RippleModalBody>
          <RippleTypography variant="body02">
            {isTeamDefault ? (
              <Trans
                t={t}
                i18nKey="emm-policy:this_policy_could_be_applied_to_all_groups_and_computers_by_default__enable"
                components={{
                  Strong: <RippleStrong variant="strong02" />,
                }}
              />
            ) : (
              t('emm-policy:are_you_sure_you_want_to_enable_this_policy')
            )}
          </RippleTypography>
        </RippleModalBody>
        <RippleModalFooter gap="8px">
          <RippleButton variant="grayScaleGhost" onClick={onClose}>
            {t('common:cancel')}
          </RippleButton>
          <RippleButton variant="danger" onClick={handleEnable} isLoading={isLoading}>
            {t('common:enable')}
          </RippleButton>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
};
