import TeamStatus from '@/components/TeamStatus';
import { SeatContext } from '@/models/SeatContext';

import { useTeamDetailQuery } from '../utils';

export function TeamStatusSection(): React.JSX.Element | null {
  const teamDetailQuery = useTeamDetailQuery();

  if (teamDetailQuery.isFetching || teamDetailQuery.data === undefined) return null;

  return (
    <SeatContext.Provider value={teamDetailQuery.data}>
      <TeamStatus sx={{ box: { mb: '12px' } }} />
    </SeatContext.Provider>
  );
}
