import React from 'react';

import type { BoxProps } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';

const BasicCard = ({ children, ...otherProps }: BoxProps) => (
  <Flex
    data-testid="basic-card"
    flexDirection="column"
    alignItems="center"
    width="100%"
    padding="32px"
    background="white"
    borderRadius="4px"
    boxShadow="0px 1px 2px rgba(102, 102, 102, 0.2)"
    {...otherProps}
  >
    {children}
  </Flex>
);

export default BasicCard;
