import { SystemStyleObject } from '@chakra-ui/react';
import { Box, Flex, GridItem } from '@chakra-ui/react';

import { RippleBodyText02, RippleHyperLink } from '@/design';

type PageFooterBaseProps = {
  itemList: Array<{ label: string; url: string; title: string }>;
  containerStyles?: SystemStyleObject;
  copyrightText?: string;
};

export const PageFooterBase = ({ itemList, containerStyles, copyrightText }: PageFooterBaseProps) => {
  return (
    <Box
      w="100%"
      display="grid"
      px="5%"
      pt="27px"
      pb={['96px', '96px', '96px', '27px']}
      borderTop="1px solid"
      borderColor="neutral.60"
      bg="neutral.10"
      gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr', 'repeat(auto-fit, minmax(0, auto))']}
      gap={['16px', '16px', '16px', '32px']}
      justifyContent="center"
      alignItems="center"
      sx={containerStyles}
    >
      {itemList.map(({ label, url, title }) => (
        <GridItem key={label} h="20px">
          <Flex align="center">
            <RippleHyperLink variant="hyperlink02" color="dark.80" target="_blank" rel="noopener noreferrer" href={url} title={title}>
              {label}
            </RippleHyperLink>
          </Flex>
        </GridItem>
      ))}
      <GridItem colSpan={[1, 2, 2, 1]}>
        <RippleBodyText02 color="dark.60">{copyrightText}</RippleBodyText02>
      </GridItem>
    </Box>
  );
};
