import { type TFunction } from 'next-i18next';

import { type ComputerData } from '@/services/computers';

export const osBuild = {
  getAccessor:
    () =>
    ({ os_build, os_build_rev }: Pick<ComputerData, 'os_build' | 'os_build_rev'>) => {
      const build = os_build ?? '';
      const buildRevision = os_build_rev ? ` (${os_build_rev})` : '';

      return `${build}${buildRevision}`;
    },
  getLabel: (t: TFunction) => t('computer:os_build'),
} as const;
