import React, { createContext, useContext } from 'react';

import { useClipboard } from '@chakra-ui/react';

import { RippleCopy, RippleCopy16, RippleIconButton, RippleInput, RippleReady, RippleReady16, RippleTextarea } from '@/design';
import type { RippleIconButtonProps, RippleInputProps, RippleTextareaProps } from '@/design';
import type { OptionalProps } from '@/types';

type CopyStringContextProps = ReturnType<typeof useClipboard>;
const CopyStringContext = createContext<CopyStringContextProps>({} as never);

type CopyStringProps = { content: string; children?: React.ReactNode; onCopy?: (text?: string) => void };
export function CopyString({ content, children, onCopy: onCopyFromProps }: CopyStringProps): React.JSX.Element {
  const { onCopy, ...otherState } = useClipboard(content);

  function handleCopy(): void {
    onCopy();
    if (onCopyFromProps) onCopyFromProps(content);
  }

  return <CopyStringContext.Provider value={{ ...otherState, onCopy: handleCopy }}>{children}</CopyStringContext.Provider>;
}

type CopyStringButtonProps = OptionalProps<RippleIconButtonProps, 'aria-label'> & { isSize16?: boolean };
export function CopyStringButton({ isDisabled, isSize16, ...otherProps }: CopyStringButtonProps): React.JSX.Element {
  const { onCopy, hasCopied } = useCopyString();

  const ReadyIcon = isSize16 ? <RippleReady16 color="green.200" /> : <RippleReady color="green.200" />;
  const CopyIcon = isSize16 ? <RippleCopy16 color="inherit" /> : <RippleCopy color="inherit" />;

  return (
    <RippleIconButton
      w={isSize16 ? '24px' : '32px'}
      h={isSize16 ? '24px' : '32px'}
      aria-label="copy"
      onClick={onCopy}
      icon={hasCopied ? ReadyIcon : CopyIcon}
      color={isDisabled ? 'dark.40' : 'blue.100'}
      isDisabled={isDisabled}
      {...otherProps}
    />
  );
}

type CopyStringInputProps = RippleInputProps;
export function CopyStringInput(props: CopyStringInputProps): React.JSX.Element {
  const { hasCopied, value } = useCopyString();

  return <RippleInput isDisabled color={hasCopied ? 'green.200' : 'neutral.300'} value={value} {...props} />;
}

type CopyStringTextareaProps = RippleTextareaProps;
export function CopyStringTextarea(props: CopyStringTextareaProps): React.JSX.Element {
  const { hasCopied, value } = useCopyString();

  return <RippleTextarea isDisabled color={hasCopied ? 'green.200' : 'neutral.300'} value={value} {...props} />;
}

/**
 * For customize
 */
export function useCopyString() {
  return useContext(CopyStringContext);
}
