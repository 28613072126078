import { Fragment, useEffect, useMemo } from 'react';

import { Flex } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import type { ColumnDef, Row } from '@tanstack/react-table';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { TableVirtuoso } from 'react-virtuoso';

import { RippleTrLegacy } from '@/design';

import { TableHeaderContainer } from '../TableHeaderContainer';
import { makeTableVirtuosoComponentsWithOffsetMask } from '../TableVirtuosoComponents';
import {
  columnFilterStateAtom,
  columnVisibilityAtom,
  currentVisibleRowsAtom,
  filterSelectedAtom,
  rowSelectionStateAtom,
  searchKeywordAtom,
  sharedFilterFnAtom,
  sortingStateAtom,
} from '../atoms';
import { sharedFilterFnName } from '../constants';
import type { ColumnVisibility, ComputerItemBase } from '../types';
import { getRowId } from '../utils';

type ComputerTableProps<T extends ComputerItemBase> = {
  data: Array<T>;
  columns: Array<ColumnDef<T>>;
};
export function ComputerTable<T extends ComputerItemBase>({ data, columns }: ComputerTableProps<T>): React.JSX.Element {
  const [columnVisibility, setColumnVisibility] = useAtom(columnVisibilityAtom);
  const columnFilters = useAtomValue(columnFilterStateAtom);
  const rowSelection = useAtomValue(rowSelectionStateAtom);
  const filterSelected = useAtomValue(filterSelectedAtom);
  const sharedFilterFn = useAtomValue(sharedFilterFnAtom);
  const searchKeyword = useAtomValue(searchKeywordAtom);
  const [sortingState, setSortingState] = useAtom(sortingStateAtom);
  const setCurrentVisibleRows = useSetAtom(currentVisibleRowsAtom);

  const table = useReactTable({
    data,
    columns,
    getRowId,
    state: {
      columnVisibility,
      columnFilters,
      globalFilter: searchKeyword,
      sorting: sortingState,
    },
    autoResetAll: false,
    enableGlobalFilter: true,
    onColumnVisibilityChange: (visibility) => {
      setColumnVisibility(visibility as ColumnVisibility);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getColumnCanGlobalFilter(column) {
      return column.getIsVisible();
    },
    onSortingChange: setSortingState,
    filterFns: {
      [sharedFilterFnName]: sharedFilterFn,
    },
  });

  const rows = computeRows();

  useEffect(() => {
    // TODO: Correct type
    setCurrentVisibleRows(rows as Array<Row<any>>);
  }, [setCurrentVisibleRows, rows]);

  return (
    <TableVirtuoso
      data-testid="computer-list--list"
      style={{ height: '100%', width: '100%' }}
      data={rows}
      // NOTE: `components` should be memoized to avoid some performance issues
      components={useMemo(() => makeTableVirtuosoComponentsWithOffsetMask<Row<T>>(), [])}
      fixedItemHeight={58}
      context={{ table }}
      useWindowScroll
      fixedHeaderContent={() => {
        return (
          <RippleTrLegacy bg="neutral.10" w="fit-content" minW="100%">
            <TableHeaderContainer viewMode="computer">
              {table.getFlatHeaders().map((header) => (
                <Fragment key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </Fragment>
              ))}
            </TableHeaderContainer>
          </RippleTrLegacy>
        );
      }}
      itemContent={(index, row) => {
        const isFirstRow = index === 0;
        const isLastRow = index === rows.length - 1;
        const isSelected = rowSelection[row.id];

        return (
          <Flex
            key={row.id}
            sx={{
              '[data-cell-type="normal"]': {
                bgColor: isSelected ? 'green.10' : 'white',
              },
              '&:hover [data-cell-type="normal"]': {
                bgColor: isSelected ? 'green.40' : 'blue.0',
              },
              '[data-cell-type="normal"]:first-of-type': {
                borderTopLeftRadius: isFirstRow ? '4px' : '0',
                borderBottomLeftRadius: isLastRow ? '4px' : '0',
              },
              '[data-cell-type="normal"]:last-of-type': {
                borderTopRightRadius: isFirstRow ? '4px' : '0',
                borderBottomRightRadius: isLastRow ? '4px' : '0',
              },
            }}
            w="100%"
          >
            {row.getVisibleCells().map((cell) => (
              <Fragment key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Fragment>
            ))}
          </Flex>
        );
      }}
    />
  );

  function computeRows(): Array<Row<T>> {
    const { rows } = table.getRowModel();

    switch (filterSelected) {
      case 'all': {
        return rows;
      }
      case 'selected': {
        return rows.filter((row) => rowSelection[row.id]);
      }
      case 'unselected': {
        return rows.filter((row) => !rowSelection[row.id]);
      }
    }
  }
}
