import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';

import { SurveyFormValue } from '@/modules/ServiceDesk/SurveyForm/Editor/types';
import { BrandImageMap, QuestionnaireFieldKindMap, QuestionnaireKind } from '@/services/serviceDesk/surveys/type';
import preSanitize from '@/utils/pre-sanitize';

import { BRAND_ICON_UUID, SUBMIT_BUTTON_UUID, WELCOME_UUID } from '../constant';
import { useCurrentOpenFieldValue, useCustomImageFileValue, useResetCurrentOpenField } from '../hooks';

export const useValidateAndAutoFillEmptyField = () => {
  const { setValue, watch } = useFormContext<SurveyFormValue>();
  const { t } = useTranslation();
  const currentField = useCurrentOpenFieldValue();
  const resetCurrentOpenField = useResetCurrentOpenField();
  const customImageFile = useCustomImageFileValue();

  const questionnaire = watch('questionnaire');
  const actionButtonLabel = watch('action_button_label');
  const welcomeMessage = watch('welcome_message');
  const brandImage = watch('brand_image');

  const untitled = t('survey:untitled');

  const fieldDefaultValueMap: Record<QuestionnaireKind, string> = {
    [QuestionnaireFieldKindMap.shortText]: untitled,
    [QuestionnaireFieldKindMap.longText]: untitled,
    [QuestionnaireFieldKindMap.comboBox]: untitled,
    [QuestionnaireFieldKindMap.radioButton]: untitled,
    [QuestionnaireFieldKindMap.checkbox]: untitled,
    [QuestionnaireFieldKindMap.nps]: untitled,
    [QuestionnaireFieldKindMap.csat]: untitled,
  };

  const autoFillActionButton = () => {
    const actionButtonLabelSanitized = preSanitize(actionButtonLabel).trim();
    setValue('action_button_label', actionButtonLabelSanitized === '' ? t('common:submit') : actionButtonLabelSanitized);
  };

  const autoFillBrandImage = () => {
    if (brandImage === BrandImageMap.custom && customImageFile.url === '') {
      setValue('brand_image', BrandImageMap.splashtop);
    }
  };

  const autoFillWelcomeMessageField = () => {
    const sanitizedTitle = welcomeMessage.title && preSanitize(welcomeMessage.title).trim();
    const sanitizedSubtitle = welcomeMessage.subtitle && preSanitize(welcomeMessage.subtitle).trim();

    if (sanitizedTitle === '' && sanitizedSubtitle === '') {
      setValue('welcome_message.title', t('survey:thank_you_for_using_splashtop_service_desk'));
      setValue('welcome_message.subtitle', t('survey:thank_you_for_using_splashtop_service_desk_message'));
    } else {
      setValue('welcome_message.title', sanitizedTitle);
      setValue('welcome_message.subtitle', sanitizedSubtitle);
    }
  };

  const autoFillQuestionField = (uuid: string | undefined) => {
    const currentFieldIndex = questionnaire.findIndex((field) => field.uuid === uuid);
    if (currentFieldIndex !== -1) {
      const currentField = questionnaire[currentFieldIndex];
      const currentQuestionSanitized = preSanitize(currentField.question).trim();
      const currentPlaceholderSanitized = preSanitize(currentField.answer_placeholder).trim();
      setValue(
        `questionnaire.${currentFieldIndex}.question`,
        currentQuestionSanitized === '' ? fieldDefaultValueMap[currentField.kind] : currentQuestionSanitized,
      );
      setValue(
        `questionnaire.${currentFieldIndex}.answer_placeholder`,
        currentPlaceholderSanitized === '' ? '' : currentPlaceholderSanitized,
      );
    }
  };

  const validateAndAutoFillEmptyField = (props: { isResetCurrentOpenField: boolean } | void) => {
    const currentUuid = currentField?.uuid;

    if (currentUuid === SUBMIT_BUTTON_UUID) {
      autoFillActionButton();
    } else if (currentUuid === BRAND_ICON_UUID) {
      autoFillBrandImage();
    } else if (currentUuid === WELCOME_UUID) {
      autoFillWelcomeMessageField();
    } else {
      autoFillQuestionField(currentUuid);
    }

    if (props?.isResetCurrentOpenField) {
      resetCurrentOpenField();
    }
  };

  return { validateAndAutoFillEmptyField };
};
