import { useAtomValue } from 'jotai';

import { RippleTooltip } from '@/design';
import { tableColumn } from '@/models/Computer';
import { ComputerData } from '@/services/computers';

import { searchKeywordAtom } from '../atoms';

type IPContentProps = { value: ComputerData['pubip'] };
/**
 * For displaying IP address (WAN) and IP address (LAN) cell content
 */
export function IPContent({ value }: IPContentProps): React.JSX.Element | null {
  const keyword = useAtomValue(searchKeywordAtom);

  // NOTE: Prevent pass null to RippleTooltip causes runtime error (React.Children.only expected to receive a single React element child.)
  if (value === null || value === undefined) return null;

  return (
    <RippleTooltip label={tableColumn.ipAddressWAN.getTooltipLabel(value)}>
      {tableColumn.ipAddressWAN.getCellContent(value, keyword) ?? ''}
    </RippleTooltip>
  );
}
