import * as Sentry from '@sentry/react';
import { i18n } from 'next-i18next';

import { showRippleFlashMessageStandalone } from '@/design';

type ShowErrorFlashMessageProps = {
  /**
   * Should pass `Error` object for stack trace.
   */
  error: any;
  /**
   * Extra context for Sentry. Useful for logging additional information.
   *
   * @see https://docs.sentry.io/platforms/javascript/enriching-events/context/
   */
  sentryExtraContext?: Parameters<typeof Sentry.captureException>[1];
  /**
   * You can pass a specific message id to avoid showing the same error message multiple times.
   */
  flashMessageId?: string;
  /**
   * You can pass a custom message to show in the flash message.
   * It's better to show the event ID in the message to help debugging.
   * @default "An unexpected error occurred, please try again. (EventID: {eventId})"
   */
  message?: (eventId: string) => React.ReactNode;
};

/**
 * Send an error log to Sentry and show error flash message.
 *
 * @example
 * ```ts
 * // Default usage
 * somePromise.catch((error) => {
 *   showErrorFlashMessage({ error });
 * });
 *
 * // Custom message
 * showErrorFlashMessage({ error, message: (eventId) => `Error occurred with Event ID: ${eventId}` });
 *
 * // If not causes by runtime error, you can pass a extra context to the function.
 * // You should pass the error object as the first parameter for stack trace.
 * showErrorFlashMessage({ error: new Error('Something went wrong'), sentryExtraContext: { extra: { message: 'Extra message' } } });
 * ```
 */
export function showErrorFlashMessage({ error, sentryExtraContext, flashMessageId, message }: ShowErrorFlashMessageProps) {
  const eventId = Sentry.captureException(error, sentryExtraContext);

  const defaultMessage = (
    <>
      {i18n?.t('common:unexpectedError')} <br /> (Event ID: {eventId})
    </>
  );

  showRippleFlashMessageStandalone({
    id: flashMessageId,
    variant: 'error',
    title: message ? message(eventId) : defaultMessage,
  });
}
