import { Box, Flex } from '@chakra-ui/react';

import { COPY_RIGHT } from '@/constants';
import { RippleBodyText03, RippleHeading05, RippleReadyOutline } from '@/design';
import { SurveyFormValue } from '@/modules/ServiceDesk/SurveyForm/Editor/types';

type ThankYouMessageProps = {
  thankYouMessage: SurveyFormValue['thank_you_message'];
};

export const ThankYouMessage = ({ thankYouMessage }: ThankYouMessageProps) => {
  return (
    <Flex w="800px" direction="column" align="center">
      <Flex
        w="688px"
        direction="column"
        align="center"
        mt="48px"
        mb="16px"
        mx="56px"
        border="1px solid"
        borderColor="neutral.300"
        borderRadius="12px"
        pt="44px"
        pb="56px"
      >
        <RippleReadyOutline color="green.200" width="56px" height="56px" />
        <Box pt="16px" w="100%">
          <RippleHeading05 whiteSpace="pre-wrap" textAlign="center">
            {thankYouMessage}
          </RippleHeading05>
        </Box>
      </Flex>
      <RippleBodyText03 mb="56px" color="neutral.300">
        {COPY_RIGHT}
      </RippleBodyText03>
    </Flex>
  );
};
