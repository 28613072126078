import { Center, Flex, Image } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleAccordion, RippleAccordionItem, RippleBodyText02 } from '@/design';
import { AddComputerBtn } from '@/modules/Unboxing/shared/AddComputerBtn';
import { AttendedStreamerSteps } from '@/modules/Unboxing/shared/AttendedStreamerSteps';

const AttendedHeader = () => {
  const { t } = useTranslation(['common']);

  return (
    <Flex alignItems="center" fontSize="14px">
      <Center w={6} h={6} mr={1}>
        <Image src="/next/images/oobe/icon_24px_SOS_Call.svg" alt="attended access" />
      </Center>
      {t('common:header.attendedAccess')}
    </Flex>
  );
};

const UnattendedHeader = () => {
  const { t } = useTranslation(['common']);

  return (
    <Flex alignItems="center" fontSize="14px">
      <Center w={6} h={6} mr={1}>
        <Image src="/next/images/oobe/icon_24px_SRS.svg" alt="unattended access" />
      </Center>
      {t('common:header.unattendedAccess')}
    </Flex>
  );
};

export const StreamerAccordion = ({ isMember, onClickAddComputer }: { isMember: boolean; onClickAddComputer: () => void }) => {
  const { t } = useTranslation(['setup']);

  return (
    <RippleAccordion variant="border-top" borderRadius="base" minW="100%">
      <RippleAccordionItem header={<AttendedHeader />}>
        <Box pt="8px">
          <AttendedStreamerSteps />
        </Box>
      </RippleAccordionItem>
      <RippleAccordionItem header={<UnattendedHeader />}>
        <RippleBodyText02 mb="16px" pt="8px">
          {isMember ? t('setup:adminWillConfigure') : t('setup:unattendedStreamer')}
        </RippleBodyText02>
        {!isMember && <AddComputerBtn onClick={onClickAddComputer} />}
      </RippleAccordionItem>
    </RippleAccordion>
  );
};
