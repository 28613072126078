import { Box, Flex } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';

import { RippleBodyText02, RippleBreadcrumb, RippleHeading04 } from '@/design';
import { useLatestStreamerVersion } from '@/models/SystemInformation';
import { useTeamInformation, useTeamRole } from '@/models/TeamInformation';

import { TeamStatusSection, computerCountAtom, useTeamDetailQuery } from '../ComputerList';
import { StickyLeftWrapper } from '../ComputerList/components';

type PageFrameProps = { children?: React.ReactNode };
export function PageFrame({ children }: PageFrameProps): React.JSX.Element {
  const { t } = useTranslation();

  const latestStreamerVersion = useLatestStreamerVersion();

  return (
    <Flex flexDirection="column" h="100%">
      <StickyLeftWrapper>
        <Box py="24px">
          <TeamStatusSection />
          <RippleBreadcrumb items={[t('common:header.management'), t('computer:allComputers.title')]} mb="16px" />
          <Flex mb="8px">
            <RippleHeading04>{t('computer:allComputers.title')}</RippleHeading04>
          </Flex>
          <Flex justifyContent="space-between">
            <Box>
              <TeamComputerInformation />
            </Box>
            <RippleBodyText02>
              {t('computer:latestStreamer')} Windows {latestStreamerVersion.win} / Mac {latestStreamerVersion.mac}
            </RippleBodyText02>
          </Flex>
        </Box>
      </StickyLeftWrapper>
      {children}
    </Flex>
  );
}

function TeamComputerInformation(): React.JSX.Element | null {
  const { t } = useTranslation();

  const { team_name } = useTeamInformation();
  const { isMember, isGroupAdmin } = useTeamRole();

  const teamDetailQuery = useTeamDetailQuery();
  const teamDetail = teamDetailQuery.data;

  const computerCount = useAtomValue(computerCountAtom);

  if (teamDetail) {
    const computerInfo = computeComputerInfo();

    return (
      <RippleBodyText02>
        {team_name} / {computerInfo}
      </RippleBodyText02>
    );
  }

  return null;

  function computeComputerInfo(): string {
    if (teamDetail) {
      const { total_computers } = teamDetail;

      const isSBAUnlimited = total_computers === null;

      if (isMember || isGroupAdmin) return t('computer:allComputers.teamInfo.limitedManagement', { computerCount });

      if (isSBAUnlimited) return t('computer:allComputers.teamInfo.SBAUnlimited', { computerCount });

      // Owner / Admin / Super admin
      return t('computer:allComputers.teamInfo.fullManagement', {
        computerCount,
        totalComputers: total_computers,
      });
    }

    return '';
  }
}
