import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';

import { useRippleFlashMessage } from '@/design';
import { useTeamId, useTeamRole } from '@/models/TeamInformation';
import { getPreferencePolicyDefaultValue } from '@/services/preferencePolicy';
import type { PreferencePolicyDefaultSettingMap } from '@/services/preferencePolicy/types';
import { getTeamDetail, getTeamMemberListSimplified } from '@/services/team';
import type { TeamMemberListItem } from '@/services/team/types';

type PreferencePolicyInformation = {
  isFetching: boolean;
  permissions: {
    canSetGroupAdmin: boolean;
  };
  groupAdminList: Array<TeamMemberListItem>;
};
export function usePreferencePolicyInformation(): PreferencePolicyInformation {
  const { t } = useTranslation();
  const { flashMessage } = useRippleFlashMessage();

  const teamId = useTeamId();
  const { isAdmin, isOwner } = useTeamRole();

  const {
    data: teamDetail,
    isFetching: isFetchingTeamDetail,
    isError: isTeamDetailError,
    error: teamDetailError,
  } = useQuery({
    queryKey: ['getTeamDetail'],
    queryFn: () => getTeamDetail(teamId),
  });

  useEffect(() => {
    if (isTeamDetailError) {
      console.error(teamDetailError);
      flashMessage({
        title: t('common:unexpectedError'),
        variant: 'error',
      });
    }
  }, [flashMessage, isTeamDetailError, t, teamDetailError]);

  const canSetGroupAdmin = computeCanSetGroupAdmin();
  function computeCanSetGroupAdmin(): boolean {
    return Boolean(teamDetail?.team_member_permissions.create_group_admin && (isOwner || isAdmin));
  }

  const {
    data: groupAdminList = [],
    isFetching: isFetchingGroupAdminList,
    isError: isGroupAdminError,
    error: groupAdminError,
  } = useQuery({
    queryKey: ['getGroupAdminList'],
    queryFn: () => getTeamMemberListSimplified(teamId, { roles: ['group_admin'] }),
    enabled: canSetGroupAdmin,
  });

  useEffect(() => {
    if (isGroupAdminError) {
      console.error(groupAdminError);
      flashMessage({
        title: t('common:unexpectedError'),
        variant: 'error',
      });
    }
  }, [isGroupAdminError, groupAdminError, flashMessage, t]);

  const isFetching = isFetchingTeamDetail || isFetchingGroupAdminList;

  const permissions = {
    canSetGroupAdmin,
  };

  return { isFetching, permissions, groupAdminList };
}

export function usePreferencePolicyDefaultValue(): PreferencePolicyDefaultSettingMap | null {
  const { t } = useTranslation();
  const { flashMessage } = useRippleFlashMessage();

  const teamId = useTeamId();

  const {
    data: defaultPolicy = {} as PreferencePolicyDefaultSettingMap,
    isFetching,
    isError,
    error,
  } = useQuery({ queryKey: ['getDefaultPolicy'], queryFn: () => getPreferencePolicyDefaultValue(teamId) });

  useEffect(() => {
    if (isError) {
      console.error(error);
      flashMessage({
        title: t('common:unexpectedError'),
        variant: 'error',
      });
    }
  }, [isError, error, flashMessage, t]);

  if (isFetching) return null;

  return defaultPolicy;
}
