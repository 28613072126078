import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { INFO_QUERY } from '@/services/common';
import type { ResponseData, ResponseErrorReason, TeamKeys } from '@/services/common/types';
import { isSuccess } from '@/services/utils';

import type {
  CurrentSession,
  CurrentSessionDetailData,
  CurrentSessionNoteData,
  CurrentSessionsData,
  DisconnectCurrentSessionParams,
  GetCurrentSessionDetailParams,
  GetCurrentSessionNoteParams,
  GetCurrentSessionParams,
  UpdateCurrentSessionUpdateNoteParams,
} from './types';

const currentSessionFields: Array<keyof CurrentSession> = [
  'id',
  'created_at',
  'srs_name',
  'srs_owner_name',
  'server_ip_addr',
  'service_kind',
  'has_command_list',
  'has_file_list',
  'has_voice_call_list',
  'voice_call_started_at',
  'note',
  'network_type',
  'can_force_disconnect',
  'status',
  'srs_uuid',
];

const currentSessionFieldsWithRelayRecording: Array<keyof CurrentSession> = [...currentSessionFields, 'has_recording_files'];

export const currentSessionFieldsForAccessedBy = ['src_user_email', 'client_ip_addr', 'src_name'];
const currentSessionOtherFields = [...currentSessionFieldsForAccessedBy];

export const allServiceKinds = [
  'unattended_remote',
  'unattended_file',
  'unattended_chat',
  'unattended_cmpt',
  'unattended_ssh',
  'unattended_premium_tool',
  'attended_remote',
  'service_desk_premium_tool',
];

function getCurrentSessions(params: GetCurrentSessionParams, options?: AxiosRequestConfig) {
  const { page = 1, pageSize = 100, serviceKinds = allServiceKinds, keyword, sessionIds, srsUUID } = params;
  const fields = [
    ...currentSessionFieldsWithRelayRecording,
    ...(params.fields ?? currentSessionOtherFields),
    ...(params.extraFields ?? []),
  ].join(',');
  const kinds = serviceKinds.join(',');
  let url = `/api/web/v1/teams/current_sessions?fields=${fields}&page=${page}&per_page_size=${pageSize}&service_kinds=${kinds}&order_by=id&order_type=desc`;

  if (keyword) {
    url += `&keyword=${encodeURIComponent(keyword)}`;
  }

  if (sessionIds) {
    url += `&ids=${sessionIds.join(',')}`;
  }

  if (srsUUID) {
    url += `&srs_uuid=${srsUUID}`;
  }

  return new Promise<CurrentSessionsData>((resolve, reject) => {
    axios
      .get<ResponseData & { data: CurrentSessionsData }>(url, options)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

type GetCurrentSessionDetailOptions = {
  requestInstance?: AxiosInstance;
};

const getCurrentSessionDetail = (params: GetCurrentSessionDetailParams, options?: GetCurrentSessionDetailOptions) => {
  const payload: { kinds: string; start_time?: string } = {
    kinds: params.kinds.join(','),
    start_time: params.startTime,
  };

  // Build the query string parameters
  const querystring = Object.entries(payload)
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  const url = `/api/web/v1/teams/api_sessions/${params.sessionId}?${querystring}`;

  return new Promise<CurrentSessionDetailData>((resolve, reject) => {
    (options?.requestInstance ?? axios)
      .get<ResponseData & { data: CurrentSessionDetailData }>(url)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const getCurrentSessionNote = (params: GetCurrentSessionNoteParams) => {
  return new Promise<CurrentSessionNoteData>((resolve, reject) => {
    axios
      .get<ResponseData & { data: CurrentSessionNoteData }>(`/api/web/v1/teams/api_sessions/${params.sessionId}/note`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const updateCurrentSessionNote = (params: UpdateCurrentSessionUpdateNoteParams) => {
  return new Promise<{ status: 'success' }>((resolve, reject) => {
    axios
      .patch<ResponseData & ResponseErrorReason>(`/api/web/v1/teams/api_sessions/${params.sessionId}/note`, params.note)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const disconnectCurrentSession = (params: DisconnectCurrentSessionParams) => {
  return new Promise<{ status: 'success' }>((resolve, reject) => {
    axios
      .post<ResponseData & ResponseErrorReason>(`/api/web/v1/teams/current_sessions/${params.sessionId}/disconnect`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const checkHasRelayRecording = () => {
  return new Promise<{ relay_recording_access: boolean; sos_relay_recording_access: boolean }>((resolve, reject) => {
    axios
      .post<
        ResponseData & {
          data: Partial<
            Record<TeamKeys, Record<'team_member_permissions', { relay_recording_access: boolean; sos_relay_recording_access: boolean }>>
          >;
        }
      >(INFO_QUERY, {
        team_member_permissions: ['relay_recording_access', 'sos_relay_recording_access'],
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;

        const teams: Array<TeamKeys> = ['msp', 'sba', 'srs', 'stp', 'sos', 'splashtop', 'ste_custom'];
        const relay_recording_access = teams.some((team) => data?.[team]?.team_member_permissions?.relay_recording_access);
        const sos_relay_recording_access = teams.some((team) => data?.[team]?.team_member_permissions?.sos_relay_recording_access);

        if (isSuccess(result)) {
          resolve({
            relay_recording_access,
            sos_relay_recording_access,
          });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

export {
  getCurrentSessions,
  getCurrentSessionDetail,
  getCurrentSessionNote,
  updateCurrentSessionNote,
  disconnectCurrentSession,
  checkHasRelayRecording,
};
