import { Container } from '@chakra-ui/react';

import { useUserProfile } from '@/models/UserProfile';
import { UnboxingDesktop } from '@/modules/Unboxing/Desktop';
import { UnboxingMobile } from '@/modules/Unboxing/Mobile';
import { UnboxingProps } from '@/modules/Unboxing/types';
import { useZendesk } from '@/utils/useZendesk';

const Unboxing = ({ pageTitle, pageSubtitle }: UnboxingProps) => {
  const { email } = useUserProfile();
  useZendesk({ product: '', email });
  return (
    <>
      <Container display={['none', 'block', 'block', 'block']} maxW="100%">
        <UnboxingDesktop pageTitle={pageTitle} pageSubtitle={pageSubtitle} />
      </Container>
      <Container display={['block', 'none', 'none', 'none']} maxW="100%">
        <UnboxingMobile pageTitle={pageTitle} pageSubtitle={pageSubtitle} />
      </Container>
    </>
  );
};

export default Unboxing;
