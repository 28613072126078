import { Global, css } from '@emotion/react';

import FullPageLoading from '@/components/FullPageLoading';
import Unboxing from '@/modules/Unboxing';
import { useIsInIframe } from '@/modules/Unboxing/hooks/useIsInIframe';
import { useStreamerTypeHandler } from '@/modules/Unboxing/hooks/useStreamerType';
import { UnboxingProps } from '@/modules/Unboxing/types';
import { PageContainer, PageLayout } from '@/showcase';

type UnboxingPageProps = UnboxingProps & { teamStatusSection?: React.JSX.Element };

export const UnboxingPage = ({ pageTitle, pageSubtitle, teamStatusSection }: UnboxingPageProps) => {
  const { isInIframe } = useIsInIframe();

  const { type } = useStreamerTypeHandler();

  return type === 'none' ? (
    <FullPageLoading />
  ) : (
    <PageLayout hideHeader={isInIframe}>
      <Global
        styles={css`
          html,
          body,
          #__next {
            height: 100%;
            min-height: 100%;
          }
        `}
      />
      <PageContainer>
        <>
          {teamStatusSection}
          <Unboxing pageTitle={pageTitle} pageSubtitle={pageSubtitle} />
        </>
      </PageContainer>
    </PageLayout>
  );
};
