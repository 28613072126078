import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleButton, RippleFilter, RippleTypography } from '@/design';

type ComputerSelectorAlertProps = {
  haveFilterApplied: boolean;
  isNotFound: boolean;
  onShowAll: () => void;
};

export default function ComputerSelectorFilterAlert(props: ComputerSelectorAlertProps) {
  const { haveFilterApplied, isNotFound, onShowAll } = props;
  const { t } = useTranslation();

  if (!haveFilterApplied || isNotFound) {
    return null;
  }

  return (
    <Flex justifyContent="center" mb="16px">
      <Flex
        justifyContent="center"
        alignItems="center"
        borderWidth="1px"
        borderStyle="solid"
        boxShadow="0px 8px 16px 4px rgba(0, 0, 0, 0.12)"
        borderRadius="4px"
        padding="7px 12px"
        borderColor="blue.200"
        backgroundColor="blue.5"
      >
        <Box flexShrink={0}>
          <RippleFilter color="blue.200" isApplied />
        </Box>
        <RippleTypography variant="body02" ml="8px" color="blue.200">
          {t('computer:selector.youHaveFiltersApplied')}
        </RippleTypography>
        <RippleButton size="xs" height="26px" ml="12px" textTransform="capitalize" onClick={onShowAll}>
          {t('computer:selector.showAll')}
        </RippleButton>
      </Flex>
    </Flex>
  );
}
