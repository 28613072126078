import axios from 'axios';
import { z } from 'zod';

import { updateKeySchema } from '@/services/teamSettings/constants';
import { RoleSwitchUpdateKey, UpdateKey } from '@/services/teamSettings/types';
import { checkResponse } from '@/services/utils';

import { granularControlRoleEnum as roleEnum } from '../granular_controls/types';

const METHOD = 'PATCH';

const getUrl = (teamId: number) => `/api/web/v1/teams/${teamId}/team_settings`;

const schema = z
  .object({
    setting_type: updateKeySchema,
    result: z.boolean(),
  })
  .array();

// TODO: Define the actual payload type without `any` ?
export type CommonSettingPayload = {
  setting_type: Exclude<UpdateKey, RoleSwitchUpdateKey>;
  update_status?: any;
  additional_settings?: any;
};

/**
 * Only accepts one role in the payload
 */
type RoleUpdateStatus =
  | { [roleEnum.manager]: boolean; [roleEnum.group_admin]?: never; [roleEnum.member]?: never }
  | { [roleEnum.manager]?: never; [roleEnum.group_admin]: boolean; [roleEnum.member]?: never }
  | { [roleEnum.manager]?: never; [roleEnum.group_admin]?: never; [roleEnum.member]: boolean };
export type RoleSwitchSettingPayload = {
  setting_type: RoleSwitchUpdateKey;
  /** @see {@link RoleUpdateStatus} */
  update_status: RoleUpdateStatus;
  additional_settings?: never;
};

export type UpdateTeamSettingsPayload = CommonSettingPayload | RoleSwitchSettingPayload;
type Payload = { settings: Array<UpdateTeamSettingsPayload> };

const execute = (teamId: number, payload: Payload) =>
  checkResponse(axios.request({ url: getUrl(teamId), method: METHOD, data: payload }), schema);

/**
 * Team Settings Update API
 *
 * @see https://splashtop.atlassian.net/wiki/x/PQFhRg
 */
export const updateTeamSettingsService = {
  getUrl,
  execute,
  method: METHOD,
} as const;
