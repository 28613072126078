import { useAtomValue } from 'jotai';

import { teamControlAtomFamily, teamControlScopeAtom } from '@/modules/TeamSettings/atoms';
import { TeamControlContextValue } from '@/modules/TeamSettings/types';

/**
 * Get the team control context.
 */
export function useTeamControlContext(): TeamControlContextValue {
  const teamControlScope = useAtomValue(teamControlScopeAtom);
  const teamControlAtom = teamControlAtomFamily(teamControlScope);

  return useAtomValue(teamControlAtom);
}
