import { RenameComputerModal, useRenameComputerModal } from './RenameComputerModal';

export function useRenameComputer() {
  const renameComputerModal = useRenameComputerModal();

  return {
    execute: renameComputerModal.open,
  };
}

export function RenameComputer(): React.JSX.Element {
  return (
    <>
      <RenameComputerModal />
    </>
  );
}
