import React from 'react';

import { SystemStyleObject } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import useActivatedButton from '@/components/ComputerList/useActivatedButton';
import { RippleCollapse, RippleExpand, RippleIconButton, RippleTooltip } from '@/design';

type AccordionButtonProps = {
  isEnabled: boolean;
  onClick: () => void;
  sx?: {
    box?: SystemStyleObject;
  };
};

function AccordionButton({ isEnabled, onClick, sx }: AccordionButtonProps) {
  const { t } = useTranslation();
  const label = isEnabled ? t('computer:selector.collapseAll') : t('computer:selector.expandAll');

  const isActivated = useActivatedButton(isEnabled);

  return (
    <RippleTooltip aria-label={label} label={label} placement="bottom">
      <RippleIconButton
        aria-label="accordion button"
        aria-expanded={isEnabled}
        data-testid="accordion-btn"
        backgroundColor={isActivated ? 'blue.20' : 'transparent'}
        _hover={{ bg: 'blue.10' }}
        p="4px"
        minWidth="initial"
        sx={sx?.box}
        onClick={onClick}
        icon={isEnabled ? <RippleCollapse /> : <RippleExpand />}
      />
    </RippleTooltip>
  );
}

export default AccordionButton;
