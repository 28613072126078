import Image from 'next/image';
import { useState } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'next-i18next';

import { RippleHeading05, RippleHeading06, RippleTab, RippleTabList, RippleTabPanel, RippleTabPanels, RippleTabs } from '@/design';
import { ConnectFrom } from '@/modules/Unboxing/Mobile/ConnectFrom';
import { ConnectTo } from '@/modules/Unboxing/Mobile/ConnectTo';
import MobileSRC from '@/modules/Unboxing/images/mobile_SRC.svg';
import MobileSRS from '@/modules/Unboxing/images/mobile_SRS.svg';
import { InstallationTutorialText } from '@/modules/Unboxing/shared/InstallationTutorialText';
import { UnboxingProps } from '@/modules/Unboxing/types';

export const UnboxingMobile = ({ pageTitle, pageSubtitle }: UnboxingProps) => {
  const { t } = useTranslation(['setup', 'common']);

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Flex direction="column" align="center" justify="center" py="4vh">
      {pageTitle && <RippleHeading05 align="center">{pageTitle}</RippleHeading05>}
      {pageSubtitle && (
        <RippleHeading06 color="neutral.200" pt="12px" align="center">
          {pageSubtitle}
        </RippleHeading06>
      )}
      <Flex w="288px" maxW="288px" direction="column" justify="center" align="center">
        <Box my="32px">
          <Image src={tabIndex === 0 ? MobileSRC : MobileSRS} alt="mobile_connection" priority />
        </Box>
        <Box border="1px solid" borderColor="neutral.60" mt="20px" borderBottomRadius="4px" bg="white">
          <RippleTabs variant="withinContainer" onChange={(index) => setTabIndex(index)}>
            <RippleTabList>
              <RippleTab>{capitalize(t('setup:connectFrom'))}</RippleTab>
              <RippleTab>{capitalize(t('setup:connectTo'))}</RippleTab>
            </RippleTabList>
            <RippleTabPanels mt="0px">
              <RippleTabPanel p="0px">
                <ConnectFrom />
              </RippleTabPanel>
              <RippleTabPanel p="0px">
                <ConnectTo />
              </RippleTabPanel>
            </RippleTabPanels>
          </RippleTabs>
        </Box>
        <InstallationTutorialText />
      </Flex>
    </Flex>
  );
};
