import { z } from 'zod';

import { Questionnaire } from '../supportForm/types';

export type SupportSessionChannelOptions = {
  private: {
    id: 0;
    name: string;
    default: false;
  };
  channels: Array<{
    id: number;
    name: string;
    default: boolean;
  }>;
};

export type SupportSessionChannelListResponse = {
  home: {
    name: string;
    desc: string;
    support_session_number: number;
    has_in_queue_session: boolean;
  };
  private: {
    id: 0;
    name: string;
    desc: string;
    support_session_number: number;
    has_in_queue_session: boolean;
  };
  channels: Array<{
    id: number;
    name: string;
    desc: string;
    default: boolean;
    status: 0 | 1;
    priority: number;
    support_session_number: number;
    has_in_queue_session: boolean;
    card: boolean;
    psa: boolean;
    user_permission: number;
  }>;
};

export type SupportSessionChannelDetailResponse = {
  id: number;
  name: string;
  status: number;
  desc: string;
  technicians: Array<{
    id: number;
    email: string;
    group: string;
    user_permission: number;
    status: 0 | 1;
  }>;
};

export type ChannelItem = {
  id: number;
  name: string;
  status: 0 | 1;
  priority: number;
  default: boolean;
  desc: string;
  user_permission: number;
};

export type SupportSessionListResponse = {
  channels: Array<ChannelItem>;
  support_sessions: Array<SupportSessionItem>;
};

export type SupportSessionLogListResponseSupportSessionItem = Omit<SupportSessionItem, 'status'> & {
  ip_address: string | null;
  commit: string | null;
  questionnaire: Questionnaire | null;
  channel_name: string | undefined;
  source: number | undefined;
  technician_email: string | undefined;
  acquired_permission_string: string | undefined;
  server_os_name: string | undefined;
  customer_name: string;
  customer_issue: string;
  comment: string | undefined;
  status: 'open' | 'closed' | 'deleted' | 'expired';
};
export type SupportSessionLogListResponseSupportSessionItemList = Array<SupportSessionLogListResponseSupportSessionItem>;

export type SupportSessionLogListResponse = {
  channels: Array<ChannelItem>;
  support_sessions: SupportSessionLogListResponseSupportSessionItemList;
};

export const SupportSessionCategoryTypes = {
  General: 37,
  CallingCard: 38,
  Integration: 40,
  SupportForm: 43,
} as const;

type SupportSessionCategoryTypeKeys = keyof typeof SupportSessionCategoryTypes;
export type SupportSessionCategoryTypeValues = (typeof SupportSessionCategoryTypes)[SupportSessionCategoryTypeKeys];

export const SUPPORT_SESSION_KIND = {
  general: 'general', // Support link and & Pin code
  calling_card: 'calling_card', // SOS call
  psa: 'psa', // Third parity integration
  webform: 'webform', // Web support form
} as const;

export const supportSessionKindSchema = z.union([
  z.literal(SUPPORT_SESSION_KIND.general),
  z.literal(SUPPORT_SESSION_KIND.calling_card),
  z.literal(SUPPORT_SESSION_KIND.psa),
  z.literal(SUPPORT_SESSION_KIND.webform),
]);
export type SupportSessionKind = z.infer<typeof supportSessionKindSchema>;

/**
 * Sometimes API returns the raw data that support session kind will not mapping to string.
 */
export const SUPPORT_SESSION_KIND_IN_NUMBER = {
  general: 1, // Support link and & Pin code
  calling_card: 2, // SOS call
  psa: 3, // Third parity integration
  webform: 4, // Web support form
} as const;

export const supportSessionKindInNumberEnum = z.nativeEnum(SUPPORT_SESSION_KIND_IN_NUMBER);

export type SupportSessionKindInNumber = z.infer<typeof supportSessionKindInNumberEnum>;

export type SupportSessionItem = {
  id: number;
  channel_id: number;
  kind: SupportSessionKind;
  category: SupportSessionCategoryTypeValues;
  code: string;
  name: string;
  priority: 3;
  expires_at: string | null;
  status: 'open' | 'closed' | 'deleted' | 'postponed' | 'expired';
  status_since: string | null;
  assignee_id: number;
  assignee_email: string | null;
  assigned_at: string | null;
  assistant_1_id: number;
  assistant_1_email: string | null;
  assistant_1_mode: number;
  assistant_2_id: number;
  assistant_2_email: string | null;
  assistant_2_mode: number;
  server_id: number;
  server_name: string | null;
  server_os: number | null;
  associated_at: string | null;
  acquired_permission: number;
  unattended_until: string | null;
  requesting_permission_id: number | null;
  online_since: string | null;
  os_logon_now: boolean | null;
  offline_since: string | null;
  connected_since: string | null;
  card_name: string | null;
  source_name: string | null;
  created_by: string;
  created_at: string;
  updated_at: string;
  support_portal_link: string;
  unsolved_events: number;
  server_feature_capability_hex: string | null;
};

export type CreateSupportSessionRequestBody = {
  channelId: number;
  kind?: string;
  name?: string;
  assigneeId?: number;
  priority?: number;
  expiresAt?: string;
};

export type MutateSupportSessionResponse = {
  action: string;
  support_session: SupportSessionItem;
};

export type ShowSupportSessionResponse = {
  id: number;
  channel_id: number;
  kind: 'general' | 'calling_card' | 'psa' | 'webform';
  category: number;
  code: string;
  name: string;
  priority: number;
  expires_at: string | null;
  status: 'open' | 'closed' | 'deleted' | 'postponed' | 'expired';
  status_since: string | null;
  assignee_id: number;
  assignee_email: string | null;
  assigned_at: string | null;
  assistant_1_id: number;
  assistant_1_email: string | null;
  assistant_1_mode: number;
  assistant_2_id: number;
  assistant_2_email: string | null;
  assistant_2_mode: number;
  server_id: number;
  associated_at: string | null;
  acquired_permission: number;
  unattended_until: string | null;
  requesting_permission_id: number | null;
  comment: string | null;
  card_name: string | null;
  source_name: string | null;
  customer_name: string | null;
  customer_issue: string | null;
  questionnaire: Array<{
    order: number;
    question: string;
    answer: string;
  }>;
  created_by: string;
  created_at: string;
  updated_at: string;
  unsolved_events: number;
  server_feature_capability_hex: string | null;
};

export type ShowSupportSessionServerResponse = {
  id: number;
  name: string;
  device_name: string;
  os: number;
  os_version: string;
  public_ip: string;
  associated_at: string;
  acquired_permission: number;
  unattended_until: string | null;
  online_since: string | null;
  os_logon_now: boolean;
  idle_time: number;
  offline_since: string | null;
  current_sessions: {
    remote: Array<{
      connected_by: string;
      connected_from: string;
      connected_at: string;
    }>;
    file: null;
    chat: null;
    cmpt: null;
    diagnosis: null;
  };
  last_sessions: {
    remote: {
      connected_by: string;
      connected_from: string;
      connected_at: string;
      duration: number;
    };
    file: null;
    chat: null;
    cmpt: null;
    diagnosis: null;
  };
};

export type SupportSessionHistoryRequestBody = {
  startTime?: string;
  endTime?: string;
  perPageSize?: number;
  page?: number;
  order?: 'desc' | 'asc';
};

export type SupportSessionHistoryResponse = {
  support_session_id: number;
  start_time: string;
  end_time: string;
  total: number;
  per_page_size: number;
  page: number;
  order: 'desc' | 'asc';
  histories: Array<{
    id: number;
    datetime: string;
    desc: string;
  }>;
};

export type SupportSessionSessionNoteResponse = {
  subject: string;
  notes: string;
};

export type SupportSessionSessionNoteRequestBody = {
  subject: string;
  notes: string;
};

export type SupportSessionLogQueryOptions = {
  startTime?: string;
  endTime?: string;
  perPageSize?: number;
  page?: number;
  service_kind: string;
  order?: 'desc' | 'asc';
};

export type SupportSessionFileLogResponse = {
  support_session_id: number;
  start_time: string;
  end_time: string;
  total: number;
  per_page_size: number;
  page: number;
  order: 'desc' | 'asc';
  file_logs: Array<{
    created_at: string;
    name: string;
    file_size: string;
    transferred_size: string;
    original_file_size: number;
    original_transferred_size: number;
    action: 'upload' | 'download';
    result: 'complete' | 'abort' | 'other';
  }>;
};

export type SupportSessionChatFileResponse = {
  download_url: string;
};

export type SessionLog = {
  id: number;
  service_kind: string; // 'attended_remote' | ''
  status: 'active' | 'being_disconnected' | 'closed';
  created_at: string;
  duration: number; // seconds
  src_user_email: string;
  src_name: string;
  client_ip_addr: string;
  srs_name: string;
  server_ip_addr: string;
  has_file_list: boolean;
  has_chat_list: boolean;
  has_command_list: boolean;
  has_voice_call_list: boolean;
  note: string; // The first 30 characters of the full notes..
};

export type SessionLogsInSupportSessionResponse = {
  support_session_id: number;
  start_time: string;
  end_time: string;
  total: number;
  per_page_size: number;
  page: number;
  order: 'desc' | 'asc';
  session_logs: Array<{
    id: number;
    service_kind: string; // 'attended_remote' | ''
    status: 'active' | 'being_disconnected' | 'closed';
    created_at: string;
    duration: number; // seconds
    src_user_email: string;
    src_name: string;
    srs_owner_name: string;
    client_ip_addr: string;
    srs_name: string;
    server_ip_addr: string;
    has_file_list: boolean;
    has_chat_list: boolean;
    has_command_list: boolean;
    has_voice_call_list: boolean;
    has_premium_tool_list: boolean;
    note: string; // The first 30 characters of the full notes..
  }>;
};

export type SessionVoiceCallDetailItem = {
  dial_in: string;
  hang_up: string;
};

export type UpdateSupportSessionStatusKind = 'take' | 'hold' | 'unhold' | 'release' | 'close' | 'delete';

export type ServiceDeskCloudBuildURLResponse = {
  can_build: boolean;
  cache_url: string;
  cache_ok_url: string;
  backup_url: string;
  category: number;
};

export type RequestSupportSessionPermissionResponse = {
  request_permission_id: number;
  polling_mode: boolean;
  polling_interval: number;
  polling_times: number;
};

export type CheckSupportSessionPermissionResponse = {
  request_permission_id: number;
  support_session_id: number;
  request_permission: number;
  request_unattended_until: string;
  requested_by: string;
  requested_at: string;
  result: -1 | 0 | 1 | 2;
  grant_permission: number;
  grant_unattended_until: string | null;
  granted_at: string | null;
};
