import { z } from 'zod';

import type { RippleComputerDeviceIconProps } from '@/design';
import { WindowsUpdatesStatusInfo, WindowsUpdatesStatusInfoValues } from '@/modules/WindowsUpdates/utils';
import type { ComputerAntivirusStatus } from '@/services/computers';
import type { OS } from '@/services/computers/types';

const rule = /SRS\/(\d+\.\d+\.\d+\.\d+|\d+\.\d+\.\d+) splashtop2 (\S+)\s?(RDP|VNC|RDS|SSH)?/i;
const osTypeMatchable = ['win', 'mac', 'linux', 'android', 'pc', 'iPhone', 'iPad', 'iOS'];

type FullStreamerVersion = string | null | undefined;

export type OSType = RippleComputerDeviceIconProps['type'];

export function getOSType(streamerVersion: FullStreamerVersion): {
  type: OS;
  isRDP: boolean;
  isRDS: boolean;
  isVNC: boolean;
  isSSH: boolean;
} {
  const [_source, _version, OSType = 'pc', postfix] = rule.exec(streamerVersion ?? '') ?? [];

  return {
    type: osTypeMatchable.includes(OSType) ? (OSType as OS) : 'pc',
    isRDP: postfix === 'RDP',
    isRDS: postfix === 'RDS',
    isVNC: postfix === 'VNC',
    isSSH: postfix === 'SSH',
  };
}

export function getOSIconType(streamerVersion: FullStreamerVersion): OSType {
  const { type, isRDP, isRDS, isVNC, isSSH } = getOSType(streamerVersion);
  if (isRDP) return 'rdp';
  if (isRDS) return 'rds';
  if (isVNC) return 'vnc';
  if (isSSH) return 'ssh';

  return type;
}

/**
 * The elements should align to `OSType`
 */
const OSTypeList = ['win', 'mac', 'linux', 'android', 'pc', 'rdp', 'rds', 'vnc', 'ssh', 'ios', 'fallback'] as const;
export const OSTypeSchema = z.enum(OSTypeList);
export const OSTypeEnum = OSTypeSchema.enum;
export const OSNameMap: Record<OSType, string> = {
  win: 'Windows',
  mac: 'Mac',
  linux: 'Linux',
  android: 'Android',
  pc: 'Others',
  rdp: 'RDP',
  vnc: 'VNC',
  rds: 'RDS',
  ssh: 'SSH',
  ios: 'iOS',
  fallback: 'fallback',
};

const OSTypeWeight: Record<OSType, number> = {
  win: 9,
  vnc: 8,
  ssh: 7,
  rds: 6,
  rdp: 5,
  mac: 4,
  linux: 3,
  ios: 2,
  android: 1,

  fallback: -1,
  pc: -1,
};
export function sortOSType(a: OSType, b: OSType): number {
  const aWeight = OSTypeWeight[a];
  const bWeight = OSTypeWeight[b];

  if (aWeight > bWeight) return -1;
  else if (aWeight < bWeight) return 1;
  return 0;
}

export function getStreamerVersion(fullStreamerVersion: FullStreamerVersion): string {
  const [_source, version] = rule.exec(fullStreamerVersion ?? '') ?? ['', ''];
  return version;
}

export function computeWindowsUpdateStatusInfoType(
  policy: string | null,
  status: string | null,
  important: number | null,
  optional: number | null,
): WindowsUpdatesStatusInfoValues | null {
  let statusInfoType: WindowsUpdatesStatusInfoValues | null = null;
  if (policy === 'never_check') {
    statusInfoType = WindowsUpdatesStatusInfo.disabled;
  } else if (status === 'updating') {
    statusInfoType = WindowsUpdatesStatusInfo.updating;
  } else if (status === 'installed_wait_restart') {
    statusInfoType = WindowsUpdatesStatusInfo.installedWaitRestart;
  } else if (important && important > 0) {
    statusInfoType = WindowsUpdatesStatusInfo.importantUpdates;
  } else if (optional && optional > 0) {
    statusInfoType = WindowsUpdatesStatusInfo.optionalUpdates;
  } else if (important === 0 && optional === 0) {
    statusInfoType = WindowsUpdatesStatusInfo.noUpdates;
  }
  return statusInfoType;
}

const AntivirusStateWeight: Record<NonNullable<ComputerAntivirusStatus>, number> = {
  has_threats: 5,
  no_protection: 5,
  expired: 4,
  no_software: 3,
  not_support_antivirus_version: 3,
  good: 2,
  computer_not_support: 1,
};
export function sortAntivirusState(a: ComputerAntivirusStatus | undefined, b: ComputerAntivirusStatus | undefined): number {
  const aWeight = a ? AntivirusStateWeight[a] : 0;
  const bWeight = b ? AntivirusStateWeight[b] : 0;

  if (aWeight > bWeight) return -1;
  else if (aWeight < bWeight) return 1;
  return 0;
}
