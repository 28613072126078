export const NoPermission = /*           */ 0b0000000000000000000000000000000;
export const Manage = /*                 */ 0b0000000000000000000000000000001;
export const Create = /*                 */ 0b0000000000000000000000000000010;
export const Take = /*                   */ 0b0000000000000000000000000000100;
export const Transfer = /*               */ 0b0000000000000000000000000001000;
export const Release = /*                */ 0b0000000000000000000000000010000;
export const Invite = /*                 */ 0b0000000000000000000000000100000;
export const Close = /*                  */ 0b0000000000000000000000001000000;
export const Delete = /*                 */ 0b0000000000000000000000010000000;
export const Comment = /*                */ 0b0000000000000000000000100000000;
export const Card = /*                   */ 0b0000000000000000000001000000000;
export const SessionRecording = /*       */ 0b0000000000000001000000000000000;
export const Remote = /*                 */ 0b0000000000000010000000000000000;
export const File = /*                   */ 0b0000000000000100000000000000000;
export const Chat = /*                   */ 0b0000000000001000000000000000000;
export const Command = /*                */ 0b0000000000010000000000000000000;
export const Diagnose = /*               */ 0b0000000000100000000000000000000;
export const BackgroundActions = /*      */ 0b0000001111000000000000000000000;
export const DefaultPermission = /*      */ 0b0000000000000010000000001000000;
export const AllPermission = /*          */ 0b0000001111000010000001111111111;
