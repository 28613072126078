import { Box, VStack } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';

import { RippleSkeleton, RippleTypography } from '@/design';

import { UserListLoadingTable, UserListTable } from './UserListTable';
import { selectedUserCountAtom } from './atoms';
import { useIsFetchingSharedMode, useTeamMemberListQuery } from './hooks';

export function UserList(): React.JSX.Element {
  const { t } = useTranslation();

  const teamMemberListQuery = useTeamMemberListQuery();
  const selectedCount = useAtomValue(selectedUserCountAtom);

  const isFetchingSharedMode = useIsFetchingSharedMode();

  const isFetching = teamMemberListQuery.isFetching || isFetchingSharedMode;

  return (
    <VStack alignItems="stretch">
      <Box>
        {isFetching ? (
          <RippleSkeleton w="128px" />
        ) : (
          <RippleTypography variant="body02">{t('computer:selected_user_count', { count: selectedCount })}</RippleTypography>
        )}
      </Box>
      {isFetching ? <UserListLoadingTable /> : <UserListTable />}
    </VStack>
  );
}
