import { Box } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleAdd, RippleMenu, RippleMenuIconButton, RippleMenuItem, RippleMenuList, RippleTooltip } from '@/design';
import { AddFieldKindValues } from '@/modules/ServiceDesk/SupportForm/Editor/types';
import { QuestionnaireFieldKind } from '@/services/serviceDesk/supportForm/types';

/**
 * Add Field Button needs to be sticky and stay in flex box model at the same time,
 * set negative margin value to adjust button position
 */
const FORM_AND_BUTTON_GAP = 16;
export const ADD_FIELD_BUTTON_HEIGHT = 32;

type AddFieldButtonProps = {
  addField: ({ kind }: { kind: AddFieldKindValues }) => void;
  isDisabledAddFieldButton: boolean;
};

export const AddFieldButton = ({ addField, isDisabledAddFieldButton }: AddFieldButtonProps) => {
  const { t } = useTranslation();
  return (
    <Box position="sticky" top="24px" alignSelf="flex-end" mr={`-${FORM_AND_BUTTON_GAP + ADD_FIELD_BUTTON_HEIGHT}px`} zIndex={1}>
      <RippleMenu>
        <RippleTooltip label={isDisabledAddFieldButton ? t('support-forms:maxField') : t('support-forms:addNewField')}>
          <RippleMenuIconButton
            isDisabled={isDisabledAddFieldButton}
            aria-label="add field button"
            icon={<RippleAdd color={isDisabledAddFieldButton ? 'neutral.300' : 'blue.100'} />}
            border="1px solid"
            borderColor={isDisabledAddFieldButton ? 'neutral.300' : 'blue.100'}
            bg="white"
          />
        </RippleTooltip>
        <RippleMenuList>
          <RippleMenuItem onClick={() => addField({ kind: QuestionnaireFieldKind.shortText })}>
            {t('support-forms:shortTextField')}
          </RippleMenuItem>
          <RippleMenuItem onClick={() => addField({ kind: QuestionnaireFieldKind.longText })}>
            {t('support-forms:longTextField')}
          </RippleMenuItem>
          <RippleMenuItem onClick={() => addField({ kind: QuestionnaireFieldKind.comboBox })}>{t('support-forms:comboBox')}</RippleMenuItem>
          <RippleMenuItem onClick={() => addField({ kind: QuestionnaireFieldKind.radioButton })}>
            {t('support-forms:radio_button')}
          </RippleMenuItem>
          <RippleMenuItem onClick={() => addField({ kind: QuestionnaireFieldKind.checkbox })}>
            {t('support-forms:check_box')}
          </RippleMenuItem>
        </RippleMenuList>
      </RippleMenu>
    </Box>
  );
};
