import { useContext } from 'react';

import { Box } from '@chakra-ui/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import { animated, useTransition } from 'react-spring';

import { RippleComputerAccordionButton, RippleIconButton, RippleTooltip, RippleViewToggleGroup, RippleViewToggleList } from '@/design';

import { allExpandedAtom, toggleExpandAllGroupsAtom, toggleViewModeAtom, viewModeAtom } from '../../atoms';
import { ColumnPermissionsContext } from '../../contexts';
import { useActionBarContext } from './hooks';

type ViewSwitchProps = { isDisabled?: boolean };
export function ViewSwitch(props: ViewSwitchProps): React.JSX.Element {
  return (
    <ShowViewSwitch>
      <ViewSwitchCore {...props} />
    </ShowViewSwitch>
  );
}

function ViewSwitchCore({ isDisabled = false }: ViewSwitchProps): React.JSX.Element {
  const { t } = useTranslation();

  const { isLoading } = useActionBarContext();

  const viewMode = useAtomValue(viewModeAtom);
  const toggleViewMode = useSetAtom(toggleViewModeAtom);

  const labelForToggle = viewMode === 'computer' ? t('computer:groupView') : t('computer:listView');

  return (
    <>
      <ShowToggleGroupExpand>
        <ToggleGroupExpand />
      </ShowToggleGroupExpand>
      <RippleTooltip label={labelForToggle} shortcut="Shift + V">
        <RippleIconButton
          data-testid="view-switch"
          aria-label="view switch"
          icon={<ViewModeIcon />}
          onClick={toggleViewMode}
          isDisabled={isDisabled || isLoading}
        />
      </RippleTooltip>
    </>
  );
}

function ViewModeIcon(): React.JSX.Element {
  const viewMode = useAtomValue(viewModeAtom);

  const transitions = useTransition(viewMode, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 300 },
  });

  return (
    <>
      {transitions.map(({ key, item, props }) => (
        <animated.div key={key} style={{ ...props, position: 'absolute' }}>
          {item === 'computer' ? <RippleViewToggleGroup /> : <RippleViewToggleList />}
        </animated.div>
      ))}
    </>
  );
}
type ShowToggleGroupExpandProps = { children?: React.ReactNode };
function ShowToggleGroupExpand({ children }: ShowToggleGroupExpandProps): React.JSX.Element | null {
  const viewMode = useAtomValue(viewModeAtom);

  const transitions = useTransition(viewMode, null, {
    from: { opacity: 0, left: 40 },
    enter: { opacity: 1, left: 0 },
    leave: { opacity: 0, left: 40 },
    config: { duration: 300 },
  });

  return (
    <Box position="relative" boxSize="32px">
      {transitions.map(({ key, item, props }) => (
        <animated.div key={key} style={{ ...props, position: 'absolute' }}>
          {item === 'group' && children}
        </animated.div>
      ))}
    </Box>
  );
}
function ToggleGroupExpand(): React.JSX.Element {
  const isAllExpanded = useAtomValue(allExpandedAtom);
  const toggleExpandAllGroups = useSetAtom(toggleExpandAllGroupsAtom);

  return <RippleComputerAccordionButton showShortcut isEnabled={isAllExpanded} onClick={toggleExpandAllGroups} />;
}

type ShowViewSwitchProps = { children?: React.ReactNode };
export function ShowViewSwitch({ children }: ShowViewSwitchProps): React.JSX.Element | null {
  const columnPermissions = useContext(ColumnPermissionsContext);

  if (columnPermissions.group) return <>{children}</>;

  return null;
}
