import axios from 'axios';

import type { ResponseData, TeamMembersCount } from '@/services/common/types';
import { isSuccess, pureInstance } from '@/services/utils';

import type {
  GetGrantGranularControlResponse,
  GetGranularControlResponse,
  GranularControlKey,
  TeamComputer,
  TeamComputerListWithCustomizedFields,
  TeamComputersCustomizedField,
  TeamDeploymentCloudBuildFileResponse,
  TeamDeploymentPackageResponse,
  TeamDetailResponse,
  TeamMember,
  TeamMemberListItem,
  TeamMemberListOptions,
  UpdateGrantGranularControlPayload,
  UpdateGranularControlPayload,
} from './types';

export { getTeamMemberList } from './getTeamMemberList';

export * from './services';

export function getTeamDetail(teamId: number) {
  return new Promise<TeamDetailResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: TeamDetailResponse }>(`/api/web/v1/teams/${teamId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

/**
 * @deprecated Use `getTeamMemberList` instead
 */
export function getTeamMemberListSimplified(teamId: number, options: Pick<TeamMemberListOptions, 'status' | 'roles' | 'ids'> = {}) {
  return new Promise<Array<TeamMemberListItem>>((resolve, reject) => {
    axios
      .get<
        ResponseData & {
          data: Array<TeamMemberListItem>;
        }
      >(`/api/web/v1/teams/${teamId}/team_members?mode=simple`, {
        params: {
          status: options.status ? options.status.join(',') : 'invited,enabled,disabled',
          roles: options.roles ? options.roles.join(',') : '',
          ids: options.ids ? options.ids.join(',') : '',
        },
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

/**
 * @deprecated Use `getTeamMemberList` instead
 */
export function getTeamMemberListWithGroups(teamId: number, options: Pick<TeamMemberListOptions, 'status' | 'roles' | 'ids'> = {}) {
  type Item = Pick<
    TeamMember,
    'id' | 'email' | 'member_name' | 'role' | 'group_id' | 'group_scope' | 'in_charge_group_ids' | 'super_admin'
  >;

  return new Promise<Array<Item>>((resolve, reject) => {
    axios
      .get<
        ResponseData & {
          data: Array<Item>;
        }
      >(`/api/web/v1/teams/${teamId}/team_members?mode=customize&customized_fields=${'group_id,role,group_scope,super_admin'}`, {
        params: {
          status: options.status ? options.status.join(',') : 'invited,enabled,disabled',
          roles: options.roles ? options.roles.join(',') : '',
          ids: options.ids ? options.ids.join(',') : '',
        },
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getTeamMembersCount(teamId: number) {
  return new Promise<TeamMembersCount>((resolve, reject) => {
    axios
      .get<ResponseData & { data: TeamMembersCount }>(`/api/web/v1/teams/${teamId}/team_members_count`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getTeamMemberGroup(teamId: number) {
  return new Promise<
    Array<{
      id: number;
      name: string;
      members_count: number;
    }>
  >((resolve, reject) => {
    axios
      .get<
        ResponseData & {
          data: Array<{
            id: number;
            name: string;
            members_count: number;
          }>;
        }
      >(`/api/web/v1/teams/${teamId}/groups?members_count=true&member_status=invited,enabled,disabled&group_type=member`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getTeamComputerList(teamId: number) {
  return new Promise<
    Array<{
      id: number;
      name: string;
      email: string | null;
    }>
  >((resolve, reject) => {
    axios
      .get<
        ResponseData & {
          data: Array<{
            id: number;
            name: string;
            email: string | null;
          }>;
        }
      >(`/api/web/v1/teams/${teamId}/computers?mode=simple`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getTeamComputerListWithGroups(
  teamId: number,
  options?: {
    ids?: Array<number>;
    customizedFields?: Array<TeamComputersCustomizedField>;
  },
) {
  const params = new URLSearchParams({ mode: 'group' });
  if (options) {
    if (Array.isArray(options.ids) && options.ids.length > 0) {
      params.append('ids', options.ids.join(','));
    }

    if (Array.isArray(options.customizedFields) && options.customizedFields.length > 0) {
      params.set('mode', 'customize');
      params.append('customized_fields', options.customizedFields.join(','));
    }
  }

  return new Promise<Array<TeamComputer>>((resolve, reject) => {
    axios
      .get<
        ResponseData & {
          data: Array<TeamComputer>;
        }
      >(`/api/web/v1/teams/${teamId}/computers`, {
        params,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getTeamComputerListWitCustomizeFields(teamId: number, options: { canGetNote: boolean }) {
  const customizedFields = [
    'email',
    'group_id',
    'last_online',
    'pubip',
    'local_ip',
    'version',
    'last_session',
    'online_status',
    'is_device_owner',
    'deployed',
  ];

  if (options.canGetNote) {
    customizedFields.push('note');
  }

  return new Promise<Array<TeamComputerListWithCustomizedFields>>((resolve, reject) => {
    axios
      .get<
        ResponseData & {
          data: Array<TeamComputerListWithCustomizedFields>;
        }
      >(`/api/web/v1/teams/${teamId}/computers`, {
        params: {
          mode: 'customize',
          customized_fields: customizedFields.join(','),
        },
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export const leaveTeam = (teamId: number, memberId: number) => {
  return new Promise<boolean>((resolve, reject) => {
    axios
      .post<ResponseData & { data: { status: boolean } }>(`/api/web/v1/teams/${teamId}/team_members/${memberId}/quit`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data.status);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

export const getTeamComputersCount = (teamId: number) => {
  return new Promise<number>((resolve, reject) => {
    axios
      .get<ResponseData & { data: { total: number } }>(`/api/web/v1/teams/${teamId}/computers/total_count`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data.total);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

export const getTeamClientsCount = (teamId: number) => {
  return new Promise<number>((resolve, reject) => {
    axios
      .get<ResponseData & { data: { total: number } }>(`/api/web/v1/teams/${teamId}/clients/total_count`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data.total);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

export const getTeamDeploymentPackages = (teamId: number) => {
  return new Promise<Array<TeamDeploymentPackageResponse>>((resolve, reject) => {
    axios
      .get<ResponseData & { data: Array<TeamDeploymentPackageResponse> }>(`/api/web/v1/teams/${teamId}/team_deployments`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

//  mac win msi android
export const getTeamDeploymentCloudBuildFile = (dCode: string, category: string) => {
  return new Promise<TeamDeploymentCloudBuildFileResponse>((resolve, reject) => {
    pureInstance
      .post<ResponseData & { data: TeamDeploymentCloudBuildFileResponse }>(`/api/web/v1/teams/team_deployments/${dCode}/cloud_build_file`, {
        category,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

/**
 * @deprecated Use `getTeamGranularControlService` instead
 */
export function getTeamGranularControl(teamId: number, features: Array<GranularControlKey>) {
  return new Promise<GetGranularControlResponse>((resolve, reject) => {
    axios
      .get<
        ResponseData & {
          data: GetGranularControlResponse;
        }
      >(`/api/web/v1/teams/${teamId}/granular_controls`, {
        params: {
          controls: features.join(','),
        },
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

/**
 * @deprecated Use `updateTeamGranularControlService` instead
 */
export function updateTeamGranularControl(teamId: number, payload: UpdateGranularControlPayload) {
  return new Promise<void>((resolve, reject) => {
    axios
      .patch<ResponseData>(`/api/web/v1/teams/${teamId}/granular_controls`, payload)
      .then((res) => {
        const {
          data: { result, messages },
        } = res;
        if (isSuccess(result)) {
          resolve();
        } else {
          reject({ statusCode: result, messages, errorReason: {} });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

/**
 * @deprecated Use `getTeamGrantGranularControlService` instead
 */
export function getTeamGrantGranularControl(teamId: number, features: Array<GranularControlKey>) {
  return new Promise<GetGrantGranularControlResponse>((resolve, reject) => {
    axios
      .get<
        ResponseData & {
          data: GetGrantGranularControlResponse;
        }
      >(`/api/web/v1/teams/${teamId}/grant_granular_controls`, {
        params: {
          controls: features.join(','),
        },
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

/**
 * @deprecated Use `updateTeamGrantGranularControlService` instead
 */
export function updateTeamGrantGranularControl(teamId: number, payload: UpdateGrantGranularControlPayload) {
  return new Promise<void>((resolve, reject) => {
    axios
      .patch<ResponseData>(`/api/web/v1/teams/${teamId}/grant_granular_controls`, payload)
      .then((res) => {
        const {
          data: { result, messages },
        } = res;
        if (isSuccess(result)) {
          resolve();
        } else {
          reject({ statusCode: result, messages, errorReason: {} });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}
