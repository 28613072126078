import React from 'react';

import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { Trans, useTranslation } from 'next-i18next';

import {
  RippleBodyText02,
  RippleButton,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalTitle,
  RippleStrong,
} from '@/design';

export const emailVerificationModalAtom = atomWithReset<{ isOpen: boolean; email: string | null }>({ isOpen: false, email: null });

export function useEmailVerificationModal() {
  const set = useSetAtom(emailVerificationModalAtom);

  return {
    open: ({ email }: { email: string }) => {
      set({ isOpen: true, email });
    },
  };
}

export const EmailVerificationModal = () => {
  const { t } = useTranslation();

  const { isOpen, email } = useAtomValue(emailVerificationModalAtom);
  const resetModal = useResetAtom(emailVerificationModalAtom);

  return (
    <RippleModal isOpen={isOpen} onClose={handleClose}>
      <RippleModalOverlay />
      <RippleModalContent borderRadius="4px" minWidth="560px">
        <RippleModalHeader>
          <RippleModalTitle>{t('computer:connectToStreamer.emailVerificationModal.title')}</RippleModalTitle>
        </RippleModalHeader>
        <RippleModalBody flexBasis="auto">
          <RippleBodyText02>
            <Trans
              t={t}
              i18nKey="computer:connectToStreamer.emailVerificationModal.content"
              components={{ Strong: <RippleStrong as="span" variant="strong02" /> }}
              values={{ email }}
            />
          </RippleBodyText02>
        </RippleModalBody>

        <RippleModalFooter>
          <RippleButton data-testid="ok-button" variant="primary" size="sm" onClick={handleClose}>
            {t('common:ok')}
          </RippleButton>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );

  function handleClose() {
    resetModal();
  }
};
