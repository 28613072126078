import { useCallback } from 'react';

import { useTranslation } from 'next-i18next';

import { useRippleFlashMessage } from '@/design';

/**
 * Usage: Trigger success message on launch SRC success then close the modal
 */
export function useLaunchSRCSuccessMessage() {
  const { t } = useTranslation();
  const { flashMessage } = useRippleFlashMessage();

  return useCallback(() => {
    flashMessage({ variant: 'success', title: t('common:launchSRC.success.title') });
  }, [t, flashMessage]);
}
