import { add, isBefore } from 'date-fns';

// Hide the banner after 60 days on BD2 expired to avoid disturbing the customer
export const isInExpiredBannerDisplayPeriod = (endTime: string): boolean => {
  const today = new Date();
  const hideAfterDays = 60;
  const bannerDisplayEndDate = add(new Date(`${endTime} UTC`), { days: hideAfterDays });

  return isBefore(today, bannerDisplayEndDate);
};
