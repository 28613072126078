import { Flex, HStack } from '@chakra-ui/react';

import { ActionBarContext } from './contexts';

type ActionBarProps = {
  isLoading: boolean;
  children?: React.ReactNode;
};
export function ActionBar({ isLoading, children }: ActionBarProps): React.JSX.Element {
  return (
    <ActionBarContext.Provider value={{ isLoading }}>
      <Flex bgColor="neutral.10" justifyContent="space-between" mb="8px" py="8px" borderBottom="1px solid" borderBottomColor="neutral.60">
        {children}
      </Flex>
    </ActionBarContext.Provider>
  );
}

type ActionBarLeftSectionProps = { children?: React.ReactNode };
export function ActionBarLeftSection({ children }: ActionBarLeftSectionProps): React.JSX.Element {
  return <HStack spacing="8px">{children}</HStack>;
}

type ActionBarRightSectionProps = { children?: React.ReactNode };
export function ActionBarRightSection({ children }: ActionBarRightSectionProps): React.JSX.Element {
  return <HStack spacing="8px">{children}</HStack>;
}
