import { useQuery } from '@tanstack/react-query';

import { useTeamInfoForSupportSessionQuery } from '@/modules/ServiceDesk/SupportSession/useTeamInfoForSupportSession';
import { Release } from '@/modules/ServiceDesk/shared/userPermissionBitmap';
import { getSupportSessionChannelDetail } from '@/services/serviceDesk/supportSession';

/**
 * Share same logic in TransferSupportSessionModal
 * ref: src/modules/ServiceDesk/SupportSession/modals/TransferSupportSessionModal.tsx
 * */
export const useSupportSessionChannelTechnicians = ({
  channelId,
  assignedTechnicianId,
}: {
  channelId: number;
  assignedTechnicianId?: number;
}) => {
  const teamInfoQuery = useTeamInfoForSupportSessionQuery();

  const teamInfo = teamInfoQuery.data;

  const { data: channelDetail } = useQuery({
    queryKey: ['channelDetail', teamInfo?.teamId, channelId],
    queryFn: () => {
      if (typeof teamInfo === 'undefined') {
        throw new Error('Dependency not passed to useSupportSessionChannelTechnicians hook.');
      }
      return getSupportSessionChannelDetail(teamInfo.teamId, channelId);
    },
    enabled: typeof teamInfo !== 'undefined' && channelId !== null && teamInfo.teamId !== -1,
  });

  if (!teamInfo) {
    return {
      isShowUnassignedOption: false,
      currentTechnicianOption: null,
      technicianOptions: [],
    };
  }

  const currentTechnician = channelDetail?.technicians.find(({ id }) => id === teamInfo.memberId);
  const currentTechnicianOption =
    currentTechnician === undefined ? null : { value: teamInfo.memberId.toString(), label: currentTechnician.email };

  const technicianOptions = channelDetail
    ? channelDetail.technicians
        .filter(({ id, status }) => id !== teamInfo.memberId && (status !== 0 || id === assignedTechnicianId))
        .map(({ id, email }) => ({ value: id.toString(), label: email }))
    : [];

  return {
    isShowUnassignedOption: currentTechnician?.user_permission && Release,
    currentTechnicianOption,
    technicianOptions,
  };
};
