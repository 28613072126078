import { Flex } from '@chakra-ui/react';

import { RippleBodyText02 } from '@/design';

export const TextWithActionBtn = ({ intro, children }: { intro: string; children: React.ReactNode }) => {
  return (
    <Flex px="16px" py="24px" direction="column" justify="center" align="center">
      <RippleBodyText02 mb={4} align="center" color="dark.100">
        {intro}
      </RippleBodyText02>
      {children}
    </Flex>
  );
};
