import { baseColumnIdMap, baseColumnWidthMap } from '../ComputerList';

export const columnIdMap = {
  ...baseColumnIdMap,
  host_name: 'host_name',
  computerOwner: 'computerOwner',
  group_id: 'group_id',
  groupName: 'groupName',
  pubip: 'pubip',
  local_ip: 'local_ip',
  note: 'note',
} as const;

export const columnWidthMap = {
  ...baseColumnWidthMap,
  [columnIdMap.name]: 384,
  [columnIdMap.host_name]: 288,
  [columnIdMap.computerOwner]: 272,
  [columnIdMap.group_id]: 0,
  [columnIdMap.groupName]: 160,
  [columnIdMap.pubip]: 352,
  [columnIdMap.local_ip]: 352,
  [columnIdMap.note]: 160,
} as const;
