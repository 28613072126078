import { CSSProperties, createContext, useContext, useMemo } from 'react';

import { Box } from '@chakra-ui/react';
import type { BoxProps, IconButtonProps } from '@chakra-ui/react';
import {
  DndContext,
  DraggableSyntheticListeners,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import type { DragEndEvent } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { RippleGripDots, RippleIconButton } from '@/design';

/**
 * Usage: Wrap the list to enable drag and drop functionality
 */
type DragListWrapperProps = {
  children: React.JSX.Element;
  items: Array<
    | UniqueIdentifier
    | {
        id: UniqueIdentifier;
      }
  >;
  onDragEnd: (event: DragEndEvent) => void;
};

export const DragListWrapper = ({ children, items, onDragEnd }: DragListWrapperProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
};

/**
 * Usage: Wrap the list item which can be dragged
 */
const DragWrapperContext = createContext<{
  attributes: Record<string, any>;
  disableDrag?: boolean;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
}>({
  attributes: {},
  disableDrag: false,
  listeners: undefined,
  ref: () => null,
});

type DragWrapperProps = {
  id: UniqueIdentifier;
  disableDrag?: boolean;
  style?: BoxProps;
  children: React.JSX.Element;
};

export const DragWrapper = ({ id, disableDrag, style, children }: DragWrapperProps) => {
  const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id,
    disabled: disableDrag,
  });

  const context = useMemo(
    () => ({
      attributes,
      disableDrag,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, disableDrag, listeners, setActivatorNodeRef],
  );

  const translateStyle: CSSProperties = {
    borderRadius: '4px',
    boxShadow: isDragging ? '0px 1px 8px 1.600000023841858px rgba(0, 0, 0, 0.08), 0px 4px 4px 1px rgba(0, 0, 0, 0.08)' : 'none',
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <DragWrapperContext.Provider value={context}>
      <Box
        w="100%"
        ref={setNodeRef}
        {...style}
        _hover={isDragging ? { bg: 'white' } : style?._hover}
        sx={translateStyle}
        zIndex={isDragging ? 1 : 0}
        bg="white"
      >
        {children}
      </Box>
    </DragWrapperContext.Provider>
  );
};

/**
 * Usage: The drag target for list item which can be dragged
 */
export const DragButton = ({ style }: { style?: Partial<IconButtonProps> }) => {
  const { attributes, disableDrag, listeners, ref } = useContext(DragWrapperContext);

  return (
    <RippleIconButton
      opacity={0}
      cursor={disableDrag ? 'auto' : 'pointer'}
      _groupHover={{ opacity: disableDrag ? 0 : 1 }}
      position="absolute"
      left="2px"
      top="50%"
      transform="translateY(-50%)"
      width="32px"
      height="32px"
      {...style}
      // Below are styles should not be modified
      aria-label="drag"
      icon={<RippleGripDots color="neutral.300" />}
      {...attributes}
      {...listeners}
      ref={ref}
    />
  );
};
