import { useEffect, useState } from 'react';

import { forwardRef } from '@chakra-ui/react';

import { RippleBodyText02, RippleComputerCellProps, RippleTdLegacy } from '@/design';

type CellContainerProps = RippleComputerCellProps;
export const CellContainer = forwardRef<CellContainerProps, 'div'>(
  ({ children, ...otherProps }: CellContainerProps, ref): React.JSX.Element => {
    return (
      <RippleTdLegacy
        ref={ref}
        data-cell-type="normal"
        bg="white"
        py="8px"
        borderBottom="1px solid"
        borderBottomColor="neutral.60"
        zIndex={1}
        h="58px"
        {...otherProps}
      >
        <DelayRender>
          <RippleBodyText02 as="div" color="dark.90">
            {children}
          </RippleBodyText02>
        </DelayRender>
      </RippleTdLegacy>
    );
  },
);

type DelayRenderProps = { children?: React.ReactNode; timeout?: number };
function DelayRender({ children, timeout = 100 }: DelayRenderProps): React.JSX.Element | null {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!show) {
      const timeoutId = window.setTimeout(() => {
        setShow(true);
      }, timeout);

      return () => {
        window.clearTimeout(timeoutId);
      };
    }
  }, [show, timeout]);

  if (show) return <>{children}</>;
  return null;
}
