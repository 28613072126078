import { ConfirmModal } from './ConfirmModal';
import { SelectKindModal, useSelectKindModal } from './SelectKindModal';

export function useRebootComputers() {
  const selectKindModal = useSelectKindModal();

  return {
    execute: selectKindModal.open,
  };
}

export function RebootComputers(): React.JSX.Element {
  return (
    <>
      <SelectKindModal />
      <ConfirmModal />
    </>
  );
}
