import { ReactElement, ReactNode } from 'react';

import { HStack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleBadge, RippleTypography } from '@/design';
import { TeamMemberStatus } from '@/services/team/types';
import { TeamMemberRoleIcon, TeamMemberRoleTag, getTeamMemberRoleLabelMap } from '@/showcase';

export function UserRoleCell({ roleTag, hasHoverStyle }: { roleTag: TeamMemberRoleTag | null; hasHoverStyle?: boolean }) {
  const { t } = useTranslation();

  if (roleTag === null) {
    return null;
  }

  const labelMap = getTeamMemberRoleLabelMap(t);
  const label = labelMap[roleTag];

  return (
    <HStack spacing="8px">
      <TeamMemberRoleIcon roleTag={roleTag} />
      <RippleTypography
        variant="body02"
        color="dark.100"
        borderStyle="dotted"
        borderBottomWidth="2px"
        borderColor="transparent"
        _hover={
          hasHoverStyle
            ? {
                borderColor: 'blue.100',
              }
            : undefined
        }
      >
        {label}
      </RippleTypography>
    </HStack>
  );
}

const UserEnabledStatus = ({ children }: { children: ReactNode }) => {
  return (
    <RippleBadge variant="secondary" colorScheme="success" color="dark.100" borderColor="dark.20">
      {children}
    </RippleBadge>
  );
};

const UserDisabledStatus = ({ children }: { children: ReactNode }) => {
  return (
    <RippleBadge variant="secondary" colorScheme="success" color="dark.100" borderColor="dark.10" backgroundColor="dark.10">
      {children}
    </RippleBadge>
  );
};

const statusLabelMap: Record<TeamMemberStatus, () => ReactElement> = {
  enabled: function Label() {
    const { t } = useTranslation();

    return <UserEnabledStatus>{t('userManagement:userStatusEnabled')}</UserEnabledStatus>;
  },
  invited: function Label() {
    const { t } = useTranslation();

    return <UserEnabledStatus>{t('userManagement:userStatusInvited')}</UserEnabledStatus>;
  },
  disabled: function Label() {
    const { t } = useTranslation();

    return <UserDisabledStatus>{t('userManagement:userStatusDisabled')}</UserDisabledStatus>;
  },
  declined: function Label() {
    const { t } = useTranslation();

    return <UserDisabledStatus>{t('userManagement:userStatusDeclined')}</UserDisabledStatus>;
  },
  quit: function Label() {
    const { t } = useTranslation();

    return <UserDisabledStatus>{t('userManagement:userStatusQuit')}</UserDisabledStatus>;
  },
};

export function UserStatusCell({ status }: { status: TeamMemberStatus }) {
  const Label = statusLabelMap[status];
  return (
    <HStack spacing="8px">
      <Label />
    </HStack>
  );
}
