import axios from 'axios';
import { z } from 'zod';

import { checkResponse } from '@/services/utils';

const method = 'POST';
const getUrl = () => '/api/web/v1/users/websrc_token';

export const schema = z.object({
  access_token: z.string(),
  access_token_ttl: z.number(),
  backend_src_feature_capability: z.string(),
  category: z.number(), // The value is used by wasm, representing the streamer type
  email: z.string(),
  fqdn: z.string(),
  server: z.object({
    domain: z.array(z.string()),
    force_auth: z.number(),
    name: z.string(),
    require_password: z.boolean(),
    share_token: z.string(),
    team_member_name: z.string(),
    uuid: z.string(),
    version: z.string(),
  }),
  session_asp: z.string().nullable(),
  websrc_uuid: z.string(),
});

const execute = (srsId: string) => checkResponse(axios.request({ method, url: getUrl(), data: { srs_id: srsId } }), schema);

export const getWebSRCSettingsService = {
  method,
  getUrl,
  execute,
};
