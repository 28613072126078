import { MouseEventHandler } from 'react';

import { FormLabel } from '@chakra-ui/react';

import { RippleHeading08 } from '@/design';
import { SupportFormScenario, SupportFormScenarioMap } from '@/modules/ServiceDesk/SupportForm/Editor/types';

type LabelProps = {
  onMouseDown: MouseEventHandler<HTMLLabelElement> | undefined;
  scenario: SupportFormScenario;
  title: string;
};

export function Label({ onMouseDown, scenario, title }: LabelProps) {
  return (
    <FormLabel
      _hover={scenario === SupportFormScenarioMap.pcp ? { bg: 'blue.0', cursor: 'pointer' } : {}}
      borderRadius="4px"
      w="100%"
      pl="2px"
      onMouseDown={onMouseDown}
    >
      <RippleHeading08 as="span">{title}</RippleHeading08>
    </FormLabel>
  );
}
