import { z } from 'zod';

import { SurveyTypeSchema } from '../type';

export const surveySchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  type: SurveyTypeSchema,
  status: z.union([z.literal(0), z.literal(1)]), // 0: suspended, 1: activated
  created_at: z.string(), // utc time
});
export type Survey = z.infer<typeof surveySchema>;

export const surveyListSchema = z.array(surveySchema);
export type SurveyList = z.infer<typeof surveyListSchema>;

export const surveyListResponseSchema = z.object({
  surveys: surveyListSchema,
});
export type SurveyListResponse = z.infer<typeof surveyListResponseSchema>;

export const surveyListErrorSchema = z.object({
  result: z.union([z.literal(40403), z.literal(41403)]),
});
export type SurveyListError = z.infer<typeof surveyListErrorSchema>;
