import { isValid, parse } from 'date-fns';

export const createUtcDate = () => {
  const date = new Date();

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds(),
  );
};

export const isValidateDateTime = (value: any) => {
  if (typeof value !== 'string') {
    return false;
  }

  const parsed = parse(value, 'yyyy-MM-dd HH:mm:ss', new Date());

  return isValid(parsed);
};
