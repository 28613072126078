import { atom } from 'jotai';
import type { PrimitiveAtom } from 'jotai';
import { atomWithImmer } from 'jotai-immer';

import { GROUPING_COLUMN, fixedColumnVisibility } from '../constants';
import type { ColumnVisibility, RowHeight, ViewMode } from '../types';

export const tableSettingAtom = atomWithImmer<{
  viewModeAtom: PrimitiveAtom<ViewMode>;
  rowHeightAtom: PrimitiveAtom<RowHeight>;
  columnVisibilityAtom: PrimitiveAtom<ColumnVisibility>;
}>({
  viewModeAtom: atom<ViewMode>('computer'),
  rowHeightAtom: atom<RowHeight>('large'),
  columnVisibilityAtom: atom<ColumnVisibility>(fixedColumnVisibility),
});

export const columnVisibilityAtom: PrimitiveAtom<ColumnVisibility> = atom(
  (get) => {
    return get(get(tableSettingAtom).columnVisibilityAtom);
  },
  (get, set, update) => {
    set(get(tableSettingAtom).columnVisibilityAtom, update);
  },
);
export const columnVisibilityForGroupModeAtom: PrimitiveAtom<ColumnVisibility> = atom(
  (get) => {
    const originColumnVisibility = get(columnVisibilityAtom);
    return { ...originColumnVisibility, [GROUPING_COLUMN]: false };
  },
  (get, set, update) => {
    set(get(tableSettingAtom).columnVisibilityAtom, update);
  },
);

export const viewModeAtom: PrimitiveAtom<ViewMode> = atom(
  (get) => {
    return get(get(tableSettingAtom).viewModeAtom);
  },
  (get, set, update) => {
    set(get(tableSettingAtom).viewModeAtom, update);
  },
);
export const toggleViewModeAtom = atom(null, (_get, set) => {
  set(viewModeAtom, (viewMode) => {
    switch (viewMode) {
      case 'computer':
        return 'group';
      case 'group':
        return 'computer';
    }
  });
});

export const rowHeightAtom: PrimitiveAtom<RowHeight> = atom(
  (get) => {
    return get(get(tableSettingAtom).rowHeightAtom);
  },
  (get, set, update) => {
    set(get(tableSettingAtom).rowHeightAtom, update);
  },
);
