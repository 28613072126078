import { useEffect } from 'react';

import { atom, useAtom, useAtomValue } from 'jotai';

import { isNewSOSPlus } from '@/utils/team';
import useTeamInformation from '@/utils/useTeamInformation';

type StreamerTypeAtom = 'none' | 'unattended' | 'attended' | 'both';

export const streamerTypeAtom = atom<StreamerTypeAtom>('none');

export const useStreamerType = () => useAtomValue(streamerTypeAtom);

export const useStreamerTypeHandler = () => {
  const teamInformation = useTeamInformation();
  const sosTeamInformation = useTeamInformation('sos');
  const [type, setType] = useAtom(streamerTypeAtom);

  useEffect(() => {
    if (teamInformation?.currentTeam === 'splashtop' || teamInformation?.currentTeam === 'ste_custom') {
      if (teamInformation?.teamMemberPermissions.attendedAccess) {
        setType('both');
        return;
      }
      setType('unattended');
      return;
    }

    if (teamInformation?.currentTeam === 'srs' && (teamInformation?.plan === 'enterprise' || isNewSOSPlus(teamInformation))) {
      if (teamInformation?.teamMemberPermissions.attendedAccess) {
        setType('both');
        return;
      }
      setType('unattended');
      return;
    }

    if (
      teamInformation?.currentTeam === 'sos' ||
      (teamInformation?.currentTeam === 'srs' && teamInformation?.plan === 'ste_lite_sos_basic')
    ) {
      setType('attended');
      return;
    }

    if (
      teamInformation?.currentTeam === 'srs' &&
      (sosTeamInformation?.seatKind === 'trial-commercial-pcs' ||
        (sosTeamInformation?.plan === 'plus' && teamInformation?.plan === 'plus_from_sos') ||
        (sosTeamInformation?.plan === 'teams' && teamInformation?.plan === 'plus_from_sos_teams') ||
        (sosTeamInformation?.plan === 'unlimited' && teamInformation?.plan === 'plus_from_sos_unlimited') ||
        (sosTeamInformation?.plan === 'enterprise' && teamInformation?.plan === 'premium_from_sos'))
    ) {
      setType('both');
      return;
    }

    if (teamInformation?.currentTeam === 'stp') {
      window.location.href = '/';
      return;
    }

    setType('unattended');
  }, [teamInformation, sosTeamInformation, setType]);

  return { type };
};
