import { useAtomValue } from 'jotai';

import { GranularControl } from '../types';
import { useTeamControlContext } from './useTeamControlContext';

/**
 * Get the granular control value map.
 */
export function useFeatureGranularControlMap(): GranularControl {
  const { atoms } = useTeamControlContext();

  return useAtomValue(atoms.granularControlAtom);
}
