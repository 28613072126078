import { Flex, FormLabel } from '@chakra-ui/react';

import { RippleHeading08, RippleInquiries16, RippleTooltip } from '@/design';

type FieldTitleProps = {
  title: string;
  tooltip?: string;
};

export const FieldTitle = ({ title, tooltip }: FieldTitleProps) => {
  return (
    <FormLabel>
      <RippleHeading08 display="inline-flex" color="blue.300">
        {title}
        {tooltip && (
          <RippleTooltip w="224px" label={tooltip}>
            <Flex marginLeft="4px" alignItems="center">
              <RippleInquiries16 color="neutral.300" />
            </Flex>
          </RippleTooltip>
        )}
      </RippleHeading08>
    </FormLabel>
  );
};
