import { useQuery } from '@tanstack/react-query';

import { useAttendedTeamInformation, useUnattendedTeamInformation } from '@/models/TeamInformation';
import { shouldShowServiceDeskChannel } from '@/modules/ServiceDesk';
import { infoQueryService } from '@/services/users/infoQueryService';
import { MemberStatus } from '@/services/users/infoQueryService/team_member_info/types';

type UseTeamInfoForChannels = {
  teamType: 'splashtop' | 'ste_custom' | 'sos' | 'srs' | '';
  teamId: number;
  memberStatus: MemberStatus;
  isExpired: boolean;
  canAccessTeamSetting: boolean;
};
/** Get the necessary team info if user is able to access channels */
export const useTeamInfoForChannelsQuery = () => {
  const attendedTeamInformation = useAttendedTeamInformation();
  const unattendedTeamInformation = useUnattendedTeamInformation();
  return useQuery({
    queryKey: ['info-query', 'channel'],
    queryFn: async () =>
      infoQueryService.execute({
        team_info: ['is_expired'],
        team_permissions: ['sos_service_desk'],
        team_member_info: ['role', 'member_status', 'group_scope'],
        team_member_permissions: ['team_settings_management', 'attended_access', 'technician_manager'],
      }),
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 5 * 60 * 1000, // 5 minutes
    select: (data): UseTeamInfoForChannels => {
      // Handle the abnormal case that if a user has duplicated service desk permission from both sos and srs, we should use sos first
      if (
        data.sos &&
        attendedTeamInformation &&
        shouldShowServiceDeskChannel({
          role: data.sos.team_member_info.role,
          groupScope: data.sos.team_member_info.group_scope,
          memberStatus: data.sos.team_member_info.member_status,
          sosServiceDesk: data.sos.team_permissions.sos_service_desk,
          attendedAccess: data.sos.team_member_permissions.attended_access,
          technicianManager: data.sos.team_member_permissions.technician_manager,
        })
      ) {
        return {
          teamType: 'sos',
          teamId: attendedTeamInformation.team_id,
          memberStatus: data.sos.team_member_info.member_status,
          isExpired: data.sos.team_info.is_expired,
          canAccessTeamSetting: data.sos.team_member_permissions.team_settings_management,
        };
      }
      // Handle the rest case
      return Object.entries(data).reduce(
        (result, [teamType, { team_info, team_member_permissions, team_member_info, team_permissions }]) => {
          if (
            unattendedTeamInformation &&
            shouldShowServiceDeskChannel({
              role: team_member_info.role,
              groupScope: team_member_info.group_scope,
              memberStatus: team_member_info.member_status,
              sosServiceDesk: team_permissions.sos_service_desk,
              attendedAccess: team_member_permissions.attended_access,
              technicianManager: team_member_permissions.technician_manager,
            })
          ) {
            return {
              teamType: teamType as UseTeamInfoForChannels['teamType'],
              teamId: unattendedTeamInformation.team_id,
              memberStatus: team_member_info?.member_status,
              isExpired: team_info.is_expired,
              canAccessTeamSetting: team_member_permissions.team_settings_management,
            };
          }
          return result;
        },
        {
          teamType: '',
          teamId: -1,
          memberStatus: 'disabled',
          isExpired: true,
          canAccessTeamSetting: false,
        } as UseTeamInfoForChannels,
      );
    },
  });
};
