import { HStack } from '@chakra-ui/react';

export type ContainerProps = {
  children: React.ReactNode;
  size: number;
};

export const Container = ({ children, size }: ContainerProps) => {
  const maxWidth = size % 2 === 0 && size % 3 !== 0 ? '1200px' : '1600px';
  // When the quantity is a multiple of 4 and not a multiple of 3, force two by two arrangements.
  // 992 is two 480-width card with 32px gap.

  return (
    <HStack
      spacing={['16px', '32px']}
      maxWidth={maxWidth}
      marginX="auto"
      marginY={['16px', '32px']}
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
    >
      {children}
    </HStack>
  );
};
