import { useTeamRole } from '@/models/TeamInformation';

export function useGroupConfig() {
  const { isManager, isMember, isGroupAdmin } = useTeamRole();

  /**
   * Member and group admin may get computer from other groups that the user can not access.
   * We should extract group information from computer list using `group_name` field.
   */
  const shouldGetGroupName = isMember || isGroupAdmin;

  const canShowGroupFilter = isManager;

  return {
    shouldGetGroupName,
    canShowGroupFilter,
  };
}
