import axios from 'axios';
import { z } from 'zod';

import { checkResponse } from '@/services/utils';

const method = 'POST';

const getUrl = (teamId: number) => `/api/web/v1/teams/${teamId}/emm_policies/tokens`;

const schema = z.object({
  access_token: z.string(),
  token_ttl: z.number(),
});

const execute = (teamId: number) => checkResponse(axios({ method, url: getUrl(teamId) }), schema);

export const createTokenService = {
  method,
  getUrl,
  execute,
};
