import { type TFunction } from 'next-i18next';

import { type ComputerData } from '@/services/computers';

type ComputerDataRequiredPropertiesOnly = Pick<ComputerData, 'email' | 'deployed'>;

export const computerOwner = {
  /**
   * We want to let user search `Deployed` to find all deployed computers,
   * so we return `Team (Deployed)` if the computer is deployed.
   */
  getAccessor: (t: TFunction) => (data: ComputerDataRequiredPropertiesOnly) => {
    const { deployed = false, email } = data;

    if (deployed) return t('computer:column_computerOwner_value_deployed');

    return email ?? '';
  },
  getLabel: (t: TFunction) => t('computer:column_computerOwner'),
  sortingFn: (a: ComputerDataRequiredPropertiesOnly, b: ComputerDataRequiredPropertiesOnly) => {
    if (a.deployed) {
      return -1;
    }

    if (b.deployed) {
      return 1;
    }

    return (a.email ?? '').localeCompare(b.email ?? '');
  },
} as const;
