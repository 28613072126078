/**
 * Seat Permission Schema
 *
 * @see {@link https://splashtop.atlassian.net/wiki/spaces/CS/pages/1177387360/Key+-+seat_permissions}
 */
import { z } from 'zod';

import { SeatPermissionQuery } from './types';

export const seatPermissionKeySchema = z.enum([
  'file_transfer', // 非直接取用 DB 的 feature_set
  'remote_print',
  'off_session_file',
  'one_to_many',
  'command_prompt',
  'ssh_computer',
  'premier_pack',
  'sso',
  'off_session_chat',
  'access_schedule',
  'sos_service_desk',
  'sos_service_desk_psa',
  'vulnerability_score',
  'restrict_pcp_ip',
  'restrict_src_ip',
  'web_src',
  'open_api_token',
  'sos_web_src',
  'relay_recording',
  'sos_relay_recording',
  'macaddr_whitelist',
  'premium_tool',
  'watermark',
  'sos_watermark',
  'session_recording',
  'sos_session_recording',
  'sos_service_desk_premium_tool',
  'license_type',
  'attended_access', // 非直接取用 DB 的 feature_set
  'unattended_ac', // 非直接取用 DB 的 feature_set
  'granular_control', // 非直接取用 DB 的 feature_set
  'file', // 非直接取用 DB 的 feature_set, file_transfer ,, off_session_file
  'chat_transcript', // 非直接取用 DB 的 feature_set
  'create_group_admin',
  'voice_call', // Depend on the toggle `PCP_3760__VoiceCall_ActiveSessions_SOS14`
  'sos_voice_call', // Depend on the toggle `PCP_3760__VoiceCall_ActiveSessions_SOS14`
]);

export function getSeatPermissionSchema<Q extends SeatPermissionQuery>(query: Q) {
  if (!query.seat_permissions) {
    return z.object({ seat_permissions: z.never().optional() });
  }

  const shape = query.seat_permissions.reduce<Partial<Record<z.infer<typeof seatPermissionKeySchema>, z.ZodBoolean>>>((acc, key) => {
    return { ...acc, [key]: z.boolean() };
  }, {});

  return z.object({ seat_permissions: z.object(shape) });
}
