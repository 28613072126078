import axios from 'axios';

import { Inventory } from '@/modules/Inventory/types';
import type { ResponseData } from '@/services/common/types';
import { isSuccess } from '@/services/utils';

type FieldTypeMaps =
  | 'system'
  | 'hardware'
  | 'software'
  | 'os'
  | 'os_product'
  | 'os_build'
  | 'os_build_rev'
  | 'architecture'
  | 'local_time'
  | 'system.computer_name'
  | 'system.operating_system'
  | 'system.time_zone'
  | 'system.last_logon_user'
  | 'system.last_boot_time'
  | 'hardware.manufacturer'
  | 'hardware.model'
  | 'hardware.product'
  | 'hardware.motherboard.chipset'
  | 'hardware.processor.type'
  | 'hardware.memory.size'
  | 'hardware.display_driver.type'
  | 'hardware.network.interface.ip_address'
  | 'hardware.drive.capacity'
  | 'hardware.drive.partition.free_space'
  | 'hardware.ram.total'
  | 'hardware.rom.free'
  | 'hardware.rom.total';

export type FieldTypes = Array<FieldTypeMaps>;

export function queryComputerInventory(teamId: number, computerIds: Array<number>, fieldTypes?: FieldTypes) {
  return new Promise<Array<Inventory>>((resolve, reject) => {
    axios
      .post<ResponseData & { data: Array<Inventory> }>(`/api/web/v1/teams/${teamId}/computers/inventories/query_by_numerous_ids`, {
        ids: computerIds,
        fields: fieldTypes,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getComputerInventoryDetail(teamId: number, computerId: string) {
  return new Promise<Inventory>((resolve, reject) => {
    axios
      .get<ResponseData & { data: Inventory }>(`/api/web/v1/teams/${teamId}/computers/inventories/${computerId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}
