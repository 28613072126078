/**
 * Update Emm Policy Service
 * @see https://github.com/SplashtopInc/be_kung_fu/blob/master/be_app/api/web-api/v1/emm/emm_policies.md#update-emm-policy-api
 */
import axios from 'axios';
import { z } from 'zod';

import { commonResponseSchema } from '@/services/schemas';
import { checkResponse, createGenericErrorHandlers } from '@/services/utils';

import { policySchema } from './types';

const METHOD = 'PATCH';

const getUrl = (teamId: number, policyId: number) => `/api/web/v1/teams/${teamId}/emm_policies/${policyId}`;

const payloadSchema = z.object({
  name: policySchema.shape.name.optional(),
  parent_id: policySchema.shape.id.optional().nullable(),
  active: policySchema.shape.active.optional(),
  description: policySchema.shape.description.optional(),
});

type PayloadSchema = z.infer<typeof payloadSchema>;

const execute = (teamId: number, policyId: number, payload: PayloadSchema) =>
  checkResponse(axios({ method: METHOD, url: getUrl(teamId, policyId), data: payload }), policySchema);

const errorHandling = {
  ...createGenericErrorHandlers(),
  /**
   * @code 40403
   * - Customized policy can not be inherited
   * - Customized policy can not be root
   * - Can not create more than one super root (team default) to each platform
   * - Super root (team default) can not have a parent
   */
  actionNotAllow: (response: any) => {
    const errorSchema = commonResponseSchema.merge(
      z.object({ result: z.literal(40403), data: z.object({ error: z.literal('action_not_allow') }) }),
    );
    return errorSchema.safeParse(response).success;
  },
  /**
   * @code 40403
   * Number of policy exceeded maximum limit (256)
   */
  resourcesNumberLimit: (response: any) => {
    const errorSchema = commonResponseSchema.merge(
      z.object({ result: z.literal(40403), data: z.object({ error: z.literal('resources_number_limit') }) }),
    );
    return errorSchema.safeParse(response).success;
  },
  /**
   * @code 40404
   * Parent not exist or the platform is different from parent policy
   */
  resourceNotExist: (response: any) => {
    const errorSchema = commonResponseSchema.merge(
      z.object({ result: z.literal(40404), data: z.object({ error: z.literal('resource_not_exists') }) }),
    );
    return errorSchema.safeParse(response).success;
  },
  /**
   * @code 40422
   * Wrong parameter, either required param is missing (name, platform, policy_kind, active, super_root) or invalid params (platform, policy_kind)
   */
  wrongParameter: (response: any) => {
    const errorSchema = commonResponseSchema.merge(
      z.object({ result: z.literal(40422), data: z.object({ error: z.literal('wrong_params') }) }),
    );
    return errorSchema.safeParse(response).success;
  },
} as const;

export const updateEmmPolicyService = { getUrl, payloadSchema, execute, method: METHOD, errorHandling };
