import { z } from 'zod';

import { TeamMemberInfoQuery } from './types';

export const rolesSchema = z.enum(['owner', 'manager', 'member']);

export const memberStatusSchema = z.enum(['disabled', 'enabled', 'inviting', 'decline', 'quit']);

export const groupScopeSchema = z.enum(['all', 'part', 'none']);

export const isResellSchema = z.boolean();

export const memberIdSchema = z.number();

export const licenseTypeSchema = z.enum(['none', 'technician', 'end_user']).nullable();

export const teamMemberInfoKeySchema = z.enum([
  'role',
  'member_status',
  'group_scope',
  'is_resell',
  'member_id',
  /** none (非 STE / unified), technician / end_user (STE / unified, license_type nil 時會是 end_user) / nil (STP) */
  'license_type',
]);

const teamMemberInfoSchemaShape = {
  role: rolesSchema,
  member_status: memberStatusSchema,
  group_scope: groupScopeSchema,
  is_resell: isResellSchema,
  member_id: memberIdSchema,
  license_type: licenseTypeSchema,
};

export const teamMemberInfoFullSchema = z.object(teamMemberInfoSchemaShape);

export function getTeamMemberInfoSchema<Q extends TeamMemberInfoQuery>(query: Q) {
  if (!query.team_member_info) {
    return z.object({ team_member_info: z.never().optional() });
  }

  const shape = query.team_member_info.reduce<Partial<typeof teamMemberInfoSchemaShape>>((acc, key) => {
    return { ...acc, [key]: teamMemberInfoSchemaShape[key] };
  }, {});

  return z.object({ team_member_info: z.object(shape) });
}
