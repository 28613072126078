import axios from 'axios';
import { z } from 'zod';

import { commonResponseSchema } from '@/services/schemas';
import { checkResponse, createGenericErrorHandlers } from '@/services/utils';

import { categoryEnum } from './types';

/**
 * List EMM Policy Categories API
 * EMM Policies all categories
 * @param teamId
 * @doc https://github.com/SplashtopInc/be_kung_fu/blob/master/be_app/api/web-api/v1/emm/emm_policies.md#emm-policies-categories-api
 */

const listEmmPolicyCategoriesUrl = (teamId: number) => `/api/web/v1/teams/${teamId}/emm_policies/categories`;

const listEmmPolicyCategoriesRequestDataSchema = z.array(z.number());
export type ListEmmPolicyCategoriesRequestData = z.infer<typeof listEmmPolicyCategoriesRequestDataSchema>;

export const listEmmPolicyCategoriesSchema = z.array(
  z.object({
    id: z.number(),
    emm_policy_id: z.number(),
    category: categoryEnum,
    version: z.string().nullable(),
  }),
);
export type ListEmmPolicyCategoriesResponse = z.infer<typeof listEmmPolicyCategoriesSchema>;

export function listEmmPolicyCategories(teamId: number, emmPolicyIds: ListEmmPolicyCategoriesRequestData) {
  return checkResponse(
    axios.post(listEmmPolicyCategoriesUrl(teamId), {
      emm_policy_ids: emmPolicyIds,
    }),
    listEmmPolicyCategoriesSchema,
  );
}

const errorHandling = {
  ...createGenericErrorHandlers(),
  /**
   * @code 40409
   * - EMM policy IDs exceed maximum limit (5000)
   */
  exceedNumberLimit: (response: any) => {
    const errorSchema = commonResponseSchema.merge(
      z.object({ result: z.literal(40409), data: z.object({ error: z.literal('exceed_number_limit') }) }),
    );
    return errorSchema.safeParse(response).success;
  },
  /**
   * @code 40422
   * EMM Policy ID is empty
   */
  wrongParams: (response: any) => {
    const errorSchema = commonResponseSchema.merge(
      z.object({ result: z.literal(40422), data: z.object({ error: z.literal('wrong_params') }) }),
    );
    return errorSchema.safeParse(response).success;
  },
} as const;

export const listEmmPolicyCategoriesService = {
  getUrl: listEmmPolicyCategoriesUrl,
  method: 'POST',
  execute: listEmmPolicyCategories,
  errorHandling,
};
