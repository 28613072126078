import { Fragment, useMemo } from 'react';

import { Flex } from '@chakra-ui/react';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { ColumnDef, GroupingState, Row } from '@tanstack/react-table';
import { useAtom, useAtomValue } from 'jotai';
import { TableVirtuoso } from 'react-virtuoso';

import { RippleCheckbox, RippleTrLegacy } from '@/design';

import { TableHeaderContainer } from '../TableHeaderContainer';
import { makeTableVirtuosoComponentsForGroupWithOffsetMask } from '../TableVirtuosoComponents';
import {
  columnFilterStateAtom,
  columnVisibilityForGroupModeAtom,
  expandedStateAtom,
  filterSelectedAtom,
  groupIdListAtom,
  groupNameMapAtom,
  isLoadingAtom,
  leftOffsetAtom,
  rowSelectionStateAtom,
  scrollWidthAtom,
  searchKeywordAtom,
  sharedFilterFnAtom,
  sortingGroupForGroupViewAtom,
  sortingStateAtom,
} from '../atoms';
import { FROM_OTHER_GROUP_ID, GROUPING_COLUMN, sharedFilterFnName } from '../constants';
import { useGetGroupCheckboxProps } from '../hooks';
import type { ColumnVisibility, ExpandedState, GroupedComputerData } from '../types';
import { generateGroupRowId, sortGroupData } from '../utils';
import { EmptyGroupItem, GroupHeader } from './components';

const groupingState: GroupingState = [GROUPING_COLUMN];

type GroupTableProps<T extends GroupedComputerData> = {
  data: Array<T>;
  columns: Array<ColumnDef<T, any>>;
  hasSelection?: boolean;
};
export function GroupTable<T extends GroupedComputerData>({ data, columns, hasSelection = true }: GroupTableProps<T>): React.JSX.Element {
  const leftOffset = useAtomValue(leftOffsetAtom);
  const scrollWidth = useAtomValue(scrollWidthAtom);

  const isLoading = useAtomValue(isLoadingAtom);
  const [columnVisibility, setColumnVisibility] = useAtom(columnVisibilityForGroupModeAtom);
  const columnFilters = useAtomValue(columnFilterStateAtom);
  const filterSelected = useAtomValue(filterSelectedAtom);
  const sharedFilterFn = useAtomValue(sharedFilterFnAtom);
  const searchKeyword = useAtomValue(searchKeywordAtom);
  const [expandedState, setExpandedState] = useAtom(expandedStateAtom);
  const [sortingState, setSortingState] = useAtom(sortingStateAtom);
  const sortGroupState = useAtomValue(sortingGroupForGroupViewAtom);
  const rowSelection = useAtomValue(rowSelectionStateAtom);

  const groupNameMap = useAtomValue(groupNameMapAtom);
  const groupIdList = useAtomValue(groupIdListAtom);
  const getGroupCheckboxProps = useGetGroupCheckboxProps<T>();

  const groupedData = useMemo(() => {
    const groupIdMap = groupIdList.reduce<Record<string, Array<T>>>((acc, groupId) => {
      acc[groupId] = [];
      return acc;
    }, {});

    data.forEach((computer) => {
      groupIdMap[computer.group_id ?? FROM_OTHER_GROUP_ID]?.push(computer);
    });

    return Object.entries(groupIdMap).map<T & { subRows: Array<T> }>(([groupId, subRows]) => {
      const groupRowData: GroupedComputerData = {
        id: generateGroupRowId(groupId),
        name: '',
        group_id: isNaN(Number(groupId)) ? groupId : Number(groupId),
        online_status: false,
        is_device_owner: false,
        connected: false,
        note: null,
        version: 'SRS/0.0.0.0 splashtop2 win',
      };
      return {
        ...groupRowData,
        subRows: subRows,
      } as T & { subRows: Array<T> };
    });
  }, [data, groupIdList]);

  const sortedGroupData = useMemo(() => {
    return groupedData.concat().sort(sortGroupData(groupNameMap, sortGroupState.direction));
  }, [groupedData, groupNameMap, sortGroupState.direction]);

  const table = useReactTable<T & { subRows?: Array<T> }>({
    data: isLoading ? [] : sortedGroupData,
    columns,
    getRowId: (row) => String(row.id),
    state: {
      columnVisibility,
      columnFilters,
      globalFilter: searchKeyword,
      grouping: groupingState,
      expanded: expandedState,
      sorting: sortingState,
    },
    autoResetAll: false,
    manualGrouping: true,
    getSubRows: (row) => row.subRows,
    getRowCanExpand(row) {
      return row.depth === 0 && Array.isArray(row.subRows);
    },
    filterFromLeafRows: true,
    maxLeafRowFilterDepth: 1,
    groupedColumnMode: 'reorder', // NOTE: Hide group column in columnVisibilityForGroupModeAtom
    enableGlobalFilter: true,
    onColumnVisibilityChange: (visibility) => {
      setColumnVisibility(visibility as ColumnVisibility);
    },
    onExpandedChange: (expandedStateUpdater) => {
      setExpandedState(expandedStateUpdater as (oldState: ExpandedState) => ExpandedState);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getColumnCanGlobalFilter(column) {
      return column.getIsGrouped() || column.getIsVisible();
    },
    onSortingChange: setSortingState,
    filterFns: {
      [sharedFilterFnName]: sharedFilterFn,
    },
  });

  const rows = computeRows();

  return (
    <TableVirtuoso
      data-testid="computer-list--group"
      style={{ height: '100%', width: '100%' }}
      useWindowScroll
      defaultItemHeight={58}
      data={rows}
      // NOTE: `components` should be memoized to avoid some performance issues
      components={useMemo(() => makeTableVirtuosoComponentsForGroupWithOffsetMask<Row<T & { subRows?: Array<T> }>>(), [])}
      context={{ table }}
      fixedHeaderContent={() => {
        return (
          <RippleTrLegacy bg="neutral.10" w="fit-content" minW="100%">
            <TableHeaderContainer viewMode="group">
              {table.getFlatHeaders().map((header) => (
                <Fragment key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </Fragment>
              ))}
            </TableHeaderContainer>
          </RippleTrLegacy>
        );
      }}
      itemContent={(index, row) => {
        const isGroupRow = row.getCanExpand();

        if (isGroupRow) {
          const groupCheckboxProps = getGroupCheckboxProps(row);

          const isFirstRow = index === 0;
          const isAllSelected = groupCheckboxProps.isChecked;
          const isSomeSelected = groupCheckboxProps.isIndeterminate;
          const isEmptyRow = row.subRows.length === 0;
          const isExpanded = row.getIsExpanded();

          const groupName = groupNameMap[row.original.group_id ?? FROM_OTHER_GROUP_ID];

          return (
            <Flex key={row.id} marginTop={isFirstRow ? '0' : '12px'} flexDirection="column" w={scrollWidth ? `${scrollWidth}px` : '100%'}>
              <Flex
                sx={{
                  '.computerList__row__groupHeader': {
                    bgColor: isAllSelected || isSomeSelected ? 'green.20' : 'neutral.40',
                  },
                  '&:hover .computerList__row__groupHeader': {
                    bgColor: isAllSelected || isSomeSelected ? 'green.40' : 'blue.0',
                  },
                }}
                w="100%"
              >
                <GroupHeader
                  checkbox={
                    hasSelection ? (
                      <RippleCheckbox data-testid={`group-checkbox-${row.original.group_id}`} {...groupCheckboxProps} />
                    ) : undefined
                  }
                  isExpanded={isExpanded}
                  name={groupName}
                  count={row.subRows.length}
                  onClick={row.getToggleExpandedHandler()}
                  sx={{
                    box: {
                      maxW: `calc(100vw - ${leftOffset * 2 + 16}px)`, // 16 is GroupHeader's horizon padding
                      left: `${leftOffset}px`,
                      flexShrink: '0',
                    },
                    name: {
                      maxW: 'initial',
                      wordBreak: 'initial',
                    },
                    tail: {
                      right: `${leftOffset}px`,
                    },
                  }}
                />
              </Flex>
              {isExpanded && isEmptyRow && <EmptyGroupItem offset={leftOffset} checkbox={hasSelection} />}
            </Flex>
          );
        }

        // computer row
        const isSelected = rowSelection[row.id];
        const isLastRow = index === rows.length - 1;
        const nextRow = rows[index + 1];
        const isLastRowInGroup = nextRow?.getCanExpand() || isLastRow;

        return (
          <Flex
            key={row.id}
            sx={{
              '[data-cell-type="normal"]': {
                bgColor: isSelected ? 'green.10' : 'white',
              },
              '&:hover [data-cell-type="normal"]': {
                bgColor: isSelected ? 'green.40' : 'blue.0',
              },
              '[data-cell-type="normal"]:first-of-type': {
                borderBottomLeftRadius: isLastRowInGroup ? '4px' : '0',
              },
              '[data-cell-type="normal"]:last-of-type': {
                borderBottomRightRadius: isLastRowInGroup ? '4px' : '0',
              },
            }}
            w="100%"
          >
            {row.getVisibleCells().map((cell) => (
              <Fragment key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Fragment>
            ))}
          </Flex>
        );
      }}
    />
  );

  function computeRows(): Array<Row<T & { subRows?: Array<T> }>> {
    const { rows } = table.getRowModel();

    switch (filterSelected) {
      case 'all': {
        return rows;
      }
      case 'selected': {
        return rows.filter((row) => {
          const groupCheckboxProps = getGroupCheckboxProps(row);
          return rowSelection[row.id] || groupCheckboxProps.isChecked || groupCheckboxProps.isIndeterminate;
        });
      }
      case 'unselected': {
        return rows.filter((row) => {
          const groupCheckboxProps = getGroupCheckboxProps(row);
          return !(rowSelection[row.id] || groupCheckboxProps.isChecked);
        });
      }
    }
  }
}
