import { Box } from '@chakra-ui/react';
import { Trans, useTranslation } from 'next-i18next';

import { RippleTypography } from '@/design';

import { MaxWakeComputersCount, useWakeComputerModalData } from './WakeComputerModalAtom';
import { WakeComputer } from './WakeComputerModalColumns';
import { WakeComputerModalComputerTable } from './WakeComputerModalComputerTable';
import { WakeComputerModalGroupTable } from './WakeComputerModalGroupTable';

const TableHeight = 350;

type WakeComputerModalTableProps = {
  showGroupTable: boolean;
  data: Array<WakeComputer>;
};

export function WakeComputerModalTable({ showGroupTable, data }: WakeComputerModalTableProps): React.JSX.Element {
  return (
    <Box pt="24px">
      <RippleTypography variant="body02" pl="32px" mb="8px">
        <SelectedComputers />
      </RippleTypography>
      {showGroupTable ? (
        <WakeComputerModalGroupTable data={data} height={TableHeight} />
      ) : (
        <WakeComputerModalComputerTable data={data} height={TableHeight} />
      )}
    </Box>
  );
}

function SelectedComputers() {
  const { t } = useTranslation();
  const { selectedComputerIds } = useWakeComputerModalData();

  const computerCount = selectedComputerIds?.length ?? 0;

  return (
    <Trans
      t={t}
      i18nKey="computer:remoteWake.selectModal.table.selectedHint"
      components={{ Strong: <Box as="span" color={computerCount >= MaxWakeComputersCount ? 'red.100' : undefined} /> }}
      values={{ computerCount, MaxWakeComputersCount }}
    />
  );
}
