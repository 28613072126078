import Link from 'next/link';

import { useTranslation } from 'next-i18next';

import {
  RippleIconButton,
  RippleSecurityError,
  RippleSecurityNoSoftware,
  RippleSecurityNotice,
  RippleSecurityReady,
  RippleSecurityUnacknowledged,
  RippleSecurityUpgrade,
  RippleTooltip,
} from '@/design';
import { ComputerAntivirusStatus } from '@/services/computers';

const AntivirusStatusIconMap: Record<NonNullable<ComputerAntivirusStatus>, React.JSX.Element> = {
  has_threats: <RippleSecurityUnacknowledged color="red.100" />,
  no_protection: <RippleSecurityError color="red.100" />,
  expired: <RippleSecurityNotice color="yellow.100" />,
  no_software: <RippleSecurityNoSoftware />,
  not_support_antivirus_version: <RippleSecurityUpgrade color="neutral.300" />,
  good: <RippleSecurityReady color="green.100" />,
  computer_not_support: <span />,
};

type AntivirusStatusCellContentProps = { computerId: number; status: ComputerAntivirusStatus };
export function AntivirusStatusCellContent({ computerId, status }: AntivirusStatusCellContentProps): React.JSX.Element | null {
  const { t } = useTranslation();

  if (status === null) return null;

  const icon = AntivirusStatusIconMap[status];
  const label = computeLabel();

  return (
    <RippleTooltip aria-label={label} label={label}>
      <Link href={`/property/antivirus/${computerId}`}>
        <RippleIconButton data-testid={`antivirus-${computerId}`} aria-label={label} icon={icon} />
      </Link>
    </RippleTooltip>
  );

  function computeLabel(): string {
    if (status === null) return '';

    const labelMap: Record<NonNullable<ComputerAntivirusStatus>, string> = {
      has_threats: t('computer:column.antivirus_scan_status.tooltip.has_threats'),
      no_protection: t('computer:column.antivirus_scan_status.tooltip.no_protection'),
      expired: t('computer:column.antivirus_scan_status.tooltip.expired'),
      no_software: t('computer:column.antivirus_scan_status.tooltip.no_software'),
      not_support_antivirus_version: t('computer:column.antivirus_scan_status.tooltip.not_support_antivirus_version', {
        version: '3.3.0.0',
      }),
      good: t('computer:column.antivirus_scan_status.tooltip.good'),
      computer_not_support: '',
    };

    return labelMap[status];
  }
}
