import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';

import SelectComputerTitle, { SelectComputerTitleHeight } from '@/components/ComputerList/SelectComputerTitle';
import type { ComputerSelectorOnSelectChanged } from '@/components/ComputerList/types';
import { filterDefaultGroup, filterFromOtherGroup } from '@/components/ComputerList/utils';

import { ComputerSelectorWithRDS, ComputerSelectorWithRDSProps } from './ComputerSelectorWithRDS';
import postMessageToParent, { PostToParentTypeKeys, PostToParentTypes, SelectedComputer, SelectedGroup } from './postMessageToParent';

type ComputerSelectorIframeWithRDSProps = Omit<
  ComputerSelectorWithRDSProps,
  'maxHeight' | 'onSelectChanged' | 'onComputerListHeightChanged'
>;

export const ComputerSelectorIframeWithRDS = (props: ComputerSelectorIframeWithRDSProps) => {
  const router = useRouter();
  const callbackType = router.query.callback as PostToParentTypeKeys;

  useEffect(() => {
    postMessageToParent({
      type: PostToParentTypes.load_complete,
    });
  }, []);

  const tempMaxHeight = (router.query['max-height'] as string)?.replace('px', '');
  let maxHeight = parseInt(tempMaxHeight, 10);
  maxHeight = !isNaN(maxHeight) ? maxHeight : 500;

  const handleSelectChange = useCallback<ComputerSelectorOnSelectChanged>(
    (data) => {
      const { computerHashMap, groupHashMap, nextSelectedComputers, nextSelectedGroups } = data;

      const computers = Object.keys(nextSelectedComputers)
        .filter((computerId) => nextSelectedComputers[computerId])
        .reduce<Record<string, SelectedComputer>>((acc, computerId) => {
          const computer = computerHashMap[computerId];
          if (computer) {
            acc[computerId] = {
              name: computer.name,
              group_id: computer.group_id,
              selected: true,
            };
          }

          return acc;
        }, {});

      const groupsForSelected = Object.keys(nextSelectedGroups)
        .filter((groupId) => nextSelectedGroups[groupId])
        .filter(filterDefaultGroup)
        .filter(filterFromOtherGroup)
        .filter((groupId) => Boolean(groupHashMap[groupId]))
        .reduce<Record<string, SelectedGroup>>((acc, groupId) => {
          const groupData = groupHashMap[groupId];

          acc[groupId] = {
            name: groupData.group.name,
            selected: true,
          };

          return acc;
        }, {});

      const groupsForComputers = Object.keys(computers).reduce<Record<string, SelectedGroup>>((acc, computerId) => {
        const computerData = computerHashMap[computerId];

        if (groupsForSelected[computerData.gid]) {
          return acc;
        }

        const groupData = groupHashMap[computerData.gid];
        acc[computerData.gid] = {
          name: groupData.group.name,
          selected: false,
        };

        return acc;
      }, {});

      if (callbackType === PostToParentTypes.select_computer) {
        postMessageToParent({
          type: callbackType,
          data: {
            computers,
            groups: {
              ...groupsForSelected,
              ...groupsForComputers,
            },
          },
        });
      }
    },
    [callbackType],
  );

  return (
    <>
      <SelectComputerTitle />
      <ComputerSelectorWithRDS maxHeight={maxHeight - SelectComputerTitleHeight} onSelectChanged={handleSelectChange} {...props} />
    </>
  );
};
