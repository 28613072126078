import { useTranslation } from 'react-i18next';

import { RippleMultipleSelect, RippleMultipleSelectOption, RippleMultipleSelectProps } from '@/design';
import { Weekday } from '@/utils/cron';

type WeekdayPickerProps = Omit<RippleMultipleSelectProps<Weekday>, 'children' | 'placeholder'>;

export const WeekdayPicker = ({ value, onChange, ...props }: WeekdayPickerProps) => {
  const { t } = useTranslation();

  const weekdays = [
    { value: '1', label: t('common:date.monday') },
    { value: '2', label: t('common:date.tuesday') },
    { value: '3', label: t('common:date.wednesday') },
    { value: '4', label: t('common:date.thursday') },
    { value: '5', label: t('common:date.friday') },
    { value: '6', label: t('common:date.saturday') },
    { value: '0', label: t('common:date.sunday') },
  ];

  return (
    <RippleMultipleSelect placeholder="" value={value} onChange={onChange} {...props}>
      {weekdays.map(({ value: optionValue, label }) => (
        <RippleMultipleSelectOption key={optionValue} value={optionValue} isDisabled={value?.length === 1 && value[0] === optionValue}>
          {label}
        </RippleMultipleSelectOption>
      ))}
    </RippleMultipleSelect>
  );
};
