import axios from 'axios';
import type { AxiosRequestConfig } from 'axios';

import type { Package, Theme as PackageTheme } from '@/modules/Customization/types';
import { INFO_QUERY } from '@/services/common';
import type { ResponseData, Teams } from '@/services/common/types';
import { isSuccess, pureInstance } from '@/services/utils';
import { delayPromise } from '@/utils/delayPromise';

import type {
  CloudBuildFileResponse,
  PackageDetailResponse,
  PackageSummaryResponse,
  ThemeFileV2,
  ThemeFileV3,
  ThemeResponse,
} from './types';

function getPackageList(teamId: number) {
  return new Promise<Array<PackageSummaryResponse>>((resolve, reject) => {
    axios
      .get<ResponseData & { data: Array<PackageSummaryResponse> }>(`/api/web/v1/teams/${teamId}/packages`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getPackageDetail(teamId: number, packageId: number) {
  return new Promise<PackageDetailResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: PackageDetailResponse }>(`/api/web/v1/teams/${teamId}/packages/${packageId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getTheme(teamId: number, themeId: number) {
  return new Promise<ThemeResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: ThemeResponse }>(`/api/web/v1/teams/${teamId}/themes/${themeId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function createTheme(teamId: number, theme: PackageTheme, version: number) {
  return new Promise<{
    id: number;
    upload_url: string;
    thumbnail_url: string;
    sd_thumbnail_url: string;
    logo_url: string;
  }>((resolve, reject) => {
    const payload = generatePayload();
    function generatePayload() {
      switch (version) {
        case 3: {
          return {
            window_title: theme.windowTitle,
            instruction_text: theme.instructionText,
            instruction_color: theme.instructionColor,
            background_color: theme.backgroundColor,
            session_code_color: theme.sessionCodeColor,
            security_text_color: theme.securityTextColor,
            security_accent_color: theme.securityAccentColor,
            status_text_color: theme.statusTextColor,
            warning_text_color: theme.warningTextColor,
          };
        }
        case 2:
        default: {
          return {
            window_title: theme.windowTitle,
            instruction_text: theme.instructionText,
            instruction_color: theme.instructionColor,
            background_color: theme.backgroundColor,
            session_code_color: theme.sessionCodeColor,
            security_text_color: theme.securityTextColor,
            security_accent_color: theme.securityAccentColor,
          };
        }
      }
    }

    axios
      .post<
        ResponseData & {
          data: {
            id: number;
            upload_url: string;
            thumbnail_url: string;
            sd_thumbnail_url: string;
            logo_url: string;
          };
        }
      >(`/api/web/v1/teams/${teamId}/themes`, payload)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function createPackage(teamId: number, sosPackage: Pick<Package, 'name' | 'config' | 'themeId'>, packageId?: number) {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .post<ResponseData>(`/api/web/v1/teams/${teamId}/packages`, {
        name: sosPackage.name,
        package_id: packageId || null,
        theme_id: sosPackage.themeId,
        config: {
          conn_perm: sosPackage.config.connectionPermission,
          fips: sosPackage.config.supportFIPS,
          audio_output: sosPackage.config.audioOutput,
          proxy_setting: sosPackage.config.proxySetting,
          proxy_addr: sosPackage.config.proxyAddress,
          proxy_port: sosPackage.config.proxyPort,
          proxy_user: sosPackage.config.proxyAccount,
          proxy_password: sosPackage.config.proxyPassword || null,
          disclaimer: sosPackage.config.disclaimer,
          auto_delete: sosPackage.config.autoDelete,
          direct_connection: sosPackage.config.directConnection,
        },
      })
      .then((res) => {
        const {
          data: { result, messages },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: {} });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function fetchThemeFileFromS3(url: string, version = 3): Promise<ThemeFileV2> | Promise<ThemeFileV3> {
  switch (version) {
    case 3: {
      return new Promise<ThemeFileV3>((resolve, reject) => {
        pureInstance
          .get<ThemeFileV3>(url)
          .then((res) => {
            const { data } = res;
            try {
              resolve(data);
            } catch {
              reject({ statusCode: 9487, messages: [], errorReason: {} });
            }
          })
          .catch((error) => {
            const statusCode = error.response ? error.response.status : 9487;
            reject({ statusCode, messages: [], errorReason: {} });
          });
      });
    }

    case 2:
    default: {
      return new Promise<ThemeFileV2>((resolve, reject) => {
        pureInstance
          .get<ThemeFileV2>(url)
          .then((res) => {
            const { data } = res;
            try {
              resolve(data);
            } catch {
              reject({ statusCode: 9487, messages: [], errorReason: {} });
            }
          })
          .catch((error) => {
            const statusCode = error.response ? error.response.status : 9487;
            reject({ statusCode, messages: [], errorReason: {} });
          });
      });
    }
  }
}

function uploadToS3(url: string, file: File | Blob, config?: AxiosRequestConfig) {
  return pureInstance.put(url, file, {
    headers: { 'Content-Type': file.type, ...config?.headers },
    ...config,
  });
}

function getPackageDownloadSet() {
  return new Promise<{
    win: string;
    mac: string;
  }>((resolve, reject) => {
    axios
      .get<
        ResponseData & {
          data: {
            win: string;
            mac: string;
          };
        }
      >(`/api/web/v1/teams/packages/download_set`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getPackageDownloadURL(packageCode: string, category: 'win' | 'mac') {
  return new Promise<CloudBuildFileResponse>((resolve, reject) => {
    pureInstance
      .post<
        ResponseData & {
          data: CloudBuildFileResponse;
        }
      >(`/api/web/v1/teams/packages/${packageCode}/cloud_build_file`, { category })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

const deletePackage = (teamId: number, packageId: number) => {
  return new Promise<Record<string, unknown>>((resolve, reject) => {
    axios
      .delete<ResponseData & { data: Record<string, unknown> }>(`/api/web/v1/teams/${teamId}/packages/${packageId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

type CheckCloudBuild = (url: string, retry?: number) => Promise<boolean>;
/**
 * Check if the cloud build is ready for download
 * @param url URL to poke
 * @param retry retry times
 * @returns {Boolean}
 */
const checkCloudBuild: CheckCloudBuild = async (url, retry = 0) => {
  if (retry > 20) {
    return false;
  }
  return axios
    .create({ withCredentials: false })
    .head(url)
    .then(() => true)
    .catch(() => delayPromise(() => checkCloudBuild(url, retry + 1), 1000)); // retry after 1s
};

function getPrivatePackageId(teamKind: keyof Teams) {
  return new Promise<number | null>((resolve, reject) => {
    axios
      .post<
        ResponseData & { data: Partial<Record<keyof Teams, { team_settings: { service_desk_private_package_id: { value: number } } }>> }
      >(INFO_QUERY, {
        team_settings: ['service_desk_private_package_id'],
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data[teamKind]?.team_settings.service_desk_private_package_id.value ?? null);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export {
  getPackageList,
  getPackageDetail,
  getTheme,
  createTheme,
  createPackage,
  fetchThemeFileFromS3,
  uploadToS3,
  getPackageDownloadSet,
  getPackageDownloadURL,
  deletePackage,
  checkCloudBuild,
  getPrivatePackageId,
};
