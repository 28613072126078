import { useRef } from 'react';

import { Box, forwardRef } from '@chakra-ui/react';
import type { BoxProps } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'next-i18next';
import { TableVirtuoso } from 'react-virtuoso';

import Highlight from '@/components/Highlight';
import { OTableSortStates } from '@/components/ObservableTable/useObservableSort';
import {
  RippleBodyText02,
  RippleComputerCell,
  RippleComputerTableHead,
  RippleSkeleton,
  RippleTableLegacy,
  RippleTbodyLegacy,
  RippleTheadLegacy,
  RippleTooltip,
  RippleTrLegacy,
} from '@/design';

import { InventorySummary, InventoryTableColumn } from '../types';
import { getInventoryColumnHeaders, getInventoryColumnWidthsSum, inventoryColumnOrder, inventoryColumnWidths } from './constant';
import { useContainerSize, useSelectRow } from './utils';

const sortConditionMap = {
  asc: OTableSortStates.ASC,
  desc: OTableSortStates.DESC,
};

type SortCondition = {
  column: InventoryTableColumn;
  order: 'asc' | 'desc';
};

type RowProps = BoxProps & { isSelected: boolean; isFirstRow: boolean; isLastRow: boolean };
const Row = forwardRef((props: RowProps, ref) => {
  const { isSelected, isFirstRow, isLastRow, ...otherProps } = props;

  return (
    <RippleTrLegacy
      overflow="hidden"
      _hover={{ backgroundColor: 'blue.0' }}
      backgroundColor={isSelected ? 'green.10' : 'white'}
      borderTop={isFirstRow ? '1px solid #DFE4E9' : undefined}
      borderTopLeftRadius={isFirstRow ? '4px' : undefined}
      borderTopRightRadius={isFirstRow ? '4px' : undefined}
      borderBottomLeftRadius={isLastRow ? '4px' : undefined}
      borderBottomRightRadius={isLastRow ? '4px' : undefined}
      borderBottom="1px solid #DFE4E9"
      borderLeft="1px solid #DFE4E9"
      borderRight="1px solid #DFE4E9"
      padding="0px 8px"
      cursor="pointer"
      ref={ref}
      {...otherProps}
    />
  );
});

export type ComputerViewProps = {
  rows: Array<InventorySummary>;
  searchKeyword?: string;
  visibilities: Record<string, boolean>;
  selectedGroupId: string;
  isLoading: boolean;
  onSelect: (id: string) => void;
  sortCondition: SortCondition;
  onChangeSortCondition: (sortCondition: SortCondition) => void;
};

const ComputerView = ({
  rows,
  searchKeyword,
  visibilities,
  isLoading,
  onSelect,
  sortCondition,
  onChangeSortCondition,
}: ComputerViewProps) => {
  const { t } = useTranslation();
  const columnHeader = getInventoryColumnHeaders(t);

  const handleClickSortIcon = (columnName: InventoryTableColumn) => () => {
    const newCondition: SortCondition = {
      column: columnName,
      order: sortCondition.column === columnName && sortCondition.order === 'asc' ? 'desc' : 'asc',
    };
    onChangeSortCondition(newCondition);
  };

  const [containerRef, containerHeight, containerWidth] = useContainerSize({
    isLoading,
    isEmpty: isEmpty(rows),
  });

  // Prevent tooltip from exceeding the display range of the table
  const scrollerRef = useRef<HTMLElement | null>(null);

  return (
    <Box ref={containerRef} flex="1 0">
      <TableVirtuoso
        scrollerRef={(ref) => (scrollerRef.current = ref as HTMLElement)}
        style={{ height: containerHeight }}
        data={!isLoading ? rows : []}
        components={{
          Table: RippleTableLegacy,
          TableBody: forwardRef((props, ref) => (
            <RippleTbodyLegacy
              background="white"
              borderRadius="4px"
              boxShadow="0px 2px 3px rgba(102, 102, 102, 0.2), 0px 0px 1px rgba(102, 102, 102, 0.2)"
              ref={ref}
              {...props}
            />
          )),
          TableHead: forwardRef((props, ref) => <RippleTheadLegacy ref={ref} bg="neutral.10" {...props} />),
          TableRow: forwardRef((props, ref) => {
            const rowIndex = props['data-item-index'];
            const rowId = rows[rowIndex].id;
            const { isSelected, selectRow } = useSelectRow(rowId);
            const isFirstRow = rowIndex === 0;
            const isLastRow = rowIndex === rows.length - 1;

            return (
              <Row
                key={rowId}
                isFirstRow={isFirstRow}
                isLastRow={isLastRow}
                isSelected={isSelected}
                width={`${Math.max(getInventoryColumnWidthsSum(visibilities) - 18, containerWidth)}px`}
                onClick={() => {
                  selectRow();
                  onSelect(rowId);
                }}
                ref={ref}
                {...props}
              />
            );
          }),
        }}
        fixedHeaderContent={() => (
          <RippleTrLegacy padding="0px 8px" bg="neutral.10">
            {inventoryColumnOrder.map((columnName, index) => {
              return (
                <RippleComputerTableHead
                  display={visibilities[columnName] ? 'flex' : 'none'}
                  width={`${inventoryColumnWidths[columnName]}px`}
                  key={index}
                  isSortable
                  sortState={sortCondition.column === columnName ? sortConditionMap[sortCondition.order] : undefined}
                  onClick={handleClickSortIcon(columnName)}
                >
                  {columnHeader[columnName]}
                </RippleComputerTableHead>
              );
            })}
          </RippleTrLegacy>
        )}
        itemContent={(_index, inventory) => {
          return (
            <>
              {inventoryColumnOrder.map((columnName, index) => {
                return (
                  <RippleComputerCell
                    display={visibilities[columnName] ? 'flex' : 'none'}
                    width={`${inventoryColumnWidths[columnName]}px`}
                    height="72px"
                    key={index}
                  >
                    <Box width={`${inventoryColumnWidths[columnName] - 16}px`}>
                      <RippleTooltip aria-label={columnName} label={inventory[columnName]} portalProps={{ containerRef: scrollerRef }}>
                        <RippleBodyText02 noOfLines={2} width="100%">
                          <Highlight keyword={searchKeyword}>{String(inventory[columnName])}</Highlight>
                        </RippleBodyText02>
                      </RippleTooltip>
                    </Box>
                  </RippleComputerCell>
                );
              })}
            </>
          );
        }}
      />
      {isLoading && (
        <Box>
          {Array(3)
            .fill(null)
            .map((_, index) => {
              return (
                <RippleTrLegacy key={index}>
                  <RippleSkeleton width={`${getInventoryColumnWidthsSum(visibilities)}px`} my="5px" />
                </RippleTrLegacy>
              );
            })}
        </Box>
      )}
    </Box>
  );
};

export default ComputerView;
