import { atom } from 'jotai';

import { basicProfileAtom } from '@/models/BasicProfile';

import type { TeamInformation, TeamInformationKind, TeamKind, TeamRole } from './types';
import { daysUntilExpiration, isExpired } from './utils';

export const teamInformationKindAtom = atom<TeamInformationKind>('unattended-first');

export const teamInformationMapAtom = atom((get) => get(basicProfileAtom).teams);

const teamKindsAtom = atom((get) => Object.keys(get(teamInformationMapAtom)) as Array<TeamKind>);

export const ATTENDED_TEAM_KIND = 'sos';

export const unattendedTeamInformationAtom = atom<TeamInformation | null>((get) => {
  const teamInformationMap = get(teamInformationMapAtom);
  const teamKind = get(teamKindsAtom).find((teamKind) => teamKind !== ATTENDED_TEAM_KIND);

  if (teamKind === undefined) return null;

  const teamInformation = teamInformationMap[teamKind];

  return teamInformation ? { ...teamInformation, teamKind } : null;
});

export const attendedTeamInformationAtom = atom<TeamInformation | null>((get) => {
  const teamInformationMap = get(teamInformationMapAtom);
  const teamInformation = teamInformationMap[ATTENDED_TEAM_KIND];

  return teamInformation ? { ...teamInformation, teamKind: ATTENDED_TEAM_KIND } : null;
});

export const teamInformationAtom = atom<TeamInformation>((get) => {
  const kind = get(teamInformationKindAtom);

  const unattendedTeamInformation = get(unattendedTeamInformationAtom);
  const attendedTeamInformation = get(attendedTeamInformationAtom);

  let teamInformation: TeamInformation | null = null;

  switch (kind) {
    case 'unattended-first': {
      teamInformation = unattendedTeamInformation ?? attendedTeamInformation;
      break;
    }
    case 'attended-first': {
      teamInformation = attendedTeamInformation ?? unattendedTeamInformation;
      break;
    }
    case 'unattended-only': {
      teamInformation = unattendedTeamInformation;
      break;
    }
    case 'attended-only': {
      teamInformation = attendedTeamInformation;
      break;
    }
  }

  if (teamInformation === null) {
    throw new Error(`Unexpected Error: No team information - ${kind}`);
  }

  return teamInformation;
});

export const teamIdAtom = atom((get) => {
  const teamInformation = get(teamInformationAtom);

  return teamInformation.team_id;
});

export const unattendedTeamIdAtom = atom((get) => {
  const teamInformation = get(unattendedTeamInformationAtom);
  if (teamInformation === null) {
    throw new Error('Unexpected Error: No team information');
  }

  return teamInformation.team_id;
});

export const teamRoleAtom = atom((get) => {
  const { role, group_scope } = get(teamInformationAtom);

  let teamRole: TeamRole;
  if (role === 'owner') teamRole = 'owner';
  else if (role === 'manager' && group_scope === 'all') teamRole = 'admin';
  else if (role === 'manager' && group_scope === 'part') teamRole = 'groupAdmin';
  else teamRole = 'member';

  const isOwner = teamRole === 'owner';
  const isMember = teamRole === 'member';
  const isAdmin = teamRole === 'admin';
  const isGroupAdmin = teamRole === 'groupAdmin';
  const isManager = teamRole === 'owner' || teamRole === 'admin' || teamRole === 'groupAdmin';

  return { role: teamRole, isOwner, isMember, isManager, isAdmin, isGroupAdmin };
});

export const teamIsResellAtom = atom((get) => {
  const teamInformation = get(teamInformationAtom);

  return teamInformation.is_resell;
});

export const teamExpirationAtom = atom((get) => {
  const { expires_at: teamExpirationDate } = get(teamInformationAtom);

  const isTeamExpired = isExpired(teamExpirationDate);
  const daysUntilTeamExpires = daysUntilExpiration(teamExpirationDate);

  return { teamExpirationDate, isTeamExpired, daysUntilTeamExpires };
});
