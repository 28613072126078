import { useAtomValue } from 'jotai';

import { useTeamControlContext } from './useTeamControlContext';

/**
 * Get the team metadata.
 */
export function useTeamMetadata() {
  const { atoms } = useTeamControlContext();

  return useAtomValue(atoms.teamMetadataAtom);
}
