import { useMutation } from '@tanstack/react-query';

import { uploadToS3 } from '@/services/customization';
import { base64ToBlob, getMimeType } from '@/utils/image';

export const useUploadImageToS3 = () => {
  const {
    mutateAsync: uploadImageToS3,
    isIdle,
    isPending: isUploadingImageToS3,
    isError: isUploadImageToS3Error,
    data: uploadImageToS3Result,
  } = useMutation({
    mutationFn: async ({ s3Url, customImageBase64 }: { s3Url: string; customImageBase64: string }) => {
      if (s3Url && customImageBase64) {
        const mimeType = getMimeType(customImageBase64);
        if (mimeType) {
          const blob = base64ToBlob(customImageBase64, mimeType);
          return await uploadToS3(s3Url, blob);
        }
      }
    },
  });
  return { uploadImageToS3, isIdle, isUploadingImageToS3, uploadImageToS3Result, isUploadImageToS3Error };
};
