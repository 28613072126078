import { useQuery } from '@tanstack/react-query';

import { useTeamInformation } from '@/models/TeamInformation';
import { infoQueryService } from '@/services/users';

export const useWindowsUpdateAccess = () => {
  const { teamKind } = useTeamInformation();
  const infoQuery = useQuery({
    queryKey: ['info-query', 'windows-update'],
    queryFn: () =>
      infoQueryService.execute({
        team_member_permissions: ['os_opportunities'],
      }),
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 5 * 60 * 1000, // 5 minutes
  });

  return {
    isFetching: infoQuery.isFetching,
    canAccess: Boolean(infoQuery.data?.[teamKind]?.team_member_permissions.os_opportunities),
  };
};
