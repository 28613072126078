import { useRouter } from 'next/router';

import { TeamKind } from '@/models/TeamInformation';
import { isNewSOS } from '@/utils/team';
import useTeamInformation from '@/utils/useTeamInformation';

const DEFAULT_DOWNLOAD_URL = 'https://www.splashtop.com/downloads';

const TEAM_MAP_QUERY: Record<TeamKind, string> = {
  stp: '#pers',
  sos: '#pers',
  sba: '#ba',
  srs: '#rs',
  msp: '#rs',
  ste_custom: '#rs',
  splashtop: '#rs',
};

export const useDownloadUrl = () => {
  const router = useRouter();
  const teamInformation = useTeamInformation();

  if (!teamInformation) {
    return DEFAULT_DOWNLOAD_URL;
  } else if (router.pathname.includes('sos') || isNewSOS(teamInformation)) {
    return DEFAULT_DOWNLOAD_URL + '#sos';
  } else {
    return DEFAULT_DOWNLOAD_URL + (TEAM_MAP_QUERY[teamInformation.currentTeam] || '');
  }
};
