import { useTranslation } from 'react-i18next';

import { RippleMultipleSelect, RippleMultipleSelectOption, RippleMultipleSelectProps } from '@/design';
import { Day, dayList } from '@/utils/cron';

type DayPickerProps = Omit<RippleMultipleSelectProps<Day>, 'children' | 'placeholder'>;

const LAST_DAY = 'L';

export const DayPicker = ({ value, onChange, ...props }: DayPickerProps) => {
  const { t } = useTranslation();

  const days = [...dayList, LAST_DAY].map((day) => {
    switch (day) {
      case '1':
        return { value: '1', label: t('common:first_day') };
      case '2':
        return { value: '2', label: t('common:second_day') };
      case '3':
        return { value: '3', label: t('common:third_day') };
      case LAST_DAY:
        return { value: LAST_DAY, label: t('common:last_day') };
      default:
        return { value: day, label: t('common:other_day', { day }) };
    }
  });

  return (
    <RippleMultipleSelect placeholder="" value={value} onChange={onChange} {...props}>
      {days.map(({ value: optionValue, label }) => (
        <RippleMultipleSelectOption key={optionValue} value={optionValue} isDisabled={value?.length === 1 && value[0] === optionValue}>
          {label}
        </RippleMultipleSelectOption>
      ))}
    </RippleMultipleSelect>
  );
};
