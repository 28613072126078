import { useContext, useMemo } from 'react';

import { Box, HStack } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import type { ColumnDef, Row } from '@tanstack/react-table';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';

import { RippleIconButton, RippleRemoteSessionStart, RippleTooltip } from '@/design';
import { tableColumn } from '@/models/Computer';
import { UserComputer } from '@/services/computers/types';
import { ComputerHeaderSortMenu } from '@/showcase';
import { getOSIconType } from '@/utils/computers';

import {
  CellContainer,
  CellLeftScrollOverlay,
  CellRightScrollOverlay,
  ColumnPermissionsContext,
  ComputerNameContent,
  FROM_OTHER_GROUP_ID,
  HeaderLeftScrollOverlay,
  HeaderRightScrollOverlay,
  HighlightSearchKeyword,
  IPContent,
  MoreActions,
  TableHead,
  checkPermission,
  computeComputerIconState,
  groupNameMapAtom,
  leftOffsetAtom,
  sortGroup,
  sortingGroupForGroupViewAtom,
  useCanRemoteControl,
  useConnectModal,
  useShowScrollOverlay,
  useShowWaitingUpdate,
  viewModeAtom,
} from '../ComputerList';
import { columnIdMap, columnWidthMap } from './constants';

const columnHelper = createColumnHelper<UserComputer>();

export function useColumns(): Array<ColumnDef<UserComputer, any>> {
  const { t } = useTranslation();
  const columnPermissions = useContext(ColumnPermissionsContext);

  const groupNameMap = useAtomValue(groupNameMapAtom);

  const columns = useMemo(
    () => [
      columnHelper.accessor(columnIdMap.name, {
        id: columnIdMap.name,
        size: columnWidthMap.name,
        enableHiding: false,
        header: function RenderHeader({ column }) {
          const viewMode = useAtomValue(viewModeAtom);
          const showOverlay = useShowScrollOverlay('left');
          const leftOffset = useAtomValue(leftOffsetAtom);

          // for group view
          const [sortingGroup, setSortingGroup] = useAtom(sortingGroupForGroupViewAtom);
          const groupSortBy = sortingGroup.direction === 'asc' ? 'ASC' : 'DESC';
          const computerSortBy = computeComputerSortBy();

          switch (viewMode) {
            case 'computer': {
              return (
                <TableHead
                  w={`${column.getSize()}px`}
                  flexShrink={0}
                  position="sticky"
                  left={`${leftOffset}px`}
                  bgColor="neutral.10"
                  pl="60px"
                  isSortable
                  sortState={column.getIsSorted()}
                  onClick={column.getToggleSortingHandler()}
                >
                  {showOverlay && <HeaderLeftScrollOverlay />}
                  {t('common:name')}
                </TableHead>
              );
            }
            case 'group': {
              return (
                <TableHead
                  w={`${column.getSize()}px`}
                  flexShrink={0}
                  position="sticky"
                  left={`${leftOffset}px`}
                  bgColor="neutral.10"
                  pl="44px"
                >
                  <ComputerHeaderSortMenu
                    groupSortBy={groupSortBy}
                    onGroupSortByAsc={() => setSortingGroup({ direction: 'asc' })}
                    onGroupSortByDesc={() => setSortingGroup({ direction: 'desc' })}
                    computerSortBy={computerSortBy}
                    onComputerSortByAsc={() => column.toggleSorting(false)}
                    onComputerSortByDesc={() => column.toggleSorting(true)}
                  >
                    {showOverlay && <HeaderLeftScrollOverlay />}
                    {t('common:name')}
                  </ComputerHeaderSortMenu>
                </TableHead>
              );
            }
          }

          function computeComputerSortBy() {
            switch (column.getIsSorted()) {
              case false:
                return 'UNDEFINED';
              case 'asc':
                return 'ASC';
              case 'desc':
                return 'DESC';
            }
          }
        },
        cell: function RenderCell({ column, getValue, row }) {
          const { id: computerId, version, online_status, connected } = row.original;
          const computerIcon = getOSIconType(version);

          const viewMode = useAtomValue(viewModeAtom);
          const showOverlay = useShowScrollOverlay('left');
          const leftOffset = useAtomValue(leftOffsetAtom);

          const showWaitingUpdate = useShowWaitingUpdate(String(computerId));
          const state = computeComputerIconState({
            connected: Boolean(connected),
            online: Boolean(online_status),
            waitingUpdate: showWaitingUpdate,
          });

          return (
            <CellContainer
              w={`${column.getSize()}px`}
              borderLeft="1px"
              borderLeftColor="neutral.60"
              position="sticky"
              left={`${leftOffset}px`}
              zIndex={2}
              pl={viewMode === 'group' ? '44px' : undefined}
            >
              {showOverlay && <CellLeftScrollOverlay />}
              <Box zIndex={1}>
                <ComputerNameContent scope="user" computerId={computerId} name={getValue()} type={computerIcon} state={state} />
              </Box>
            </CellContainer>
          );
        },
      }),
      columnHelper.accessor(columnIdMap.host_name, {
        id: columnIdMap.host_name,
        size: columnWidthMap.host_name,
        header: ({ column }) => {
          return (
            <TableHead
              w={`${column.getSize()}px`}
              flexShrink={0}
              isSortable
              sortState={column.getIsSorted()}
              onClick={column.getToggleSortingHandler()}
            >
              {t('computer:deviceName')}
            </TableHead>
          );
        },
        cell: ({ column, getValue }) => {
          return (
            <CellContainer w={`${column.getSize()}px`}>
              <RippleTooltip label={getValue()}>
                <Box noOfLines={2}>
                  <HighlightSearchKeyword>{getValue()}</HighlightSearchKeyword>
                </Box>
              </RippleTooltip>
            </CellContainer>
          );
        },
      }),
      columnHelper.accessor((row) => row.group_id ?? FROM_OTHER_GROUP_ID, {
        id: columnIdMap.group_id,
        size: columnWidthMap.group_id,
        minSize: 0, // NOTE: tanstack/table has default minSize: 20
        filterFn: 'equals', // Keep ability of filtering by group id
        enableSorting: false,
        enableGlobalFilter: false,
        // Hidden column
        header: () => null,
        cell: () => null,
      }),
      ...checkPermission(
        columnPermissions.group,
        columnHelper.accessor((row) => groupNameMap[row.group_id ?? FROM_OTHER_GROUP_ID], {
          id: columnIdMap.groupName,
          size: columnWidthMap.groupName,
          sortingFn: (a, b) => {
            function transformToGroupItem(row: Row<UserComputer>) {
              const groupId = row.original.group_id ?? FROM_OTHER_GROUP_ID;
              return { id: String(groupId), name: groupNameMap[groupId] };
            }

            return sortGroup(transformToGroupItem(a), transformToGroupItem(b));
          },
          header: ({ column }) => {
            return (
              <TableHead
                w={`${column.getSize()}px`}
                flexShrink={0}
                isSortable
                sortState={column.getIsSorted()}
                onClick={column.getToggleSortingHandler()}
              >
                {t('computer:group')}
              </TableHead>
            );
          },
          cell: ({ column, getValue }) => {
            return (
              <CellContainer w={`${column.getSize()}px`}>
                <RippleTooltip label={getValue()}>
                  <Box noOfLines={2}>
                    <HighlightSearchKeyword>{getValue()}</HighlightSearchKeyword>
                  </Box>
                </RippleTooltip>
              </CellContainer>
            );
          },
        }),
      ),
      columnHelper.accessor(tableColumn.ipAddressWAN.getAccessor(), {
        id: columnIdMap.pubip,
        size: columnWidthMap.pubip,
        header: ({ column }) => {
          return (
            <TableHead
              w={`${column.getSize()}px`}
              flexShrink={0}
              isSortable
              sortState={column.getIsSorted()}
              onClick={column.getToggleSortingHandler()}
              bgColor="neutral.10"
            >
              {tableColumn.ipAddressWAN.getLabel(t)}
            </TableHead>
          );
        },
        cell: ({ column, getValue }) => {
          return (
            <CellContainer w={`${column.getSize()}px`}>
              <IPContent value={getValue()} />
            </CellContainer>
          );
        },
      }),
      columnHelper.accessor(tableColumn.ipAddressLAN.getAccessor(), {
        id: columnIdMap.local_ip,
        size: columnWidthMap.local_ip,
        header: ({ column }) => {
          return (
            <TableHead
              w={`${column.getSize()}px`}
              flexShrink={0}
              isSortable
              sortState={column.getIsSorted()}
              onClick={column.getToggleSortingHandler()}
              bgColor="neutral.10"
            >
              {tableColumn.ipAddressLAN.getLabel(t)}
            </TableHead>
          );
        },
        cell: ({ column, getValue }) => {
          return (
            <CellContainer w={`${column.getSize()}px`}>
              <IPContent value={getValue()} />
            </CellContainer>
          );
        },
      }),
      ...checkPermission(
        columnPermissions.notes,
        columnHelper.accessor(columnIdMap.note, {
          id: columnIdMap.note,
          size: columnWidthMap.note,
          enableSorting: false,
          header: ({ column }) => {
            return (
              <TableHead
                w={`${column.getSize()}px`}
                flexShrink={0}
                isSortable
                sortState={column.getIsSorted()}
                onClick={column.getToggleSortingHandler()}
                bgColor="neutral.10"
              >
                {t('computer:note')}
              </TableHead>
            );
          },
          cell: ({ column, getValue }) => {
            return (
              <CellContainer w={`${column.getSize()}px`}>
                <RippleTooltip label={getValue()}>
                  <Box noOfLines={2} w={`${column.getSize()}px`}>
                    <HighlightSearchKeyword>{getValue()}</HighlightSearchKeyword>
                  </Box>
                </RippleTooltip>
              </CellContainer>
            );
          },
        }),
      ),
      columnHelper.display({
        id: columnIdMap.spacer,
        size: columnWidthMap.spacer,
        header: function RenderHeader() {
          const leftOffset = useAtomValue(leftOffsetAtom);
          return <TableHead p="0" flex="1" minW={`${leftOffset}px`} bgColor="neutral.10" />;
        },
        cell: function RenderCell() {
          const leftOffset = useAtomValue(leftOffsetAtom);
          return <CellContainer p="0" flex="1" minW={`${leftOffset}px`} bgColor="white" />;
        },
      }),
      columnHelper.display({
        id: columnIdMap.action,
        size: columnWidthMap.action,
        enableHiding: false,
        header: function RenderHeader({ column }) {
          const showOverlay = useShowScrollOverlay('right');
          const leftOffset = useAtomValue(leftOffsetAtom);

          return (
            <TableHead w={`${column.getSize()}px`} flexShrink={0} position="sticky" right={`${leftOffset}px`} bgColor="neutral.10">
              {showOverlay && <HeaderRightScrollOverlay />}
            </TableHead>
          );
        },
        cell: function RenderCell({ column, row }) {
          const connectModal = useConnectModal();

          const showOverlay = useShowScrollOverlay('right');
          const leftOffset = useAtomValue(leftOffsetAtom);

          const canConnect = useCanRemoteControl(row.original);
          const computerId = row.original.id;
          const computerName = row.original.name;
          const note = row.original.note ?? null;
          const platform = getOSIconType(row.original.version);

          return (
            <CellContainer
              borderRight="1px"
              borderRightColor="neutral.60"
              w={`${column.getSize()}px`}
              position="sticky"
              right={`${leftOffset}px`}
              bgColor="white"
              zIndex={2}
            >
              {showOverlay && <CellRightScrollOverlay />}
              <HStack spacing="4px">
                <RippleTooltip label={t('computer:startRemoteSession')}>
                  <RippleIconButton
                    data-testid={`start-remote-session-${computerId}`}
                    aria-label="connect"
                    isDisabled={!canConnect}
                    icon={<RippleRemoteSessionStart color={canConnect ? 'blue.100' : 'neutral.80'} />}
                    onClick={() => {
                      connectModal.open({ computerId, platform });
                    }}
                  />
                </RippleTooltip>
                <MoreActions
                  computerId={computerId}
                  computerName={computerName}
                  note={note}
                  isDisabled={false}
                  computerData={row.original}
                />
              </HStack>
            </CellContainer>
          );
        },
      }),
    ],
    [t, columnPermissions, groupNameMap],
  );

  // Force assign column list type here or the columnHelper will lose type safety
  return columns as Array<ColumnDef<UserComputer, any>>;
}
