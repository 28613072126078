import { type TFunction } from 'next-i18next';

import { ComputerData } from '@/services/computers';

export const os = {
  getAccessor:
    () =>
    ({ os, os_product }: Pick<ComputerData, 'os' | 'os_product'>) => {
      // `os_product` is supported the streamer version 3620 or later.
      // If `os_product` is not available, use `os` (OS version) instead.

      return os_product ?? os ?? '';
    },
  getLabel: (t: TFunction) => t('computer:os'),
} as const;
