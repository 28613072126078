import { ReactElement, useState } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { OTableSortStateValues, OTableSortStates } from '@/components/ObservableTable/useObservableSort';
import {
  RippleButton,
  RippleMenu,
  RippleMenuButton,
  RippleMenuGroup,
  RippleMenuList,
  RippleSortOptionAsc16,
  RippleSortOptionDesc16,
  RippleTypography,
} from '@/design';

type SortBySates = Omit<OTableSortStateValues, 'UNDEFINED'>;

export type ComputerSelectorSortMenuProps = {
  groupSortBy: SortBySates;
  onGroupSortByAsc: () => void;
  onGroupSortByDesc: () => void;
  computerSortBy: SortBySates;
  onComputerSortByAsc: () => void;
  onComputerSortByDesc: () => void;
  children: (props: { isOpen?: boolean }) => ReactElement;
};

function AscButton({ sortBy, onClick }: { sortBy: SortBySates; onClick: () => void }) {
  const { t } = useTranslation();
  const [hoverColor, setHoverColor] = useState<string | null>(null);
  const handleMouseEnter = () => {
    setHoverColor('white');
  };
  const handleMouseLeave = () => {
    setHoverColor(null);
  };

  return (
    <RippleButton
      px="12px"
      height="24px"
      border={0}
      backgroundColor={sortBy === OTableSortStates.ASC ? 'blue.20' : 'white'}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Flex alignItems="center">
        <RippleSortOptionAsc16 color={hoverColor ?? (sortBy === OTableSortStates.ASC ? 'blue.200' : 'neutral.300')} />
        <RippleTypography variant="body02" ml="4px" color={hoverColor ?? (sortBy === OTableSortStates.ASC ? 'blue.200' : 'neutral.300')}>
          {t('common:asc')}
        </RippleTypography>
      </Flex>
    </RippleButton>
  );
}

function DescButton({ sortBy, onClick }: { sortBy: SortBySates; onClick: () => void }) {
  const { t } = useTranslation();
  const [hoverColor, setHoverColor] = useState<string | null>(null);
  const handleMouseEnter = () => {
    setHoverColor('white');
  };
  const handleMouseLeave = () => {
    setHoverColor(null);
  };

  return (
    <RippleButton
      ml="4px"
      px="12px"
      height="24px"
      border={0}
      backgroundColor={sortBy === OTableSortStates.DESC ? 'blue.20' : 'white'}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Flex alignItems="center">
        <RippleSortOptionDesc16 color={hoverColor ?? (sortBy === OTableSortStates.DESC ? 'blue.200' : 'neutral.300')} />
        <RippleTypography variant="body02" ml="4px" color={hoverColor ?? (sortBy === OTableSortStates.DESC ? 'blue.200' : 'neutral.300')}>
          {t('common:desc')}
        </RippleTypography>
      </Flex>
    </RippleButton>
  );
}

export default function ComputerSelectorSortMenu(props: ComputerSelectorSortMenuProps) {
  const { groupSortBy, onGroupSortByAsc, onGroupSortByDesc, computerSortBy, onComputerSortByAsc, onComputerSortByDesc, children } = props;

  const { t } = useTranslation();

  return (
    <RippleMenu closeOnBlur placement="right-start">
      {({ isOpen }) => {
        return (
          <>
            <RippleMenuButton
              aria-label="Filters"
              data-testid="filter-button"
              borderRadius="4px"
              padding="1px 2px 1px 4px"
              backgroundColor={isOpen ? 'blue.20' : 'transparent'}
              _hover={{ bg: 'blue.10' }}
            >
              <Flex justifyContent="center" alignItems="center">
                {children({ isOpen })}
              </Flex>
            </RippleMenuButton>
            <RippleMenuList width="336px" zIndex={10}>
              <RippleMenuGroup title="Sort by" mx="12px" color="neutral.100">
                <Flex width="100%" justifyContent="space-between" alignItems="center" p="4px 12px">
                  <RippleTypography
                    variant="body02"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    title={t('computer:selector.groupName')}
                  >
                    {t('computer:selector.groupName')}
                  </RippleTypography>
                  <Box flexShrink={0}>
                    <AscButton sortBy={groupSortBy} onClick={onGroupSortByAsc} />
                    <DescButton sortBy={groupSortBy} onClick={onGroupSortByDesc} />
                  </Box>
                </Flex>
                <Flex width="100%" justifyContent="space-between" alignItems="center" p="4px 12px">
                  <RippleTypography
                    variant="body02"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    title={t('computer:computerName')}
                  >
                    {t('computer:computerName')}
                  </RippleTypography>
                  <Box flexShrink={0}>
                    <AscButton sortBy={computerSortBy} onClick={onComputerSortByAsc} />
                    <DescButton sortBy={computerSortBy} onClick={onComputerSortByDesc} />
                  </Box>
                </Flex>
              </RippleMenuGroup>
            </RippleMenuList>
          </>
        );
      }}
    </RippleMenu>
  );
}
