import { useCallback } from 'react';

import { useAtomValue } from 'jotai';
import { selectAtom } from 'jotai/utils';
import isEqual from 'lodash/isEqual';

import { GranularControlKey } from '@/services/teams/granular_controls/types';

import { useTeamControlContext } from './useTeamControlContext';

/**
 * Get the granular control value for a specific key.
 */
export function useFeatureGranularControlValue<Key extends GranularControlKey = GranularControlKey>(granularControlKey: Key | undefined) {
  const { atoms } = useTeamControlContext();

  const featureGranularControlAtom = selectAtom(
    atoms.granularControlAtom,
    useCallback(
      (granularControl) => {
        const currentControlMap = granularControl;

        return granularControlKey ? currentControlMap[granularControlKey] : undefined;
      },
      [granularControlKey],
    ),
    isEqual,
  );

  return useAtomValue(featureGranularControlAtom) ?? null;
}
