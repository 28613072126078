import { z } from 'zod';

import { commonResponseSchema } from '@/services/schemas';

import {
  baseFieldAPISchemaWithoutId,
  checkboxOnlyAPISchema,
  comboBoxOnlyAPISchema,
  commonSurveyFormSchema,
  csatOnlyAPISchema,
  longTextOnlyAPISchema,
  npsOnlyAPISchema,
  radioButtonOnlyAPISchema,
  shortTextOnlyAPISchema,
} from '../type';

export const viewSurveyBaseFieldAPISchema = baseFieldAPISchemaWithoutId.merge(
  z.object({
    id: z.number(),
  }),
);

const shortTextFieldViewAPISchema = viewSurveyBaseFieldAPISchema.merge(shortTextOnlyAPISchema);
const longTextFieldViewAPISchema = viewSurveyBaseFieldAPISchema.merge(longTextOnlyAPISchema);
const comboBoxFieldViewAPISchema = viewSurveyBaseFieldAPISchema.merge(comboBoxOnlyAPISchema);
const radioButtonFieldViewAPISchema = viewSurveyBaseFieldAPISchema.merge(radioButtonOnlyAPISchema);
const checkboxFieldViewAPISchema = viewSurveyBaseFieldAPISchema.merge(checkboxOnlyAPISchema);
const npsFieldViewAPISchema = viewSurveyBaseFieldAPISchema.merge(npsOnlyAPISchema);
const csatFieldViewAPISchema = viewSurveyBaseFieldAPISchema.merge(csatOnlyAPISchema);

const questionnaireFieldViewAPISchema = z.discriminatedUnion('kind', [
  shortTextFieldViewAPISchema,
  longTextFieldViewAPISchema,
  comboBoxFieldViewAPISchema,
  radioButtonFieldViewAPISchema,
  checkboxFieldViewAPISchema,
  npsFieldViewAPISchema,
  csatFieldViewAPISchema,
]);

export type QuestionnaireFieldAPI = z.infer<typeof questionnaireFieldViewAPISchema>;

export const viewSurveySchema = commonSurveyFormSchema.extend({
  name: z.string().min(1), // must has form name
  questionnaire: z.array(questionnaireFieldViewAPISchema).min(1), // must has one question
  brand_image_url: z.string().nullable(),
  status: z.union([z.literal(1), z.literal(0)]),
});

export type ViewSurvey = z.infer<typeof viewSurveySchema>;

export const viewSurveyErrorSchema = commonResponseSchema.merge(
  z.object({
    result: z.union([z.literal(40403), z.literal(40404), z.literal(41403), z.literal(40422)]),
  }),
);
