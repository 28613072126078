import { forwardRef } from '@chakra-ui/react';
import { TableComponents } from 'react-virtuoso';

import { OTableRowOrGroupList, OTableSourceData, OTableSourceGroup } from '@/components/ObservableTable/types';
import { OTableRowTypes } from '@/components/ObservableTable/useObservableTable';
import { OTableSelectStates } from '@/components/ObservableTable/utils/selection';
import { RippleTableLegacy, RippleTbodyLegacy, RippleTheadLegacy, RippleTrLegacy } from '@/design';

export const TableVirtuosoComponents: TableComponents = {
  // Fix warning for <tr> cannot appear as a child of <div>.
  // 1. copy from https://github.com/petyosi/react-virtuoso/blob/274731d5eb027859224e12931d6c63b9a6ec5f77/src/Table.tsx#L63
  // 2. replace tr and td with div
  FillerRow: ({ height }: { height: number }) => (
    <div>
      <div style={{ height: height, padding: 0, border: 0 }}></div>
    </div>
  ),
  Table: RippleTableLegacy,
  TableBody: forwardRef((props, ref) => <RippleTbodyLegacy display="table-row-group" ref={ref} {...props} />),
  TableHead: RippleTheadLegacy,
  TableRow: RippleTrLegacy,
};

export const makeTableVirtuosoComponents = <D extends OTableRowOrGroupList<OTableSourceData, OTableSourceGroup>>({
  top = 0,
  rows,
}: {
  top?: number;
  rows: D;
}): TableComponents<D[number]> => {
  return {
    ...TableVirtuosoComponents,
    TableHead: forwardRef(({ style, ...otherProps }, ref) => {
      return (
        <RippleTheadLegacy
          ref={ref}
          style={{
            ...style,
            top,
          }}
          {...otherProps}
        />
      );
    }),
    TableRow: forwardRef((props, ref) => {
      const index = props['data-item-index'];
      const row = rows[index];
      const nextRow = rows[index + 1];

      const isNextGroupRow = nextRow?.type === OTableRowTypes.GROUP;
      const isLastRow = nextRow === undefined;
      const isLastRowInGroup = isNextGroupRow || isLastRow;

      let sx;
      if (row.type === OTableRowTypes.ROW) {
        const isSelected = row?.selection?.isSelected ?? false;
        sx = {
          '.td': {
            bg: isSelected ? 'green.10' : 'white',
          },
          '&:hover .td': {
            bg: isSelected ? 'green.40' : 'blue.0',
          },
        };
      } else {
        const isSelected = row?.selection?.selectState === OTableSelectStates.ALL;
        sx = {
          '.group_td': {
            bg: isSelected ? 'green.20' : 'neutral.40',
          },
          '&:hover .group_td': {
            bg: isSelected ? 'green.40' : 'blue.0',
          },
        };
      }

      return <RippleTrLegacy sx={sx} pb={isLastRowInGroup ? '12px' : '0px'} ref={ref} {...props} />;
    }),
  };
};
