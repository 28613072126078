import { useContext, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';

import { useTeamId } from '@/models/TeamInformation';
import { UserComputer } from '@/services/computers';
import { getGroupList } from '@/services/group';
import type { Group } from '@/services/group/types';

import { ColumnPermissionsContext, DEFAULT_GROUP_ID, manageableGroupListAtom } from '../ComputerList';
import { useGroupConfig } from './useGroupConfig';

const groupListPlaceholder: Array<Group> = [];

export function useGroupData(computerListData: Array<UserComputer>) {
  const { t } = useTranslation();
  const teamId = useTeamId();

  const { shouldGetGroupName, canShowGroupFilter } = useGroupConfig();

  const setManageableGroupList = useSetAtom(manageableGroupListAtom);

  const columnPermissions = useContext(ColumnPermissionsContext);
  const groupListQueryKey = ['myComputer', 'groupList', teamId];
  const groupListQuery = useQuery({
    queryKey: groupListQueryKey,
    queryFn: async () => {
      const groupList = await getGroupList(teamId, {
        group_type: 'computer',
        members_count: false,
        computers_count: false,
        admins_count: false,
        member_status: [],
      });
      setManageableGroupList(groupList.map(({ id, name }) => ({ id: String(id), name })));
      return groupList;
    },
    gcTime: 0,
    enabled: columnPermissions.group && canShowGroupFilter,
  });
  const manageableGroupList = groupListQuery.data ?? groupListPlaceholder;

  const groupList = useMemo<Array<Pick<Group, 'id' | 'name'>>>(() => {
    if (shouldGetGroupName) {
      const groupIdNameMap: Record<string, string> = computerListData.reduce<Record<string, string>>((acc, computer) => {
        if (computer.group_id !== null) {
          const groupName = computer.group_id === Number(DEFAULT_GROUP_ID) ? t('computer:defaultGroup') : computer.group_name ?? '';
          acc[computer.group_id ?? 0] = groupName;
        }
        return acc;
      }, {});

      if (canShowGroupFilter) {
        manageableGroupList.forEach(({ id, name }) => {
          groupIdNameMap[id] = name;
        });
      }

      return Object.entries(groupIdNameMap).map(([groupId, groupName]) => ({ id: Number(groupId), name: groupName }));
    } else {
      // Owner, admin should show default group
      const result: Array<Pick<Group, 'id' | 'name'>> = [{ id: Number(DEFAULT_GROUP_ID), name: t('computer:defaultGroup') }].concat(
        manageableGroupList,
      );
      return result;
    }
  }, [t, computerListData, manageableGroupList, canShowGroupFilter, shouldGetGroupName]);

  return { groupListQuery, groupList, groupListQueryKey };
}
