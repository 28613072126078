import { MouseEventHandler } from 'react';

import { Box, FormControl } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { Controller, useFormContext } from 'react-hook-form';

import { RippleSingleSelect, RippleSingleSelectOption, RippleSingleSelectProps } from '@/design';
import { DragButton } from '@/modules/ServiceDesk/SurveyForm/Editor/FormEditor/EditMode/DragWrapper';
import { Label } from '@/modules/ServiceDesk/SurveyForm/Editor/FormEditor/ViewMode/Label';
import { NO_DEFAULT_OPTION } from '@/modules/ServiceDesk/SurveyForm/Editor/constant';
import { SurveyFormScenario, SurveyFormScenarioMap } from '@/modules/ServiceDesk/SurveyForm/Editor/types';

type ComboBoxFieldProps = {
  registerFieldName: string;
  defaultValue: RippleSingleSelectProps['defaultValue'];
  isRequired: RippleSingleSelectProps['isRequired'];
  isInvalid?: RippleSingleSelectProps['isInvalid'];
  labelValue: string;
  onClickLabel?: MouseEventHandler<HTMLLabelElement>;
  onMouseDown?: RippleSingleSelectProps['onMouseDown'];
  options: Array<{ option: string }> | null;
  scenario: SurveyFormScenario;
};

const comboBoxSharedProps = {
  id: 'combo-box',
  'aria-describedby': 'combo-box',
  borderRadius: '4px',
  mt: '4px',
};

export const ComboBoxField = ({
  registerFieldName,
  defaultValue,
  isRequired,
  isInvalid,
  labelValue,
  onClickLabel,
  onMouseDown,
  options,
  scenario,
}: ComboBoxFieldProps) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  const optionsSection =
    options &&
    options.map(({ option }) => (
      <RippleSingleSelectOption key={option} value={option}>
        {option}
      </RippleSingleSelectOption>
    ));

  const defaultPlaceholder = t('common:select_placeholder');
  return (
    <Box w="100%" p="16px 48px 24px" position="relative" role="group">
      {scenario === SurveyFormScenarioMap.editor && <DragButton />}
      <FormControl isRequired={isRequired} isInvalid={isInvalid}>
        <Label title={labelValue} scenario={scenario} onMouseDown={onClickLabel} />
        {scenario === SurveyFormScenarioMap.editor ? (
          <RippleSingleSelect
            placeholder={defaultPlaceholder}
            _hover={{ bg: 'blue.0', cursor: 'pointer' }}
            borderColor="neutral.300"
            menuProps={{ isOpen: false }}
            onMouseDown={onMouseDown}
            value={defaultValue ? String(defaultValue) : defaultPlaceholder}
            onChange={() => null}
            {...comboBoxSharedProps}
          >
            {optionsSection}
          </RippleSingleSelect>
        ) : (
          <Controller
            name={registerFieldName}
            control={control}
            defaultValue={defaultValue === NO_DEFAULT_OPTION ? undefined : defaultValue?.toString()}
            rules={{ required: isRequired }}
            render={({ field }) => {
              return (
                <RippleSingleSelect
                  placeholder={defaultPlaceholder}
                  borderColor={isInvalid ? 'red.100' : 'neutral.300'}
                  onMouseDown={onMouseDown}
                  {...comboBoxSharedProps}
                  {...field}
                >
                  {optionsSection}
                </RippleSingleSelect>
              );
            }}
          />
        )}
      </FormControl>
    </Box>
  );
};
