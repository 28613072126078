import { HStack } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useTranslation } from 'next-i18next';

import {
  RippleBodyText02,
  RippleButton,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalTitle,
  useRippleFlashMessage,
} from '@/design';
import { useTeamId } from '@/models/TeamInformation';
import { batchRebootComputers } from '@/services/computers';
import type { RebootComputerKind } from '@/services/computers';

import { checkRebootAtom, computerScopeAtom } from '../atoms';
import { useRemoveMoreActionsCache } from '../components/MoreActions/utils';
import { kindEnum } from './constants';

export const confirmModalAtom = atomWithReset<{
  isOpen: boolean;
  computerId: number | null;
  computerName: string | null;
  kind: RebootComputerKind | null;
}>({
  isOpen: false,
  computerId: null,
  computerName: null,
  kind: null,
});

export function useConfirmModal() {
  const set = useSetAtom(confirmModalAtom);

  return {
    open({ computerId, computerName, kind }: { computerId: number | null; computerName: string; kind: RebootComputerKind | null }) {
      set({ isOpen: true, computerId, computerName, kind });
    },
  };
}

export function ConfirmModal(): React.JSX.Element {
  const { t } = useTranslation();
  const { flashMessage } = useRippleFlashMessage();
  const teamId = useTeamId();

  const removeMoreActionsCache = useRemoveMoreActionsCache();

  const computerScope = useAtomValue(computerScopeAtom);

  const { isOpen, computerId, computerName, kind } = useAtomValue(confirmModalAtom);
  const resetModal = useResetAtom(confirmModalAtom);

  const checkReboot = useSetAtom(checkRebootAtom);

  const rebootMutation = useMutation({
    mutationFn: async ({ computerId, kind }: { computerId: number; kind: RebootComputerKind }) => {
      return batchRebootComputers.service([computerId], kind);
    },
    onSuccess: () => {
      checkReboot({ teamId, computerId: String(computerId) });
    },
    onError: (error) => {
      if (batchRebootComputers.errorHandling.targetNotAllow(error)) {
        flashMessage({ variant: 'error', title: t('common:noPermission') });
        if (computerId !== null) {
          removeMoreActionsCache({ computerScope, computerId });
        }
      } else {
        flashMessage({ variant: 'error', title: t('common:unexpectedError') });
      }
    },
    onSettled: () => {
      resetModal();
    },
  });

  const titleMap: Record<RebootComputerKind, string> = {
    [kindEnum.restart_streamer]: t('computer:remoteReboot.confirmModal.restart_streamer.title'),
    [kindEnum.normal_reboot]: t('computer:remoteReboot.confirmModal.reboot.title'),
    [kindEnum.safe_mode_reboot]: t('computer:remoteReboot.confirmModal.safe_mode_reboot.title'),
    [kindEnum.shut_down]: t('computer:remoteReboot.confirmModal.shut_down.title'),
    [kindEnum.log_off]: t('computer:remoteReboot.confirmModal.log_off.title'),
  };
  const askMap: Record<RebootComputerKind, string> = {
    [kindEnum.restart_streamer]: t('computer:remoteReboot.confirmModal.restart_streamer.content1', { computerName }),
    [kindEnum.normal_reboot]: t('computer:remoteReboot.confirmModal.reboot.content1', { computerName }),
    [kindEnum.safe_mode_reboot]: t('computer:remoteReboot.confirmModal.safe_mode_reboot.content1', { computerName }),
    [kindEnum.shut_down]: t('computer:remoteReboot.confirmModal.shut_down.content1', { computerName }),
    [kindEnum.log_off]: t('computer:remoteReboot.confirmModal.log_off.content1', { computerName }),
  };
  const descriptionMap: Record<RebootComputerKind, string> = {
    [kindEnum.restart_streamer]: t('computer:remoteReboot.confirmModal.restart_streamer.content2'),
    [kindEnum.normal_reboot]: t('computer:remoteReboot.confirmModal.reboot.content2'),
    [kindEnum.safe_mode_reboot]: t('computer:remoteReboot.confirmModal.safe_mode_reboot.content2'),
    [kindEnum.shut_down]: t('computer:remoteReboot.confirmModal.shut_down.content2'),
    [kindEnum.log_off]: t('computer:remoteReboot.confirmModal.log_off.content2'),
  };
  const submitLabelMap: Record<RebootComputerKind, string> = {
    [kindEnum.restart_streamer]: t('computer:remoteReboot.confirmModal.submit_restart'),
    [kindEnum.normal_reboot]: t('computer:remoteReboot.confirmModal.submit_reboot'),
    [kindEnum.safe_mode_reboot]: t('computer:remoteReboot.confirmModal.submit_reboot'),
    [kindEnum.shut_down]: t('computer:remoteReboot.confirmModal.submit_shutdown'),
    [kindEnum.log_off]: t('computer:remoteReboot.confirmModal.submit_logoff'),
  };

  return (
    <RippleModal
      isOpen={isOpen}
      onClose={resetModal}
      size="2xl"
      closeOnOverlayClick={!rebootMutation.isPending}
      closeOnEsc={!rebootMutation.isPending}
    >
      <RippleModalOverlay />
      {kind && (
        <RippleModalContent>
          <RippleModalHeader>
            <RippleModalTitle>{titleMap[kind]}</RippleModalTitle>
          </RippleModalHeader>
          <RippleModalBody>
            <RippleBodyText02 mb="12px">{askMap[kind]}</RippleBodyText02>
            <RippleBodyText02>{descriptionMap[kind]}</RippleBodyText02>
          </RippleModalBody>
          <RippleModalFooter>
            <HStack spacing="8px">
              <RippleButton variant="grayScaleGhost" onClick={resetModal}>
                {t('common:cancel')}
              </RippleButton>
              <RippleButton
                variant="primary"
                isLoading={rebootMutation.isPending}
                onClick={() => {
                  if (computerId && kind) {
                    rebootMutation.mutate({ computerId, kind });
                  }
                }}
              >
                {submitLabelMap[kind]}
              </RippleButton>
            </HStack>
          </RippleModalFooter>
        </RippleModalContent>
      )}
    </RippleModal>
  );
}
