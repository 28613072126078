import { atom } from 'jotai';

import { PolicyToCreate } from '../types';

export const cloneDetailsAtom = atom<PolicyToCreate>({
  name: '',
  description: '',
  active: false,
  parentId: null,
  platform: 'Windows',
});
