import { useContext, useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';

import { RippleDownloadBusinessApp } from '@/design';
import { featureControl } from '@/feature/toggle';
import { tableColumn } from '@/models/Computer';
import { UserComputer } from '@/services/computers';
import type { Group } from '@/services/group/types';

import {
  ActionBar,
  ActionBarItem,
  ActionBarLeftSection,
  ActionBarRightSection,
  ColumnPermissionsContext,
  ComputerList,
  ComputerListProvider,
  FiltersDrawer,
  FiltersDrawerSections,
  StickyLeftWrapper,
  TableSettingsDrawer,
  TableSettingsSection,
  checkPermission,
  resetUpdateQueueAtom,
  useInitializeColumnVisibility,
  useInitializeExpandedState,
  useInitializeViewMode,
} from '../ComputerList';
import type { ComputerItemBase, ConfigurableColumn } from '../ComputerList';
import { PageFrame } from './PageFrame';
import { useColumns } from './columns';
import { useColumns_SBA } from './columns_SBA';
import { columnIdMap } from './constants';
import { useComputerDataSBA, useComputerDataSRS } from './useComputerData';
import { useGroupConfig } from './useGroupConfig';
import { useGroupData } from './useGroupData';

const FT_PHASE_2 = featureControl.getToggle('CF_355__computer_list_UI_renewal__phase_2');

export function MyComputersSRS(): React.JSX.Element {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const columnPermissions = useContext(ColumnPermissionsContext);

  const columns = useColumns();

  const configurableColumns: Array<ConfigurableColumn> = useMemo(
    () => [
      { columnId: columnIdMap.host_name, label: t('computer:deviceName'), defaultVisibility: true },
      ...checkPermission(columnPermissions.group, { columnId: columnIdMap.groupName, label: t('computer:group') }),
      { columnId: columnIdMap.pubip, label: `${t('computer:ipAddress')} (WAN)`, defaultVisibility: false },
      { columnId: columnIdMap.local_ip, label: `${t('computer:ipAddress')} (LAN)`, defaultVisibility: false },
      ...checkPermission(columnPermissions.notes, { columnId: columnIdMap.note, label: t('computer:note'), defaultVisibility: true }),
    ],
    [t, columnPermissions],
  );

  const { computerListData, refresh: refreshComputerData, isFetching: isFetchingComputerData } = useComputerDataSRS();
  const { groupListQuery, groupList, groupListQueryKey } = useGroupData(computerListData);

  const resetUpdateQueue = useSetAtom(resetUpdateQueueAtom);

  const isLoading = groupListQuery.isFetching || isFetchingComputerData;

  const { showFilterSettings } = useFilterSettings();

  function handleRefresh() {
    if (columnPermissions.group) {
      queryClient.removeQueries({ queryKey: groupListQueryKey, exact: true });
      groupListQuery.refetch();
    }

    refreshComputerData();

    resetUpdateQueue();
  }

  return (
    <MyComputersCore
      computerListData={computerListData}
      isLoading={isLoading}
      groupList={groupList}
      configurableColumns={configurableColumns}
      columns={columns}
      actionBar={
        <ActionBar isLoading={isLoading}>
          <ActionBarLeftSection>
            <ActionBarItem.AddComputer />
            <RippleDownloadBusinessApp emphasis="secondary" />
            <ActionBarItem.Refresh onClick={handleRefresh} />
          </ActionBarLeftSection>

          <ActionBarRightSection>
            <ActionBarItem.ViewSwitch />
            <ActionBarItem.ViewSettings />
            {showFilterSettings && <ActionBarItem.FilterSettings />}
            <ActionBarItem.SearchBar />
          </ActionBarRightSection>
        </ActionBar>
      }
    />
  );
}

export function MyComputersSBA(): React.JSX.Element {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const columnPermissions = useContext(ColumnPermissionsContext);

  const columns = useColumns_SBA();

  const configurableColumns: Array<ConfigurableColumn> = useMemo(
    () => [
      { columnId: columnIdMap.host_name, label: t('computer:deviceName'), defaultVisibility: true },
      { columnId: columnIdMap.computerOwner, label: tableColumn.computerOwner.getLabel(t), defaultVisibility: true },
      ...checkPermission(columnPermissions.group, { columnId: columnIdMap.groupName, label: t('computer:group') }),
      { columnId: columnIdMap.pubip, label: `${t('computer:ipAddress')} (WAN)`, defaultVisibility: false },
      { columnId: columnIdMap.local_ip, label: `${t('computer:ipAddress')} (LAN)`, defaultVisibility: false },
      ...checkPermission(columnPermissions.notes, { columnId: columnIdMap.note, label: t('computer:note'), defaultVisibility: true }),
    ],
    [t, columnPermissions],
  );

  const { computerListData, refresh: refreshComputerData, isFetching: isFetchingComputerData } = useComputerDataSBA();
  const { groupListQuery, groupList, groupListQueryKey } = useGroupData(computerListData);

  const resetUpdateQueue = useSetAtom(resetUpdateQueueAtom);

  const isLoading = groupListQuery.isFetching || isFetchingComputerData;

  const { showFilterSettings } = useFilterSettings();

  function handleRefresh() {
    if (columnPermissions.group) {
      queryClient.removeQueries({ queryKey: groupListQueryKey, exact: true });
      groupListQuery.refetch();
    }

    refreshComputerData();

    resetUpdateQueue();
  }

  return (
    <MyComputersCore
      computerListData={computerListData}
      isLoading={isLoading}
      groupList={groupList}
      configurableColumns={configurableColumns}
      columns={columns}
      actionBar={
        <ActionBar isLoading={isLoading}>
          <ActionBarLeftSection>
            <ActionBarItem.AddComputer_SBA />
            <RippleDownloadBusinessApp emphasis="secondary" />
            <ActionBarItem.Refresh onClick={handleRefresh} />
          </ActionBarLeftSection>

          <ActionBarRightSection>
            <ActionBarItem.ViewSwitch />
            <ActionBarItem.ViewSettings />
            {showFilterSettings && <ActionBarItem.FilterSettings />}
            <ActionBarItem.SearchBar />
          </ActionBarRightSection>
        </ActionBar>
      }
    />
  );
}

type MyComputersCoreProps = {
  computerListData: Array<UserComputer>;
  isLoading: boolean;
  groupList: Array<Pick<Group, 'id' | 'name'>>;
  configurableColumns: Array<ConfigurableColumn>;
  columns: Array<ColumnDef<ComputerItemBase, unknown>>;
  actionBar: React.JSX.Element;
};
function MyComputersCore(props: MyComputersCoreProps): React.JSX.Element {
  const { computerListData, isLoading, configurableColumns, columns, actionBar, groupList } = props;

  const columnPermissions = useContext(ColumnPermissionsContext);

  const { showFilterSettings, showFilterGroup } = useFilterSettings();

  useInitializeViewMode({ storageKey: 'splashtop__myComputers__viewMode', hasPermission: columnPermissions.group });
  useInitializeColumnVisibility({ storageKey: 'splashtop__myComputers__column', configurableColumns });
  useInitializeExpandedState({ storageKey: 'splashtop__myComputers__expandedState', hasPermission: columnPermissions.group });

  return (
    <PageFrame>
      <ComputerListProvider scope="user" isLoading={isLoading} data={computerListData} groupList={groupList}>
        <StickyLeftWrapper position="sticky" top="54px" zIndex={10}>
          {actionBar}
        </StickyLeftWrapper>
        <ComputerList columns={columns} hasSelection={false} />
      </ComputerListProvider>

      <TableSettingsDrawer>
        <TableSettingsSection.ViewSwitch />
        {FT_PHASE_2 && <TableSettingsSection.RowHeightSetting />}
        <TableSettingsSection.ColumnVisibilitySetting configurableColumns={configurableColumns} />
      </TableSettingsDrawer>

      {showFilterSettings && <FiltersDrawer>{showFilterGroup && <FiltersDrawerSections.FilterGroup />}</FiltersDrawer>}
    </PageFrame>
  );
}

function useFilterSettings() {
  const { canShowGroupFilter } = useGroupConfig();
  const columnPermissions = useContext(ColumnPermissionsContext);

  const showFilterGroup = canShowGroupFilter && columnPermissions.group;
  const showFilterSettings = showFilterGroup;

  return { showFilterSettings, showFilterGroup };
}
