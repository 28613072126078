import { useState } from 'react';

import { HStack, VStack } from '@chakra-ui/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useTranslation } from 'next-i18next';

import {
  RippleBodyText02,
  RippleButton,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalTitle,
  RippleRadio,
  RippleRadioGroup,
} from '@/design';
import { type BatchRebootComputersOption, batchRebootComputersOptionMap } from '@/services/computers';

import { useConfirmModal } from './ConfirmModal';
import { TargetComputerList } from './types';

export const selectKindModalAtom = atomWithReset<{ isOpen: boolean; targetComputerList: TargetComputerList }>({
  isOpen: false,
  targetComputerList: [],
});

export function useSelectKindModal() {
  const set = useSetAtom(selectKindModalAtom);

  return {
    open({ targetComputerList }: { targetComputerList: TargetComputerList }) {
      set({ isOpen: true, targetComputerList });
    },
  };
}

export function SelectKindModal(): React.JSX.Element {
  const { t } = useTranslation();
  const { isOpen, targetComputerList } = useAtomValue(selectKindModalAtom);
  const resetModal = useResetAtom(selectKindModalAtom);

  const confirmModal = useConfirmModal();

  const [kind, setKind] = useState<BatchRebootComputersOption | undefined>(undefined);

  function handleSubmit() {
    if (kind) {
      confirmModal.open({ targetComputerList, kind });
      resetModal();
    }
  }

  function handleClose() {
    resetModal();
    setKind(undefined);
  }

  return (
    <RippleModal size="xl" isOpen={isOpen} onClose={handleClose}>
      <RippleModalOverlay />
      <RippleModalContent>
        <RippleModalHeader>
          <RippleModalTitle>{t('computer:rebootComputers.selectKindModal.title')}</RippleModalTitle>
        </RippleModalHeader>

        <RippleModalBody display="flex" flexDirection="column">
          <RippleRadioGroup
            value={kind}
            onChange={(newValue) => {
              setKind(newValue as BatchRebootComputersOption);
            }}
          >
            <VStack spacing="4px" alignItems="start">
              <RippleRadio data-testid="restart_streamer" py="4px" value={batchRebootComputersOptionMap.restart_streamer}>
                <VStack spacing="4px" alignItems="start">
                  <RippleBodyText02>{t('computer:rebootComputers.selectKindModal.option.restart_streamer.label')}</RippleBodyText02>
                  <RippleBodyText02 color="neutral.300">
                    {t('computer:rebootComputers.selectKindModal.option.restart_streamer.description')}
                  </RippleBodyText02>
                </VStack>
              </RippleRadio>
              <RippleRadio data-testid="normal_reboot" py="4px" value={batchRebootComputersOptionMap.normal_reboot}>
                <RippleBodyText02>{t('computer:rebootComputers.selectKindModal.option.reboot.label')}</RippleBodyText02>
              </RippleRadio>
            </VStack>
          </RippleRadioGroup>
        </RippleModalBody>

        <RippleModalFooter>
          <HStack spacing="8px">
            <RippleButton data-testid="cancel-button" variant="grayScaleGhost" onClick={handleClose}>
              {t('common:cancel')}
            </RippleButton>
            <RippleButton data-testid="ok-button" variant="primary" onClick={handleSubmit} isDisabled={kind === undefined}>
              {t('common:ok')}
            </RippleButton>
          </HStack>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
}
