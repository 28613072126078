import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';

import { getUserBasicProfile } from '@/services/common';

import { basicProfileAtom } from './atoms';

export function useRefreshBasicProfile() {
  const setBasicProfile = useSetAtom(basicProfileAtom);

  const basicProfileQuery = useQuery({
    queryKey: ['basicProfile'],
    queryFn: () => getUserBasicProfile(),
    enabled: false,
  });

  useEffect(() => {
    if (basicProfileQuery.data) {
      setBasicProfile(basicProfileQuery.data);
    }
  }, [basicProfileQuery.data, setBasicProfile]);

  return basicProfileQuery.refetch;
}
