import {
  OTableGroup,
  OTableResult,
  OTableRow,
  OTableRowOrGroupList,
  OTableSourceData,
  OTableSourceGroup,
} from '@/components/ObservableTable/types';
import { SearchColumnsParam, SearchParams } from '@/components/ObservableTable/useObservableSearch';
import { OTableRowTypes, OTableViewStates } from '@/components/ObservableTable/useObservableTable';

const makeIsMatch =
  <D extends OTableSourceData, G extends OTableSourceGroup>(
    row: OTableRow<D> | OTableGroup<G>,
    keyword: string,
    keywordUpperCase: string,
  ) =>
  (column: SearchColumnsParam) => {
    const value = (row.original as Record<string, unknown>)[column.accessor];

    if (typeof value === 'string') {
      return column?.caseSensitive ? value.includes(keyword) : value.toLocaleUpperCase().includes(keywordUpperCase);
    } else if (typeof value === 'number') {
      return `${value}`.includes(keyword);
    }

    return false;
  };

export const makeSearchPipe =
  <D extends OTableSourceData, G extends OTableSourceGroup>(tableResult: OTableResult<D, G>) =>
  (searchParams: SearchParams): OTableResult<D, G> => {
    const { keyword, keywordUpperCase, columns, groupColumns } = searchParams;

    if (keyword === '') {
      return tableResult;
    }

    const { rows, states } = tableResult;

    let nextRows: OTableRowOrGroupList<D, G> = rows
      .filter((row): row is OTableRow<D> => row.type === OTableRowTypes.ROW)
      .filter((row) => columns.some(makeIsMatch(row, keyword, keywordUpperCase)));

    if (states.viewState === OTableViewStates.GROUP) {
      const tmpGroups = rows.filter((row): row is OTableGroup<G> => row.type === OTableRowTypes.GROUP);
      const filterGroupHashMap = tmpGroups.reduce<Record<string, boolean>>((acc, row) => {
        acc[row.original.id] = false;
        return acc;
      }, {});

      if (groupColumns) {
        tmpGroups
          .filter((row) => groupColumns.some(makeIsMatch(row, keyword, keywordUpperCase)))
          .forEach((row) => {
            filterGroupHashMap[row.original.id] = true;
          });
      }

      const filteredHashMap = nextRows.reduce<Record<string, true>>((acc, row) => {
        if (row.type === OTableRowTypes.ROW && row.original.gid) {
          filterGroupHashMap[row.original.gid] = true;
          acc[`${row.original.id}-${row.original.gid}`] = true;
        }
        return acc;
      }, {});

      nextRows = rows.filter((row) => {
        if (row.type === OTableRowTypes.GROUP) {
          return filterGroupHashMap[row.original.id];
        }
        return row.original.gid ? filteredHashMap[`${row.original.id}-${row.original.gid}`] : true;
      });
    }

    nextRows = nextRows.map((row) => {
      const nextRow = {
        ...row,
        isFiltered: true,
        filterMatches: {
          ...row.filterMatches,
          isSearched: true,
        },
      };

      if (row.type === OTableRowTypes.GROUP) {
        if (groupColumns) {
          nextRow.filterMatches.isSearched = groupColumns.some(makeIsMatch(row, keyword, keywordUpperCase));
        } else {
          nextRow.filterMatches.isSearched = false;
        }
      }

      return nextRow;
    });

    return {
      ...tableResult,
      rows: nextRows,
    };
  };
