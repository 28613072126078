import { EditableSettingType, RoleSwitchSetting, RoleSwitchUpdateKey } from '@/services/teamSettings';
import { GranularControlKey, GranularControlSetting } from '@/services/teams/granular_controls/types';

import { useFeatureGranularControlMutation } from './useFeatureGranularControlMutation';
import { useFeatureRoleSwitchMutation } from './useFeatureRoleSwitchMutation';

type UseOldRoleSwitchMutationProps = {
  settingKey: EditableSettingType;
  roleSwitchUpdateKey: RoleSwitchUpdateKey;
  granularControlKey: GranularControlKey;
};

/**
 * The old role switch does not have group admin role.
 */
type RoleMapForOldRoleSwitch = Omit<RoleSwitchSetting, 'group_admin'>;

/**
 * Mutation hook for old role switch that using `mode` to determine the role switch value.
 *
 * May be removed after all teams are migrated to the new role switch layout.
 */
export function useOldRoleSwitchMutate({ settingKey, roleSwitchUpdateKey, granularControlKey }: UseOldRoleSwitchMutationProps) {
  const roleSwitchMutation = useFeatureRoleSwitchMutation();
  const granularControlMutation = useFeatureGranularControlMutation();

  return function mutate(roleMap: RoleMapForOldRoleSwitch) {
    const newRoleMap = fillGroupAdminValue(roleMap);

    roleSwitchMutation.mutate({
      type: 'multiple',
      settingKey,
      updateKey: roleSwitchUpdateKey,
      roleMap: newRoleMap,
    });

    granularControlMutation.mutate({
      type: 'normal',
      controlKey: granularControlKey,
      value: transformRoleMapToGranularControlValue(newRoleMap),
    });
  };
}

/**
 * Due to the old role switch does not have group admin role, we need to fill the value of group admin with admin.
 */
function fillGroupAdminValue(roleMap: RoleMapForOldRoleSwitch): RoleSwitchSetting {
  const result: RoleSwitchSetting = {};

  if (roleMap.manager !== undefined) {
    result.manager = roleMap.manager;
    result.group_admin = roleMap.manager;
  }

  if (roleMap.member !== undefined) {
    result.member = roleMap.member;
  }

  return result;
}

function transformRoleMapToGranularControlValue(roleMap: RoleSwitchSetting): GranularControlSetting {
  return Object.fromEntries(Object.entries(roleMap).map(([role, enabled]) => [role, enabled ? 'on' : 'off']));
}
