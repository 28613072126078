import type { Draft } from 'immer';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import type { PrimitiveAtom } from 'jotai';
import { atomWithImmer } from 'jotai-immer';
import { atomFamily, atomWithReset, useResetAtom } from 'jotai/utils';

import { queryTargetKeys } from '../constants';
import type { TabKind, TeamMetadata, TourKind } from '../types';
import { hasSearchParamsTarget, useTeamMetadata } from '../utils';

export type CacheAtom<T> = ReturnType<typeof createCacheAtom<T>>;
type CacheAtomUpdatePayload<T> = { type: 'update' | 'overwrite'; updateDraft: (draft: Draft<T>) => void } | { type: 'save' | 'rollback' };
type CreateCacheAtomsProps<T> = { data: T };
export function createCacheAtom<T>({ data }: CreateCacheAtomsProps<T>) {
  const currentValueAtom = atomWithImmer<T>(data);
  const previousValueAtom = atomWithImmer<T>(data);
  return atom(
    (get) => get(currentValueAtom),
    (get, set, update: CacheAtomUpdatePayload<T>) => {
      switch (update.type) {
        case 'update': {
          set(currentValueAtom, update.updateDraft);
          break;
        }
        case 'save': {
          set(previousValueAtom, get(currentValueAtom));
          break;
        }
        case 'rollback': {
          set(currentValueAtom, get(previousValueAtom));
          break;
        }
        case 'overwrite': {
          set(currentValueAtom, update.updateDraft);
          set(previousValueAtom, update.updateDraft);
        }
      }
    },
  );
}

export const tourStateMapAtom = atom<Record<TourKind, PrimitiveAtom<boolean>>>({
  showMultipleTeamsTour: atom(false),
  showDifferentAccessesTour: atom(false),
});

export const tabStateMapAtom = atom<Record<TabKind, PrimitiveAtom<number>>>({
  multipleTeamsTab: atom<number>(0),
  differentAccessesTab: atom<number>(0),
});

tabStateMapAtom.onMount = (setTabStateMapAtom) => {
  if (hasSearchParamsTarget({ targetKey: queryTargetKeys.serviceDesk })) {
    const SOS_TAB_INDEX = 1;
    setTabStateMapAtom((tabStateMapAtom) => {
      return { ...tabStateMapAtom, multipleTeamsTab: atom<number>(SOS_TAB_INDEX) };
    });
  }
};

/**
 * @deprecated Use `createModalAtomFamily` instead
 */
type ModalAtomFamily_deprecated<Value> = ReturnType<typeof modalAtom<Value>>;
/**
 * @deprecated Use `createModalAtomFamily` instead
 */
export function modalAtom<Value = unknown>(initialValue?: Value) {
  return atomFamily(
    (_params: { teamId: number }) => atomWithReset<Value & { isOpen: boolean }>({ ...(initialValue ?? ({} as Value)), isOpen: false }),
    (aParams, bParams) => aParams.teamId === bParams.teamId,
  );
}

/**
 * @deprecated Use `useModalAtom_new` instead
 */
export function useModalAtom<Value>(modalAtomFamily: ModalAtomFamily_deprecated<Value>): {
  isOpen: boolean;
  value: Omit<Value, 'isOpen'>;
  resetModal: () => void;
} {
  const atomScope = useModalAtomScope();
  const currentAtom = modalAtomFamily({ teamId: atomScope });

  const { isOpen, ...otherValue } = useAtomValue(currentAtom);
  const value = otherValue as Omit<Value, 'isOpen'>;
  const resetModal = useResetAtom(currentAtom);

  return { isOpen, value, resetModal };
}

/**
 * @deprecated Use `useSetModalAtom_new` instead
 */
export function useSetModalAtom<Value = unknown>(modalAtomFamily: ModalAtomFamily_deprecated<Value>) {
  const atomScope = useModalAtomScope();
  const currentAtom = modalAtomFamily({ teamId: atomScope });

  return useSetAtom(currentAtom);
}

type ModalAtomScope = TeamMetadata['team_id'];
function useModalAtomScope(): ModalAtomScope {
  const { team_id } = useTeamMetadata();

  return team_id;
}

type ModalAtomFamily<Value> = ReturnType<typeof createModalAtomFamily<Value>>;
export function createModalAtomFamily<Value = unknown>(initialValue?: Value) {
  return atomFamily(
    (_params: { teamId: number }) =>
      atomWithReset<{ isOpen: boolean; payload: Value }>({ payload: initialValue ?? ({} as Value), isOpen: false }),
    (aParams, bParams) => aParams.teamId === bParams.teamId,
  );
}

export function useModalAtom_new<Value>(modalAtomFamily: ModalAtomFamily<Value>): {
  isOpen: boolean;
  payload: Value;
  resetModal: () => void;
} {
  const atomScope = useModalAtomScope();
  const currentAtom = modalAtomFamily({ teamId: atomScope });

  const { isOpen, payload } = useAtomValue(currentAtom);
  const resetModal = useResetAtom(currentAtom);

  return { isOpen, payload, resetModal };
}

export function useSetModalAtom_new<Value = unknown>(modalAtomFamily: ModalAtomFamily<Value>) {
  const atomScope = useModalAtomScope();
  const currentAtom = modalAtomFamily({ teamId: atomScope });

  return useSetAtom(currentAtom);
}
