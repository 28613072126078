import { useHydrateAtoms } from 'jotai/utils';

import { teamInformationKindAtom } from './atoms';
import { TeamInformationKind } from './types';

type TeamInformationKindProviderProps = { kind: TeamInformationKind; children?: React.ReactNode };
export function TeamInformationKindProvider({ kind, children }: TeamInformationKindProviderProps): React.JSX.Element {
  useHydrateAtoms([[teamInformationKindAtom, kind]]);

  return <>{children}</>;
}
