import { Box, Flex } from '@chakra-ui/react';
import { Trans, useTranslation } from 'next-i18next';

import { RippleBodyText02, RippleHeading05, RippleHyperLink, RippleStrong } from '@/design';
import composeTranslation from '@/utils/composeTranslation';
import downloadSRC from '@/utils/downloadSRC';

export function LaunchSRCFail(): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <RippleHeading05 marginTop="32px">{t('common:launchSRC.fail.title')}</RippleHeading05>
      <Box
        border="1px solid"
        borderColor="yellow.300"
        borderRadius="4px"
        marginTop="32px"
        padding="16px"
        backgroundColor="yellow.10"
        color="yellow.300"
      >
        <Flex align="center">
          <AlertIcon />
          <RippleBodyText02 marginLeft="20px">{t('common:launchSRC.fail.warning')}</RippleBodyText02>
        </Flex>
      </Box>

      <Box marginTop="32px" color="neutral.100">
        <RippleBodyText02>
          {composeTranslation(
            t,
            'common:launchSRC.fail.description1',
            'download',
            <RippleHyperLink
              variant="hyperlink02"
              margin="0"
              cursor="pointer"
              onClick={() => {
                downloadSRC();
              }}
            >
              {t('common:download').toLowerCase()}
            </RippleHyperLink>,
          )}
        </RippleBodyText02>
        <RippleBodyText02 marginTop="24px">
          <Trans t={t} i18nKey="common:launchSRC.fail.description2" components={{ Strong: <RippleStrong variant="strong02" /> }} />
        </RippleBodyText02>
        <RippleBodyText02 marginTop="24px">
          <Trans
            t={t}
            i18nKey="common:launchSRC.fail.moreInfo"
            values={{
              helpArticle: t('common:helpArticle'),
            }}
            components={{
              anchor: (
                <RippleHyperLink
                  variant="hyperlink02"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://support-splashtopbusiness.splashtop.com/hc/en-us/articles/360003562371"
                />
              ),
            }}
          />
        </RippleBodyText02>
      </Box>
    </>
  );
}

const AlertIcon = () => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0002 9C10.0002 9.553 9.55221 10 9.00021 10C8.44821 10 8.00021 9.553 8.00021 9V5C8.00021 4.448 8.44821 4 9.00021 4C9.55221 4 10.0002 4.448 10.0002 5V9ZM9.00025 14.2C8.33785 14.2 7.80025 13.6636 7.80025 13C7.80025 12.3364 8.33785 11.8 9.00025 11.8C9.66265 11.8 10.2002 12.3364 10.2002 13C10.2002 13.6636 9.66265 14.2 9.00025 14.2ZM17.7222 12.986L10.6932 0.978C9.93121 -0.326 8.06921 -0.326 7.30721 0.978L0.278206 12.986C-0.499794 14.316 0.446206 16 1.97221 16H16.0282C17.5542 16 18.5002 14.316 17.7222 12.986Z"
      fill="#F8C81C"
    />
  </svg>
);
