import { MouseEventHandler, forwardRef } from 'react';

import { Box, FormControl } from '@chakra-ui/react';

import { RippleTextarea, RippleTextareaProps } from '@/design';
import { DragButton } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/EditMode/DragWrapper';
import { Label } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/ViewMode/Label';
import { TEXTAREA_MAX_LENGTH } from '@/modules/ServiceDesk/SupportForm/Editor/constants';
import { SupportFormScenario, SupportFormScenarioMap } from '@/modules/ServiceDesk/SupportForm/Editor/types';

type DetailedAnswerFieldProps = RippleTextareaProps & {
  labelValue: string;
  onClickLabel?: MouseEventHandler<HTMLLabelElement>;
  scenario: SupportFormScenario;
};

export const DetailedAnswerField = forwardRef(
  ({ isRequired, labelValue, onClickLabel, onMouseDown, placeholder, scenario, ...textareaInputProps }: DetailedAnswerFieldProps, ref) => {
    return (
      <Box w="100%" p="8px 36px 12px" position="relative" role="group">
        {scenario === SupportFormScenarioMap.pcp && <DragButton />}
        <FormControl isRequired={isRequired}>
          <Label title={labelValue} scenario={scenario} onMouseDown={onClickLabel} />
          <RippleTextarea
            id="detailed-answer"
            aria-describedby="detailed-answer"
            maxLength={TEXTAREA_MAX_LENGTH}
            height="120px"
            overflow="auto"
            resize="none"
            placeholder={placeholder}
            _hover={scenario === SupportFormScenarioMap.pcp ? { bg: 'blue.0', cursor: 'pointer' } : {}}
            onMouseDown={onMouseDown}
            ref={ref}
            {...textareaInputProps}
          />
        </FormControl>
      </Box>
    );
  },
);
