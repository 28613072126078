import axios from 'axios';

import type { ResponseData, ResponseErrorReason } from '@/services/common/types';
import { isSuccess } from '@/services/utils';

import { AssignedComputer, PreferencePolicyDefaultSettingMap, PreferencePolicyDetail, PreferencePolicySummary } from './types';

export function getPreferencePolicyList(teamId: number) {
  return new Promise<Array<PreferencePolicySummary>>((resolve, reject) => {
    axios
      .get<ResponseData & { data: Array<PreferencePolicySummary> }>(`/api/web/v1/teams/${teamId}/computers/preference_policies/`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getPreferencePolicyDetail(teamId: number, preferencePolicyId: number) {
  return new Promise<PreferencePolicyDetail>((resolve, reject) => {
    axios
      .get<ResponseData & { data: PreferencePolicyDetail }>(
        `/api/web/v1/teams/${teamId}/computers/preference_policies/${preferencePolicyId}`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getPreferencePolicyDefaultValue(teamId: number) {
  return new Promise<PreferencePolicyDefaultSettingMap>((resolve, reject) => {
    axios
      .get<ResponseData & { data: PreferencePolicyDefaultSettingMap }>(`/api/web/v1/teams/${teamId}/computers/preference_policies/default`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function createPreferencePolicy(teamId: number, policy: PreferencePolicyDetail) {
  return new Promise<{ status: string; data: { id: number } }>((resolve, reject) => {
    axios
      .post<ResponseData & { data: { id: number } } & ResponseErrorReason>(`/api/web/v1/teams/${teamId}/computers/preference_policies/`, {
        ...policy,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success', data });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function updatePreferencePolicy(teamId: number, preferencePolicyId: number, policy: PreferencePolicyDetail) {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .patch<ResponseData & ResponseErrorReason>(`/api/web/v1/teams/${teamId}/computers/preference_policies/${preferencePolicyId}`, {
        ...policy,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function deletePreferencePolicy(teamId: number, preferencePolicyId: number) {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .delete<ResponseData & ResponseErrorReason>(`/api/web/v1/teams/${teamId}/computers/preference_policies/${preferencePolicyId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        const messages = error.messages;
        reject({ statusCode, messages });
      });
  });
}

export function getPreferencePolicyAssignedComputer(teamId: number, preferencePolicyId: number) {
  return new Promise<Array<AssignedComputer>>((resolve, reject) => {
    axios
      .get<ResponseData & { data: Array<AssignedComputer> }>(
        `/api/web/v1/teams/${teamId}/computers/preference_policies/${preferencePolicyId}/computers`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getPreferencePolicyAssignedComputerIds(teamId: number, preferencePolicyId: number) {
  return new Promise<Array<number>>((resolve, reject) => {
    axios
      .get<ResponseData & { data: Array<number> }>(
        `/api/web/v1/teams/${teamId}/computers/preference_policies/${preferencePolicyId}/computers?mode=simple`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

type AssignComputerOption = {
  add: Array<number>;
  remove: Array<number>;
};

export function editPreferencePolicyAssignedComputer(teamId: number, preferencePolicyId: number, options: AssignComputerOption) {
  // patch add: Array<number>; remove: Array<number>;
  return new Promise<{ success: Array<number>; fail: Array<number> }>((resolve, reject) => {
    axios
      .patch<ResponseData & { data: { success: Array<number>; fail: Array<number> } }>(
        `/api/web/v1/teams/${teamId}/computers/preference_policies/${preferencePolicyId}/computers/assign`,
        { ...options },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}
