import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'next-i18next';

import { useRippleFlashMessage } from '@/design';
import { SeatContext } from '@/models/SeatContext';
import type { QuickSetupInfo } from '@/modules/Welcome/types';
import { getUserComputersCount as getUserComputersCountAPI } from '@/services/common';
import { getQuickSetupInfo } from '@/services/common';
import { getTeamComputersCount as getTeamComputersCountAPI, getTeamDetail as getTeamDetailAPI } from '@/services/team';
import type { PostMessage } from '@/utils/postMessageToRails';
import { postMessageToRailsCore } from '@/utils/postMessageToRails';
import useTeamInformation from '@/utils/useTeamInformation';

import { Computers } from './Computers';

type WelcomeComputersModuleProps = {
  kind: 'user' | 'team';
};

type CallbackType = string; // TODO: declare real values in the future
const postMessageToRails: PostMessage<{ type: CallbackType }> = postMessageToRailsCore;

const WelcomeComputersModule = ({ kind }: WelcomeComputersModuleProps) => {
  const router = useRouter();
  const { t } = useTranslation();
  const teamInformation = useTeamInformation();
  const [seatInformation, setSeatInformation] = useState(useContext(SeatContext));
  const [quickSetupInfo, setQuickSetupInfo] = useState<QuickSetupInfo>({
    SRSDownloadInstruction: '',
    SRSDownloadKind: '',
    SRSDownloadLink: '',
    SRCLaunchURI: '',
  });

  const callbackType = router.query.callback as CallbackType;

  const { flashMessage } = useRippleFlashMessage();

  useEffect(() => {
    if (teamInformation) {
      const {
        teamId,
        teamPermissions: { memberList },
      } = teamInformation;

      let computersCountAPI = getUserComputersCountAPI;
      if (kind === 'team' && memberList) computersCountAPI = getTeamComputersCountAPI;

      getQuickSetupInfo()
        .then((res) => {
          const { srs_download_kind, srs_download_link, instruction, src_launch_uri } = res;
          setQuickSetupInfo({
            SRSDownloadInstruction: instruction,
            SRSDownloadKind: srs_download_kind,
            SRSDownloadLink: srs_download_link,
            SRCLaunchURI: src_launch_uri,
          });
        })
        .catch(() => {
          flashMessage({
            title: t('common:unexpectedError'),
            variant: 'error',
          });
        });

      getTeamDetailAPI(teamId)
        .then((res) => {
          setSeatInformation(res);
        })
        .catch(console.log);

      computersCountAPI(teamId)
        .then((computersCount) => {
          if (computersCount !== 0) {
            postMessageToRails({ type: callbackType });
          }
        })
        .catch(console.log);

      postMessageToRails({ type: 'load_complete' });
    } else {
      postMessageToRails({ type: callbackType });
    }

    const blankAreaClickListener = () => {
      postMessageToRails({ type: 'blankAreaClick' });
    };
    window.addEventListener('click', blankAreaClickListener);

    return () => {
      window.removeEventListener('click', blankAreaClickListener);
    };
  }, []);

  return (
    <SeatContext.Provider value={{ ...seatInformation }}>
      <Computers quickSetupInfo={quickSetupInfo} />
    </SeatContext.Provider>
  );
};

export { WelcomeComputersModule };
