import { useEffect } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { FormProvider } from 'react-hook-form';

import { ThankYouMessage } from '@/modules/ServiceDesk/SurveyForm/Editor/FormEditor/ViewMode/ThankYouMessage';
import { SurveyFormScenarioMap } from '@/modules/ServiceDesk/SurveyForm/Editor/types';
import { Form, SkeletonLoader } from '@/modules/ServiceDesk/SurveyForm/EndUser';
import { useSubmitForm } from '@/modules/ServiceDesk/SurveyForm/EndUser/hooks/useSubmitForm';
import { GetSurveyErrorState, SubmitSurveyErrorState } from '@/modules/ServiceDesk/SurveyForm/components';
import { viewSurveyWithoutAuth } from '@/services/serviceDesk/surveys/viewSurvey';

const FORM_CONTAINER_WIDTH = '800px';

type MainContentProps = {
  supportSessionCode: string;
};

export const MainContent = ({ supportSessionCode }: MainContentProps) => {
  const {
    isInitialLoading: isLoadingSurvey,
    data: survey,
    isError: isViewSurveyError,
  } = useQuery({
    queryKey: ['survey', 'view', supportSessionCode],
    enabled: supportSessionCode !== undefined,
    queryFn: async () => {
      if (!supportSessionCode) {
        return null as never;
      }
      return await viewSurveyWithoutAuth({ supportSessionCode });
    },
  });

  const { form, handleSubmit, isSubmittingForm, submitSurveyResult, isSubmitSurveyError } = useSubmitForm({ supportSessionCode });

  useEffect(
    function initializeSubmitFormValues() {
      if (survey) {
        form.setValue(
          'questionnaire',
          survey.questionnaire.map((field) => {
            return {
              id: field.id,
              kind: field.kind,
              answer: field.default_option || null,
            };
          }),
        );
      }
    },
    [form, survey],
  );

  if (isLoadingSurvey) {
    return (
      <Box w={FORM_CONTAINER_WIDTH} pt="44px">
        <SkeletonLoader />
      </Box>
    );
  }

  if (isViewSurveyError) {
    return (
      <Box w={FORM_CONTAINER_WIDTH} pt="48px">
        <GetSurveyErrorState />
      </Box>
    );
  }

  if (isSubmitSurveyError) {
    return (
      <Box w={FORM_CONTAINER_WIDTH} pt="48px">
        <SubmitSurveyErrorState />
      </Box>
    );
  }

  if (survey && submitSurveyResult) {
    return (
      <Box w={FORM_CONTAINER_WIDTH}>
        <ThankYouMessage thankYouMessage={survey.thank_you_message} />
      </Box>
    );
  }
  return (
    <Flex w={FORM_CONTAINER_WIDTH} pt="24px" direction="column" align="center">
      <FormProvider {...form}>
        {survey && (
          <Form
            scenario={SurveyFormScenarioMap.endUser}
            formFields={survey}
            brandImageUrl={survey.brand_image_url}
            onSubmit={handleSubmit}
            isSubmittingForm={isSubmittingForm}
          />
        )}
      </FormProvider>
    </Flex>
  );
};
