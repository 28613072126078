import { z } from 'zod';

import { TeamMemberPermissionQuery } from './types';

export const teamMemberPermissionKeySchema = z.enum([
  'alert',
  'access_schedule',
  'allow_src_version_control',
  'allow_srs_version_control',
  'antivirus',
  'antivirus_threats',
  'attended_access', // 非從 granular_control 取得
  'bitdefender',
  'bitdefender2',
  'command_prompt',
  'create_group_admin',
  'emm_os',
  'emm_os_opportunities', // 沒有 granular control
  'emm_patch',
  'emm_patch_opportunities',
  'endpoint_security',
  'event_logs',
  'extra_src_management',
  'file',
  'file_transfer',
  'force_admin_2step_auth',
  'force_member_2step_auth',
  'grant_granular_control',
  'inventory',
  'invite_member',
  'license_type',
  'macaddr_whitelist',
  'manage_infra_gen',
  'member_listrdp_computer',
  'mobile_streamer',
  'multiple_groups',
  'off_session_chat',
  'off_session_file',
  'one_to_many', // 非從 granular_control 取得
  'one_to_many_basic',
  'open_api_token',
  'os_opportunities', // 同時考慮 emm os 或 premier_pack 有效就可以使用
  'pref_policy_security',
  'preference_policy',
  'premium_tool',
  'relay_recording',
  'relay_recording_access', // 非從 granular_control 取得, 根據 team_member_permissions + team settings 設定決定
  'remote_print',
  'remote_reboot',
  'restrict_pcp_ip',
  'restrict_src_ip',
  'smart_action',
  'sos_relay_recording',
  'sos_relay_recording_access', // 非從 granular_control 取得, 根據 team_member_permissions + team settings 設定決定
  'sos_service_desk',
  'sos_service_desk_premium_tool',
  'sos_service_desk_psa',
  'sos_sso',
  'sos_ticket_session',
  'sos_web_src',
  'ssh_computer',
  'sso',
  'sso_management', // 非從 granular_control 取得
  'super_admin', // 非從 granular_control 取得
  'super_admin_or_technician_manager', // 非從 granular_control 取得
  'team_settings_management', // 非從 granular_control 取得
  'technician_manager', // 非從 granular_control 取得
  'user_management', // 非從 granular_control 取得
  'vnc_computer',
  'vulnerability_score',
  'wake_on_lan',
  'web_src',
  'voice_call', // Depend on the toggle `PCP_3760__VoiceCall_ActiveSessions_SOS14`
  'sos_voice_call', // Depend on the toggle `PCP_3760__VoiceCall_ActiveSessions_SOS14`
]);

export function getTeamMemberPermissionSchema<Q extends TeamMemberPermissionQuery>(query: Q) {
  if (!query.team_member_permissions) {
    return z.object({ team_member_permissions: z.never().optional() });
  }

  const shape = query.team_member_permissions.reduce<Partial<Record<z.infer<typeof teamMemberPermissionKeySchema>, z.ZodBoolean>>>(
    (acc, key) => {
      return { ...acc, [key]: z.boolean() };
    },
    {},
  );

  return z.object({ team_member_permissions: z.object(shape) });
}
