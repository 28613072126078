import { createContext } from 'react';

import type { PrimitiveAtom } from 'jotai';

import type { TeamKind } from '@/services/common/types';
import type { SeatPermissions, TeamPermissions, TeamSettings } from '@/services/teamSettings/types';

import type { CacheAtom } from './atoms';
import { FeatureItemContextValue, GranularControl, SettingControlContextValue, TeamMetadata } from './types';

export type ModalOffsetInfo = {
  height: number;
  top: number;
  isMobile: boolean;
};

type ModalContextProps = {
  offsetRef: React.RefObject<ModalOffsetInfo>;
};

// REFACTOR: use jotai instead
export const ModalContext = createContext<ModalContextProps>({} as never);

export const SettingControlContext = createContext<SettingControlContextValue>({} as never);

export const TeamControlContext = createContext<{
  teamKind: TeamKind;
  teamPermissions: TeamPermissions;
  seatPermissions: SeatPermissions;
  planName: string;
  canUseGranularControl: boolean;
  atoms: {
    teamMetadataAtom: PrimitiveAtom<TeamMetadata>;
    teamSettingsAtom: CacheAtom<TeamSettings>;
    granularControlAtom: CacheAtom<GranularControl>;
  };
}>({} as never);

/**
 * For some corner cases in SOS Enterprise
 */
export const RestrictCategoryContext = createContext(false);

export const FeatureItemContext = createContext<FeatureItemContextValue>({} as never);

type FeatureGranularContextValue = { isDisabled: boolean };
export const FeatureGranularContext = createContext<FeatureGranularContextValue>({ isDisabled: false });
