import Image from 'next/image';
import Link from 'next/link';
import { useRef, useState } from 'react';

import { Box, Fade, Grid, HStack, SlideFade, Stack } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  RippleArrowTailLeft,
  RippleArrowTailRight,
  RippleButton,
  RippleModal,
  RippleModalBody,
  RippleModalCloseButton,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleSkeleton,
  RippleTypography,
} from '@/design';
import { MessageSchemaType } from '@/services/users/getMessageService/schemas';

type FeatureAwarenessModalProps = {
  message?: MessageSchemaType['message'];
  isOpen: boolean;
  onClose: () => void;
};

/**
 * Feature awareness modal component
 * Simular to component: /modules/FeatureAwareness/PopUp/Card.tsx which is in a card style
 */
export const TipsModal = ({ message, isOpen = false, onClose }: FeatureAwarenessModalProps) => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 576px)');
  const [canExtendHeight] = useMediaQuery('(min-height: 800px)');
  const bodyRef = useRef<HTMLDivElement>(null);
  const headerHeght = isMobile ? 208 : 296;
  const width = isMobile ? 369 : 640;

  const [currIndex, setCurrIndex] = useState(0);
  const content = message?.pages?.[currIndex];

  const handleNext = () => {
    setCurrIndex((prev) => Math.min(prev + 1, (message?.pages?.length ?? 0) - 1));
    bodyRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleBack = () => {
    setCurrIndex((prev) => Math.max(prev - 1, 0));
    bodyRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleClose = () => {
    setCurrIndex(0);
    onClose();
  };

  if (!message) {
    return (
      <RippleModal isOpen={isOpen} onClose={handleClose}>
        <RippleModalOverlay />
        <RippleModalContent minW={width} minH="768px">
          <RippleModalCloseButton onClick={handleClose} />
          <RippleModalHeader p="0">
            <RippleSkeleton w={`${width}px`} minH={`${headerHeght}px`} />
          </RippleModalHeader>
          <RippleModalBody minH={canExtendHeight ? '240px' : 'unset'}>
            <Stack gap="16px" pt="28px">
              <RippleSkeleton h="40px" w="400px" />
              <RippleSkeleton h="20px" w="520px" />
              <RippleSkeleton h="20px" w="520px" />
              <RippleSkeleton h="20px" w="420px" />
            </Stack>
          </RippleModalBody>
          <RippleModalFooter justifyContent="space-between" position="relative">
            <RippleButton variant="primary" size="sm" padding="8px" ml="auto" onClick={handleClose}>
              {t('common:close')}
            </RippleButton>
          </RippleModalFooter>
        </RippleModalContent>
      </RippleModal>
    );
  }

  return (
    <RippleModal isOpen={isOpen} onClose={handleClose}>
      <RippleModalOverlay />
      <RippleModalContent minW={`${width}px`} overflow="hidden">
        <RippleModalHeader p="0">
          <Box width={width} overflowX="hidden">
            {message?.pages?.length && (
              <Grid
                templateColumns={`repeat(${message.pages.length}, ${width}px)`}
                style={{ transform: `translateX(-${width * currIndex}px)` }}
                transition="transform 0.2s ease-in-out"
              >
                {message.pages.map((page, i) => {
                  return (
                    <Image
                      key={`head-${i}`}
                      src={page?.image}
                      alt={page?.title ?? 'features'}
                      width={width}
                      height={headerHeght}
                      quality={100}
                    />
                  );
                })}
              </Grid>
            )}
          </Box>
        </RippleModalHeader>
        <RippleModalCloseButton onClick={handleClose} />
        <RippleModalBody ref={bodyRef} overflowX="hidden" px="0" minH={canExtendHeight ? '240px' : 'unset'}>
          <SlideFade in>
            <Box width={width} overflowX="hidden">
              {message?.pages?.length && (
                <Grid
                  templateColumns={`repeat(${message.pages.length}, ${width}px)`}
                  style={{ transform: `translateX(-${width * currIndex}px)` }}
                  transition="transform 0.2s ease-in-out"
                >
                  {message.pages.map((page, i) => {
                    return (
                      <Box key={`page-${i}`} width={`${width}px`} px="24px">
                        <Stack gap="16px" pt="28px">
                          <RippleTypography variant="heading04">{page?.title}</RippleTypography>
                          <RippleTypography variant="body01" color="neutral.300" lineHeight="24px">
                            {page?.body_text}
                          </RippleTypography>
                        </Stack>
                        {page?.url && page?.link_text && (
                          <RippleButton as={Link} variant="secondary" href={page?.url} target="_blank" mt="24px">
                            {page?.link_text}
                          </RippleButton>
                        )}
                      </Box>
                    );
                  })}
                </Grid>
              )}
            </Box>
          </SlideFade>
        </RippleModalBody>
        <RippleModalFooter justifyContent="space-between" position="relative">
          <Fade in={currIndex !== 0}>
            <RippleButton
              variant="ghost"
              size="sm"
              padding="8px"
              mr="auto"
              leftIcon={<RippleArrowTailLeft color="blue.100" />}
              onClick={handleBack}
            >
              {t('common:back')}
            </RippleButton>
          </Fade>
          <HStack gap="4px" justifyContent="space-between" position="absolute" left="50%" transform="translateX(-50%)">
            {Array.from({ length: message.pages?.length ?? 0 }, (_, i) => (
              <Indicator key={i} isActived={i === currIndex} onClick={() => setCurrIndex(i)} />
            ))}
          </HStack>
          {currIndex === (message.pages?.length ?? 0) - 1 ? (
            <Fade in>
              <RippleButton variant="primary" size="sm" padding="8px" ml="auto" onClick={handleClose}>
                {content?.end_button}
              </RippleButton>
            </Fade>
          ) : (
            <Fade in>
              <RippleButton
                variant="ghost"
                size="sm"
                padding="8px"
                ml="auto"
                rightIcon={<RippleArrowTailRight color="blue.100" />}
                onClick={handleNext}
              >
                {t('common:next')}
              </RippleButton>
            </Fade>
          )}
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
};

const Indicator = ({ isActived, onClick }: { isActived: boolean; onClick: () => void }) => {
  return (
    <Box
      height="6px"
      borderRadius="4px"
      transition="0.3s ease"
      cursor="pointer"
      sx={{
        backgroundColor: isActived ? 'blue.100' : 'neutral.80',
        width: isActived ? '18px' : '6px',
      }}
      _hover={isActived ? {} : { backgroundColor: 'blue.60', transform: 'scale(1.3)' }}
      onClick={isActived ? undefined : onClick}
    />
  );
};
