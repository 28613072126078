import { useMemo } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { chunk, flatten } from 'lodash';

import { useTeamId } from '@/models/TeamInformation';
import { useColumnPermissions } from '@/modules/Computer/AllComputers/useColumnPermissions';
import { useRegisterComputerDataFields } from '@/modules/Computer/ComputerList/hooks';
import { ColumnPermissions } from '@/modules/Computer/ComputerList/types';
import { checkPermission } from '@/modules/Computer/ComputerList/utils';
import { computeShowOOBE } from '@/modules/Computer/utils';
import { ComputerListCustomizedField, getComputerListByToken, getTeamComputerTokenList } from '@/services/computers';
import { getComputerListById } from '@/services/computers';
import { getComputerIds } from '@/services/computers';
import { CHUNK_SIZE } from '@/utils/constants';

import { computerIdListPlaceholder, computerListPlaceholder } from './constants';
import { computerTokenListPlaceholder } from './constants';

export function useComputerListQuery({ columnPermissions }: { columnPermissions: ColumnPermissions | null }) {
  const teamId = useTeamId();

  const computerIdListQuery = useComputerIdListQuery();
  const computerIdList = computerIdListQuery.data ?? computerIdListPlaceholder;

  const fields = useSRSFields(columnPermissions);

  return useQuery({
    queryKey: ['allComputers', 'computerList'],
    queryFn: async () => {
      if (computerIdList.length === 0) {
        return [];
      }

      const idListChunks = chunk(computerIdList, CHUNK_SIZE);
      const requestList = idListChunks.map((idList) => {
        return getComputerListById(teamId, idList, 'customize', fields);
      });

      const result = await Promise.all(requestList);

      return flatten(result);
    },
    staleTime: Infinity,
    enabled: !computerIdListQuery.isFetching && columnPermissions !== null,
  });
}

export const useComputerIdListQueryKey = () => {
  const teamId = useTeamId();
  return ['allComputers', 'getComputerIdList', teamId];
};

export function useComputerIdListQuery() {
  const teamId = useTeamId();

  return useQuery({
    queryKey: useComputerIdListQueryKey(),
    queryFn: () => getComputerIds(teamId),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
}

const useComputerQueriesSRS = useComputerQueriesSRS_tokenList;
export function useComputerDataSRS() {
  const queryClient = useQueryClient();
  const columnPermissions = useColumnPermissions();

  const { computerIdListQuery, computerListQuery, computerListQueryKey, computerIdListQueryKey } = useComputerQueriesSRS({
    columnPermissions,
  });
  const computerListData = computerListQuery.data ?? computerListPlaceholder;

  const showOOBE = computeShowOOBE(computerIdListQuery);
  const isFetching = computerIdListQuery.isFetching || computerListQuery.isFetching;

  function refresh() {
    queryClient.removeQueries({ queryKey: computerIdListQueryKey, exact: true });
    computerIdListQuery.refetch().then(() => {
      queryClient.removeQueries({ queryKey: computerListQueryKey, exact: true });
      computerListQuery.refetch();
    });
  }

  return { showOOBE, computerListData, isFetching, refresh };
}

function useComputerQueriesSRS_tokenList({ columnPermissions }: { columnPermissions: ColumnPermissions | null }) {
  return {
    computerIdListQueryKey: useComputerTokenListQueryKey(),
    computerIdListQuery: useComputerTokenListQuery(),
    computerListQueryKey: useComputerListByTokenQueryKey(),
    computerListQuery: useComputerListByTokenQuery({ columnPermissions }),
  };
}

const useComputerTokenListQueryKey = () => {
  const teamId = useTeamId();
  return ['allComputers', 'getComputerTokenList', teamId];
};

function useComputerTokenListQuery() {
  const teamId = useTeamId();

  return useQuery({
    queryKey: useComputerTokenListQueryKey(),
    queryFn: () => getTeamComputerTokenList(teamId),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
}

const useComputerListByTokenQueryKey = () => {
  const computerTokenListQuery = useComputerTokenListQuery();
  const computerTokenList = computerTokenListQuery.data ?? computerTokenListPlaceholder;
  return ['allComputers', 'computerList', computerTokenList];
};

function useComputerListByTokenQuery({ columnPermissions }: { columnPermissions: ColumnPermissions | null }) {
  const teamId = useTeamId();

  const computerTokenListQuery = useComputerTokenListQuery();
  const computerTokenList = computerTokenListQuery.data ?? computerTokenListPlaceholder;

  const fields = useSRSFields(columnPermissions);

  const queryKey = useComputerListByTokenQueryKey();

  return useQuery({
    queryKey,
    queryFn: async () => {
      if (computerTokenList.length === 0) {
        return [];
      }

      const requestList = computerTokenList.map((token) => {
        return getComputerListByToken(teamId, {
          token,
          mode: 'customize',
          customizedFields: fields,
        });
      });

      const result = await Promise.all(requestList);

      return flatten(result);
    },
    staleTime: Infinity,
    enabled: !computerTokenListQuery.isFetching && columnPermissions !== null,
  });
}

function useSRSFields(columnPermissions: ColumnPermissions | null): Array<ComputerListCustomizedField> {
  const fields = useMemo<Array<ComputerListCustomizedField>>(() => getSRSFields(columnPermissions), [columnPermissions]);

  useRegisterComputerDataFields(fields);

  return fields;
}

function getSRSFields(columnPermissions: ColumnPermissions | null): Array<ComputerListCustomizedField> {
  return [
    'group_id',
    'online_status',
    'connected',
    'version',
    'os',
    'os_product',
    'os_build',
    'os_build_rev',
    'architecture',
    'pubip',
    'local_ip',
    ...checkPermission(columnPermissions?.notes, 'note' as const),
    'host_name',
    'last_online',
    'last_session',
    ...checkPermission(columnPermissions?.windowsUpdates, 'windows_updates' as const),
    ...checkPermission(columnPermissions?.windowsUpdates, 'support_windows_updates' as const),
    ...checkPermission(columnPermissions?.emmPolicy, 'support_emm_policy' as const),
    ...checkPermission(columnPermissions?.preferencePolicy, 'preference_policy' as const),
    ...checkPermission(columnPermissions?.antivirusStatus, 'antivirus_scan_status' as const),
    ...checkPermission(columnPermissions?.antivirusStatus, 'support_antivirus' as const),
    ...checkPermission(columnPermissions?.alertCount, 'alerts_count' as const),
    ...checkPermission(columnPermissions?.securityLayer, 'infra_gen_status' as const),
    'is_device_owner',
  ];
}
