import { useEffect, useRef, useState } from 'react';

import { Box, useClipboard } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useTranslation } from 'next-i18next';

import { RippleButton, RippleInput, RippleInputGroup, RippleInputRightElement, RippleTypography } from '@/design';

const defaultWidth = 552;
const defaultHeight = 480;
const maxWidth = 800;
const minWidth = 320;
const maxHeight = 720;
const minHeight = 480;

const DEFAULT_BORDER_COLOR = 'neutral.300';

const SANDBOX_ATTRIBUTE = 'allow-scripts allow-same-origin allow-popups allow-downloads';
// Add allow-popups to allow mobile open app link
// Add allow-downloads to allow desktop download files

const iframeCodeGenerator = ({ width, height, downloadUrl }: { width: number; height: number; downloadUrl: string }) => {
  return `<iframe width="${width}" height="${height}" src="${downloadUrl}" style="padding: 4px 0;border:1px solid #80859F;border-radius:12px;" sandbox="${SANDBOX_ATTRIBUTE}"></iframe>`;
};

export const iframeSizeAtom = atomWithReset({ width: defaultWidth, height: defaultHeight });

type SnippetProps = {
  downloadUrl: string;
  onCheckValid?: (isValid: boolean) => void;
  iframeInfoSectionHeight?: string;
};

export const Snippet = ({ downloadUrl, onCheckValid, iframeInfoSectionHeight = 'auto' }: SnippetProps) => {
  const { t } = useTranslation();
  const [width, setWidth] = useState(defaultWidth);
  const [widthError, setWidthError] = useState(false);

  const [height, setHeight] = useState(defaultHeight);
  const [heightError, setHeightError] = useState(false);

  const lastValidWidth = useRef(defaultWidth);
  const lastValidHeight = useRef(defaultHeight);

  const [iframeSize, setIframeSize] = useAtom(iframeSizeAtom);
  const resetIframeSize = useResetAtom(iframeSizeAtom);

  useEffect(() => {
    resetIframeSize();
  }, [resetIframeSize]);

  const iframeCode = iframeCodeGenerator({ width: iframeSize.width, height: iframeSize.height, downloadUrl });

  const { onCopy, hasCopied } = useClipboard(iframeCode);

  const setWidthValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (isNaN(value)) return;

    setWidth(value);

    if (value > maxWidth || value < minWidth) {
      setWidthError(true);
      onCheckValid?.(false);
    } else {
      setWidthError(false);
      lastValidWidth.current = value;
      setIframeSize({ width: value, height: lastValidHeight.current });
      onCheckValid?.(!heightError);
    }
  };

  const setHeightValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (isNaN(value)) return;

    setHeight(value);

    if (value > maxHeight || value < minHeight) {
      setHeightError(true);
      onCheckValid?.(false);
    } else {
      setHeightError(false);
      lastValidHeight.current = value;
      setIframeSize({ width: lastValidWidth.current, height: value });
      onCheckValid?.(!widthError);
    }
  };

  const handleCopy = () => {
    onCopy();
  };

  return (
    <>
      <Box w="100%">
        <RippleTypography variant="heading08" mb="8px">
          {t('support-forms:formWidth')}
        </RippleTypography>
        <RippleInputGroup>
          <RippleInput mb="4px" maxLength={4} value={width} onChange={setWidthValue} isInvalid={widthError} />
          <RippleInputRightElement p="10px 12px" top="1px" right="1px" borderRadius="0 4px 4px 0">
            px
          </RippleInputRightElement>
        </RippleInputGroup>
        <RippleTypography variant="body03" mb="24px" color={widthError ? 'red.80' : DEFAULT_BORDER_COLOR}>
          {t('support-forms:maxMinPixel', { max: maxWidth, min: minWidth })}
        </RippleTypography>
      </Box>
      <Box w="100%">
        <RippleTypography variant="heading08" mb="8px">
          {t('support-forms:formHeight')}
        </RippleTypography>
        <RippleInputGroup>
          <RippleInput mb="4px" maxLength={4} value={height} onChange={setHeightValue} isInvalid={heightError} />
          <RippleInputRightElement p="10px 12px" top="1px" right="1px" borderRadius="0 4px 4px 0">
            px
          </RippleInputRightElement>
        </RippleInputGroup>
        <RippleTypography variant="body03" mb="24px" color={heightError ? 'red.80' : DEFAULT_BORDER_COLOR}>
          {t('support-forms:maxMinPixel', { max: maxHeight, min: minHeight })}
        </RippleTypography>
      </Box>
      <Box>
        <RippleTypography variant="heading08" mb="8px">
          iframe
        </RippleTypography>
        <Box
          borderRadius="4px"
          border="solid 1px"
          p="12px"
          h={iframeInfoSectionHeight}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={
            hasCopied
              ? { borderColor: 'green.100', color: 'green.200', bg: 'green.10', transition: '0.2s' }
              : { borderColor: DEFAULT_BORDER_COLOR, bg: 'white' }
          }
        >
          <Box pb="12px" fontSize="14px" fontWeight="400" lineHeight="20px" wordBreak="break-all">
            {iframeCode}
          </Box>
          <RippleButton variant={hasCopied ? 'success' : 'primary'} w="100%" color="white" onClick={handleCopy}>
            {hasCopied ? t('common:copied') : t('support-forms:copyCodeSnippet')}
          </RippleButton>
        </Box>
      </Box>
    </>
  );
};
