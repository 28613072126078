import React from 'react';

import type { BoxProps } from '@chakra-ui/react';
import { Box, Spinner as ChakraSpinner, Flex, Image } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const IconSpinner = ({ imageSrcType = 'white' }) => {
  const imageSrc = imageSrcType === 'white' ? 'Icon16px_loading_W100.svg' : 'Icon16px_loading_N100.svg';
  return (
    <Flex as="span" animation={`${spin} 2s linear infinite`}>
      <Image src={`/next/images/oobe/${imageSrc}`} alt="" />
    </Flex>
  );
};

export type InlineLoaderProps = BoxProps & { loaderColor?: string };

const loader1 = keyframes`
  0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
`;

const loader2 = keyframes`
	0% { transform: rotate(0deg); }
	100% { transform: rotate(220deg); }
`;

const loader3 = keyframes`
  0% { transform: rotate(-140deg); }
	50% { transform: rotate(-160deg); }
	100% { transform: rotate(140deg); }
`;

const Loader = styled(Box)<InlineLoaderProps>`
  display: inline-block;
  width: 16px;
  height: 16px;
  animation: ${loader1} 3.2s linear infinite;

  & span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    margin: auto;
    clip: rect(0, 16px, 16px, 8px);
    animation: ${loader2} 1.6s linear infinite;
  }

  & span::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 16px;
    height: 16px;
    margin: auto;
    clip: rect(0, 16px, 16px, 8px);
    content: '';
    border: 2px solid;
    border-color: ${({ loaderColor }) => (loaderColor ? loaderColor : '#fff')};
    border-radius: 50%;
    animation: ${loader3} 1.6s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  }
`;

export const InlineLoader = (props: InlineLoaderProps) => (
  <Loader {...props}>
    <span></span>
  </Loader>
);

// TODO: Omit children ?
const FullPageSpinner = ({ ...props }: BoxProps) => (
  <Box position="fixed" top="0" left="0" zIndex={1500} width="100%" height="100%" backgroundColor="rgba(51, 51, 51, 0.75)" {...props}>
    <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
      <ChakraSpinner emptyColor="white" color="blue.200" thickness="6px" width="48px" height="48px" speed=".8s" />
    </Box>
  </Box>
);

export default FullPageSpinner;
