import { z } from 'zod';

// Survey Type
export const SurveyTypeMap = {
  customer: 1,
  technician: 2,
} as const;

export const SurveyTypeSchema = z.union([z.literal(SurveyTypeMap.customer), z.literal(SurveyTypeMap.technician)]);
export type SurveyType = z.infer<typeof SurveyTypeSchema>;

// Brand Image
export const BrandImageMap = {
  splashtop: 1,
  custom: 2,
} as const;

export const BrandImageSchema = z.union([z.literal(BrandImageMap.splashtop), z.literal(BrandImageMap.custom)]);
export type BrandImageType = z.infer<typeof BrandImageSchema>;

// Questionnaire
export const QuestionnaireFieldKindMap = {
  shortText: 1,
  longText: 2,
  comboBox: 3,
  radioButton: 4,
  checkbox: 5,
  nps: 6,
  csat: 7,
} as const;

const QuestionnaireKindSchema = z.union([
  z.literal(QuestionnaireFieldKindMap.shortText),
  z.literal(QuestionnaireFieldKindMap.longText),
  z.literal(QuestionnaireFieldKindMap.comboBox),
  z.literal(QuestionnaireFieldKindMap.radioButton),
  z.literal(QuestionnaireFieldKindMap.checkbox),
  z.literal(QuestionnaireFieldKindMap.nps),
  z.literal(QuestionnaireFieldKindMap.csat),
]);

export type QuestionnaireKind = z.infer<typeof QuestionnaireKindSchema>;

export const baseFieldAPISchemaWithoutId = z.object({
  answer_placeholder: z.string(),
  order: z.number(),
  question: z.string(),
  required: z.boolean(),
});

export const baseFieldAPISchema = baseFieldAPISchemaWithoutId.merge(
  z.object({
    id: z.number().optional().nullable(),
  }),
);

export const shortTextOnlyAPISchema = z.object({
  kind: z.literal(QuestionnaireFieldKindMap.shortText),
  default_option: z.null(),
  options: z.null(),
});

export const longTextOnlyAPISchema = z.object({
  kind: z.literal(QuestionnaireFieldKindMap.longText),
  default_option: z.null(),
  options: z.null(),
});

export const comboBoxOnlyAPISchema = z.object({
  kind: z.literal(QuestionnaireFieldKindMap.comboBox),
  default_option: z.string(),
  options: z.array(z.object({ option: z.string() })).nullable(),
});

export const radioButtonOnlyAPISchema = z.object({
  kind: z.literal(QuestionnaireFieldKindMap.radioButton),
  default_option: z.string(),
  options: z.array(z.object({ option: z.string() })).nullable(),
});

export const checkboxOnlyAPISchema = z.object({
  kind: z.literal(QuestionnaireFieldKindMap.checkbox),
  default_option: z.array(z.string()),
  options: z.array(z.object({ option: z.string() })),
});

export const npsOnlyAPISchema = z.object({
  kind: z.literal(QuestionnaireFieldKindMap.nps),
  default_option: z.number().nullable(),
  options: z.array(z.object({ option: z.string(), value: z.number() })).nullable(),
});

export const csatOnlyAPISchema = z.object({
  kind: z.literal(QuestionnaireFieldKindMap.csat),
  default_option: z.number().nullable(),
  options: z.array(z.object({ option: z.string(), value: z.number() })).nullable(),
});

export const shortTextFieldAPISchema = baseFieldAPISchema.merge(shortTextOnlyAPISchema);
export const longTextFieldAPISchema = baseFieldAPISchema.merge(longTextOnlyAPISchema);
export const comboBoxFieldAPISchema = baseFieldAPISchema.merge(comboBoxOnlyAPISchema);
export const radioButtonFieldAPISchema = baseFieldAPISchema.merge(radioButtonOnlyAPISchema);
export const checkboxFieldAPISchema = baseFieldAPISchema.merge(checkboxOnlyAPISchema);
export const npsFieldAPISchema = baseFieldAPISchema.merge(npsOnlyAPISchema);
export const csatFieldAPISchema = baseFieldAPISchema.merge(csatOnlyAPISchema);

const questionnaireFieldAPISchema = z.discriminatedUnion('kind', [
  shortTextFieldAPISchema,
  longTextFieldAPISchema,
  comboBoxFieldAPISchema,
  radioButtonFieldAPISchema,
  checkboxFieldAPISchema,
  npsFieldAPISchema,
  csatFieldAPISchema,
]);

export type QuestionnaireFieldAPI = z.infer<typeof questionnaireFieldAPISchema>;

// Survey Form
export const commonSurveyFormSchema = z.object({
  description: z.string(),
  locale: z.null(), // null: user default locale
  type: SurveyTypeSchema,
  brand_image: BrandImageSchema.nullable(),
  welcome_message: z.object({
    title: z.string().nullable(),
    subtitle: z.string().nullable(),
  }),
  thank_you_message: z.string().nullable(),
  action_button_label: z.string(),
});

export const surveyFormAPISchema = commonSurveyFormSchema.merge(
  z.object({
    name: z.string().min(1), // must has form name
    questionnaire: z.array(questionnaireFieldAPISchema).min(1), // must has one question
  }),
);

export type SurveyFormAPIValue = z.infer<typeof surveyFormAPISchema>;

export type SurveyFormAPIValueKey = keyof z.infer<typeof surveyFormAPISchema>;

// channel id
export const channelIdSchema = z.number();
export type ChannelId = z.infer<typeof channelIdSchema>;

// survey details
export const surveyDetailsSchema = z.object({});
