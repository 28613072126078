import { useEffect, useState } from 'react';

import { HStack } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useTranslation } from 'next-i18next';

import {
  RippleBodyText02,
  RippleButton,
  RippleHeading08,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalTitle,
  RippleTextField,
  useRippleFlashMessage,
} from '@/design';
import { updateComputerName } from '@/services/computers';
import preSanitize from '@/utils/pre-sanitize';

import { computerDataMapAtom } from '../atoms';

export const renameComputerModalAtom = atomWithReset<{ isOpen: boolean; computerId: number | null; name: string | null }>({
  isOpen: false,
  computerId: null,
  name: null,
});

export function useRenameComputerModal() {
  const set = useSetAtom(renameComputerModalAtom);

  return {
    open: ({ computerId, name }: { computerId: number; name: string }) => {
      set({ isOpen: true, computerId, name });
    },
  };
}

export function RenameComputerModal(): React.JSX.Element {
  const { t } = useTranslation();
  const { flashMessage } = useRippleFlashMessage();

  const { isOpen, computerId, name } = useAtomValue(renameComputerModalAtom);
  const resetModal = useResetAtom(renameComputerModalAtom);

  const [value, setValue] = useState<string>('');
  useEffect(
    function initialInputValue() {
      if (isOpen && name) {
        setValue(name);
      }
    },
    [isOpen, name],
  );

  const setComputerDataMap = useSetAtom(computerDataMapAtom);

  const saveMutation = useMutation({
    mutationFn: async ({ computerId, name }: { computerId: number; name: string }) => {
      return updateComputerName(computerId, name);
    },
    onSuccess: (_response, { computerId, name }) => {
      setComputerDataMap((draft) => {
        draft[computerId].name = name;
      });
    },
    onError: () => {
      flashMessage({ variant: 'error', title: t('common:unexpectedError') });
    },
    onSettled: () => {
      resetModal();
    },
  });

  const isSameWithCurrent = value === name;
  const isDisabled = value.length === 0 || isSameWithCurrent || saveMutation.isPending;

  return (
    <RippleModal size="2xl" isOpen={isOpen} onClose={resetModal}>
      <RippleModalOverlay />
      <RippleModalContent>
        <RippleModalHeader>
          <RippleModalTitle>{t('computer:rename.modal.title')}</RippleModalTitle>
        </RippleModalHeader>
        <RippleModalBody>
          <RippleBodyText02 mb="24px">{t('computer:rename.modal.description')}</RippleBodyText02>
          <RippleHeading08 mb="8px">{t('common:name')}</RippleHeading08>
          <RippleTextField
            data-testid="name-input"
            placeholder=""
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
            }}
            maxLength={64}
          />
        </RippleModalBody>
        <RippleModalFooter>
          <HStack spacing="12px">
            <RippleButton data-testid="cancel-button" variant="grayScaleGhost" onClick={resetModal}>
              {t('common:cancel')}
            </RippleButton>
            <RippleButton
              data-testid="save-button"
              variant="primary"
              isLoading={saveMutation.isPending}
              isDisabled={isDisabled}
              onClick={() => {
                if (computerId) {
                  saveMutation.mutate({ computerId, name: processString(value) });
                }
              }}
            >
              {t('common:save')}
            </RippleButton>
          </HStack>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
}

/**
 * The fellowing charts are invalid, and will be ignore <>,;:"*+=\\|?
 * Limit: 64 (chart after 64th will be ignore)
 * blank value ( '', null ) is invalid
 * The fellowing charts will be change to full-width
 *  < => ＜
 *  > => ＞
 *  ; => ；
 */
function processString(input: string): string {
  return preSanitize(input.trim());
}
