import { GroupScope, MemberStatus, Role } from '@/services/users/infoQueryService/team_member_info/types';

export const isGroupAdmin = ({ role, groupScope }: { role: Role; groupScope: GroupScope }) => {
  return role === 'manager' && groupScope === 'part';
};

/** Determine whether the user can access the channels of service desk */
export function shouldShowServiceDeskChannel({
  role,
  groupScope,
  memberStatus,
  sosServiceDesk,
  attendedAccess,
  technicianManager,
}: {
  role: Role;
  groupScope: GroupScope;
  memberStatus: MemberStatus;
  /** the value sos_service_desk of team permission from the info-query API */
  sosServiceDesk: boolean;
  /** the value attended_access of team member permission from the info-query API */
  attendedAccess: boolean;
  /** the value technician_manager of team member permission from the info-query API */
  technicianManager: boolean;
}) {
  return (
    !isGroupAdmin({ role, groupScope }) &&
    ['enabled', 'disabled'].includes(memberStatus) &&
    sosServiceDesk &&
    attendedAccess &&
    technicianManager
  );
}

/** Determine whether the user can access the support session of service desk */
export function shouldShowServiceDeskSupportSession({
  memberStatus,
  sosServiceDesk,
  attendedAccess,
}: {
  memberStatus: MemberStatus;
  /** the value sos_service_desk of team permission from the info-query API */
  sosServiceDesk: boolean;
  /** the value attended_access of team member permission from the info-query API */
  attendedAccess: boolean;
}) {
  return memberStatus === 'enabled' && sosServiceDesk && attendedAccess;
}
