import { Flex } from '@chakra-ui/react';

import { RippleComputerDeviceIcon, RippleStrong } from '@/design';
import { RippleComputerDeviceIconProps } from '@/design';
import { useTeamId } from '@/models/TeamInformation';
import { ComputerDetailTooltip } from '@/showcase/ComputerTable';

import { HighlightSearchKeyword } from './HighlightSearchKeyword';

type ComputerNameContentProps = {
  /** default: team */
  scope?: 'user' | 'team';
  computerId: number;
  name: string;
} & RippleComputerDeviceIconProps;
export function ComputerNameContent({ scope = 'team', computerId, name, type, state }: ComputerNameContentProps): React.JSX.Element {
  const teamId = useTeamId();

  return (
    <ComputerDetailTooltip scope={scope} teamId={String(teamId)} computerId={String(computerId)} computerName={name}>
      <Flex alignItems="center">
        <RippleComputerDeviceIcon type={type} state={state} />
        <RippleStrong as="p" variant="strong02" pl="8px" noOfLines={2}>
          <HighlightSearchKeyword>{name}</HighlightSearchKeyword>
        </RippleStrong>
      </Flex>
    </ComputerDetailTooltip>
  );
}
