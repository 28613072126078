import axios from 'axios';

import { ResponseData } from '@/services/common/types';
import { checkResponse } from '@/services/utils';

import { updateSurveyStatusResponseSchema } from './types';

type UpdateSurveyStatusParams = {
  teamId: number;
  surveyId: number;
  isSuspend: boolean;
};

export const updateSurveyStatus = async ({ teamId, surveyId, isSuspend }: UpdateSurveyStatusParams) => {
  return await checkResponse(
    axios.put<ResponseData>(`/api/web/v1/teams/${teamId}/service_desk/surveys/${surveyId}/status`, {
      status: isSuspend ? 0 : 1, // 0: suspended, 1: activated
    }),
    updateSurveyStatusResponseSchema,
  );
};
