import { useRef } from 'react';

import { Stack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useTranslation } from 'next-i18next';

import {
  RippleBodyText02,
  RippleBodyText03,
  RippleDescriptionList,
  RippleDrawer,
  RippleDrawerBody,
  RippleDrawerCloseButton,
  RippleDrawerContent,
  RippleDrawerFooter,
  RippleDrawerHeader,
  RippleDrawerOverlay,
  RippleHeading05,
  RippleHeading07,
  RippleHeading09,
  RippleTab,
  RippleTabList,
  RippleTabPanel,
  RippleTabPanels,
  RippleTableLegacy,
  RippleTabs,
  RippleTbodyLegacy,
  RippleTdLegacy,
  RippleThLegacy,
  RippleTheadLegacy,
  RippleTrLegacy,
} from '@/design';
import { getComputerInventoryDetail } from '@/services/inventory';
import useTeamInformation from '@/utils/useTeamInformation';
import { useZendeskDisplay } from '@/utils/useZendesk';

import { Inventory } from '../types';
import { fromMBtoGB, fromMHzToGHz, validateSerialNumber } from '../utils';
import CollapseSection from './CollapseSection';
import { useResetSelectRow } from './utils';

export const detailDrawerAtom = atomWithReset<{ isOpen: boolean; inventory: Inventory | null }>({ isOpen: false, inventory: null });

export const DetailDrawer = () => {
  const { t } = useTranslation();
  const teamInformation = useTeamInformation();
  const teamId = teamInformation?.teamId ?? 0;
  const closeButton = useRef<HTMLButtonElement>(null);

  const { isOpen, inventory } = useAtomValue(detailDrawerAtom);
  useZendeskDisplay(!isOpen);

  const id = inventory?.id ?? '';
  const { data } = useQuery({
    queryKey: ['inventoryDetail', teamId, id],
    queryFn: () => getComputerInventoryDetail(teamId, id),
    enabled: Boolean(teamId) && Boolean(id) && isOpen,
    staleTime: 30000,
  });

  const composedInventory = inventory ? { ...inventory, ...data } : null;
  const handleClose = useCloseDrawer();

  return (
    <RippleDrawer isOpen={isOpen} onClose={handleClose} placement="right" initialFocusRef={closeButton}>
      <RippleDrawerOverlay background="none" />
      <RippleDrawerContent minWidth="600px">
        <RippleDrawerHeader paddingY="32px">
          <Stack direction="column" spacing="32px">
            <RippleHeading05 color="dark.90">{composedInventory?.system?.computer_name}</RippleHeading05>
            <RippleHeading09 color="neutral.100">
              {t('inventory:reportedAtTimestamp', { timestamp: composedInventory?.local_time })}
            </RippleHeading09>
          </Stack>
        </RippleDrawerHeader>
        <DrawerBody inventory={composedInventory} />
        <RippleDrawerFooter>
          <RippleDrawerCloseButton onClick={handleClose} ref={closeButton}>
            {t('common:close')}
          </RippleDrawerCloseButton>
        </RippleDrawerFooter>
      </RippleDrawerContent>
    </RippleDrawer>
  );
};

function DrawerBody({ inventory }: { inventory: Inventory | null }): React.JSX.Element | null {
  const { t } = useTranslation();

  if (!inventory) return null;
  const isAndroid = inventory.os === 'Android';

  return (
    <RippleDrawerBody paddingTop="0" overflow="auto">
      <RippleTabs>
        <RippleTabList position="sticky" top="0" background="white" h="auto" pt="3px">
          <RippleTab>{t('inventory:general')}</RippleTab>
          <RippleTab>{t('inventory:hardware')}</RippleTab>
          <RippleTab>{t('inventory:storage')}</RippleTab>
          <RippleTab>{t('inventory:network')}</RippleTab>
          <RippleTab>{t('inventory:software')}</RippleTab>
        </RippleTabList>

        {/* General */}
        <RippleTabPanels>
          <RippleTabPanel>
            <Stack direction="column" spacing="32px">
              <RippleDescriptionList
                title={t('inventory:hardware')}
                items={[
                  {
                    name: t('inventory:manufacturer'),
                    content: inventory.hardware?.manufacturer,
                  },
                  {
                    name: t('inventory:modelProduct'),
                    content: (inventory.hardware?.product || inventory.hardware?.model) ?? '',
                  },
                  {
                    name: t('inventory:serialNumber'),
                    content:
                      validateSerialNumber(inventory.hardware?.motherboard?.bios_serial_number) ||
                      validateSerialNumber(inventory.hardware?.motherboard?.serial_number) ||
                      validateSerialNumber(inventory.hardware?.serial_number) ||
                      '',
                  },
                  {
                    name: t('inventory:cpu'),
                    content: inventory.hardware?.processor?.map(({ type }) => type).join(', '),
                  },
                  {
                    name: t('inventory:ram'),
                    content: `${fromMBtoGB(isAndroid ? inventory?.hardware?.ram?.total : inventory.hardware?.memory.size)} GB`,
                  },
                ]}
              />

              <RippleDescriptionList
                title={t('inventory:system')}
                items={[
                  {
                    name: t('inventory:deviceName'),
                    content: inventory.server_name,
                  },
                  {
                    name: t('inventory:os'),
                    content: inventory.system?.operating_system,
                  },
                  {
                    name: t('inventory:ipAddress'),
                    content: inventory.hardware?.network?.interface
                      ?.map(({ ip_address }) => ip_address ?? [])
                      .flat()
                      .join(', '),
                  },
                  {
                    name: t('inventory:domain'),
                    content: inventory.system?.domain,
                  },
                  {
                    name: t('inventory:timeZone'),
                    content: inventory.system?.time_zone,
                  },
                  {
                    name: t('inventory:lastBoot'),
                    content: inventory.system?.last_boot_time,
                  },
                  {
                    name: t('inventory:lastLoggedOn'),
                    content: inventory.system?.last_logon_user,
                  },
                ]}
              />
            </Stack>
          </RippleTabPanel>

          {/* Hardware */}
          <RippleTabPanel>
            {isAndroid ? (
              <Stack direction="column" spacing="32px">
                {inventory.hardware?.processor?.map((cpu, index) => {
                  return (
                    <RippleDescriptionList
                      key={index}
                      title={t('inventory:cpu')}
                      items={[
                        {
                          name: t('inventory:cpu'),
                          content: cpu.type,
                        },
                        {
                          name: t('inventory:speed'),
                          content: cpu.speed ? `${fromMHzToGHz(cpu.speed)} GHz` : '',
                        },
                        {
                          name: t('inventory:slot'),
                          content: cpu.slot,
                        },
                        {
                          name: t('inventory:cores'),
                          content: cpu.number_of_cores,
                        },
                        {
                          name: t('inventory:logicalProcessors'),
                          content: cpu.number_of_logical_processors,
                        },
                      ]}
                    />
                  );
                })}
                <RippleDescriptionList
                  title={t('inventory:ram')}
                  items={[
                    {
                      name: t('common:total'),
                      content: inventory.hardware?.ram?.total ? `${fromMBtoGB(inventory.hardware?.ram?.total)} GB` : '',
                    },
                  ]}
                />

                <RippleDescriptionList
                  title={t('inventory:screen')}
                  items={[
                    {
                      name: t('common:name'),
                      content: inventory.hardware?.screen?.name,
                    },
                    {
                      name: t('inventory:resolution'),
                      content: inventory.hardware?.screen?.resolution,
                    },
                    {
                      name: t('inventory:dpi'),
                      content: inventory.hardware?.screen?.density_dpi,
                    },
                  ]}
                />

                <RippleDescriptionList
                  title={t('inventory:battery')}
                  items={[
                    {
                      name: t('inventory:health'),
                      content: inventory.hardware?.battery?.health,
                    },
                  ]}
                />
                <Stack direction="column" spacing="16px">
                  <RippleHeading07>{t('inventory:bluetooth')}</RippleHeading07>
                  {inventory.hardware?.bluetooth_device?.map((device, index) => {
                    return (
                      <CollapseSection
                        key={index}
                        title={`${t('inventory:bluetooth')} ${index + 1}`}
                        content={
                          <RippleDescriptionList
                            items={[
                              {
                                name: t('common:name'),
                                content: device.name,
                              },
                              {
                                name: t('inventory:address'),
                                content: device.address,
                              },
                            ]}
                          />
                        }
                      />
                    );
                  })}
                </Stack>

                <Stack direction="column" spacing="16px">
                  <RippleHeading07>{t('inventory:usb')}</RippleHeading07>
                  {inventory.hardware?.usb_device?.map((device, index) => {
                    return (
                      <CollapseSection
                        key={index}
                        title={`${t('inventory:usb')} ${index + 1}`}
                        content={
                          <RippleDescriptionList
                            items={[
                              {
                                name: t('common:name'),
                                content: device.product_name,
                              },
                              {
                                name: t('inventory:manufacturer'),
                                content: device.manufacturer,
                              },
                              {
                                name: t('inventory:serialNumber'),
                                content: device.serial_number,
                              },
                            ]}
                          />
                        }
                      />
                    );
                  })}
                </Stack>
              </Stack>
            ) : (
              <Stack direction="column" spacing="32px">
                <RippleDescriptionList
                  title={t('inventory:motherboard')}
                  items={[
                    {
                      name: t('inventory:biosVersion'),
                      content: inventory.hardware?.motherboard?.bios_version,
                    },
                    {
                      name: t('inventory:chipset'),
                      content: inventory.hardware?.motherboard?.chipset,
                    },
                    {
                      name: t('inventory:memorySlot'),
                      content: inventory.hardware?.motherboard?.memory_slots,
                    },
                    {
                      name: t('inventory:serialNumber'),
                      content: inventory.hardware?.motherboard?.serial_number,
                    },
                  ]}
                />

                {inventory.hardware?.processor?.map((cpu, index) => {
                  return (
                    <RippleDescriptionList
                      key={index}
                      title={t('inventory:cpu')}
                      items={[
                        {
                          name: t('inventory:cpu'),
                          content: cpu.type,
                        },
                        {
                          name: t('inventory:speed'),
                          content: cpu.speed ? `${fromMHzToGHz(cpu.speed)} GHz` : '',
                        },
                        {
                          name: t('inventory:slot'),
                          content: cpu.slot,
                        },
                        {
                          name: t('inventory:cores'),
                          content: cpu.number_of_cores,
                        },
                        {
                          name: t('inventory:logicalProcessors'),
                          content: cpu.number_of_logical_processors,
                        },
                      ]}
                    />
                  );
                })}
                <Stack direction="column" spacing="16px">
                  <RippleDescriptionList
                    title={t('inventory:ram')}
                    items={[
                      {
                        name: t('common:total'),
                        content: inventory.hardware?.memory?.size ? `${fromMBtoGB(inventory.hardware?.memory.size)} GB` : '',
                      },
                    ]}
                  />
                  {inventory.hardware?.memory.modules?.map((module, index) => {
                    return (
                      <CollapseSection
                        key={index}
                        title={`${t('inventory:ram')} ${index}`}
                        content={
                          <RippleDescriptionList
                            items={[
                              {
                                name: t('inventory:manufacturer'),
                                content: module.manufacturer,
                              },
                              {
                                name: t('inventory:size'),
                                content: module.size ? `${fromMBtoGB(module.size)} GB` : '',
                              },
                              {
                                name: t('inventory:speed'),
                                content: module.speed ? `${module.speed} MHz` : '',
                              },
                              {
                                name: t('inventory:slot'),
                                content: module.slot,
                              },
                            ]}
                          />
                        }
                      />
                    );
                  })}
                </Stack>
                <Stack direction="column" spacing="16px">
                  <RippleHeading07>{t('inventory:displayAdapter')}</RippleHeading07>
                  {inventory.hardware?.display_driver?.map((adapter, index) => {
                    return (
                      <CollapseSection
                        key={index}
                        title={`${t('inventory:displayAdapter')} ${index}`}
                        content={
                          <RippleDescriptionList
                            key={index}
                            title={t('inventory:displayAdapter')}
                            items={[
                              {
                                name: t('inventory:type'),
                                content: adapter.type,
                              },
                              {
                                name: t('inventory:vram'),
                                content: adapter.vram ? `${adapter.vram} MB` : '',
                              },
                              {
                                name: t('inventory:resolution'),
                                content: adapter.resolution,
                              },
                              {
                                name: t('inventory:slot'),
                                content: adapter.slot,
                              },
                            ]}
                          />
                        }
                      />
                    );
                  })}
                </Stack>
              </Stack>
            )}
          </RippleTabPanel>

          {/* Storage */}
          <RippleTabPanel>
            {isAndroid ? (
              <RippleDescriptionList
                title={t('inventory:rom')}
                items={[
                  {
                    name: t('inventory:total'),
                    content: inventory?.hardware?.rom?.total ? `${inventory?.hardware?.rom?.total} GB` : '',
                  },
                  {
                    name: t('inventory:free'),
                    content: inventory?.hardware?.rom?.free ? `${inventory?.hardware?.rom?.free} GB` : '',
                  },
                  {
                    name: t('inventory:used'),
                    content: inventory?.hardware?.rom?.used ? `${inventory?.hardware?.rom?.used} GB` : '',
                  },
                ]}
              />
            ) : (
              <Stack direction="column" spacing="32px">
                <Stack direction="column" spacing="16px">
                  <RippleHeading07>{t('inventory:drive')}</RippleHeading07>
                  {inventory.hardware?.drive?.map((drive, index) => {
                    return (
                      <CollapseSection
                        key={index}
                        title={`${t('inventory:drive')} ${index + 1}`}
                        content={
                          <RippleDescriptionList
                            items={[
                              {
                                name: t('common:name'),
                                content: drive.name,
                              },
                              {
                                name: t('inventory:capacity'),
                                content: drive.capacity ? `${fromMBtoGB(drive.capacity)} GB` : '',
                              },
                              {
                                name: t('inventory:type'),
                                content: drive.type,
                              },
                              {
                                name: t('inventory:serialNumber'),
                                content: drive.serial_number,
                              },
                            ]}
                          />
                        }
                      />
                    );
                  })}
                </Stack>
                <Stack direction="column" spacing="16px">
                  <RippleHeading07>{t('inventory:partition')}</RippleHeading07>
                  {inventory.hardware?.drive?.map((drive, driveIndex) => {
                    return drive.partition?.map((partition, partitionIndex) => {
                      return (
                        <CollapseSection
                          key={partitionIndex}
                          title={`${t('inventory:drive')} ${driveIndex + 1} - ${t('inventory:partition')} ${partitionIndex + 1}`}
                          content={
                            <RippleDescriptionList
                              items={[
                                {
                                  name: t('inventory:drive'),
                                  content: partition.drive,
                                },
                                {
                                  name: t('common:name'),
                                  content: partition.name,
                                },
                                {
                                  name: t('inventory:mountPoint'),
                                  content: partition.mount_point,
                                },
                                {
                                  name: t('inventory:fileSystem'),
                                  content: partition.file_system,
                                },
                                {
                                  name: t('inventory:freeSpace'),
                                  content: partition.free_space ? `${fromMBtoGB(partition.free_space)} GB` : '',
                                },
                                {
                                  name: t('inventory:capacity'),
                                  content: partition.capacity ? `${fromMBtoGB(partition.capacity)} GB` : '',
                                },
                              ]}
                            />
                          }
                        />
                      );
                    });
                  })}
                </Stack>
              </Stack>
            )}
          </RippleTabPanel>

          {/* Network */}
          <RippleTabPanel>
            <Stack direction="column" spacing="32px">
              {inventory.hardware?.network?.interface?.map((networkInterface, index) => {
                return (
                  <RippleDescriptionList
                    key={index}
                    title={networkInterface.name}
                    items={[
                      {
                        name: t('inventory:macAddress'),
                        content: networkInterface.physical_address_mac,
                      },
                      {
                        name: t('inventory:ipAddress'),
                        content: networkInterface.ip_address?.join(', '),
                      },
                      {
                        name: t('inventory:subsetMask'),
                        content: networkInterface.subnet_mask?.join(', '),
                      },
                      {
                        name: t('inventory:dhcpServer'),
                        content: networkInterface.dhcp_server,
                      },
                      {
                        name: t('inventory:defaultGateway'),
                        content: networkInterface.default_gateway ?? '',
                      },
                      {
                        name: t('inventory:speed'),
                        content: networkInterface.speed ? `${networkInterface.speed} Mbps` : '',
                      },
                    ]}
                  />
                );
              })}
            </Stack>
          </RippleTabPanel>

          {/* Software */}
          <RippleTabPanel paddingX="0px">
            <RippleTableLegacy width="537px">
              <RippleTheadLegacy>
                <RippleTrLegacy height="40px" paddingX="8px" paddingY="10px" alignItems="center">
                  <RippleThLegacy width="304px" paddingX="8px">
                    <RippleHeading09 color="neutral.300">{t('common:name')}</RippleHeading09>
                  </RippleThLegacy>
                  <RippleThLegacy width="112px" paddingX="8px">
                    <RippleHeading09 color="neutral.300">{t('inventory:version')}</RippleHeading09>
                  </RippleThLegacy>
                  <RippleThLegacy width="120px" paddingX="8px">
                    <RippleHeading09 color="neutral.300">{t('inventory:installedDate')}</RippleHeading09>
                  </RippleThLegacy>
                </RippleTrLegacy>
              </RippleTheadLegacy>
              <RippleTbodyLegacy borderWidth="1px" borderStyle="solid" borderColor="neutral.60" borderRadius="4px">
                {inventory.software?.map((software, index) => {
                  return (
                    <RippleTrLegacy
                      key={index}
                      minHeight="66px"
                      paddingX="8px"
                      paddingY="10px"
                      borderTopWidth="1px"
                      borderTopStyle={index ? 'solid' : 'none'}
                      borderTopColor="neutral.60"
                    >
                      <RippleTdLegacy width="304px" paddingX="8px">
                        <Stack direction="column" spacing="8px">
                          <RippleBodyText02 color="dark.90">{software.name}</RippleBodyText02>
                          <RippleBodyText03 color="dark.40">{software.vendor}</RippleBodyText03>
                        </Stack>
                      </RippleTdLegacy>
                      <RippleTdLegacy width="112px" paddingX="8px">
                        <RippleBodyText02 color="dark.90">{software.version}</RippleBodyText02>
                      </RippleTdLegacy>
                      <RippleTdLegacy width="120px" paddingX="8px">
                        <RippleBodyText02 color="dark.90">{software.install_date}</RippleBodyText02>
                      </RippleTdLegacy>
                    </RippleTrLegacy>
                  );
                })}
              </RippleTbodyLegacy>
            </RippleTableLegacy>
          </RippleTabPanel>
        </RippleTabPanels>
      </RippleTabs>
    </RippleDrawerBody>
  );
}

function useCloseDrawer() {
  const resetDrawer = useResetAtom(detailDrawerAtom);
  const resetSelectRow = useResetSelectRow();

  function closeDrawer() {
    resetDrawer();
    resetSelectRow();
  }

  return closeDrawer;
}
