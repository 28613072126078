import { Box, Card, CardBody, CardFooter, Image, Link, useDisclosure } from '@chakra-ui/react';

import { RippleBodyText01, RippleButton, RippleHeading04, RippleTooltip } from '@/design';

const BIG_LINE_NUMBER = 100;

export type FeatureCardProps = {
  imageUrl: string;
  title: string;
  description: string;
  buttonText: string | null;
  url: string | null;
};

export const FeatureCard = ({ imageUrl, title, description, buttonText, url }: FeatureCardProps) => {
  const accordion = useDisclosure();
  return (
    <Card
      align="center"
      width={['calc(100vw - 32px)', 'calc((100vw - 96px) / 2)', 'calc((100vw - 96px) / 2)', 'calc((100vw - 128px) / 3)']}
      maxWidth={['396px', '480px']}
      minWidth={['288px', '360px']}
      minHeight="480px"
      height={[accordion.isOpen ? 'auto' : '480px', '548px']}
      border="none"
      padding="0px"
      borderRadius="12px"
    >
      <Image width="100%" height={['216px', '264px']} src={imageUrl} alt="" objectFit="cover" borderRadius="12px 12px 0px 0px" />
      <CardBody width="100%" paddingX={['16px', '24px']} paddingY="0px">
        <Box marginY="16px" width="100%">
          <RippleTooltip aria-label={title} label={title} placement="bottom">
            <RippleHeading04 size={['24px', '32px']} marginBottom="8px" noOfLines={2} whiteSpace="break-spaces">
              {title}
            </RippleHeading04>
          </RippleTooltip>
          <RippleTooltip aria-label={description} label={description} placement="bottom">
            <RippleBodyText01
              size={['14px', '16px']}
              color="neutral.300"
              noOfLines={[accordion.isOpen ? BIG_LINE_NUMBER : 4, 4]}
              whiteSpace="break-spaces"
              onClick={accordion.onOpen}
            >
              {description}
            </RippleBodyText01>
          </RippleTooltip>
        </Box>
      </CardBody>
      {url && buttonText && (
        <CardFooter width="100%">
          <Link href={url} width="100%" target="_blank" rel="noopener noreferrer">
            <RippleButton variant="primary" size="sm" width="100%" isTruncated>
              {buttonText}
            </RippleButton>
          </Link>
        </CardFooter>
      )}
    </Card>
  );
};
