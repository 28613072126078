import React from 'react';

import { Box } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleTypography } from '@/design';

export const SelectComputerTitleHeight = 36;

function SelectComputerTitle() {
  const { t } = useTranslation();
  return (
    <Box paddingBottom="12px" backgroundColor="white">
      <RippleTypography variant="heading07">{t('computer:selector.selectComputers')}</RippleTypography>
    </Box>
  );
}

export default SelectComputerTitle;
