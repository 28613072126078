import React from 'react';

import type { TextProps } from '@chakra-ui/react';
import { Text, forwardRef } from '@chakra-ui/react';

const Heading01 = forwardRef<TextProps, 'h1'>(({ children, ...otherProps }, ref) => (
  <Text as="h1" fontSize="60px" lineHeight="72px" fontWeight="600" letterSpacing="-1.6px" ref={ref} {...otherProps}>
    {children}
  </Text>
));

const Heading02 = forwardRef<TextProps, 'h2'>(({ children, ...otherProps }, ref) => (
  <Text as="h2" fontSize="48px" lineHeight="56px" fontWeight="600" letterSpacing="-1.6px" ref={ref} {...otherProps}>
    {children}
  </Text>
));

const Heading03 = forwardRef<TextProps, 'h3'>(({ children, ...otherProps }, ref) => (
  <Text as="h3" fontSize="40px" lineHeight="48px" fontWeight="600" letterSpacing="-0.4px" ref={ref} {...otherProps}>
    {children}
  </Text>
));

const Heading04 = forwardRef<TextProps, 'h4'>(({ children, ...otherProps }, ref) => (
  <Text as="h4" fontSize="32px" lineHeight="40px" fontWeight="600" letterSpacing="-0.32px" ref={ref} {...otherProps}>
    {children}
  </Text>
));

const Heading05 = forwardRef<TextProps, 'h5'>(({ children, ...otherProps }, ref) => (
  <Text as="h5" fontSize="24px" lineHeight="30px" fontWeight="600" letterSpacing="-0.24px" ref={ref} {...otherProps}>
    {children}
  </Text>
));

const Heading06 = forwardRef<TextProps, 'h6'>(({ children, ...otherProps }, ref) => (
  <Text as="h6" fontSize="20px" lineHeight="26px" fontWeight="600" letterSpacing="-0.16px" ref={ref} {...otherProps}>
    {children}
  </Text>
));

const Heading07 = forwardRef<TextProps, 'p'>(({ children, ...otherProps }, ref) => (
  <Text fontSize="16px" lineHeight="24px" fontWeight="700" letterSpacing="0" ref={ref} {...otherProps}>
    {children}
  </Text>
));

const Heading08 = forwardRef<TextProps, 'span'>(({ children, ...otherProps }, ref) => (
  <Text as="span" display="inline-block" fontSize="12px" lineHeight="18px" fontWeight="700" letterSpacing="0" ref={ref} {...otherProps}>
    {children}
  </Text>
));

const Heading09 = forwardRef<TextProps, 'p'>(({ children, ...otherProps }, ref) => (
  <Text fontSize="12px" lineHeight="18px" fontWeight="600" letterSpacing="0" ref={ref} {...otherProps}>
    {children}
  </Text>
));

const BodyText01 = forwardRef<TextProps, 'p'>(({ children, ...otherProps }, ref) => (
  <Text fontSize="16px" lineHeight="24px" fontWeight="400" letterSpacing="0" ref={ref} {...otherProps}>
    {children}
  </Text>
));

const BodyText02 = forwardRef<TextProps, 'p'>(({ children, ...otherProps }, ref) => (
  <Text fontSize="14px" lineHeight="20px" fontWeight="400" letterSpacing="0" ref={ref} {...otherProps}>
    {children}
  </Text>
));

const BodyText03 = forwardRef<TextProps, 'p'>(({ children, ...otherProps }, ref) => (
  <Text fontSize="12px" lineHeight="18px" fontWeight="400" letterSpacing="0" ref={ref} {...otherProps}>
    {children}
  </Text>
));

const ButtonText = forwardRef<TextProps, 'p'>(({ children, ...otherProps }, ref) => (
  <Text fontSize="14px" lineHeight="18px" fontWeight="600" letterSpacing="0" ref={ref} {...otherProps}>
    {children}
  </Text>
));

const Hyperlink01 = forwardRef<TextProps, 'span'>(({ children, ...otherProps }, ref) => (
  <Text
    as="span"
    display="inline-block"
    color="splashtopBlue.100"
    fontSize="14px"
    lineHeight="20px"
    fontWeight="600"
    letterSpacing="0"
    ref={ref}
    {...otherProps}
  >
    {children}
  </Text>
));

const Hyperlink02 = forwardRef<TextProps, 'span'>(({ children, ...otherProps }, ref) => (
  <Text
    as="span"
    display="inline-block"
    color="splashtopBlue.100"
    fontSize="16px"
    lineHeight="24px"
    fontWeight="600"
    letterSpacing="0"
    ref={ref}
    {...otherProps}
  >
    {children}
  </Text>
));

export {
  Heading01,
  Heading02,
  Heading03,
  Heading04,
  Heading05,
  Heading06,
  Heading07,
  Heading08,
  Heading09,
  BodyText01,
  BodyText02,
  BodyText03,
  Hyperlink01,
  Hyperlink02,
  ButtonText,
};
