import { useMemo } from 'react';

import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { GetPluginFunc, OTablePlugin } from '@/components/ObservableTable/types';
import { makeSearchPipe } from '@/components/ObservableTable/utils/search';

export type SearchColumnsParam = {
  accessor: string;
  caseSensitive?: boolean;
};

type SearchColumnsParams = Array<SearchColumnsParam>;

export type SearchParams = {
  keyword: string;
  keywordUpperCase: string;
  columns: SearchColumnsParams;
  groupColumns?: SearchColumnsParams;
};

type UseObservableSearchProps = {
  columns: SearchColumnsParams;
  groupColumns?: SearchColumnsParams;
  initSearchKeyword?: string;
};

type OnColumnChange = (options: { columns?: SearchColumnsParams; groupColumns?: SearchColumnsParams }) => void;
type UseObservableSearchResult = {
  searchPlugin: OTablePlugin;
  onSearchChange: (text: string) => void;
  onClearSearch: () => void;
  getSearchKeyword: () => string;
  onColumnChange: OnColumnChange;
};

function useObservableSearch(props: UseObservableSearchProps): UseObservableSearchResult {
  return useMemo(() => {
    const { initSearchKeyword, columns, groupColumns } = props;

    const initParams: SearchParams = {
      keyword: '',
      keywordUpperCase: '',
      columns,
    };

    if (initSearchKeyword) {
      initParams.keyword = initSearchKeyword;
      initParams.keywordUpperCase = initSearchKeyword.toLocaleUpperCase();
    }

    if (groupColumns) {
      initParams.groupColumns = groupColumns;
    }

    const searchSubject = new BehaviorSubject<SearchParams>(initParams);

    const getPlugin: GetPluginFunc = (tableResult) => searchSubject.pipe(map(makeSearchPipe(tableResult)));

    const onColumnChange: OnColumnChange = ({ columns, groupColumns }) => {
      const nextValue = {
        ...searchSubject.getValue(),
      };

      if (columns) {
        nextValue.columns = columns;
      }

      if (groupColumns) {
        nextValue.groupColumns = groupColumns;
      }

      searchSubject.next(nextValue);
    };

    const onSearchChange = (keyword: string) => {
      searchSubject.next({
        ...searchSubject.getValue(),
        keyword,
        keywordUpperCase: keyword.toLocaleUpperCase(),
      });
    };

    const onClearSearch = () => {
      searchSubject.next({
        ...searchSubject.getValue(),
        keyword: '',
        keywordUpperCase: '',
      });
    };

    const getSearchKeyword = () => {
      const value = searchSubject.getValue();

      return value.keyword;
    };

    const searchPlugin: OTablePlugin = {
      type: 'search' as const,
      getPlugin,
    };

    return {
      searchPlugin,
      onColumnChange,
      onSearchChange,
      onClearSearch,
      getSearchKeyword,
    };
  }, []); // eslint-disable-line
}

export default useObservableSearch;
