import React, { useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import {
  RippleBodyText02,
  RippleHyperLink,
  RippleListItem,
  RippleModal,
  RippleModalBody,
  RippleModalCloseButton,
  RippleModalContent,
  RippleModalHeader,
  RippleModalOverlay,
  RippleOrderedList,
  RippleStrong,
} from '@/design';
import { RippleTypography, TypographyVariant } from '@/design/RippleTypography/RippleTypography';
import { useIsInIframe } from '@/modules/Unboxing/hooks/useIsInIframe';
import composeTranslation from '@/utils/composeTranslation';
import downloadSRC from '@/utils/downloadSRC';

type DownloadModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

export const DownloadModal = ({ isOpen, closeModal }: DownloadModalProps) => {
  const { t } = useTranslation(['setup']);
  const { isInIframe } = useIsInIframe();

  useEffect(() => {
    if (isOpen) downloadSRC({ isInIframe });
  }, [isOpen, isInIframe]);

  const headerFontVariant = useBreakpointValue<TypographyVariant>({ base: 'heading06', md: 'heading05' });

  return (
    <>
      <RippleModal isOpen={isOpen} onClose={closeModal}>
        <RippleModalOverlay />
        <RippleModalContent w="95%">
          <RippleModalHeader borderBottom="1px solid" borderColor="neutral.60" color="dark.100">
            <RippleTypography variant={headerFontVariant ?? 'heading06'}>
              {t('setup:downloadModal.title')}
              <RippleStrong as="p" variant="strong02" mt="8px">
                {t('setup:downloadModal.description')}
              </RippleStrong>
            </RippleTypography>
          </RippleModalHeader>
          <RippleModalCloseButton w="24px" h="24px" />
          <RippleModalBody p="24px">
            <Box fontSize="14px">
              <RippleOrderedList color="dark.100">
                <RippleListItem>{t('setup:downloadModal.runBusinessApp')}</RippleListItem>
                <RippleListItem>{t('setup:downloadModal.loginAndConnect')}</RippleListItem>
              </RippleOrderedList>
              <RippleBodyText02 mt="16px">
                {composeTranslation(
                  t,
                  'setup:downloadModal.notBegin',
                  'clickHereHtml',
                  <RippleHyperLink
                    variant="hyperlink02"
                    href="#"
                    onClick={() => {
                      downloadSRC({ isInIframe });
                    }}
                  >
                    {t('setup:downloadModal.tryAgain')}
                  </RippleHyperLink>,
                )}
              </RippleBodyText02>
            </Box>
          </RippleModalBody>
        </RippleModalContent>
      </RippleModal>
    </>
  );
};
