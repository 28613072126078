import { useEffect, useState } from 'react';

import { HStack, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  RippleButton,
  RippleInput,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleSingleSelect,
  RippleSingleSelectOption,
  RippleSwitch,
  RippleTextarea,
  RippleTooltip,
  RippleTypography,
} from '@/design';
import { featureControl } from '@/feature/toggle';
import { PLATFORMS, Policy } from '@/services/teams/emm_policies';
import preSanitize from '@/utils/pre-sanitize';

import { MAX_POLICY_DESC_LENGTH, MAX_POLICY_NAME_LENGTH } from '../constants';
import { PolicyAssignmentList } from '../hooks';
import { PolicyToCreate } from '../types';
import { getPlatformString } from '../utils';

type ClonePolicyModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  policyNames: Set<string>;
  policesByPlatform: Record<Policy['platform'], PolicyAssignmentList>;
  srcPolicy: Policy | null;
  onClose: () => void;
  onCreate: (policy: PolicyToCreate) => void;
};

export const ClonePolicyModal = ({
  isOpen,
  isLoading,
  policesByPlatform,
  policyNames,
  srcPolicy,
  onClose,
  onCreate,
}: ClonePolicyModalProps) => {
  const [name, setName] = useState(srcPolicy?.name ?? '');
  const [description, setDescription] = useState(srcPolicy?.description ?? '');
  const [enabled, setEnabled] = useState(srcPolicy?.active ?? false);
  const [parentPolicyId, setParentPolicyId] = useState<number | null>(srcPolicy?.parent_id ?? null);
  const { t } = useTranslation();

  const parentNode = policesByPlatform[srcPolicy?.platform ?? 'Windows']?.find(
    ({ policyNode }) => srcPolicy?.parent_id === policyNode.policy.id,
  )?.policyNode;

  const selectedParentName = parentPolicyId === null ? t('common:none') : parentNode?.policy.name;

  useEffect(() => {
    setName(srcPolicy?.name ?? '');
    setDescription(srcPolicy?.description ?? '');
    setEnabled(srcPolicy?.active ?? false);
    setParentPolicyId(srcPolicy?.parent_id ?? null);
  }, [srcPolicy]);

  const isNameDuplicated = policyNames.has(name);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > MAX_POLICY_NAME_LENGTH) {
      return;
    }
    setName(e.target.value);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > MAX_POLICY_DESC_LENGTH) {
      return;
    }
    setDescription(e.target.value);
  };

  const handleNameBlur = () => {
    setName((name) => preSanitize(name).trim());
  };

  const handleCreate = () => {
    if (!srcPolicy) {
      if (isOpen) {
        onClose();
      }
      return;
    }
    onCreate({
      name,
      description,
      active: enabled,
      platform: srcPolicy.platform,
      parentId: parentPolicyId,
    });
  };

  const handleClose = () => {
    setName('');
    setDescription('');
    setEnabled(true);
    onClose();
  };

  return (
    <RippleModal isOpen={isOpen} onClose={handleClose}>
      <RippleModalOverlay />
      <RippleModalContent w="640px">
        <RippleModalHeader>
          <RippleTypography variant="heading05">{t('emm-policy:clone_policy')}</RippleTypography>
        </RippleModalHeader>
        <RippleModalBody>
          <Stack gap="32px" alignItems="stretch">
            <Stack gap="8px">
              <RippleTypography variant="heading08">{t('common:name')}</RippleTypography>
              <Stack gap="4px">
                <RippleInput
                  size="sm"
                  placeholder={t('common:name')}
                  value={name}
                  onBlur={handleNameBlur}
                  onChange={handleNameChange}
                  isInvalid={isNameDuplicated}
                />
                {isNameDuplicated && (
                  <RippleTypography variant="body03" color="red.100">
                    {t('common:duplicated_name')}
                  </RippleTypography>
                )}
              </Stack>
            </Stack>
            <Stack gap="8px">
              <RippleTypography variant="heading08">{t('emm-policy:description')}</RippleTypography>
              <RippleTextarea placeholder="Description" rows={3} value={description} onChange={handleDescriptionChange} />
            </Stack>
            <HStack gap="24px">
              <Stack gap="8px" flex="1">
                <RippleTypography variant="heading08">{t('emm-policy:platform')}</RippleTypography>
                <RippleSingleSelect placeholder="Platforms" value={srcPolicy?.platform ?? ''} onChange={() => {}} isDisabled>
                  {Object.keys(PLATFORMS)
                    .filter(
                      (platform) => platform !== PLATFORMS.Android || featureControl.getToggle('PCP_2647__Policy_support_android_platform'),
                    )
                    .map((platform) => (
                      <RippleSingleSelectOption value={platform} key={platform} title={platform}>
                        {getPlatformString(platform)}
                      </RippleSingleSelectOption>
                    ))}
                </RippleSingleSelect>
              </Stack>
              <Stack gap="8px" flex="1" overflow="hidden">
                <RippleTypography variant="heading08">{t('emm-policy:parent')}</RippleTypography>
                <RippleTooltip label={selectedParentName}>
                  <RippleSingleSelect
                    placeholder={t('emm-policy:parent')}
                    value={String(parentPolicyId ?? '')}
                    onChange={(id?: string) => setParentPolicyId(id ? Number(id) : null)}
                  >
                    <RippleSingleSelectOption value="">{t('common:none')}</RippleSingleSelectOption>
                    <>
                      {parentNode ? (
                        <RippleSingleSelectOption
                          key={parentNode.policy.id}
                          value={String(parentNode.policy.id)}
                          title={parentNode.policy.name}
                        >
                          <RippleTooltip aria-label={parentNode.policy.name} label={parentNode.policy.name}>
                            {parentNode.policy.name}
                          </RippleTooltip>
                        </RippleSingleSelectOption>
                      ) : null}
                    </>
                  </RippleSingleSelect>
                </RippleTooltip>
              </Stack>
            </HStack>
            {/* TODO: Support group admin when spec is ready */}
            {/* <Stack gap="8px">
              <RippleTypography variant="heading08">Group admin</RippleTypography>
              <RippleSingleSelect placeholder="Group admin" value="1" onChange={() => {}}>
                <RippleSingleSelectOption value="1">Admin 1</RippleSingleSelectOption>
              </RippleSingleSelect>
            </Stack> */}
            <HStack gap="12px">
              <RippleTypography variant="heading08">{t('common:enabled')}</RippleTypography>
              <RippleSwitch size="md" isChecked={enabled} onChange={(e) => setEnabled(e.target.checked)} />
            </HStack>
          </Stack>
        </RippleModalBody>
        <RippleModalFooter gap="8px">
          <RippleButton size="sm" variant="grayScaleGhost" onClick={handleClose}>
            {t('common:cancel')}
          </RippleButton>
          <RippleButton size="sm" onClick={handleCreate} isDisabled={!name || isNameDuplicated} isLoading={isLoading}>
            {t('common:clone')}
          </RippleButton>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
};
