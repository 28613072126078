import styled from '@emotion/styled';

const ModalFooter = styled.footer`
  padding: 19px 20px 20px;

  & a {
    font-size: 14px;
    line-height: 1.5;
    color: #428bca;
    text-decoration: none;

    &:hover {
      color: #2a6496;
      text-decoration: underline;
    }
  }
`;

export default ModalFooter;
