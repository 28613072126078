import { useAtom } from 'jotai';

import { RippleComputerSearchBar } from '@/design';

import { searchKeywordAtom } from '../../atoms';

export function SearchBar(): React.JSX.Element {
  const [searchKeyword, setSearchKeyword] = useAtom(searchKeywordAtom);

  return (
    <RippleComputerSearchBar
      key={searchKeyword} // Force re-mount when searchKeyword changes
      initKeyword={searchKeyword}
      onSearch={setSearchKeyword}
      showShortcut
    />
  );
}
