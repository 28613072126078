import { Flex, useClipboard } from '@chakra-ui/react';
import { Trans, useTranslation } from 'next-i18next';

import { RippleCopy16, RippleIconButton, RippleListItem, RippleOrderedList, RippleReady16, RippleStrong } from '@/design';
import { getRegionData } from '@/utils/region';

export const AttendedStreamerSteps = () => {
  const { t } = useTranslation(['setup']);
  const downloadLink = getRegionData().sosDownloadLink;
  const { onCopy, hasCopied } = useClipboard(downloadLink);

  return (
    <RippleOrderedList color="dark.100" fontSize="14px">
      <RippleListItem>
        <Trans
          i18nKey="setup:attendedStreamerDirectEndUserVisitLink"
          components={{
            CopyLink: (
              <Flex alignItems="center">
                <RippleStrong as="p" variant="strong02" color={hasCopied ? 'green.200' : 'dark.100'}>
                  {downloadLink.replace('https://', '')}
                </RippleStrong>
                <RippleIconButton
                  aria-label="copy"
                  onClick={onCopy}
                  icon={hasCopied ? <RippleReady16 color="green.200" /> : <RippleCopy16 color="blue.100" />}
                  color={hasCopied ? 'green.200' : 'dark.100'}
                  width="24px"
                  height="24px"
                />
              </Flex>
            ),
          }}
        />
      </RippleListItem>
      <RippleListItem>{t('setup:attendedStreamerDownloadSosApp')}</RippleListItem>
      <RippleListItem>{t('setup:attendedStreamerEnterSessionCode')}</RippleListItem>
    </RippleOrderedList>
  );
};
