import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleFilter, RippleMenu, RippleMenuButton, RippleMenuItemWithSwitch, RippleMenuList, RippleTooltip } from '@/design';

type FilterActionsProps = {
  showSelectedOnly: boolean;
  onToggleShowSelectedOnly: () => void;
};

export default function FilterActions({ showSelectedOnly, onToggleShowSelectedOnly }: FilterActionsProps) {
  const { t } = useTranslation();
  const isAppliedFilters = showSelectedOnly;
  const iconColor = isAppliedFilters ? 'blue.300' : 'neutral.300';
  const buttonLabel = isAppliedFilters ? t('computer:selector.filtersApplied') : t('common:filter');

  return (
    <RippleMenu closeOnBlur closeOnSelect={false} placement="bottom">
      <RippleTooltip aria-label={buttonLabel} label={buttonLabel} placement="bottom">
        <RippleMenuButton
          aria-label={buttonLabel}
          data-testid="filter-button"
          width="32px"
          minWidth="32px"
          height="32px"
          borderRadius="4px"
          backgroundColor={isAppliedFilters ? 'blue.20' : 'transparent'}
          _hover={{ bg: 'blue.10' }}
        >
          <Flex justifyContent="center" alignItems="center">
            <RippleFilter color={iconColor} isApplied={isAppliedFilters} />
          </Flex>
        </RippleMenuButton>
      </RippleTooltip>
      <RippleMenuList width="336px">
        <RippleMenuItemWithSwitch data-testid="show-selected-only" isChecked={showSelectedOnly} onChange={onToggleShowSelectedOnly}>
          {t('computer:selector.showSelectedOnly')}
        </RippleMenuItemWithSwitch>
      </RippleMenuList>
    </RippleMenu>
  );
}
