import { useMemo } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import type { Row } from '@tanstack/react-table';
import { useAtomValue } from 'jotai';

import { RippleCheckbox } from '@/design';
import { ComputerData } from '@/services/computers';
import { UserComputer } from '@/services/computers/types';
import { getOSIconType } from '@/utils/computers';

import { CellContainer, ComputerNameContent, FROM_OTHER_GROUP_ID, groupNameMapAtom, sortGroup } from '../../ComputerList';

export type WakeComputer = Pick<ComputerData, 'id' | 'uuid' | 'name' | 'version' | 'group_id' | 'online_status' | 'connected'>;

export const WakeTotalWidth = 464;
const columnHelper = createColumnHelper<WakeComputer>();

export function useWakeComputerModalColumns(options: { view: 'computer' | 'group' } = { view: 'group' }) {
  const groupNameMap = useAtomValue(groupNameMapAtom);

  return useMemo(
    () => [
      columnHelper.display({
        id: 'checkbox',
        size: 40,
        enableSorting: false,
        enableHiding: false,
        header: () => null,
        cell: ({ column, row }) => {
          return (
            <CellContainer
              w={`${column.getSize()}px`}
              alignItems="center"
              position="sticky"
              left="0px"
              px="16px"
              borderLeft="1px"
              borderLeftColor="neutral.60"
              zIndex={2}
            >
              <RippleCheckbox
                data-testid={`checkbox-${row.original.id}`}
                isChecked={row.getIsSelected()}
                isDisabled={!row.getCanSelect()}
                onChange={row.getToggleSelectedHandler()}
              />
            </CellContainer>
          );
        },
      }),
      columnHelper.accessor((row) => row.name, {
        id: 'name',
        size: WakeTotalWidth - 40,
        enableHiding: false,
        header: () => null,
        cell: ({ column, getValue, row }) => {
          const { id: computerId, version, online_status, connected } = row.original;
          const computerIcon = getOSIconType(version);
          return (
            <CellContainer
              w={`${column.getSize()}px`}
              borderRight="1px"
              borderRightColor="neutral.60"
              position="sticky"
              left="40px"
              zIndex={2}
              py="6px"
              pl={options.view === 'group' ? '32px' : '12px'}
            >
              <ComputerNameContent
                computerId={computerId}
                name={getValue()}
                type={computerIcon}
                state={connected ? 'connected' : online_status ? 'online' : 'offline'}
              />
            </CellContainer>
          );
        },
      }),
      columnHelper.accessor((row) => row.group_id ?? FROM_OTHER_GROUP_ID, {
        id: 'groupId',
        size: 0,
        filterFn: 'equals', // Keep ability of filtering by group id
        enableSorting: false,
        enableGlobalFilter: false,
        // Hidden column
        header: () => null,
        cell: () => null,
      }),
      columnHelper.accessor((row) => groupNameMap[row.group_id ?? FROM_OTHER_GROUP_ID], {
        id: 'groupName',
        size: 0,
        sortingFn: (a, b) => {
          function transformToGroupItem(row: Row<UserComputer>) {
            const groupId = row.original.group_id ?? FROM_OTHER_GROUP_ID;
            return { id: String(groupId), name: groupNameMap[groupId] };
          }
          return sortGroup(transformToGroupItem(a), transformToGroupItem(b));
        },
        header: () => null,
        cell: () => null,
      }),
    ],
    [groupNameMap],
  );
}
