import { useMemo } from 'react';

import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { GetPluginFunc, OTablePlugin } from '@/components/ObservableTable/types';
import { makeAccordionPipe } from '@/components/ObservableTable/utils/accordion';

export type AccordionHashMap = Record<string, boolean>;
export type OTableAccordionParams = {
  accordionHashMap: AccordionHashMap;
};

type UseObservableAccordionProps = {
  initAccordionIds?: Array<string>;
};

function useObservableAccordion(props: UseObservableAccordionProps = {}) {
  return useMemo(() => {
    const { initAccordionIds } = props;

    const initExpandHashMap = initAccordionIds?.reduce<AccordionHashMap>((acc, id) => {
      acc[id] = true;
      return acc;
    }, {});
    const initParams: OTableAccordionParams = {
      accordionHashMap: initExpandHashMap ?? {},
    };

    const accordionSubject = new BehaviorSubject<OTableAccordionParams>(initParams);

    const updateAccordion = (accordionHashMap: AccordionHashMap) => {
      accordionSubject.next({
        ...accordionSubject.getValue(),
        accordionHashMap,
      });
    };

    const getPlugin: GetPluginFunc = (tableResult) => accordionSubject.pipe(map(makeAccordionPipe(tableResult, { updateAccordion })));

    const onToggleAccordion = (id: string, expanded?: boolean) => {
      const currentValue = accordionSubject.getValue();
      const nextExpanded = expanded === undefined ? !currentValue.accordionHashMap[id] : expanded;

      accordionSubject.next({
        ...currentValue,
        accordionHashMap: {
          ...currentValue.accordionHashMap,
          [id]: nextExpanded,
        },
      });
    };

    const getAccordionState = (id: string) => {
      const value = accordionSubject.getValue();

      return Boolean(value.accordionHashMap[id]);
    };

    const accordionPlugin: OTablePlugin = {
      type: 'accordion' as const,
      getPlugin,
    };

    return {
      accordionPlugin,
      onToggleAccordion,
      getAccordionState,
    };
  }, []); // eslint-disable-line
}

export default useObservableAccordion;
