import axios from 'axios';

import type {
  ClientConnectData,
  ClientConnectUrlData,
  InviteOptions,
  InviteResponseData,
  InviterInformation,
  ReInviteOptions,
  ResponseData,
  ResponseErrorReason,
  SeatInformationResponse,
  Session,
  SessionsData,
  TeamMember,
  TeamMembersData,
} from '@/services/common/types';
import { isSuccess } from '@/services/utils';

function getTeamMembers(teamId: number, hasUserManagementPermission?: boolean) {
  return new Promise<Array<TeamMember>>((resolve, reject) => {
    axios
      .get<ResponseData & TeamMembersData>(`/api/web/v1/sos/teams/${teamId}/team_members`, {
        params: {
          mode: 'customize',
          customized_fields: hasUserManagementPermission
            ? 'role,xauth_method_id,status,invite_code,technician_manager,enable_2fa'
            : 'role,xauth_method_id,status,invite_code,technician_manager',
        },
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

const getSeatInformation = (teamId: number) => {
  return new Promise<SeatInformationResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: SeatInformationResponse }>(`/api/web/v1/sos/teams/${teamId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const resetPassword = (teamId: number, memberId: number) => {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .post<ResponseData & ResponseErrorReason>(`/api/web/v1/sos/teams/${teamId}/team_members/${memberId}/reset_password`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const disableMember = (teamId: number, memberId: number) => {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .put<ResponseData & ResponseErrorReason>(`/api/web/v1/sos/teams/${teamId}/team_members/${memberId}`, {
        status: 'disabled',
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};
const enableMember = (teamId: number, memberId: number) => {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .put<ResponseData & ResponseErrorReason>(`/api/web/v1/sos/teams/${teamId}/team_members/${memberId}`, {
        status: 'enabled',
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const changeDisplayName = (teamId: number, memberId: number, displayName: string) => {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .put<ResponseData & ResponseErrorReason>(`/api/web/v1/sos/teams/${teamId}/team_members/${memberId}`, {
        name: displayName,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const changeXauthMethod = (teamId: number, memberId: number, methodId: number) => {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .put<ResponseData & ResponseErrorReason>(`/api/web/v1/sos/teams/${teamId}/team_members/${memberId}`, {
        xauth_method_id: methodId,
      })
      .then((res) => {
        const {
          data: { result, messages },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject(`${messages.toString()}`);
        }
      })
      .catch(reject);
  });
};

const deleteMember = (teamId: number, memberId: number) => {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .delete<ResponseData & ResponseErrorReason>(`/api/web/v1/sos/teams/${teamId}/team_members/${memberId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const inviteTeamMember = (teamId: number, options: InviteOptions) => {
  const { email, message, xauthMethodId = 0 } = options;
  return new Promise<{ result: ResponseData['result'] } & InviteResponseData['data']>((resolve, reject) => {
    axios
      .post<ResponseData & InviteResponseData>(`/api/web/v1/sos/teams/${teamId}/team_members`, {
        email: email, // <被邀請的 Email>,
        invitation_text: message, // <邀請 message>,
        xauth_method_id: xauthMethodId, //<sso method id>
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({
            result,
            ...data,
          });
        } else {
          reject({ result, status: messages });
        }
      })
      .catch(() => {
        reject({ result: 400, status: 'network error' });
      });
  });
};

const resendInviteTeamMember = (teamId: number, memberId: number, options: ReInviteOptions) => {
  return new Promise<{ result: ResponseData['result'] }>((resolve, reject) => {
    const { message, xauthMethodId, cc } = options;
    axios
      .post<ResponseData & InviteResponseData>(`/api/web/v1/sos/teams/${teamId}/team_members/${memberId}/resend_invite`, {
        invitation_text: message,
        xauth_method_id: xauthMethodId,
        cc: cc,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ result });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const leaveTeam = (teamId: number, memberId: number) => {
  return new Promise<boolean>((resolve, reject) => {
    axios
      .post<ResponseData & { data: { status: boolean } }>(`/api/web/v1/sos/teams/${teamId}/team_members/${memberId}/quit`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data.status);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const getSessions = (teamId: number) => {
  return new Promise<Array<Session>>((resolve, reject) => {
    axios
      .get<ResponseData & SessionsData>(`/api/web/v1/sos/teams/${teamId}/api_sessions`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const getClientConnectUrl = (teamId: number, token: string) => {
  return new Promise<string>((resolve, reject) => {
    axios
      .post<ResponseData & ClientConnectUrlData>(`/api/web/v1/sos/teams/${teamId}/api_sessions/client_connect_url`, {
        token: token,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data.url);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const checkClientConnect = (teamId: number) => {
  return new Promise<ClientConnectData['data']>((resolve, reject) => {
    axios
      .get<ResponseData & ClientConnectData>(`/api/web/v1/sos/teams/${teamId}/api_sessions/check_client_connect`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const checkSessionConnect = (teamId: number, memberId: number) => {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .get<ResponseData & ResponseErrorReason>(`/api/web/v1/sos/teams/${teamId}/api_sessions/${memberId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const disconnectSession = (teamId: number, memberId: number) => {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .post<ResponseData & ResponseErrorReason>(`/api/web/v1/sos/teams/${teamId}/api_sessions/${memberId}/disconnect`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const addSessionNote = (teamId: number, memberId: number, note: string) => {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .put<ResponseData & ResponseErrorReason>(`/api/web/v1/sos/teams/${teamId}/api_sessions/${memberId}`, {
        note,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const getInviterInformation = (teamId: number, memberId: number) => {
  return new Promise<InviterInformation>((resolve, reject) => {
    axios
      .get<ResponseData & { data: InviterInformation }>(`/api/web/v1/sos/teams/${teamId}/team_members/${memberId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const acceptInvitation = (teamId: number, memberId: number) => {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .post<ResponseData & { data: { status: string } }>(`/api/web/v1/sos/teams/${teamId}/team_members/${memberId}/accept_invitation`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const declineInvitation = (teamId: number, memberId: number) => {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .post<ResponseData & { data: { status: string } }>(`/api/web/v1/sos/teams/${teamId}/team_members/${memberId}/decline_invitation`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const setTechnicianManagerWithCapacity = (
  teamId: number,
  memberId: number,
  granted: boolean,
  user_management: boolean,
  team_settings_management: boolean,
) => {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .patch<ResponseData & ResponseErrorReason>(`/api/web/v1/sos/teams/${teamId}/team_members/${memberId}/change_role`, {
        technician_manager: granted,
        technician_manager_capability: {
          user_management,
          team_settings_management,
        },
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

export {
  getTeamMembers,
  getSeatInformation,
  resetPassword,
  enableMember,
  disableMember,
  changeDisplayName,
  changeXauthMethod,
  deleteMember,
  inviteTeamMember,
  resendInviteTeamMember,
  leaveTeam,
  getSessions,
  getClientConnectUrl,
  checkClientConnect,
  checkSessionConnect,
  disconnectSession,
  addSessionNote,
  getInviterInformation,
  acceptInvitation,
  declineInvitation,
  setTechnicianManagerWithCapacity,
};
