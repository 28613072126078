import { Flex } from '@chakra-ui/react';

const NumberTag = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex
      borderRadius="50%"
      backgroundColor="blue.20"
      color="blue.100"
      width="32px"
      height="32px"
      justify="center"
      align="center"
      lineHeight="1em"
      flexShrink={0}
    >
      {children}
    </Flex>
  );
};

export { NumberTag };
