import { createContext, useContext } from 'react';

import { Button, Divider, HStack, TabPanel, TabPanels, Tabs, VStack, forwardRef, useTab } from '@chakra-ui/react';
import type { LayoutProps, StackProps, TabPanelProps, TabPanelsProps, TabProps, TabsProps } from '@chakra-ui/react';

import { RippleButtonText } from '@/design';

type ContentHeight = LayoutProps['height'];
const ModalTabsContext = createContext<{ contentHeight: ContentHeight }>({} as never);

type ModalTabsProps = TabsProps & { contentHeight: ContentHeight };
export const ModalTabs = forwardRef<ModalTabsProps, 'div'>(({ contentHeight, ...otherProps }, ref) => (
  <ModalTabsContext.Provider value={{ contentHeight }}>
    <Tabs ref={ref} {...otherProps} />
  </ModalTabsContext.Provider>
));

type ModalTabsContainerProps = StackProps & { children: [tabList: React.ReactNode, tabPanels: React.ReactNode] };
export const ModalTabsContainer = forwardRef<ModalTabsContainerProps, 'div'>(({ children: [tabList, tabPanels], ...otherProps }, ref) => {
  const { contentHeight } = useContext(ModalTabsContext);

  return (
    <HStack ref={ref} spacing="16px" alignItems="start" h={contentHeight} {...otherProps}>
      {tabList}
      <Divider orientation="vertical" />
      {tabPanels}
    </HStack>
  );
});

type ModalTabListProps = StackProps;
export const ModalTabList = forwardRef<ModalTabListProps, 'div'>((props, ref) => <VStack ref={ref} spacing="4px" pt="2px" {...props} />);

type ModalTabProps = TabProps;
export const ModalTab = forwardRef<ModalTabProps, 'button'>((props, ref) => {
  const { children } = props;
  const tabProps = useTab({ ...props, ref });
  const isSelected = Boolean(tabProps['aria-selected']);

  return (
    <Button
      variant="ghost"
      w="224px"
      borderRadius="4px"
      color={isSelected ? 'blue.200' : 'dark.80'}
      bg={isSelected ? 'blue.20' : 'transparent'}
      justifyContent="start"
      {...tabProps}
    >
      <RippleButtonText color="inherit">{children}</RippleButtonText>
    </Button>
  );
});

type ModalTabPanelsProps = TabPanelsProps;
export const ModalTabPanels = forwardRef<ModalTabPanelsProps, 'div'>((props, ref) => {
  const { contentHeight } = useContext(ModalTabsContext);

  return <TabPanels ref={ref} h={contentHeight} overflowY="scroll" mt="0" {...props} />;
});

/**
 * 避免雙重 scroll bar，在 tab panel 加 padding bottom 以顯示全部內容
 * modal header + modal footer 高度約 155px，高於此高度皆可
 */
const TAB_PANEL_PADDING_BOTTOM = '200px';
type ModalTabPanelProps = TabPanelProps;
export const ModalTabPanel = forwardRef<ModalTabPanelProps, 'div'>((props, ref) => {
  return <TabPanel ref={ref} p={`0 0 ${TAB_PANEL_PADDING_BOTTOM} 0`} {...props} />;
});
