import { atom } from 'jotai';

type expandedGroupsAtom = Record<number, boolean>;
export const expandedGroupsAtom = atom<expandedGroupsAtom>({});

export const isAllGroupsExpandedAtom = atom<boolean>((get) => {
  const expandedGroups = get(expandedGroupsAtom);
  return Object.values(expandedGroups).every((value) => value);
});

export const toggleAllGroupsExpandedAtom = atom(null, (get, set) => {
  const isAllGroupsExpanded = get(isAllGroupsExpandedAtom);
  set(expandedGroupsAtom, Object.fromEntries(Object.keys(get(expandedGroupsAtom)).map((id) => [id, !isAllGroupsExpanded])));
});
