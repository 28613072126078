import { useState } from 'react';

import { HStack, Spinner, VStack, useDisclosure } from '@chakra-ui/react';
import * as Sentry from '@sentry/nextjs';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai';
import chunk from 'lodash/chunk';
import flatten from 'lodash/flatten';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'next-i18next';

import { DEFAULT_GROUP_ID } from '@/components/ComputerList/utils';
import {
  RippleButton,
  RippleExport,
  RippleIconButton,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalTitle,
  RippleRadio,
  RippleRadioGroup,
  RippleTooltip,
  useRippleFlashMessage,
} from '@/design';
import { useTeamId, useTeamInformation, useTeamRole } from '@/models/TeamInformation';
import { computerListAtom, groupNameMapAtom } from '@/modules/Computer/ComputerList';
import { formatInventoryList, sortRowsByCondition } from '@/modules/Inventory/Datagrid/Datagrid';
import { ExportInventory, ExtractSoftware, ExtractSystemHardware, generateFileName } from '@/modules/Inventory/ExportModal/utils';
import { Inventory } from '@/modules/Inventory/types';
import { type ComputerData, getComputerIds, getComputerListByToken, getTeamComputerTokenList } from '@/services/computers';
import { getComputerGroupList } from '@/services/group';
import { appendCancelTokenToRequest, cancelRequests } from '@/services/interceptor';
import { queryComputerInventory } from '@/services/inventory';
import type { FieldTypes } from '@/services/inventory';
import { infoQueryService } from '@/services/users';
import { downloadAsCSV } from '@/utils/downloadAsCSV';
import { useTime } from '@/utils/formatDateTime';
import { arrayToHashPair } from '@/utils/hashTable';

import type { ComputerItemBase } from '../../types';
import { useActionBarContext } from './hooks';

type ExportType = 'computer' | 'system' | 'software';

type ComputerListDataBonusItem = Pick<
  ComputerData,
  'id' | 'name' | 'email' | 'last_session_detail' | 'version' | 'os' | 'last_online' | 'note' | 'pubip' | 'local_ip' | 'host_name'
>;

type ExportActionsProps = {
  isDisabled?: boolean;
};

export function ExportActions(props: ExportActionsProps) {
  return (
    <ShowExportActions>
      <ExportActionsCore {...props} />
    </ShowExportActions>
  );
}

function ExportActionsCore({ isDisabled = false }: ExportActionsProps) {
  const { t, i18n } = useTranslation();

  const { teamKind } = useTeamInformation();

  const { isLoading } = useActionBarContext();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const [exportType, setExportType] = useState<ExportType>('computer');
  const [isExporting, setIsExporting] = useState(false);
  const { flashMessage } = useRippleFlashMessage();
  const teamId = useTeamId();
  const groupNameMap = useAtomValue(groupNameMapAtom);
  const computerListData = useAtomValue(computerListAtom) as Array<ComputerItemBase & ComputerListDataBonusItem>;
  const { formatDateTime } = useTime(i18n.language);

  const isSBATeam = teamKind === 'sba';
  const permissionMap = usePermissionMap({ enabled: !isSBATeam });
  const isFetchingPermissionMap = permissionMap === null;
  const canOnlyExportComputer = isSBATeam || (permissionMap && !permissionMap.seat_permissions.premier_pack);

  const openModal = () => {
    onOpen();
  };

  const handleRadioChange = (newExportType: ExportType) => {
    setExportType(newExportType);
  };

  const closeModal = () => {
    // User can close the modal before the request is completed

    setIsExporting(false);
    handleRadioChange('computer');
    cancelRequests();
    onClose();
  };

  const handleSubmit = async () => {
    setIsExporting(true);

    try {
      await executeExport(exportType);
    } catch (error: any) {
      // Exclude cancel request error
      Sentry.captureException(error);
      if (error.statusCode !== 9487) {
        flashMessage({
          title: t('common:unexpectedError'),
          variant: 'error',
        });
      }
      console.error(error);
    } finally {
      closeModal();
    }
  };

  const handleExportComputer = async () => {
    setIsExporting(true);

    try {
      await executeExport('computer');
    } catch (error: any) {
      Sentry.captureException(error);
      // Exclude cancel request error
      if (error.statusCode !== 9487) {
        flashMessage({
          title: t('common:unexpectedError'),
          variant: 'error',
        });
      }
      console.error(error);
    } finally {
      setIsExporting(false);
    }
  };

  if (canOnlyExportComputer) {
    return (
      <RippleTooltip label={t('common:export')}>
        <RippleIconButton
          aria-label="export"
          icon={<RippleExport />}
          isLoading={isExporting}
          onClick={handleExportComputer}
          spinner={<Spinner size="sm" color="neutral.300" />}
          isDisabled={isDisabled || isExporting || isLoading}
        />
      </RippleTooltip>
    );
  }

  return (
    <>
      <RippleTooltip label={t('common:export')}>
        <RippleIconButton
          data-testid="export"
          aria-label="export"
          icon={<RippleExport />}
          onClick={openModal}
          isDisabled={isDisabled || isFetchingPermissionMap || isLoading}
        />
      </RippleTooltip>
      <RippleModal isOpen={isOpen} onClose={closeModal}>
        <RippleModalOverlay />
        <RippleModalContent w="448px">
          <RippleModalHeader>
            <RippleModalTitle>{t('inventory:export.title')}</RippleModalTitle>
          </RippleModalHeader>
          <RippleModalBody>
            <RippleRadioGroup value={exportType} isDisabled={isExporting} onChange={handleRadioChange}>
              <VStack alignItems="start">
                <RippleRadio value="computer">{t('computer:computerList')}</RippleRadio>
                <RippleRadio value="system">{t('inventory:export.option1')}</RippleRadio>
                <RippleRadio value="software">{t('inventory:export.option2')}</RippleRadio>
              </VStack>
            </RippleRadioGroup>
          </RippleModalBody>
          <RippleModalFooter>
            <HStack spacing="12px">
              <RippleButton variant="grayScaleGhost" onClick={closeModal}>
                {t('common:cancel')}
              </RippleButton>
              <RippleButton variant="primary" isLoading={isExporting} onClick={handleSubmit}>
                {t('common:export')}
              </RippleButton>
            </HStack>
          </RippleModalFooter>
        </RippleModalContent>
      </RippleModal>
    </>
  );

  async function executeExport(exportType: ExportType) {
    appendCancelTokenToRequest();

    switch (exportType) {
      case 'computer': {
        const computerListDataBonus = await getTeamComputerTokenList(teamId).then(async (tokenList) => {
          const requestList = tokenList.map((token) => {
            return getComputerListByToken(teamId, {
              token,
              mode: 'customize',
              customizedFields: ['email', 'last_session_detail'],
            });
          });
          const result = await Promise.all(requestList);
          return flatten(result).reduce((acc: Record<string, Omit<ComputerListDataBonusItem, 'id'>>, item) => {
            const { id, ...rest } = item as ComputerListDataBonusItem;
            acc[id] = rest;
            return acc;
          }, {});
        });

        const dataKeyList = [
          'name',
          'host_name',
          'group_id',
          'os',
          'version',
          'owner',
          'pubip',
          'last_online',
          'last_remote_user',
          'last_session_start_time',
          'last_session_end_time',
          'note',
          'local_ip',
        ] as const;

        const data: Array<Array<string>> = computerListData.map((item) => {
          return dataKeyList.map((key) => {
            if (key === 'group_id') {
              if (item['group_id']) {
                return groupNameMap[item['group_id']];
              } else {
                return '';
              }
            }
            if (key === 'version') {
              return item['version']?.split(' ')[0].replace('SRS/', '') ?? '';
            }
            if (key === 'owner') {
              const userEmail = computerListDataBonus[item.id]?.['email'];
              return userEmail ?? 'deployed computer';
            }
            if (key === 'last_online') {
              const lastOnline = item['last_online'];
              return lastOnline ? formatDateTime(lastOnline) : '';
            }
            if (key === 'last_remote_user') {
              const lastSessionDetail = computerListDataBonus[item.id]?.['last_session_detail'];
              return lastSessionDetail?.user ?? '';
            }
            if (key === 'last_session_start_time') {
              const lastSessionDetail = computerListDataBonus[item.id]?.['last_session_detail'];
              return lastSessionDetail?.start_at ? formatDateTime(lastSessionDetail.start_at) : '';
            }
            if (key === 'last_session_end_time') {
              const lastSessionDetail = computerListDataBonus[item.id]?.['last_session_detail'];
              return lastSessionDetail?.end_at ? formatDateTime(lastSessionDetail.end_at) : '';
            }
            return item[key] ?? '';
          });
        });
        const fileName = `Computer_List-${format(new Date(), 'yyyyMMddHHMMSS')}`;
        const headers = [
          'Computer Name',
          'Device Name',
          'Group Name',
          'Operating System',
          'Streamer Version',
          'Owner',
          'IP Address',
          'Last Online',
          'Last Remote User',
          'Last Session Start Time',
          'Last Session End Time',
          'Note',
          'LAN IP Addresses',
        ];
        downloadAsCSV({
          data,
          fileName,
          headers,
          transform: (v) => v,
        });

        break;
      }
      case 'system': {
        const { groupHashMap, dataInventoryList } = await getInventoryData(teamId, ['system', 'hardware', 'local_time']);
        const extract = new ExtractSystemHardware(groupHashMap);
        const rawList: Array<Inventory> = dataInventoryList.map(({ raw }) => raw);
        const { data, headers } = new ExportInventory(extract).output(rawList);
        const fileName = generateFileName(exportType);

        downloadAsCSV({
          data,
          fileName,
          headers,
          transform: (v) => v,
        });

        break;
      }
      case 'software': {
        const { groupHashMap, dataInventoryList } = await getInventoryData(teamId, ['software', 'local_time']);
        const extract = new ExtractSoftware(groupHashMap);
        const rawList: Array<Inventory> = dataInventoryList.map(({ raw }) => raw);
        const { data, headers } = new ExportInventory(extract).output(rawList);
        const fileName = generateFileName(exportType);

        downloadAsCSV({
          data,
          fileName,
          headers,
          transform: (v) => v,
        });

        break;
      }
    }
  }

  async function getInventoryData(teamId: number, fieldsTypes?: FieldTypes) {
    const [rawGroups, inventoryList] = await Promise.all([getComputerGroupList(teamId), queryAllComputersInventory(teamId, fieldsTypes)]);
    const groups = rawGroups.map(({ id, name }) => {
      return {
        id: id.toString(),
        name,
      };
    });
    const sortGroups = [
      ...sortBy(groups, ['name']),
      {
        id: DEFAULT_GROUP_ID,
        name: t('computer:defaultGroup'),
      },
    ];
    const groupHashMap = arrayToHashPair(sortGroups, { mainKey: 'id', valueKey: 'name' });
    const inventorySummaryList = formatInventoryList(inventoryList, groupHashMap);
    const sortCondition = {
      column: 'computerName',
      order: 'asc',
    } as const;

    const dataInventoryList = sortRowsByCondition(inventorySummaryList, sortCondition);
    return {
      groupHashMap,
      dataInventoryList,
    };
  }

  // Copy from src/modules/Inventory/Inventory.tsx
  async function queryAllComputersInventory(teamId: number, fieldsTypes?: FieldTypes) {
    const res = await getComputerIds(teamId);
    if (res.length === 0) {
      return [];
    }
    const idListChunks = chunk(res, 1000); // system hardware software, this field max sieze is 1000
    const requestList = idListChunks.map((idList) => {
      return queryComputerInventory(teamId, idList, fieldsTypes);
    });
    const result = await Promise.all(requestList);
    return flatten(result);
  }
}

type ShowExportActionsProps = { children?: React.ReactNode };
function ShowExportActions({ children }: ShowExportActionsProps): React.JSX.Element | null {
  const { isManager } = useTeamRole();

  if (isManager) return <>{children}</>;

  return null;
}

type PermissionMap = { seat_permissions: { premier_pack: boolean } };
function usePermissionMap({ enabled }: { enabled: boolean }): PermissionMap | null {
  const { teamKind } = useTeamInformation();

  const permissionMapQuery = useQuery({
    queryKey: ['ComputerList', 'ExportAction', 'getPermissions'],
    queryFn: () => infoQueryService.execute({ seat_permissions: ['premier_pack'] }),
    staleTime: Infinity,
    gcTime: Infinity,
    enabled,
  });

  if (permissionMapQuery.isFetching) return null;

  const permissionMap = permissionMapQuery.data?.[teamKind];
  const fallback: PermissionMap = { seat_permissions: { premier_pack: false } };

  return permissionMap ?? fallback;
}
