import { ReactNode } from 'react';

import { Td, Tr } from '@/components/BasicTable';

type DataRowProps = {
  rowId: string;
  isLastRowInGroup: boolean;
  children: ReactNode;
};

export default function DataRow({ rowId, isLastRowInGroup, children }: DataRowProps) {
  return (
    <Tr key={rowId} data-testid={`row-${rowId}`} paddingBottom={isLastRowInGroup ? '12px' : undefined}>
      <Td display="flex" alignItems="center" overflow="hidden">
        {children}
      </Td>
    </Tr>
  );
}
