import { z } from 'zod';

import { isValidateDateTime } from '@/utils/datetime';

import { TeamKind } from '../../common/types';
import { licenseInfoSchema } from './team_info/schemas';
import {
  groupScopeSchema,
  isResellSchema,
  licenseTypeSchema,
  memberIdSchema,
  memberStatusSchema,
  rolesSchema,
} from './team_member_info/schemas';
import { QueryMap, TeamKindResult } from './types';

export function generateSchema<Q extends QueryMap, T extends TeamKind>(query: Q) {
  return z.custom<Record<T, TeamKindResult<Q>>>((data: any) => {
    const typedData = data as Record<T, TeamKindResult<Q>>;
    const teamKindResults: Array<any> = Object.values(typedData);
    if (teamKindResults.length === 0) {
      return false;
    }
    return teamKindResults.every((teamKindResult) => {
      let result = true;
      if (query.seat_permissions) {
        result =
          result &&
          teamKindResult.seat_permissions &&
          query.seat_permissions.every((seatPermission) => seatPermission in teamKindResult.seat_permissions);
      }

      if (query.team_permissions) {
        result =
          result &&
          teamKindResult.team_permissions &&
          query.team_permissions.every((teamPermission) => teamPermission in teamKindResult.team_permissions);
      }

      if (query.team_member_permissions) {
        result =
          result &&
          teamKindResult.team_member_permissions &&
          query.team_member_permissions.every((teamPermission) => teamPermission in teamKindResult.team_member_permissions);
      }

      if (query.team_info) {
        result = query.team_info.every((teamInfo) => {
          switch (teamInfo) {
            case 'license_info': {
              return licenseInfoSchema.safeParse(teamKindResult.team_info.license_info).success;
            }
            case 'expires_at': {
              return isValidateDateTime(teamKindResult.team_info.expires_at);
            }
            case 'trial_premium_expired_at': {
              return (
                teamKindResult.team_info.trial_premium_expired_at === null ||
                isValidateDateTime(teamKindResult.team_info.trial_premium_expired_at)
              );
            }
            case 'trial_emm_expired_at': {
              return (
                teamKindResult.team_info.trial_emm_expired_at === null || isValidateDateTime(teamKindResult.team_info.trial_emm_expired_at)
              );
            }
            case 'is_expired':
            case 'upsell_premium':
            case 'can_upgrade_premium':
            case 'can_current_user_upgrade_premium':
            case 'can_trial_premium':
            case 'can_current_user_trial_premium':
            case 'upsell_emm':
            case 'can_buy_emm':
            case 'can_current_user_buy_emm':
            case 'can_trial_emm':
            case 'can_current_user_trial_emm':
              return teamInfo in teamKindResult.team_info;
            case 'feature_id':
            case 'feature_capacity':
              return z.number().nullable().safeParse(teamKindResult.team_info[teamInfo]).success;
            default: {
              const error: never = teamInfo;
              throw new Error(error);
            }
          }
        });
      }

      if (query.team_member_info) {
        result =
          result &&
          teamKindResult.team_member_info &&
          query.team_member_info.every((teamMemberInfo) => {
            switch (teamMemberInfo) {
              case 'role': {
                return rolesSchema.safeParse(teamKindResult.team_member_info.role).success;
              }
              case 'member_status': {
                return memberStatusSchema.safeParse(teamKindResult.team_member_info.member_status).success;
              }
              case 'group_scope': {
                return groupScopeSchema.safeParse(teamKindResult.team_member_info.group_scope).success;
              }
              case 'is_resell': {
                return isResellSchema.safeParse(teamKindResult.team_member_info.is_resell).success;
              }
              case 'member_id': {
                return memberIdSchema.safeParse(teamKindResult.team_member_info.member_id).success;
              }
              case 'license_type': {
                return licenseTypeSchema.safeParse(teamKindResult.team_member_info.license_type).success;
              }
              default: {
                const error: never = teamMemberInfo;
                throw new Error(error);
              }
            }
          });
      }

      return result;
    });
  });
}
