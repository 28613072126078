import { useTranslation } from 'next-i18next';

import FullPageLoading from '@/components/FullPageLoading';
import Container from '@/layouts/common/Container';
import Unboxing from '@/modules/Unboxing';
import { useStreamerTypeHandler } from '@/modules/Unboxing/hooks/useStreamerType';
import { WelcomeComputersModule } from '@/modules/Welcome/Computers';
import { PageContainer, PageLayout } from '@/showcase';
import { getRegionData } from '@/utils/region';

import { TeamStatusSection } from './ComputerList';

const { env } = getRegionData();

export function OOBE({ kind }: { kind: 'user' | 'team' }): React.JSX.Element {
  if (env === 'global') return <GlobalOOBE />;
  return <EuroOOBE kind={kind} />;
}

function GlobalOOBE(): React.JSX.Element {
  const { t } = useTranslation();

  const { type } = useStreamerTypeHandler();

  if (type === 'none') return <FullPageLoading />;

  return (
    <PageLayout>
      <PageContainer>
        <Container width={['100%', 750, 970, 1170]} pt="12px">
          <TeamStatusSection />
        </Container>
        <Unboxing pageTitle={t('setup:howToConnect')} />
      </PageContainer>
    </PageLayout>
  );
}

function EuroOOBE({ kind }: { kind: 'user' | 'team' }): React.JSX.Element {
  return (
    <PageLayout hideFooter sx={{ main: { display: 'flex', flexDirection: 'column' } }}>
      <WelcomeComputersModule kind={kind} />
    </PageLayout>
  );
}
