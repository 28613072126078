import { format, utcToZonedTime } from 'date-fns-tz';

export function formatTimezone(timeZoneMinutes: number) {
  const symbol = `${timeZoneMinutes >= 0 ? '+' : '-'}`;
  const positiveMinutes = Math.abs(timeZoneMinutes);

  const hours = Math.floor(positiveMinutes / 60);
  const minutes = Math.floor(positiveMinutes % 60);

  return `${symbol}${hours >= 10 ? hours : `0${hours}`}:${minutes >= 10 ? minutes : `0${minutes}`}`;
}

/**
 * @param date
 * @returns 2023-08-10 19:16:49 by timezone
 */
export const convertToUserTimezone = (date: Date) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate = utcToZonedTime(date, userTimeZone);

  return format(zonedDate, 'yyyy-MM-dd HH:mm:ss', { timeZone: userTimeZone });
};

/**
 * @param date
 * @returns 2023-08-10 19:16 by timezone
 */
export const convertToUserTimezoneToMinute = (date: Date) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate = utcToZonedTime(date, userTimeZone);

  return format(zonedDate, 'yyyy-MM-dd HH:mm', { timeZone: userTimeZone });
};
