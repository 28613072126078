import { useRouter } from 'next/router';

import { Box, Center, useDisclosure, useSteps } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { RippleSheet, RippleStep, RippleStepIndicator, RippleStepNumber, RippleStepStatus, RippleStepTitle, RippleStepper } from '@/design';
import {
  CustomFields,
  DownloadPage,
  ErrorSection,
  General,
  LeaveConfirmModal,
  SnippetSection,
} from '@/modules/ServiceDesk/SupportForm/Editor';
import { SUPPORT_FORM_CONTENT_WIDTH } from '@/modules/ServiceDesk/SupportForm/Editor/constants';
import { useSnippetUrl } from '@/modules/ServiceDesk/SupportForm/Editor/hooks/useEditingForm';
import { DOWNLOAD_PAGE_KIND_MAP, SupportFormMode, SupportFormValue } from '@/modules/ServiceDesk/SupportForm/Editor/types';
import type { ErrorResponse } from '@/services/common/types';
import { SupportFormValueForAPI } from '@/services/serviceDesk/supportForm/types';
import preSanitize from '@/utils/pre-sanitize';

type SupportFormLayoutProps = {
  defaultFormValues?: SupportFormValue;
  apiError: ErrorResponse | null;
  isFetching?: boolean;
  mode: SupportFormMode;
  onSubmit: (formData: SupportFormValueForAPI) => void;
};

export const SupportFormLayout = ({ apiError, defaultFormValues, isFetching, mode, onSubmit }: SupportFormLayoutProps) => {
  const { t } = useTranslation();
  const snippetUrl = useSnippetUrl();
  const hasSnippetUrl = snippetUrl !== '';

  const router = useRouter();

  const supportFormId = parseInt(router.query.code as string, 10);

  const toFormListPage = () => {
    const channelId = router.query.id as string;
    const formListPage = `/service_desk/channels/${channelId}/support_forms`;
    router.push(formListPage);
  };

  const { isOpen, onClose, onOpen } = useDisclosure();

  const openLeaveConfirmModal = onOpen;

  const TITLE_MAP = {
    create: t('support-forms:createForm'),
    edit: t('support-forms:editForm'),
    clone: t('support-forms:createForm'),
  };

  const steps = [t('support-forms:general'), t('support-forms:customFields'), t('support-forms:downloadPage')];

  const { activeStep, goToPrevious, goToNext } = useSteps({
    index: 0,
    count: steps.length,
  });

  const form = useForm<SupportFormValue>({
    defaultValues: defaultFormValues,
    reValidateMode: 'onSubmit',
  });

  const handleSubmit = () => {
    form.handleSubmit(async (formValue) => {
      const requestData: SupportFormValueForAPI = {
        ...formValue,
        questionnaire: formValue.questionnaire.map(({ kind, required, question, placeholder, default_option, options }, index) => {
          return {
            kind,
            required,
            default_option,
            order: index,
            question: preSanitize(question),
            placeholder: preSanitize(placeholder),
            options: options
              ? options.map(({ option }) => {
                  return { option: preSanitize(option) };
                })
              : null,
          };
        }),
        name: preSanitize(formValue.name),
        technician_id: parseInt(formValue.technician_id, 10),
        download_page_kind: DOWNLOAD_PAGE_KIND_MAP[formValue.download_page_kind],
      };
      onSubmit(requestData);
    })();
  };

  return (
    <>
      <RippleSheet
        contentWidth="960px"
        title={hasSnippetUrl ? '' : TITLE_MAP[mode]}
        handleClose={hasSnippetUrl ? toFormListPage : openLeaveConfirmModal}
      >
        {hasSnippetUrl ? (
          <SnippetSection onClose={toFormListPage} />
        ) : (
          <FormProvider {...form}>
            <Center display="flex" flexDirection="column" justifyContent="center">
              <RippleStepper index={activeStep} w={SUPPORT_FORM_CONTENT_WIDTH} mt="20px" gap="8px">
                {steps.map((stepName) => (
                  <RippleStep key={stepName}>
                    <RippleStepIndicator>
                      <RippleStepStatus complete={<RippleStepNumber />} incomplete={<RippleStepNumber />} active={<RippleStepNumber />} />
                    </RippleStepIndicator>
                    <Box flexShrink="0">
                      <RippleStepTitle>{stepName}</RippleStepTitle>
                    </Box>
                  </RippleStep>
                ))}
              </RippleStepper>
              {activeStep === 0 && (
                <General onClickPreviousStep={openLeaveConfirmModal} onClickNextStep={goToNext} supportFormId={supportFormId} mode={mode} />
              )}
              {activeStep === 1 && <CustomFields onClickPreviousStep={goToPrevious} onClickNextStep={goToNext} />}
              {activeStep === 2 && (
                <DownloadPage onClickPreviousStep={goToPrevious} onClickNextStep={handleSubmit} mode={mode} isFetching={isFetching} />
              )}
            </Center>
          </FormProvider>
        )}
      </RippleSheet>
      <ErrorSection apiError={apiError} toFormListPage={toFormListPage} />
      <LeaveConfirmModal isOpen={isOpen} onClose={onClose} onConfirm={toFormListPage} />
    </>
  );
};
