import { Box, Center, Flex, VStack } from '@chakra-ui/react';
import { Global, css } from '@emotion/react';
import { useTranslation } from 'next-i18next';

import TeamStatus from '@/components/TeamStatus';
import { RippleBodyText02, RippleButton, RippleHeading04, RippleHyperLink } from '@/design';
import Container from '@/layouts/common/Container';
import Spinner from '@/modules/Welcome/Spinner';
import type { QuickSetupInfo } from '@/modules/Welcome/types';
import { PageFooter } from '@/showcase/Layout';
import composeTranslation from '@/utils/composeTranslation';
import useTeamInformation from '@/utils/useTeamInformation';

import EmptySRS from './EmptySRS';

type ComputersProps = {
  quickSetupInfo: QuickSetupInfo;
};

const Computers = ({ quickSetupInfo }: ComputersProps) => {
  const { t } = useTranslation();
  const teamInformation = useTeamInformation();

  const isSBAMemberWithInstruction =
    teamInformation?.currentTeam === 'sba' &&
    teamInformation?.role === 'member' &&
    quickSetupInfo.SRSDownloadKind === 'none' &&
    quickSetupInfo.SRSDownloadInstruction !== '';

  // BE still see `splashtop` and `ste_custom` as `srs` in BE logic in 3720
  const isSRSMemberWithInstruction =
    (teamInformation?.currentTeam === 'srs' ||
      teamInformation?.currentTeam === 'splashtop' ||
      teamInformation?.currentTeam === 'ste_custom') &&
    teamInformation?.role === 'member';

  const renderAddRemoteComputerButton = () => {
    if (!quickSetupInfo.SRSDownloadLink) {
      return (
        <RippleButton width="212px" isDisabled>
          <Spinner />
        </RippleButton>
      );
    }

    let anchorTarget = '_top';
    let anchorHref = '/login';
    if (teamInformation) {
      const { SRSDownloadKind, SRSDownloadLink } = quickSetupInfo;
      const { role, groupScope, currentTeam } = teamInformation;

      if (role !== 'member' && groupScope === 'all') {
        anchorHref = SRSDownloadLink;
      } else if (role !== 'member' && groupScope === 'part') {
        anchorHref = '/team_deployment';
      } else if (role === 'member' && currentTeam === 'sba' && SRSDownloadKind !== 'none') {
        anchorTarget = '_blank';
        anchorHref = SRSDownloadLink;
      }
    }

    if (!quickSetupInfo.SRSDownloadLink) {
      return (
        <RippleButton width="212px" isDisabled>
          {t('welcome:addRemoteComputer')}
        </RippleButton>
      );
    }

    return (
      <RippleButton
        as="a"
        href={anchorHref}
        target={anchorTarget}
        rel="noopener,noreferrer"
        width="212px"
        data-testid="add-remote-computer-button"
      >
        {t('welcome:addRemoteComputer')}
      </RippleButton>
    );
  };

  return (
    <>
      <Global
        styles={css`
          #__next {
            display: flex;
            flex-direction: column;
            min-height: 100vh;
          }
        `}
      />
      <Container width={['100%', 750, 970, 1170]}>
        <TeamStatus isInIframe />
        <Flex minHeight="80vh" maxHeight="100%" align="center" justify="center">
          <Box>
            <Box width={['100%', '586px']} margin="auto">
              <EmptySRS />
            </Box>
            <VStack width={['100%', '586px']} mt="32px">
              <RippleHeading04 textAlign="center">
                {isSBAMemberWithInstruction || isSRSMemberWithInstruction ? t('welcome:noComputerYet') : t('welcome:setupRemoteComputer')}
              </RippleHeading04>
              <RippleBodyText02 textAlign="center" mt="32px">
                {t('welcome:noRemoteComputer')}
              </RippleBodyText02>
              {(isSBAMemberWithInstruction || isSRSMemberWithInstruction) && (
                <RippleBodyText02 textAlign="center" m="0">
                  {t('welcome:teamAdminConfigure')}
                </RippleBodyText02>
              )}
            </VStack>
            <Box textAlign="center" marginTop="48px">
              {!(isSBAMemberWithInstruction || isSRSMemberWithInstruction) && renderAddRemoteComputerButton()}
            </Box>
          </Box>
        </Flex>
        <Center>
          <RippleBodyText02 m="0">
            {composeTranslation(
              t,
              'welcome:startAgain',
              'setupGuide',
              <RippleHyperLink
                variant="hyperlink02"
                onClick={() => {
                  let href = '/quick-setup';

                  if (teamInformation) {
                    if (teamInformation.currentTeam === 'sba') href = '/welcome/sba';
                    else if (teamInformation.currentTeam === 'srs') href = '/welcome/srs';
                  }

                  if (window.top) window.top.location.href = href;
                }}
              >
                {t('welcome:setupGuide')}
              </RippleHyperLink>,
            )}
          </RippleBodyText02>
        </Center>
      </Container>
      <PageFooter />
    </>
  );
};

export { Computers };
