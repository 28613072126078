import { useTranslation } from 'next-i18next';

import {
  RippleAlertSpecificColor,
  RippleClear,
  RippleComputerIconCell,
  RippleDownloadAvailable,
  RippleReady,
  RippleTdLegacyProps,
  RippleTooltip,
  RippleUpdating,
} from '@/design';
import { WindowsUpdatesStatusInfo, WindowsUpdatesStatusInfoValues } from '@/modules/WindowsUpdates/utils';

const WindowsUpdatesStatus = {
  [WindowsUpdatesStatusInfo.disabled]: <RippleClear color="red.100" />,
  [WindowsUpdatesStatusInfo.updating]: <RippleUpdating color="neutral.100" />,
  [WindowsUpdatesStatusInfo.installedWaitRestart]: <RippleUpdating color="neutral.100" />,
  [WindowsUpdatesStatusInfo.importantUpdates]: <RippleAlertSpecificColor />,
  [WindowsUpdatesStatusInfo.optionalUpdates]: <RippleDownloadAvailable color="#0BD3FF" />, // Special Case
  [WindowsUpdatesStatusInfo.noUpdates]: <RippleReady color="green.200" />,
} as const;

type WindowsUpdatesStatusCellProps = WindowsUpdatesStatusContentCellProps & RippleTdLegacyProps;

export const WindowsUpdatesStatusCell = ({
  statusInfoType,
  importantCount,
  optionalCount,
  ...otherProps
}: WindowsUpdatesStatusCellProps) => {
  return (
    <RippleComputerIconCell {...otherProps}>
      <WindowsUpdatesStatusCellContent statusInfoType={statusInfoType} importantCount={importantCount} optionalCount={optionalCount} />
    </RippleComputerIconCell>
  );
};

type WindowsUpdatesStatusContentCellProps = {
  statusInfoType: WindowsUpdatesStatusInfoValues | null;
  importantCount: number | null;
  optionalCount: number | null;
};
export const WindowsUpdatesStatusCellContent = ({
  statusInfoType,
  importantCount,
  optionalCount,
}: WindowsUpdatesStatusContentCellProps) => {
  const { t } = useTranslation();

  const statusIcon = statusInfoType !== null ? WindowsUpdatesStatus[statusInfoType] : null;
  let tooltipLabel = null;

  switch (statusInfoType) {
    case WindowsUpdatesStatusInfo.disabled:
      tooltipLabel = t('windows-updates:updateDisabled');
      break;
    case WindowsUpdatesStatusInfo.updating:
      tooltipLabel = t('windows-updates:updateUpdating', {
        importantCount: importantCount,
        optionalCount: optionalCount,
      });
      break;
    case WindowsUpdatesStatusInfo.installedWaitRestart:
      tooltipLabel = t('windows-updates:updateInstalledWaitRestart', {
        importantCount: importantCount,
        optionalCount: optionalCount,
      });
      break;
    case WindowsUpdatesStatusInfo.importantUpdates:
      tooltipLabel = t('windows-updates:importantUpdates', {
        importantCount: importantCount,
        optionalCount: optionalCount,
      });
      break;
    case WindowsUpdatesStatusInfo.optionalUpdates:
      tooltipLabel = t('windows-updates:optionalUpdates', {
        optionalCount: optionalCount,
      });
      break;
    case WindowsUpdatesStatusInfo.noUpdates:
      tooltipLabel = t('windows-updates:noUpdates');
      break;
    default: {
      if (statusInfoType !== null) {
        const neverType: never = statusInfoType;
        throw new Error(neverType);
      }
    }
  }

  if (!statusIcon || !tooltipLabel) return null;

  return (
    <RippleTooltip aria-label={tooltipLabel} label={tooltipLabel}>
      <div>{statusIcon}</div>
    </RippleTooltip>
  );
};
