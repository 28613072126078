import { Box, BoxProps, SystemStyleObject } from '@chakra-ui/react';

import { RippleSkeleton } from '@/design';

type MultiLinesLoadingProps = {
  count: number;
  loading: boolean;
  sx?: SystemStyleObject;
} & BoxProps;

const MultiLinesLoading = ({ count = 1, loading, sx, ...otherProps }: MultiLinesLoadingProps) => {
  if (!loading) {
    return null;
  }

  return (
    <>
      {Array(count)
        .fill(null)
        .map((_, index) => {
          return (
            <Box key={index} {...otherProps}>
              <RippleSkeleton height="30px" sx={sx} />
            </Box>
          );
        })}
    </>
  );
};

export default MultiLinesLoading;
