import emojiRegex from 'emoji-regex';

const UTF8_REGEX = /[\u{0}-\u{FFFF}]/gu;

const EMOJI_UTF8_REGEX =
  /[\u00A9\u00AE\u203C\u2049\u2139\u2194-\u2199\u21A9\u21AA\u2328\u23CF\u23ED-\u23EF\u23F1\u23F2\u23F8-\u23FA\u24C2\u25AA\u25AB\u25B6\u25C0\u25FB\u25FC\u2600-\u2604\u260E\u2611\u2618\u2620\u2622\u2623\u2626\u262A\u262E\u262F\u2638-\u263A\u2640\u2642\u265F\u2660\u2663\u2665\u2666\u2668\u267B\u267E\u2692\u2694-\u2697\u2699\u269B\u269C\u26A0\u26A7\u26B0\u26B1\u26C8\u26CF\u26D1\u26D3\u26E9\u26F0\u26F1\u26F4\u26F7\u26F8\u2702\u2708\u2709\u270F\u2712\u2714\u2716\u271D\u2721\u2733\u2734\u2744\u2747\u2763\u27A1\u2934\u2935\u2B05-\u2B07\u3030\u303D\u3297\u3299]|[\u231A\u231B\u23E9-\u23EC\u23F0\u23F3\u25FD\u25FE\u2614\u2615\u2648-\u2653\u267F\u2693\u26A1\u26AA\u26AB\u26BD\u26BE\u26C4\u26C5\u26CE\u26D4\u26EA\u26F2\u26F3\u26F5\u26FA\u26FD\u2705\u2728\u274C\u274E\u2753-\u2755\u2757\u2795-\u2797\u27B0\u27BF\u2B1B\u2B1C\u2B50\u2B55]/gu;

const EMOJI_REGEX = emojiRegex();

export function htmlInjectionFilter(str: string) {
  return str.replace(/</gi, '＜').replace(/>/gi, '＞').replace(/;/gi, '；');
}

export function emojiFilter(str: string) {
  return str.replace(EMOJI_REGEX, '').replace(EMOJI_UTF8_REGEX, '').match(UTF8_REGEX)?.join('') ?? '';
}

const compose = <R>(fn1: (a: R) => R, ...fns: Array<(a: R) => R>) => fns.reduce((prevFn, nextFn) => (value) => prevFn(nextFn(value)), fn1);

export default compose(emojiFilter, htmlInjectionFilter);
