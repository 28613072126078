// reference: Fulong API v3: 2.32 PERMISSION OF SUPPORT SESSION

export const NoPermission = /*            */ 0b0000000000000000000000000000000 as const;
export const UnattendedMode = /*          */ 0b0000000000000000000000000000001 as const;
export const SessionRecording = /*        */ 0b0000000000000000000000000000010 as const;
export const RequestRemote = /*           */ 0b0000000000000000000000000000100 as const;
export const FileTransfer = /*            */ 0b0000000000000000000000000001000 as const;
export const Chat = /*                    */ 0b0000000000000000000000000010000 as const;
export const CommandSession = /*          */ 0b0000000000000000000000000100000 as const;
export const DiagnosticSession = /*       */ 0b0000000000000000000000001000000 as const;
export const TaskManager = /*             */ 0b0000000000000000000000010000000 as const;
export const ServiceManager = /*          */ 0b0000000000000000000000100000000 as const;
export const RegistryEditor = /*          */ 0b0000000000000000000001000000000 as const;
export const DeviceManager = /*           */ 0b0000000000000000000010000000000 as const;
