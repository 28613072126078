import { useContext } from 'react';

import { SeatContext } from '@/models/SeatContext';

export type SeatInformation = {
  multipleMagnification: number;
  seatsUsersCount: number;
  occupiedSeatCount: number;
  teamMembersCount: number;
  onShelf: boolean;
  canChangePlan: boolean;
  canTrial: boolean;
  isRecurring: boolean;
  hasAutoRenew: boolean;
  canBuyProduct: boolean;
  buyUrl: string;
  trialUrl: string;
  upgradeUrl: string | null;
  teamPermissions: {
    sso: boolean;
  };
  teamMemberPermissions: {
    sso: boolean;
  };
  productOptions: {
    canBuyPcs: boolean;
  };
  teamSettings?: {
    scheduleTimeZoneMinutes: number;
    scheduleTimeZone: string | null;
  };
  teamCode: string;
  isDefault?: boolean;
};

function useSeatInformation(): SeatInformation {
  const {
    multiple_magnification,
    seats_users_count,
    occupied_seat_count,
    team_members_count,
    on_shelf,
    can_change_plan,
    can_trial,
    buy_url,
    trial_url,
    upgrade_url,
    seat_is_recurring,
    seat_auto_renew,
    can_buy_product,
    team_permissions,
    team_member_permissions,
    product_options,
    team_settings,
    team_code,
    isDefault,
  } = useContext(SeatContext);

  return {
    multipleMagnification: multiple_magnification,
    seatsUsersCount: seats_users_count,
    occupiedSeatCount: occupied_seat_count,
    teamMembersCount: team_members_count,
    onShelf: on_shelf,
    canChangePlan: can_change_plan,
    canTrial: can_trial,
    isRecurring: seat_is_recurring,
    hasAutoRenew: seat_auto_renew,
    canBuyProduct: can_buy_product,
    buyUrl: buy_url,
    trialUrl: trial_url,
    upgradeUrl: upgrade_url,
    teamPermissions: {
      sso: team_permissions.sso,
    },
    teamMemberPermissions: {
      sso: team_member_permissions.sso,
    },
    productOptions: {
      canBuyPcs: product_options.can_buy_pcs,
    },
    teamSettings: {
      scheduleTimeZoneMinutes: team_settings?.schedule_time_zone_minutes || 0,
      scheduleTimeZone: team_settings?.schedule_time_zone || 'UTC',
    },
    teamCode: team_code,
    isDefault,
  };
}

export default useSeatInformation;
