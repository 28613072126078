import { useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';

import { RippleBodyText02 } from '@/design';

import { rowSelectionCountAtom } from '../../atoms';

export function SelectedComputerCount(): React.JSX.Element {
  const { t } = useTranslation();

  const selectedCount = useAtomValue(rowSelectionCountAtom);

  return <RippleBodyText02>{t('computer:numberSelected', { selectedCount })}</RippleBodyText02>;
}
