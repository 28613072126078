import axios from 'axios';

import { checkResponse } from '@/services/utils';

import { policyDetailResponseDataSchema } from './types';

/**
 * Patch Policy Detail API
 * @param teamId
 * @param emmPolicyId
 * @doc https://github.com/SplashtopInc/be_kung_fu/blob/master/be_app/api/web-api/v1/emm/software_patch.md#patch-policy-detail-api
 */

const getPatchPolicyDetailUrl = (teamId: number, emmPolicyId: number) =>
  `/api/web/v1/teams/${teamId}/emm_policies/${emmPolicyId}/patch_policy`;

export function getPatchPolicyDetail(teamId: number, emmPolicyId: number) {
  return checkResponse(axios.get(getPatchPolicyDetailUrl(teamId, emmPolicyId)), policyDetailResponseDataSchema);
}

export const getPatchPolicyDetailService = {
  getUrl: getPatchPolicyDetailUrl,
  method: 'GET',
  execute: getPatchPolicyDetail,
};
