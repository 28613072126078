import * as Sentry from '@sentry/nextjs';
import { useMutation } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';

import { type EditableSettingType, type RoleSwitchSettingKey, RoleSwitchUpdateKey } from '@/services/teamSettings';
import { updateTeamSettingsService } from '@/services/teams/team_settings';
import { RoleSwitchSettingPayload } from '@/services/teams/team_settings/updateTeamSettingsService';

import { queryMainKey } from '../constants';
import { showErrorMessageOnRailsPage } from '../utils';
import { useTeamControlContext } from './useTeamControlContext';

type SingleRoleUpdatePayload = {
  type?: 'single';
  settingKey: EditableSettingType;
  updateKey: RoleSwitchUpdateKey;
  role: RoleSwitchSettingKey;
  enabled: boolean;
};
type MultipleRoleUpdatePayload = {
  type: 'multiple';
  settingKey: EditableSettingType;
  updateKey: RoleSwitchUpdateKey;
  roleMap: Partial<Record<RoleSwitchSettingKey, boolean>>;
};
type Payload = SingleRoleUpdatePayload | MultipleRoleUpdatePayload;

/**
 * Mutation hook for role switch.
 */
export function useFeatureRoleSwitchMutation() {
  const { t } = useTranslation();

  const { atoms } = useTeamControlContext();
  const teamMetadata = useAtomValue(atoms.teamMetadataAtom);
  const dispatch = useSetAtom(atoms.teamSettingsAtom);

  return useMutation({
    mutationKey: [queryMainKey, 'mutateRoleSwitch'],
    mutationFn: async (payload: Payload) => {
      if (payload.type === 'multiple') {
        const { updateKey, roleMap } = payload;
        return updateTeamSettingsService.execute(teamMetadata.team_id, {
          settings: Object.entries(roleMap).map<RoleSwitchSettingPayload>(([_role, enabled]) => {
            const role = _role as RoleSwitchSettingKey;

            return {
              setting_type: updateKey,
              update_status: updateRoleSwitchValue(role, enabled),
            };
          }),
        });
      }

      // Single role update
      const { updateKey, role, enabled } = payload;
      return updateTeamSettingsService.execute(teamMetadata.team_id, {
        settings: [{ setting_type: updateKey, update_status: updateRoleSwitchValue(role, enabled) }],
      });
    },
    onMutate: (payload: Payload) => {
      if (payload.type === 'multiple') {
        const { settingKey, roleMap } = payload;
        dispatch({
          type: 'update',
          updateDraft: (draft) => {
            const settingState = draft[settingKey];
            if (settingState && settingState.role_switch) {
              settingState.role_switch = { ...settingState.role_switch, ...roleMap };
            }
          },
        });
        return;
      }

      // Single role update
      const { settingKey, role, enabled } = payload;
      dispatch({
        type: 'update',
        updateDraft: (draft) => {
          const settingState = draft[settingKey];
          if (settingState && settingState.role_switch) {
            settingState.role_switch = { ...settingState.role_switch, [role]: enabled };
          }
        },
      });
    },
    onSuccess: () => {
      dispatch({ type: 'save' });
    },
    onError: (error) => {
      Sentry.captureException(error);
      showErrorMessageOnRailsPage(t('common:unexpectedError'));
      dispatch({ type: 'rollback' });
    },
  });
}

/**
 * Used to avoid the limitation of using constraint properties in TypeScript.
 */
function updateRoleSwitchValue(role: RoleSwitchSettingKey, enabled: boolean): RoleSwitchSettingPayload['update_status'] {
  switch (role) {
    case 'manager':
      return { manager: enabled };
    case 'group_admin':
      return { group_admin: enabled };
    case 'member':
      return { member: enabled };
  }
}
