import React, { useState } from 'react';

import { HStack, VStack } from '@chakra-ui/react';
import * as Sentry from '@sentry/nextjs';
import chunk from 'lodash/chunk';
import flatten from 'lodash/flatten';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'next-i18next';

import { DEFAULT_GROUP_ID } from '@/components/ComputerList/utils';
import { RippleButton, RippleModalTitle, RippleRadio, RippleRadioGroup, useRippleFlashMessage } from '@/design';
import {
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
} from '@/design/RippleModal';
import type { RippleModalProps } from '@/design/RippleModal';
import { formatInventoryList, sortRowsByCondition } from '@/modules/Inventory/Datagrid/Datagrid';
import { getComputerIds } from '@/services/computers';
import { getComputerGroupList } from '@/services/group';
import { appendCancelTokenToRequest, cancelRequests } from '@/services/interceptor';
import { queryComputerInventory } from '@/services/inventory';
import type { FieldTypes } from '@/services/inventory';
import { downloadAsCSV } from '@/utils/downloadAsCSV';
import { arrayToHashPair } from '@/utils/hashTable';
import useTeamInformation from '@/utils/useTeamInformation';

import { Inventory } from '../types';
import { ExportType } from './types';
import { ExportInventory, ExtractSoftware, ExtractSystemHardware, generateFileName } from './utils';

export type ExportModalProps = Pick<RippleModalProps, 'isOpen' | 'onClose'>;

export const ExportModal = ({ isOpen, onClose }: ExportModalProps) => {
  const { t } = useTranslation();
  const [exportType, setExportType] = useState<ExportType>('system');
  const [isExporting, setIsExporting] = useState(false);
  const { flashMessage } = useRippleFlashMessage();
  const teamInformation = useTeamInformation();
  const teamId = teamInformation?.teamId ?? 0;

  const handleRadioChange = (newValue: ExportType) => setExportType(newValue);

  const closeModal = () => {
    setIsExporting(false);
    handleRadioChange('system');
    cancelRequests();
    onClose();
  };

  const handleSubmit = async () => {
    setIsExporting(true);
    appendCancelTokenToRequest();

    try {
      switch (exportType) {
        case 'system': {
          const { groupHashMap, dataInventoryList } = await getInventoryData(
            teamId,

            ['system', 'hardware', 'local_time', 'os', 'os_build', 'architecture'],
          );
          const extract = new ExtractSystemHardware(groupHashMap);
          const rawList: Array<Inventory> = dataInventoryList.map(({ raw }) => raw);
          const { data, headers } = new ExportInventory(extract).output(rawList);
          downloadAsCSV({
            data,
            fileName: generateFileName('system'),
            headers,
            transform: (v) => v,
          });
          break;
        }
        case 'software': {
          const { groupHashMap, dataInventoryList } = await getInventoryData(teamId, ['software', 'local_time']);
          const extract = new ExtractSoftware(groupHashMap);
          const rawList: Array<Inventory> = dataInventoryList.map(({ raw }) => raw);
          const { data, headers } = new ExportInventory(extract).output(rawList);
          downloadAsCSV({
            data,
            fileName: generateFileName('software'),
            headers,
            transform: (v) => v,
          });
          break;
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
      // Exclude cancel request error
      if (error.statusCode !== 9487) {
        flashMessage({
          title: t('common:unexpectedError'),
          variant: 'error',
        });
      }
    } finally {
      closeModal();
    }

    async function getInventoryData(teamId: number, fieldsTypes?: FieldTypes) {
      const [rawGroups, inventoryList] = await Promise.all([getComputerGroupList(teamId), queryAllComputersInventory(teamId, fieldsTypes)]);
      const groups = rawGroups.map(({ id, name }) => {
        return {
          id: id.toString(),
          name,
        };
      });
      const sortGroups = [
        ...sortBy(groups, ['name']),
        {
          id: DEFAULT_GROUP_ID,
          name: t('computer:defaultGroup'),
        },
      ];
      const groupHashMap = arrayToHashPair(sortGroups, { mainKey: 'id', valueKey: 'name' });
      const inventorySummaryList = formatInventoryList(inventoryList, groupHashMap);
      const sortCondition = {
        column: 'computerName',
        order: 'asc',
      } as const;

      const dataInventoryList = sortRowsByCondition(inventorySummaryList, sortCondition);
      return {
        groupHashMap,
        dataInventoryList,
      };
    }

    // Copy from src/modules/Inventory/Inventory.tsx
    async function queryAllComputersInventory(teamId: number, fieldsTypes?: FieldTypes) {
      const res = await getComputerIds(teamId);
      if (res.length === 0) {
        return [];
      }
      const idListChunks = chunk(res, 1000); // system hardware software, this field max sieze is 1000
      const requestList = idListChunks.map((idList) => {
        return queryComputerInventory(teamId, idList, fieldsTypes);
      });
      const result = await Promise.all(requestList);
      return flatten(result);
    }
  };

  return (
    <RippleModal isOpen={isOpen} onClose={closeModal}>
      <RippleModalOverlay />
      <RippleModalContent w="448px">
        <RippleModalHeader>
          <RippleModalTitle>{t('inventory:export.title')}</RippleModalTitle>
        </RippleModalHeader>
        <RippleModalBody>
          <RippleRadioGroup value={exportType} isDisabled={isExporting} onChange={handleRadioChange}>
            <VStack alignItems="start">
              <RippleRadio value="system">{t('inventory:export.option1')}</RippleRadio>
              <RippleRadio value="software">{t('inventory:export.option2')}</RippleRadio>
            </VStack>
          </RippleRadioGroup>
        </RippleModalBody>
        <RippleModalFooter>
          <HStack spacing="12px">
            <RippleButton variant="grayScaleGhost" onClick={closeModal}>
              {t('common:cancel')}
            </RippleButton>
            <RippleButton variant="primary" isLoading={isExporting} onClick={handleSubmit}>
              {t('common:download')}
            </RippleButton>
          </HStack>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
};
