import { useMemo } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { type ColumnPermissions, checkPermission } from '@/modules/Computer/ComputerList';
import { useRegisterComputerDataFields } from '@/modules/Computer/ComputerList/hooks';
import { computeShowOOBE_SBA_MyComputers } from '@/modules/Computer/utils';
import { type ComputerListCustomizedField, getUserComputerList } from '@/services/computers';

import { useColumnPermissions } from '../useColumnPermissions';
import { useGroupConfig } from '../useGroupConfig';
import { computerListPlaceholder } from './constants';

export function useComputerDataSBA() {
  const queryClient = useQueryClient();
  const columnPermissions = useColumnPermissions();

  const computerListQuery = useComputerListSBAQuery({ columnPermissions });
  const computerListData = computerListQuery.data ?? computerListPlaceholder;

  const showOOBE = computeShowOOBE_SBA_MyComputers(computerListQuery);

  const isFetching = computerListQuery.isFetching;

  function refresh() {
    queryClient.removeQueries({ queryKey: computerListSBAQueryKey, exact: true });
    computerListQuery.refetch();
  }

  return { showOOBE, isFetching, computerListData, refresh };
}

export const computerListSBAQueryKey = ['myComputer', 'computerList'];
function useComputerListSBAQuery({ columnPermissions }: { columnPermissions: ColumnPermissions | null }) {
  const { shouldGetGroupName } = useGroupConfig();

  const fields: Array<ComputerListCustomizedField> = useMemo(
    () => [
      'version',
      'host_name',
      'email',
      'deployed',
      'group_id',
      ...checkPermission(shouldGetGroupName, 'group_name' as const),
      'pubip',
      'local_ip',
      ...checkPermission(columnPermissions?.notes, 'note' as const),
      'online_status',
      'is_device_owner',
      'connected',
      'from_external_shared',
    ],
    [columnPermissions, shouldGetGroupName],
  );

  useRegisterComputerDataFields(fields);

  return useQuery({
    queryKey: computerListSBAQueryKey,
    queryFn: async () => getUserComputerList({ fields }),
    staleTime: Infinity,
    enabled: columnPermissions !== null,
  });
}
