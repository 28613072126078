import { Fragment } from 'react';

import { Box, Center } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { RippleSkeleton } from '@/design';
import { SubmitButton } from '@/modules/ServiceDesk/SurveyForm/Editor/FormEditor/ViewMode/ActionButtons';
import { CheckboxField } from '@/modules/ServiceDesk/SurveyForm/Editor/FormEditor/ViewMode/CheckboxField';
import { ComboBoxField } from '@/modules/ServiceDesk/SurveyForm/Editor/FormEditor/ViewMode/ComboBoxField';
import { Header } from '@/modules/ServiceDesk/SurveyForm/Editor/FormEditor/ViewMode/Header';
import { LongTextField } from '@/modules/ServiceDesk/SurveyForm/Editor/FormEditor/ViewMode/LongTextField';
import { RadioButtonField } from '@/modules/ServiceDesk/SurveyForm/Editor/FormEditor/ViewMode/RadioButtonField';
import { ShortTextField } from '@/modules/ServiceDesk/SurveyForm/Editor/FormEditor/ViewMode/ShortTextField';
import { SurveyFormScenario, SurveyFormValue } from '@/modules/ServiceDesk/SurveyForm/Editor/types';
import { SubmitSurveyPayload } from '@/services/serviceDesk/surveys/submitSurvey';
import { QuestionnaireFieldKindMap } from '@/services/serviceDesk/surveys/type';
import { ViewSurvey } from '@/services/serviceDesk/surveys/viewSurvey';

type FormProps = {
  scenario: SurveyFormScenario;
  formFields: ViewSurvey | SurveyFormValue;
  brandImageUrl: ViewSurvey['brand_image_url'];
  onSubmit: () => void;
  isSubmittingForm?: boolean;
};

export const Form = ({ scenario, formFields, brandImageUrl, onSubmit, isSubmittingForm = false }: FormProps) => {
  const form = useFormContext<SubmitSurveyPayload>();

  const {
    register,
    formState: { errors },
  } = form;

  const isFieldError = (index: number) => Boolean(errors.questionnaire?.[index]?.answer);

  const { questionnaire } = formFields;

  return (
    <Fragment>
      <Header
        scenario={scenario}
        welcomeTitle={formFields.welcome_message.title}
        welcomeSubtitle={formFields.welcome_message.subtitle}
        brandImage={formFields.brand_image}
        brandImageUrl={brandImageUrl}
      />
      {questionnaire.map((field, index) => {
        switch (field.kind) {
          case QuestionnaireFieldKindMap.shortText:
            return (
              <Fragment key={field.order}>
                <ShortTextField
                  {...register(`questionnaire.${index}.answer`, { required: field.required })}
                  scenario={scenario}
                  labelValue={field.question}
                  placeholder={field.answer_placeholder}
                  isRequired={field.required}
                  isInvalid={isFieldError(index)}
                />
              </Fragment>
            );
          case QuestionnaireFieldKindMap.longText:
            return (
              <Fragment key={field.order}>
                <LongTextField
                  {...register(`questionnaire.${index}.answer`, { required: field.required })}
                  scenario={scenario}
                  labelValue={field.question}
                  placeholder={field.answer_placeholder}
                  isRequired={field.required}
                  isInvalid={isFieldError(index)}
                />
              </Fragment>
            );
          case QuestionnaireFieldKindMap.comboBox:
            return (
              <Fragment key={field.order}>
                <ComboBoxField
                  registerFieldName={`questionnaire.${index}.answer`}
                  defaultValue={field.default_option}
                  options={field.options}
                  scenario={scenario}
                  labelValue={field.question}
                  isRequired={field.required}
                  isInvalid={isFieldError(index)}
                />
              </Fragment>
            );
          case QuestionnaireFieldKindMap.radioButton:
            return (
              <Fragment key={field.order}>
                <RadioButtonField
                  registerFieldName={`questionnaire.${index}.answer`}
                  defaultValue={field.default_option}
                  options={field.options}
                  scenario={scenario}
                  labelValue={field.question}
                  isRequired={field.required}
                  isInvalid={isFieldError(index)}
                />
              </Fragment>
            );
          case QuestionnaireFieldKindMap.checkbox:
            return (
              <Fragment key={field.order}>
                <CheckboxField
                  registerFieldName={`questionnaire.${index}.answer`}
                  defaultValue={field.default_option}
                  options={field.options}
                  scenario={scenario}
                  labelValue={field.question}
                  isRequired={field.required}
                  isInvalid={isFieldError(index)}
                />
              </Fragment>
            );
          default:
            return null;
        }
      })}
      <Center py="16px">
        <SubmitButton label={formFields.action_button_label || ''} onClick={onSubmit} isLoading={isSubmittingForm} />
      </Center>
    </Fragment>
  );
};

export const SkeletonLoader = () => {
  return (
    <Box w="100%">
      {Array(5)
        .fill(null)
        .map((_, index) => {
          return (
            <Box key={index} px="36px">
              <RippleSkeleton w="136px" maxW="100%" h="18px" mb="12px" />
              <RippleSkeleton w="100%" h="40px" mb="40px" />
            </Box>
          );
        })}
    </Box>
  );
};
