import { atom } from 'jotai';

import { Group } from '@/services/group/types';

import { ComputerDataForPolicy } from '../hooks';

export const assignPolicyResultAtom = atom<{
  groups: {
    success: Array<Pick<Group, 'id' | 'name'>>;
    fail: Array<Pick<Group, 'id' | 'name'>>;
  };
  computers: {
    success: Array<ComputerDataForPolicy>;
    fail: Array<ComputerDataForPolicy>;
  };
}>({
  groups: {
    success: [],
    fail: [],
  },
  computers: {
    success: [],
    fail: [],
  },
});
