import { useEffect, useRef } from 'react';

import { useFormContext } from 'react-hook-form';

import { useCurrentOpenFieldAtom } from '@/modules/ServiceDesk/SupportForm/Editor/hooks/useEditingForm';
import { SupportFormValue } from '@/modules/ServiceDesk/SupportForm/Editor/types';

export const useScrollToLastField = () => {
  const labelRef = useRef<HTMLInputElement | null>(null);
  const { watch } = useFormContext<SupportFormValue>();

  const currentOpenField = useCurrentOpenFieldAtom();

  const questionnaire = watch('questionnaire');

  useEffect(() => {
    const isLastField = currentOpenField?.questionIndex === questionnaire.length - 1;
    if (labelRef.current && isLastField) {
      labelRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [currentOpenField?.questionIndex, questionnaire.length]);

  return { labelRef };
};
