import { useMemo } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { chunk, flatten } from 'lodash';

import { useTeamId } from '@/models/TeamInformation';
import { useColumnPermissions } from '@/modules/Computer/AllComputers/useColumnPermissions';
import { useRegisterComputerDataFields } from '@/modules/Computer/ComputerList/hooks';
import { ColumnPermissions } from '@/modules/Computer/ComputerList/types';
import { checkPermission } from '@/modules/Computer/ComputerList/utils';
import { computeShowOOBE } from '@/modules/Computer/utils';
import { ComputerListCustomizedField, getComputerListById } from '@/services/computers';
import { CHUNK_SIZE } from '@/utils/constants';

import { computerIdListPlaceholder, computerListPlaceholder } from './constants';
import { useComputerIdListQuery, useComputerIdListQueryKey } from './useComputerDataSRS';

export function useComputerDataSBA() {
  const queryClient = useQueryClient();
  const columnPermissions = useColumnPermissions();
  const computerIdListQuery = useComputerIdListQuery();
  const computerIdListQueryKey = useComputerIdListQueryKey();

  const showOOBE = computeShowOOBE(computerIdListQuery);

  const computerListQuery = useComputerListQuerySBA({ columnPermissions });
  const computerListData = computerListQuery.data ?? computerListPlaceholder;

  const isFetching = computerIdListQuery.isFetching || computerListQuery.isFetching;

  function refresh() {
    queryClient.removeQueries({ queryKey: computerIdListQueryKey, exact: true });
    computerIdListQuery.refetch();

    queryClient.removeQueries({ queryKey: computerListQuerySBAKey, exact: true });
    computerListQuery.refetch();
  }

  return { showOOBE, computerListData, isFetching, refresh };
}

export const computerListQuerySBAKey = ['allComputers', 'computerList'];
function useComputerListQuerySBA({ columnPermissions }: { columnPermissions: ColumnPermissions | null }) {
  const teamId = useTeamId();

  const computerIdListQuery = useComputerIdListQuery();
  const computerIdList = computerIdListQuery.data ?? computerIdListPlaceholder;

  const fields = useMemo<Array<ComputerListCustomizedField>>(
    () => [
      'group_id',
      'email',
      'deployed',
      'share_mode',
      'online_status',
      'connected',
      'version',
      'os',
      'os_product',
      'os_build',
      'os_build_rev',
      'architecture',
      'pubip',
      'local_ip',
      ...checkPermission(columnPermissions?.notes, 'note' as const),
      'host_name',
      'last_online',
      'last_session',
      ...checkPermission(columnPermissions?.windowsUpdates, 'windows_updates' as const),
      ...checkPermission(columnPermissions?.windowsUpdates, 'support_windows_updates' as const),
      ...checkPermission(columnPermissions?.emmPolicy, 'support_emm_policy' as const),
      ...checkPermission(columnPermissions?.preferencePolicy, 'preference_policy' as const),
      ...checkPermission(columnPermissions?.antivirusStatus, 'antivirus_scan_status' as const),
      ...checkPermission(columnPermissions?.antivirusStatus, 'support_antivirus' as const),
      ...checkPermission(columnPermissions?.alertCount, 'alerts_count' as const),
      ...checkPermission(columnPermissions?.securityLayer, 'infra_gen_status' as const),
      'is_device_owner',
      'from_external_shared',
    ],
    [columnPermissions],
  );

  useRegisterComputerDataFields(fields);

  return useQuery({
    queryKey: computerListQuerySBAKey,
    queryFn: async () => {
      if (computerIdList.length === 0) {
        return [];
      }

      const idListChunks = chunk(computerIdList, CHUNK_SIZE);
      const requestList = idListChunks.map((idList) => {
        return getComputerListById(teamId, idList, 'customize', fields);
      });

      const result = await Promise.all(requestList);

      return flatten(result);
    },
    staleTime: 60000,
    enabled: !computerIdListQuery.isFetching && columnPermissions !== null,
  });
}
