import { useQuery } from '@tanstack/react-query';

import { useTeamRole } from '@/models/TeamInformation';
import { infoQueryService } from '@/services/users';

/**
 * Hook to check if the user has access to the team settings page.
 *
 * Only the owner and super admin can access the team settings page.
 */
export function useTeamSettingsAccess() {
  const { isOwner, isAdmin, isGroupAdmin, isMember } = useTeamRole();

  const query = useQuery({
    queryKey: ['info_query', 'team settings'],
    queryFn: () => infoQueryService.execute({ team_member_permissions: ['team_settings_management'] }),
    enabled: isAdmin,
    select: (data) => {
      const teamKindDataList = Object.values(data);

      return {
        canAccess: teamKindDataList.some(({ team_member_permissions }) => {
          return team_member_permissions.team_settings_management;
        }),
      };
    },
  });

  if (isOwner)
    return {
      isFetched: true,
      canAccess: true,
    };

  if (isMember || isGroupAdmin)
    return {
      isFetched: true,
      canAccess: false,
    };

  return {
    isFetched: query.isFetched,
    canAccess: isAdmin && Boolean(query.data?.canAccess),
  };
}
