import { HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { RippleButton } from '@/design';

type FooterProps = {
  isLoading?: boolean;
  onClose: () => void;
  onAssign: () => void;
};
export const Footer = ({ isLoading, onClose, onAssign }: FooterProps) => {
  const { t } = useTranslation();
  return (
    <HStack gap="12px" justifyContent="flex-end">
      <RippleButton variant="grayScaleGhost" size="sm" onClick={onClose}>
        {t('common:cancel')}
      </RippleButton>
      <RippleButton variant="primary" size="sm" onClick={onAssign} isLoading={isLoading}>
        {t('common:assign')}
      </RippleButton>
    </HStack>
  );
};
