/**
 * User Verify Email API
 * @see https://splashtop.atlassian.net/wiki/spaces/CS/pages/1202061652/User+Verify+Email+API
 */
import axios from 'axios';
import { z } from 'zod';

import { featureControl } from '@/feature/toggle';
import { checkResponse, createGenericErrorHandlers } from '@/services/utils';

import { commonResponseSchema } from '../schemas';

const METHOD = 'POST';

const getUrl = () => `/api/web/v1/users/verify_email`;

/**
 * NOTE: "premium_tool" is depend on the feature toggle "PCP_2742__ComputerList_backgroundActions_verifyEmail"
 */
const reasonEnum = featureControl.getToggle('PCP_2742__ComputerList_backgroundActions_verifyEmail')
  ? z.enum(['invite_user', '2sv', 'sos_package', 'connect_with_web_client', 'premium_tool'])
  : z.enum(['invite_user', '2sv', 'sos_package', 'connect_with_web_client']);

const payloadSchema = z.object({
  requested_from: reasonEnum,
});

const responseSchema = z.object({});

const errorHandling = {
  ...createGenericErrorHandlers(),
  /**
   * @code 40409
   * - Email verification verified.
   */
  isEmailVerificationVerified: (response: any) => {
    const errorSchema = commonResponseSchema.merge(
      z.object({ result: z.literal(40409), data: z.object({ is_verified: z.boolean(), error: z.literal('email_verified') }) }),
    );

    return errorSchema.safeParse(response).success;
  },
} as const;

const execute = (payload: z.infer<typeof payloadSchema>) =>
  checkResponse(axios({ method: METHOD, url: getUrl(), data: payload }), responseSchema);

export const sendVerificationEmailService = { getUrl, execute, method: METHOD, errorHandling };
