import { AddFieldKindValues } from '@/modules/ServiceDesk/SupportForm/Editor/types';
import { QuestionnaireField, QuestionnaireFieldKind } from '@/services/serviceDesk/supportForm/types';

export const ADD_FIELD_MAP: Record<AddFieldKindValues, Omit<QuestionnaireField, 'order' | 'uuid'>> = {
  [QuestionnaireFieldKind.shortText]: {
    kind: QuestionnaireFieldKind.shortText,
    required: false,
    question: '',
    placeholder: '',
    default_option: '',
    options: null,
  },
  [QuestionnaireFieldKind.longText]: {
    kind: QuestionnaireFieldKind.longText,
    required: false,
    question: '',
    placeholder: '',
    default_option: '',
    options: null,
  },
  [QuestionnaireFieldKind.comboBox]: {
    kind: QuestionnaireFieldKind.comboBox,
    required: false,
    question: '',
    placeholder: '',
    default_option: '',
    options: null,
  },
  [QuestionnaireFieldKind.radioButton]: {
    kind: QuestionnaireFieldKind.radioButton,
    required: false,
    question: '',
    placeholder: '',
    default_option: '',
    options: null,
  },
  [QuestionnaireFieldKind.checkbox]: {
    kind: QuestionnaireFieldKind.checkbox,
    required: false,
    question: '',
    placeholder: '',
    default_option: [],
    options: [],
  },
};

export const FORM_NAME_MAX_LENGTH = 64;
export const LABEL_MAX_LENGTH = 64;
export const PLACEHOLDER_MAX_LENGTH = 64;
export const CUSTOMER_NAME_MAX_LENGTH = 64;
export const INPUT_MAX_LENGTH = 128;
export const TEXTAREA_MAX_LENGTH = 512;

export const COMBO_BOX_OPTION_MAX_LENGTH = 30;
export const SUBMIT_BUTTON_MAX_LENGTH = 20;

export const SUPPORT_FORM_CONTENT_WIDTH = '600px';

const CUSTOMER_NAME_COUNT = 1;
const CUSTOMER_ISSUE_COUNT = 1;
const MAX_CUSTOM_FIELD_COUNT = 8;
export const MAX_QUESTIONNAIRE_COUNT = CUSTOMER_NAME_COUNT + CUSTOMER_ISSUE_COUNT + MAX_CUSTOM_FIELD_COUNT;

export const NO_DEFAULT_OPTION = '';

export const MAX_OPTION_COUNT = 11;
export const OPTION_MAX_LENGTH = 30;
