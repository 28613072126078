import { useCurrentFormNameList } from '@/modules/ServiceDesk/SupportForm/Editor/hooks/useEditingForm';
import { SupportFormMode } from '@/modules/ServiceDesk/SupportForm/Editor/types';

type UseIsDuplicateSupportFormName = { currentSupportFormId: number; mode: SupportFormMode; supportFormName: string };

export const useIsDuplicateSupportFormName = ({ currentSupportFormId, mode, supportFormName }: UseIsDuplicateSupportFormName) => {
  const currentFormNameList = useCurrentFormNameList();

  if (mode === 'edit') {
    // Avoid validating current form itself
    return currentFormNameList
      .filter((formInfo) => formInfo.formId !== currentSupportFormId)
      .map((formInfo) => formInfo.name.toLocaleLowerCase())
      .includes(supportFormName.toLocaleLowerCase());
  }
  return currentFormNameList.map((formInfo) => formInfo.name.toLocaleLowerCase()).includes(supportFormName.toLocaleLowerCase());
};
