import { useEffect } from 'react';

import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';

import { useRippleFlashMessage } from '@/design';
import { assignSurvey, assignSurveyErrorSchema } from '@/services/serviceDesk/surveys/assignSurvey';

export const useAssignSurveyMutation = () => {
  const { t } = useTranslation();
  const { flashMessage } = useRippleFlashMessage();

  const assignSurveyMutation = useMutation({
    mutationFn: assignSurvey,
  });

  const { isError, error } = assignSurveyMutation;

  useEffect(
    function handleError() {
      if (isError) {
        const errorValidation = assignSurveyErrorSchema.safeParse(error);
        if (!errorValidation.success) {
          console.error('Error status code is not valid', errorValidation.error);
          return;
        }
        switch (errorValidation.data.result) {
          // 40422 when parameter is error
          case 40422: {
            flashMessage({ variant: 'error', title: t('survey:please_contact_splashtop_support_team') });
            break;
          }
          // 40403 when the user is not in a valid team
          // 41403 when the user is not a admins
          case 40403:
          case 41403: {
            flashMessage({ variant: 'error', title: t('survey:unable_to_access_the_survey') });
            break;
          }
          // 40404 when the survey does not exist
          case 40404: {
            flashMessage({ variant: 'error', title: t('survey:survey_does_not_exist') });
            break;
          }
          default: {
            const exhaustiveCheck: never = errorValidation.data.result;
            // @ts-ignore-next-line
            throw new Error(`Unhandled error case: ${exhaustiveCheck.data.result}`);
          }
        }
      }
    },
    [error, flashMessage, isError, t],
  );

  return assignSurveyMutation;
};
