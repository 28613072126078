import React from 'react';

import { Box, useControllableState, useDisclosure } from '@chakra-ui/react';
import type { BoxProps, PopoverProps } from '@chakra-ui/react';
import { hslToColorString, parseToRgb, rgbToColorString } from 'polished';
import { SketchPicker } from 'react-color';
import type { Color, ColorResult, HSLColor, RGBColor, SketchPickerProps } from 'react-color';

import { RippleArrowDown16, RippleArrowUp16, RipplePopover, RipplePopoverContent, RipplePopoverTrigger } from '@/design';

export type ColorPickerProps = Omit<SketchPickerProps, 'onChange' | 'color'> &
  (
    | {
        mode: 'hex';
        value: string;
        onChange: (value: string) => void;
      }
    | { mode: 'rgb'; value: RGBColor; onChange: (value: RGBColor) => void }
    | { mode: 'hsl'; value: HSLColor; onChange: (value: HSLColor) => void }
  ) & { sx?: BoxProps['sx']; 'data-testid'?: string; onOpen?: () => void; onClose?: () => void; popoverStyle?: PopoverProps };

export function ColorPicker({
  value,
  onChange,
  mode,
  disableAlpha = false,
  sx,
  'data-testid': testId,
  onOpen,
  onClose,
  popoverStyle,
  ...otherProps
}: ColorPickerProps): React.JSX.Element {
  const [previewColorHex, setPreviewColorHex] = useControllableState<string>({ value: computePreviewColorHex() });

  const popoverState = useDisclosure({ onOpen, onClose });

  return (
    <Box className="ColorPicker__container" sx={sx}>
      <RipplePopover placement="bottom-start" {...popoverStyle} {...popoverState}>
        {({ isOpen }) => (
          <>
            <RipplePopoverTrigger>
              <Box
                className="ColorPicker__trigger"
                display="inline-flex"
                alignItems="center"
                height="40px"
                padding="8px"
                border="1px solid"
                borderColor="neutral.300"
                borderRadius="4px"
                backgroundColor={isOpen ? 'blue.0' : 'white'}
                _hover={{
                  backgroundColor: 'blue.0',
                }}
                _disabled={{
                  backgroundColor: 'neutral.10',
                  borderColor: 'dark.40',
                }}
                transition="0.1s ease-in-out all"
                cursor="pointer"
                userSelect="none"
              >
                <PreviewColorBox className="ColorPicker__preview" marginRight="8px" previewColor={previewColorHex} />
                {isOpen ? <RippleArrowDown16 color="neutral.300" /> : <RippleArrowUp16 color="neutral.300" />}
              </Box>
            </RipplePopoverTrigger>

            <RipplePopoverContent w="100%" userSelect="none">
              <SketchPicker
                className="ColorPicker__picker"
                width="270px"
                color={computeColor()}
                onChange={handleChange}
                disableAlpha={disableAlpha}
                {...otherProps}
              />
            </RipplePopoverContent>
          </>
        )}
      </RipplePopover>
    </Box>
  );

  function computePreviewColorHex(): string {
    switch (mode) {
      case 'hex': {
        return value;
      }
      case 'rgb': {
        return rgbToColorString({
          red: value.r,
          green: value.g,
          blue: value.b,
          alpha: value.a,
        });
      }
      case 'hsl': {
        return hslToColorString({
          hue: value.h,
          saturation: value.s,
          lightness: value.l,
          alpha: value.a,
        });
      }
    }
  }

  function computeColor(): Color {
    switch (mode) {
      case 'hex': {
        const rgb = parseToRgb(value);
        if ('alpha' in rgb)
          return {
            r: rgb.red,
            g: rgb.green,
            b: rgb.blue,
            a: rgb.alpha,
          };
        return {
          r: rgb.red,
          g: rgb.green,
          b: rgb.blue,
        };
      }
      case 'rgb':
      case 'hsl': {
        return value;
      }
    }
  }

  function handleChange(result: ColorResult): void {
    const alphaHex = alphaToHex(result.rgb.a ?? 1);
    const rgbHex = result.hex === 'transparent' ? '#000000' : result.hex;
    const rgbaHex = `${rgbHex}${alphaHex}`;
    setPreviewColorHex(rgbaHex);

    switch (mode) {
      case 'hex': {
        onChange(disableAlpha ? result.hex : rgbaHex);
        break;
      }
      case 'rgb': {
        onChange(result.rgb);
        break;
      }
      case 'hsl': {
        onChange(result.hsl);
        break;
      }
    }
  }
}

type PreviewColorBoxProps = BoxProps & { previewColor: string };
function PreviewColorBox({ previewColor, ...otherProps }: PreviewColorBoxProps): React.JSX.Element {
  return (
    <Box
      width="24px"
      height="24px"
      border="1px solid"
      borderColor="neutral.60"
      borderRadius="2px"
      backgroundColor={previewColor}
      {...otherProps}
    />
  );
}

function alphaToHex(alpha: number): string {
  return Math.round(alpha * 255)
    .toString(16)
    .padStart(2, '0');
}
