import { useTranslation } from 'next-i18next';

import {
  RippleButton,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalProps,
  RippleModalTitle,
} from '@/design';

export type ErrorModalProps = {
  isOpen: boolean;
  message: string;
  onConfirm: () => void;
  size?: RippleModalProps['size'];
};

export const ErrorModal = ({ isOpen, message, onConfirm, size = 'xl' }: ErrorModalProps) => {
  const { t } = useTranslation();
  return (
    <RippleModal isOpen={isOpen} onClose={onConfirm} size={size}>
      <RippleModalOverlay />
      <RippleModalContent>
        <RippleModalHeader>
          <RippleModalTitle>{t('support-forms:errorOccurred')}</RippleModalTitle>
        </RippleModalHeader>
        <RippleModalBody>{message}</RippleModalBody>
        <RippleModalFooter>
          <RippleButton onClick={onConfirm}>{t('common:ok')}</RippleButton>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
};
