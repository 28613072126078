import { parameterControl } from '@/feature/toggle';
import { ResponseData } from '@/services/common/types';
import { isSuccess, pureInstance } from '@/services/utils';

export type RetrieveSupportSessionLogsParams = {
  support_session_id: number;
  end_time?: string;
  start_time?: string;
  max_number?: number;
  order?: 'asc' | 'desc';
};

export type SupportSessionLogs = Array<{
  support_session_log_id: string;
  time: string;
  event_id: number;
  log: string;
}>;

type RetrieveSupportSessionLogsResponse = {
  more_logs: false;
  support_session_logs: SupportSessionLogs;
};

export function retrieveSupportSessionLogs(token: string, params: RetrieveSupportSessionLogsParams) {
  const queryString = (Object.keys(params) as Array<keyof typeof params>)
    .filter((key) => Boolean(params[key]))
    .map((key) => `${key}=${params[key] ?? ''}`)
    .join('&');

  return new Promise<RetrieveSupportSessionLogsResponse>((resolve, reject) => {
    pureInstance
      .get<ResponseData & { data: RetrieveSupportSessionLogsResponse }>(
        `${parameterControl.getParameter('logServiceUrl')}/api/v1/support_session_log?${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}
