import { PLATFORMS, Policy } from '@/services/teams/emm_policies';
import { getOSType } from '@/utils/computers';

import { PolicyAssignmentList } from './hooks';
import { PolicyInheritanceNode, PolicyWithInheritedDepth } from './types';

/**
 * Create prepared default policy for each platform, only used when there is no policy in the list
 */
export const createPreparedDefaultPolicy = (platform: Policy['platform']): Policy => {
  switch (platform) {
    case 'Windows': {
      return {
        id: -1,
        active: false,
        name: 'Windows Default Policy',
        description: '',
        platform: PLATFORMS.Windows,
        updated_at: '',
        parent_id: null,
        policy_kind: 'policy',
        super_root: true,
      };
    }
    case 'macOS': {
      return {
        id: -2,
        active: false,
        name: 'Mac Default Policy',
        description: '',
        platform: PLATFORMS.macOS,
        updated_at: '',
        parent_id: null,
        policy_kind: 'policy',
        super_root: true,
      };
    }
    case 'Android': {
      return {
        id: -3,
        active: false,
        name: 'Android Default Policy',
        description: '',
        platform: PLATFORMS.Android,
        updated_at: '',
        parent_id: null,
        policy_kind: 'policy',
        super_root: true,
      };
    }
    default: {
      const x: never = platform;
      throw new Error(`Unknown platform: ${String(x)}`);
    }
  }
};

/**
 * Check if the policy is a prepared default policy
 */
export const isPreparedDefaultPolicy = (policyId: Policy['id']): boolean => policyId < 0;

/** Get policy platform from streamer version */
export const getPlatformByOs = (version: string | null | undefined) => {
  const { type } = getOSType(version);
  switch (type) {
    case 'win':
      return 'Windows';
    case 'mac':
      return 'macOS';
    case 'android':
      return 'Android';
    default:
      return '';
  }
};

/** Get display platform string from policy platform */
export const getPlatformString = (platform: string) => {
  switch (platform) {
    case 'Windows': {
      return 'Windows';
    }
    case 'macOS': {
      return 'Mac';
    }
    case 'Android': {
      return 'Android';
    }
    default: {
      return '';
    }
  }
};

/** Create an array of inheritance tree */
export const createPolicyInheritanceTrees = (policies: Array<Policy> | undefined): Array<PolicyInheritanceNode> => {
  if (!policies) return [];
  const nodes: Array<PolicyInheritanceNode> = policies?.map((policy) => ({
    policy,
    children: [],
  }));
  // Map policy ID to array index
  const policyIndexMap = policies?.reduce((map, policy, index) => {
    const id = policy.id;
    map.set(id, index);
    return map;
  }, new Map());
  const roots: Array<PolicyInheritanceNode> = [];

  // Push each element to parent's children array
  nodes.forEach((node) => {
    if (node.policy.parent_id === null) {
      roots.push(node);
      return;
    }
    if (typeof policyIndexMap.get(node.policy.parent_id) === 'undefined') {
      roots.push(node);
      return;
    }
    nodes[policyIndexMap.get(node.policy.parent_id)].children.push(node);
  });
  return roots;
};

/** get pre-order traversal of the trees */
export const getPreOrderDfs = (trees: Array<PolicyInheritanceNode>) => {
  const preOrderPolicies: Array<PolicyWithInheritedDepth> = [];
  trees.forEach((tree) => preOrderDfs(tree, (node, depth) => preOrderPolicies.push({ ...node, depth })));
  return preOrderPolicies;
};

/** Depth-first search (DFS) traversal in pre-order */
const preOrderDfs = (node: PolicyInheritanceNode, callback: (node: PolicyInheritanceNode, depth: number) => void, depth = 0) => {
  callback(node, depth);
  node.children.forEach((child) => preOrderDfs(child, callback, depth + 1));
};

/**
 * Create default policy map for each platform, only used when there is no policy in the list
 */
export const createDefaultPolicyMap = (): Record<Policy['platform'], PolicyAssignmentList> =>
  Object.keys(PLATFORMS).reduce(
    (map, platform) => {
      map[platform as keyof typeof PLATFORMS] = [];
      return map;
    },
    {} as Record<Policy['platform'], PolicyAssignmentList>,
  );
