import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { getMessageDetailsService } from '@/services/messages/getMessageDetailsService';

import { viewedMessagesAtom } from '../atoms';

export const useTutorialMessageQuery = ({ enabled = true }: { enabled: boolean }) => {
  const [viewedMessages] = useAtom(viewedMessagesAtom);
  // The tutorial message is the one with the lowest ID
  const tutorialMessageID = Math.min(...viewedMessages);
  const { i18n } = useTranslation();
  return useQuery({
    queryKey: ['policy', 'tutorial-message'],
    queryFn: () => getMessageDetailsService.execute({ messageID: String(tutorialMessageID), language: i18n.language }),
    staleTime: 10 * 60_000, // 10 minutes
    enabled: enabled && viewedMessages.length > 0,
  });
};
