import axios from 'axios';

import { checkResponse } from '@/services/utils';

import { ChannelId } from '../type';
import { AssignSurvey, assignSurveySchema } from './types';

type AssignSurveyParams = { teamId: number; surveyId: number; addChannels: Array<ChannelId>; removeChannels: Array<ChannelId> };

export const assignSurvey = async ({ teamId, surveyId, addChannels, removeChannels }: AssignSurveyParams) => {
  return await checkResponse<AssignSurvey>(
    axios.put(`/api/web/v1/teams/${teamId}/service_desk/surveys/${surveyId}/assign`, {
      add_channels: addChannels,
      remove_channels: removeChannels,
    }),
    assignSurveySchema,
  );
};
