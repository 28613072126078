import axios from 'axios';

import { ResponseData } from '@/services/common/types';
import { isSuccess, pureInstance } from '@/services/utils';
import { Region } from '@/utils/region';

import { SupportSessionCategoryTypeValues } from '../supportSession';

export const SOS_CALL_CARD_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
} as const;
export type SosCallCardStatusValues = (typeof SOS_CALL_CARD_STATUS)[keyof typeof SOS_CALL_CARD_STATUS];

type SosCallCard = {
  id: number;
  name: string;
  application_name: string | null;
  status: SosCallCardStatusValues;
  default_assignee_id: number;
  default_assignee: string | null;
  created_by: string;
  created_at: string;
  support_portal_link: string;
};

export type GetSosCallListResponse = {
  cards: Array<SosCallCard>;
};

export function getSosCallListAPI(teamId: number, channelId: number) {
  return new Promise<GetSosCallListResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: GetSosCallListResponse }>(`/api/web/v1/teams/${teamId}/service_desk/channels/${channelId}/card_list`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export const UNASSIGNED_ID = 0;

type CreateSosCallParams = {
  name: string;
  application_name: string;
  /** `0` means unassigned */
  default_assignee_id: number;
  status: SosCallCardStatusValues;
};

export type CreateSosCallResponse = SosCallCard;

export function createSosCallAPI(teamId: number, channelId: number, params: CreateSosCallParams) {
  return new Promise<CreateSosCallResponse>((resolve, reject) => {
    axios
      .post<ResponseData & { data: CreateSosCallResponse }>(`/api/web/v1/teams/${teamId}/service_desk/channels/${channelId}/cards`, params)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

type DeleteSosCallResponse = Record<string, unknown>;

export function deleteSosCallAPI(teamId: number, cardId: string) {
  return new Promise<DeleteSosCallResponse>((resolve, reject) => {
    axios
      .delete<ResponseData & { data: DeleteSosCallResponse }>(`/api/web/v1/teams/${teamId}/service_desk/cards/${cardId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

type UpdateSosCallParams = Partial<CreateSosCallParams>;
type UpdateSosCallResponse = Record<string, unknown>;

export function updateSosCallAPI(teamId: number, cardId: string, params: UpdateSosCallParams) {
  return new Promise<UpdateSosCallResponse>((resolve, reject) => {
    axios
      .put<ResponseData & { data: UpdateSosCallResponse }>(`/api/web/v1/teams/${teamId}/service_desk/cards/${cardId}`, params)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export type SosCallCloudBuildURLResponse = {
  can_build: boolean;
  application_name: string | null;
  cache_url: string;
  cache_ok_url: string;
  backup_url: string;
};

export type SosCallLaunchURLDetailResponse = {
  code: string;
  category: SupportSessionCategoryTypeValues;
  team_code: string;
  region: Region;
};

export function getSosCallCloudBuildURL(packageCode: string, category: 'win' | 'mac') {
  return new Promise<SosCallCloudBuildURLResponse>((resolve, reject) => {
    pureInstance
      .post<ResponseData & { data: SosCallCloudBuildURLResponse }>(`/api/web/v1/teams/service_desk/call/${packageCode}/cloud_build_file`, {
        category,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getSosCallLaunchURLDetail(packageCode: string) {
  return new Promise<SosCallLaunchURLDetailResponse>((resolve, reject) => {
    pureInstance
      .get<ResponseData & { data: SosCallLaunchURLDetailResponse }>(`/api/web/v1/teams/service_desk/call/${packageCode}/detail`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}
