import { ConfirmModal } from './ConfirmModal';
import { SelectKindModal, useSelectKindModal } from './SelectKindModal';

export function useRebootComputer() {
  const renameComputerModal = useSelectKindModal();

  return {
    execute: renameComputerModal.open,
  };
}

export function RebootComputer(): React.JSX.Element {
  return (
    <>
      <SelectKindModal />
      <ConfirmModal />
    </>
  );
}
