import { forwardRef } from '@chakra-ui/react';
import { SortingColumn } from '@tanstack/react-table';

import { RippleHeading09, RippleInlineAscending, RippleInlineDescending, RippleTdLegacyProps, RippleThLegacy } from '@/design';

type SortState = ReturnType<SortingColumn<unknown>['getIsSorted']>;
type TableHeadProps = RippleTdLegacyProps & { isSortable?: boolean; sortState?: SortState };
export const TableHead = forwardRef<TableHeadProps, 'div'>(({ children, sortState, isSortable, onClick, ...otherProps }, ref) => {
  return (
    <RippleThLegacy p="11px 0 11px 6px" cursor={isSortable ? 'pointer' : 'initial'} onClick={onClick} ref={ref} {...otherProps}>
      <RippleHeading09 color="neutral.300">{children}</RippleHeading09>
      {sortState === 'desc' && <RippleInlineDescending color="blue.200" />}
      {sortState === 'asc' && <RippleInlineAscending color="blue.200" />}
    </RippleThLegacy>
  );
});
