import z from 'zod';

export const PLATFORMS = {
  Windows: 'Windows',
  macOS: 'macOS',
  Android: 'Android',
} as const;
/**
 * EMM Policy platform enum
 */
export const policyPlatformEnum = z.enum([PLATFORMS.Windows, PLATFORMS.macOS, PLATFORMS.Android]);
export type Platform = z.infer<typeof policyPlatformEnum>;

/**
 * EMM Policy kind enum
 * @define policy - primitive policy
 * @define server - customized policy for streamer, can not be inherited
 * @define group - customized policy for group, can be inherited
 */
export const policyKindEnum = z.enum(['policy', 'server', 'group']);

/** Policy categories */
export const categoryEnum = z.enum(['streamer', 'patch', 'os']);
export type Category = z.infer<typeof categoryEnum>;

export const policySchema = z.object({
  id: z.number(),
  active: z.boolean(),
  name: z.string(),
  description: z.string().nullable(),
  platform: policyPlatformEnum,
  /**
   * UTC date string in ISO format (YYYY-MM-DD HH:mm:ss)
   */
  updated_at: z.string(), // "YYYY-MM-DD HH:mm:ss"
  /**
   * Parent policy id, null for root policy
   */
  parent_id: z.number().nullable(),
  policy_kind: policyKindEnum,
  /**
   * Super root policy can not be deleted
   */
  super_root: z.boolean(),
});
export type Policy = z.infer<typeof policySchema>;

/**
 * EMM Policy - Category related types
 */
export const createPolicyItemSchema = <T extends z.ZodTypeAny, Code extends string>(code: Code, valueSchema: T) =>
  z.object({
    id: z.number().optional(),
    code: z.literal(code),
    operator: z.union([z.literal('+'), z.literal('-')]),
    value: valueSchema,
  });
