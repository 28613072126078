import { useAtomValue } from 'jotai';

import { settingControlAtom } from '@/modules/TeamSettings/atoms';
import { SettingControlContextValue } from '@/modules/TeamSettings/types';

/**
 * Get the setting control context value.
 */
export function useSettingControlContext(): SettingControlContextValue {
  return useAtomValue(settingControlAtom);
}
