import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { shouldShowPreferencePolicy_new } from '@/components/Navigation/routeConfig/utils';
import { useEmmPolicyAccess } from '@/hooks';
import { useTeamRole } from '@/models/TeamInformation';
import { useTeamInformation } from '@/models/TeamInformation';
import type { ComputerListColumnPermissions } from '@/services/computers';
import { type TeamKindResult, infoQueryService } from '@/services/users';

import type { ColumnPermissions } from '../ComputerList';
import { canSeeGroup_new, canSeeNotes_new } from '../ComputerList';

export function useColumnPermissions(): ColumnPermissions | null {
  const { teamKind } = useTeamInformation();
  const { isManager } = useTeamRole();

  const permissionQuery = useQuery({
    queryKey: ['allComputerList', 'getColumnPermissions'],
    queryFn: async () => {
      const params = {
        team_permissions: [
          'display_group_to_member',
          'member_fetch_computer_notes',
          'manage_infra_gen',
          'web_src',
          'remote_reboot',
          'member_reboot_computer',
          'preference_policy',
        ],
        team_member_permissions: ['alert', 'os_opportunities', 'antivirus', 'endpoint_security'],
      } as const;

      const result = await infoQueryService.execute(params);

      const [, unattendedTeamKindData] =
        Object.entries<TeamKindResult<typeof params>>(result).find(([teamKind]) => teamKind !== 'sos') ?? [];

      if (unattendedTeamKindData) return unattendedTeamKindData;

      return Promise.reject(new Error('No data found'));
    },
    staleTime: Infinity,
  });

  const permissionMap = permissionQuery.data;

  const hasSeeGroupPermission = canSeeGroup_new(isManager, permissionMap);
  const hasSeePreferencePolicyPermission =
    shouldShowPreferencePolicy_new(teamKind, isManager) && Boolean(permissionMap?.team_permissions?.preference_policy);
  const hasSeeAlertCountPermission = Boolean(permissionMap?.team_member_permissions?.alert);
  const hasSeeWindowsUpdatesPermission = Boolean(permissionMap?.team_member_permissions?.os_opportunities);
  const hasSeeAntivirusStatusPermission = Boolean(
    permissionMap?.team_member_permissions?.antivirus || permissionMap?.team_member_permissions?.endpoint_security,
  );
  const hasSeeSecurityLayerPermission = canShowSecurityLayer(isManager, permissionMap);
  const hasSeeNotesPermission = canSeeNotes_new(isManager, permissionMap);
  const hasWebAppPermission = Boolean(permissionMap?.team_permissions?.web_src);
  const hasRemoteRebootPermission = Boolean(permissionMap?.team_permissions?.remote_reboot);
  const hasMemberRebootComputerPermission = Boolean(permissionMap?.team_permissions?.member_reboot_computer);
  const { policyAccessQuery } = useEmmPolicyAccess();
  const hasSeeEmmPolicyPermission = Boolean(policyAccessQuery.data?.canAccess);

  const columnPermission = useMemo<ColumnPermissions>(
    () => ({
      alertCount: hasSeeAlertCountPermission,
      antivirusStatus: hasSeeAntivirusStatusPermission,
      emmPolicy: hasSeeEmmPolicyPermission,
      group: hasSeeGroupPermission,
      memberRebootComputer: hasMemberRebootComputerPermission,
      notes: hasSeeNotesPermission,
      preferencePolicy: hasSeePreferencePolicyPermission,
      remoteReboot: hasRemoteRebootPermission,
      securityLayer: hasSeeSecurityLayerPermission,
      webApp: hasWebAppPermission,
      windowsUpdates: hasSeeWindowsUpdatesPermission,
    }),
    [
      hasMemberRebootComputerPermission,
      hasRemoteRebootPermission,
      hasSeeAlertCountPermission,
      hasSeeAntivirusStatusPermission,
      hasSeeEmmPolicyPermission,
      hasSeeGroupPermission,
      hasSeeNotesPermission,
      hasSeePreferencePolicyPermission,
      hasSeeSecurityLayerPermission,
      hasSeeWindowsUpdatesPermission,
      hasWebAppPermission,
    ],
  );

  if (!permissionQuery.isFetched || !policyAccessQuery.isFetched || typeof permissionMap === 'undefined') return null;

  return columnPermission;
}

/**
 * @param isManager {boolean} Value from `useTeamRole`
 * @param permissionMap {ComputerListColumnPermissions | undefined}
 */
function canShowSecurityLayer(isManager: boolean, permissionMap: ComputerListColumnPermissions | undefined): boolean {
  return Boolean(isManager && permissionMap?.team_permissions?.manage_infra_gen);
}
