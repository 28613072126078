import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';

import { CreateFormResponse, SupportFormSummaryList } from '@/services/serviceDesk/supportForm/types';

export type CurrentOpenFieldAutoFocusType = 'label' | 'input' | 'theme';

type CurrentOpenFieldAtom = {
  uuid: string | 'submit-button';
  autoFocusType: CurrentOpenFieldAutoFocusType;
  questionIndex?: number;
} | null;

export const currentOpenFieldAtom = atomWithReset<CurrentOpenFieldAtom>(null);

export const useCurrentOpenField = () => useAtom(currentOpenFieldAtom);
export const useCurrentOpenFieldAtom = () => useAtomValue(currentOpenFieldAtom);
export const useSetCurrentOpenField = () => useSetAtom(currentOpenFieldAtom);
export const useResetCurrentOpenField = () => useResetAtom(currentOpenFieldAtom);

export const currentFormSummaryListAtom = atom<SupportFormSummaryList>([]);
export const useCurrentFormSummaryList = () => useAtom(currentFormSummaryListAtom);

export const currentFormNameListAtom = atom((get) =>
  get(currentFormSummaryListAtom).map((formInfo) => {
    return {
      name: formInfo.name,
      formId: formInfo.id,
    };
  }),
);
export const useCurrentFormNameList = () => useAtomValue(currentFormNameListAtom);

export const snippetUrlAtom = atomWithReset<CreateFormResponse['snippet_url']>('');
export const useSnippetUrl = () => useAtomValue(snippetUrlAtom);
export const useSetSnippetUrl = () => useSetAtom(snippetUrlAtom);
export const useResetSnippetUrl = () => useResetAtom(snippetUrlAtom);
