import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { IconSpinner } from '@/components/Spinner';
import { RippleBodyText01, RippleBodyText02, RippleHeading05 } from '@/design';

export function LaunchSRCProcessing(): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <RippleHeading05 marginTop="32px">{t('common:launchSRC.title')}</RippleHeading05>
      <Flex align="center" justify="space-between" marginTop="36px">
        <RippleBodyText01>{t('common:launchSRC.locatingSRC')}.</RippleBodyText01>
        <IconSpinner imageSrcType="neutral" />
      </Flex>
      <Box marginTop="36px">
        <RippleBodyText02
          color="neutral.100"
          dangerouslySetInnerHTML={{
            __html: t('common:launchSRC.allowPopUpsNote'),
          }}
        />
      </Box>
    </>
  );
}
