import { z } from 'zod';

/** Types to query */
export const messageTypeSchema = z.union([
  z.literal('popup'),
  z.literal('banner'),
  z.literal('discover_page'),
  z.literal('get_started_page'),
]);
export type MessageType = z.infer<typeof messageTypeSchema>;

/** The page to display the message */
export const page = z.union([
  z.literal('my_computer'),
  z.literal('sos'),
  z.literal('service_desk'),
  z.literal('discover_page'),
  z.literal('policy'),
  z.literal('get_started_page'),
]);
export type Page = z.infer<typeof page>;

/** The message ids that the user has viewed (only for popup/banner) */
export const viewedMessages = z.array(z.number());
export type ViewedMessages = z.infer<typeof viewedMessages>;

/** The response details */
export const messageSchema = z.object({
  message: z.object({
    id: z.number().optional(),
    type: z.string().optional(),
    kind: z.string().optional(),
    style: z.string().optional(),
    kibana_tracking: z.boolean().optional(),
    pages: z
      .array(
        z.object({
          page: z.number(),
          image: z.string(),
          title: z.string(),
          body_text: z.string(),
          link_text: z.string().nullable(),
          url: z.string().nullable(),
          end_button: z.string().nullable(),
        }),
      )
      .optional(),
  }),
  viewed_messages: z.array(z.number()),
});
export type MessageSchemaType = z.infer<typeof messageSchema>;
