import { atom } from 'jotai';
import { atomWithImmer } from 'jotai-immer';

import { teamIdAtom } from '@/models/TeamInformation';
import {
  ComputerListCustomizedField,
  getTeamComputerDetail_deprecated,
  getTeamComputerListById,
  getUserComputerDetail_deprecated,
  getUserComputerListById,
} from '@/services/computers';
import { arrayToHashPair } from '@/utils/hashTable';

import { RetryQueue } from '../RetryQueue';
import { ALL_GROUP_ID, DEFAULT_GROUP_ID, FROM_OTHER_GROUP_ID } from '../constants';
import type { AdditionGroupMap, ComputerItem, ComputerScope, Group } from '../types';

export const computerScopeAtom = atom<ComputerScope>('team');

export const computerDataFieldsAtom = atom<Array<ComputerListCustomizedField>>([]);

export const isLoadingAtom = atom<boolean>(false);

export const computerDataMapAtom = atomWithImmer<Record<string, ComputerItem>>({});
export const computerIdListAtom = atom<Array<string>>((get) => {
  const computerDataMap = get(computerDataMapAtom);
  return Object.keys(computerDataMap);
});
export const computerListAtom = atom<Array<ComputerItem>>((get) => {
  const computerDataMap = get(computerDataMapAtom);
  return Object.values(computerDataMap);
});
export const computerCountAtom = atom<number>((get) => get(computerListAtom).length);

export const updateQueueAtom = atom(new RetryQueue());
export const waitingUpdateMapAtom = atomWithImmer<Record<string, boolean>>({});
export const resetUpdateQueueAtom = atom(null, (get) => {
  const updateQueue = get(updateQueueAtom);
  updateQueue.reset();
});

const getComputerDetailAtom = atom((get) => {
  const computerScope = get(computerScopeAtom);

  switch (computerScope) {
    case 'team': {
      return (teamId: number, computerId: string) => getTeamComputerDetail_deprecated(String(teamId), computerId);
    }
    case 'user': {
      return (_teamId: number, computerId: string) => getUserComputerDetail_deprecated(computerId);
    }
  }
});
export const checkDisconnectAtom = atom(null, (get, set, { teamId, computerId }: { teamId: number; computerId: string }) => {
  const updateQueue = get(updateQueueAtom);
  const getComputerDetail = get(getComputerDetailAtom);

  set(waitingUpdateMapAtom, (draft) => {
    draft[computerId] = true;
  });

  updateQueue.enqueue({
    task: () => getComputerDetail(teamId, computerId),
    condition: (response) => response.connected === false,
    onSuccess: (response) => {
      set(waitingUpdateMapAtom, (draft) => {
        draft[computerId] = false;
      });
      set(computerDataMapAtom, (draft) => {
        draft[computerId] = { ...draft[computerId], ...response };
      });
    },
    retryDelay: 5000,
    maxAttempts: 12,
  });
});
export const checkRebootAtom = atom(null, (get, set, { teamId, computerId }: { teamId: number; computerId: string }) => {
  const updateQueue = get(updateQueueAtom);
  const getComputerDetail = get(getComputerDetailAtom);

  set(computerDataMapAtom, (draft) => {
    draft[computerId].online_status = false;
  });

  set(waitingUpdateMapAtom, (draft) => {
    draft[computerId] = true;
  });

  setTimeout(() => {
    updateQueue.enqueue({
      task: () => getComputerDetail(teamId, computerId),
      condition: (response) => response.online_status === true,
      onSuccess: (response) => {
        set(waitingUpdateMapAtom, (draft) => {
          draft[computerId] = false;
        });
        set(computerDataMapAtom, (draft) => {
          draft[computerId] = { ...draft[computerId], ...response };
        });
      },
      retryDelay: 5000,
      maxAttempts: 12,
    });
  }, 60000); // check reboot 1min later due to the server can not get the latest status
});
export const checkWakeAtom = atom(null, (get, set, { teamId, computerId }: { teamId: number; computerId: string }) => {
  const updateQueue = get(updateQueueAtom);
  const getComputerDetail = get(getComputerDetailAtom);

  set(waitingUpdateMapAtom, (draft) => {
    draft[computerId] = true;
  });

  updateQueue.enqueue({
    task: () => getComputerDetail(teamId, computerId),
    condition: (response) => response.online_status === true,
    onSuccess: (response) => {
      set(waitingUpdateMapAtom, (draft) => {
        draft[computerId] = false;
      });
      set(computerDataMapAtom, (draft) => {
        draft[computerId] = { ...draft[computerId], ...response };
      });
    },
    retryDelay: 5000,
    maxAttempts: 12,
  });
});

export const groupListAtom = atom<Array<Group>>([]);
export const manageableGroupListAtom = atom<Array<Group>>([]);

export const additionGroupMapAtom = atom<AdditionGroupMap>({
  [ALL_GROUP_ID]: 'All Groups',
  [DEFAULT_GROUP_ID]: 'Default Group',
  [FROM_OTHER_GROUP_ID]: 'From Other Group',
});
export const groupIdListAtom = atom((get) => {
  const groupList = get(groupListAtom);

  return groupList.map(({ id }) => id);
});

export const groupNameMapAtom = atom((get): Record<string, string> => {
  const groupList = get(groupListAtom);
  const normalGroupNameMap = arrayToHashPair(groupList, { mainKey: 'id', valueKey: 'name' });

  const additionGroupMap = get(additionGroupMapAtom);

  return { ...normalGroupNameMap, ...additionGroupMap };
});

const getRefreshComputerDataServiceAtom = atom((get) => {
  const teamId = get(teamIdAtom);
  const computerScope = get(computerScopeAtom);
  const fields = get(computerDataFieldsAtom);

  switch (computerScope) {
    case 'team': {
      return (computerIdList: Array<number>) =>
        getTeamComputerListById.service({
          teamId,
          computerIds: computerIdList,
          mode: 'customize',
          customizedFields: fields,
        });
    }
    case 'user': {
      return (computerIdList: Array<number>) =>
        getUserComputerListById.service({
          computerIds: computerIdList,
          customizedFields: fields,
        });
    }
  }
});
export const refreshComputerDataAtom = atom(null, async (get, set, computerIdList: Array<number>) => {
  const refreshDataService = get(getRefreshComputerDataServiceAtom);

  const newComputerDataList = await refreshDataService(computerIdList);

  set(computerDataMapAtom, (draft) => {
    newComputerDataList.forEach((newComputerData) => {
      draft[newComputerData.id] = newComputerData;
    });
  });
});
