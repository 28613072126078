import { useTranslation } from 'react-i18next';

import {
  RippleButton,
  RippleButtonProps,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleTypography,
} from '@/design';

type DeletePolicyModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  hasOverridden: boolean;
  hasChildren: boolean;
  onClose: () => void;
  onDelete: () => void;
};

export const DeletePolicyModal = ({ isOpen, isLoading, hasChildren, hasOverridden, onClose, onDelete }: DeletePolicyModalProps) => {
  const { t } = useTranslation();
  const handleDelete = () => {
    onDelete();
    onClose();
  };
  const modalContent: Record<
    'hasOverridden' | 'hasChildren' | 'generic',
    {
      title: string;
      content: string;
      primaryButton: RippleButtonProps;
      secondaryButton?: RippleButtonProps;
    }
  > = {
    hasOverridden: {
      title: t('emm-policy:delete_policy'),
      content: t('emm-policy:all_overridden_configurations_on_computers_will_be_cleared_after_deleting'),
      primaryButton: {
        children: t('common:delete'),
        variant: 'danger',
      },
      secondaryButton: {
        children: t('common:cancel'),
        variant: 'grayScaleGhost',
      },
    },
    hasChildren: {
      title: t('emm-policy:cannot_delete_parent_policy'),
      content: t('emm-policy:you_cannot_delete_a_parent_policy_please_remove_all_child_policies_first'),
      primaryButton: {
        children: t('common:ok'),
        variant: 'primary',
      },
    },
    generic: {
      title: t('emm-policy:delete_policy'),
      content: t('emm-policy:are_you_sure_you_want_to_delete_this_policy'),
      primaryButton: {
        children: t('common:delete'),
        variant: 'danger',
      },
      secondaryButton: {
        children: t('common:cancel'),
        variant: 'grayScaleGhost',
      },
    },
  };
  const modalType: keyof typeof modalContent = hasChildren ? 'hasChildren' : hasOverridden ? 'hasOverridden' : 'generic';
  const { title, content, primaryButton, secondaryButton } = modalContent[modalType];
  return (
    <RippleModal isOpen={isOpen} onClose={onClose}>
      <RippleModalOverlay />
      <RippleModalContent w="448px">
        <RippleModalHeader>
          <RippleTypography variant="heading05">{title}</RippleTypography>
        </RippleModalHeader>
        <RippleModalBody>
          <RippleTypography variant="body02">{content}</RippleTypography>
        </RippleModalBody>
        <RippleModalFooter gap="8px">
          {secondaryButton && (
            <RippleButton variant={secondaryButton.variant} onClick={onClose}>
              {secondaryButton.children}
            </RippleButton>
          )}
          <RippleButton variant={primaryButton.variant} onClick={handleDelete} isLoading={isLoading}>
            {primaryButton.children}
          </RippleButton>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
};
