import axios from 'axios';

import { checkResponse } from '@/services/utils';

import { SurveyFormAPIValue } from '../type';
import { CreateSurveyResult, createSurveyResultSchema } from './types';

export const createSurvey = async (teamId: number, formValue: SurveyFormAPIValue): Promise<CreateSurveyResult> => {
  return checkResponse<CreateSurveyResult>(
    axios.post(`/api/web/v1/teams/${teamId}/service_desk/surveys`, formValue),
    createSurveyResultSchema,
  );
};
