import { RippleRefreshIconButton } from '@/design';

import { useActionBarContext } from './hooks';

type RefreshProps = { onClick: () => void };
export function Refresh({ onClick }: RefreshProps): React.JSX.Element {
  const { isLoading } = useActionBarContext();

  return <RippleRefreshIconButton isLoading={isLoading} onClick={onClick} />;
}
