import { Box } from '@chakra-ui/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';

import { RippleSheetModal, RippleTypography } from '@/design';
import { useTeamRole } from '@/models/TeamInformation';
import { useUserProfile } from '@/models/UserProfile';
import { ComputerData } from '@/services/computers';

import { Footer } from './Footer';
import { ShareWithSomeoneSection, ShowShareWithSomeoneSection } from './ShareWithSomeoneSection';
import { SharedModeSelect } from './SharedModeSelect';
import { accessPermissionModalAtom, resetModalAtom } from './atoms';

export function useAccessPermissionModal() {
  const set = useSetAtom(accessPermissionModalAtom);

  const { email } = useUserProfile();
  const { isManager } = useTeamRole();

  return {
    open: ({ computerData }: { computerData: Pick<ComputerData, 'id' | 'name' | 'deployed' | 'email'> }) => {
      const isDeployedComputer = Boolean(computerData.deployed);
      const isComputerOwner = computerData.email === email;
      const hasLimitedManagePermission = !isDeployedComputer && isManager && !isComputerOwner;

      set({ isOpen: true, computerData, hasLimitedManagePermission });
    },
  };
}

export function AccessPermissionModal(): React.JSX.Element {
  const { t } = useTranslation();

  const { isOpen, computerData } = useAtomValue(accessPermissionModalAtom);
  const resetModal = useSetAtom(resetModalAtom);

  return (
    <RippleSheetModal title={t('computer:access_permission')} isOpen={isOpen} onClose={resetModal} footer={<Footer />}>
      <Box px="16px">
        <RippleTypography variant="body02" mb="32px">
          {computerData?.name}
        </RippleTypography>

        <RippleTypography variant="body02" mb="8px">
          {t('computer:this_computer_can_be_accessed_by')}
        </RippleTypography>

        <SharedModeSelect />

        <ShowShareWithSomeoneSection>
          <ShareWithSomeoneSection />
        </ShowShareWithSomeoneSection>
      </Box>
    </RippleSheetModal>
  );
}
