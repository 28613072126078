import React from 'react';

import styled from '@emotion/styled';

import Button from '@/components/Button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@/components/Modal';

const MessageBody = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 14px;
  word-break: break-all;

  & button {
    padding: 0;
    font-size: 21px;
    font-weight: bold;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    cursor: pointer;
    background: transparent;
    border: 0;
    opacity: 0.2;

    &:hover {
      color: #000;
      opacity: 0.5;
    }
  }
`;

type MessageModalProps = {
  title?: string;
  isOpen: boolean;
  closeModal: () => void;
  message: string;
};

const MessageModal = ({ title, isOpen, message, closeModal }: MessageModalProps) => {
  return (
    <Modal isOpen={isOpen}>
      {title && <ModalHeader title={title} closeModal={closeModal} />}
      <ModalBody>
        <MessageBody>
          <div>{message}</div>
          {!title && (
            <div style={{ marginTop: '-10px' }}>
              <button aria-hidden="true" type="button" onClick={closeModal}>
                ×
              </button>
            </div>
          )}
        </MessageBody>
      </ModalBody>
      <ModalFooter>
        <div style={{ textAlign: 'right' }}>
          <Button color="primary" px="12px" py="6px" onClick={closeModal}>
            OK
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default MessageModal;
