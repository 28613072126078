import axios from 'axios';
import { z } from 'zod';

import { policyPlatformEnum } from '@/services/teams/emm_policies/types';
import { checkResponse, createGenericErrorHandlers } from '@/services/utils';

/**
 * EMM Policy Detail API
 * Fetch EMM policy detail
 * @param teamId
 * @param emmPolicyId
 * @doc https://github.com/SplashtopInc/be_kung_fu/blob/master/be_app/api/web-api/v1/emm/emm_policies.md#emm-policy-detail-api
 */

const listEmmPolicyDetailUrl = (teamId: number, emmPolicyId: number) => `/api/web/v1/teams/${teamId}/emm_policies/${emmPolicyId}`;

export const listEmmPolicyDetailSchema = z.object({
  id: z.number(),
  parent_id: z.number().nullable(),
  name: z.string(),
  description: z.string().nullable(),
  platform: policyPlatformEnum, // 'Windows', 'macOS'
  policy_kind: z.string(), // 'policy', 'server'
  updated_at: z.string(), // "YYYY-MM-DD HH:mm:ss"
  active: z.boolean(),
  super_root: z.boolean(),
});
export type ListEmmPolicyDetail = z.infer<typeof listEmmPolicyDetailSchema>;

export function listEmmPolicyDetail(teamId: number, emmPolicyId: number) {
  return checkResponse(axios.get(listEmmPolicyDetailUrl(teamId, emmPolicyId)), listEmmPolicyDetailSchema);
}

export const errorHandling = createGenericErrorHandlers();

export const listEmmPolicyDetailService = {
  getUrl: listEmmPolicyDetailUrl,
  method: 'GET',
  execute: listEmmPolicyDetail,
  errorHandling,
};
