import { Fragment, useMemo } from 'react';

import { Flex } from '@chakra-ui/react';
import { Row, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { TableVirtuoso } from 'react-virtuoso';

import { makeTableVirtuosoComponents } from '../TableVirtuosoComponents';
import { getRowId } from '../utils';
import { useWakeComputerModalActions, useWakeComputerModalData } from './WakeComputerModalAtom';
import { WakeComputer, useWakeComputerModalColumns } from './WakeComputerModalColumns';

type WakeComputerModalComputerTableProps = {
  data: Array<WakeComputer>;
  height: number;
};
export function WakeComputerModalComputerTable({ data, height }: WakeComputerModalComputerTableProps): React.JSX.Element {
  const columns = useWakeComputerModalColumns({ view: 'computer' });
  const { state, selectedComputerIdsHashMap } = useWakeComputerModalData();
  const { handleSelectId } = useWakeComputerModalActions();

  const table = useReactTable({
    data,
    columns,
    getRowId,
    state: {
      rowSelection: selectedComputerIdsHashMap,
    },
    enableRowSelection: true,
    onRowSelectionChange: (expandedStateUpdater) => {
      if (typeof expandedStateUpdater === 'function' && state === 'Selector') {
        const nextSelectedIds = expandedStateUpdater(selectedComputerIdsHashMap);
        handleSelectId(nextSelectedIds);
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { rows } = table.getRowModel();

  return (
    <TableVirtuoso
      style={{ height: `${height}px`, width: '100%' }}
      data={rows}
      // NOTE: `components` should be memoized to avoid some performance issues
      components={useMemo(() => makeTableVirtuosoComponents<Row<WakeComputer>>(), [])}
      context={{ table }}
      itemContent={(index, row) => {
        const isFirstRow = index === 0;
        const isLastRow = index === rows.length - 1;
        const isSelected = row.getIsSelected();

        return (
          <Flex
            key={row.id}
            sx={{
              '[data-cell-type="normal"]': {
                bgColor: isSelected ? 'green.10' : 'white',
              },
              '&:hover [data-cell-type="normal"]': {
                bgColor: isSelected ? 'green.40' : 'blue.0',
              },
              '[data-cell-type="normal"]:first-of-type': {
                borderTopLeftRadius: isFirstRow ? '4px' : '0',
                borderBottomLeftRadius: isLastRow ? '4px' : '0',
              },
              '[data-cell-type="normal"]:last-of-type': {
                borderTopRightRadius: isFirstRow ? '4px' : '0',
                borderBottomRightRadius: isLastRow ? '4px' : '0',
              },
            }}
            w="100%"
          >
            {row.getVisibleCells().map((cell) => (
              <Fragment key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Fragment>
            ))}
          </Flex>
        );
      }}
    />
  );
}
