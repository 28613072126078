import { atom } from 'jotai';
import type { PrimitiveAtom } from 'jotai';

import { DEFAULT_LEFT_OFFSET } from '../constants';
import type { ViewMode } from '../types';
import { viewModeAtom } from './tableSetting';

export const leftOffsetAtom = atom<number>(DEFAULT_LEFT_OFFSET);
export const horizonOffsetAtom = atom((get) => get(leftOffsetAtom) * 2);

const scrollWidthMapAtom = atom<Record<ViewMode, PrimitiveAtom<number | null>>>({
  computer: atom<number | null>(null),
  group: atom<number | null>(null),
});
export const scrollWidthAtom = atom(
  (get) => {
    const viewMode = get(viewModeAtom);
    const scrollWidthAtom = get(scrollWidthMapAtom)[viewMode];
    return get(scrollWidthAtom);
  },
  (get, set, { viewMode, value }: { viewMode: ViewMode; value: number }) => {
    const scrollWidthAtom = get(scrollWidthMapAtom)[viewMode];
    set(scrollWidthAtom, value);
  },
);
export const scrollContainerWidthAtom = atom((get) => {
  const scrollWidth = get(scrollWidthAtom);

  if (scrollWidth) return `${scrollWidth}px`;
  return '100%';
});

export const fullPageWidthAtom = atom((get) => {
  const scrollWidth = get(scrollWidthAtom);
  const horizonOffset = get(horizonOffsetAtom);

  if (scrollWidth) return `${scrollWidth + horizonOffset}px`;
  return '100vw';
});
