/**
 * Assign policy to servers
 * @see https://github.com/SplashtopInc/be_kung_fu/blob/master/be_app/api/web-api/v1/emm/emm_policies.md#assign-policy-to-servers-api
 */
import axios from 'axios';
import { z } from 'zod';

import { commonResponseSchema } from '@/services/schemas';
import { checkResponse, createGenericErrorHandlers } from '@/services/utils';

const getUrl = (teamId: number, policyId: number) => `/api/web/v1/teams/${teamId}/emm_policies/${policyId}/assign_policy_to_servers`;

const METHOD = 'POST';

const payloadSchema = z.object({
  server_ids: z.array(z.number()),
});

const responseSchema = z.object({
  success: z.array(z.number()),
  fail: z.array(z.number()),
});

const errorHandling = {
  ...createGenericErrorHandlers(),
  /**
   * @code 40409
   * - Server IDs exceed maximum limit (5000)
   */
  exceedNumberLimit: (response: any) => {
    const errorSchema = commonResponseSchema.merge(
      z.object({ result: z.literal(40409), data: z.object({ error: z.literal('exceed_number_limit') }) }),
    );
    return errorSchema.safeParse(response).success;
  },
  /**
   * @code 40422
   * server ID is empty
   */
  wrongParams: (response: any) => {
    const errorSchema = commonResponseSchema.merge(
      z.object({ result: z.literal(40422), data: z.object({ error: z.literal('wrong_params') }) }),
    );
    return errorSchema.safeParse(response).success;
  },
} as const;

const execute = (teamId: number, policyId: number, payload: z.infer<typeof payloadSchema>) =>
  checkResponse(axios({ method: METHOD, url: getUrl(teamId, policyId), data: payload }), responseSchema);

export const assignEmmPolicyToServersService = { getUrl, method: METHOD, payloadSchema, execute, errorHandling };
