import { Box, HStack, VStack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { Controller, useFormContext } from 'react-hook-form';

import { RippleBodyText02, RippleButton, RippleHeading06, RippleSheetAction } from '@/design';
import { RippleRadio, RippleRadioGroup } from '@/design';
import { SUPPORT_FORM_CONTENT_WIDTH } from '@/modules/ServiceDesk/SupportForm/Editor/constants';
import { DownloadPageKindOptions, SupportFormMode, SupportFormValue } from '@/modules/ServiceDesk/SupportForm/Editor/types';
import { DownloadSection } from '@/modules/ServiceDesk/SupportForm/EndUser/DownloadSection';
import { DownloadPageKind } from '@/services/serviceDesk/supportForm/types';
import { getRegionData } from '@/utils/region';

const { env } = getRegionData();

const DOWNLOAD_SECTION_INFO = {
  downloadUrl: '',
  helpLink: (
    <RippleBodyText02 as="span" color="blue.100" fontWeight="semibold">
      {env === 'eu' ? 'eu.help123.app' : 'help123.app'}
    </RippleBodyText02>
  ),
  pinCode: '123 456',
  onRefreshPinCode: () => null,
  expiredDate: new Date(),
  linkForCopy: '',
};

type DownloadPageProps = {
  isFetching?: boolean;
  mode: SupportFormMode;
  onClickNextStep: () => void;
  onClickPreviousStep: () => void;
};

export function DownloadPage({ isFetching, mode, onClickPreviousStep, onClickNextStep }: DownloadPageProps) {
  const { t } = useTranslation();
  const SUBMIT_BUTTON_TEXT_MAP = {
    create: t('common:create'),
    edit: t('common:update'),
    clone: t('common:create'),
  };

  const { control } = useFormContext<SupportFormValue>();

  return (
    <HStack w={SUPPORT_FORM_CONTENT_WIDTH} mt="48px" gap="24px" flexDirection="column" alignItems="flex-start">
      <VStack gap="8px" alignItems="flex-start">
        <RippleHeading06>{t('support-forms:downloadPage')}</RippleHeading06>
        <RippleBodyText02>{t('support-forms:chooseStyle')}</RippleBodyText02>
      </VStack>

      <Controller
        control={control}
        name="download_page_kind"
        render={({ field }) => {
          return (
            <RippleRadioGroup value={field.value} onChange={field.onChange} w="100%">
              <VStack gap="24px" alignItems="stretch" mb="8px">
                <Box>
                  <RippleRadio mb="10px" value={DownloadPageKindOptions.SPLASHTOP_CLASSIC}>
                    {t('support-forms:splashtopClassic')}
                  </RippleRadio>
                  <Wrapper>
                    <DownloadSection kind={DownloadPageKind.SPLASHTOP_CLASSIC} {...DOWNLOAD_SECTION_INFO} />
                  </Wrapper>
                </Box>
                <Box>
                  <RippleRadio mb="10px" value={DownloadPageKindOptions.HIDE_SPLASHTOP_BRAND}>
                    {t('support-forms:hideSplashtopBrand')}
                  </RippleRadio>
                  <Wrapper>
                    <DownloadSection kind={DownloadPageKind.HIDE_SPLASHTOP_BRAND} {...DOWNLOAD_SECTION_INFO} />
                  </Wrapper>
                </Box>
              </VStack>
            </RippleRadioGroup>
          );
        }}
      />

      <RippleSheetAction>
        <RippleButton variant="grayScaleGhost" onClick={onClickPreviousStep}>
          {t('common:back')}
        </RippleButton>
        <RippleButton onClick={onClickNextStep} isLoading={isFetching}>
          {SUBMIT_BUTTON_TEXT_MAP[mode]}
        </RippleButton>
      </RippleSheetAction>
    </HStack>
  );
}

const Wrapper = ({ children }: { children: React.JSX.Element }) => {
  return (
    <Box py="4px" border="1px solid" borderColor="neutral.300" borderRadius="12px">
      {children}
    </Box>
  );
};
