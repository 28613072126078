import { pureInstance } from '@/services/utils';

/**
 * Get MIME type from base64
 * @param {string} base64
 * @return {string|null} valid MIME type or null
 */
export function getMimeType(base64: string) {
  const match = base64.match(/^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  return match ? match[1] : null;
}

/**
 * Convert base64 to Blob
 * @param {string} base64
 * @param {string} mimeType
 * @return {Blob}
 */
export function base64ToBlob(base64: string, mimeType: string) {
  const byteString = atob(base64.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeType });
}

/**
 * Convert Image Url to base64
 * @param {string} url
 * @return {string} base64
 */
export function imageUrlToBase64(url: string, callback: (base64String: string) => void): void {
  pureInstance
    .get(url, { responseType: 'blob' })
    .then((response: { data: Blob }) => {
      const reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onloadend = () => {
        const base64String = typeof reader.result === 'string' ? reader.result : '';
        callback(base64String);
      };
    })
    .catch((error) => console.error(error));
}
