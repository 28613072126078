import { useRouter } from 'next/router';

/**
 * A hook that provides functions to update and delete search parameters in the URL.
 * @returns An object containing the `setSearchParam` and `deleteSearchParam` functions.
 */
export const useUpdateSearchParam = () => {
  const { push } = useRouter();

  /**
   * Redirect the search parameters in the URL and navigates to the new URL.
   * @param updates - An object containing key-value pairs of search parameters to update.
   */
  const redirectWithSearchParam = (redirectPathname: string, updates: Record<string, string>) => {
    const params = new URLSearchParams(updates);
    const paramsString = params.toString();
    if (!paramsString) {
      push(redirectPathname);
      return;
    }
    push(`${redirectPathname}?${paramsString}`);
  };

  return {
    redirectWithSearchParam,
  };
};
