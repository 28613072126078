import { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Flex, VStack, forwardRef, useMergeRefs } from '@chakra-ui/react';
import { BoxProps, FlexProps } from '@chakra-ui/react';
import type { Table } from '@tanstack/react-table';
import { useAtomValue, useSetAtom } from 'jotai';
import { TableComponents } from 'react-virtuoso';

import { RippleSkeleton, RippleTbodyLegacy, RippleTheadLegacy, RippleTrLegacy } from '@/design';
import { ComputerNoResultAlerts } from '@/showcase';

import {
  fullPageWidthAtom,
  hasFilterAtom,
  hasSearchAtom,
  isLoadingAtom,
  leftOffsetAtom,
  resetAllFiltersAtom,
  resetSearchAtom,
  rowSelectionCountAtom,
} from './atoms';
import { StickyLeftWrapper } from './components';
import { ScrollPositionContext } from './contexts';
import { ScrollPosition } from './types';

type VirtuosoContext = { table: Table<any> };

type MakeTableVirtuosoComponentsProps = {
  /**
   * The view mode of the table.
   *
   * Currently only used for adding test id to the table components.
   *
   * @default 'list'
   */
  viewMode?: 'list' | 'group';
};

// Copy from src/showcase/ComputerTable/makeTableVirtuosoComponents.tsx
export const makeTableVirtuosoComponents = <D,>({ viewMode = 'list' }: MakeTableVirtuosoComponentsProps = {}): TableComponents<
  D,
  VirtuosoContext
> => ({
  // Fix warning for <tr> cannot appear as a child of <div>.
  // 1. copy from https://github.com/petyosi/react-virtuoso/blob/274731d5eb027859224e12931d6c63b9a6ec5f77/src/Table.tsx#L63
  // 2. replace tr and td with div
  FillerRow: ({ height }: { height: number }) => (
    <div>
      <div style={{ height: height, padding: 0, border: 0 }}></div>
    </div>
  ),
  Table: forwardRef((props, ref) => <Flex data-testid={`computer-list--${viewMode}--table`} flexDirection="column" ref={ref} {...props} />),
  TableBody: forwardRef((props, ref) => (
    <RippleTbodyLegacy
      data-testid={`computer-list--${viewMode}--tableBody`}
      display="table-row-group"
      zIndex={0} // Prevent table header being overlay by table body
      ref={ref}
      {...props}
    />
  )),
  TableHead: forwardRef(({ style, ...otherProps }, ref) => {
    return (
      <RippleTheadLegacy
        data-testid={`computer-list--${viewMode}--tableHead`}
        ref={ref}
        display="block"
        position="sticky"
        style={{ ...style, top: 100 }}
        {...otherProps}
      />
    );
  }),
  TableRow: forwardRef((props, ref) => {
    return (
      <RippleTrLegacy
        ref={ref}
        _first={{
          '[data-cell-type="normal"]': {
            borderTop: '1px solid',
            borderTopColor: 'neutral.60',
          },
        }}
        w="fit-content"
        minW="100%"
        {...props}
      />
    );
  }),
  EmptyPlaceholder: ({ context }) => {
    const isLoading = useAtomValue(isLoadingAtom);
    const hasFilter = useAtomValue(hasFilterAtom);
    const hasSearch = useAtomValue(hasSearchAtom);
    const selectedCount = useAtomValue(rowSelectionCountAtom);

    const resetAllFilters = useSetAtom(resetAllFiltersAtom);
    const resetSearch = useSetAtom(resetSearchAtom);

    const rowWidth =
      context?.table.getFlatHeaders().reduce((acc, header) => {
        const columnSize = header.getSize();
        return acc + columnSize;
      }, 0) ?? 500;

    if (isLoading)
      return (
        <VStack spacing="8px" w={`${rowWidth}px`} alignItems="stretch">
          <RippleSkeleton h="40px" />
          <RippleSkeleton h="40px" />
          <RippleSkeleton h="40px" />
        </VStack>
      );

    return (
      <StickyLeftWrapper>
        <Flex w="100%" justifyContent="center">
          <ComputerNoResultAlerts
            isLoading={false}
            haveFilterApplied={hasFilter}
            haveSearchApplied={hasSearch}
            isEmpty
            selectedComputerCount={selectedCount}
            onShowAll={() => {
              resetAllFilters();
              resetSearch();
            }}
          />
        </Flex>
      </StickyLeftWrapper>
    );
  },
  Scroller: forwardRef((props, ref) => {
    const scrollerRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergeRefs(ref, scrollerRef);

    const [scrollPosition, setScrollPosition] = useState<ScrollPosition>('none');

    const updateScrollPosition = useCallback((element: HTMLDivElement) => {
      const scrollPosition = element.scrollLeft;
      const scrollWidth = element.scrollWidth;
      const clientWidth = element.clientWidth;
      const isScrollable = scrollWidth > clientWidth;

      if (isScrollable) {
        if (scrollPosition === 0) setScrollPosition('left');
        else if (Math.ceil(scrollPosition + clientWidth) >= scrollWidth) setScrollPosition('right');
        else {
          setScrollPosition('middle');
        }
      } else {
        setScrollPosition('none');
      }
    }, []);

    function handleElementEvent(event: React.SyntheticEvent<HTMLDivElement>): void {
      if (event.currentTarget) updateScrollPosition(event.currentTarget);
    }

    useEffect(
      function initialScrollPosition() {
        if (scrollerRef?.current) {
          updateScrollPosition(scrollerRef.current);
        }
      },
      [updateScrollPosition],
    );

    return (
      <ScrollPositionContext.Provider value={scrollPosition}>
        <Box ref={mergedRef} onResize={handleElementEvent} onScroll={handleElementEvent} {...props} />
      </ScrollPositionContext.Provider>
    );
  }),
});

export const makeTableVirtuosoComponentsForGroup = <D,>() => ({
  ...makeTableVirtuosoComponents<D>({ viewMode: 'group' }),
  TableRow: forwardRef((props, ref) => {
    return <RippleTrLegacy ref={ref} w="fit-content" minW="100%" {...props} />;
  }),
});

type TableWithOffsetMaskProps = FlexProps & { viewMode: 'list' | 'group' };
const TableWithOffsetMask = forwardRef<TableWithOffsetMaskProps, 'div'>(({ children, viewMode, ...otherProps }, ref) => (
  <Flex data-testid={`computer-list--${viewMode}--table`} ref={ref} flexDirection="column" position="relative" {...otherProps}>
    <OffsetMask />
    {children}
  </Flex>
));

function OffsetMask(): React.JSX.Element {
  const leftOffset = useAtomValue(leftOffsetAtom);
  const fullPageWidth = useAtomValue(fullPageWidthAtom);

  return (
    <Flex position="absolute" left={`${-leftOffset}px`} top="0" w={fullPageWidth} h="100%" justifyContent="space-between">
      {/* Left mask */}
      <Box position="sticky" left="0px" top="0px" w={`${leftOffset}px`} h="100%" bgColor="neutral.10" zIndex={10} />

      {/* Right mask */}
      <Box position="sticky" right="0px" top="0px" w={`${leftOffset}px`} h="100%" bgColor="neutral.10" zIndex={10} />
    </Flex>
  );
}

const ScrollerForWindowScroll = forwardRef<BoxProps, 'div'>((props, ref) => {
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>('none');

  const updateScrollPosition = useCallback(() => {
    const scrollPosition = window.pageXOffset;
    const scrollWidth = document.documentElement.scrollWidth;
    const clientWidth = document.documentElement.clientWidth;
    const isScrollable = scrollWidth > clientWidth;

    if (isScrollable) {
      if (scrollPosition === 0) setScrollPosition('left');
      else if (Math.ceil(scrollPosition + clientWidth) >= scrollWidth) setScrollPosition('right');
      else {
        setScrollPosition('middle');
      }
    } else {
      setScrollPosition('none');
    }
  }, []);

  useEffect(
    function registerListeners() {
      updateScrollPosition(); // Initialize

      window.addEventListener('resize', updateScrollPosition);
      window.addEventListener('scroll', updateScrollPosition);

      return () => {
        window.removeEventListener('resize', updateScrollPosition);
        window.removeEventListener('scroll', updateScrollPosition);
      };
    },
    [updateScrollPosition],
  );

  return (
    <ScrollPositionContext.Provider value={scrollPosition}>
      <Box ref={ref} {...props} />
    </ScrollPositionContext.Provider>
  );
});

export const makeTableVirtuosoComponentsWithOffsetMask = <D,>(): TableComponents<D, VirtuosoContext> => ({
  ...makeTableVirtuosoComponents<D>(),
  Table: (props) => <TableWithOffsetMask viewMode="list" {...props} />,
  Scroller: ScrollerForWindowScroll,
});

export const makeTableVirtuosoComponentsForGroupWithOffsetMask = <D,>(): TableComponents<D, VirtuosoContext> => ({
  ...makeTableVirtuosoComponentsForGroup<D>(),
  Table: (props) => <TableWithOffsetMask viewMode="group" {...props} />,
  Scroller: ScrollerForWindowScroll,
});
