import { useEffect, useRef, useState } from 'react';

import { Box, Flex } from '@chakra-ui/react';

import { RippleClock, RippleTextField, RippleTypography } from '@/design';
import { DatePickerButton } from '@/design/RippleDatePicker/components/DatePickerButton';
import { hideScrollbarStyle, zeroPadding } from '@/design/RippleDatePicker/utils';
import { Hour, Minute, hourList, minuteList, parseTime } from '@/utils/cron';
import { useClickOutside } from '@/utils/useClickOutside';

const TIME_BUTTON_HEIGHT = 32;
const CUSTOM_DATE_PICKER_STYLE = {
  width: '48px',
  height: `${TIME_BUTTON_HEIGHT}px`,
};

const getFormattedTime = ({ hour, minute }: { hour: Hour; minute: Minute }) => {
  return `${zeroPadding(parseInt(hour, 10))}:${zeroPadding(parseInt(minute, 10))}`;
};

export const TimePicker = ({
  selectTime,
  setSelectTime,
  isDisabled,
}: {
  selectTime: { hour: Hour; minute: Minute };
  setSelectTime: (time: { hour: Hour; minute: Minute }) => void;
  isDisabled?: boolean;
}) => {
  const timeSelectBoxRef = useRef<HTMLDivElement | null>(null);
  const hourButtonBoxRef = useRef<HTMLDivElement | null>(null);
  const minuteButtonBoxRef = useRef<HTMLDivElement | null>(null);

  const [showTimePicker, setShowTimePicker] = useState(false);

  useEffect(() => {
    if (!showTimePicker) return;
    if (hourButtonBoxRef.current) {
      hourButtonBoxRef.current.scrollTo({ top: (parseInt(selectTime.hour, 10) - 3) * TIME_BUTTON_HEIGHT, behavior: 'smooth' });
    }
    if (minuteButtonBoxRef.current) {
      minuteButtonBoxRef.current.scrollTo({ top: (parseInt(selectTime.minute, 10) - 3) * TIME_BUTTON_HEIGHT, behavior: 'smooth' });
    }
  }, [selectTime.hour, selectTime.minute, showTimePicker]);

  useClickOutside(timeSelectBoxRef, () => {
    if (showTimePicker) {
      setShowTimePicker(false);
    }
  });

  const [isTyping, setIsTyping] = useState(false);
  const time = getFormattedTime(selectTime);
  const [input, setInput] = useState(time);

  return (
    <Box position="relative">
      <Box>
        <RippleTextField
          isDisabled={isDisabled}
          opacity="1 !important"
          bg="transparent !important"
          color={isDisabled ? 'dark.40' : 'dark.90'}
          leadingIcon={<RippleClock color={isDisabled ? 'dark.40' : 'neutral.300'} />}
          size="sm"
          onFocus={() => {
            setIsTyping(true);
            // Set to latest selected time when user start typing
            setInput(time);
          }}
          onBlur={() => {
            setIsTyping(false);
            const parsedTime = parseTime(input);
            if (parsedTime) {
              setSelectTime({ hour: parsedTime.hour, minute: parsedTime.minute });
            }
          }}
          value={isTyping ? input : time}
          width="160px"
          onClick={() => setShowTimePicker(!showTimePicker)}
          onChange={(e) => {
            setInput(e.target.value);
          }}
        />
      </Box>
      {showTimePicker && (
        <Box
          p="12px"
          mt="8px"
          boxShadow="0px 8px 16px 4px rgba(0, 0, 0, 0.12)"
          borderRadius="4px"
          position="absolute"
          background="white"
          zIndex={9}
        >
          <RippleTypography variant="heading09" mt="4px" mb="8px">
            Time
          </RippleTypography>
          <Flex height="286px" gap="4px" ref={timeSelectBoxRef}>
            <Box ref={hourButtonBoxRef} overflowY="auto" sx={hideScrollbarStyle}>
              {hourList.map((hour) => {
                return (
                  <DatePickerButton
                    key={hour}
                    isActive={hour === selectTime.hour}
                    isDisabled={false}
                    onClick={() => {
                      setSelectTime({ hour, minute: selectTime.minute });
                    }}
                    sx={CUSTOM_DATE_PICKER_STYLE}
                    type="button"
                  >
                    {zeroPadding(parseInt(hour, 10))}
                  </DatePickerButton>
                );
              })}
              {/* Add a placeholder to enable the last-time button to scroll to the middle of the box. */}
              <Placeholder />
            </Box>
            <Box ref={minuteButtonBoxRef} overflowY="auto" sx={hideScrollbarStyle}>
              {minuteList.map((minute) => {
                return (
                  <DatePickerButton
                    key={minute}
                    isActive={minute === selectTime.minute}
                    isDisabled={false}
                    onClick={() => {
                      setSelectTime({ hour: selectTime.hour, minute });
                    }}
                    sx={CUSTOM_DATE_PICKER_STYLE}
                    type="button"
                  >
                    {zeroPadding(parseInt(minute, 10))}
                  </DatePickerButton>
                );
              })}
              {/* Add a placeholder to enable the last-time button to scroll to the middle of the box. */}
              <Placeholder />
            </Box>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

const Placeholder = () => <Box h="106px" />;
