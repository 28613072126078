import { ConfirmModal, useConfirmModal } from './ConfirmModal';

export function useDisconnectSession() {
  const confirmModal = useConfirmModal();

  return {
    execute: confirmModal.open,
  };
}

export function DisconnectSession(): React.JSX.Element {
  return (
    <>
      <ConfirmModal />
    </>
  );
}
