import React, { forwardRef } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { layout, space } from 'styled-system';
import type { LayoutProps, SpaceProps } from 'styled-system';

export type ButtonProps = {
  color: 'primary' | 'secondary' | 'success' | 'link' | 'outline' | 'default';
  disabled?: boolean;
  [x: string]: any;
};

const StyledButton = styled.button<Partial<ButtonProps> & LayoutProps & SpaceProps>`
  display: inline-block;
  min-width: 75px;
  line-height: normal;
  color: ${({ color }) => {
    switch (color) {
      case 'link':
        return '#111';
      case 'secondary':
      case 'default':
        return '#333';
      case 'outline':
        return '#428bca';
      default:
        return '#fff';
    }
  }};
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: ${({ color }) => {
    switch (color) {
      case 'primary':
        return '#428bca';
      case 'success':
        return '#5cb85c';
      case 'link':
      case 'default':
        return '#fff';
      case 'secondary':
        return '#efefef';
      case 'outline':
        return '#fff';
      default:
        return '#428bca';
    }
  }};
  border: 1px solid transparent;
  border-color: ${({ color }) => {
    switch (color) {
      case 'primary':
        return '#347ebd';
      case 'success':
        return '#4cae4c';
      case 'link':
        return '#fff';
      case 'default':
        return '#ccc';
      case 'secondary':
        return 'transparent';
      case 'outline':
        return '#347ebd';
      default:
        return '#347ebd';
    }
  }};
  border-radius: 4px;

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ color }) => {
      switch (color) {
        case 'primary':
          return '#3276b1';
        case 'success':
          return '#47a447';
        case 'link':
          return '#fff';
        case 'default':
          return '#ebebeb';
        case 'secondary':
          return '#efefef';
        case 'outline':
          return '#f7f7f7';
        default:
          return '#3276b1';
      }
    }};
    border-color: ${({ color }) => {
      switch (color) {
        case 'primary':
          return '#285e8e';
        case 'success':
          return '#398439';
        case 'link':
          return '#fff';
        case 'default':
          return '#adadad';
        case 'secondary':
          return 'transparent';
        case 'outline':
          return '#285e8e';
        default:
          return '#285e8e';
      }
    }};
  }

  &:active {
    outline: 0;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  &:focus {
    outline: 0;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      box-shadow: none;
      opacity: 0.65;
    `}

  ${layout}
  ${space}
`;

const Content = styled.h4`
  display: inline-block;
  margin: 0;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;
`;

const Button = forwardRef(
  ({ color, children, disabled, ...otherProps }: ButtonProps, ref?: React.Ref<HTMLButtonElement & HTMLAnchorElement>) => {
    return (
      <StyledButton color={color} disabled={disabled} ref={ref} {...otherProps}>
        <Content>{children}</Content>
      </StyledButton>
    );
  },
);

export default Button;
