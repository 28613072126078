import React, { ReactNode } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

type PageContainerProps = {
  children: ReactNode;
} & BoxProps;

export const PageContainer = (props: PageContainerProps) => (
  <Box p="0 15px" mx="auto" width={['100%', 750, 970, 1170]} height="100%" {...props} />
);
