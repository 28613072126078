import { RippleTD, RippleTDProps, RippleTH, RippleTHProps } from '@/design';

export const CheckboxHeader = (props: RippleTHProps) => (
  <RippleTH flex="0 0 40px" p="4px 4px 4px 12px" alignItems="center" justifyContent="center" {...props} />
);
export const CheckboxColumn = (props: RippleTDProps) => (
  <RippleTD flex="0 0 40px" p="4px 4px 4px 12px" alignItems="center" justifyContent="center" {...props} />
);
export const IconHeader = (props: RippleTDProps) => (
  <RippleTH flex="0 0 24px" justifyContent="center" alignItems="center" p="0" {...props} />
);
export const IconColumn = (props: RippleTDProps) => (
  <RippleTD flex="0 0 24px" justifyContent="center" alignItems="center" p="0" {...props} />
);

export const NameHeader = (props: RippleTHProps) => <RippleTH flex="0 0 412px" {...props} />;
export const NameColumn = (props: RippleTDProps) => <RippleTD flex="0 0 412px" {...props} />;

export const PolicyHeader = (props: RippleTHProps) => <RippleTH flex="0 0 412px" {...props} />;
export const PolicyColumn = (props: RippleTDProps) => <RippleTD flex="0 0 412px" {...props} />;

export const VersionHeader = (props: RippleTHProps) => <RippleTH flex="1" {...props} />;
export const VersionColumn = (props: RippleTDProps) => <RippleTD flex="1" {...props} />;
