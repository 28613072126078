import { FilterGroup } from './FilterGroup';
import { FilterLastOnline } from './FilterLastOnline';
import { FilterPlatform } from './FilterPlatform';
import { FilterPreferencePolicy } from './FilterPreferencePolicy';
import { FilterSelected } from './FilterSelected';

export * from './FiltersDrawer';

export const FiltersDrawerSections = {
  FilterGroup,
  FilterPreferencePolicy,
  FilterSelected,
  FilterLastOnline,
  FilterPlatform,
};
