import { ConfirmModal, useConfirmModal } from './ConfirmModal';
import { ResultModal } from './ResultModal';

export function useDeleteComputers() {
  const confirmModal = useConfirmModal();

  return {
    execute: confirmModal.open,
  };
}

export function DeleteComputers(): React.JSX.Element {
  return (
    <>
      <ConfirmModal />
      <ResultModal />
    </>
  );
}
