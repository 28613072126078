import { useRouter } from 'next/router';

import { atom, useAtomValue } from 'jotai';
import { loadable } from 'jotai/utils';
import { useTranslation } from 'next-i18next';

import { RippleButton } from '@/design';
import { useTeamExpiration, useTeamInformation, useTeamRole } from '@/models/TeamInformation';
import { getQuickSetupInfoService } from '@/services/users';

import { useActionBarContext } from './hooks';

const quickSetupInfoAtom = atom(async () => getQuickSetupInfoService.execute());
const quickSetupInfoLoadableAtom = loadable(quickSetupInfoAtom);

type AddComputerProps = { isDisabled?: boolean };
export function AddComputer_SBA(props: AddComputerProps): React.JSX.Element {
  return (
    <ShowAddComputer>
      <AddComputerCore {...props} />
    </ShowAddComputer>
  );
}

function AddComputerCore({ isDisabled = false }: AddComputerProps): React.JSX.Element {
  const { t } = useTranslation();
  const router = useRouter();

  const { teamKind } = useTeamInformation();
  const { isTeamExpired } = useTeamExpiration();
  const { isMember } = useTeamRole();
  const { isLoading } = useActionBarContext();
  const quickSetupInfoQuery = useAtomValue(quickSetupInfoLoadableAtom);

  function handleAddComputer(): void {
    if (quickSetupInfoQuery.state === 'hasData') {
      if (teamKind === 'sba' && isMember) {
        // Align the behavior of opening the link to OOBE `src/modules/Unboxing/hooks/useAddComputerHandler.ts`
        // Open the link in a new tab if the user is a member of the SBA team
        window.open(quickSetupInfoQuery.data.srs_download_link, '_blank', 'noopener noreferrer');
      } else {
        router.push(quickSetupInfoQuery.data.srs_download_link);
      }
    }
  }

  return (
    <RippleButton
      data-testid="add-computer"
      size="xs"
      variant="secondary"
      onClick={handleAddComputer}
      isDisabled={isDisabled || isLoading || isTeamExpired || quickSetupInfoQuery.state !== 'hasData'}
    >
      {t('computer:addComputer')}
    </RippleButton>
  );
}

type ShowAddComputerProps = { children?: React.ReactNode };
function ShowAddComputer({ children }: ShowAddComputerProps): React.JSX.Element | null {
  const { isManager } = useTeamRole();

  const quickSetupInfoQuery = useAtomValue(quickSetupInfoLoadableAtom);

  /**
   * `srs_download_kind` is affected by the setting "OOBE instruction for members" (`srs_download`) in Team Settings
   */
  const isMemberHasAbilityToAddComputer = quickSetupInfoQuery.state === 'hasData' && quickSetupInfoQuery.data.srs_download_kind !== 'none';

  if (isManager || isMemberHasAbilityToAddComputer) return <>{children}</>;

  return null;
}
