import type { RowSelectionState } from '@tanstack/react-table';
import { atom } from 'jotai';
import { RESET, atomWithReset } from 'jotai/utils';

import { teamInformationAtom, teamRoleAtom } from '@/models/TeamInformation';
import { userProfileAtom } from '@/models/UserProfile';
import { UserPermissionSBAInstance } from '@/modules/Computer/MyComputers/userPermission';
import {
  type ComputerData,
  type ComputerSharedMode,
  type ComputerSharedWith,
  GetComputerSharedModeResponse,
  type UpdateComputerSharedModePayload,
  computerSharedModeMap,
} from '@/services/computers';
import type { TeamMember as TeamMemberOriginal } from '@/services/team/types';

import { ExternalUser, TeamMember, User } from './types';
import { computeArrayDiff, generateRowId } from './utils';

const { getUserPermissionAtom } = UserPermissionSBAInstance;

export const functionPermissionMapAtom = atom((get) => {
  const { isManager } = get(teamRoleAtom);
  const userPermission = get(getUserPermissionAtom)();

  return {
    canGetMemberList: isManager || userPermission.team_permissions.member_list,
  };
});

export const accessPermissionModalAtom = atomWithReset<{
  isOpen: boolean;
  computerData: Pick<ComputerData, 'id' | 'name' | 'deployed' | 'email'> | null;
  /**
   * The admin but not the owner of the computer can only stop sharing the computer.
   */
  hasLimitedManagePermission: boolean;
}>({
  isOpen: false,
  computerData: null,
  hasLimitedManagePermission: false,
});
export const sharedModeAtom = atomWithReset<ComputerSharedMode>(computerSharedModeMap.stop_share_server);
export const sharedWithAtom = atomWithReset<ComputerSharedWith>({
  members: [],
  users: [],
});

export const tableSelectionStateAtom = atomWithReset<RowSelectionState>({});
export const selectedUserCountAtom = atom((get) => {
  const tableSelectionState = get(tableSelectionStateAtom);
  return Object.values(tableSelectionState).filter((selected) => selected).length;
});

export const setSharedModeAtom = atom(null, (get, set, payload: GetComputerSharedModeResponse) => {
  const { member_id } = get(teamInformationAtom);
  const { email } = get(userProfileAtom);
  const selfTeamMemberData: TeamMemberData = { id: member_id, email };

  const { canGetMemberList } = get(functionPermissionMapAtom);

  set(sharedModeAtom, payload.mode);

  const hasSharedWithData = payload.share_with.members.length > 0 || payload.share_with.users.length > 0;
  if (hasSharedWithData) {
    // Save original shared_with data
    set(sharedWithAtom, payload.share_with);

    // Initialize selection state
    set(tableSelectionStateAtom, (value) => {
      return {
        ...value,
        ...payload.share_with.members.reduce<RowSelectionState>((acc, user) => {
          acc[generateRowId.teamMember(user.id)] = true;
          return acc;
        }, {}),
        ...payload.share_with.users.reduce<RowSelectionState>((acc, user) => {
          acc[generateRowId.externalUser(user.id)] = true;
          return acc;
        }, {}),
      };
    });

    // Initialize teamMemberList and externalUserList
    set(externalUserListAtom, payload.share_with.users);
    if (!canGetMemberList) {
      // teamMemberList should always include self
      const teamMemberList = payload.share_with.members.find((member) => member.id === selfTeamMemberData.id)
        ? payload.share_with.members
        : payload.share_with.members.concat(selfTeamMemberData);
      set(teamMemberListAtom, teamMemberList);
    }
  } else {
    // Initialize share to someone state that includes self by default
    if (!canGetMemberList) {
      // teamMemberList should always include self
      set(teamMemberListAtom, [selfTeamMemberData]);
    }
    set(tableSelectionStateAtom, { [generateRowId.teamMember(selfTeamMemberData.id)]: true });
  }
});

export const newSharedWithDiffAtom = atom<Pick<UpdateComputerSharedModePayload & { mode: 'share_to_someone' }, 'members' | 'users'>>(
  (get) => {
    const prevSharedWith = get(sharedWithAtom);
    const tableSelectionState = get(tableSelectionStateAtom);

    const prevValue = {
      members: prevSharedWith.members.map((user) => user.id),
      users: prevSharedWith.users.map((user) => user.id),
    };
    const newValue = Object.entries(tableSelectionState).reduce<{ members: Array<number>; users: Array<number> }>(
      (acc, cur) => {
        const [rowId, selected] = cur;
        if (selected) {
          const memberId = Number(rowId.split('_')[1]);

          if (rowId.startsWith('teamMember')) {
            acc.members.push(memberId);
          } else if (rowId.startsWith('externalUser')) {
            acc.users.push(memberId);
          }
        }
        return acc;
      },
      { members: [], users: [] },
    );

    return {
      members: computeArrayDiff(prevValue.members, newValue.members),
      users: computeArrayDiff(prevValue.users, newValue.users),
    };
  },
);

export const addShareTeamMemberAtom = atom(null, (_get, set, payload: { memberId: number }) => {
  const { memberId } = payload;
  set(tableSelectionStateAtom, (value) => ({ ...value, [generateRowId.teamMember(memberId)]: true }));
});
export const addShareUserAtom = atom(null, (_get, set, payload: { id: number; email: string }) => {
  const { id, email } = payload;
  set(tableSelectionStateAtom, (value) => ({ ...value, [generateRowId.externalUser(id)]: true }));
  set(externalUserListAtom, (externalUserList) => {
    if (externalUserList.some((user) => user.id === id)) return externalUserList;
    else {
      return externalUserList.concat({ id, email });
    }
  });
});
export const resetModalAtom = atom(null, (_get, set) => {
  set(accessPermissionModalAtom, RESET);
  set(sharedModeAtom, RESET);
  set(sharedWithAtom, RESET);
  set(tableSelectionStateAtom, RESET);
  set(teamMemberListAtom, RESET);
  set(externalUserListAtom, RESET);
});

type TeamMemberData = Pick<TeamMemberOriginal, 'id' | 'email'> & Partial<Pick<TeamMemberOriginal, 'role' | 'group_scope' | 'super_admin'>>;
export const teamMemberListAtom = atomWithReset<Array<TeamMemberData>>([]);
export const externalUserListAtom = atomWithReset<Array<{ id: number; email: string }>>([]);

export const userListAtom = atom<Array<User>>((get) => {
  // May contains team members from other groups that the user may not have permission to get data from team list API
  const prevSelectedTeamMemberList = get(sharedWithAtom).members;
  const currentTeamMemberList = get(teamMemberListAtom);
  const currentTeamMemberIdMap = currentTeamMemberList.reduce<Record<number, boolean>>((acc, { id }) => {
    acc[id] = true;
    return acc;
  }, {});
  const teamMemberFromOtherGroups = prevSelectedTeamMemberList.filter(({ id }) => !currentTeamMemberIdMap[id]);

  const teamMemberList = [...currentTeamMemberList, ...teamMemberFromOtherGroups].map<TeamMember>((member) => ({
    ...member,
    id: generateRowId.teamMember(member.id),
    teamMemberId: member.id,
    type: 'team_member',
  }));

  const externalUserList = get(externalUserListAtom).map<ExternalUser>((user) => ({
    id: generateRowId.externalUser(user.id),
    userId: user.id,
    type: 'external_user',
    email: user.email,
  }));

  return ([] as Array<User>).concat(teamMemberList, externalUserList);
});
