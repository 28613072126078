import React, { Fragment } from 'react';

import { Box, CSSWithMultiValues } from '@chakra-ui/react';
import escapeRegExp from 'lodash/escapeRegExp';

import colors from '@/design/theme/colors';

type HighlightPropsType = {
  children: string | null | undefined;
  keyword?: string;
  sx?: CSSWithMultiValues;
};

function Highlight(props: HighlightPropsType) {
  const { children, keyword, sx } = props;

  if (!children) {
    return null;
  }

  if (!keyword) {
    return <>{children}</>;
  }

  const filteredKeyword = escapeRegExp(keyword);

  const regexp = new RegExp(`${filteredKeyword}`, 'ig');
  const matchResult = [...children.matchAll(regexp)];

  if (matchResult.length === 0) {
    return <>{children}</>;
  }

  const splitResult = children.split(regexp);

  const resultJoined = splitResult.map((data, index) => {
    const matchData = matchResult[index];

    return (
      <Fragment key={index}>
        {data}
        {matchData && (
          <Box
            as="span"
            data-testid="highlight"
            p="1px"
            borderRadius="2px"
            backgroundColor={colors.highlight.light}
            color={colors.white}
            sx={sx}
          >
            {matchData[0]}
          </Box>
        )}
      </Fragment>
    );
  });

  return <>{resultJoined}</>;
}

export default Highlight;
