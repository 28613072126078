import { z } from 'zod';

import { commonResponseSchema } from '@/services/schemas';

export const deleteSurveyResponseSchema = z.object({});
export type DeleteSurveyResponse = z.infer<typeof deleteSurveyResponseSchema>;

export const deleteSurveyErrorSchema = commonResponseSchema.merge(
  z.object({
    result: z.union([z.literal(40403), z.literal(41403), z.literal(40404)]),
  }),
);

export type DeleteSurveyError = z.infer<typeof deleteSurveyErrorSchema>;
