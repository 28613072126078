import React from 'react';

import styled from '@emotion/styled';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;

  & h4 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    color: rgb(51, 51, 51);
  }

  & button {
    align-self: flex-start;
    padding: 0;
    margin-top: 0px;
    font-size: 21px;
    font-weight: bold;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    cursor: pointer;
    background: transparent;
    border: 0;
    opacity: 0.2;
    &:hover {
      color: #000;
      opacity: 0.5;
    }
  }
`;

type ModalHeaderProps = { title: string; closeModal?: () => void; children?: React.ReactNode };

const ModalHeader = ({ title, closeModal, children }: ModalHeaderProps) => {
  return (
    <Header>
      <h4>{title}</h4>
      {children}
      {closeModal && (
        <button aria-hidden="true" type="button" onClick={closeModal}>
          ×
        </button>
      )}
    </Header>
  );
};

export default ModalHeader;
