import { Box } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';

import { RippleInput } from '@/design';
import colors from '@/design/theme/colors';
import { ActionBar } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/EditMode/ActionBar';
import { FieldTitle } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/EditMode/FieldTitle';
import { Highlighter } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/EditMode/Highlighter';
import { LABEL_MAX_LENGTH, PLACEHOLDER_MAX_LENGTH } from '@/modules/ServiceDesk/SupportForm/Editor/constants';
import { useCurrentOpenFieldAtom } from '@/modules/ServiceDesk/SupportForm/Editor/hooks/useEditingForm';

type CustomerNameFieldEditModeProps = {
  isRequireProps: UseFormRegisterReturn;
  labelProps: UseFormRegisterReturn;
  onSave: () => void;
  placeholderProps: UseFormRegisterReturn;
};

export function CustomerNameFieldEditMode({ isRequireProps, labelProps, onSave, placeholderProps }: CustomerNameFieldEditModeProps) {
  const currentOpenField = useCurrentOpenFieldAtom();
  const { t } = useTranslation();
  return (
    <Box w="100%" p="0px 36px 12px" position="relative">
      <Highlighter />
      <FieldTitle title={t('support-forms:customerName')} tooltip={t('support-forms:asCustomerName')} />
      <RippleInput
        autoFocus={currentOpenField?.autoFocusType === 'label'}
        id="edit-customer-name-title"
        aria-describedby="edit-customer-name-title"
        maxLength={LABEL_MAX_LENGTH}
        variant="flushed"
        sx={{
          caretColor: colors.blue[100],
        }}
        fontSize="12px"
        size="sm"
        type="text"
        placeholder="This field is for customer name"
        _placeholder={{
          fontStyle: 'italic',
          fontWeight: 'normal',
          fontSize: '14px',
          color: 'dark.40',
        }}
        mb="12px"
        {...labelProps}
      />
      <RippleInput
        autoFocus={currentOpenField?.autoFocusType === 'input'}
        id="edit-customer-name-placeholder"
        aria-describedby="edit-customer-name-placeholder"
        maxLength={PLACEHOLDER_MAX_LENGTH}
        variant="editPlaceholder"
        size="sm"
        type="text"
        placeholder={t('support-forms:addPlaceholderText')}
        mb="12px"
        {...placeholderProps}
      />
      <ActionBar isDisabledRequired isShowDeleteButton={false} switchProps={isRequireProps} onSave={onSave} />
    </Box>
  );
}
