import axios from 'axios';
import { z } from 'zod';

import { checkResponse } from '@/services/utils';

import { GranularControlMap } from './types';

const method = 'PATCH';

const getUrl = (teamId: number) => {
  return `/api/web/v1/teams/${teamId}/granular_controls`;
};

const schema = z.object({});

const execute = (teamId: number, payload: GranularControlMap) =>
  checkResponse(axios.request({ method, url: getUrl(teamId), data: payload }), schema);

/**
 * Update granular control settings for a team
 *
 * @see https://splashtop.atlassian.net/wiki/spaces/CS/pages/1186398482/Update+Team+Granular+Controls+API
 */
export const updateTeamGranularControlService = {
  getUrl,
  method,
  execute,
};
