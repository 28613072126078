import { useRouter } from 'next/router';

import { useTranslation } from 'next-i18next';

import { ErrorModal } from '@/modules/ServiceDesk/SupportForm/Editor';
import type { ErrorResponse } from '@/services/common/types';

type ErrorSectionProps = {
  apiError: ErrorResponse | null;
  toFormListPage: () => void;
};

export const ErrorSection = ({ apiError, toFormListPage }: ErrorSectionProps) => {
  const router = useRouter();
  const { t } = useTranslation();
  switch (apiError?.statusCode) {
    case 40422:
    case 40404:
      return <ErrorModal isOpen message={t('support-forms:targetNotExist')} onConfirm={toFormListPage} />;
    case 40403:
    case 41403:
      return (
        <ErrorModal
          isOpen
          message={t('support-session:errorModal.message')}
          onConfirm={() => {
            router.replace('/');
          }}
        />
      );
    default:
      return null;
  }
};
