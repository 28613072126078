import { parameterControl } from '@/feature/toggle';
import { ResponseData } from '@/services/common/types';
import { isSuccess, pureInstance } from '@/services/utils';

import { SupportSessionLogs } from './retrieveSupportSessionLogs';

export type SupportSessionEvent = {
  support_session_id: number;
  time: string;
  event_id: 90001;
  subject: string;
  info: string;
};

type InsertSupportSessionLogsParams = {
  support_session_logs: Array<SupportSessionEvent>;
};

export type InsertSupportSessionLogsResponse = {
  support_session_logs: SupportSessionLogs;
};

export function insertSupportSessionLogs(token: string, params: InsertSupportSessionLogsParams) {
  return new Promise<InsertSupportSessionLogsResponse>((resolve, reject) => {
    pureInstance
      .post<ResponseData & { data: InsertSupportSessionLogsResponse }>(
        `${parameterControl.getParameter('logServiceUrl')}/api/v1/support_session_log`,
        params,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}
