import { useState } from 'react';

import { Collapse } from '@chakra-ui/react';
import { motion } from 'framer-motion';

type HorizontalCollapseProps = {
  isOpen: boolean;
  children: React.ReactNode;
  onAnimateComplete?: () => void;
};

export const HorizontalCollapse = ({ children, isOpen, onAnimateComplete }: HorizontalCollapseProps) => {
  const [hidden, setHidden] = useState(!isOpen);
  return (
    <motion.div
      hidden={hidden}
      initial={false}
      transition={{ duration: 0.2 }}
      onAnimationStart={() => setHidden(false)}
      onAnimationComplete={() => {
        onAnimateComplete?.();
        setHidden(!isOpen);
      }}
      animate={{ width: isOpen ? '100%' : 0 }}
      style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        position: 'absolute',
        right: '0',
        top: '0',
      }}
    >
      <Collapse in={isOpen && !hidden} animateOpacity>
        {children}
      </Collapse>
    </motion.div>
  );
};
