import { useQuery } from '@tanstack/react-query';

import { useTeamId } from '@/models/TeamInformation';
import { Group, getGroupList } from '@/services/group';

type UseComputerListQueryProps<T> = {
  select?: (data: Array<Group>) => T;
  enabled?: boolean;
};

export const useGroupListQuery = <TData = Array<Group>>({ select, enabled = true }: UseComputerListQueryProps<TData> = {}) => {
  const teamId = useTeamId();
  return useQuery({
    queryKey: ['policy_assign', 'group_list', teamId],
    queryFn: () =>
      getGroupList(teamId, {
        group_type: 'computer',
        members_count: false,
        computers_count: false,
        admins_count: false,
        member_status: [],
      }),
    enabled,
    select,
  });
};
