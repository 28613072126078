import axios from 'axios';

import type { AdvancedThreatControlProfileType, ScanProfileType } from '@/modules/Antivirus/AntivirusPolicyEditor/AntivirusPolicyForm';
import type { ScanExclusionList } from '@/modules/Antivirus/AntivirusPolicyEditor/FieldScanExclusions';
import type { SelectDay } from '@/modules/Antivirus/AntivirusPolicyEditor/FieldScheduleScans';
import type { AntiPhishingExclusionList } from '@/modules/Antivirus/AntivirusPolicyEditor/FieldTrafficScanning';
import type { ResponseData, ResponseErrorReason } from '@/services/common/types';
import { isSuccess } from '@/services/utils';

type OnAccessScanDefaultAction = 1 | 3 | 7; // 1: Deny Access, 3: Disinfect, 7: Move to Quarantine
type AdvancedThreatControlDefaultAction = 1 | 2 | 3; // 1: Block, 2: Disinfect, 3: Take no action
type OnDemandScanAction = 1 | 3 | 7; // 1: Deny Access, 3: Disinfect, 7: Move to Quarantine

export type SettingsType = {
  direct_policies: {
    on_access_scans: {
      enabled: boolean;
      scan_profile: ScanProfileType;
      default_action: OnAccessScanDefaultAction;
    };
    advanced_threat_control: {
      enabled: boolean;
      scan_profile: AdvancedThreatControlProfileType;
      default_action: AdvancedThreatControlDefaultAction;
    };
    device_scan: {
      enabled: boolean;
      targets: {
        cd_dvd_media: boolean;
        usb_storage: boolean;
      };
    };
    antiphishing: {
      enabled: boolean;
      skip_urls: AntiPhishingExclusionList;
    };
    traffic_scanning: boolean;
    anti_exploit?: boolean;
    silent_mode: boolean;
    firewall: {
      enabled: boolean;
      settings: {
        block_port_scans: boolean;
        ics: boolean;
      };
    };
    assign_uninstallation_password: {
      enabled: boolean;
      password: string;
    };
  };
  configuration: {
    on_demand_scans: boolean;
    on_demand_scan_action: OnDemandScanAction;
    quick_scan_tasks: [
      {
        hour: number;
        minute: number;
        days: Array<SelectDay> | [];
        enabled: boolean;
      },
    ];
    full_scan_tasks: [
      {
        hour: number;
        minute: number;
        days: Array<SelectDay> | [];
        enabled: boolean;
      },
    ]; // same with quick_scan_tasks
    scan_exclusions: ScanExclusionList;
  };
};

export type AntivirusPolicySummary =
  | {
      id: number;
      name: string;
      group_admin_id: number;
      default: boolean;
      created_at: string;
    }
  | Record<string, never>;

export type ScanSchedule = 'daily' | 'weekly' | 'firstDay';
export type FormValues = {
  policyName: string;
  groupAdmin: number | undefined;
  scanProfileType: ScanProfileType;
  onAccessScan: boolean;
  onAccessScanDefaultAction: OnAccessScanDefaultAction;
  advancedThreatControl: boolean;
  advancedThreatControlDefaultAction: AdvancedThreatControlDefaultAction;
  onDemandScan: boolean;
  onDemandScanAction: OnDemandScanAction;
  quickScan: boolean;
  quickScanSchedule: ScanSchedule;
  quickScanSelectedDay: Array<SelectDay>;
  quickScanTime: Date;
  fullScan: boolean;
  fullScanSchedule: ScanSchedule;
  fullScanSelectedDay: Array<SelectDay>;
  fullScanTime: Date;
  deviceScanning: boolean;
  deviceCDVCDMedia: boolean;
  deviceUSBStorage: boolean;
  scanExclusionList: ScanExclusionList;
  showFirewallOption: boolean;
  blockPortScans: boolean;
  allowICS: boolean;
  trafficScanning: boolean;
  advanceAntiExpolit?: boolean;
  antiPhishing: boolean;
  antiPhishingExclusionList: AntiPhishingExclusionList;
  hasUninstallationPassword: boolean;
  password: string;
  silentMode: boolean;
};

export function getAntivirusPolicyList(teamId: number) {
  return new Promise<Array<AntivirusPolicySummary>>((resolve, reject) => {
    axios
      .get<ResponseData & { data: Array<AntivirusPolicySummary> }>(`/api/web/v1/teams/${teamId}/antivirus/policies`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export type AntivirusPolicyDetail = {
  id: string;
  name: string;
  default: boolean;
  group_admin_id: number;
  settings: SettingsType;
};

export type AntivirusPolicyFormPayload = {
  name: string;
  software: string; // Currently only support bitdefender2
  group_admin_id?: number;
  settings: SettingsType;
};

export function createAntivirusPolicy(teamId: number, payload: AntivirusPolicyFormPayload) {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .post<ResponseData & ResponseErrorReason>(`/api/web/v1/teams/${teamId}/antivirus/policies`, payload)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function updateAntivirusPolicy(teamId: number, policyId: string, payload: AntivirusPolicyFormPayload) {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .patch<ResponseData & ResponseErrorReason>(`/api/web/v1/teams/${teamId}/antivirus/policies/${policyId}`, payload)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function setDefaultAntivirusPolicy(teamId: number, policyId: number) {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .patch<ResponseData & ResponseErrorReason>(`/api/web/v1/teams/${teamId}/antivirus/policies/set_default`, {
        id: policyId,
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function getAntivirusPolicyDetail(teamId: number, policyId: string) {
  return new Promise<AntivirusPolicyDetail>((resolve, reject) => {
    axios
      .get<ResponseData & { data: AntivirusPolicyDetail }>(`/api/web/v1/teams/${teamId}/antivirus/policies/${policyId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export function deleteAntivirusPolicy(teamId: number, policyId: number) {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .delete<ResponseData & ResponseErrorReason>(`/api/web/v1/teams/${teamId}/antivirus/policies/${policyId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export type AssignedComputer = {
  id: number;
  name: string;
  group_id: string;
  version: string;
  online_status: boolean;
};

export function getAssignedComputers(teamId: number, policyId: number) {
  return new Promise<Array<AssignedComputer>>((resolve, reject) => {
    axios
      .get<ResponseData & { data: Array<AssignedComputer> }>(`/api/web/v1/teams/${teamId}/antivirus/policies/${policyId}/computers`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}
