import { useContext } from 'react';

import { RoleSwitchContext } from '../components/RoleSwitch/RoleSwitchContext';

/**
 * Get the `RoleSwitchContext` value.
 */
export function useRoleSwitchContext() {
  return useContext(RoleSwitchContext);
}
