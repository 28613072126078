import { forwardRef } from 'react';

import { Flex, HStack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';

import { RippleBodyText02, RippleDelete, RippleDivider, RippleIconButton, RippleOK, RippleSwitch } from '@/design';

type ActionBarProps = {
  isDisabledRequired?: boolean;
  isShowDeleteButton?: boolean;
  isShowRequired?: boolean;
  onDelete?: () => void;
  onSave?: () => void;
  switchProps?: UseFormRegisterReturn;
};

export const ActionBar = forwardRef<HTMLInputElement, ActionBarProps>(
  (
    { isDisabledRequired = false, isShowDeleteButton = true, isShowRequired = true, onDelete, onSave, switchProps }: ActionBarProps,
    ref,
  ) => {
    const { t } = useTranslation();
    return (
      <>
        <RippleDivider />
        <Flex justify="space-between" mt="12px">
          <HStack gap="12px">
            {isShowRequired && (
              <>
                <RippleSwitch ref={ref} {...switchProps} isDisabled={isDisabledRequired} />
                <RippleBodyText02>{t('common:required')}</RippleBodyText02>
              </>
            )}
          </HStack>
          <HStack gap="4px">
            {isShowDeleteButton && (
              <RippleIconButton
                variant="ghost"
                boxSize="32px"
                aria-label="delete"
                icon={<RippleDelete color="neutral.300" />}
                onClick={onDelete}
              />
            )}
            <RippleIconButton variant="ghost" boxSize="32px" aria-label="submit" icon={<RippleOK color="green.100" />} onClick={onSave} />
          </HStack>
        </Flex>
      </>
    );
  },
);
