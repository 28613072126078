/**
 * team_info schemas
 *
 * @see {@link https://splashtop.atlassian.net/wiki/spaces/CS/pages/1199016007/Key+-+team_info}
 */
import { z } from 'zod';

import { isValidateDateTime } from '@/utils/datetime';

import { TeamInfoQuery } from './types';

export const teamInfoKeySchema = z.enum([
  'license_info',
  'expires_at',
  'is_expired',
  'upsell_premium',
  'can_upgrade_premium',
  'can_current_user_upgrade_premium',
  'can_trial_premium',
  'can_current_user_trial_premium',
  'trial_premium_expired_at',
  'upsell_emm',
  'can_buy_emm',
  'can_current_user_buy_emm',
  'can_trial_emm',
  'can_current_user_trial_emm',
  'trial_emm_expired_at',
  'feature_id',
  'feature_capacity',
]);

export const licenseInfoSchema = z
  .object({
    end_users: z.number(),
    technicians: z.number(),
    end_users_count: z.number(),
    technicians_count: z.number(),
    occupied_end_users_count: z.number(),
    occupied_technicians_count: z.number(),
  })
  .nullable();

const datetimeStringSchema = z.string().refine((value) => isValidateDateTime(value));

const teamInfoSchemaShape = {
  license_info: licenseInfoSchema,
  expires_at: datetimeStringSchema,
  is_expired: z.boolean(),

  // Premium upsell related
  /** Whether should upsell premium or not */
  upsell_premium: z.boolean(),
  /** Whether the current team can upgrade premium or not */
  can_upgrade_premium: z.boolean(),
  /** Whether the current user can upgrade premium or not */
  can_current_user_upgrade_premium: z.boolean(),
  /** Whether the current team can trial premium or not */
  can_trial_premium: z.boolean(),
  /** Whether the current user can trial premium or not */
  can_current_user_trial_premium: z.boolean(),
  /** The expired time to premium, format: `yyyy-mm-dd hh:mm:ss` */
  trial_premium_expired_at: datetimeStringSchema.nullable(),

  // Emm upsell related
  /** Whether should upsell emm or not */
  upsell_emm: z.boolean(),
  can_buy_emm: z.boolean(),
  can_current_user_buy_emm: z.boolean(),
  can_trial_emm: z.boolean(),
  can_current_user_trial_emm: z.boolean(),
  trial_emm_expired_at: datetimeStringSchema.nullable(),

  feature_id: z.number().nullable(),
  feature_capacity: z.number().nullable(),
} as const;

export const teamInfoFullSchema = z.object(teamInfoSchemaShape);

export function getTeamInfoSchema<Q extends TeamInfoQuery>(query: Q) {
  if (!query.team_info) {
    return z.object({ team_info: z.never().optional() });
  }

  const shape = query.team_info.reduce<Partial<typeof teamInfoSchemaShape>>((acc, key) => {
    return { ...acc, [key]: teamInfoSchemaShape[key] };
  }, {});

  return z.object({ team_info: z.object(shape) });
}
