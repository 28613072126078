import { useMemo } from 'react';

import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { GetPluginFunc, OTablePlugin } from '@/components/ObservableTable/types';
import { OTableRowTypeValues } from '@/components/ObservableTable/useObservableTable';
import { makeFilterPipe } from '@/components/ObservableTable/utils/filter';

type Filter = {
  type: OTableRowTypeValues;
  accessor: string;
  matches: Array<string>;
};
type Filters = Array<Filter>;
type FilterHashMap = Record<string, boolean>;
export type FilterParams = {
  filters: Filters;
  filterHashMap: FilterHashMap;
};

type UseObservableFilterProps = {
  initFilters?: Filters;
};

type OnFilterChanged = (filters: Filters) => void;
type OnToggleFilter = (type: OTableRowTypeValues, accessor: string, match: string) => void;
type OnToggleOneFilter = (type: OTableRowTypeValues, accessor: string, match: string) => void;
type GetFilterMatches = (type: OTableRowTypeValues, accessor: string) => Filter['matches'] | null;

type UseObservableFilterResult = {
  filterPlugin: OTablePlugin;
  onFilterChanged: OnFilterChanged;
  onToggleFilter: OnToggleFilter;
  onToggleOneFilter: OnToggleOneFilter;
  onResetFilters: () => void;
  getFilterMatches: GetFilterMatches;
};

function getFilterKey(type: OTableRowTypeValues, accessor: string, match: string) {
  return `${type}-${accessor}-${match}`;
}

export function formatFiltersHashMap(filters: Filters) {
  return (
    filters.reduce<FilterHashMap>((acc, filter) => {
      filter.matches.forEach((match) => {
        acc[getFilterKey(filter.type, filter.accessor, match)] = true;
      });
      return acc;
    }, {}) ?? {}
  );
}

function useObservableFilter(props: UseObservableFilterProps = {}): UseObservableFilterResult {
  return useMemo(() => {
    const filterHashMap = props.initFilters ? formatFiltersHashMap(props.initFilters) : {};
    const initParams: FilterParams = {
      filters: props.initFilters ?? [],
      filterHashMap,
    };

    const filterSubject = new BehaviorSubject<FilterParams>(initParams);
    const getPlugin: GetPluginFunc = (tableResult) => filterSubject.pipe(map(makeFilterPipe(tableResult)));

    const onFilterChanged: OnFilterChanged = (filters) => {
      const value = filterSubject.getValue();
      const filterHashMap = formatFiltersHashMap(filters);

      filterSubject.next({
        ...value,
        filters,
        filterHashMap,
      });
    };

    const onToggleFilter: OnToggleFilter = (type, accessor, match) => {
      const value = filterSubject.getValue();

      const targetFilterKey = getFilterKey(type, accessor, match);
      const nextIsFiltered = !value.filterHashMap[targetFilterKey];
      const nextFilterHashMap = {
        ...value.filterHashMap,
        [targetFilterKey]: nextIsFiltered,
      };

      const targetFilterIndex = value.filters.findIndex((filter) => filter.accessor === accessor);

      let nextFilters;
      if (targetFilterIndex === -1) {
        nextFilters = [
          ...value.filters,
          {
            type,
            accessor,
            matches: [match],
          },
        ];
      } else {
        const targetFilter = value.filters[targetFilterIndex];

        const nextMatches = nextIsFiltered
          ? [...targetFilter.matches, match]
          : targetFilter.matches.filter((innerMatch) => innerMatch !== match);
        const nextFilter = {
          ...targetFilter,
          matches: nextMatches,
        };

        nextFilters = value.filters.map((filter) => (filter.accessor === accessor ? nextFilter : filter));
      }

      filterSubject.next({
        ...value,
        filterHashMap: nextFilterHashMap,
        filters: nextFilters,
      });
    };

    const onToggleOneFilter: OnToggleOneFilter = (type, accessor, match) => {
      const value = filterSubject.getValue();

      const targetFilterKey = getFilterKey(type, accessor, match);
      const nextIsFiltered = !value.filterHashMap[targetFilterKey];

      const nextFilterHashMap = {
        [targetFilterKey]: nextIsFiltered,
      };

      let nextFilters: Filters = [];
      const targetFilter = value.filters[0];
      if (type !== targetFilter?.type || targetFilter?.accessor !== accessor || targetFilter?.matches?.[0] !== match) {
        nextFilters = [
          {
            type,
            accessor,
            matches: [match],
          },
        ];
      }

      filterSubject.next({
        ...value,
        filterHashMap: nextFilterHashMap,
        filters: nextFilters,
      });
    };

    function onResetFilters() {
      filterSubject.next({
        ...filterSubject.getValue(),
        filterHashMap: {},
        filters: [],
      });
    }

    const getFilterMatches: GetFilterMatches = (type, accessor) => {
      const value = filterSubject.getValue();

      const targetFilter = value.filters.find((filter) => filter.type === type && filter.accessor === accessor);

      return targetFilter?.matches ? targetFilter.matches : null;
    };

    const filterPlugin: OTablePlugin = {
      type: 'filter' as const,
      getPlugin,
    };

    return {
      filterPlugin,
      onFilterChanged,
      onToggleFilter,
      onToggleOneFilter,
      onResetFilters,
      getFilterMatches,
    };
  }, []); // eslint-disable-line
}

export default useObservableFilter;
