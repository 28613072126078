import { useTranslation } from 'next-i18next';

import { QuestionnaireFieldKindMap } from '@/services/serviceDesk/surveys/type';

export const useFieldList = () => {
  const { t } = useTranslation();

  return [
    { label: t('survey:short_text_field'), kind: QuestionnaireFieldKindMap.shortText },
    { label: t('survey:long_text_field'), kind: QuestionnaireFieldKindMap.longText },
    { label: t('survey:combo_box'), kind: QuestionnaireFieldKindMap.comboBox },
    { label: t('survey:radio_button'), kind: QuestionnaireFieldKindMap.radioButton },
    { label: t('survey:check_box'), kind: QuestionnaireFieldKindMap.checkbox },
  ];
};
