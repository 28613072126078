import { z } from 'zod';

import { commonResponseSchema } from '@/services/schemas';

export const updateSurveyResultSchema = z.object({
  brand_image_upload_url: z.string().nullable(),
});

export type UpdateSurveyResult = z.infer<typeof updateSurveyResultSchema>;

export const updateSurveyErrorSchema = commonResponseSchema.merge(
  z.object({
    result: z.union([z.literal(40403), z.literal(40404), z.literal(41403), z.literal(40422), z.literal(40404), z.literal(40409)]),
  }),
);
