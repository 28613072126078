import axios from 'axios';

import { ResponseData } from '@/services/common/types';
import { isSuccess } from '@/services/utils';

type GetSupportSessionLogTokenResponse = {
  support_session_id: number;
  access_token: string;
  token_ttl: number;
};

export function getSupportSessionLogToken(teamId: number, supportSessionId: number) {
  return new Promise<GetSupportSessionLogTokenResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: GetSupportSessionLogTokenResponse }>(
        `/api/web/v1/teams/${teamId}/service_desk/support_sessions/${supportSessionId}/log_token`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}
