import { Policy } from '@/services/teams/emm_policies';

import { createPreparedDefaultPolicy } from '../utils';
import { useCreatePolicyMutation } from './useCreatePolicyMutation';

/**
 * Create default policy for specific platform
 */
export const useCreateDefaultPolicy = () => {
  const createPolicyMutation = useCreatePolicyMutation();
  return (platform: Policy['platform'], active?: boolean) => {
    const preparedDefaultPolicy = createPreparedDefaultPolicy(platform);
    return createPolicyMutation.mutateAsync({
      parent_id: null,
      super_root: true,
      platform: platform,
      policy_kind: 'policy',
      name: preparedDefaultPolicy.name,
      active: active ?? preparedDefaultPolicy.active,
      description: preparedDefaultPolicy.description,
    });
  };
};
