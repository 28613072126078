import axios from 'axios';

import { checkResponse, pureInstance } from '@/services/utils';

import { ViewSurvey, viewSurveySchema } from './types';

/**
 * Purpose: For technician to preview/edit/clone survey
 */
export function viewSurvey({ teamId, surveyId }: { teamId: number; surveyId: string }) {
  return checkResponse<ViewSurvey>(axios.get(`/api/web/v1/teams/${teamId}/service_desk/surveys/${surveyId}`), viewSurveySchema);
}

/**
 * Purpose: For end user to fill survey
 */
export function viewSurveyWithoutAuth({ supportSessionCode }: { supportSessionCode: string }) {
  return checkResponse<ViewSurvey>(pureInstance.get(`/api/web/v1/teams/service_desk/surveys/${supportSessionCode}`), viewSurveySchema);
}
