import React from 'react';

import { Flex, Image } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = ({ imageSrcType = 'white' }) => {
  const imageSrc = imageSrcType === 'white' ? 'Icon16px_loading_W100.svg' : 'Icon16px_loading_N100.svg';
  return (
    <Flex as="span" animation={`${spin} 2s linear infinite`}>
      <Image src={`/next/images/oobe/${imageSrc}`} alt="spinner" />
    </Flex>
  );
};

export default Spinner;
