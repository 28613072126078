import { TeamKind } from '@/models/TeamInformation';
import { TeamInformation } from '@/utils/useTeamInformation';

export function shouldShowCurrentSessions(teamInformation: TeamInformation | null, sosTeamInformation: TeamInformation | null) {
  const stbCanShow = teamInformation && !teamInformation.isExpired && teamInformation.memberStatus === 'enabled';
  const sosCanShow = sosTeamInformation && !sosTeamInformation.isExpired && sosTeamInformation.memberStatus === 'enabled';

  return stbCanShow || sosCanShow;
}

export function shouldShowVulnerabilityScore(teamInformation: TeamInformation | null) {
  return teamInformation?.role !== 'member' && teamInformation?.seatPermissions?.vulnerabilityScore;
}

export function shouldShowInventory(teamInformation: TeamInformation | null) {
  if (!teamInformation) return false;
  return teamInformation.teamPermissions.premierPack && teamInformation.role !== 'member';
}

/**
 * @deprecated use `shouldShowPreferencePolicy_new` instead
 */
export function shouldShowPreferencePolicy(teamInformation: TeamInformation | null) {
  if (!teamInformation) return false;

  return (
    ['sba', 'srs', 'msp', 'splashtop', 'ste_custom'].includes(teamInformation.currentTeam) &&
    (teamInformation?.role === 'manager' || teamInformation?.role === 'owner')
  );
}

/**
 * @param teamKind Value from `useTeamInformation` (src/models/TeamInformation)
 * @param isManager {boolean} Value from `useTeamRole`
 */
export function shouldShowPreferencePolicy_new(teamKind: TeamKind, isManager: boolean) {
  return ['sba', 'srs', 'msp', 'splashtop', 'ste_custom'].includes(teamKind) && isManager;
}
