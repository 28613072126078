import type { ModalOffsetInfo } from './contexts';

export const modalOffsetInfoDefault: ModalOffsetInfo = { height: 400, top: 10, isMobile: false };

/**
 * Put it into query key list then will show FullPageLoading while querying
 */
export const queryMainKey = 'teamSettings';

export const mutationKeyMap = {
  featureState: [queryMainKey, 'mutateSetting'],
} as const;

export const fileTransferMode = {
  /**
   * means both, will get this if the team has not set the value
   */
  default_both: '0',
  uploadOnly: '1',
  downloadOnly: '2',
  both: '3',
} as const;

export const tourKeys = {
  showMultipleTeamsTour: 'splashtop__teamSettings__showMultipleTeamsTour',
  showDifferentAccessesTour: 'splashtop__teamSettings__showDifferentAccessesTour',
} as const;

export const queryTargetKeys = {
  serviceDesk: 'service_desk',
} as const;
