import { Box, Flex, HStack, Icon } from '@chakra-ui/react';
import type { BoxProps, StackProps, StyleProps } from '@chakra-ui/react';

import {
  RippleArrowDown,
  RippleArrowRight,
  RippleBodyText02,
  RippleCheckbox,
  RippleComputerEmptyGroupIcon,
  RippleHeading07,
  RippleHeading09,
} from '@/design';
import { featureControl } from '@/feature/toggle';

import { CellContainer } from '../components';
import { HighlightSearchKeyword } from '../components/HighlightSearchKeyword';
import { baseColumnWidthMap } from '../constants';

const FT_NEW_DEVICE_ICON = featureControl.getToggle('CLE_53__Computer_Device_Icon_Change');

type GroupHeaderProps = {
  checkbox?: React.JSX.Element;
  sx?: { box?: StyleProps; sharedContent?: StyleProps; name?: StyleProps; tail?: StyleProps };
  isExpanded: boolean;
  name: string;
  count: number;
  onClick: () => void;
};

export function GroupHeader({ sx, isExpanded, name, count, onClick, checkbox }: GroupHeaderProps): React.JSX.Element {
  const sharedProps: BoxProps & StackProps = {
    className: 'computerList__row__groupHeader',
    h: '100%',
    cursor: 'pointer',
    borderBottom: '1px solid',
    borderBottomColor: 'neutral.60',
    borderTop: '1px solid',
    borderTopColor: 'neutral.60',
    onClick,
    ...sx?.sharedContent,
  };

  return (
    <>
      <Box flexBasis="content" position="sticky" left="0px" bgColor="neutral.10" w="max-content" {...sx?.box}>
        <HStack
          {...sharedProps}
          spacing="8px"
          p="8px"
          pl="16px"
          borderLeft="1px solid"
          borderLeftColor="neutral.60"
          alignItems="center"
          borderTopLeftRadius="4px"
          borderBottomLeftRadius={isExpanded ? '0px' : '4px'}
        >
          {checkbox && (
            <Flex alignItems="center" onClick={(e) => e.stopPropagation()}>
              {checkbox}
            </Flex>
          )}

          {isExpanded ? <RippleArrowDown /> : <RippleArrowRight />}

          {/* NOTE: maxW for name due to scrolling with sticky, maybe there's better way to resolve it */}
          <RippleHeading07 w="100%" maxW="400px" isTruncated wordBreak="break-all" whiteSpace="initial" {...sx?.name}>
            <HighlightSearchKeyword>{name}</HighlightSearchKeyword>
          </RippleHeading07>

          <Flex alignItems="center" bg="neutral.60" margin="0 8px" padding="0 6px" minWidth="20px" minHeight="20px" flexShrink={0}>
            <RippleHeading09>{count}</RippleHeading09>
          </Flex>
        </HStack>
      </Box>
      <Box {...sharedProps} flexGrow="1" flexShrink="0" />
      <Box position="sticky" right="0px" bgColor="neutral.10" {...sx?.tail}>
        <Box
          {...sharedProps}
          flexShrink="0"
          w="8px"
          borderRight="1px solid"
          borderRightColor="neutral.60"
          borderBottomRightRadius={isExpanded ? '0px' : '4px'}
          borderTopRightRadius="4px"
        />
      </Box>
    </>
  );
}

type EmptyGroupItemProps = { offset: number; checkbox?: boolean };
export function EmptyGroupItem({ offset, checkbox = true }: EmptyGroupItemProps): React.JSX.Element {
  return (
    <Flex w="100%">
      {checkbox && (
        <CellContainer
          w={`${baseColumnWidthMap.checkbox}px`}
          position="sticky"
          left={`${offset}px`}
          borderLeft="1px"
          borderLeftColor="neutral.60"
          borderBottomLeftRadius="4px"
          pl="16px"
          zIndex={2}
        >
          <Flex pr="16px" alignItems="center" position="sticky" left={`${offset}px`} zIndex={2}>
            <RippleCheckbox isDisabled />
          </Flex>
        </CellContainer>
      )}
      <CellContainer
        position="sticky"
        left={`${offset + (checkbox ? baseColumnWidthMap.checkbox : 0)}px`}
        zIndex={2}
        {...(checkbox ? {} : { borderLeft: '1px', borderLeftColor: 'neutral.60', borderBottomLeftRadius: '4px', pl: '26px' })}
      >
        <Flex alignItems="center" ml="24px">
          {FT_NEW_DEVICE_ICON ? <RippleComputerEmptyGroupIcon /> : <EmptyComputerIcon />}

          {/* TODO: i18n */}
          <RippleBodyText02 ml="16px">No computers</RippleBodyText02>
        </Flex>
      </CellContainer>
      <CellContainer flexGrow="1" flexShrink="0" />
      <CellContainer
        position="sticky"
        right={`${offset}px`}
        w="4px"
        p="0"
        borderRight="1px"
        borderRightColor="neutral.60"
        borderBottomRightRadius="4px"
      />
    </Flex>
  );
}

function EmptyComputerIcon(): React.JSX.Element {
  return (
    <Icon boxSize="32px" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="12.5" y="25.5" width="8" height="3" fill="#999999" />
      <rect x="10.5" y="29.5" width="12" height="1" fill="#B0B0B0" />
      <path d="M12.5 28.5H20.5L22.5 29.5H10.5L12.5 28.5Z" fill="#C6C6C6" />
      <rect x="1.5" y="3.5" width="30" height="22" rx="1" stroke="#B0B0B0" strokeLinecap="round" />
    </Icon>
  );
}
