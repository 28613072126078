import type { TFunction } from 'i18next';

const composeTranslation = (t: TFunction, i18nKey: string, interpolationKey: string, component: React.ReactNode) => {
  const replaceToken = '___splashtop___';
  const confirmMessage = t(i18nKey, { [interpolationKey]: replaceToken });
  const replaceIndex = confirmMessage.indexOf(replaceToken);

  return (
    <>
      {confirmMessage.substring(0, replaceIndex)}
      {component}
      {confirmMessage.substring(replaceIndex + replaceToken.length)}
    </>
  );
};

export default composeTranslation;
