export const nestedJoin = <T extends Record<string, string | number>>(source: Array<T>, mainKey: keyof T) => {
  return source.map((item) => item[mainKey]).join(',');
};

export const nestedSum = <T extends Record<string, number>>(source: Array<T>, mainKey: keyof T) => {
  return source.map((item) => item[mainKey]).reduce((a, c) => a + c, 0);
};

export const fromMBtoGB = (volume?: number) => {
  if (!volume) return 0;
  return Number((volume / 1024).toFixed(2));
};

export const fromMHzToGHz = (frequency?: number) => {
  if (!frequency) return 0;
  return Number((frequency / 1000).toFixed(2));
};

export const validateSerialNumber = (source?: string | null) => {
  if (source === 'System Serial Number') {
    return undefined;
  } else return source?.trim();
};
