import { useCallback } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';

export const MaxWakeComputersCount = 10;

export const wakeComputerModalAtom = atomWithReset<
  | {
      state: 'Idle';
    }
  | {
      state: 'Selector';
      wakeType: 'auto' | 'manual';
      wakeComputer: {
        id: number;
        name: string;
      };
      selectedComputerIdsHashMap: Record<string, boolean>;
    }
  | {
      state: 'RequestSent';
      sentComputers: {
        id: number;
        name: string;
      };
    }
  | {
      state: 'Unavailable';
    }
>({
  state: 'Idle',
});

export const useWakeComputerModalData = () => {
  const wakeData = useAtomValue(wakeComputerModalAtom);
  const { state } = wakeData;

  if (state === 'Idle' || state === 'Unavailable') {
    return {
      state,
    };
  } else if (state === 'Selector') {
    const { wakeComputer, wakeType, selectedComputerIdsHashMap } = wakeData;
    const selectedComputerIds = Object.keys(selectedComputerIdsHashMap).filter(
      (id) => !id.startsWith('groupName:') && selectedComputerIdsHashMap[id],
    );
    const selectedComputersCount = selectedComputerIds.length;
    const canSubmit =
      wakeType === 'auto' || (wakeType === 'manual' && selectedComputersCount > 0 && selectedComputersCount <= MaxWakeComputersCount);

    return {
      state,
      wakeType,
      wakeComputer,
      selectedComputerIds,
      selectedComputerIdsHashMap,
      canSubmit,
    };
  }

  const { sentComputers } = wakeData;
  return {
    state,
    sentComputers,
  };
};

export const useWakeComputerModalActions = () => {
  const setModalAtom = useSetAtom(wakeComputerModalAtom);
  const resetModalAtom = useResetAtom(wakeComputerModalAtom);

  const handleOpenSelector = useCallback(
    ({ wakeComputer }: { wakeComputer: { id: number; name: string } }) =>
      setModalAtom(() => ({
        state: 'Selector',
        wakeType: 'auto',
        wakeComputer,
        selectedComputerIdsHashMap: {},
      })),
    [setModalAtom],
  );

  const handleOpenRequestSent = useCallback(
    () =>
      setModalAtom((update) => {
        const { state } = update;
        if (state === 'Selector') {
          return {
            state: 'RequestSent',
            sentComputers: update.wakeComputer,
          };
        }

        return update;
      }),
    [setModalAtom],
  );

  const handleOpenUnavailable = useCallback(() => setModalAtom({ state: 'Unavailable' }), [setModalAtom]);

  const handleSelectId = useCallback(
    (selectedIds: Record<string, boolean>) =>
      setModalAtom((update) => ({
        ...update,
        selectedComputerIdsHashMap: selectedIds,
      })),
    [setModalAtom],
  );

  const handleChangeType = useCallback(
    (wakeType: 'auto' | 'manual') =>
      setModalAtom((update) => ({
        ...update,
        wakeType,
      })),
    [setModalAtom],
  );

  return {
    handleClose: resetModalAtom,
    handleOpenSelector,
    handleOpenRequestSent,
    handleOpenUnavailable,
    handleChangeType,
    handleSelectId,
  };
};
