import { z } from 'zod';

import { commonResponseSchema } from '@/services/schemas';

import { channelIdSchema } from '../type';

// Assign channels api response
export const assignSurveySchema = z.object({
  success: z.array(channelIdSchema),
  fail: z.array(channelIdSchema),
});

export type AssignSurvey = z.infer<typeof assignSurveySchema>;

export const assignSurveyErrorSchema = commonResponseSchema.merge(
  z.object({
    result: z.union([z.literal(40404), z.literal(40403), z.literal(41403), z.literal(40422)]),
  }),
);
