import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useMemo } from 'react';

import { Box, Flex, HStack } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import type { ColumnDef, FilterFns, Row } from '@tanstack/react-table';
import { isBefore, parseISO, subDays } from 'date-fns';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';

import { LS_KEY_PCP_CSRF_TOKEN } from '@/authentication';
import { RippleCheckbox, RippleHyperLink, RippleIconButton, RippleRemoteSessionStart, RippleTooltip, RippleTypography } from '@/design';
import { tableColumn } from '@/models/Computer';
import { useTeamExpiration, useTeamId } from '@/models/TeamInformation';
import { categoryKeySchema } from '@/modules/PolicyCategories/type';
import { WindowsUpdatesStatusCellContent } from '@/modules/WindowsUpdates/Columns/WindowsUpdatesStatusCell';
import type { ComputerData } from '@/services/computers';
import { ComputerHeaderSortMenu } from '@/showcase';
import { computeWindowsUpdateStatusInfoType, getOSIconType, getStreamerVersion, sortAntivirusState } from '@/utils/computers';
import { useTime } from '@/utils/formatDateTime';

import {
  CellContainer,
  CellLeftScrollOverlay,
  CellRightScrollOverlay,
  ColumnPermissionsContext,
  ComputerNameContent,
  FROM_OTHER_GROUP_ID,
  type FilterLastOnlineValue,
  HeaderLeftScrollOverlay,
  HeaderRightScrollOverlay,
  HighlightSearchKeyword,
  IPContent,
  MoreActions,
  TableHead,
  checkPermission,
  computeComputerIconState,
  groupNameMapAtom,
  leftOffsetAtom,
  sortGroup,
  sortingGroupForGroupViewAtom,
  useCanRemoteControl,
  useComputerCheckboxProps,
  useConnectModal,
  useHeaderCheckboxProps,
  useShowScrollOverlay,
  useShowWaitingUpdate,
  viewModeAtom,
} from '../ComputerList';
import { sharedFilterFnName } from '../ComputerList/constants';
import { AntivirusStatusCellContent } from './AntivirusStatusCellContent';
import { columnIdMap, columnWidthMap } from './constants';
import { usePolicyMapQuery } from './usePolicyMap';
import { useGetPolicyName } from './utils';

const columnHelper = createColumnHelper<ComputerData>();

/**
 * Hooks to create a computer list column configurations for React table
 * Please also update the column view settings in `src/modules/Computer/AllComputers/useConfigurableColumns.tsx`
 */
export function useColumns_SBA(): Array<ColumnDef<ComputerData, any>> {
  const { t } = useTranslation();
  const router = useRouter();
  const columnPermissions = useContext(ColumnPermissionsContext);

  const groupNameMap = useAtomValue(groupNameMapAtom);
  const { formatDateTime } = useTime();
  const policyMapQuery = usePolicyMapQuery();
  const getPolicyName = useGetPolicyName();
  const { isTeamExpired } = useTeamExpiration();

  const columns = useMemo(
    () => [
      // Checkbox
      columnHelper.display({
        id: columnIdMap.checkbox,
        size: columnWidthMap.checkbox,
        enableSorting: false,
        enableHiding: false,
        header: function RenderHeader({ column }) {
          const leftOffset = useAtomValue(leftOffsetAtom);
          const checkboxProps = useHeaderCheckboxProps();

          return (
            <Flex w={`${column.getSize()}px`} px="16px" bgColor="neutral.10" alignItems="center" position="sticky" left={`${leftOffset}px`}>
              <RippleCheckbox data-testid="checkbox-all" {...checkboxProps} />
            </Flex>
          );
        },
        cell: function RenderCell({ column, row }) {
          const leftOffset = useAtomValue(leftOffsetAtom);
          const checkboxProps = useComputerCheckboxProps(String(row.original.id));

          return (
            <CellContainer
              w={`${column.getSize()}px`}
              borderLeft="1px"
              borderLeftColor="neutral.60"
              position="sticky"
              alignItems="center"
              left={`${leftOffset}px`}
              pl="16px"
              zIndex={2}
            >
              <RippleCheckbox data-testid={`checkbox-${row.original.id}`} {...checkboxProps} />
            </CellContainer>
          );
        },
      }),
      // Name
      columnHelper.accessor(columnIdMap.name, {
        id: columnIdMap.name,
        size: columnWidthMap.name,
        enableHiding: false,
        filterFn: sharedFilterFnName as keyof FilterFns,
        header: function RenderHeader({ column }) {
          const viewMode = useAtomValue(viewModeAtom);
          const showOverlay = useShowScrollOverlay('left');
          const leftOffset = useAtomValue(leftOffsetAtom);

          // for group view
          const [sortingGroup, setSortingGroup] = useAtom(sortingGroupForGroupViewAtom);
          const groupSortBy = sortingGroup.direction === 'asc' ? 'ASC' : 'DESC';
          const computerSortBy = computeComputerSortBy();

          switch (viewMode) {
            case 'computer': {
              return (
                <TableHead
                  w={`${column.getSize()}px`}
                  flexShrink={0}
                  position="sticky"
                  left={`${leftOffset + columnWidthMap.checkbox}px`}
                  bgColor="neutral.10"
                  pl="60px"
                  isSortable
                  sortState={column.getIsSorted()}
                  onClick={column.getToggleSortingHandler()}
                >
                  {showOverlay && <HeaderLeftScrollOverlay />}
                  {t('common:name')}
                </TableHead>
              );
            }
            case 'group': {
              return (
                <TableHead
                  w={`${column.getSize()}px`}
                  flexShrink={0}
                  position="sticky"
                  left={`${leftOffset + columnWidthMap.checkbox}px`}
                  bgColor="neutral.10"
                  pl="24px"
                >
                  <ComputerHeaderSortMenu
                    groupSortBy={groupSortBy}
                    onGroupSortByAsc={() => setSortingGroup({ direction: 'asc' })}
                    onGroupSortByDesc={() => setSortingGroup({ direction: 'desc' })}
                    computerSortBy={computerSortBy}
                    onComputerSortByAsc={() => column.toggleSorting(false)}
                    onComputerSortByDesc={() => column.toggleSorting(true)}
                  >
                    {showOverlay && <HeaderLeftScrollOverlay />}
                    {t('common:name')}
                  </ComputerHeaderSortMenu>
                </TableHead>
              );
            }
          }

          function computeComputerSortBy() {
            switch (column.getIsSorted()) {
              case false:
                return 'UNDEFINED';
              case 'asc':
                return 'ASC';
              case 'desc':
                return 'DESC';
            }
          }
        },
        cell: function RenderCell({ column, getValue, row }) {
          const { id: computerId, version, online_status, connected } = row.original;
          const computerIcon = getOSIconType(version);

          const viewMode = useAtomValue(viewModeAtom);
          const showOverlay = useShowScrollOverlay('left');
          const leftOffset = useAtomValue(leftOffsetAtom);

          const showWaitingUpdate = useShowWaitingUpdate(String(computerId));
          const state = computeComputerIconState({
            connected: Boolean(connected),
            online: Boolean(online_status),
            waitingUpdate: showWaitingUpdate,
          });

          return (
            <CellContainer
              w={`${column.getSize()}px`}
              position="sticky"
              left={`${leftOffset + columnWidthMap.checkbox}px`}
              zIndex={2}
              pl={viewMode === 'group' ? '24px' : undefined}
            >
              {showOverlay && <CellLeftScrollOverlay />}
              <ComputerNameContent computerId={computerId} name={getValue()} type={computerIcon} state={state} />
            </CellContainer>
          );
        },
      }),
      // Host Name
      columnHelper.accessor(columnIdMap.host_name, {
        id: columnIdMap.host_name,
        size: columnWidthMap.host_name,
        header: ({ column }) => {
          return (
            <TableHead
              w={`${column.getSize()}px`}
              flexShrink={0}
              isSortable
              sortState={column.getIsSorted()}
              onClick={column.getToggleSortingHandler()}
            >
              {t('computer:deviceName')}
            </TableHead>
          );
        },
        cell: ({ column, getValue }) => {
          return (
            <CellContainer w={`${column.getSize()}px`}>
              <RippleTooltip label={getValue()}>
                <Box noOfLines={2}>
                  <HighlightSearchKeyword>{getValue()}</HighlightSearchKeyword>
                </Box>
              </RippleTooltip>
            </CellContainer>
          );
        },
      }),
      // Computer Owner
      columnHelper.accessor(tableColumn.computerOwner.getAccessor(t), {
        id: columnIdMap.computerOwner,
        size: columnWidthMap.computerOwner,
        sortingFn: (a, b) => {
          return tableColumn.computerOwner.sortingFn(a.original, b.original);
        },
        header: ({ column }) => {
          return (
            <TableHead
              w={`${column.getSize()}px`}
              flexShrink={0}
              isSortable
              sortState={column.getIsSorted()}
              onClick={column.getToggleSortingHandler()}
              bgColor="neutral.10"
            >
              {tableColumn.computerOwner.getLabel(t)}
            </TableHead>
          );
        },
        cell: ({ column, getValue }) => {
          const value = getValue();

          return (
            <CellContainer w={`${column.getSize()}px`}>
              <RippleTooltip label={value}>
                <Box noOfLines={1}>
                  <HighlightSearchKeyword>{value}</HighlightSearchKeyword>
                </Box>
              </RippleTooltip>
            </CellContainer>
          );
        },
      }),
      // Access
      columnHelper.accessor(tableColumn.access.getAccessor(), {
        id: columnIdMap.access,
        size: columnWidthMap.access,
        sortingFn: (a, b) => {
          return tableColumn.access.sortingFn(a.original, b.original);
        },
        header: ({ column }) => {
          return (
            <TableHead
              w={`${column.getSize()}px`}
              flexShrink={0}
              isSortable
              sortState={column.getIsSorted()}
              onClick={column.getToggleSortingHandler()}
              bgColor="neutral.10"
            >
              {tableColumn.access.getLabel(t)}
            </TableHead>
          );
        },
        cell: ({ column, getValue }) => {
          const value = getValue();
          if (value === undefined) return <CellContainer w={`${column.getSize()}px`} />;

          const label = tableColumn.access.getCellContent(t, value);

          return (
            <CellContainer w={`${column.getSize()}px`}>
              <RippleTooltip label={label}>
                <Box noOfLines={1}>
                  <HighlightSearchKeyword>{label}</HighlightSearchKeyword>
                </Box>
              </RippleTooltip>
            </CellContainer>
          );
        },
      }),
      // Group ID
      columnHelper.accessor((row) => row.group_id ?? FROM_OTHER_GROUP_ID, {
        id: columnIdMap.group_id,
        size: columnWidthMap.group_id,
        minSize: 0, // NOTE: tanstack/table has default minSize: 20
        filterFn: 'equals', // Keep ability of filtering by group id
        enableSorting: false,
        enableGlobalFilter: false,
        // Hidden column
        header: () => null,
        cell: () => null,
      }),
      ...checkPermission(
        columnPermissions.group,
        columnHelper.accessor((row) => groupNameMap[row.group_id ?? FROM_OTHER_GROUP_ID], {
          id: columnIdMap.groupName,
          size: columnWidthMap.groupName,
          sortingFn: (a, b) => {
            function transformToGroupItem(row: Row<ComputerData>) {
              const groupId = row.original.group_id ?? FROM_OTHER_GROUP_ID;
              return { id: String(groupId), name: groupNameMap[groupId] };
            }

            return sortGroup(transformToGroupItem(a), transformToGroupItem(b));
          },
          header: ({ column }) => {
            return (
              <TableHead
                w={`${column.getSize()}px`}
                flexShrink={0}
                isSortable
                sortState={column.getIsSorted()}
                onClick={column.getToggleSortingHandler()}
              >
                {t('computer:group')}
              </TableHead>
            );
          },
          cell: ({ column, getValue }) => {
            return (
              <CellContainer w={`${column.getSize()}px`}>
                <RippleTooltip label={getValue()}>
                  <Box noOfLines={2}>
                    <HighlightSearchKeyword>{getValue()}</HighlightSearchKeyword>
                  </Box>
                </RippleTooltip>
              </CellContainer>
            );
          },
        }),
      ),
      // Streamer version
      columnHelper.accessor((row) => getStreamerVersion(row.version), {
        id: columnIdMap.version,
        size: columnWidthMap.version,
        header: ({ column }) => {
          return (
            <TableHead
              w={`${column.getSize()}px`}
              flexShrink={0}
              isSortable
              sortState={column.getIsSorted()}
              onClick={column.getToggleSortingHandler()}
              bgColor="neutral.10"
            >
              {t('computer:column.streamerVer.title')}
            </TableHead>
          );
        },
        cell: ({ column, getValue }) => {
          return (
            <CellContainer w={`${column.getSize()}px`}>
              <RippleTooltip label={getValue()}>
                <Box noOfLines={2}>
                  <HighlightSearchKeyword>{getValue()}</HighlightSearchKeyword>
                </Box>
              </RippleTooltip>
            </CellContainer>
          );
        },
      }),
      // OS
      columnHelper.accessor(tableColumn.os.getAccessor(), {
        id: columnIdMap.os,
        size: columnWidthMap.os,
        header: ({ column }) => {
          return (
            <TableHead
              w={`${column.getSize()}px`}
              flexShrink={0}
              isSortable
              sortState={column.getIsSorted()}
              onClick={column.getToggleSortingHandler()}
              bgColor="neutral.10"
            >
              {tableColumn.os.getLabel(t)}
            </TableHead>
          );
        },
        cell: ({ column, getValue }) => {
          return (
            <CellContainer w={`${column.getSize()}px`}>
              <RippleTooltip label={getValue()}>
                <Box noOfLines={2}>
                  <HighlightSearchKeyword>{getValue()}</HighlightSearchKeyword>
                </Box>
              </RippleTooltip>
            </CellContainer>
          );
        },
      }),
      // OS Build
      columnHelper.accessor(tableColumn.osBuild.getAccessor(), {
        id: columnIdMap.osBuild,
        size: columnWidthMap.osBuild,
        header: ({ column }) => {
          return (
            <TableHead
              w={`${column.getSize()}px`}
              flexShrink={0}
              isSortable
              sortState={column.getIsSorted()}
              onClick={column.getToggleSortingHandler()}
              bgColor="neutral.10"
            >
              {tableColumn.osBuild.getLabel(t)}
            </TableHead>
          );
        },
        cell: ({ column, getValue }) => {
          return (
            <CellContainer w={`${column.getSize()}px`}>
              <RippleTooltip label={getValue()}>
                <Box noOfLines={2}>
                  <HighlightSearchKeyword>{getValue()}</HighlightSearchKeyword>
                </Box>
              </RippleTooltip>
            </CellContainer>
          );
        },
      }),
      // Architecture
      columnHelper.accessor(tableColumn.architecture.getAccessor(), {
        id: columnIdMap.architecture,
        size: columnWidthMap.architecture,
        enableSorting: tableColumn.architecture.enableSorting,
        header: ({ column }) => {
          return (
            <TableHead
              w={`${column.getSize()}px`}
              flexShrink={0}
              isSortable
              sortState={column.getIsSorted()}
              onClick={column.getToggleSortingHandler()}
              bgColor="neutral.10"
            >
              {tableColumn.architecture.getLabel(t)}
            </TableHead>
          );
        },
        cell: ({ column, getValue }) => {
          return (
            <CellContainer w={`${column.getSize()}px`}>
              <RippleTooltip label={getValue()}>
                <Box noOfLines={2}>
                  <HighlightSearchKeyword>{getValue()}</HighlightSearchKeyword>
                </Box>
              </RippleTooltip>
            </CellContainer>
          );
        },
      }),
      // EMM Policy
      ...checkPermission(
        columnPermissions.emmPolicy,
        columnHelper.accessor(getPolicyName, {
          id: columnIdMap.emm_policy,
          size: columnWidthMap.emm_policy,
          header: ({ column }) => {
            return (
              <TableHead
                w={`${column.getSize()}px`}
                flexShrink={0}
                isSortable
                sortState={column.getIsSorted()}
                onClick={column.getToggleSortingHandler()}
                bgColor="neutral.10"
              >
                {t('computer:endpoint_policy')}
              </TableHead>
            );
          },
          cell: ({ column, getValue, row }) => {
            const policy = policyMapQuery.serverPolicies?.[Number(row.id)];
            const isCustomized = policy?.policy_kind === 'server';
            const isFollowGroup = row.original.support_emm_policy && !policy;

            if (isFollowGroup) {
              return (
                <CellContainer w={`${column.getSize()}px`}>
                  <RippleTooltip label={getValue()}>
                    <Box noOfLines={2}>
                      {t('computer:follow_group')}
                      <RippleTypography variant="body02" color="neutral.200">
                        <HighlightSearchKeyword>{getValue()}</HighlightSearchKeyword>
                      </RippleTypography>
                    </Box>
                  </RippleTooltip>
                </CellContainer>
              );
            }

            return (
              <CellContainer w={`${column.getSize()}px`}>
                <RippleTooltip label={getValue()}>
                  <HStack>
                    <Box noOfLines={2}>
                      <HighlightSearchKeyword>{getValue()}</HighlightSearchKeyword>
                    </Box>
                    {isCustomized && (
                      // TODO: Change it to new RippleChip component when new component ready
                      <Box
                        p="2px 8px"
                        border="1px solid"
                        borderColor="blue.60"
                        borderRadius="16px"
                        bg="blue.10"
                        flexShrink="0"
                        cursor={isTeamExpired ? 'not-allowed' : 'pointer'}
                        onClick={() => {
                          if (isTeamExpired) {
                            return;
                          }
                          if (policy) {
                            router.push(`/w/policy/${policy.id}/${categoryKeySchema.Enum.streamer_preference}`);
                          }
                        }}
                      >
                        <RippleTypography variant="body03">{t('computer:overridden')}</RippleTypography>
                      </Box>
                    )}
                  </HStack>
                </RippleTooltip>
              </CellContainer>
            );
          },
        }),
      ),
      // Preference Policy
      ...checkPermission(
        columnPermissions.preferencePolicy,
        columnHelper.accessor((row) => row.preference_policy?.name ?? '', {
          id: columnIdMap.preference_policy,
          size: columnWidthMap.preference_policy,
          header: ({ column }) => {
            return (
              <TableHead
                w={`${column.getSize()}px`}
                flexShrink={0}
                isSortable
                sortState={column.getIsSorted()}
                onClick={column.getToggleSortingHandler()}
                bgColor="neutral.10"
              >
                {t('preferencePolicy:title')}
              </TableHead>
            );
          },
          cell: ({ column, getValue }) => {
            return (
              <CellContainer w={`${column.getSize()}px`}>
                <RippleTooltip label={getValue()}>
                  <Box noOfLines={2}>
                    <HighlightSearchKeyword>{getValue()}</HighlightSearchKeyword>
                  </Box>
                </RippleTooltip>
              </CellContainer>
            );
          },
        }),
      ),
      // IP Address (WAN)
      columnHelper.accessor(tableColumn.ipAddressWAN.getAccessor(), {
        id: columnIdMap.pubip,
        size: columnWidthMap.pubip,
        header: ({ column }) => {
          return (
            <TableHead
              w={`${column.getSize()}px`}
              flexShrink={0}
              isSortable
              sortState={column.getIsSorted()}
              onClick={column.getToggleSortingHandler()}
              bgColor="neutral.10"
            >
              {tableColumn.ipAddressWAN.getLabel(t)}
            </TableHead>
          );
        },
        cell: ({ column, getValue }) => {
          return (
            <CellContainer w={`${column.getSize()}px`}>
              <IPContent value={getValue()} />
            </CellContainer>
          );
        },
      }),
      // IP Address (LAN)
      columnHelper.accessor(tableColumn.ipAddressLAN.getAccessor(), {
        id: columnIdMap.local_ip,
        size: columnWidthMap.local_ip,
        header: ({ column }) => {
          return (
            <TableHead
              w={`${column.getSize()}px`}
              flexShrink={0}
              isSortable
              sortState={column.getIsSorted()}
              onClick={column.getToggleSortingHandler()}
              bgColor="neutral.10"
            >
              {tableColumn.ipAddressLAN.getLabel(t)}
            </TableHead>
          );
        },
        cell: ({ column, getValue }) => {
          return (
            <CellContainer w={`${column.getSize()}px`}>
              <IPContent value={getValue()} />
            </CellContainer>
          );
        },
      }),
      // Note
      ...checkPermission(
        columnPermissions.notes,
        columnHelper.accessor(columnIdMap.note, {
          id: columnIdMap.note,
          size: columnWidthMap.note,
          enableSorting: false,
          header: ({ column }) => {
            return (
              <TableHead
                w={`${column.getSize()}px`}
                flexShrink={0}
                isSortable
                sortState={column.getIsSorted()}
                onClick={column.getToggleSortingHandler()}
                bgColor="neutral.10"
              >
                {t('computer:note')}
              </TableHead>
            );
          },
          cell: ({ column, getValue }) => {
            return (
              <CellContainer w={`${column.getSize()}px`}>
                <RippleTooltip label={getValue()}>
                  <Box noOfLines={2} w={`${column.getSize()}px`}>
                    <HighlightSearchKeyword>{getValue()}</HighlightSearchKeyword>
                  </Box>
                </RippleTooltip>
              </CellContainer>
            );
          },
        }),
      ),
      // Antivirus status
      ...checkPermission(
        columnPermissions.antivirusStatus,
        columnHelper.accessor(columnIdMap.antivirus_scan_status, {
          id: columnIdMap.antivirus_scan_status,
          size: columnWidthMap.antivirus_scan_status,
          enableGlobalFilter: false,
          sortingFn: (a, b) => {
            return sortAntivirusState(a.original.antivirus_scan_status, b.original.antivirus_scan_status);
          },
          header: ({ column }) => {
            return (
              <TableHead
                w={`${column.getSize()}px`}
                flexShrink={0}
                isSortable
                sortState={column.getIsSorted()}
                onClick={column.getToggleSortingHandler()}
                bgColor="neutral.10"
                justifyContent="center"
              >
                {t('computer:status')}
              </TableHead>
            );
          },
          cell: ({ column, getValue, row }) => {
            const computerId = row.original.id;
            const status = getValue() ?? null;
            const supportAntivirus = Boolean(row.original.support_antivirus);
            return (
              <CellContainer w={`${column.getSize()}px`} justifyContent="center">
                {supportAntivirus && <AntivirusStatusCellContent computerId={computerId} status={status} />}
              </CellContainer>
            );
          },
        }),
      ),
      // Windows updates
      ...checkPermission(
        columnPermissions.windowsUpdates,
        columnHelper.accessor(
          (row) => {
            const { policy = null, status = null, important_count = null, optional_count = null } = row.windows_updates ?? {};
            return computeWindowsUpdateStatusInfoType(policy, status, important_count, optional_count);
          },
          {
            id: columnIdMap.windows_updates,
            size: columnWidthMap.windows_updates,
            enableGlobalFilter: false,
            header: ({ column }) => {
              return (
                <TableHead
                  w={`${column.getSize()}px`}
                  flexShrink={0}
                  isSortable
                  sortState={column.getIsSorted()}
                  onClick={column.getToggleSortingHandler()}
                  bgColor="neutral.10"
                  justifyContent="center"
                >
                  {t('computer:column.updates.title')}
                </TableHead>
              );
            },
            cell: ({ column, getValue, row }) => {
              const computerId = row.original.id;
              const supportWindowsUpdate = Boolean(row.original.support_windows_updates);
              const statusInfoType = getValue();
              const importantCount = row.original.windows_updates?.important_count ?? null;
              const optionalCount = row.original.windows_updates?.optional_count ?? null;

              return (
                <CellContainer w={`${column.getSize()}px`} justifyContent="center">
                  {supportWindowsUpdate && (
                    <Link data-testid={`updates-${computerId}`} href={`/property/updates/${computerId}`}>
                      {/* TODO: icon button ? */}
                      <WindowsUpdatesStatusCellContent
                        statusInfoType={statusInfoType}
                        importantCount={importantCount}
                        optionalCount={optionalCount}
                      />
                    </Link>
                  )}
                </CellContainer>
              );
            },
          },
        ),
      ),
      // Alert count
      ...checkPermission(
        columnPermissions.alertCount,
        columnHelper.accessor(columnIdMap.alerts_count, {
          id: columnIdMap.alerts_count,
          size: columnWidthMap.alerts_count,
          enableGlobalFilter: false,
          sortingFn: (a, b) => {
            const aValue = a.original.alerts_count;
            const bValue = b.original.alerts_count;

            if (typeof aValue !== 'number' && typeof bValue === 'number') return -1;
            if (typeof aValue === 'number' && typeof bValue !== 'number') return 1;
            if (typeof aValue !== 'number' && typeof bValue !== 'number') return 0;

            if (typeof aValue === 'number' && typeof bValue === 'number') {
              if (aValue > bValue) return 1;
              if (aValue < bValue) return -1;
            }

            return 0;
          },
          header: ({ column }) => {
            return (
              <TableHead
                w={`${column.getSize()}px`}
                flexShrink={0}
                isSortable
                sortState={column.getIsSorted()}
                onClick={column.getToggleSortingHandler()}
                bgColor="neutral.10"
              >
                {t('computer:column.alerts.title')}
              </TableHead>
            );
          },
          cell: function RenderCell({ column, getValue, row }) {
            const teamId = useTeamId();

            const value = getValue();
            const hasAlertValue = typeof value === 'number';
            const computerId = row.original.id;

            const csrfToken = localStorage.getItem(LS_KEY_PCP_CSRF_TOKEN) ?? '';
            const filterQueries = new URLSearchParams({
              authenticity_token: csrfToken,
              'pcp_logs_alert_logs_form[team_id]': String(teamId),
              'pcp_logs_alert_logs_form[created_at]': '90',
              'pcp_logs_alert_logs_form[alert_log_status]': 'unsolved',
              'pcp_logs_alert_logs_form[server_id]': String(computerId),
            });

            return (
              <CellContainer w={`${column.getSize()}px`}>
                {hasAlertValue ? (
                  <Link
                    data-testid={`alerts-${computerId}`}
                    href={`/property/alerts/${computerId}?${filterQueries.toString()}`}
                    legacyBehavior
                  >
                    <RippleHyperLink variant="hyperlink02">{value}</RippleHyperLink>
                  </Link>
                ) : null}
              </CellContainer>
            );
          },
        }),
      ),
      // Security layer
      ...checkPermission(
        columnPermissions.securityLayer,
        columnHelper.accessor(columnIdMap.infra_gen_status, {
          id: columnIdMap.infra_gen_status,
          size: columnWidthMap.infra_gen_status,
          enableGlobalFilter: false,
          header: ({ column }) => {
            return (
              <TableHead
                w={`${column.getSize()}px`}
                flexShrink={0}
                isSortable
                sortState={column.getIsSorted()}
                onClick={column.getToggleSortingHandler()}
                bgColor="neutral.10"
              >
                {t('computer:column.securityLayer.title')}
              </TableHead>
            );
          },
          cell: ({ column, getValue }) => {
            return (
              <CellContainer w={`${column.getSize()}px`}>
                <RippleTooltip label={getValue()}>
                  <Box noOfLines={2}>
                    <HighlightSearchKeyword>{getValue()}</HighlightSearchKeyword>
                  </Box>
                </RippleTooltip>
              </CellContainer>
            );
          },
        }),
      ),
      // Last session
      columnHelper.accessor((row) => (row.last_session ? formatDateTime(row.last_session) : null), {
        id: columnIdMap.last_session,
        size: columnWidthMap.last_session,
        header: ({ column }) => {
          return (
            <TableHead
              w={`${column.getSize()}px`}
              flexShrink={0}
              isSortable
              sortState={column.getIsSorted()}
              onClick={column.getToggleSortingHandler()}
              bgColor="neutral.10"
            >
              {t('computer:lastSession')}
            </TableHead>
          );
        },
        cell: ({ column, getValue }) => {
          return (
            <CellContainer w={`${column.getSize()}px`}>
              <RippleTooltip label={getValue()}>
                <Box noOfLines={2}>
                  <HighlightSearchKeyword>{getValue()}</HighlightSearchKeyword>
                </Box>
              </RippleTooltip>
            </CellContainer>
          );
        },
      }),
      // Last online
      columnHelper.accessor((row) => (row.last_online ? formatDateTime(row.last_online) : null), {
        id: columnIdMap.last_online,
        size: columnWidthMap.last_online,
        filterFn: (row, _columnId, filterValue: FilterLastOnlineValue) => {
          const value = row.original.last_online;

          if (typeof value !== 'string') return false;

          if (filterValue === null) return true;

          return isBefore(parseISO(formatDateTime(value)), subDays(new Date(), filterValue));
        },
        header: ({ column }) => {
          return (
            <TableHead
              w={`${column.getSize()}px`}
              flexShrink={0}
              isSortable
              sortState={column.getIsSorted()}
              onClick={column.getToggleSortingHandler()}
              bgColor="neutral.10"
            >
              {t('computer:lastOnline')}
            </TableHead>
          );
        },
        cell: ({ column, getValue }) => {
          return (
            <CellContainer w={`${column.getSize()}px`}>
              <RippleTooltip label={getValue()}>
                <Box noOfLines={2}>
                  <HighlightSearchKeyword>{getValue()}</HighlightSearchKeyword>
                </Box>
              </RippleTooltip>
            </CellContainer>
          );
        },
      }),
      // Spacer
      columnHelper.display({
        id: columnIdMap.spacer,
        size: columnWidthMap.spacer,
        header: function RenderHeader() {
          const leftOffset = useAtomValue(leftOffsetAtom);

          return <TableHead p="0" flex="1" minW={`${leftOffset}px`} bgColor="neutral.10" />;
        },
        cell: function RenderCell() {
          const leftOffset = useAtomValue(leftOffsetAtom);

          return <CellContainer p="0" flex="1" minW={`${leftOffset}px`} bgColor="white" />;
        },
      }),
      // Actions
      columnHelper.display({
        id: columnIdMap.action,
        size: columnWidthMap.action,
        enableHiding: false,
        header: function RenderHeader({ column }) {
          const showOverlay = useShowScrollOverlay('right');
          const leftOffset = useAtomValue(leftOffsetAtom);

          return (
            <TableHead w={`${column.getSize()}px`} flexShrink={0} position="sticky" right={`${leftOffset}px`} bgColor="neutral.10">
              {showOverlay && <HeaderRightScrollOverlay />}
            </TableHead>
          );
        },
        cell: function RenderCell({ column, row }) {
          const connectModal = useConnectModal();

          const showOverlay = useShowScrollOverlay('right');
          const leftOffset = useAtomValue(leftOffsetAtom);

          const canConnect = useCanRemoteControl(row.original);
          const computerId = row.original.id;
          const computerName = row.original.name;
          const note = row.original.note ?? null;
          const platform = getOSIconType(row.original.version);

          return (
            <CellContainer
              borderRight="1px"
              borderRightColor="neutral.60"
              w={`${column.getSize()}px`}
              position="sticky"
              right={`${leftOffset}px`}
              bgColor="white"
              zIndex={2}
            >
              {showOverlay && <CellRightScrollOverlay />}
              <HStack spacing="4px">
                <RippleTooltip label={t('computer:startRemoteSession')}>
                  <RippleIconButton
                    data-testid={`start-remote-session-${computerId}`}
                    aria-label="connect"
                    isDisabled={!canConnect}
                    icon={<RippleRemoteSessionStart color={canConnect ? 'blue.100' : 'neutral.80'} />}
                    onClick={() => {
                      connectModal.open({ computerId, platform });
                    }}
                  />
                </RippleTooltip>
                <MoreActions
                  computerId={computerId}
                  computerName={computerName}
                  note={note}
                  isDisabled={false}
                  computerData={row.original}
                />
              </HStack>
            </CellContainer>
          );
        },
      }),
    ],
    [t, columnPermissions, formatDateTime, groupNameMap],
  );

  // Force assign column list type here or the columnHelper will lose type safety
  return columns as Array<ColumnDef<ComputerData, any>>;
}
