import { z } from 'zod';

const hourSchema = z.enum([
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
]);

const minuteSchema = z.enum([
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59',
]);

const timeSchema = z
  .string()
  .regex(/^\d{2}:\d{2}$/)
  .refine(
    (value) => {
      const [hour, minute] = value.split(':');
      // Make sure hour and minute are valid numbers and remove leading zeros
      const parsedHour = parseInt(hour, 10);
      const parsedMinute = parseInt(minute, 10);
      if (isNaN(parsedHour) || isNaN(parsedMinute) || value.length !== 5) return false;
      return hourSchema.safeParse(parsedHour.toString()).success && minuteSchema.safeParse(parsedMinute.toString()).success;
    },
    {
      message: 'Invalid time format. Expected format: "hh:mm"',
    },
  );

export const validateTime = (time: string) => {
  return timeSchema.safeParse(time);
};

export const parseTime = (time: string) => {
  try {
    const validatedTime = validateTime(time);
    if (validatedTime.success) {
      const [hour, minute] = validatedTime.data.split(':');
      const parsedHour = parseInt(hour, 10).toString() as Hour;
      const parsedMinute = parseInt(minute, 10).toString() as Minute;
      return { hour: parsedHour, minute: parsedMinute };
    }
  } catch (e: any) {
    console.error(e);
    return null;
  }
};

export type Hour = z.infer<typeof hourSchema>;
export type Minute = z.infer<typeof minuteSchema>;
export type Day =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '22'
  | '23'
  | '24'
  | '25'
  | '26'
  | '27'
  | '28'
  | '29'
  | '30'
  | '31'
  | 'L';
export type Month = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
export type Weekday = '0' | '1' | '2' | '3' | '4' | '5' | '6'; // 0-6; 0 for Sunday
export type DayList = Array<Day>;
export type MonthList = Array<Month>;
export type WeekdayList = Array<Weekday>;
export type Period = 'daily' | 'weekly' | 'monthly';

const createRange = <T extends Hour | Minute | Day>(length: number, start = 0): Array<T> =>
  Array.from({ length }, (_, i) => (i + start).toString() as T);

export const hourList = createRange<Hour>(24);
export const minuteList = createRange<Minute>(60);
export const dayList = createRange<Day>(31, 1);

/**
 * Based on Cloud Service Cron Doc: https://splashtop.atlassian.net/wiki/spaces/CS/pages/1065386176/Schedule+and+Cron+Job+Format
 */
export type CronInfo = {
  minute: Minute;
  hour: Hour;
  day: DayList | '*'; // * for any value; L for the last day;
  month: MonthList | '*'; // * for any value
  weekday: WeekdayList | '*'; // * for any value
};

export const generateCronString = (cronInfo: CronInfo): string => {
  const { minute, hour, day, month, weekday } = cronInfo;
  return `${minute} ${hour} ${typeof day === 'string' ? day : day.join(',')} ${typeof month === 'string' ? month : month.join(',')} ${
    typeof weekday === 'string' ? weekday : weekday.join(',')
  }`;
};

export const generateCronStringForDaily = (cronInfo: Omit<CronInfo, 'day' | 'month' | 'weekday'>): string => {
  return generateCronString({ ...cronInfo, day: '*', month: '*', weekday: '*' });
};

export const generateCronStringForWeekly = (cronInfo: Omit<CronInfo, 'day' | 'month'>): string => {
  return generateCronString({ ...cronInfo, day: '*', month: '*' });
};

export const generateCronStringForMonthly = (cronInfo: Omit<CronInfo, 'month' | 'weekday'>): string => {
  return generateCronString({ ...cronInfo, month: '*', weekday: '*' });
};

export const getCronStringByPeriod = ({ cronInfo, period }: { cronInfo: CronInfo; period: Period }): string => {
  switch (period) {
    case 'daily':
      return generateCronStringForDaily(cronInfo);
    case 'weekly':
      return generateCronStringForWeekly(cronInfo);
    case 'monthly':
      return generateCronStringForMonthly(cronInfo);
    default:
      return '';
  }
};

export const getPeriodByCronInfo = (cronString: string): Period => {
  const cronArr = cronString.split(' ');
  const day = cronArr[2].split(',');
  const month = cronArr[3].split(',');
  const weekday = cronArr[4].split(',');
  if (day[0] === '*' && month[0] === '*' && weekday[0] === '*') {
    return 'daily';
  } else if (day[0] === '*' && month[0] === '*' && weekday[0] !== '*') {
    return 'weekly';
  } else if (day[0] !== '*' && month[0] === '*' && weekday[0] === '*') {
    return 'monthly';
  } else {
    console.error('Invalid cron string:', cronString);
    return 'daily';
  }
};

export const DEFAULT_DAY: DayList = ['1'];
export const DEFAULT_WEEKDAY: WeekdayList = ['1'];

export const convertCronStringToInputFormat = (
  cronString: string,
): {
  cronInfo: {
    minute: Minute;
    hour: Hour;
    days: DayList;
    weekdays: WeekdayList;
  };
  period: Period;
} => {
  const cronArr = cronString.split(' ');
  const minute = cronArr[0];
  const hour = cronArr[1];
  const days = cronArr[2].split(',');
  const weekdays = cronArr[4].split(',');

  const cronInfo = {
    minute: minute as Minute,
    hour: hour as Hour,
    days: (days[0] === '*' ? DEFAULT_DAY : days) as DayList,
    weekdays: (weekdays[0] === '*' ? DEFAULT_WEEKDAY : weekdays) as WeekdayList,
  };

  return {
    cronInfo,
    period: getPeriodByCronInfo(cronString),
  };
};
