import type { ColumnFiltersState, FilterFn, Row, SortDirection, SortingState } from '@tanstack/react-table';
import { PrimitiveAtom, atom } from 'jotai';
import { atomWithImmer } from 'jotai-immer';
import { RESET, atomWithReset } from 'jotai/utils';

import { ALL_GROUP_ID, DEFAULT_GROUP_ID, FROM_OTHER_GROUP_ID, baseColumnIdMap } from '../constants';
import { filterPlatformFn } from '../filterFns';
import type {
  ComputerItemBase,
  ExpandedState,
  FilterGroup,
  FilterLastOnlineValue,
  FilterPlatformValue,
  FilterPreferencePolicy,
  FilterSelected,
} from '../types';
import { generateGroupRowId } from '../utils';
import { groupIdListAtom } from './computerData';

export const currentVisibleRowsAtom = atom<Array<Row<ComputerItemBase>>>([]);
export const currentVisibleComputerIdListAtom = atom<Array<string>>((get) => get(currentVisibleRowsAtom).map((row) => row.id));

export const tableStateAtom = atomWithImmer<{ expandedStateAtom: PrimitiveAtom<ExpandedState> }>({
  expandedStateAtom: atom<ExpandedState>({}),
});

export const expandedStateAtom: PrimitiveAtom<ExpandedState> = atom(
  (get) => {
    const tableStateMap = get(tableStateAtom);
    return get(tableStateMap.expandedStateAtom);
  },
  (get, set, update) => {
    set(get(tableStateAtom).expandedStateAtom, update);
  },
);
export const allExpandedAtom = atom<boolean>((get) => {
  const expandedState = get(expandedStateAtom);

  const groupIdList = get(groupIdListAtom);

  return groupIdList.every((groupId) => {
    const expandKey = generateGroupRowId(groupId);
    return expandedState[expandKey] === true;
  });
});
export const toggleExpandAllGroupsAtom = atom(null, (get, set) => {
  const allExpanded = get(allExpandedAtom);

  const groupIdList = get(groupIdListAtom);

  if (allExpanded) {
    set(expandedStateAtom, {});
  } else {
    const newExpandState = groupIdList.reduce<ExpandedState>((acc, groupId) => {
      const expandKey = generateGroupRowId(groupId);
      acc[expandKey] = true;
      return acc;
    }, {});
    set(expandedStateAtom, newExpandState);
  }
});

// TODO: Use sharedFilterFn instead -- start
export const filterGroupAtom = atomWithReset<FilterGroup>(ALL_GROUP_ID);
export const filterSelectedAtom = atomWithReset<FilterSelected>(ALL_GROUP_ID);
export const filterPreferencePolicyAtom = atomWithReset<FilterPreferencePolicy>(null);
export const filterLastOnlineAtom = atomWithReset<FilterLastOnlineValue>(null);
// TODO: Use sharedFilterFn instead -- end

export const filterPlatformAtom = atomWithReset<FilterPlatformValue>([]);

export const sharedFilterFnAtom = atom<FilterFn<ComputerItemBase>>((get) => {
  const filterPlatform = get(filterPlatformAtom);

  return function sharedFilterFn(row): boolean {
    if (row.getCanExpand()) {
      // Always filter out group rows.
      // Due to all groups (including empty group) are shown in the group view,
      // we need to filter out group rows in the shared filter.
      return false;
    }

    let result = true;

    if (filterPlatform.length > 0) {
      const filterPlatformResult = filterPlatformFn(filterPlatform, row);
      result = result && filterPlatformResult;
    }

    return result;
  };
});

export const columnFilterStateAtom = atom<ColumnFiltersState>((get) => {
  const filterGroup = get(filterGroupAtom);
  const filterGroupState: ColumnFiltersState = computeFilterGroupState(filterGroup);

  const filterPreferencePolicy = get(filterPreferencePolicyAtom);
  const filterPreferencePolicyState: ColumnFiltersState =
    filterPreferencePolicy === null ? [] : [{ id: 'preference_policy', value: filterPreferencePolicy }];

  const filterLastOnline = get(filterLastOnlineAtom);
  const filterLastOnlineState: ColumnFiltersState = filterLastOnline === null ? [] : [{ id: 'last_online', value: filterLastOnline }];

  const filterPlatform = get(filterPlatformAtom);
  const hasFilterPlatform = filterPlatform.length > 0;
  const enableSharedFilterFn = hasFilterPlatform;
  const sharedFilterState = enableSharedFilterFn ? [{ id: baseColumnIdMap.name, value: true }] : [];

  return [...sharedFilterState, ...filterGroupState, ...filterPreferencePolicyState, ...filterLastOnlineState];
});
function computeFilterGroupState(filterGroup: FilterGroup): ColumnFiltersState {
  switch (filterGroup) {
    case ALL_GROUP_ID: {
      return [];
    }
    case FROM_OTHER_GROUP_ID: {
      return [{ id: 'group_id', value: FROM_OTHER_GROUP_ID }];
    }
    case DEFAULT_GROUP_ID:
    default: {
      return [{ id: 'group_id', value: Number(filterGroup) }];
    }
  }
}
export const hasFilterAtom = atom<boolean>(
  (get) => Boolean(get(columnFilterStateAtom).length > 0) || get(filterSelectedAtom) !== ALL_GROUP_ID,
);
export const resetAllFiltersAtom = atom(null, (_get, set) => {
  set(filterGroupAtom, RESET);
  set(filterSelectedAtom, RESET);
  set(filterPreferencePolicyAtom, RESET);
  set(filterLastOnlineAtom, RESET);
  set(filterPlatformAtom, RESET);
});

export const searchKeywordAtom = atomWithReset<string>('');
export const hasSearchAtom = atom<boolean>((get) => Boolean(get(searchKeywordAtom)));
export const resetSearchAtom = atom(null, (_get, set) => {
  set(searchKeywordAtom, RESET);
});

export const sortingStateAtom = atom<SortingState>([{ id: 'name', desc: false }]);
export const sortingGroupForGroupViewAtom = atom<{ direction: SortDirection }>({ direction: 'asc' });
