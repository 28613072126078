import React from 'react';

import { Box, BoxProps, forwardRef } from '@chakra-ui/react';

type TableProps = {
  children: React.ReactNode;
};

export const Table = forwardRef((props: TableProps & BoxProps, ref) => {
  const { children, ...otherProps } = props;

  return (
    <Box role="table" ref={ref} {...otherProps}>
      {children}
    </Box>
  );
});

type TableCaptionProps = {
  children: React.ReactNode;
};

export const TableCaption = forwardRef((props: TableCaptionProps & BoxProps, ref) => {
  const { children, ...otherProps } = props;

  return (
    <Box ref={ref} {...otherProps}>
      {children}
    </Box>
  );
});

type TableHeadProps = {
  children: React.ReactNode;
};

export const Thead = forwardRef((props: TableHeadProps & BoxProps, ref) => {
  const { children, ...otherProps } = props;

  return (
    <Box role="rowgroup" ref={ref} {...otherProps}>
      {children}
    </Box>
  );
});

type TableBodyProps = {
  children: React.ReactNode;
};

export const Tbody = forwardRef((props: TableBodyProps & BoxProps, ref) => {
  const { children, ...otherProps } = props;

  return (
    <Box role="rowgroup" ref={ref} {...otherProps}>
      {children}
    </Box>
  );
});

type TableFooterProps = {
  children: React.ReactNode;
};

export const Tfoot = forwardRef((props: TableFooterProps & BoxProps, ref) => {
  const { children, ...otherProps } = props;

  return (
    <Box role="rowgroup" ref={ref} {...otherProps}>
      {children}
    </Box>
  );
});

type TableColumnHeaderProps = {
  children?: React.ReactNode;
};

export const Th = forwardRef((props: TableColumnHeaderProps & BoxProps, ref) => {
  const { children, ...otherProps } = props;

  return (
    <Box role="columnheader" ref={ref} {...otherProps}>
      {children}
    </Box>
  );
});

type TableRowProps = {
  children: React.ReactNode;
  key?: string | number;
};

export const Tr = forwardRef((props: TableRowProps & BoxProps, ref) => {
  const { children, ...otherProps } = props;

  return (
    <Box role="row" ref={ref} {...otherProps}>
      {children}
    </Box>
  );
});

type TableCellProps = {
  children: React.ReactNode;
};

export const Td = forwardRef((props: TableCellProps & BoxProps, ref) => {
  const { children, ...otherProps } = props;

  return (
    <Box role="cell" ref={ref} {...otherProps}>
      {children}
    </Box>
  );
});
