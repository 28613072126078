import { Box, Center, Flex, Image } from '@chakra-ui/react';

import { RippleEdit, RippleSkeleton } from '@/design';
import { SplashtopLogo } from '@/modules/ServiceDesk/SurveyForm/Editor/FormEditor/SplashtopLogo';
import { SurveyFormScenario, SurveyFormScenarioMap } from '@/modules/ServiceDesk/SurveyForm/Editor/types';

type BrandImageProps = {
  scenario: SurveyFormScenario;
  isShowSplashtopLogo: boolean;
  customLogoSrc: string;
  editBrandImageField?: () => void;
};

export function BrandImage({ scenario, isShowSplashtopLogo, customLogoSrc, editBrandImageField }: BrandImageProps) {
  return (
    <Center
      w="100%"
      my="16px"
      py="4px"
      px="48px"
      borderRadius="4px"
      _hover={scenario === SurveyFormScenarioMap.editor ? { bg: 'blue.0', cursor: 'pointer' } : {}}
      role="group"
      onClick={editBrandImageField}
    >
      <Box position="relative">
        <Flex justify="center" align="center">
          {isShowSplashtopLogo ? <SplashtopLogo /> : <>{customLogoSrc ? <LogoImage src={customLogoSrc} /> : <LogoImageSkeleton />}</>}
        </Flex>
        {scenario === SurveyFormScenarioMap.editor && (
          <Box as="span" position="absolute" right="-32px" top="50%" transform="translateY(-50%)" opacity={0} _groupHover={{ opacity: 1 }}>
            <RippleEdit />
          </Box>
        )}
      </Box>
    </Center>
  );
}

export const LogoImage = ({ src }: { src: string }) => {
  return (
    <Flex w="320px" h="120px" justify="center" align="center">
      <Image
        src={src}
        alt="Custom Logo"
        w="100%"
        h="100%"
        maxW="fit-content"
        maxH="fit-content"
        objectFit="scale-down"
        fallback={<LogoImageSkeleton />}
      />
    </Flex>
  );
};

const LogoImageSkeleton = () => <RippleSkeleton w="320px" h="120px" />;
