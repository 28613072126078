import { VStack } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';

import { computerSharedModeMap } from '@/services/computers';

import { AddUserInput } from './AddUserInput';
import { UserList } from './UserList';
import { sharedModeAtom } from './atoms';

export function ShareWithSomeoneSection(): React.JSX.Element {
  return (
    <VStack
      alignItems="stretch"
      mt="8px"
      ml="32px"
      p="16px"
      borderRadius="4px"
      border="1px solid"
      borderColor="neutral.60"
      bgColor="neutral.10"
    >
      <AddUserInput />
      <UserList />
    </VStack>
  );
}

type ShowShareWithSomeoneSectionProps = { children?: React.ReactNode };
export function ShowShareWithSomeoneSection({ children }: ShowShareWithSomeoneSectionProps): React.JSX.Element | null {
  const sharedMode = useAtomValue(sharedModeAtom);

  if (sharedMode !== computerSharedModeMap.share_to_someone) return null;

  return <>{children}</>;
}
