import { MouseEventHandler } from 'react';

import { FormLabel } from '@chakra-ui/react';

import { RippleHeading07 } from '@/design';
import { SurveyFormScenario, SurveyFormScenarioMap } from '@/modules/ServiceDesk/SurveyForm/Editor/types';

type LabelProps = {
  onMouseDown: MouseEventHandler<HTMLLabelElement> | undefined;
  scenario: SurveyFormScenario;
  title: string;
};

export function Label({ onMouseDown, scenario, title }: LabelProps) {
  return (
    <FormLabel
      as="legend"
      _hover={scenario === SurveyFormScenarioMap.editor ? { bg: 'blue.0', cursor: 'pointer' } : {}}
      borderRadius="4px"
      w="100%"
      pl="2px"
      onMouseDown={onMouseDown}
    >
      <RippleHeading07 as="span">{title}</RippleHeading07>
    </FormLabel>
  );
}
