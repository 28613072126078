import { useEffect, useRef } from 'react';

import { useFormContext } from 'react-hook-form';

import { SurveyFormValue } from '@/modules/ServiceDesk/SurveyForm/Editor/types';

import { useCurrentOpenFieldValue } from './useCurrentOpenField';

export const useScrollToLastField = () => {
  const labelRef = useRef<HTMLInputElement | null>(null);
  const { watch } = useFormContext<SurveyFormValue>();

  const currentOpenField = useCurrentOpenFieldValue();

  const questionnaire = watch('questionnaire');

  const currentFieldIndex = questionnaire.findIndex((field) => field.uuid === currentOpenField?.uuid);
  const isLastField = currentFieldIndex === questionnaire.length - 1;

  useEffect(() => {
    if (labelRef.current && isLastField) {
      labelRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isLastField]);

  return { labelRef };
};
