import { Box, Center, VStack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleButton, RippleHeading04, RippleReadyOutline, RippleSheetAction } from '@/design';
import { useSnippetUrl } from '@/modules/ServiceDesk/SupportForm/Editor/hooks/useEditingForm';
import { Snippet } from '@/showcase/Snippet';

type SnippetSectionProps = {
  onClose: () => void;
};

export function SnippetSection({ onClose }: SnippetSectionProps) {
  const snippetUrl = useSnippetUrl();
  const { t } = useTranslation();
  return (
    <Center>
      <VStack w="552px">
        <RippleReadyOutline color="green.200" width="56px" height="56px" />
        <RippleHeading04 pt="16px" pb="32px">
          {t('support-forms:createSuccessfully')}
        </RippleHeading04>
        <Box p="24px" bg="blue.0" borderRadius="8px">
          <Snippet downloadUrl={snippetUrl} iframeInfoSectionHeight="236px" />
        </Box>
      </VStack>
      <RippleSheetAction>
        <RippleButton variant="secondary" onClick={onClose}>
          {t('common:close')}
        </RippleButton>
      </RippleSheetAction>
    </Center>
  );
}
