import styled from '@emotion/styled';
import { layout } from 'styled-system';
import type { LayoutProps } from 'styled-system';

const ModalBody = styled.main<LayoutProps>`
  display: block;
  height: auto;
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;

  ${layout};
`;

export default ModalBody;
