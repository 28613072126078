import { useEffect, useMemo } from 'react';

import { useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { tableSettingAtom } from '../../atoms';
import { fixedColumnVisibility } from '../../constants';
import type { ColumnVisibility, ConfigurableColumn, ViewMode } from '../../types';

export function useInitializeViewMode({ storageKey, hasPermission }: { storageKey: string; hasPermission: boolean }): void {
  const setTableSettingAtom = useSetAtom(tableSettingAtom);

  const storedViewModeAtom = useMemo(() => atomWithStorage<ViewMode>(storageKey, 'computer'), [storageKey]);

  useEffect(
    function initializeViewMode() {
      if (hasPermission) {
        setTableSettingAtom((draft) => {
          draft.viewModeAtom = storedViewModeAtom;
        });
      }
    },
    [hasPermission, setTableSettingAtom, storedViewModeAtom],
  );
}

export function useInitializeColumnVisibility({
  storageKey,
  configurableColumns,
}: {
  storageKey: string;
  configurableColumns: Array<ConfigurableColumn>;
}) {
  const setTableSettingAtom = useSetAtom(tableSettingAtom);

  const storedColumnVisibilityAtom = useMemo(() => {
    const defaultColumnVisibility: ColumnVisibility = configurableColumns.reduce<ColumnVisibility>(
      (acc, cur) => {
        acc[cur.columnId] = cur.defaultVisibility ?? true;
        return acc;
      },
      Object.assign({}, fixedColumnVisibility),
    );

    return atomWithStorage<ColumnVisibility>(storageKey, defaultColumnVisibility);
  }, [storageKey, configurableColumns]);

  useEffect(
    function initializeColumnVisibility() {
      setTableSettingAtom((draft) => {
        draft.columnVisibilityAtom = storedColumnVisibilityAtom;
      });
    },
    [setTableSettingAtom, storedColumnVisibilityAtom],
  );

  return storedColumnVisibilityAtom;
}
