import { useAtomValue } from 'jotai';

import { useTeamControlContext } from './useTeamControlContext';

/**
 * Get the feature state map, contains all the feature states.
 */
export function useFeatureStateMap() {
  const { atoms } = useTeamControlContext();

  return useAtomValue(atoms.teamSettingsAtom);
}
