import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { getComputerListColumnPermissions } from '@/services/computers';
import useTeamInformation from '@/utils/useTeamInformation';

import type { ColumnPermissions } from '../ComputerList';
import { canSeeGroup, canSeeNotes } from '../ComputerList';

export function useColumnPermissions(): ColumnPermissions | null {
  const teamInformation = useTeamInformation();

  const permissionQuery = useQuery({
    queryKey: ['myComputerList', 'getColumnPermissions'],
    queryFn: () => getComputerListColumnPermissions(),
    staleTime: Infinity,
  });

  const permissionMap = permissionQuery.data;

  const hasSeeGroupPermission = canSeeGroup(teamInformation, permissionMap);
  const hasSeeNotesPermission = canSeeNotes(teamInformation, permissionMap);
  const hasWebAppPermission = Boolean(permissionMap?.team_permissions.web_src);
  const hasRemoteRebootPermission = Boolean(permissionMap?.team_permissions.remote_reboot);
  const hasMemberRebootComputerPermission = Boolean(permissionMap?.team_permissions.member_reboot_computer);

  const columnPermission = useMemo<ColumnPermissions>(
    () => ({
      emmPolicy: false,
      preferencePolicy: false,
      alertCount: false,
      group: hasSeeGroupPermission,
      windowsUpdates: false,
      antivirusStatus: false,
      securityLayer: false,
      notes: hasSeeNotesPermission,
      webApp: hasWebAppPermission,
      remoteReboot: hasRemoteRebootPermission,
      memberRebootComputer: hasMemberRebootComputerPermission,
    }),
    [hasMemberRebootComputerPermission, hasRemoteRebootPermission, hasSeeGroupPermission, hasSeeNotesPermission, hasWebAppPermission],
  );

  if (permissionQuery.isFetching || typeof permissionMap === 'undefined') return null;

  return columnPermission;
}
