import { CommonMessageType, postMessageToRailsCore } from '@/utils/postMessageToRails';
import type { PostMessage } from '@/utils/postMessageToRails';

export const PostToParentTypes = {
  ...CommonMessageType,
  select_computer: 'select_computer',
  select_save: 'select_save',
} as const;

export type PostToParentTypeKeys = keyof typeof PostToParentTypes;

export type SelectedComputer = {
  name: string;
  group_id?: number | null;
  selected: true;
};

export type SelectedGroup = {
  name: string;
  selected: boolean;
};

type PostMessageToParentProps =
  | {
      type: typeof PostToParentTypes.select_computer;
      data: {
        computers: Record<string, SelectedComputer>;
        groups: Record<string, SelectedGroup>;
      };
    }
  | {
      type: typeof PostToParentTypes.select_save;
    };

const postMessageToParent: PostMessage<PostMessageToParentProps> = postMessageToRailsCore;

export default postMessageToParent;
