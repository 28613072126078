import { useEffect } from 'react';

import { Flex, VStack } from '@chakra-ui/react';
import { atom, useAtom, useSetAtom } from 'jotai';
import { Trans, useTranslation } from 'next-i18next';

import { RippleBodyText02, RippleHeading08, RippleInput, RippleRadio, RippleRadioGroup } from '@/design';

import { filterLastOnlineAtom } from '../../atoms';

const optionMap = {
  all: 'all',
  '90days': '90days',
  '30days': '30days',
  '7days': '7days',
  custom: 'custom',
} as const;

// NOTE: FilterLastOnline will unmount when FiltersDrawer closed, so use atom to keep state
const optionAtom = atom<string>(optionMap.all);
const timeTermAtom = atom<number>(0);

export function FilterLastOnline(): React.JSX.Element {
  const { t } = useTranslation();

  const setFilterLastOnline = useSetAtom(filterLastOnlineAtom);
  const [selectedOption, setSelectedOption] = useAtom(optionAtom);
  const [timeTerm, setTimeTerm] = useAtom(timeTermAtom);

  function handleChange(newValue: string): void {
    setSelectedOption(newValue);

    switch (newValue) {
      case optionMap.all: {
        setFilterLastOnline(null);
        break;
      }
      case optionMap['90days']: {
        setFilterLastOnline(90);
        break;
      }
      case optionMap['30days']: {
        setFilterLastOnline(30);
        break;
      }
      case optionMap['7days']: {
        setFilterLastOnline(7);
        break;
      }
      case optionMap.custom: {
        setFilterLastOnline(timeTerm);
      }
    }
  }

  useEffect(
    function listenTimeTermChange() {
      if (selectedOption === optionMap.custom) {
        setFilterLastOnline(timeTerm);
      }
    },
    [setFilterLastOnline, selectedOption, timeTerm],
  );

  return (
    <Flex flexDirection="column">
      <RippleHeading08 mb="8px">{t('computer:filterDrawer.lastOnline.label')}</RippleHeading08>
      <RippleRadioGroup data-testid="filter-last-online-radio-group" value={selectedOption} onChange={handleChange}>
        <VStack spacing="4px" alignItems="start">
          <RippleRadio data-testid="all-time" value={optionMap.all} my="6px">
            {t('common:allTime')}
          </RippleRadio>
          <RippleRadio data-testid="90-days" value={optionMap['90days']} my="6px">
            {t('common:moreThanDaysAgo', { digit: 90 })}
          </RippleRadio>
          <RippleRadio data-testid="30-days" value={optionMap['30days']} my="6px">
            {t('common:moreThanDaysAgo', { digit: 30 })}
          </RippleRadio>
          <RippleRadio data-testid="7-days" value={optionMap['7days']} my="6px">
            {t('common:moreThanDaysAgo', { digit: 7 })}
          </RippleRadio>
          <Flex>
            <RippleRadio data-testid="custom-days" value={optionMap.custom} my="3px" alignItems="center"></RippleRadio>
            <RippleBodyText02 as="span" ml="12px">
              <Trans
                t={t}
                i18nKey="common:moreThanDaysAgoInput"
                components={{
                  input: (
                    <RippleInput
                      data-testid="custom-days-input"
                      size="xs"
                      width="56px"
                      style={{ textAlign: 'center' }}
                      value={timeTerm}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setTimeTerm((prev) => {
                          const newValue = isNaN(Number(e.target.value)) ? prev : Number(e.target.value);
                          return newValue > 365 ? 365 : newValue;
                        });
                      }}
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === 'Enter') {
                          setSelectedOption(optionMap.custom);
                        }
                      }}
                    />
                  ),
                }}
              />
            </RippleBodyText02>
          </Flex>
        </VStack>
      </RippleRadioGroup>
    </Flex>
  );
}
