import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { TextWithActionBtn } from '@/modules/Unboxing/Mobile/TextWithActionBtn';
import { useAddComputerHandler } from '@/modules/Unboxing/hooks/useAddComputerHandler';
import { useStreamerType } from '@/modules/Unboxing/hooks/useStreamerType';
import { AddComputerBtn } from '@/modules/Unboxing/shared/AddComputerBtn';
import { AttendedStreamerSteps } from '@/modules/Unboxing/shared/AttendedStreamerSteps';
import { StreamerAccordion } from '@/modules/Unboxing/shared/StreamerAccordion';

export const ConnectTo = () => {
  const { t } = useTranslation(['setup', 'common']);
  const { isShowInstruction, isMember, handleClickAddComputer } = useAddComputerHandler();
  const type = useStreamerType();

  return (
    <>
      {type === 'both' && <StreamerAccordion isMember={isMember} onClickAddComputer={handleClickAddComputer} />}
      {type === 'unattended' && (
        <TextWithActionBtn intro={isShowInstruction ? t('setup:adminWillConfigure') : t('setup:unattendedStreamer')}>
          {!isShowInstruction && <AddComputerBtn onClick={handleClickAddComputer} />}
        </TextWithActionBtn>
      )}
      {type === 'attended' && (
        <Flex pl="20px" pr="16px" py="24px" direction="column" justify="center" align="center">
          <AttendedStreamerSteps />
        </Flex>
      )}
    </>
  );
};
