import { AddNotesModal, useAddNotesModal } from './AddNotesModal';

export function useAddNotes() {
  const addNotesModal = useAddNotesModal();

  return {
    execute: addNotesModal.open,
  };
}

export function AddNotes(): React.JSX.Element {
  return (
    <>
      <AddNotesModal />
    </>
  );
}
