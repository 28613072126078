import NextImage from 'next/image';
import type { ImageProps } from 'next/image';
import React from 'react';

import SOSAppLogo from './sos-app-logo.svg';
import SupportAppLogo from './sos-app-service-desk-logo.png';
import SplashtopLogo from './splashtop-logo.svg';

type LogoCommonProps = Omit<ImageProps, 'src' | 'srcSet' | 'alt'>;

export const Splashtop = ({ width = 128, height = 28, ...otherProps }: LogoCommonProps) => {
  return <NextImage src={SplashtopLogo} width={width} height={height} {...otherProps} alt="Splashtop Inc." />;
};

export const SOSApp = ({ width = 68, height = 68, ...otherProps }: LogoCommonProps) => {
  return <NextImage src={SOSAppLogo} width={width} height={height} {...otherProps} alt="SOS App" />;
};

export const SupportApp = ({ width = 64, height = 64, ...otherProps }: LogoCommonProps) => {
  return <NextImage src={SupportAppLogo} width={width} height={height} {...otherProps} alt="Support App" />;
};
