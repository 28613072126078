import {
  OTableGroup,
  OTableResult,
  OTableRow,
  OTableRowOrGroupList,
  OTableSourceData,
  OTableSourceGroup,
} from '@/components/ObservableTable/types';
import { SelectedGroupIds, SelectedRowIds, SelectionParams } from '@/components/ObservableTable/useObservableSelection';
import { OTableRowTypes, OTableViewStates } from '@/components/ObservableTable/useObservableTable';
import { OTableGroupHashMap } from '@/components/ObservableTable/utils/group';
import { checkIsFilterMatched } from '@/components/ObservableTable/utils/table';

const calcSelectedIdsByRows = <D extends OTableSourceData, G extends OTableSourceGroup>(
  rows: OTableRowOrGroupList<D, G>,
  select: boolean,
  groupHashMap?: OTableGroupHashMap<G>,
) => {
  return rows.reduce(
    (acc, row) => {
      if (row.type === OTableRowTypes.GROUP) {
        const isMatched = checkIsFilterMatched(row);
        if (!isMatched) {
          return acc;
        }

        const gid = row.original.id;
        acc.groups[gid] = select;

        groupHashMap?.[gid]?.rowIds?.forEach((rid) => {
          acc.rows[rid] = select;
        });
      } else {
        acc.rows[row.original.id] = select;
      }

      return acc;
    },
    { rows: {}, groups: {} } as { rows: Record<string, boolean>; groups: Record<string, boolean> },
  );
};

export const OTableSelectStates = {
  ALL: 'ALL',
  SOME: 'SOME',
  EMPTY: 'EMPTY',
  NONE: 'NONE',
} as const;
export type OTableSelectStateKeys = keyof typeof OTableSelectStates;
export type OTableSelectStateValues = (typeof OTableSelectStates)[OTableSelectStateKeys];

export type OnToggleRowSelected = (id: string) => void;
export type OnToggleGroupSelected = (gid: string, isSelected?: boolean) => void;
export type OnToggleSelectedAll = () => void;
export type OTableSelectionResult = {
  headSelectState: OTableSelectStateValues;
  onToggleRowSelected: OnToggleRowSelected;
  onToggleGroupSelected: OnToggleGroupSelected;
  onToggleSelectedAll: OnToggleSelectedAll;
  headFilteredSelectState: OTableSelectStateValues;
  onToggleFilteredSelectedAll: OnToggleSelectedAll;
};

export type OTableSelectionRow = {
  isSelected: boolean;
};

export type OTableSelectionGroup = {
  selectState: OTableSelectStateValues;
};

export type UpdateSelected = (selectedRowIds: SelectedRowIds, selectedGroupIds?: SelectedGroupIds) => void;
export type SelectionProps = {
  updateSelected: UpdateSelected;
};

export const makeSelectionPipe =
  <D extends OTableSourceData, G extends OTableSourceGroup>(tableResult: OTableResult<D, G>, props: SelectionProps) =>
  (selectionParams: SelectionParams): OTableResult<D, G> => {
    const { data, groups, rows, rowHashMap, groupHashMap, states } = tableResult;
    const { selectedRowIds, selectedGroupIds, isSelectedOnly } = selectionParams;
    const { updateSelected } = props;

    const selectedRowKeys = Object.keys(selectedRowIds);
    const selectedGroupKeys = Object.keys(selectedGroupIds);

    let nextRows: OTableRowOrGroupList<D, G> = rows;
    if (isSelectedOnly) {
      const { prevSelectedRowIds, prevSelectedGroupIds } = selectionParams;

      nextRows = nextRows
        .filter((row) => {
          if (row.type === OTableRowTypes.GROUP) {
            const isSelectedGroup = prevSelectedGroupIds[row.original.id];
            const group = groupHashMap?.[row.original.id];
            const isSomeSelected = group?.rowIds?.some((rowId) => prevSelectedRowIds[rowId]);

            return isSelectedGroup || isSomeSelected;
          }

          return selectedRowIds[row.original.id] || prevSelectedRowIds[row.original.id];
        })
        .map((row) => {
          let isSelected = true;
          if (row.type === OTableRowTypes.GROUP) {
            isSelected = selectedGroupIds[row.original.id];
          }

          return {
            ...row,
            isFiltered: true,
            filterMatches: {
              ...row.filterMatches,
              isSelected,
            },
          };
        });
    }

    nextRows = nextRows.map((row) => {
      if (row.type === OTableRowTypes.GROUP) {
        const gid = row.original.id;
        const selection: OTableSelectionGroup = {
          selectState: OTableSelectStates.EMPTY,
        };

        if (selectedGroupIds[gid]) {
          selection.selectState = OTableSelectStates.ALL;
        } else {
          const group = groupHashMap?.[gid];
          const isSomeSelected = group?.rowIds?.some((rowId) => selectedRowIds[rowId]);

          if (isSomeSelected) {
            selection.selectState = OTableSelectStates.SOME;
          }
        }

        return {
          ...row,
          selection,
        };
      }

      const selection: OTableSelectionRow = {
        isSelected: selectedRowIds[row.original.id],
      };

      return {
        ...row,
        selection,
      };
    });

    let headSelectState: OTableSelectStateKeys = OTableSelectStates.EMPTY;

    if (selectedRowKeys.length > 0 || selectedGroupKeys.length > 0) {
      const isNotAllRowSelected = selectedRowKeys.some((selectedRowId) => !selectedRowIds[selectedRowId]);
      const isAllRowSelected = selectedRowKeys.length === data.length && !isNotAllRowSelected;

      const isNotAllGroupSelected = selectedGroupKeys.some((selectedGroupId) => !selectedGroupIds[selectedGroupId]);
      const isAllGroupSelected =
        states.viewState !== OTableViewStates.GROUP || (selectedGroupKeys.length === groups?.length && !isNotAllGroupSelected);

      if (isAllRowSelected && isAllGroupSelected) {
        headSelectState = OTableSelectStates.ALL;
      } else {
        const isSomeRowSelected = selectedRowKeys.some((selectedRowId) => selectedRowIds[selectedRowId]);
        const isSomeGroupSelected = selectedGroupKeys.some((selectedGroupId) => selectedGroupIds[selectedGroupId]);

        if (isSomeRowSelected || isSomeGroupSelected) {
          headSelectState = OTableSelectStates.SOME;
        }
      }
    }

    const onToggleRowSelected = (id: string) => {
      const nextSelectedRow = !selectedRowIds[id];
      const nextSelectedRowIds = {
        ...selectedRowIds,
        [id]: nextSelectedRow,
      };

      if (nextSelectedRow) {
        updateSelected(nextSelectedRowIds);
      } else {
        const gid = rowHashMap[id].gid;

        const nextSelectedGroupIds = gid
          ? {
              ...selectedGroupIds,
              [gid]: false,
            }
          : selectedGroupIds;
        updateSelected(nextSelectedRowIds, nextSelectedGroupIds);
      }
    };

    const onToggleGroupSelected = (gid: string, isSelected?: boolean) => {
      const nextSelectedGroup = isSelected === undefined ? !selectedGroupIds[gid] : isSelected;
      const nextSelectedGroupIds = {
        ...selectedGroupIds,
        [gid]: nextSelectedGroup,
      };

      if (nextSelectedGroup) {
        const rowIds = groupHashMap?.[gid]?.rowIds?.reduce<SelectedRowIds>((acc, rid) => {
          acc[rid] = true;
          return acc;
        }, {});

        const nextSelectedRowIds = {
          ...selectedRowIds,
          ...rowIds,
        };

        updateSelected(nextSelectedRowIds, nextSelectedGroupIds);
      } else {
        const rowIds = groupHashMap?.[gid]?.rowIds?.reduce<SelectedRowIds>((acc, rid) => {
          acc[rid] = false;
          return acc;
        }, {});

        const nextSelectedRowIds = {
          ...selectedRowIds,
          ...rowIds,
        };

        updateSelected(nextSelectedRowIds, nextSelectedGroupIds);
      }
    };

    const onToggleSelectedAll = () => {
      if (headSelectState === OTableSelectStates.EMPTY || headSelectState === OTableSelectStates.SOME) {
        const nextSelectedRowIds = data.reduce<SelectedRowIds>((acc, rawData) => {
          acc[rawData.id] = true;
          return acc;
        }, {});

        const nextSelectedGroupIds = groups?.reduce<SelectedGroupIds>((acc, group) => {
          acc[group.id] = true;
          return acc;
        }, {});

        updateSelected(nextSelectedRowIds, nextSelectedGroupIds);
      } else {
        updateSelected({}, {});
      }
    };

    let headFilteredSelectState: OTableSelectStateKeys = OTableSelectStates.NONE;

    const filteredRows = nextRows.filter((row): row is OTableRow<D> => row.type === OTableRowTypes.ROW);
    const filteredGroups = nextRows.filter((row): row is OTableGroup<G> => row.type === OTableRowTypes.GROUP);
    if (filteredRows.length > 0 || filteredGroups.length > 0) {
      const isAllRowSelected = filteredRows.every((row) => row.selection?.isSelected);
      const isAllGroupSelected =
        states.viewState !== OTableViewStates.GROUP ||
        filteredGroups.every(
          (row) =>
            row.selection?.selectState === OTableSelectStates.ALL ||
            (!checkIsFilterMatched(row) && row.selection?.selectState === OTableSelectStates.SOME),
        );

      if (isAllRowSelected && isAllGroupSelected) {
        headFilteredSelectState = OTableSelectStates.ALL;
      } else {
        const isSomeRowSelected = filteredRows.some((row) => row.selection?.isSelected);
        const isSomeGroupSelected = filteredGroups.some((row) => row.selection?.selectState !== OTableSelectStates.EMPTY);

        if (isSomeRowSelected || isSomeGroupSelected) {
          headFilteredSelectState = OTableSelectStates.SOME;
        } else {
          headFilteredSelectState = OTableSelectStates.EMPTY;
        }
      }
    }

    const onToggleFilteredSelectedAll = () => {
      let nextSelectedIds;
      if (headFilteredSelectState === OTableSelectStates.EMPTY || headFilteredSelectState === OTableSelectStates.SOME) {
        nextSelectedIds = calcSelectedIdsByRows(nextRows, true, groupHashMap);
      } else {
        nextSelectedIds = calcSelectedIdsByRows(nextRows, false, groupHashMap);
      }

      updateSelected(
        {
          ...selectedRowIds,
          ...nextSelectedIds.rows,
        },
        {
          ...selectedGroupIds,
          ...nextSelectedIds.groups,
        },
      );
    };

    const selection: OTableSelectionResult = {
      headSelectState,
      onToggleRowSelected,
      onToggleGroupSelected,
      onToggleSelectedAll,
      headFilteredSelectState,
      onToggleFilteredSelectedAll,
    };

    return {
      ...tableResult,
      rows: nextRows,
      selection,
    };
  };

const shouldUpdateSelectedGroup = <D extends OTableSourceData, G extends OTableSourceGroup>(
  tableResult: OTableResult<D, G>,
  selectionParams: SelectionParams,
): boolean => {
  const { groupHashMap } = tableResult;
  const { selectedRowIds, selectedGroupIds } = selectionParams;

  return !Object.keys(selectedGroupIds)
    .filter((groupId) => selectedGroupIds[groupId])
    .every((groupId) => groupHashMap?.[groupId]?.rowIds?.every((rid) => selectedRowIds[rid]));
};

export const makeUpdateSelectedGroupPipe =
  <D extends OTableSourceData, G extends OTableSourceGroup>(tableResult: OTableResult<D, G>, props: SelectionProps) =>
  (selectionParams: SelectionParams): void => {
    const { groupHashMap } = tableResult;
    const { selectedRowIds, selectedGroupIds } = selectionParams;
    const { updateSelected } = props;

    const shouldUpdate = shouldUpdateSelectedGroup(tableResult, selectionParams);

    if (shouldUpdate) {
      const nextSelectedRowIds = Object.keys(selectedGroupIds).reduce(
        (acc, groupId) => {
          return (
            groupHashMap?.[groupId]?.rowIds?.reduce((acc, rid) => {
              acc[rid] = true;
              return acc;
            }, acc) ?? acc
          );
        },
        { ...selectedRowIds },
      );

      updateSelected(nextSelectedRowIds);
    }
  };

export const makeFilterSelectedGroupPipe =
  <D extends OTableSourceData, G extends OTableSourceGroup>(tableResult: OTableResult<D, G>) =>
  (selectionParams: SelectionParams): boolean =>
    !shouldUpdateSelectedGroup(tableResult, selectionParams);
