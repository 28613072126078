import { Flex } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useTranslation } from 'next-i18next';

import { RippleHeading08, RippleSingleSelect, RippleSingleSelectOption } from '@/design';

import { filterSelectedAtom } from '../../atoms';

export function FilterSelected(): React.JSX.Element {
  const { t } = useTranslation();
  const [filterSelected, setFilterSelected] = useAtom(filterSelectedAtom);

  return (
    <Flex flexDirection="column">
      <RippleHeading08 mb="8px">{t('computer:filterDrawer.selection.label')}</RippleHeading08>
      <RippleSingleSelect
        data-testid="filter-selected-select-button"
        value={filterSelected}
        placeholder=""
        onChange={(value) => {
          setFilterSelected(value ?? 'all');
        }}
      >
        <RippleSingleSelectOption value="all">{t('computer:filterDrawer.selection.option.all')}</RippleSingleSelectOption>
        <RippleSingleSelectOption value="selected">{t('computer:filterDrawer.selection.option.selected')}</RippleSingleSelectOption>
        <RippleSingleSelectOption value="unselected">{t('computer:filterDrawer.selection.option.unselected')}</RippleSingleSelectOption>
      </RippleSingleSelect>
    </Flex>
  );
}
