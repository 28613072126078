import React, { useCallback, useEffect, useState } from 'react';

import FullPageLoading from '@/components/FullPageLoading';
import { SupportSessionCategoryTypeValues } from '@/services/serviceDesk/supportSession/types';
import getOS, { OS } from '@/utils/getOS';

import DownloadCard from './DownloadCard';
import DownloadMobileCard from './DownloadMobileCard';
import NotAvailable from './NotAvailable';
import { downloadModel, useDownloadMachine } from './machine';
import type { Category } from './types';

const checkIsMobile = (os: OS) => os === 'iOS' || os === 'Android';

type DownloadProps = {
  packageCode: string;
  category: SupportSessionCategoryTypeValues;
  showLogo?: boolean;
};

const Download = ({ packageCode, category, showLogo = true }: DownloadProps) => {
  const [state, send] = useDownloadMachine(packageCode);
  const { downloadLink } = state.context;

  const [os, setOS] = useState<null | OS>(null);

  const handleDownload = useCallback(
    (category: Category) => () => {
      send(downloadModel.events.DOWNLOAD(category));
    },
    [send],
  );

  useEffect(() => {
    const os = getOS();
    setOS(os);
  }, []);

  useEffect(() => {
    if (!os || checkIsMobile(os)) {
      return;
    }

    if (os === 'Mac') {
      handleDownload('mac')();
    } else {
      handleDownload('win')();
    }
  }, [os, handleDownload]);

  if (state.matches('blocking')) {
    return <NotAvailable showLogo={false} />;
  }

  if (!os) {
    return <FullPageLoading />;
  }

  const isMobile = os === 'iOS' || os === 'Android'; // TODO: For limit type to android and ios only

  return (
    <>
      {isMobile ? (
        <DownloadMobileCard os={os} code={packageCode} configSupportSessionCategory={category} showLogo={showLogo} />
      ) : (
        <DownloadCard onWindowsDownload={handleDownload('win')} onMacDownload={handleDownload('mac')} showLogo={showLogo} />
      )}
      {!isMobile && downloadLink && <iframe frameBorder="0" height="0" id="download-iframe" width="0" src={downloadLink}></iframe>}
    </>
  );
};

export default Download;
