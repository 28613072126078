import { OTableResult, OTableSourceData, OTableSourceGroup } from '@/components/ObservableTable/types';
import { OTableGroupSortParams } from '@/components/ObservableTable/useObservableGroupSort';
import { OTableSortBy } from '@/components/ObservableTable/useObservableSort';

export type OTableGroupSortProps<G> = {
  sortFunc?: (sort: OTableSortBy, groupA: G, groupB: G) => 0 | -1 | 1;
};

export const makeGroupSortPipe =
  <D extends OTableSourceData, G extends OTableSourceGroup>(tableResult: OTableResult<D, G>) =>
  (sortParams: OTableGroupSortParams): OTableResult<D, G> => {
    const { groups } = tableResult;
    const { sortBy, sortFunc } = sortParams;

    let nextGroups = groups;
    if (groups && sortBy.length > 0) {
      nextGroups = [...groups].sort((groupA, groupB) => {
        let result = 0;
        sortBy.some((sort) => {
          if (sortFunc) {
            const sortResult = sortFunc(sort, groupA, groupB);

            if (sortResult === -1 || sortResult === 1) {
              result = sortResult;
              return true;
            }
            return false;
          }

          const { accessor, desc } = sort;

          // TODO: FIXIT
          // @ts-ignore
          const targetA = groupA[accessor] ?? '';
          // @ts-ignore
          const targetB = groupB[accessor] ?? '';

          if (targetA > targetB) {
            result = desc ? -1 : 1;
            return true;
          }
          if (targetB > targetA) {
            result = desc ? 1 : -1;
            return true;
          }

          return false;
        });

        return result;
      });
    }

    return {
      ...tableResult,
      groups: nextGroups,
    };
  };
