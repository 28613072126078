import { useEffect, useState } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import Cookie from 'js-cookie';
import { Trans, useTranslation } from 'next-i18next';

import { RippleHyperLink, RippleMessageModal, RippleTypography } from '@/design';

const RESET_PASSWORD_URL = '/account_info/?change_password=1';

export const PasswordRotationAlert = () => {
  const { t } = useTranslation();
  const passwordRotationAlert = useDisclosure();
  const [digit, setDigit] = useState(0);
  const cleanCookie = () => {
    Cookie.remove('pcp_password_rotation_remaining_days');
  };

  const handleClose = () => {
    passwordRotationAlert.onClose();
  };
  useEffect(() => {
    const dayCount = Cookie.get('pcp_password_rotation_remaining_days');
    if (dayCount && Number(dayCount)) {
      setDigit(Number(dayCount));
      passwordRotationAlert.onOpen();
      cleanCookie();
    }
  }, []);
  return (
    <RippleMessageModal
      title={t('common:changePassword')}
      confirmButtonText={t('common:ok')}
      isOpen={passwordRotationAlert.isOpen}
      onConfirm={handleClose}
    >
      <RippleTypography variant="body02">
        {digit === 1 ? (
          <Trans
            t={t}
            i18nKey="common:passwordRotation"
            components={{ Link: <RippleHyperLink variant="hyperlink02" color="blue.60" href={RESET_PASSWORD_URL} /> }}
          />
        ) : (
          <Trans
            t={t}
            i18nKey="common:passwordRotationDays"
            components={{ Link: <RippleHyperLink variant="hyperlink02" color="blue.60" href={RESET_PASSWORD_URL} /> }}
            values={{ digit }}
          />
        )}
      </RippleTypography>
    </RippleMessageModal>
  );
};
