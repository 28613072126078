import { RefObject, useMemo, useRef } from 'react';

import { useDimensions } from '@chakra-ui/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, selectAtom, useResetAtom } from 'jotai/utils';

export const actionBarHeight = 34;

type ContainerSizeOptions = {
  isLoading: boolean;
  isEmpty: boolean;
};

const defaultHeight = 40 + 16; // Header height + scrollbar height
const defaultWidth = 420;

export function useContainerSize({ isLoading, isEmpty }: ContainerSizeOptions): [RefObject<HTMLDivElement>, number, number] {
  const container = useRef<HTMLDivElement>(null);
  const containerDimensions = useDimensions(container, true);

  const height = computeHeight();
  const width = containerDimensions?.borderBox.width ?? defaultWidth;

  return [container, height, width];

  function computeHeight(): number {
    if (isLoading) {
      return defaultHeight;
    }
    if (isEmpty) {
      return 0;
    }
    return containerDimensions ? Math.max(containerDimensions.borderBox.height, 300) : defaultHeight;
  }
}

export const selectRowTableAtom = atomWithReset<Record<string, boolean>>({});

export function useSelectRow(rowId: string) {
  const setSelectRowTable = useSetAtom(selectRowTableAtom);
  const targetRowAtom = useMemo(() => selectAtom(selectRowTableAtom, (state) => Boolean(state[rowId])), [rowId]);
  const isSelected = useAtomValue(targetRowAtom);

  const selectRow = () => {
    setSelectRowTable((state) => {
      return { ...state, [rowId]: true };
    });
  };
  return { isSelected, selectRow };
}

export function useResetSelectRow() {
  return useResetAtom(selectRowTableAtom);
}
