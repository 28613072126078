import { useQuery } from '@tanstack/react-query';

import { infoQueryService } from '@/services/users';

export const useEmmPolicyAccess = () => {
  const infoQuery = useQuery({
    queryKey: ['info-query', 'emm-policy-access'],
    queryFn: () =>
      infoQueryService.execute({
        team_member_info: ['role', 'group_scope', 'member_status'],
      }),
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 5 * 60 * 1000, // 5 minutes
    select: (data) => {
      return Object.entries(data).reduce(
        (acc, [teamKind, { team_member_info }]) => {
          // Return the current checking if there is any team can access EMM policy
          if (acc.canAccess) {
            return acc;
          }
          if (!team_member_info || !['sba', 'srs', 'msp', 'splashtop', 'ste_custom'].includes(teamKind)) {
            return acc;
          }
          const { role, group_scope, member_status } = team_member_info;
          const isAdmin = role === 'manager' && group_scope === 'all';
          return {
            navigationGuard: member_status === 'inviting' ? '/team' : '/',
            canAccess: Boolean((role === 'owner' || isAdmin) && member_status === 'enabled'),
          };
        },
        {
          navigationGuard: '/',
          canAccess: false,
        },
      );
    },
  });

  return {
    policyAccessQuery: infoQuery,
  };
};
