import { Suspense } from 'react';

import axios from 'axios';
import { atom, useAtomValue } from 'jotai';
import { loadable } from 'jotai/utils';

import FullPageLoading from '@/components/FullPageLoading';
import { attendedTeamInformationAtom, teamInformationAtom, unattendedTeamInformationAtom } from '@/models/TeamInformation';
import { INFO_QUERY } from '@/services/common';

import { createQuery } from './createQuery';
import { CreateUserPermissionOptions, TeamPermissionKey, UserPermissionMap } from './types';

export function createUserPermission<TeamPermission extends TeamPermissionKey>(options: CreateUserPermissionOptions<TeamPermission>) {
  type UserPermissionValue = UserPermissionMap<TeamPermission>;

  const userPermissionMapSourceAtom = atom<Promise<UserPermissionValue>>(async () => {
    // TODO: Use zod to validate the response ?
    const response = await axios.post<{ data: UserPermissionValue }>(INFO_QUERY, createQuery(options));

    return response.data.data;
  });
  const userPermissionMapLoadableAtom = loadable(userPermissionMapSourceAtom);
  const userPermissionMapAtom = atom<UserPermissionValue>((get) => {
    const result = get(userPermissionMapLoadableAtom);

    if (result.state !== 'hasData') {
      throw new Error('UserPermissionProvider is not provided.');
    }

    return result.data;
  });

  const getUserPermissionAtom = atom((get) => {
    const userPermissionMap = get(userPermissionMapAtom);
    const teamInformation = get(teamInformationAtom);
    const attendedTeamInformation = get(attendedTeamInformationAtom);
    const unattendedTeamInformation = get(unattendedTeamInformationAtom);

    return function getUserPermission(kind?: 'attended' | 'unattended') {
      let userPermission;

      if (kind === 'attended' && attendedTeamInformation) {
        userPermission = userPermissionMap[attendedTeamInformation.teamKind];
      } else if (kind === 'unattended' && unattendedTeamInformation) {
        userPermission = userPermissionMap[unattendedTeamInformation.teamKind];
      } else {
        userPermission = userPermissionMap[teamInformation.teamKind];
      }

      if (!userPermission) throw new Error("Unexpected Error: Can't get userPermissionMap");

      return userPermission;
    };
  });

  function UserPermissionFetcher({ children }: { children: React.ReactNode }): React.JSX.Element {
    useAtomValue(userPermissionMapSourceAtom);

    return <>{children}</>;
  }

  function UserPermissionProvider({ children }: { children: React.ReactNode }): React.JSX.Element {
    return (
      <Suspense fallback={<FullPageLoading />}>
        <UserPermissionFetcher>{children}</UserPermissionFetcher>
      </Suspense>
    );
  }

  function useUserPermission(kind?: 'attended' | 'unattended') {
    const getUserPermission = useAtomValue(getUserPermissionAtom);

    return getUserPermission(kind);
  }

  return {
    userPermissionMapAtom,
    UserPermissionProvider,
    useUserPermission,
    getUserPermissionAtom,
  };
}
