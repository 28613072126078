import { useEffect, useState } from 'react';

import { VStack } from '@chakra-ui/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';

import {
  RippleInput,
  RippleInputGroup,
  RippleInputGroupButton,
  RippleInputRightElement,
  RippleTypography,
  useRippleFlashMessage,
} from '@/design';
import { useTeamId } from '@/models/TeamInformation';
import emptyFunc from '@/utils/emptyFunc';

import { accessPermissionModalAtom } from '../atoms';
import { useIsLoading } from '../hooks';
import { addUserAtom, addUserErrorAtom, addUserStateAtom, resetAddUserInputAtom } from './atoms';
import { addUserErrorEnum } from './types';

export function AddUserInput(): React.JSX.Element {
  const { t } = useTranslation();
  const teamId = useTeamId();

  const isLoading = useIsLoading();

  const { computerData, hasLimitedManagePermission } = useAtomValue(accessPermissionModalAtom);

  const [email, setEmail] = useState<string>('');
  const checkingState = useAtomValue(addUserStateAtom);
  const errorKind = useAtomValue(addUserErrorAtom);
  const submitAddUser = useSetAtom(addUserAtom);
  const resetInput = useSetAtom(resetAddUserInputAtom);

  const isDisabled = hasLimitedManagePermission || isLoading || checkingState === 'checking';

  const showError = checkingState === 'error' && errorKind !== null && errorKind !== addUserErrorEnum.unexpected_error;

  const { flashMessage } = useRippleFlashMessage();

  useEffect(
    function handleUnexpectedError() {
      if (checkingState === 'error' && errorKind === addUserErrorEnum.unexpected_error) {
        flashMessage({ variant: 'error', title: t('common:unexpectedError') });
      }
    },
    [flashMessage, t, checkingState, errorKind],
  );

  useEffect(
    function resetState() {
      return () => {
        resetInput();
      };
    },
    [resetInput],
  );

  return (
    <VStack spacing="4px" alignItems="stretch">
      <RippleInputGroup maxWidth="524px">
        <RippleInput
          data-testid="email-input"
          size="xs"
          type="email"
          placeholder={t('computer:enter_splashtop_account')}
          isDisabled={isDisabled}
          value={email}
          onChange={handleInputChange}
        />
        <RippleInputRightElement>
          <RippleInputGroupButton
            data-testid="add-user-submit-button"
            size="xs"
            minW="72px"
            isLoading={isLoading}
            isDisabled={isDisabled}
            onClick={handleSubmit}
          >
            {t('common:add')}
          </RippleInputGroupButton>
        </RippleInputRightElement>
      </RippleInputGroup>
      {showError && (
        <RippleTypography variant="body03" color="red.100">
          {errorKind === addUserErrorEnum.invalid_format && t('computer:you_entered_an_invalid_splashtop_account_email_address')}
          {errorKind === addUserErrorEnum.not_shareable && t('computer:you_entered_an_invalid_splashtop_account_email_address')}
          {errorKind === addUserErrorEnum.is_msp_team && t('computer:you_cant_add_a_splashtop_remote_support_user_account')}
        </RippleTypography>
      )}
    </VStack>
  );

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  function handleSubmit() {
    if (computerData) {
      submitAddUser({ teamId, computerId: computerData.id, email })
        .then(() => {
          setEmail('');
        })
        .catch(emptyFunc);
    }
  }
}
