import React, { useCallback, useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useTranslation } from 'next-i18next';

import { RippleButton, RippleModal, RippleModalBody, RippleModalContent, RippleModalFooter, RippleModalOverlay } from '@/design';
import { getBusinessAppURI } from '@/services/computers';
import { LaunchSRCFail, LaunchSRCProcessing, useLaunchSRCSuccessMessage } from '@/showcase';
import emptyFunc from '@/utils/emptyFunc';
import getOS from '@/utils/getOS';

export const launchSRCModalAtom = atomWithReset<{ isOpen: boolean; computerId: number | null; isSSH: boolean }>({
  isOpen: false,
  computerId: null,
  isSSH: false,
});

export function useLaunchSRCModal() {
  const set = useSetAtom(launchSRCModalAtom);

  return {
    open: ({ computerId, isSSH }: { computerId: number; isSSH: boolean }) => {
      set({ isOpen: true, computerId, isSSH });
    },
  };
}

export const LaunchSRCModal = () => {
  const { t } = useTranslation();

  const { isOpen, computerId, isSSH } = useAtomValue(launchSRCModalAtom);
  const resetModal = useResetAtom(launchSRCModalAtom);

  const [launchURI, setLaunchURI] = useState<string | null>(null);

  const uriQuery = useQuery({
    queryKey: ['getBusinessAppURI', computerId],
    queryFn: () => {
      if (computerId !== null) {
        const os = getOS();

        switch (os) {
          case 'iOS': {
            return getBusinessAppURI({ computerId }).then(({ url }) => {
              window.location.href = url;
              return url;
            });
          }

          case 'ChromeOS':
          case 'Android': {
            return getBusinessAppURI({ computerId, isAndroid: true }).then(({ url }) => {
              let newWindow: Window | null = null;
              newWindow = window.open(url, '_blank', 'noopener,noreferrer');
              if (newWindow) newWindow.opener = null;
              return url;
            });
          }

          // NOTE: Only Mac and Windows are supported for SSH session
          case 'Mac':
          case 'Windows':
          default: {
            return getBusinessAppURI({ computerId, isSSH }).then(({ url }) => {
              setLaunchURI(url);
              return url;
            });
          }
        }
      } else {
        return null as never;
      }
    },
    gcTime: 0,
    refetchOnWindowFocus: false,
    enabled: isOpen && computerId !== null,
  });

  const handleClose = useCallback(() => {
    resetModal();

    // Delay unmount iframe to avoid launching app being skipped
    setTimeout(() => {
      setLaunchURI(null);
    }, 1000);
  }, [resetModal]);

  const successMessage = useLaunchSRCSuccessMessage();

  useEffect(
    function showFlashMessageOnSuccess() {
      if (uriQuery.isSuccess) {
        handleClose();
        successMessage();
      }
    },
    [uriQuery, handleClose, successMessage],
  );

  return (
    <>
      <RippleModal isOpen={isOpen} onClose={emptyFunc} closeOnOverlayClick={false}>
        <RippleModalOverlay />
        <RippleModalContent borderRadius="4px" minWidth="560px">
          <RippleModalBody flexBasis="auto">
            {uriQuery.isFetching && <LaunchSRCProcessing />}
            {uriQuery.isError && <LaunchSRCFail />}
          </RippleModalBody>

          <RippleModalFooter marginTop="24px" borderTop="1px solid" borderTopColor="neutral.60">
            <RippleButton data-testid="close-button" variant="grayScaleGhost" size="sm" onClick={handleClose}>
              {uriQuery.isFetching && t('common:cancel')}
              {uriQuery.isError && t('common:close')}
            </RippleButton>
          </RippleModalFooter>
        </RippleModalContent>
      </RippleModal>

      {launchURI && <iframe name="src_launch" frameBorder="0" height="0" id="launch-iframe" width="0" src={launchURI} />}
    </>
  );
};
