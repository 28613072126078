import { UseQueryResult } from '@tanstack/react-query';

import { UserComputer } from '@/services/computers';

export const ComputerPlatformNames = {
  iPad: 'iPad',
  iPhone: 'iPhone',
  mac: 'Mac',
  android: 'Android',
  win8: 'Windows 8',
  winRT: 'Windows RT',
  winPhone: 'Windows Phone',
  win: 'Windows',
  linux: 'Linux',
  bb: 'BlackBerry',
  chrome: 'Chrome',
  browser: 'Browser',
} as const;

export type ComputerPlatformNameKeys = keyof typeof ComputerPlatformNames;

export function getComputerPlatformName(key: ComputerPlatformNameKeys) {
  return ComputerPlatformNames[key];
}

export function computeShowOOBE(query: UseQueryResult): boolean | null {
  if (query.isFetching) return null;

  if (query.error) return true; // member status is not `enabled`

  if (!Array.isArray(query.data)) return true as never;

  return query.data.length === 0;
}

/**
 * SBA team My computers API flow is different from other pages that using computer list API.
 * SBA team My computers will get the computer list data from `/api/web/v1/users/computers` API directly.
 */
export function computeShowOOBE_SBA_MyComputers(query: UseQueryResult<Array<UserComputer>>): boolean | null {
  if (!query.isFetched || query.isFetching) return null;

  if (query.error) return true;

  if (!Array.isArray(query.data)) return true as never;

  return query.data.length === 0;
}
