import { useEffect, useState } from 'react';

import { Box, Flex, Stack, useDisclosure } from '@chakra-ui/react';
import { useSetAtom } from 'jotai';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import { useTranslation } from 'next-i18next';

import { ALL_GROUP_ID } from '@/components/ComputerList/utils';
import {
  RippleBodyText02,
  RippleBreadcrumb,
  RippleButton,
  RippleComputerAccordionButton,
  RippleComputerGroupDropdown,
  RippleComputerGroupDropdownData,
  RippleComputerSearchBar,
  RippleHeading04,
  RippleRefreshIconButton,
} from '@/design';
import {
  ComputerViewValues,
  RippleComputerViewDropdown,
  useRippleComputerViewDropdown,
} from '@/design/RippleComputer/RippleComputerViewDropdown';
import { featureControl } from '@/feature/toggle';
import { useInventoryAccess } from '@/hooks';
import { arrayToExistence, arrayToHashPair, arrayToHashTable } from '@/utils/hashTable';

import { ExportModal } from '../ExportModal';
import { Inventory, InventorySummary, InventoryTableColumn } from '../types';
import { fromMBtoGB, fromMHzToGHz, validateSerialNumber } from '../utils';
import ColumnSelector, { defaultColumnsVisibilities } from './ColumnSelector';
import ComputerView from './ComputerView';
import { DetailDrawer, detailDrawerAtom } from './DetailDrawer';
import GroupView from './GroupView';
import { inventoryColumnOrder } from './constant';

const COLUMN_SELECT_STORAGE_KEY = 'splashtop__inventory__column';

export const formatInventoryList = (inventoryList: Array<Inventory>, groupHashMap: Record<string, string>): Array<InventorySummary> => {
  return inventoryList.map((inventory) => {
    const isAndroid = inventory.os === 'Android';
    return {
      raw: inventory,
      id: inventory.id,
      computerName: inventory.server_name ?? '',
      groupId: String(inventory.group_id),
      groupName: groupHashMap[inventory.group_id] ?? '',
      reportTime: inventory.local_time ?? '',
      os: inventory.os_product ?? inventory.system?.operating_system ?? '',
      osBuild: inventory.os_build_rev ? `${inventory.os_build}(${inventory.os_build_rev})` : inventory.os_build ?? '',
      architecture: inventory.architecture ?? '',
      deviceName: inventory.system?.computer_name ?? '',
      domain: inventory.system?.domain ?? '',
      manufacturer: inventory.hardware?.manufacturer ?? '',
      modelProduct: (inventory.hardware?.product || inventory.hardware?.model) ?? '',
      serialNumber:
        validateSerialNumber(inventory.hardware?.motherboard?.bios_serial_number) ||
        validateSerialNumber(inventory.hardware?.motherboard?.serial_number) ||
        validateSerialNumber(inventory.hardware?.serial_number) ||
        '',
      biosVersion: inventory.hardware?.motherboard?.bios_version ?? '',
      cpu: inventory.hardware?.processor?.map(({ type }) => type ?? '') ?? [],
      cpuSpeed: inventory.hardware?.processor?.map(({ speed }) => fromMHzToGHz(speed)) ?? [],
      chipset: inventory.hardware?.motherboard?.chipset ?? '',
      ram: isAndroid ? fromMBtoGB(inventory?.hardware?.ram?.total) ?? -Infinity : fromMBtoGB(inventory.hardware?.memory?.size) ?? -Infinity,
      totalFreeSpace: isAndroid
        ? inventory?.hardware?.rom?.free ?? -Infinity
        : fromMBtoGB(
            inventory.hardware?.drive
              ?.map(({ partition }) => partition?.map(({ free_space }) => Number(free_space)).reduce((a, c) => a + c, 0) ?? 0)
              .reduce((a, c) => a + c, 0),
          ) ?? -Infinity,
      totalStorage: isAndroid
        ? inventory?.hardware?.rom?.total ?? -Infinity
        : fromMBtoGB(inventory.hardware?.drive?.map(({ capacity }) => Number(capacity)).reduce((a, c) => a + c, 0)) ?? -Infinity,
      displayAdapter: inventory.hardware?.display_driver?.map(({ type }) => type ?? '') ?? [],
      ipAddress: inventory.hardware?.network?.interface?.map(({ ip_address }) => ip_address ?? []).flat() ?? [],
      timeZone: inventory.system?.time_zone ?? '',
      lastBoot: inventory.system?.last_boot_time ?? '',
      lastLoggedOn: inventory.system?.last_logon_user ?? '',
    };
  });
};

export const filterRowsByGroupId = (rows: Array<InventorySummary>, groupId?: string) => {
  if (!groupId || groupId === ALL_GROUP_ID) {
    return rows;
  }
  return rows.filter((row) => row.groupId === groupId);
};

export const filterRowsBySearchKeyword = (
  rows: Array<InventorySummary>,
  searchKeyword?: string,
  visibilities?: Record<string, boolean>,
) => {
  if (!searchKeyword) {
    return rows;
  }
  if (visibilities) {
    const currentColumnOrder = inventoryColumnOrder.filter((columnName) => visibilities[columnName]);
    return rows.filter((row) =>
      currentColumnOrder.some((columnName) => row[columnName].toString().toLocaleUpperCase().includes(searchKeyword.toLocaleUpperCase())),
    );
  } else {
    return rows.filter((row) =>
      inventoryColumnOrder.some((columnName) => row[columnName].toString().toLocaleUpperCase().includes(searchKeyword.toLocaleUpperCase())),
    );
  }
};

export const sortRowsByCondition = (rows: Array<InventorySummary>, sortCondition: SortCondition) => {
  if (sortCondition.column === 'computerName') {
    return orderBy(
      rows,
      [(rows) => rows['computerName'].toString().toLowerCase(), (rows) => rows['groupName'].toString().toLowerCase()],
      [sortCondition.order, 'asc'],
    );
  }
  return orderBy(
    rows,
    [
      (rows) =>
        typeof rows[sortCondition.column] === 'number' ? rows[sortCondition.column] : rows[sortCondition.column].toString().toLowerCase(),
      (rows) => rows['computerName'].toString().toLowerCase(),
    ],
    [sortCondition.order, 'asc'],
  );
};

const groupRows = (rows: Array<InventorySummary>, groupedRowsTemplate: Record<string, Array<InventorySummary>>) => {
  return rows.reduce((acc, row) => {
    if (!acc[row.groupId]) acc[row.groupId] = [];
    acc[row.groupId].push(row);
    return acc;
  }, groupedRowsTemplate);
};

export type DatagridProps = {
  inventoryList: Array<Inventory>;
  groups: Array<{ id: string; name: string }>;
  isLoading: boolean;
  onRefresh: () => void;
};

export type SortCondition = {
  column: InventoryTableColumn;
  order: 'asc' | 'desc';
};

export const Datagrid = ({ inventoryList, groups, isLoading, onRefresh }: DatagridProps) => {
  const { t } = useTranslation();
  const inventoryAccess = useInventoryAccess();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedGroupId, setSelectedGroupId] = useState(ALL_GROUP_ID);

  const [sortCondition, setSortCondition] = useState<SortCondition>({
    column: 'computerName',
    order: 'asc',
  });

  const [visibilities, setVisibilities] = useState<Record<string, boolean>>(defaultColumnsVisibilities);
  const defaultExpandAbilities = arrayToExistence(groups, { mainKey: 'id' });
  const [expandAbilities, setExpandAbilities] = useState<Record<string, boolean>>(defaultExpandAbilities);

  const setDetailDrawer = useSetAtom(detailDrawerAtom);

  const exportModal = useDisclosure();
  const { currentComputerView, setCurrentComputerView } = useRippleComputerViewDropdown();

  const groupHashMap = arrayToHashPair(groups, { mainKey: 'id', valueKey: 'name' });
  const groupedRowsTemplate = groups.reduce<Record<string, Array<InventorySummary>>>((acc, group) => {
    acc[group.name] = [];
    return acc;
  }, {});
  const inventorySummaryList = formatInventoryList(inventoryList, groupHashMap);
  const inventoryHashMap = arrayToHashTable(inventoryList, { mainKey: 'id' });

  const rows = sortRowsByCondition(
    filterRowsBySearchKeyword(filterRowsByGroupId(inventorySummaryList, selectedGroupId), searchKeyword, visibilities),
    sortCondition,
  );

  const groupedRows = groupRows(rows, groupedRowsTemplate);

  const isExportDisable = featureControl.getToggle('PCP_2871__Inventory_Export_Disabled__TeamExpiration')
    ? inventoryAccess.isFetched && inventoryAccess.isExpired
    : false;

  const handleChangeCurrentComputerView = (key: ComputerViewValues) => {
    setCurrentComputerView(key);
  };

  const handleSelectGroup = (selectedGroup: RippleComputerGroupDropdownData) => {
    setSelectedGroupId(selectedGroup.id);
  };

  const handleSelectInventory = (id: string) => {
    setDetailDrawer({ isOpen: true, inventory: inventoryHashMap[id] });
  };

  const handleChangeCondition = (sortCondition: SortCondition) => {
    setSortCondition(sortCondition);
  };

  const handleChangeVisibilities = (visibilities: Record<string, boolean>) => {
    setVisibilities(visibilities);
    localStorage.setItem(COLUMN_SELECT_STORAGE_KEY, JSON.stringify(visibilities));
  };

  const handleExpandGroup = (groupId: string) => {
    setExpandAbilities({ ...expandAbilities, [groupId]: !expandAbilities[groupId] });
  };

  const handleSearch = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const handleToggleAccordion = () => {
    if (isEqual(expandAbilities, defaultExpandAbilities)) setExpandAbilities({});
    else {
      setExpandAbilities(defaultExpandAbilities);
    }
  };

  const handleExport = () => {
    exportModal.onOpen();
  };

  useEffect(() => {
    const value = localStorage.getItem(COLUMN_SELECT_STORAGE_KEY);
    if (value) {
      const savedVisibilities = JSON.parse(value) as typeof defaultColumnsVisibilities;
      setVisibilities(savedVisibilities);
    }
  }, []);

  return (
    <Flex flexDirection="column" marginBottom="30px" overflowX="hidden" overflowY="clip" minHeight="calc(100vh - 160px)">
      <Box display="inline-block" position="sticky" left="0px">
        <RippleBreadcrumb
          items={[t('common:header.management'), t('common:header.endpointManagement'), t('common:header.inventory')]}
          marginY="24px"
        />
        <RippleHeading04>{t('common:header.inventory')}</RippleHeading04>
        {inventoryList && <RippleBodyText02 mt="8px">{inventoryList.length} computers</RippleBodyText02>}
      </Box>

      <Flex
        position={isLoading ? 'static' : 'sticky'}
        top="54px"
        left="0px"
        mt="24px"
        paddingY="8px"
        justifyContent="space-between"
        alignItems="center"
        borderColor="neutral.60"
        borderBottomWidth="1px"
        borderStyle="solid"
        backgroundColor="neutral.10"
        zIndex="5"
      >
        {/* Left */}
        <Flex alignItems="center">
          <RippleButton onClick={handleExport} size="xs" fontSize="14px" marginRight="8px" isDisabled={isExportDisable}>
            {t('common:table.export')}
          </RippleButton>
          <ExportModal {...exportModal} />
          <RippleRefreshIconButton isLoading={isLoading} onClick={onRefresh} />
        </Flex>
        {/* Right */}
        <Stack direction="row" spacing="8px" alignItems="center">
          <Box>
            <RippleComputerViewDropdown
              currentComputerView={currentComputerView}
              setCurrentComputerView={handleChangeCurrentComputerView}
            />
          </Box>
          <Box>
            <RippleComputerGroupDropdown
              showAllGroup
              showDefaultGroup
              showFromOtherGroup={false}
              groups={groups ?? []}
              maxHeight={500}
              onSelect={handleSelectGroup}
            />
          </Box>

          {currentComputerView === 'GROUP' && (
            <Box>
              <RippleComputerAccordionButton isEnabled={isEqual(expandAbilities, defaultExpandAbilities)} onClick={handleToggleAccordion} />
            </Box>
          )}
          <Box>
            <ColumnSelector visibilities={visibilities} onChange={handleChangeVisibilities} />
          </Box>
          <Box>
            <RippleComputerSearchBar onSearch={handleSearch} />
          </Box>
        </Stack>
      </Flex>
      <Flex flex="1 0" minH="400px">
        {currentComputerView === 'COMPUTER' && (
          <ComputerView
            rows={rows}
            visibilities={visibilities}
            searchKeyword={searchKeyword}
            selectedGroupId={selectedGroupId}
            onSelect={handleSelectInventory}
            isLoading={isLoading}
            sortCondition={sortCondition}
            onChangeSortCondition={handleChangeCondition}
          />
        )}
        {currentComputerView === 'GROUP' && (
          <GroupView
            rows={rows}
            groupedRows={groupedRows}
            sortedGroupList={groups}
            visibilities={visibilities}
            expandAbilities={expandAbilities}
            searchKeyword={searchKeyword}
            selectedGroupId={selectedGroupId}
            onSelect={handleSelectInventory}
            onExpand={handleExpandGroup}
            isLoading={isLoading}
            sortCondition={sortCondition}
            onChangeSortCondition={handleChangeCondition}
          />
        )}
      </Flex>
      <DetailDrawer />
    </Flex>
  );
};

export default Datagrid;
