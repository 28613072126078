import { useRouter } from 'next/router';

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';

import { RippleButton } from '@/design';
import { useTeamExpiration, useTeamId, useTeamRole } from '@/models/TeamInformation';
import { getTeamDeploymentPackages } from '@/services/team';

import { useActionBarContext } from './hooks';

type AddComputerProps = { isDisabled?: boolean };
export function AddComputer(props: AddComputerProps): React.JSX.Element {
  return (
    <ShowAddComputer>
      <AddComputerCore {...props} />
    </ShowAddComputer>
  );
}

function AddComputerCore({ isDisabled = false }: AddComputerProps): React.JSX.Element {
  const { t } = useTranslation();
  const router = useRouter();

  const teamId = useTeamId();
  const { isGroupAdmin } = useTeamRole();
  const { isTeamExpired } = useTeamExpiration();

  const { isLoading } = useActionBarContext();

  const defaultDeployPackageQuery = useQuery({
    queryKey: ['computerList', 'defaultDeployPackage', teamId],
    queryFn: async () => {
      const deployPackageList = await getTeamDeploymentPackages(teamId);
      // The deploy package list always has at least one package.
      // But the user group may has no package.
      return (
        deployPackageList.find(({ default: isDefault }) => {
          // NOTE: Use `default` directly will cause syntax error
          return isDefault;
        }) ?? deployPackageList[0]
      );
    },
  });
  const defaultDeployPackageId = defaultDeployPackageQuery.data?.id;

  function handleAddComputer(): void {
    if (isGroupAdmin) {
      router.push(`/team_deployment`);
    } else if (defaultDeployPackageId) {
      router.push(`/team/deployments/${defaultDeployPackageId}`);
    } else {
      // Go to create a deployment package
      router.push(`/team_deployment`);
    }
  }

  return (
    <RippleButton
      data-testid="add-computer"
      size="xs"
      variant="secondary"
      onClick={handleAddComputer}
      isDisabled={isDisabled || isLoading || isTeamExpired || defaultDeployPackageQuery.isFetching}
    >
      {t('computer:addComputer')}
    </RippleButton>
  );
}

type ShowAddComputerProps = { children?: React.ReactNode };
function ShowAddComputer({ children }: ShowAddComputerProps): React.JSX.Element | null {
  const { isManager } = useTeamRole();

  if (isManager) return <>{children}</>;

  return null;
}
