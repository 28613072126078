import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleBodyText02, RippleHeading04 } from '@/design';
import { useLatestStreamerVersion } from '@/models/SystemInformation';

import { StickyLeftWrapper, TeamStatusSection } from '../ComputerList';

type PageFrameProps = { children?: React.ReactNode };
export function PageFrame({ children }: PageFrameProps): React.JSX.Element {
  const { t } = useTranslation();

  const latestStreamerVersion = useLatestStreamerVersion();

  return (
    <Flex flexDirection="column" h="100%">
      <StickyLeftWrapper>
        <Box py="24px">
          <TeamStatusSection />
          <Flex mb="8px">
            <RippleHeading04>{t('computer:myComputers.title')}</RippleHeading04>
          </Flex>
          <Flex justifyContent="start">
            <RippleBodyText02>
              {t('computer:latestStreamer')} Windows {latestStreamerVersion.win} / Mac {latestStreamerVersion.mac}
            </RippleBodyText02>
          </Flex>
        </Box>
      </StickyLeftWrapper>
      {children}
    </Flex>
  );
}
