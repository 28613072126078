import { Flex } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useTranslation } from 'next-i18next';

import { RippleHeading08 } from '@/design';

import { viewModeAtom } from '../../atoms';
import type { ViewMode } from '../../types';
import { ShowViewSwitch } from '../ActionBar/ViewSwitch';
import { RadioButtonGroup } from '../RadioButtonGroup';

export function ViewSwitch(): React.JSX.Element {
  return (
    <ShowViewSwitch>
      <ViewSwitchCore />
    </ShowViewSwitch>
  );
}

export function ViewSwitchCore(): React.JSX.Element {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useAtom(viewModeAtom);

  return (
    <Flex flexDirection="column" p="0 24px 24px 24px">
      <RippleHeading08 mb="16px">{t('computer:tableSetting.view.label')}</RippleHeading08>
      <RadioButtonGroup
        value={viewMode}
        onChange={(value) => {
          setViewMode(value as ViewMode);
        }}
        options={[
          { label: t('computer:computer'), value: 'computer' },
          { label: t('computer:group'), value: 'group' },
        ]}
      />
    </Flex>
  );
}
