import React, { useEffect, useRef, useState } from 'react';

import { createPortal } from 'react-dom';

type PortalProps = {
  selector: string;
  children: React.ReactNode;
};

const Portal = ({ children, selector }: PortalProps) => {
  const ref = useRef<HTMLElement | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector(selector);
    setMounted(true);
  }, [selector]);

  return mounted ? createPortal(children, ref.current!) : null;
};

export default Portal;
