import axios from 'axios';

import { SurveyFormAPIValue } from '@/services/serviceDesk/surveys/type';
import { checkResponse } from '@/services/utils';

import { UpdateSurveyResult, updateSurveyResultSchema } from './types';

export function updateSurvey({ teamId, surveyId, formValue }: { teamId: number; surveyId: string; formValue: SurveyFormAPIValue }) {
  return checkResponse<UpdateSurveyResult>(
    axios.put(`/api/web/v1/teams/${teamId}/service_desk/surveys/${surveyId}`, formValue),
    updateSurveyResultSchema,
  );
}
