import { useEffect } from 'react';

import { Box, Flex, List, ListItem } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import Card from '@/components/BasicCard';
import FullPageLoading from '@/components/FullPageLoading';
import * as Logo from '@/components/Logo';
import { RippleButton, RippleDivider, RipplePlayStore, RippleTypography } from '@/design';
import useGetMobileCategory from '@/modules/ServiceDesk/Download/hooks/useGetMobileCategory';
import { NumberTag } from '@/modules/Welcome/NumberTag';
import { SupportSessionCategoryTypeValues } from '@/services/serviceDesk/supportSession/types';
import { launchSosAndroidApp, launchSosAppStore, launchSosIosApp, launchSosPlayStore } from '@/utils/urlScheme';

export type DownloadMobileCardProps = {
  os: 'iOS' | 'Android';
  code: string;
  configSupportSessionCategory: SupportSessionCategoryTypeValues;
  showLogo?: boolean;
};

const DownloadMobileCard = ({ os, code, showLogo = true }: DownloadMobileCardProps) => {
  const { t } = useTranslation();

  const { isInitialLoading: isLoading, data } = useGetMobileCategory(code, os);
  const supportSessionCategory = data?.category as SupportSessionCategoryTypeValues; // TODO: Hooks should add a type

  useEffect(() => {
    if (!supportSessionCategory) return;

    if (os === 'iOS') {
      launchSosIosApp(code, supportSessionCategory);
      launchSosAppStore();
    } else if (os === 'Android') {
      launchSosAndroidApp(code, supportSessionCategory);
    }
  }, [os, code, supportSessionCategory]);

  const handleLaunchApp = () => {
    if (!supportSessionCategory) return;

    if (os === 'iOS') {
      launchSosIosApp(code, supportSessionCategory);
    } else if (os === 'Android') {
      launchSosAndroidApp(code, supportSessionCategory);
    }
  };

  const handleLaunchStore = () => {
    if (!supportSessionCategory) return;

    if (os === 'iOS') {
      launchSosAppStore();
    } else if (os === 'Android') {
      launchSosPlayStore(code, supportSessionCategory);
    }
  };

  const isAndroid = os === 'Android';

  if (isLoading) {
    return <FullPageLoading />;
  }

  return (
    <Card maxWidth="640px" mb="15px">
      {showLogo && (
        <>
          <Logo.Splashtop />
          <Box mt="1.5em" mb="1em">
            <Logo.SupportApp />
          </Box>
        </>
      )}
      <RippleTypography variant="heading05" color="dark.90" py="16px">
        {t('sos-package:downloadMobile.title')}
      </RippleTypography>
      <Box>
        <List as="ol" styleType="none" pt="48px" spacing="24px">
          <ListItem fontSize="14px" display="flex">
            <NumberTag>1</NumberTag>
            <Flex flexDirection="column" pl="16px" pt="6px">
              <RippleTypography variant="body02" color="dark.90">
                {isAndroid ? t('sos-package:downloadMobile.step1.line1.android') : t('sos-package:downloadMobile.step1.line1.ios')}
              </RippleTypography>
              {isAndroid && (
                <Box pt="12px">
                  <button onClick={handleLaunchStore}>
                    <RipplePlayStore />
                  </button>
                </Box>
              )}
            </Flex>
          </ListItem>
          <ListItem fontSize="14px" display="flex">
            <NumberTag>2</NumberTag>
            <Flex flexDirection="column" pl="16px" pt="6px">
              <RippleTypography variant="body02" color="dark.90">
                {t('sos-package:downloadMobile.step2.line1')}
              </RippleTypography>
              <RippleTypography variant="body02" color="neutral.100">
                {t('sos-package:downloadMobile.step2.line2')}
              </RippleTypography>
              <Box pt="12px">
                <RippleButton size="md" width="initial" onClick={handleLaunchApp}>
                  {t('sos-package:downloadMobile.launchApp')}
                </RippleButton>
              </Box>
            </Flex>
          </ListItem>
          <ListItem fontSize="14px" display="flex">
            <NumberTag>3</NumberTag>
            <Flex flexDirection="column" pl="16px" pt="6px">
              <RippleTypography variant="body02" color="dark.90">
                {t('sos-package:downloadMobile.step3.line1')}
              </RippleTypography>
              <RippleTypography variant="body02" color="neutral.100">
                {t('sos-package:downloadMobile.step3.line2')}
              </RippleTypography>
            </Flex>
          </ListItem>
        </List>
      </Box>

      <Box>
        <RippleDivider my="2em" />
        <RippleTypography variant="body02" color="dark.90">
          {t('sos-package:download.note.line1')}
        </RippleTypography>
        <br />
        <RippleTypography variant="body02" color="dark.90">
          {t('sos-package:download.note.line2')}
        </RippleTypography>
      </Box>
    </Card>
  );
};

export default DownloadMobileCard;
