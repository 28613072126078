import axios from 'axios';

import { checkResponse } from '@/services/utils';

import { GranularControlKey, granularControlMapSchema } from './types';

const method = 'GET';

const getUrl = (teamId: number, features: Array<GranularControlKey>) => {
  const params = new URLSearchParams({ controls: features.join(',') });
  return `/api/web/v1/teams/${teamId}/granular_controls?${params.toString()}`;
};

const schema = granularControlMapSchema;

const execute = (teamId: number, features: Array<GranularControlKey>) =>
  checkResponse(axios.request({ method, url: getUrl(teamId, features) }), schema);

/**
 * Get granular control settings for a team
 *
 * @see https://splashtop.atlassian.net/wiki/spaces/CS/pages/1185972587/Team+Granular+Controls+API
 */
export const getTeamGranularControlService = {
  getUrl,
  method,
  execute,
} as const;
