import { useQuery } from '@tanstack/react-query';

import { infoQueryService } from '@/services/users';

import { shouldShowServiceDeskChannel, shouldShowServiceDeskSupportSession } from './utils';

/** Check whether the user can access service desk channels */
export const useChannelAccessQuery = () => {
  return useQuery({
    queryKey: ['info-query', 'channel-access'],
    queryFn: async () => {
      return await infoQueryService.execute({
        team_member_info: ['role', 'member_status', 'group_scope'],
        team_permissions: ['sos_service_desk'],
        team_member_permissions: ['attended_access', 'technician_manager'],
      });
    },
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 5 * 60 * 1000, // 5 minutes
    select: (data) =>
      Boolean(
        Object.entries(data).find(([_teamKind, { team_member_info, team_permissions, team_member_permissions }]) => {
          return shouldShowServiceDeskChannel({
            role: team_member_info.role,
            groupScope: team_member_info.group_scope,
            memberStatus: team_member_info.member_status,
            sosServiceDesk: team_permissions.sos_service_desk,
            attendedAccess: team_member_permissions.attended_access,
            technicianManager: team_member_permissions.technician_manager,
          });
        }),
      ),
  });
};

/** Check whether the user can access service desk support session */
export const useSupportSessionAccessQuery = () => {
  return useQuery({
    queryKey: ['info-query', 'support-session-access'],
    queryFn: async () => {
      return await infoQueryService.execute({
        team_member_info: ['member_status'],
        team_permissions: ['sos_service_desk'],
        team_member_permissions: ['attended_access'],
      });
    },
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 5 * 60 * 1000, // 5 minutes
    select: (data) =>
      Boolean(
        Object.values(data).find(({ team_member_info, team_permissions, team_member_permissions }) =>
          shouldShowServiceDeskSupportSession({
            memberStatus: team_member_info.member_status,
            sosServiceDesk: team_permissions.sos_service_desk,
            attendedAccess: team_member_permissions.attended_access,
          }),
        ),
      ),
  });
};
