import { useState } from 'react';

import { Box, InputLeftElement, forwardRef } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { RippleArrowLeft, RippleInput, RippleInputGroup, RippleMenuDivider, RippleMenuItem, RippleSearch, RippleTooltip } from '@/design';
import { DEFAULT_GROUP_ID } from '@/modules/Computer';
import { Group } from '@/services/group';

import { HorizontalCollapse } from './HorizontalCollapse';

export type GroupListProps = {
  isOpen: boolean;
  minHeight?: number;
  groups: Array<Group>;
  selectedGroupId?: number;
  onGroupSelect: (groupId?: number) => void;
  onClose: () => void;
  onAnimateComplete?: () => void;
};
export const GroupList = forwardRef(
  ({ groups, isOpen, minHeight, selectedGroupId, onClose, onGroupSelect, onAnimateComplete }: GroupListProps, ref) => {
    const { t } = useTranslation();
    const [keyword, setKeyword] = useState('');

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        setKeyword(e.currentTarget.value.toLowerCase());
      }
    };

    const keywords = keyword.split(' ').filter((k) => k.length > 0);

    return (
      <HorizontalCollapse isOpen={isOpen} onAnimateComplete={onAnimateComplete}>
        <Box background="white" ref={ref} borderRadius="4px" overflow="hidden" py="6px" minHeight={minHeight}>
          <RippleMenuItem icon={<RippleArrowLeft />} onClick={onClose}>
            {t('common:filter_by_group')}
          </RippleMenuItem>
          <RippleMenuDivider />
          <Box p="6px 12px">
            <RippleInputGroup>
              <InputLeftElement height="32px" w="32px">
                <RippleSearch />
              </InputLeftElement>
              <RippleInput size="xs" placeholder="Search" onKeyDown={handleKeyDown} />
            </RippleInputGroup>
          </Box>
          <Box maxHeight="300px" overflowY="scroll" pt="6px">
            <RippleMenuItem isSelected={typeof selectedGroupId === 'undefined'} onClick={() => onGroupSelect(undefined)}>
              {t('common:all_groups')}
            </RippleMenuItem>
            <RippleMenuItem
              isSelected={selectedGroupId === Number(DEFAULT_GROUP_ID)}
              onClick={() => onGroupSelect(Number(DEFAULT_GROUP_ID))}
            >
              {t('common:default_group')}
            </RippleMenuItem>
            <RippleMenuDivider />
            {groups
              .filter((group) => keywords.length === 0 || keywords.some((keyword) => group.name.toLowerCase().includes(keyword)))
              .map((group) => {
                return (
                  <RippleMenuItem key={group.id} isSelected={group.id === selectedGroupId} onClick={() => onGroupSelect(group.id)}>
                    <RippleTooltip label={group.name} aria-label={group.name}>
                      {group.name}
                    </RippleTooltip>
                  </RippleMenuItem>
                );
              })}
          </Box>
        </Box>
      </HorizontalCollapse>
    );
  },
);
