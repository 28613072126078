import { useEffect } from 'react';

import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';

import { useRippleFlashMessage } from '@/design';
import { updateSurveyStatus, updateSurveyStatusErrorSchema } from '@/services/serviceDesk/surveys';

export const useUpdateSurveyStatusMutation = () => {
  const { flashMessage } = useRippleFlashMessage();
  const { t } = useTranslation();

  const updateStatusMutation = useMutation({
    mutationFn: updateSurveyStatus,
  });

  const { isError, error } = updateStatusMutation;

  useEffect(
    function handleError() {
      if (isError) {
        const errorValidation = updateSurveyStatusErrorSchema.safeParse(error);
        if (!errorValidation.success) {
          console.error('Error status code is not valid', errorValidation.error);
          return;
        }
        switch (errorValidation.data.result) {
          case 40422: {
            flashMessage({ variant: 'error', title: t('survey:please_contact_splashtop_support_team') });
            break;
          }
          case 40403:
          case 41403: {
            flashMessage({ variant: 'error', title: t('survey:unable_to_access_the_survey') });
            break;
          }
          case 40404: {
            flashMessage({ variant: 'error', title: t('survey:survey_does_not_exist') });
            break;
          }
          default: {
            const exhaustiveCheck: never = errorValidation.data.result;
            // @ts-ignore-next-line
            throw new Error(`Unhandled error case: ${exhaustiveCheck.data.result}`);
          }
        }
      }
    },
    [error, flashMessage, isError, t],
  );

  return updateStatusMutation;
};
