import { Box } from '@chakra-ui/react';
import type { BoxProps } from '@chakra-ui/react';

type HeaderLeftScrollOverlayProps = BoxProps;
export function HeaderLeftScrollOverlay({ children, ...otherProps }: HeaderLeftScrollOverlayProps): React.JSX.Element {
  return (
    <Box zIndex={-1} position="absolute" w="calc(100% + 8px)" h="100%" top="0" left="0" overflow="hidden" {...otherProps}>
      <Box position="absolute" top="0" left="0" w="calc(100% - 8px)" h="100%" boxShadow="1px 0px 4px 1px rgba(0, 0, 0, 0.16)" />
    </Box>
  );
}

type HeaderRightScrollOverlayProps = BoxProps;
export function HeaderRightScrollOverlay({ children, ...otherProps }: HeaderRightScrollOverlayProps): React.JSX.Element {
  return (
    <Box zIndex={-1} position="absolute" w="calc(100% + 8px)" h="100%" top="0" right="0" overflow="hidden" {...otherProps}>
      <Box position="absolute" top="0" right="0" w="calc(100% - 8px)" h="100%" boxShadow="-1px 0px 4px 1px rgba(0, 0, 0, 0.16)" />
    </Box>
  );
}

type CellLeftScrollOverlayProps = BoxProps;
export function CellLeftScrollOverlay({ children, ...otherProps }: CellLeftScrollOverlayProps): React.JSX.Element {
  return (
    <Box zIndex={-1} position="absolute" w="calc(100% + 8px)" h="100%" top="0" left="0" overflow="hidden" {...otherProps}>
      <Box position="absolute" top="0" left="0" w="calc(100% - 8px)" h="100%" boxShadow="1px 0px 4px 1px rgba(0, 0, 0, 0.16)" />
    </Box>
  );
}

type CellRightScrollOverlayProps = BoxProps;
export function CellRightScrollOverlay({ children, ...otherProps }: CellRightScrollOverlayProps): React.JSX.Element {
  return (
    <Box zIndex={-1} position="absolute" w="calc(100% + 8px)" h="100%" top="0" right="0" overflow="hidden" {...otherProps}>
      <Box position="absolute" top="0" right="0" w="calc(100% - 8px)" h="100%" boxShadow="-1px 0px 4px 1px rgba(0, 0, 0, 0.16)" />
    </Box>
  );
}
