import { useQuery } from '@tanstack/react-query';

import { useUnattendedTeamInformation } from '@/models/TeamInformation';
import { infoQueryService } from '@/services/users';

export const useInventoryAccess = () => {
  const unattendedTeamInformation = useUnattendedTeamInformation();
  const infoQuery = useQuery({
    queryKey: ['inventory', 'info'],
    queryFn: () => infoQueryService.execute({ team_info: ['is_expired'], team_member_permissions: ['inventory'] }),
  });

  const isTeamAccessible =
    infoQuery.data && unattendedTeamInformation?.teamKind
      ? infoQuery.data[unattendedTeamInformation.teamKind]?.team_member_permissions.inventory
      : false;

  const isAccessibleTeamExpired =
    infoQuery.data && unattendedTeamInformation?.teamKind
      ? infoQuery.data[unattendedTeamInformation.teamKind]?.team_member_permissions.inventory &&
        infoQuery.data[unattendedTeamInformation.teamKind]?.team_info.is_expired
      : false;

  return {
    isFetched: infoQuery.isFetched,
    canAccess: isTeamAccessible,
    isExpired: isAccessibleTeamExpired,
  };
};
