import { z } from 'zod';

import { commonResponseSchema } from '@/services/schemas';

export const updateSurveyStatusResponseSchema = z.object({});
export type UpdateSurveyStatusResponse = z.infer<typeof updateSurveyStatusResponseSchema>;

export const updateSurveyStatusErrorSchema = commonResponseSchema.merge(
  z.object({
    result: z.union([z.literal(40422), z.literal(40403), z.literal(41403), z.literal(40404)]),
  }),
);

export type UpdateSurveyStatusError = z.infer<typeof updateSurveyStatusErrorSchema>;
