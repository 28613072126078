import { createContext } from 'react';

import type { ColumnPermissions, ScrollPosition } from './types';

/**
 * TODO: Use jotai atom instead of context
 */
export const ColumnPermissionsContext = createContext<ColumnPermissions>({} as never);

export const ScrollPositionContext = createContext<ScrollPosition>({} as never);
