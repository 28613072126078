import axios from 'axios';
import { z } from 'zod';

import { checkResponse } from '../utils';

const method = 'GET';
const getUrl = () => '/api/web/v1/users/quick_setup_info';

const schema = z.object({
  instruction: z.string(),
  srs_download_kind: z.union([z.literal('none'), z.literal('download_srs'), z.literal('download_csrs')]),
  srs_download_link: z.string(),
  src_launch_uri: z.string(),
});

const execute = () => checkResponse(axios.request({ method, url: getUrl() }), schema);

export const getQuickSetupInfoService = {
  method,
  getUrl,
  execute,
};
