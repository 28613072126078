import { Box, BoxProps, forwardRef } from '@chakra-ui/react';

const Container = forwardRef<BoxProps, 'div'>((props, ref) => (
  <Box
    ref={ref}
    position="relative"
    boxSizing="border-box"
    display="flex"
    flexDirection="column"
    minWidth="290px"
    padding="0 15px"
    margin="auto"
    {...props}
  />
));

export default Container;
