import { HStack, ListItem, UnorderedList, forwardRef } from '@chakra-ui/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { Trans, useTranslation } from 'next-i18next';
import { Virtuoso } from 'react-virtuoso';

import {
  RippleBodyText02,
  RippleButton,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalTitle,
  RippleStrong,
} from '@/design';

import { useResultModal } from './ResultModal';
import { TargetComputerItem } from './types';

export const confirmModalAtom = atomWithReset<{ isOpen: boolean; targetComputerList: Array<TargetComputerItem> }>({
  isOpen: false,
  targetComputerList: [],
});

export function useConfirmModal() {
  const set = useSetAtom(confirmModalAtom);

  return {
    open({ targetComputerList }: { targetComputerList: Array<TargetComputerItem> }) {
      set({ isOpen: true, targetComputerList });
    },
  };
}

export function ConfirmModal(): React.JSX.Element {
  const { t } = useTranslation();
  const { isOpen, targetComputerList } = useAtomValue(confirmModalAtom);
  const resetModal = useResetAtom(confirmModalAtom);

  const resultModal = useResultModal();

  function handleConfirm() {
    resetModal();
    resultModal.open({ targetComputerList });
  }

  return (
    <RippleModal isOpen={isOpen} onClose={resetModal} size="xl">
      <RippleModalOverlay />
      <RippleModalContent>
        <RippleModalHeader>
          <RippleModalTitle>{t('computer:delete_computers')}</RippleModalTitle>
        </RippleModalHeader>
        <RippleModalBody display="flex" flexDirection="column">
          <RippleBodyText02>
            <Trans
              t={t}
              i18nKey="computer:deleteComputers.confirmModal.hint"
              components={{ Strong: <RippleStrong variant="strong02" color="red.100" as="strong" /> }}
            />
          </RippleBodyText02>

          <Virtuoso
            style={{ height: 400, marginTop: 8 }}
            components={{
              List: forwardRef((props, ref) => <UnorderedList ref={ref} {...props} />),
              Item: forwardRef((props, ref) => <ListItem ref={ref} {...props} />),
            }}
            data={targetComputerList}
            itemContent={(_index, computer) => <RippleBodyText02>{computer.name}</RippleBodyText02>}
          />
        </RippleModalBody>
        <RippleModalFooter>
          <HStack spacing="8px">
            <RippleButton data-testid="close-button" variant="grayScaleGhost" onClick={resetModal}>
              {t('common:close')}
            </RippleButton>
            <RippleButton data-testid="submit-button" variant="danger" onClick={handleConfirm}>
              {t('common:delete')}
            </RippleButton>
          </HStack>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
}
