import { useMemo } from 'react';

import { Flex } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import { Virtuoso } from 'react-virtuoso';

import { RippleHeading08, RippleSingleSelect, RippleSingleSelectOption } from '@/design';
import { getPreferencePolicyList } from '@/services/preferencePolicy';
import { PreferencePolicySummary } from '@/services/preferencePolicy/types';
import useTeamInformation from '@/utils/useTeamInformation';

import { filterPreferencePolicyAtom } from '../../atoms';

const FILTER_ALL_POLICY = '';
const preferencePolicyListPlaceholder: Array<PreferencePolicySummary> = [];

export function FilterPreferencePolicy(): React.JSX.Element {
  const { t } = useTranslation();
  const teamInformation = useTeamInformation();
  const teamId = teamInformation?.teamId ?? 0;

  const preferencePolicyListQuery = useQuery({
    queryKey: ['filterDrawer', 'getPreferencePolicyList'],
    queryFn: () => {
      return getPreferencePolicyList(teamId);
    },
  });
  const preferencePolicyList = preferencePolicyListQuery.data ?? preferencePolicyListPlaceholder;

  const [filterPreferencePolicy, setFilterPreferencePolicy] = useAtom(filterPreferencePolicyAtom);
  const selectValue = filterPreferencePolicy === null ? FILTER_ALL_POLICY : filterPreferencePolicy;

  const optionList = useMemo<Array<{ value: string; label: string }>>(
    () => [{ value: FILTER_ALL_POLICY, label: 'All policies' }, ...preferencePolicyList.map(({ name }) => ({ value: name, label: name }))],
    [preferencePolicyList],
  );

  return (
    <Flex flexDirection="column">
      <RippleHeading08 mb="8px">{t('computer:filterDrawer.preferencePolicy.label')}</RippleHeading08>
      <RippleSingleSelect
        data-testid="filter-preference-policy-select-button"
        value={selectValue}
        placeholder=""
        onChange={(value) => {
          if (typeof value !== 'undefined') {
            const filterValue = value === FILTER_ALL_POLICY ? null : value;
            setFilterPreferencePolicy(filterValue);
          }
        }}
      >
        {/* TODO: This is temporary implementation, need to implement a virtualized single select */}
        <Virtuoso
          data={optionList}
          style={{ height: 300 }}
          itemContent={(_index, { value, label }) => (
            <RippleSingleSelectOption key={value} value={value}>
              {label}
            </RippleSingleSelectOption>
          )}
        />
      </RippleSingleSelect>
    </Flex>
  );
}
