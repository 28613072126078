import * as Sentry from '@sentry/nextjs';
import { compare } from 'compare-versions';
import UAParser from 'ua-parser-js';

export default function checkIsUnsupportedSafari() {
  try {
    const uaParser = new UAParser();
    const browser = uaParser.getBrowser();
    const isSafari = browser.name === 'Safari';
    const isSupported = compare(browser.version || '0', '15.6.1', '>=');
    return isSafari && !isSupported;
  } catch (error) {
    Sentry.captureException(error);
    return false;
  }
}
