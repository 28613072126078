/** URL search parameter keys */
export const searchParamKeys = {
  /** actions to preform on survey list page */
  MODAL: 'modal',
  /** survey id */
  SURVEY_ID: 'survey-id',
};

export const modalType = {
  /** action to open assign-channel modal on survey list page */
  ASSIGN_CHANNEL: 'assign-channel',
};
