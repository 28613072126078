import { atom } from 'jotai';
import { RESET, atomWithReset } from 'jotai/utils';

// Primitive atoms
/** Whether to show only selected groups and computers */
export const isSelectedOnlyFilterAtom = atomWithReset(false);
/** Whether to show only computers that is following group */
export const followGroupFilterAtom = atomWithReset(false);
/** Which group is selected for filtering */
export const groupIdFilterAtom = atomWithReset<number | undefined>(undefined);
/** Which policies are selected for filtering */
export const policyFilterSetAtom = atomWithReset<Set<number>>(new Set<number>());

// Derived atoms
/** Setter to toggle isSelected atom */
export const toggleSelectedOnlyAtom = atom(null, (get, set) => {
  set(isSelectedOnlyFilterAtom, !get(isSelectedOnlyFilterAtom));
});

/** Setter to add or remove policy from the policy filter Set */
export const setPolicyFilterAtom = atom(null, (get, set, id: number) => {
  const policies = get(policyFilterSetAtom);
  const newSet = new Set(policies);
  newSet.has(id) ? newSet.delete(id) : newSet.add(id);
  set(policyFilterSetAtom, newSet);
});

/** Getter for the total amount of applied filters */
export const filterCountAtom = atom((get) => {
  const isFollowGroup = get(followGroupFilterAtom);
  const groupFilter = get(groupIdFilterAtom);
  const policyFilterSet = get(policyFilterSetAtom);
  const isSelectedOnlyFilter = get(isSelectedOnlyFilterAtom);
  return [isFollowGroup || typeof groupFilter !== 'undefined', policyFilterSet.size, isSelectedOnlyFilter].filter((filter) =>
    Boolean(filter),
  ).length;
});

/** Setter to reset all primitive filter atoms */
export const resetAllFilterAtom = atom(null, (_get, set) => {
  set(isSelectedOnlyFilterAtom, RESET);
  set(followGroupFilterAtom, RESET);
  set(groupIdFilterAtom, RESET);
  set(policyFilterSetAtom, RESET);
});
