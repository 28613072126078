/**
 * Delete EMM policy
 * @see https://github.com/SplashtopInc/be_kung_fu/blob/master/be_app/api/web-api/v1/emm/emm_policies.md#delete-emm-policy-api
 */
import axios from 'axios';
import { z } from 'zod';

import { commonResponseSchema } from '@/services/schemas';
import { checkResponse, createGenericErrorHandlers } from '@/services/utils';

const getUrl = (teamId: number, policyId: number) => `/api/web/v1/teams/${teamId}/emm_policies/${policyId}`;
const METHOD = 'DELETE';

const responseSchema = z.object({}).nullable();

const execute = (teamId: number, policyId: number) =>
  checkResponse(axios({ method: METHOD, url: getUrl(teamId, policyId) }), responseSchema);

const errorHandling = {
  ...createGenericErrorHandlers(),
  /**
   * @code 40403
   * - Can not delete super root
   * - Can only delete leaf node of inherited policy tree (customized policy is not included)
   */
  actionNotAllow: (response: any) => {
    const errorSchema = commonResponseSchema.merge(
      z.object({ result: z.literal(40403), data: z.object({ error: z.literal('action_not_allow') }) }),
    );
    return errorSchema.safeParse(response).success;
  },
  /**
   * @code 40404
   * - Policy is not exist
   */
  resourceNotExist: (response: any) => {
    const errorSchema = commonResponseSchema.merge(
      z.object({ result: z.literal(40404), data: z.object({ error: z.literal('resource_not_exists') }) }),
    );
    return errorSchema.safeParse(response).success;
  },
} as const;

export const deleteEmmPolicyService = { getUrl, method: METHOD, execute, errorHandling };
