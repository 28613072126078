import { useFeatureState } from './useFeatureState';

/**
 * If `create_group_admin` feature is enabled, show group admin role switch.
 */
export function useShowGroupAdmin(): boolean {
  const createGroupAdminState = useFeatureState('create_group_admin');

  return createGroupAdminState?.value === true;
}
