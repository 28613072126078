import { useMutation } from '@tanstack/react-query';

import { useFillForm } from '@/modules/ServiceDesk/SurveyForm/EndUser/hooks/useFillForm';
import { SubmitQuestion, SubmitSurveyPayload, submitSurvey as submitSurveyAPI } from '@/services/serviceDesk/surveys/submitSurvey';
import preSanitize from '@/utils/pre-sanitize';

export const useSubmitForm = ({ supportSessionCode }: { supportSessionCode: string }) => {
  const { form } = useFillForm({
    defaultValues: {
      questionnaire: [],
    },
  });

  const {
    mutateAsync: submitSurvey,
    isPending: isSubmittingForm,
    isError: isSubmitSurveyError,
    data: submitSurveyResult,
  } = useMutation({
    mutationFn: async (formData: SubmitSurveyPayload) => {
      return submitSurveyAPI({ supportSessionCode, payload: formData });
    },
  });

  const handleSubmit = () => {
    form.handleSubmit(async (formValue) => {
      const requestData: SubmitSurveyPayload = {
        questionnaire: formValue.questionnaire.map((field) => {
          return {
            ...field,
            answer: sanitizeAnswer(field.answer),
          };
        }),
      };
      submitSurvey(requestData);
    })();
  };

  return { form, handleSubmit, isSubmittingForm, submitSurveyResult, isSubmitSurveyError };
};

const sanitizeAnswer = (value: SubmitQuestion['answer']) => {
  if (value === null) {
    return null;
  }
  if (typeof value === 'number') {
    return value;
  }
  if (typeof value === 'string') {
    return preSanitize(value);
  }
  if (Array.isArray(value)) {
    return value.map((value) => preSanitize(value));
  }
  return null;
};
