import * as Sentry from '@sentry/nextjs';
import { useMutation } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { CheckFeatureIdSetFn, TeamMatches } from '@/models/Team';
import { updateTeamGrantGranularControlService } from '@/services/teams/grant_granular_controls';
import { GrantGranularControlMap, GrantGranularControlSetting } from '@/services/teams/grant_granular_controls/types';
import { updateTeamGranularControlService } from '@/services/teams/granular_controls';
import {
  GranularControlDetailSetting,
  GranularControlDetailSettingKey,
  GranularControlKey,
  GranularControlMap,
  GranularControlSetting,
} from '@/services/teams/granular_controls/types';

import { queryMainKey } from '../constants';
import { showErrorMessageOnRailsPage } from '../utils';
import { useTeamControlContext } from './useTeamControlContext';

type MutationPayload =
  | {
      controlKey: GranularControlKey;
      type: 'normal';
      value: GranularControlSetting;
      /** Required when the control key is on if the control key has detail setting */
      detailSetting?: GranularControlDetailSetting;
    }
  | { controlKey: GranularControlKey; type: 'grant'; value: GrantGranularControlSetting };

/**
 * Mutation hook for granular control.
 */
export function useFeatureGranularControlMutation() {
  const { t } = useTranslation();

  const { atoms, teamInfo } = useTeamControlContext();
  const teamMetadata = useAtomValue(atoms.teamMetadataAtom);
  const dispatch = useSetAtom(atoms.granularControlAtom);

  const canMutateGranularControl = !isLegacySosTeam(teamInfo);

  return useMutation({
    mutationKey: [queryMainKey, 'mutateGranular'],
    mutationFn: async (mutationPayload: MutationPayload) => {
      if (!canMutateGranularControl) return null;

      switch (mutationPayload.type) {
        case 'normal': {
          const { controlKey, value, detailSetting } = mutationPayload;

          const payload: GranularControlMap = {
            [controlKey]: value,
            ...(detailSetting ?? {}),
          };
          return updateTeamGranularControlService.execute(teamMetadata.team_id, payload);
        }
        case 'grant': {
          const { controlKey, value } = mutationPayload;
          const payload: GrantGranularControlMap = { [controlKey]: value };
          return updateTeamGrantGranularControlService.execute(teamMetadata.team_id, payload);
        }
      }
    },
    onMutate: (mutatePayload) => {
      dispatch({
        type: 'update',
        updateDraft: (draft) => {
          const setting = draft[mutatePayload.controlKey];
          if (setting) {
            switch (mutatePayload.type) {
              case 'normal': {
                const { type, value } = mutatePayload;
                setting[type] = { ...setting[type], ...value };
                break;
              }

              // Keep flexible for future usage / extension
              // eslint-disable-next-line sonarjs/no-duplicated-branches
              case 'grant': {
                const { type, value } = mutatePayload;
                setting[type] = value;
                break;
              }
            }
          }

          if (mutatePayload.type === 'normal' && mutatePayload.detailSetting) {
            const detailSettingKeys = Object.keys(mutatePayload.detailSetting) as Array<GranularControlDetailSettingKey>;
            detailSettingKeys.forEach((key) => {
              const currentDetailSetting = draft[key];
              const newDetailSettingValue = mutatePayload.detailSetting?.[key];
              if (currentDetailSetting?.normal && newDetailSettingValue) {
                currentDetailSetting.normal = newDetailSettingValue;
              }
            });
          }
        },
      });
    },
    onSuccess: () => {
      dispatch({ type: 'save' });
    },
    onError: (error) => {
      Sentry.captureException(error);
      showErrorMessageOnRailsPage(t('common:unexpectedError'));
      dispatch({ type: 'rollback' });
    },
  });
}

/**
 * SOS team has no granular control.
 */
const isLegacySosTeam: CheckFeatureIdSetFn = (featureIdSet) =>
  [
    TeamMatches.SOS,
    TeamMatches.SOS_Basic,
    TeamMatches.SOS_Enterprise,
    TeamMatches.SOS_Teams,
    TeamMatches.SOS_with_Mobile,
    TeamMatches.SOS_with_unattended_computers,
  ].some((matchFn) => matchFn(featureIdSet));
