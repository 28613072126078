import Image from 'next/image';
import { CSSProperties, useRef, useState } from 'react';

import { Box, Center, Flex, HStack, VStack, useClipboard } from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';
import { Trans, useTranslation } from 'next-i18next';

import {
  RippleArrowTailLeft,
  RippleArrowTailRight,
  RippleBodyText02,
  RippleBodyText03,
  RippleButton,
  RippleDivider,
  RippleHeading06,
  RippleHeading07,
  RippleHeading09,
  RippleReadyOutline,
  RippleRefreshIconButton,
} from '@/design';
import CurrentDeviceImage from '@/modules/ServiceDesk/SupportForm/Editor/assets/current-device.png';
import OtherDeviceImage from '@/modules/ServiceDesk/SupportForm/Editor/assets/other-device.png';
import { DownloadPageKind, DownloadPageKindValue } from '@/services/serviceDesk/supportForm/types';
import { convertToUserTimezone } from '@/utils/formatTimezone';

type DownloadSectionProps = {
  expiredDate?: Date;
  helpLink: React.JSX.Element;
  isDisableGetAppButton?: boolean;
  isLoadingGetAppLink?: boolean;
  isRefreshingPinCode?: boolean;
  kind: DownloadPageKindValue;
  linkForCopy: string;
  minH?: CSSProperties['minHeight'];
  downloadUrl: string;
  onRefreshPinCode: () => void;
  pinCode: string;
};

export function DownloadSection({
  minH = '480px',
  kind,
  downloadUrl,
  isDisableGetAppButton = false,
  isLoadingGetAppLink = false,
  helpLink,
  pinCode,
  onRefreshPinCode,
  isRefreshingPinCode,
  expiredDate,
  linkForCopy,
}: DownloadSectionProps) {
  const [isMore, setIsMore] = useState(true);
  const handleClickMore = () => setIsMore((pre) => !pre);
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const dimensions = useSize(containerRef);
  const isShowDesktopLayout = dimensions && dimensions.width >= 430;

  const styles = isShowDesktopLayout
    ? {
        wrapper: { flexDirection: 'row' },
        methodWrapper: { borderRight: '1px solid', pr: '16px', borderColor: 'neutral.60' },
        divider: { pl: '16px' },
        button: { alignSelf: 'center' },
      }
    : {
        wrapper: { flexDirection: 'column' },
        methodWrapper: { borderBottom: '1px solid', pb: '16px', borderColor: 'neutral.60' },
        divider: { pt: '16px' },
        button: { alignSelf: 'flex-start' },
      };

  const { onCopy, hasCopied } = useClipboard(linkForCopy);

  const handleCopy = () => {
    if (!linkForCopy) return;
    onCopy();
  };

  return (
    <>
      <VStack w="100%" maxW={window.innerWidth} minH={minH} py="20px" gap="24px">
        <HStack gap="8px" alignSelf="flex-start" px="24px">
          <RippleReadyOutline color="green.200" />
          <RippleHeading06>{t('support-forms:issueSubmitted')}</RippleHeading06>
        </HStack>
        <Box px="24px" w="100%">
          <RippleDivider />
        </Box>
        <HStack
          ref={containerRef}
          w="100%"
          pl={isMore ? '24px' : '16px'}
          pr={isMore ? '16px' : '24px'}
          spacing="16px"
          flexGrow={1}
          alignItems="stretch"
        >
          {isMore ? (
            <Flex w="100%" flexDirection="column" alignItems="center" gap="12px" p="16px" borderRadius="4px" bg="blue.10">
              {kind === DownloadPageKind.SPLASHTOP_CLASSIC && (
                <Center w="123px" h="88px">
                  <Image src={CurrentDeviceImage} alt="current device" width={123} style={{ height: 'auto' }} />
                </Center>
              )}
              <RippleHeading07 textAlign="center">{t('support-forms:getSupportOnThisDevice')}</RippleHeading07>
              <RippleBodyText02 flexGrow={1} color="neutral.300" textAlign="center">
                {t('support-forms:downloadSplashtopSOS')}
              </RippleBodyText02>
              <RippleButton
                w="100%"
                isDisabled={isDisableGetAppButton}
                isLoading={isLoadingGetAppLink}
                {...(downloadUrl
                  ? { as: 'a', href: downloadUrl, target: isMobileAppUrl(downloadUrl) ? '_blank' : '_self' }
                  : { onClick: () => null })}
              >
                {t('support-forms:getTheApp')}
              </RippleButton>
            </Flex>
          ) : (
            <Flex w="100%" order={1} flexDirection="column" alignItems="center" gap="12px" p="16px" borderRadius="4px" bg="blue.10">
              {kind === DownloadPageKind.SPLASHTOP_CLASSIC && (
                <Center w="123px" h="88px">
                  <Image src={OtherDeviceImage} alt="other device" width={123} style={{ height: 'auto' }} />
                </Center>
              )}
              <RippleHeading07 textAlign="center">{t('support-forms:getSupportOnOtherDevice')}</RippleHeading07>
              <Flex w="100%" h="100%">
                <Flex w="100%" h="100%" justifyContent="center" sx={styles.wrapper}>
                  <Flex w="100%" flexDirection="column" align="center" sx={styles.methodWrapper}>
                    <DownloadSectionTitle>{t('support-forms:downloadViaPinCode')}</DownloadSectionTitle>
                    <DownloadSectionContent>
                      <Trans t={t} i18nKey="support-forms:gotoHelp123" components={{ Link: helpLink }} />
                    </DownloadSectionContent>
                    <Flex w="100%" flexDirection="column" align="center" p="4px" bg="white" borderRadius="4px">
                      <Flex align="center">
                        <RippleHeading06>{pinCode}</RippleHeading06>
                        <RippleRefreshIconButton isSize16 isLoading={Boolean(isRefreshingPinCode)} onClick={onRefreshPinCode} />
                      </Flex>
                      <RippleBodyText03 color="dark.40" textAlign="center">
                        {t('support-forms:expireOn', { dateTime: expiredDate ? convertToUserTimezone(expiredDate) : '' })}
                      </RippleBodyText03>
                    </Flex>
                  </Flex>
                  <Flex w="100%" flexDirection="column" align="center" sx={styles.divider}>
                    <DownloadSectionTitle>{t('support-forms:downloadViaLink')}</DownloadSectionTitle>
                    <DownloadSectionContent>{t('support-forms:copyAndShareLink')}</DownloadSectionContent>
                    <RippleButton
                      w="100%"
                      variant="secondary"
                      size="sm"
                      onClick={handleCopy}
                      sx={hasCopied ? { borderColor: 'green.100', bg: 'green.10', color: 'green.200' } : undefined}
                    >
                      {hasCopied ? t('common:copied') : t('common:copyLink')}
                    </RippleButton>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          )}
          <VStack
            role="group"
            p="8px"
            borderRadius="4px"
            _hover={{ bg: 'neutral.10' }}
            _active={{ bg: 'neutral.40' }}
            cursor="pointer"
            onClick={handleClickMore}
            sx={styles.button}
          >
            {isMore ? (
              <RippleArrowTailRight _groupActive={{ color: 'dark.70' }} />
            ) : (
              <RippleArrowTailLeft _groupActive={{ color: 'dark.70' }} />
            )}
            <RippleBodyText03 fontWeight="semibold" color="neutral.300" _groupActive={{ color: 'dark.70' }}>
              {isMore ? t('common:more') : t('common:back')}
            </RippleBodyText03>
          </VStack>
        </HStack>
      </VStack>
    </>
  );
}

function DownloadSectionTitle({ children }: { children: React.ReactNode }) {
  return (
    <RippleHeading09 color="neutral.300" mb="4px">
      {children}
    </RippleHeading09>
  );
}

function DownloadSectionContent({ children }: { children: React.ReactNode }) {
  return (
    <RippleBodyText02 color="neutral.300" flexGrow={1} textAlign="center" mb="12px">
      {children}
    </RippleBodyText02>
  );
}

function isMobileAppUrl(url: string) {
  return url.startsWith('st-sos');
}
