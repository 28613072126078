import axios from 'axios';

import type { ResponseData } from '@/services/common/types';
import { isSuccess } from '@/services/utils';

import {
  type ChannelAssignedTechniciansResponse,
  type ChannelAssignedUserGroups,
  type ChannelDetailResponse,
  type ChannelListResponse,
  type ChannelRequestBody,
  type ChannelTechniciansResponse,
  type ChannelUserGroupsResponse,
  type SOSPackageListResponse,
} from './types';

function getChannelLists(teamId: number, page = 1, perPageSize = 20) {
  return new Promise<ChannelListResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: ChannelListResponse }>(`/api/web/v1/teams/${teamId}/service_desk/channels/`, {
        params: { page, per_page_size: perPageSize },
      })
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          if (data.total === 0) {
            reject({ statusCode: 9487, messages: [], errorReason: { error: 'wait for default channel' } });
          } else {
            resolve(data);
          }
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

async function getAllChannelLists(teamId: number) {
  const perPageSize = 100;
  let page = 1;
  let hasMore = true;
  let result: ChannelListResponse['data'] = [];

  while (hasMore) {
    const { total, data } = await getChannelLists(teamId, page, perPageSize);
    if (Array.isArray(data)) {
      result = result.concat(data);
    }
    hasMore = total > result.length;
    page = page + 1;
  }
  return result;
}

function getSOSPackageList(teamId: number) {
  return new Promise<SOSPackageListResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: SOSPackageListResponse }>(`/api/web/v1/teams/${teamId}/service_desk/channels/sos_packages`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getChannelDetail(teamId: number, channelId: number) {
  return new Promise<ChannelDetailResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: ChannelDetailResponse }>(`/api/web/v1/teams/${teamId}/service_desk/channels/${channelId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function createChannel(teamId: number, channel: ChannelRequestBody) {
  return new Promise<{ id: number }>((resolve, reject) => {
    axios
      .post<ResponseData & { data: { id: number } }>(`/api/web/v1/teams/${teamId}/service_desk/channels`, channel)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function updateChannel(teamId: number, channelId: number, channel: Partial<ChannelRequestBody>) {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .put<ResponseData & { data: { id: number } }>(`/api/web/v1/teams/${teamId}/service_desk/channels/${channelId}`, channel)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function deleteChannel(teamId: number, channelId: number) {
  return new Promise<{ status: string }>((resolve, reject) => {
    axios
      .delete<ResponseData & { data: { id: number } }>(`/api/web/v1/teams/${teamId}/service_desk/channels/${channelId}`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({ status: 'success' });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getChannelTechnicians(teamId: number) {
  return new Promise<ChannelTechniciansResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: ChannelTechniciansResponse }>(`/api/web/v1/teams/${teamId}/service_desk/channels/technicians`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getChannelAssignedTechnicians(teamId: number, channelId: number) {
  return new Promise<ChannelAssignedTechniciansResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: ChannelAssignedTechniciansResponse }>(
        `/api/web/v1/teams/${teamId}/service_desk/channels/${channelId}/assigned_technicians`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getChannelUserGroups(teamId: number) {
  return new Promise<ChannelUserGroupsResponse>((resolve, reject) => {
    axios
      .get<ResponseData & { data: ChannelUserGroupsResponse }>(`/api/web/v1/teams/${teamId}/service_desk/channels/user_groups`)
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function getChannelAssignedUserGroups(teamId: number, channelId: number) {
  return new Promise<ChannelAssignedUserGroups>((resolve, reject) => {
    axios
      .get<ResponseData & { data: ChannelAssignedUserGroups }>(
        `/api/web/v1/teams/${teamId}/service_desk/channels/${channelId}/assigned_user_groups`,
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve(data);
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function updateChannelTechnicians(teamId: number, channelId: number, addEmails: Record<string, number>, removeEmails: Array<string>) {
  return new Promise<{ success: Array<string>; fail: Array<string> }>((resolve, reject) => {
    axios
      .put<ResponseData & { data: { success: Array<string>; fail: Array<string> } }>(
        `/api/web/v1/teams/${teamId}/service_desk/channels/${channelId}/technicians`,
        {
          add_emails: addEmails,
          remove_emails: removeEmails,
        },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({
            success: data.success,
            fail: data.fail,
          });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

function updateChannelUserGroups(teamId: number, channelId: number, addGroupIds: Record<string, number>, removeGroupIds: Array<number>) {
  return new Promise<{ success: Array<number>; fail: Array<number> }>((resolve, reject) => {
    axios
      .put<ResponseData & { data: { success: Array<number>; fail: Array<number> } }>(
        `/api/web/v1/teams/${teamId}/service_desk/channels/${channelId}/user_groups
      `,
        {
          add_group_ids: addGroupIds,
          remove_group_ids: removeGroupIds,
        },
      )
      .then((res) => {
        const {
          data: { result, messages, data },
        } = res;
        if (isSuccess(result)) {
          resolve({
            success: data.success,
            fail: data.fail,
          });
        } else {
          reject({ statusCode: result, messages, errorReason: data });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
}

export {
  getChannelLists,
  getAllChannelLists,
  getSOSPackageList,
  getChannelDetail,
  createChannel,
  updateChannel,
  deleteChannel,
  getChannelTechnicians,
  getChannelAssignedTechnicians,
  getChannelAssignedUserGroups,
  getChannelUserGroups,
  updateChannelTechnicians,
  updateChannelUserGroups,
};
