import { useCallback } from 'react';

import { useAtomValue } from 'jotai';
import { selectAtom } from 'jotai/utils';
import isEqual from 'lodash/isEqual';

import { SettingKey, TeamSettings } from '@/services/teamSettings';

import { useTeamControlContext } from './useTeamControlContext';

/**
 * Get the feature state for a specific key.
 */
export function useFeatureState<Key extends SettingKey = SettingKey>(settingKey: Key) {
  const { atoms } = useTeamControlContext();
  const featureSettingAtom = selectAtom(
    atoms.teamSettingsAtom,
    useCallback((stateMap: TeamSettings) => stateMap[settingKey], [settingKey]),
    isEqual,
  );

  return useAtomValue(featureSettingAtom);
}
