import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';

import { getTeamSettings } from '@/services/teamSettings';

import { queryMainKey } from '../constants';
import { useTeamControlContext } from './useTeamControlContext';

/**
 * Refresh all feature state.
 */
export function useRefreshAllFeatureState() {
  const { teamKind, atoms } = useTeamControlContext();

  const dispatch = useSetAtom(atoms.teamSettingsAtom);

  const query = useQuery({
    queryKey: [queryMainKey, 'refreshAllFeatureState'],
    queryFn: getTeamSettings,
    enabled: false,
  });

  return useCallback(() => {
    query.refetch().then((result) => {
      if (result.isSuccess) {
        const teamDataSet = result.data;

        const featureStateMap = teamDataSet[teamKind]?.team_settings;
        if (featureStateMap) {
          dispatch({
            type: 'overwrite',
            updateDraft: () => {
              return featureStateMap;
            },
          });
        }
      }
    });
  }, [dispatch, query, teamKind]);
}
