import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { Trans, useTranslation } from 'next-i18next';

import {
  RippleButton,
  RippleHyperLink,
  RippleModal,
  RippleModalBody,
  RippleModalCloseButton,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalTitle,
  RippleTypography,
} from '@/design';
import { useTeamExpiration, useTeamInformation } from '@/models/TeamInformation';
import { CopyString, CopyStringButton } from '@/showcase';
import { SALES_EMAIL } from '@/utils/constants';

export const contactSalesModalAtom = atomWithReset<{ isOpen: boolean; title: string | undefined }>({ isOpen: false, title: undefined });

export function useContactSalesModal() {
  const set = useSetAtom(contactSalesModalAtom);

  return {
    open: ({ title }: { title?: string } = {}) => set({ isOpen: true, title }),
  };
}

type ContactSalesModalProps = {
  onClose?: () => void;
};
export function ContactSalesModal({ onClose: onCloseFromProps }: ContactSalesModalProps): React.JSX.Element {
  const { t } = useTranslation();

  const { sales_mail = SALES_EMAIL, is_resell } = useTeamInformation();

  const { isTeamExpired } = useTeamExpiration();

  const { isOpen, title } = useAtomValue(contactSalesModalAtom);
  const onClose = useResetAtom(contactSalesModalAtom);

  return (
    <RippleModal size="2xl" isOpen={isOpen} onClose={handleClose}>
      <RippleModalOverlay />
      <RippleModalContent>
        <RippleModalCloseButton />
        <ContactSalesModalTitle isTeamExpired={isTeamExpired} titleFromProp={title} />
        <ContactSalesModalBody salesMail={sales_mail} isResell={is_resell} />
        <RippleModalFooter>
          <RippleButton variant="primary" size="sm" onClick={handleClose}>
            {t('common:ok')}
          </RippleButton>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );

  function handleClose() {
    if (onCloseFromProps) {
      onCloseFromProps();
    }

    onClose();
  }
}

type CopyEmailProps = { email: string };
/**
 * `Trans` component will override the `CopyString` component's `children` prop, so we need to wrap it with a new component.
 */
function CopyEmail({ email }: CopyEmailProps): React.JSX.Element {
  return (
    <CopyString content={email}>
      <CopyStringButton isSize16 />
    </CopyString>
  );
}

/**
 * Due to modals in Team Settings require special modal components, we need to create this for the modal title in Team Settings.
 */
export function ContactSalesModalTitle({
  isTeamExpired,
  titleFromProp,
}: {
  isTeamExpired: boolean;
  titleFromProp?: string | undefined;
}): React.JSX.Element {
  const { t } = useTranslation();

  const title = getTitle();
  function getTitle() {
    if (titleFromProp) return titleFromProp;

    if (isTeamExpired) {
      return t('common:buy_now');
    }

    return t('common:change_number');
  }

  return (
    <RippleModalHeader>
      <RippleModalTitle>{title}</RippleModalTitle>
    </RippleModalHeader>
  );
}

/**
 * Due to modals in Team Settings require special modal components, we need to create this for the modal title in Team Settings.
 */
export function ContactSalesModalBody({ salesMail, isResell }: { salesMail: string; isResell: boolean }): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <RippleModalBody>
      <RippleTypography variant="body02">
        {isResell ? (
          t('common:please_contact_your_reseller')
        ) : (
          <Trans
            t={t}
            i18nKey="common:direct_purchase_is_not_allowed_please_contact_our_sales"
            components={{
              HyperLink: <RippleHyperLink variant="hyperlink02" href={`mailto:${salesMail}`} />,
              CopyString: <CopyEmail email={salesMail} />,
            }}
            values={{ salesMail: salesMail }}
          />
        )}
      </RippleTypography>
    </RippleModalBody>
  );
}
