import { Box, Flex, HStack } from '@chakra-ui/react';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';

import { RippleBodyText02, RippleCheckbox, RippleHeading08 } from '@/design';

import { columnVisibilityAtom, viewModeAtom } from '../../atoms';
import { GROUPING_COLUMN } from '../../constants';
import { ConfigurableColumn } from '../../types';

type ColumnVisibilitySettingProps = { configurableColumns: Array<ConfigurableColumn> };
export function ColumnVisibilitySetting({ configurableColumns }: ColumnVisibilitySettingProps): React.JSX.Element {
  const { t } = useTranslation();
  const viewMode = useAtomValue(viewModeAtom);
  const [columnVisibility, setColumnVisibility] = useAtom(columnVisibilityAtom);

  return (
    <Box>
      <Flex flexDirection="column" p="0 24px 16px 24px">
        <RippleHeading08 mb="4px">{t('computer:tableSetting.column.label')}</RippleHeading08>
        <RippleBodyText02>{t('computer:tableSetting.column.description')}</RippleBodyText02>
      </Flex>
      <Flex flexDirection="column" flex="1">
        {configurableColumns
          .filter(({ columnId }) => {
            if (viewMode === 'group') {
              // Hide group column setting in group mode
              return columnId !== GROUPING_COLUMN;
            }
            return true;
          })
          .map(({ columnId, label }) => (
            <ColumnItem key={columnId} label={label} visibility={getVisibilityProps(columnId)} />
          ))}
      </Flex>
    </Box>
  );

  function getVisibilityProps(columnId: string): VisibilityProps {
    return {
      columnId,
      value: columnVisibility[columnId],
      onChange: (event) => {
        const newValue = event.target.checked;
        setColumnVisibility((v) => ({ ...v, [columnId]: newValue }));
      },
    };
  }
}
type VisibilityProps = {
  columnId: string;
  value: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
type ColumnItemProps = {
  label: string;
  visibility: VisibilityProps;
};
function ColumnItem({ label, visibility }: ColumnItemProps): React.JSX.Element {
  return (
    <Flex
      justifyContent="space-between"
      borderBottom="1px solid"
      borderBottomColor="neutral.60"
      px="24px"
      py="8px"
      _first={{ borderTop: '1px solid', borderTopColor: 'neutral.60' }}
    >
      <HStack spacing="8px" h="32px">
        <RippleCheckbox
          data-testid={`column-visibility-${visibility.columnId}`}
          isChecked={visibility.value}
          onChange={visibility.onChange}
        />
        <RippleBodyText02>{label}</RippleBodyText02>
      </HStack>
    </Flex>
  );
}
